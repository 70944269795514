define('ingenio-web/components/featured-listings/bid/step-indicator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        steps: null,

        currentStep: function () {
            return this.get('steps').find(function (item) {
                return item.get('isCurrent') === true;
            }) || null;
        }.property('steps.@each.isCurrent')
    });
});