define('ingenio-web/utils/featured-listings-bid-steps', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Step = Ember.Object.extend({
        stepIndex: null,
        route: null,
        name: null,
        isCurrent: false,

        toString: function toString() {
            return JSON.stringify(this);
        }
    });

    var createSteps = function createSteps() {
        return [Step.create({
            stepIndex: 1,
            route: 'featured-listings.bid.select-listing',
            name: 'Choose',
            title: 'Choose a Listing To Feature',
            isCurrent: true
        }), Step.create({
            stepIndex: 2,
            route: 'featured-listings.bid.set-bid',
            name: 'Bid',
            title: null // Page title is set in route - changes based on create/edit mode.
        }), Step.create({
            stepIndex: 3,
            route: 'featured-listings.bid.confirm-bid',
            name: 'Confirm',
            title: null // Page title is set in route - changes based on if bid action is create/update or cancel.
        }), Step.create({
            stepIndex: 4,
            route: 'featured-listings.bid.confirmation',
            name: 'Done!',
            title: 'Featured Listing Bid Confirmed'
        })];
    };

    var Steps = Ember.Object.extend({
        all: null,

        init: function init() {
            var steps = createSteps();

            this.set('all', steps);
        },


        currentStep: Ember.computed('all.@each.isCurrent', function () {
            return this.get('all').find(function (step) {
                return step.get('isCurrent') === true;
            });
        }),

        previousStep: Ember.computed('currentStep', function () {
            var currentStepIndex = this.get('currentStep.stepIndex');

            return this.get('all').find(function (step) {
                return step.get('stepIndex') === currentStepIndex - 1;
            });
        }),

        nextStep: Ember.computed('currentStep', function () {
            var currentStepIndex = this.get('currentStep.stepIndex');

            return this.get('all').find(function (step) {
                return step.get('stepIndex') === currentStepIndex + 1;
            });
        }),

        setCurrentStep: function setCurrentStep(route) {
            if (this.get('current.route') === route) {
                return;
            }

            var currentStep = this.get('currentStep');
            var stepToSwitchTo = this.get('all').findBy('route', route);

            if (!stepToSwitchTo) {
                return;
            }

            if (currentStep) {
                currentStep.set('isCurrent', false);
            }

            stepToSwitchTo.set('isCurrent', true);
        }
    });

    exports.default = Steps;
});