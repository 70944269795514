define('ingenio-web/services/price-buckets-experiment', ['exports', 'ingenio-web/helpers/platform-domain'], function (exports, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        metrics: Ember.inject.service(),

        configure: function configure(payload, minuteRate) {
            if (!this.checkExperimentEligibility()) return;

            if (this.hasVariant(payload) && payload.priceBuckets && minuteRate >= 5 && minuteRate < 10) {
                payload.priceBuckets.forEach(function (item) {
                    var boxOrder = item.get('boxOrder');
                    var newUserPrice = 0;
                    switch (boxOrder) {
                        case 1:
                            newUserPrice = 10;
                            break;
                        case 2:
                            newUserPrice = 25;
                            break;
                        case 3:
                            newUserPrice = 50;
                            break;
                        default:
                            break;
                    }

                    var minuteQuantity = Math.floor(newUserPrice / minuteRate);
                    item.set('totalPrice', newUserPrice);
                    item.set('minuteQuantity', minuteQuantity);
                });
            }
        },
        checkExperimentEligibility: function checkExperimentEligibility() {
            return Ember.Application.user.firstBilledMinuteDate === null && (0, _platformDomain.platformIsKeen)();
        },
        hasVariant: function hasVariant(payload) {
            var hasVariant = false;
            var experiment = payload.experiments.find(function (experiment) {
                return experiment.settings.targets.some(function (target) {
                    return target.type === "Identifier" && target.value === "NEW_USER_PRICE_BUCKET_TEST";
                });
            });
            if (experiment && payload.buckets) {
                var variantBucket = experiment.buckets.find(function (x) {
                    return x.name === 'Variant';
                });
                var buckets = payload.buckets.activeBuckets;
                hasVariant = buckets.find(function (bucket) {
                    return bucket.id == variantBucket.id;
                }) !== undefined;
            }
            return hasVariant;
        }
    });
});