define("ingenio-web/components/credit-card/countries", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = [{
        code: "AU",
        name: "Australia"
    }, {
        code: "AT",
        name: "Austria"
    }, {
        code: "BE",
        name: "Belgium"
    }, {
        code: "BH",
        name: "Bahrain"
    }, {
        code: "CA",
        name: "Canada"
    }, {
        code: "CL",
        name: "Chile"
    }, {
        code: "DK",
        name: "Denmark"
    }, {
        code: "FI",
        name: "Finland"
    }, {
        code: "FR",
        name: "France"
    }, {
        code: "DE",
        name: "Germany"
    }, {
        code: "IS",
        name: "Iceland"
    }, {
        code: "IE",
        name: "Ireland"
    }, {
        code: "IT",
        name: "Italy"
    }, {
        code: "JP",
        name: "Japan"
    }, {
        code: "KR",
        name: "Korea, Republic Of"
    }, {
        code: "LU",
        name: "Luxembourg"
    }, {
        code: "MX",
        name: "Mexico"
    }, {
        code: "NL",
        name: "Netherlands"
    }, {
        code: "NZ",
        name: "New Zealand"
    }, {
        code: "NO",
        name: "Norway"
    }, {
        code: "PR",
        name: "Puerto Rico"
    }, {
        code: "ZA",
        name: "South Africa"
    }, {
        code: "SE",
        name: "Sweden"
    }, {
        code: "TW",
        name: "Taiwan, Republic of China"
    }, {
        code: "AE",
        name: "United Arab Emirates"
    }, {
        code: "GB",
        name: "United Kingdom"
    }, {
        code: "US",
        name: "United States"
    }, {
        code: "VI",
        name: "Virgin Islands (U.S.)"
    }];
});