define('ingenio-web/mixins/visibility-handler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        activate: function activate() {
            this._super.apply(this, arguments);

            var hidden = void 0,
                visibilityState = void 0,
                visibilityChange = void 0;

            if (typeof document.hidden !== 'undefined') {
                hidden = 'hidden';
                visibilityChange = 'visibilitychange';
                visibilityState = 'visibilityState';
            } else if (typeof document.webkitHidden !== 'undefined') {
                hidden = 'webkitHidden';
                visibilityChange = 'webkitvisibilitychange';
                visibilityState = 'webkitVisibilityState';
            } else {
                //browser doesn't support visibilityChange
                return;
            }

            var handleVisibilityChange = function handleVisibilityChange() {
                if (document[visibilityState] == hidden) {
                    this.controller.set('visible', false);
                } else {
                    this.controller.set('visible', true);
                }
            }.bind(this);

            this.set('handleVisibilityChange', handleVisibilityChange);
            this.set('visibilityChange', visibilityChange);

            //https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        },

        deactivate: function deactivate() {
            this._super.apply(this, arguments);
            if (this.get('visibilityChange')) {
                document.removeEventListener(this.get('visibilityChange'), this.get('handleVisibilityChange'));
            }
        }
    });
});