define('ingenio-web/controllers/listing/listing-base', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['returnUrl'],
    returnUrl: null,
    icId: 17011,
    psychicParty: 17101,
    psychicReadingsId: 195,

    primaryActionText: 'submit your listing for approval',

    uploadInProgress: {
      profilePicture: false,
      approachPicture: false,
      backgroundPicture: false,
      video: false
    },

    saving: false,

    imageErrors: null,
    profileErrors: null,
    backgroundErrors: null,
    approachErrors: null,
    setup: function () {
      this.set('imageErrors', Ember.A());
      this.set('profileErrors', Ember.A());
      this.set('backgroundErrors', Ember.A());
      this.set('approachErrors', Ember.A());
    }.on('init'),

    filteredToolsList: function () {
      var toolList = this.get('toolList');
      var icId = this.get('icId');
      var categoryId = this.get('model.categoryId') === icId ? icId : this.get('psychicReadingsId');
      var filtered = toolList.filter(function (s) {
        return s.categoryId === categoryId;
      });

      return filtered;
    }.property('toolList', 'model.categoryId'),

    filteredSkillList: function () {
      var skillList = this.get('skillList');
      var icId = this.get('icId');
      var categoryId = this.get('model.categoryId') === icId ? icId : this.get('psychicReadingsId');
      var filtered = skillList.filter(function (s) {
        return s.categoryId === categoryId;
      });

      return filtered;
    }.property('skillList', 'model.categoryId'),

    videoPictureUrl: function () {
      if (this.get('model.introVideo')) {
        return this.get('model.introVideo.thumbnailUrl');
      }
      return '';
    }.property('model.introVideo'),

    displayVideoNote: function () {
      return (/^\/app\/listings\/drafts\/\d+$/.test(window.location.pathname)
      );
    }.property(),

    actions: {
      deleteImage: function deleteImage(sectionName) {
        this.set('model.' + sectionName + 'Picture', null);
      },
      uploadImage: function uploadImage(sectionName, formData) {
        var _this = this;

        var modelProp = 'model.' + sectionName + 'Picture';
        var inProgressFlag = 'uploadInProgress.' + sectionName + 'Picture';
        this.set(inProgressFlag, true);
        this.set('sec', sectionName);
        this.get('imageErrors').clear(); //Clear previous errors
        this.get('profileErrors').clear();
        this.get('backgroundErrors').clear();
        this.get('approachErrors').clear();
        Ember.$.ajax({
          url: Ember.ENV.getApiRoot() + '/pictures',
          data: formData,
          contentType: false,
          processData: false,
          type: 'POST'
        }).done(function (data) {
          _this.set(modelProp, data);

          // skip validation if a profile picture... which is optional currently.
          if (!Ember.isEqual(sectionName, 'profile')) {
            _this.get('model').validate();
          }
        }).fail(this.handleImageError.bind(this)).always(function () {
          return Object.keys(_this.get('uploadInProgress')).map(function (key) {
            return setTimeout(function () {
              _this.set('uploadInProgress.' + key, false);
            }, 1000);
          });
        });
      },
      onExistingPictureSelected: function onExistingPictureSelected(sectionName, picture) {
        // todo: model should be updated to support nested ember data objects instead of json.
        this.set('model.' + sectionName + 'Picture', picture.toJSON({ includeId: true }));

        // skip validation if a profile picture... which is optional currently.
        if (!Ember.isEqual(sectionName, 'profile')) {
          this.get('model').validate();
        }
      },
      phoneNumberChanged: function phoneNumberChanged() {
        var _this2 = this;

        var phoneNumber = this.get('model.primaryPhoneNumber.countryCode') + this.get('model.primaryPhoneNumber.number');
        var apiUrl = Ember.ENV.getApiRoot();
        this.get('model').addTouched('primaryPhoneNumber');

        Ember.$.getJSON(apiUrl + '/domain-info?domainid=' + Ember.ENV.domainId + '&phoneNumber=' + phoneNumber).then(function (response) {
          _this2.set('domainInfo', response.domainInfo);
          _this2.set('model.domainInfo', response.domainInfo);
          _this2.get('model').validate();
        });
      },
      listingCategoryChanged: function listingCategoryChanged() {
        this.get('model').addTouched('categoryId');
        var hourlySessionRate = 100;
        if (this.get('model.categoryId') === 17101) {
          hourlySessionRate = 250;
        }
        this.set('model.minuteRate', 3.99);
        this.set('model.hourlySessionRate', hourlySessionRate);
        this.get('model').validate();
      },
      onEditListChanged: function onEditListChanged() {
        this.get('model').validate();
        this.get('model').transitionTo('updated.uncommitted');
      }
    },

    /* server-side Error handling */
    hasImageErrors: function () {
      return this.get('imageErrors').length > 0;
    }.property('imageErrors.length'),

    handleImageError: function handleImageError(xhr) {
      var _this3 = this;

      this.get('imageErrors').clear(); //Clear previous errors
      this.get('profileErrors').clear();
      this.get('backgroundErrors').clear();
      this.get('approachErrors').clear();
      var self = this;
      var property;
      var message = '';
      var section = this.get('sec');

      if (xhr.status && xhr.status === 401) {
        throw xhr; //propagate 401 up, it should be handled globally
      }

      if (xhr.errors && _lodash.default.isArray(xhr.errors)) {
        var _loop = function _loop(i) {
          setTimeout(function () {
            self.get('imageErrors').pushObject(xhr.errors[i].detail);
            self.get(section + 'Errors').pushObject(xhr.errors[i].detail);
          }, 1000);
        };

        for (var i = 0; i < xhr.errors.length; i++) {
          _loop(i);
        }
        return;
      } else if (xhr.errors) {
        //disabling ESLint check (since it's a serialized POCO object and oesn't require check for hasOwnProps)
        for (property in xhr.errors) {
          var _loop2 = function _loop2(i) {
            setTimeout(function () {
              self.get('imageErrors').pushObject(xhr.errors[property][i]);
              self.get(section + 'Errors').pushObject(xhr.errors[property][i]);
            }, 1000);
          };

          //eslint-disable-line guard-for-in
          for (var i = 0; i < xhr.errors[property].length; i++) {
            _loop2(i);
          }
        }
        return;
      } else if (xhr.responseJSON && xhr.responseJSON.Message) {
        message = xhr.responseJSON.Message;
      } else if (xhr.responseJSON && xhr.responseJSON.message) {
        message = xhr.responseJSON.message;
      } else if (xhr.responseText) {
        message = xhr.responseText;
      } else {
        message = 'An unknown error has occurred.';
      }
      setTimeout(function () {
        _this3.get('imageErrors').pushObject(message);
        _this3.get(section + 'Errors').pushObject(message);
      }, 1000);
    }
  });
});