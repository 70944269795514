define('ingenio-web/mixins/checkout-flow/payment-details/offerRemovalHelper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  //import { inject } from '@ember/service';

  /*
      This Mixin is is just to share the common code between the payment-details controller  & route related to offer removal when not qualified
  */
  var OfferQualificationResult = Object.freeze({
    //Keeping the casing to match the backend api.
    Qualified: 'Qualified',
    InvalidOffer: 'InvalidOffer',
    QualifierAmountNotRequired: 'QualifierAmountNotRequired',
    NotQualified: 'NotQualified'
  });

  exports.default = Ember.Mixin.create({
    removeOfferIfNotQualified: function removeOfferIfNotQualified(offerId, logger) {
      var _this = this;

      //const offerId = Number(this.get('model.user.offer.id'));

      /*
                Perform the following
                1) Get user offer qualification
                2) If user is not qualified for the offer, remove the offer and redirect back to details page for new user and confirmation page for the repeat user with sufficient funds
              */
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // no need to check user's offer qualification if no offer is found.
        if (!offerId) {
          return resolve(null);
        }

        _this.store.findRecord('userOfferQualification', offerId).then(function (offerQualification) {
          var qualificationResult = offerQualification.get('qualificationResult');

          logger.log('Check user offer qualification. userId=' + Ember.Application.user.id + ', offerId=' + offerId + ', qualificationResult=' + qualificationResult);

          if (qualificationResult === OfferQualificationResult.NotQualified) {
            _this.store.findRecord('userOffer', offerId, {
              backgroundReload: false
            }).then(function (userOffer) {
              userOffer.destroyRecord().then(function () {
                return resolve('user-offer-deleted');
              }).catch(function (error) {
                logger.error('Problem in user offer removal. userId=' + Ember.Application.user.id + ', offerId=' + offerId, {
                  originalError: error
                });

                reject(error);
              });
            }).catch(function (error) {
              return reject(error);
            });
          } else if (offerQualification.get('qualificationResult') === OfferQualificationResult.InvalidOffer) {
            //in case of 3 free minutes offer the offer is already redeemed when adding cc which cannot be removed
            resolve('user-offer-redeemed');
          } else {
            //For any other qualification result like Qualified, QualifierAmountNotRequired,
            resolve('user-offer-associated');
          }
        }).catch(function (error) {
          logger.error('Problem in checking user offer qualification. userId=' + Ember.Application.user.id + ', offerId=' + offerId, {
            originalError: error
          });

          reject(error);
        });
      });
    },
    onNonQualifiedOfferRemoval: function onNonQualifiedOfferRemoval(offerStatus, model, logger, mixpanelClient) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        //TODO: Muthu - instead of checking the balance is < 1, we should check if the user has atleast the amount equivalent to the listing rate per minute
        if (offerStatus === 'user-offer-deleted' && model.get('user.balance.monetary') < 1) {
          /*
                1) Refresh the offer model post delete
                2) Reset the offer amount to 0
                3) Raise offer deleted notification so that we display the error message
                4) Take the user to the detail step to add more funds
              */
          var offerId = model.get('user.offer.id');

          logger.log('Removed User Offer. userId=' + Ember.Application.user.id + ', offerId=' + offerId);

          model.set('user.funds.offerAmount', 0);
          _this2.getCurrentSpecialOffer(model).finally(function () {
            return resolve('return-to-details-step');
          });
        } else {
          resolve(null);
        }
      });
    },
    getCurrentSpecialOffer: function getCurrentSpecialOffer(model) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.store.queryRecord('specialOffer', {
          listingId: model.get('listing.id')
        }).then(function (specialOffer) {
          model.set('user.offer', specialOffer);
          resolve(specialOffer);
        }).catch(function (adapterError) {
          if (adapterError && Ember.get(adapterError, 'errors.firstObject.status') === 404) {
            model.set('user.offer', undefined);
            resolve(null);
          } else {
            reject(adapterError);
          }
        });
      });
    }
  });
});