define('ingenio-web/controllers/listing/draft', ['exports', 'ingenio-web/controllers/listing/listing-base', 'ingenio-web/helpers/platform-domain'], function (exports, _listingBase, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listingBase.default.extend({
        primaryActionText: 'submit your listing',
        showIdvModal: false,
        canEditCategory: true,
        showInfo: true,
        isKeen: (0, _platformDomain.platformIsKeen)(),
        isIntimacyCoachingCategory: false,
        isPsychicPartyCategory: false,
        selectedVideoId: null,
        uploadError: '',

        earningsHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360049891413-Making-Money';
            }
            return '/help/makingmoney';
        }.property(),

        onInit: function () {
            var _this = this;

            this.addObserver('model.categoryId', 'icId', function () {
                var categoryId = _this.get('model.categoryId');
                if (categoryId === _this.get('icId')) {
                    _this.set('isIntimacyCoachingCategory', true);
                    _this.set('isPsychicPartyCategory', false);
                } else if (categoryId === _this.get('psychicParty')) {
                    _this.set('isPsychicPartyCategory', true);
                    _this.set('isIntimacyCoachingCategory', false);
                } else {
                    _this.set('isIntimacyCoachingCategory', false);
                    _this.set('isPsychicPartyCategory', false);
                }
            });
        }.on('init'),

        actions: {
            save: function save() {
                var _this2 = this;

                this.get('model').clearErrors();

                this.set('uploadError', '');
                this.set('model.advisorName', this.get('model.advisorName').trim()); // ensure advisor name do not have trailing spaces
                this.set('saving', true);
                this.get('model').save().catch(this.get('model').handleError.bind(this.get('model'))).finally(function () {
                    // revalidate model after validation and server errors get cleared
                    _this2.get('model').validate();

                    // update flag
                    _this2.set('saving', false);

                    //refreshing model
                    _this2.get('model').reload();
                });
            },

            submitListing: function submitListing() {
                var _this3 = this;

                var submitListingForApproval = function submitListingForApproval() {
                    var listing = _this3.store.createRecord('listing');
                    var draftId = _this3.get('model.id');
                    var introVideo = _this3.get('model.introVideo');

                    var returnUrl = _this3.get('returnUrl');

                    listing.set('listingDraftId', draftId);

                    listing.set('introVideo', introVideo);

                    _this3.set('saving', true);
                    return listing.save().then(function (result) {
                        // redirect to IDV verification if required
                        if (result.get('pendingOnIDV')) {
                            window.location.href = Ember.ENV.urls.idvAuthPage + encodeURIComponent(Ember.ENV.urls.myAccountPage);
                        } else if (returnUrl) {
                            window.location.href = decodeURIComponent(returnUrl);
                        } else {
                            window.location.href = Ember.ENV.urls.myAccountPage;
                        }
                    }).catch(_this3.get('model').handleError.bind(_this3.get('model'))).finally(function () {
                        _this3.set('saving', false);
                    });
                };

                this.get('model').clearErrors();
                this.set('model.advisorName', this.get('model.advisorName').trim()); // ensure advisor name do not have trailing spaces

                this.set('saving', true);
                this.get('model').save().then(submitListingForApproval).catch(this.get('model').handleError.bind(this.get('model'))).finally(function () {
                    // revalidate model after validation and server errors get cleared
                    _this3.get('model').validate();

                    _this3.set('saving', false);
                });
            },
            setVideoId: function setVideoId(id) {
                this.set('model.introVideo', { uid: id });
            },
            uploadErrorMessage: function uploadErrorMessage(message) {
                this.set('uploadError', message);
            }
        }
    });
});