define('ingenio-web/controllers/chat/consumer-session', ['exports', 'ingenio-web/objects/chat/message', 'ingenio-web/objects/chat/end-reasons', 'ingenio-web/objects/chat/chat-notification', 'ingenio-web/mixins/end-chat-helpers', 'ingenio-web/mixins/message-delivery-handler', 'ingenio-web/utils/cookies'], function (exports, _message, _endReasons, _chatNotification, _endChatHelpers, _messageDeliveryHandler, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //import SessionBaseController from 'ingenio-web/controllers/chat/session-base';
  //TODO: inherit ConsumerSessionController from SessionBaseController ()
  var MY_ID = Math.round(Math.random() * 100);

  exports.default = Ember.Controller.extend(_messageDeliveryHandler.default, _endChatHelpers.default, {
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    applePayService: Ember.inject.service('applePay'),
    networkUp: true,
    visible: true,
    logoLinkActive: false,
    userEvents: Ember.inject.service(),

    sessionId: null,
    webtrack: null,
    clickId: null,
    messages: null,
    systemChannelClient: null,
    chatChannelClient: null,
    timeLimit: null,

    typingMessage: null,
    reviewingMessage: null,
    reviewingMessageTimer: null,

    keyPressNotifier: null,
    rechargeTimeLimit: 0, //set in route
    idleWarnDuration: 0, //set in route
    showFooter: true,
    useApplePayForMidChatRecharge: false,

    status: 'connecting',

    connecting: Ember.computed.equal('status', 'connecting'),
    ready: Ember.computed.equal('status', 'ready'),
    rechargePaused: Ember.computed.equal('status', 'rechargePaused'),
    idlePaused: Ember.computed.equal('status', 'idlePaused'),
    ended: Ember.computed.equal('status', 'ended'),
    mobileSession: Ember.computed.equal('webtrack', 101),
    sessionEnded: Ember.computed.alias('model.sessionEnded'),

    elapsedDurationV2: '00:00',

    notification: null,

    listingCategoryName: null,
    listingCategoryId: null,
    listingMinuteRate: 0,
    defaultPostPayIncreaseType: '10',
    showAdvisorDetails: false,
    postPayExtensionFailed: false,

    isShowVariant: false,

    isKeen: Ember.computed(function () {
      return Ember.ENV.domainId === 1;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.determinePaymentMethodForMidChatRecharge();
    },


    onNetworkConnectionStatusChange: Ember.observer('networkUp', function () {
      var _this = this;

      var notification = null;

      if (!this.get('networkUp')) {
        var text = 'Your network is currently disconnected. Make sure your device has an active internet connection.';

        notification = { text: text, closeAfter: null };
      } else {
        var _text = 'Your internet connection was restored.';

        notification = { text: _text, closeAfter: 5 };

        this.get('messages').filter(function (item) {
          return item.get('sendContext.deliveryError');
        }).forEach(function (message) {
          _this.get('chatChannelClient').sendChatMessage(null, message.get('sendContext'));
        });
      }

      Ember.run.debounce(function () {
        //dont notify network issues if the session has already ended
        if (_this.get('ended')) {
          return;
        }
        _this.set('notification', _chatNotification.default.create(notification));
      }, 2000);
    }),

    trackMixpanelEvent: function trackMixpanelEvent(eventName, eventData) {
      var consumerId = this.get('model.chatConsumer.userId');

      if (consumerId === null) {
        return;
      }
      var sessionId = this.get('sessionId');

      var data = void 0;

      if (typeof eventData === 'undefined') {
        data = {};
      } else {
        data = eventData;
      }

      data.session_id = sessionId;
      data.is_postpay = this.get('isPostPay');
      data.is_autoreload = this.get('autoReload');
      data.customer_id = consumerId;
      data.user_type = 'consumer';

      this.get('mixpanelClient').trackNewEvent(eventName, { eventData: data });
    },
    getInitialMessageFromBestMatchCookies: function getInitialMessageFromBestMatchCookies() {
      var answersJsonFromCookies = _cookies.default.getCookie('best-match-v2-answers');
      _cookies.default.eraseCookie('best-match-v2-answers');
      if (!answersJsonFromCookies) {
        return '';
      }

      var answers = JSON.parse(answersJsonFromCookies);

      var name = (answers.find(function (a) {
        return a.label === 'Name';
      }) || '').value;
      var birthDay = (answers.find(function (a) {
        return a.label === 'BirthDay';
      }) || '').value;
      var popularQuestions = (answers.find(function (a) {
        return a.label === 'PopularQuestions' && a.popularQuestion;
      }) || '').option;

      if (name && birthDay && popularQuestions) {
        return 'Hi, my name is ' + name + ' and my birthday is ' + birthDay + '. I\'d like to ask - ' + popularQuestions;
      }

      if (name && popularQuestions) {
        return 'Hi, my name is ' + name + '. I\'d like to ask - ' + popularQuestions;
      }

      if (birthDay && popularQuestions) {
        return 'Hi, my birthday is ' + birthDay + '. I\'d like to ask - ' + popularQuestions;
      }

      if (popularQuestions) {
        return 'Hi, I\u2019d like to ask - ' + popularQuestions;
      }

      return '';
    },


    setup: function () {
      var _this2 = this;

      this.set('previousChatMessagesWithSameAdvisor', null);
      this.set('chatting', true);
      this.set('ending', false);
      this.set('messages', Ember.A());
      this.set('notification', _chatNotification.default.create({
        text: '',
        closeAfter: null
      }));

      this.set('isRateable', false);
      this.set('chatEndReason', null);
      this.set('sessionEndedAndSave', false);

      this.set('status', 'connecting');
      this.set('showChatFooter', false);
      this.set('isPostPay', false);
      this.set('logoLinkActive', false);

      //Chat ended component data:
      this.set('isRateable', false);
      this.set('chatEndReason', null);

      this.set('sessionAmount', null);
      this.set('chargedTime', null);

      this.set('canLeaveFeedback', false);
      this.set('isFeedbackQualifiedForDisplay', false);

      this.set('firstBilledMinuteDate', null);

      var initialQuestion = this.getInitialMessageFromBestMatchCookies();

      if (initialQuestion) {
        this.set('initialMessage', initialQuestion);
      }

      //TODO restuta: use of jQuery in controller
      //$('html, body').animate({scrollTop: $(document).height()}, 'fast');

      //TODO sandeep: need to find a cleaner way of doing this
      Ember.$(window).scroll(function () {
        var startScrollPosition = 80;
        var currentScrollPosition = $(window).scrollTop();

        if (currentScrollPosition >= startScrollPosition) {
          $('#chat-notify-container').removeClass('static-view');
          $('#chat-notify-container').addClass('fixed-view');
        } else {
          $('#chat-notify-container').removeClass('fixed-view');
          $('#chat-notify-container').addClass('static-view');
        }
      });

      if (Ember.Application.user.firstBilledMinuteDate === null && this.get('isKeen')) {
        // Only for new non fbm users
        var url = Ember.ENV.getApiRoot() + '/experiments';
        $.ajax({
          url: url,
          type: 'GET'
        }).then(function (response) {
          var experiment = response.find(function (experiment) {
            return experiment.settings.targets.some(function (target) {
              return target.type === 'Identifier' && target.value === 'RECHARGE_MODAL_TEST';
            });
          });
          _this2.updateRechargeModalVariant(experiment);
        });
      }
    }.observes('model').on('init'),

    updateRechargeModalVariant: function updateRechargeModalVariant(experiment) {
      var _this3 = this;

      var variantBucket = experiment.buckets.find(function (x) {
        return x.name === 'Variant';
      });
      Ember.$.ajax(Ember.ENV.getApiRoot() + '/experiments/buckets', {
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          identifier: 'RECHARGE_MODAL_TEST',
          domainId: Ember.ENV.domainId,
          tempUid: window.ENV.keenUid
        })
      }).then(function (response) {
        var buckets = response.buckets;
        var activebucket = buckets.find(function (bucket) {
          return bucket.id == variantBucket.id;
        });
        if (activebucket) {
          _this3.isShowVariant = true;
        }
      });
    },

    /*'init' is fired only when controller is instantiated. For the next session, the same controller instance
          might be used, in which case we need to reset properties. Hence observing for change in model also.*/

    onRechargePausedChanged: function () {
      if (this.get('rechargePaused')) {
        this.get('logger').log('onRechargePausedChanged true');
      } else {
        this.get('logger').log('onRechargePausedChanged false');
      }
    }.observes('rechargePaused'),

    previousChatMessagesWithSameAdvisorDate: function () {
      var previousChatMessagesWithSameAdvisor = this.get('previousChatMessagesWithSameAdvisor');

      return previousChatMessagesWithSameAdvisor.get('firstObject').sentAt;
    }.property('previousChatMessagesWithSameAdvisor'),

    actions: {
      showConnectingNotification: function showConnectingNotification() {
        this.set('messages', Ember.A());
        this.set('notification', _chatNotification.default.create({
          text: 'Finalizing connection with advisor. This should only take a few moments...',
          closeAfter: null
        }));
      },
      toggleModal: function toggleModal() {
        this.set('status', 'idlePaused');
      },
      messageSent: function messageSent(text) {
        if (!text || text.trim().length === 0) {
          return;
        }

        this.hideTyping();

        var deliverableMessage = _message.DeliverableMessage.create({
          text: text
        });

        this.addMessage(deliverableMessage, true);
        this.get('chatChannelClient').sendChatMessage(null, deliverableMessage); //I don't think we need a recipient. Setting null for now.

        this.get('keyPressNotifier').notifyMessageSent();
        if (!this.get('model.isReceiptEnabled')) {
          this.showReviewing();
        }
      },


      //Below action is for testing only - it'll go away.
      otherPartyMessageSent: function otherPartyMessageSent(text) {
        this.addMessage({ text: text }, false);
      },
      keyPress: function keyPress(e) {
        this.get('keyPressNotifier').notifyKeyPress(e);
      },
      sendKeyPressMessage: function sendKeyPressMessage(state) {
        this.get('chatChannelClient').sendStateMessage(null, state);
      },
      onSessionIdleTimeoutImminent: function onSessionIdleTimeoutImminent(timeoutAfter) {
        var _this4 = this;

        this.get('logger').log('onSessionIdleTimeoutImminent ' + timeoutAfter);
        this.set('status', 'idlePaused');

        var session = this.get('model');

        session.set('status', 'paused');
        session.save().then(function () {
          //We want timer to be paused.
          //TODO:  In addition we should also make sure session is actually paused, by pausing commerce-side.
          _this4.get('chatDurationManagerV2').stop();
          _this4.sendSystemMessage('Session Paused');
        });
      },
      onSessionIdleTimeout: function onSessionIdleTimeout() {
        //todo: add here logic for showing timeout due to idleness warning and close session.
        this.get('logger').log('onSessionIdleTimeout');
        this.endSession(_endReasons.default.consumerIdleTimeout);
      },
      resumeSessionFromIdle: function resumeSessionFromIdle() {
        this.get('logger').log('resumeSessionFromIdle');
        this.get('chatChannelClient').sendStateMessage(null, 'ping');
        this.get('keyPressNotifier').startTimeoutCountdown();
        this.unpauseSession();
      },
      endSessionSelf: function endSessionSelf() {
        //there's probably more to do here.
        this.get('logger').log('endSessionSelf');
        this.endSession(_endReasons.default.consumerEnded);
      },
      onSystemMessageReceivedV2: function onSystemMessageReceivedV2(message) {
        if (message.Type === 'system' || message.Type === 'state') {
          return;
        }

        //Handling common for advisor and consumer system messages
        //called from child controllers with already validated message (message.Data != null)
        var eventData = message.Data;

        if (!eventData) {
          this.get('logger').log('onSystemMessageReceivedV2 empty data.');
          return;
        }

        switch (eventData.eventType) {
          case 'ChatEndedEvent':
            var messageId = message.MessageId;

            if (messageId) {
              var latency = message.ReceivedTimestamp - message.ServerTimestamp;

              this.get('logger').log('ChatAnalysis - Command: EndChat, MessageId: ' + messageId + '. Latency: ' + latency + '.');
            }

            if (Number(this.get('sessionId')) !== Number(eventData.sessionId)) {
              this.get('logger').log('onSystemMessageReceivedV2 incorrect sessionId=' + eventData.sessionId + '.');
              return;
            }

            this.get('logger').log('EndSession based on system message handled on consumer side.');
            this.endSession();
            return;
          default:
            return;
        }
      },
      onBothPartiesReady: function onBothPartiesReady() {
        var _this5 = this;

        this.get('logger').log('onBothPartiesReady');
        //user can start chatting now.

        this.set('showChatFooter', false);
        $(function () {
          $('.footer').hide();
        });

        this.set('status', 'ready');
        this.set('notification', _chatNotification.default.create({
          text: 'Your session has started. Enjoy your conversation.',
          closeAfter: 5
        }));

        var availableTime = this.get('model.availableTimeSeconds');

        this.get('keyPressNotifier').startTimeoutCountdown();
        //chat session start
        this.get('chatDurationManagerV2').startWithAvailabileTime(availableTime);

        var logger = this.get('logger');

        logger.log('Started chatDurationManager with ' + availableTime + ' seconds');

        var session = this.get('model');

        session.set('status', 'ConsumerJoined');
        session.save().then(function () {
          logger.log('Consumer Joined');
        });

        Ember.run.later(function () {
          //put initial message if required
          _this5.sendInitiationMessage();
        }, 2000);
      },
      onChatMessageReceived: function onChatMessageReceived(m) {
        var chatMessage = m.message;
        this.hideReviewing();
        if (!this.get('idlePaused') && chatMessage.Type !== 'system') {
          //TODO: clarify should we remvoe this.get('idlePaused') from if condition
          //when we click on advisor side continue we expect chat unpaused
          this.get('keyPressNotifier').notifyMessageReceived();
        }

        if (chatMessage.Type === 'chat' && chatMessage.Body) {
          this.hideTyping();
          this.addMessage({ text: chatMessage.Body }, false); //TODO: line-breaks should not be escaped to display multi-line messages.

          this.handleMessageReceive(m);
        } else if (chatMessage.Type === 'state') {
          if (chatMessage.Body === 'composing') {
            this.showTyping();
          } else if (chatMessage.Body === 'paused') {
            this.hideTyping();
          } else if (chatMessage.Body === 'ping') {
            this.get('keyPressNotifier').startTimeoutCountdown();
          }
        }
      },
      addMoney: function addMoney() {
        this.get('logger').log('addMoney');
        var timeLimit = this.get('timeLimit');

        if (timeLimit.get('isValid')) {
          this.addMoneyToAccount();
        }
        //TODO: Check do we really need it? Note: we should do it in sync with duration manager
        this.get('keyPressNotifier').startTimeoutCountdown();
        this.send('markSessionAsPostPay', false);
      },
      endSessionDuringRecharge: function endSessionDuringRecharge() {
        this.get('logger').log('endSessionDuringRecharge');
        this.endSession(_endReasons.default.consumerEndedDuringRecharge);
      },
      markSessionAsPostPay: function markSessionAsPostPay(isPostPay) {
        if (this.get('isPostPay') === isPostPay) {
          return;
        }
        this.set('isPostPay', isPostPay);
        var session = this.get('model');

        session.set('isPostPay', isPostPay);
        session.save();
      },
      markSessionAsAutoReload: function markSessionAsAutoReload(autoReload) {
        var session = this.get('model');

        session.set('autoReload', autoReload);
        session.save();
      },
      extendSessionIfPostPayEnabled: function extendSessionIfPostPayEnabled() {
        var isPostPay = this.get('isPostPay');

        if (isPostPay) {
          this.send('extendPostPaySession');
        }
      },
      sessionTimeoutWarning: function sessionTimeoutWarning() {
        // do nothing if it is postPay
        if (this.get('isPostPay')) {
          return false;
        }
        var autoReload = this.get('autoReload');

        if (autoReload) {
          this.send('extendAutoReloadSession');
        } else {
          this.send('warnForRecharge');
        }

        // For cases where user might delete payment method elswhere like in payment method screen or in mobile app.
        // determine again.
        this.determinePaymentMethodForMidChatRecharge();
      },
      extendPostPaySession: function extendPostPaySession(source, increaseType, secondAttempt) {
        var _this6 = this;

        this.get('keyPressNotifier').stopTimeoutCountdown();

        var increaseTypeValue = increaseType || this.get('defaultPostPayIncreaseType');
        var currentIncreaseType = this.get('timeLimit.increaseType');

        this.set('defaultPostPayIncreaseType', increaseTypeValue);
        this.set('timeLimit.increaseType', increaseTypeValue);

        var amount = this.get('timeLimit.additionalAmount');
        this.get('logger').log('extendPostPaySession(' + source + ', ' + increaseType + ', ' + secondAttempt + '). Amount=' + amount);
        var sessionId = this.get('sessionId');

        this.set('timeLimit.increaseType', currentIncreaseType);

        var chatPostPaySessionExtension = this.store.createRecord('chatPostPaySessionExtension', {
          sessionId: sessionId,
          amount: amount,
          additionalFundsInfo: this.store.createFragment('additionalFundsInfo', {
            priceBucketMinutes: null,
            source: 'сhat_post_pay_extension',
            rechargeMethod: 'PostPay' //ManualInput, PriceBucket, PostPay
          })
        });

        this.set('timeLimit.inProcess', true);

        chatPostPaySessionExtension.save().then(function (e) {
          var availableTimeSeconds = e.get('availableTimeSeconds');
          var startTime = e.get('remainingDuration.startTime');
          var endTime = e.get('remainingDuration.endTime');

          if (availableTimeSeconds > 0) {
            _this6.set('status', 'ready');
            _this6.get('keyPressNotifier').startTimeoutCountdown();

            _this6.get('chatDurationManagerV2').stop();
            _this6.get('chatDurationManagerV2').start(startTime, endTime);

            if (!_this6.get('model.IsPostPay')) {
              _this6.send('markSessionAsPostPay', true);
            }
            _this6.set('timeLimit.inProcess', false);

            _this6.get('logger').log('chatPostPaySessionExtension success ' + availableTimeSeconds);
          } else if (!secondAttempt) {
            _this6.get('keyPressNotifier').startTimeoutCountdown();
            _this6.get('logger').log('chatPostPaySessionExtension secondAttempt');
            _this6.set('timeLimit.inProcess', false);

            _this6.send('extendPostPaySession', source, '3', true);
          } else if (source === 'add-time-dialog') {
            //TODO:show error on dialog
            _this6.get('timeLimit').clearErrors();
            _this6.get('timeLimit').addError(null, 'There was an error with Pay After Chat. ' + 'Please retry with a different payment method or select Pay Before Chat.');
            _this6.set('timeLimit.inProcess', false);
          } else {
            _this6.get('keyPressNotifier').startTimeoutCountdown();
            _this6.get('logger').log('chatPostPaySessionExtension failed ' + availableTimeSeconds);
            _this6.set('timeLimit.inProcess', false);
            _this6.send('markSessionAsPostPay', false);
            _this6.set('postPayExtensionFailed', true);
          }
        });
      },
      extendAutoReloadSession: function extendAutoReloadSession(source, increaseType) {
        var _this7 = this;

        this.get('keyPressNotifier').stopTimeoutCountdown();

        var increaseTypeValue = increaseType || this.get('autoReload') / 100;
        //const currentIncreaseType = this.get('timeLimit.increaseType');

        //this.set('autoReload', increaseTypeValue);
        //this.set('timeLimit.increaseType', increaseTypeValue);

        var amount = increaseTypeValue;
        var sessionId = this.get('sessionId');

        //this.set('timeLimit.increaseType', currentIncreaseType);

        var chatSessionExtension = this.store.createRecord('chatSessionExtension', {
          sessionId: sessionId,
          amount: amount,
          extensionType: 'AutoReload',
          eventSource: 'WebSite'
        });

        this.set('timeLimit.inProcess', true);

        chatSessionExtension.save().then(function (e) {
          var availableTimeSeconds = e.get('availableTimeSeconds');
          var startTime = e.get('remainingDuration.startTime');
          var endTime = e.get('remainingDuration.endTime');

          if (availableTimeSeconds > 0) {
            _this7.set('status', 'ready');
            _this7.get('keyPressNotifier').startTimeoutCountdown();

            _this7.get('chatDurationManagerV2').stop();
            _this7.get('chatDurationManagerV2').start(startTime, endTime);

            if (!_this7.get('autoReload')) {
              var autoReload = Math.round(amount * 100);

              _this7.set('autoReload', autoReload);
              _this7.send('markSessionAsAutoReload', autoReload);
            }

            _this7.set('timeLimit.inProcess', false);

            _this7.get('logger').log('chatSessionExtension success ' + availableTimeSeconds);
          } else if (source === 'add-time-dialog') {
            //TODO:show error on dialog
            _this7.get('timeLimit').clearErrors();
            _this7.get('timeLimit').addError(null, 'There was an error with Auto Reload Chat. ' + 'Please retry with a different payment method.');
            _this7.set('timeLimit.inProcess', false);
            _this7.set('autoReloadExtensionFailed', true);
          } else {
            _this7.get('keyPressNotifier').startTimeoutCountdown();
            _this7.get('logger').log('chatSessionExtension failed ' + availableTimeSeconds);
            _this7.set('timeLimit.inProcess', false);
            //this.send('markSessionAsPostPay', false);
            _this7.set('autoReloadExtensionFailed', true);
          }
        });
      },
      warnForRecharge: function warnForRecharge() {
        this.get('logger').log('WarnForRecharge');
        this.set('notification', _chatNotification.default.create({
          text: 'You have 1 minute remaining. You can add more time at the end.',
          closeAfter: 15
        }));
      },
      demandForRecharge: function demandForRecharge() {
        var _this8 = this;

        this.get('logger').log('demandForRecharge');

        var consumerId = this.get('model.chatConsumer.userId');

        this.get('chatDurationManagerV2').stop();
        this.get('keyPressNotifier').stopTimeoutCountdown();

        var session = this.get('model');

        session.set('status', 'Paused');
        session.save().then(function () {
          _this8.get('logger').log('session pause success');
          Ember.RSVP.hash({
            chatPostPayEligibility: _this8.store.findRecord('chatPostPayEligibility', consumerId, { reload: true })
          }).then(function (e) {
            var isPostPayEligible = false;

            //if auto-reload previously failed we don't need to show auto-reload or post-pay payment option
            if (_this8.get('autoReloadExtensionFailed') || _this8.get('postPayExtensionFailed')) {
              isPostPayEligible = false;
            } else {
              isPostPayEligible = e.chatPostPayEligibility.get('isUserEligibleForChatPostPay');
            }

            _this8.set('isPostPayEligible', isPostPayEligible);
            _this8.set('useAutoReload', false);
            _this8.set('timeLimit.increaseType', '10');
            _this8.set('timeLimit.buyMinutes', '');

            _this8.set('status', 'rechargePaused');
          });
        }).catch(function (e) {
          _this8.get('logger').log('consumer chat: error has occurred during pausing session for recharge ' + e);
          _this8.endSession(_endReasons.default.unknown);
        });
      },
      onRechargeTimeout: function onRechargeTimeout() {
        this.get('logger').log('onRechargeTimeout');
        this.endSession(_endReasons.default.consumerEndedRechargeTimeout);
      },
      endSessionOtherPartyEnded: function endSessionOtherPartyEnded() {
        this.get('logger').log('ending session based on advisor leave event');
        //Session was finished by other party
        this.endSession(_endReasons.default.advisorEnded);
      },
      endSessionOnError: function endSessionOnError() {
        this.get('logger').log('ending session due to error');
        this.endSession(_endReasons.default.consumerPubnubError);
      },
      endSessionOnBackButtonClick: function endSessionOnBackButtonClick() {
        this.get('logger').log('ending session due to back button clicked');
        this.endSession(_endReasons.default.consumerBackButtonClicked);
      },
      toggleShowAdvisorDetails: function toggleShowAdvisorDetails() {
        this.toggleProperty('showAdvisorDetails');
      }
    },

    sendInitiationMessage: function sendInitiationMessage() {
      //check for entered in chat banner inside cookies
      var cookie = this.get('cookie');

      var chatQuestion = cookie.getCookie('chat-question-text-from-chat-banner');
      var afqWidget = this.get('afqWidgetInfo');

      if (afqWidget && afqWidget.isEnabled && chatQuestion) {
        chatQuestion = 'Hello, I have the following question: ' + afqWidget.question;
      }

      if (chatQuestion) {
        var message = _message.DeliverableMessage.create({
          text: chatQuestion
        });

        this.addMessage(message, true);

        this.get('chatChannelClient').sendChatMessage(null, message);
        this.get('keyPressNotifier').notifyMessageSent();
        this.showReviewing();

        cookie.removeCookie('chat-question-text-from-chat-banner', { path: '/' });
        cookie.removeCookie('is-afq-widget', { path: '/' });
      }
    },
    sendSystemMessage: function sendSystemMessage(message) {
      //this is needed for system messages to be seen in transcript.
      this.get('chatChannelClient').sendSystemMessage(null, message);
    },
    addMessage: function addMessage(message, textIsMine) {
      var avatarUrl = 'http://api.adorable.io/avatars/50/' + MY_ID + '.png';

      if (!textIsMine) {
        avatarUrl = this.get('model.chatAdvisor.profilePictureUrl');
      }

      //We want to display raw html so line breaks are shown.
      //First make user input safe, then replace '/n' with '<br/>'
      var escapedText = Ember.Handlebars.Utils.escapeExpression(message.text).replace(new RegExp('\n', 'g'), '<br/>'); //eslint-disable-line no-control-regex

      var newMessage = _message.default.create({
        avatarUrl: avatarUrl,
        text: escapedText,
        sentAt: new Date(),
        isMine: textIsMine,
        sendContext: message
      });

      newMessage.sendContext.parent = newMessage;

      this.get('messages').addObject(newMessage);
    },
    _showStatusMessage: function _showStatusMessage(messageType) {
      var message = this.get(messageType);

      if (message === null) {
        message = _message.default.create({
          avatarUrl: this.get('model.chatAdvisor.profilePictureUrl'),
          advisorName: this.get('model.chatAdvisor.advisorName'),
          sentAt: new Date(),
          isMine: false
        });

        switch (messageType) {
          case 'typingMessage':
            message.typingNotificationMessage = true;
            break;
          case 'reviewingMessage':
            message.reviewingNotificationMessage = true;
            break;
        }

        message.messageType = messageType;
        this.set(messageType, message);
      }

      var messages = this.get('messages');
      var contains = messages.includes(message);

      if (contains) {
        this.get('messages').removeObject(message);
      }

      this.get('messages').addObject(message);
    },
    _hideStatusMessage: function _hideStatusMessage(messageType) {
      var message = this.get(messageType);
      var messages = this.get('messages');

      if (messages) {
        messages.removeObject(message);
      }
    },
    showTyping: function showTyping() {
      this._showStatusMessage('typingMessage');
    },
    hideTyping: function hideTyping() {
      this._hideStatusMessage('typingMessage');
    },
    showReviewing: function showReviewing() {
      var _this9 = this;

      //First remove any existing reviewing message and clear timer. Then show reviewing messge after a small delay.
      //After delay, make sure it's still appropriate to show reviewing message - i.e. last message should be a consumer message.
      this.hideReviewing();

      var taskTimer = Ember.run.later(function () {
        var latestMessage = _this9.get('messages').get('lastObject');

        if (latestMessage && latestMessage.isMine) {
          _this9._showStatusMessage('reviewingMessage');
        }
      }, 2000);

      this.set('reviewingMessageTimer', taskTimer);
    },
    hideReviewing: function hideReviewing() {
      this._hideStatusMessage('reviewingMessage');
      var taskTimer = this.get('reviewingMessageTimer');

      if (taskTimer) {
        Ember.run.cancel(taskTimer);
      }
    },
    fetchRewardsAndDisplayBirthChart: function fetchRewardsAndDisplayBirthChart() {
      var _this10 = this;

      var apiUrl = Ember.ENV.getApiRoot();
      var userId = Ember.Application.user.id;

      Ember.$.getJSON(apiUrl + '/loyalty/users/' + userId).then(function (rewards) {
        var birthChartPurchased = _cookies.default.getCookie('birthChartPurchased');
        if (!birthChartPurchased) {
          if (rewards && rewards.tiers.current.name !== 'Transcend') {
            _cookies.default.createCookie('showBirthChart', true, 365);
            _this10.trackMixpanelEvent('Birth Chart Banner Viewed');
            _this10.set('showBirthChart', true);
          }
        }
      }).fail(function (error) {
        console.log('Unable to fetch user rewards!', error);
        _this10.get('logger').log('Unable to fetch user rewards!', error);
      });
    },
    endSession: function endSession(endReason) {
      var _this11 = this;

      var session = this.get('model');
      var sessionId = session.get('id');

      if (this.get('initialMessage')) {
        this.set('initialMessage', '');
      }

      if (this.get('chatChannelClient')) {
        this.get('chatChannelClient').sendAnalytics(sessionId);
      }

      this.set('chatting', false);
      this.set('logoLinkActive', true);

      this.set('notification', _chatNotification.default.create({
        text: '',
        closeAfter: null
      }));

      if (this.get('ended')) {
        return;
      }

      $('.footer').show();
      this.set('showChatFooter', true);

      this.get('logger').log('ending session');

      this.hideTyping();
      this.hideReviewing();

      if (this.get('keyPressNotifier')) {
        this.get('keyPressNotifier').stopTimeoutCountdown();
      }

      if (this.get('chatDurationManagerV2')) {
        this.get('chatDurationManagerV2').stop();
      }

      if (this.get('elapsedDurationTimeoutV2')) {
        this.get('logger').log('clearTimeout elapsedDurationTimeoutV2');
        clearTimeout(this.get('elapsedDurationTimeoutV2'));
      }

      if (endReason) {
        session.set('endReason', endReason);
      }

      var sessionEnded = this.get('sessionEnded');

      if (!sessionEnded) {
        session.set('status', 'Ended');
        this.set('ending', true);

        session.save().then(function () {
          console.log('session ended'); //eslint-disable-line no-console
          _this11.set('ending', false);

          var clientLog = _this11.get('logger').getLogAndClear().join('\n');

          _this11.onSessionEnd(sessionId, clientLog).then(function (result) {
            _this11.set('sessionEndedAndSave', true);
            _this11.set('isRateable', result.rateable.isRateable);
            _this11.set('chatEndReason', result.endResult.endReason);
            var sessionAmount = result.endResult.sessionAmount >= 0 ? Number(result.endResult.sessionAmount).toFixed(2) : 0.0;

            _this11.set('sessionAmount', sessionAmount);
            _this11.set('chargedTime', result.endResult.chargedTime);

            _this11.set('canLeaveFeedback', result.feedbackStatus.Response === 0);
            _this11.set('isFeedbackQualifiedForDisplay', result.feedbackStatus.IsQualifiedForDisplay);

            _this11.set('firstBilledMinuteDate', result.user.user.firstBilledMinuteDate);

            _this11.set('orderCredits', result.orderCredits || []);

            var userInfo = result.user.user;
            var isFbm = userInfo.firstBilledMinuteDate === null && sessionAmount > 0;
            var isKeen = Ember.ENV.domainId === 1;
            if (isKeen) {
              window.dataLayer = window.dataLayer || [];
              // dataLayer object is used by GTM to pass information to tags. 
              // Events or variables can be passed via the data layer, and triggers can be set up based on the values of variables.
              window.dataLayer.push({
                'event': isFbm ? 'fbm_transaction' : 'purchase_chat',
                'purchase_amount': Number(sessionAmount),
                'session_id': Number(sessionId),
                'user_email': userInfo.email
              });
            }
          });

          if (Ember.ENV.domainId === 1) {
            _this11.fetchRewardsAndDisplayBirthChart();
          }
        });
      }

      //unsubscribe from both channels
      if (this.systemChannelClient) {
        this.systemChannelClient.unsubscribe();
      }
      if (this.chatChannelClient) {
        this.chatChannelClient.unsubscribe();
      }

      // Web Tracking of TikTok event
      if (window.ttq) {
        window.ttq.track('CompletePayment', {}, { event_id: sessionId });
      }

      this.set('status', 'ended');
    },
    createAdditionalFundsInfo: function createAdditionalFundsInfo() {
      var manualInput = this.get('selectedBox') === '$custom';

      var selectedPriceBuckets = this.get('priceBuckets').findBy('selected', true);
      var priceBucketMinutes = !manualInput ? selectedPriceBuckets.get('minuteQuantity') : null;

      var additionalFundsInfo = {
        priceBucketMinutes: priceBucketMinutes,
        source: 'mid_chat',
        rechargeMethod: manualInput ? 'ManualInput' : 'PriceBucketMinutes' //ManualInput, PriceBucket, PostPay
      };

      return additionalFundsInfo;
    },
    determinePaymentMethodForMidChatRecharge: function determinePaymentMethodForMidChatRecharge() {
      console.log('Determining payment method for mid chat recharge...');
      var self = this;
      var APPLE_PAY_CHARGE_ACCOUNT_TYPE = 3;
      var canOfferApplePay = this.get('applePayService.canOfferApplePay');
      console.log('🍎 ApplePay available on device?: ', canOfferApplePay);

      this.store.findAll('paymentMethod', { reload: true }).then(function (paymentMethods) {

        if (!Ember.isEmpty(paymentMethods)) {

          var applePayPaymentMethod = paymentMethods.findBy('chargeAccountType', APPLE_PAY_CHARGE_ACCOUNT_TYPE);
          var isApplePayAvailableAsPrimaryPaymentMethod = applePayPaymentMethod && applePayPaymentMethod.get('isPrimary') === true;
          console.log('isApplePayAvailableAsPrimaryPaymentMethod', isApplePayAvailableAsPrimaryPaymentMethod);

          if (isApplePayAvailableAsPrimaryPaymentMethod && canOfferApplePay) {
            console.log('ApplePay available as primary payment method. Will use apple pay for mid-chat recharge.');
            self.set('useApplePayForMidChatRecharge', true);
          } else {
            console.log('Will use credit card for mid chat recharge.');
            self.set('useApplePayForMidChatRecharge', false);
          }
        }
      });
    },
    addMoneyToAccount: function addMoneyToAccount() {
      if (this.get('useApplePayForMidChatRecharge')) {
        console.log('ApplePay available as primary payment method. Attempting to add money using apple pay');
        this.addMoneyToAccountUsingApplePay();
      } else {
        console.log('Attempting to add money using primary payment method.');
        this.addMoneyToAccountWithCard();
      }
    },
    addMoneyToAccountUsingApplePay: function addMoneyToAccountUsingApplePay() {
      var self = this;
      this.get('logger').log('AddMoneyToAccountUsingApplePay - Initiated!');

      var timeLimit = this.get('timeLimit');
      var apiUrl = Ember.ENV.getApiRoot();
      var userId = this.get('model.chatConsumer.userId');
      var url = apiUrl + '/users/' + userId + '/balance';
      var amount = timeLimit.get('additionalAmount');
      var sessionId = this.get('sessionId');

      timeLimit.set('inProcess', true);

      var eventData = { amount: amount, paymentMethod: 'ApplePay' };
      this.trackMixpanelEvent('AddMoneyToAccountUsingApplePay - Initiated!', eventData);

      this.get('applePayService').addFundsWithApplePay({
        amount: amount,
        onPaymentSuccess: function onPaymentSuccess() {
          timeLimit.set('inProcess', false);
          self.unpauseSession();
          self.trackMixpanelEvent('AddMoneyToAccountUsingApplePay - Payment Succeeded!', eventData);
          self.get('userEvents').fundsAdded('Purchase');
        },
        onPaymentError: function onPaymentError(reason) {
          // an error occured during apple pay session. 
          timeLimit.set('inProcess', false);
          var errorMessage = 'An error occurred while initiating ApplePay.';

          if (reason && reason === 'InvalidAccessError') {
            // Apple complains that the apple pay session should be user click intiated. 
            // Let the user try again.
            errorMessage = 'Please click Continue button again to initiate ApplePay! (required)';
            timeLimit.addError(null, errorMessage);
          } else {
            self.get('logger').log('ApplePayOnPaymentError. ' + reason);
            self.trackMixpanelEvent('ApplePayOnPaymentError', eventData);
            timeLimit.addError(null, errorMessage);
          }
        },
        onPaymentCancel: function onPaymentCancel(reason) {
          // user has cancelled the apple pay payment or got cancelled for some other reason.
          timeLimit.set('inProcess', false);
          self.get('logger').log('ApplePayOnPaymentCancelError. Payment Cancelled.');
          var errorMessage = 'Payment Cancelled - We could not add funds using ApplePay.';

          timeLimit.addError(null, errorMessage);
          self.trackMixpanelEvent('ApplePayOnPaymentCancelError', eventData);
        }
      });
    },
    addMoneyToAccountWithCard: function addMoneyToAccountWithCard() {
      var _this12 = this;

      this.get('logger').log('addMoneyToAccount');

      var timeLimit = this.get('timeLimit');
      var apiUrl = Ember.ENV.getApiRoot();
      var userId = this.get('model.chatConsumer.userId');
      var url = apiUrl + '/users/' + userId + '/balance';
      var amount = timeLimit.get('additionalAmount');
      var sessionId = this.get('sessionId');

      var addFundData = {
        amount: amount,
        usePrimaryAccount: true,
        eventSource: 'WebSite',
        sessionId: sessionId,
        sessionType: 'Chat',
        additionalFundsInfo: this.createAdditionalFundsInfo()
      };

      timeLimit.set('inProcess', true);

      var eventData = { amount: amount };
      this.trackMixpanelEvent('Chat Add Funds Attempted', eventData);

      Ember.$.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify(addFundData),
        contentType: 'application/json'
      }).done(function () {
        timeLimit.set('inProcess', false);
        _this12.unpauseSession();
        _this12.trackMixpanelEvent('Chat Funds Added', eventData);
        _this12.get('userEvents').fundsAdded('Purchase');
      }).fail(function (response) {
        timeLimit.set('inProcess', false);
        _this12.get('logger').log('add money failed.');
        var errorMessage = 'Oh no! An error occurred. Please try again.';

        if (response.responseJSON && response.responseJSON.message) {
          errorMessage = response.responseJSON.message;
        }
        timeLimit.addError(null, errorMessage);
        _this12.trackMixpanelEvent('Chat Add Funds Failed', eventData);
      });
    },
    unpauseSession: function unpauseSession() {
      var _this13 = this;

      this.get('logger').log('unpauseSession');
      //call unpause
      var session = this.get('model');

      session.set('status', 'unpaused');
      session.save().then(function () {
        if (_this13.get('ended')) {
          _this13.get('logger').log('session was ended, no need to extend');
          return;
        }

        session = _this13.get('model'); //TODO: this line may not be needed

        var remainingDurationSeconds = session.get('availableTimeSeconds');
        var startTime = session.get('remainingDuration.startTime');
        var endTime = session.get('remainingDuration.endTime');

        if (remainingDurationSeconds > 0) {
          _this13.get('logger').log('UnpauseSession: Can chat for seconds: ' + remainingDurationSeconds);

          //unpause
          _this13.get('chatDurationManagerV2').start(startTime, endTime);

          _this13.set('status', 'ready');
          _this13.set('notification', _chatNotification.default.create({
            text: 'Your session has resumed. You can chat for ' + Math.round(remainingDurationSeconds / 60) + ' minutes',
            closeAfter: 15,
            hideCloseButton: true
          }));
        } else {
          //TODO: Should we show an error instead?
          _this13.get('logger').log('UnpauseSession returned 0 seconds');
          _this13.endSession(_endReasons.default.consumerRechargeError);
        }
      });
    }
  });
});