define('ingenio-web/birth-chart/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var BirthChartModel = Ember.Object.extend({
        hasExistingPaymentMethods: Ember.computed('user.paymentMethods.@each', function () {
            return !Ember.isEmpty(this.get('user.paymentMethods'));
        }),

        paymentMethodSelected: Ember.computed('user.funds.paymentMethodId', 'user.paymentMethods.@each', function () {
            var _this = this;

            return this.get('user.paymentMethods').find(function (method) {
                return method.get('id') === _this.get('user.funds.paymentMethodId');
            });
        })
    });

    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        model: function model(params, transition) {
            var _this2 = this;

            var survrespid = transition.queryParams.survrespid;
            var birthChartReportId = 1;

            var promises = {
                paymentMethods: this.store.findAll('paymentMethod'),
                countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
                answers: Ember.$.getJSON(Ember.ENV.getApiRoot() + ('/surveys/responses/' + survrespid)),
                digitalReportsSettings: Ember.$.getJSON(Ember.ENV.getApiRoot() + ('/digital-reports-settings/' + birthChartReportId))
            };

            //TODO: try to reduce arranged conversation code reusage
            return Ember.RSVP.hash(promises).then(function (payload) {
                return _this2.transformPromiseHashIntoViewModel(payload);
            });
        },


        actions: {
            onUserOfferDeleted: function onUserOfferDeleted() {
                var model = this.modelFor(this.routeName);

                model.set('showOfferRemovalMessage', true);
            }
        },

        translateCamelCaseToTitle: function translateCamelCaseToTitle(str) {
            return str.replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
            .replace(/^./, function (str) {
                return str.toUpperCase();
            }) // Capitalize the first letter
            .trim(); // Remove any leading or trailing spaces
        },
        formatAnswer: function formatAnswer(a) {
            if (a.question == 'DateOfBirth') {
                return (0, _moment.default)(a.answer).format('MMMM D, YYYY');
            } else if (a.question == 'TimeOfBirth') {
                return (0, _moment.default)(a.answer, 'HH:mm').format('h:mm a');
            }
            return a.answer;
        },
        transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
            var _this3 = this;

            var fundsModel = this.store.createRecord('funds');

            // Remove apple pay as a payment method.
            var filteredPaymentMethods = payload.paymentMethods.filter(function (pm) {
                return pm.get('chargeAccountType') !== 3;
            });

            var answers = payload.answers.data.sort(function (a, b) {
                return a.questionId - b.questionId;
            }).filter(function (a) {
                return a.question !== 'BirthLocationLong' && a.question !== 'BirthLocationLat';
            }).map(function (a) {
                return {
                    question: _this3.translateCamelCaseToTitle(a.question),
                    answer: _this3.formatAnswer(a)
                };
            });
            // use the default payment method, by default, if found.
            // handle a weird edge case where the user does not have any primary
            // payment methods and choose the first object by default.
            if (!Ember.isEmpty(filteredPaymentMethods)) {
                var defaultPaymentMethod = filteredPaymentMethods.find(function (method) {
                    return method.get('isPrimary');
                }) || filteredPaymentMethods.get('firstObject');

                fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
            }

            return BirthChartModel.create({
                domain: {
                    id: Ember.ENV.domainId,
                    name: Ember.ENV.domainName
                },
                user: {
                    hasAddedFundsInPast: Ember.Application.user.hasAddedFundsInPast,
                    funds: fundsModel,
                    paymentMethods: filteredPaymentMethods
                },
                countries: payload.countries,
                answers: answers,

                price: payload.digitalReportsSettings.data.priceInCents / 100,
                discountedPrice: payload.digitalReportsSettings.data.discountedPriceInCents / 100,

                showStepsType: 'hide',
                showDepositeType: 'addMoney'
            });
        }
    });
});