define('ingenio-web/utils/ably-monitor-client', ['exports', 'ably', 'ingenio-web/utils/cookies'], function (exports, _ably, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var AblyMonitorClient = function () {
        function AblyMonitorClient(clientId) {
            _classCallCheck(this, AblyMonitorClient);

            this.clientId = clientId;
            this.channelName = 'App:User:' + clientId;

            this.authUrl = Ember.ENV.getApiRoot() + '/users/' + clientId + '/ablytoken';

            var sessionId = _cookies.default.getSessionId();

            this.authHeaders = { 'X-EpcApi-ID': sessionId };
        }

        _createClass(AblyMonitorClient, [{
            key: 'subscribe',
            value: function subscribe() {
                var _this = this;

                var clientId = this.clientId.toString();
                var authUrl = this.authUrl;
                var authHeaders = this.authHeaders;

                var client = new _ably.default.Realtime({ authUrl: authUrl, authMethod: 'POST', authHeaders: authHeaders, clientId: clientId });

                var channelName = this.channelName;

                var channel = client.channels.get(channelName);

                channel.on(function (status) {
                    if (_this.onStatusReceived) {
                        _this.onStatusReceived(status);
                    }
                });

                channel.subscribe(function (message) {
                    message.data.ReceivedTimestamp = _this._serverNow();
                    if (_this.onMessageReceived) {
                        _this.onMessageReceived(message);
                    }
                });

                this.client = client;

                if (!this.difference) {
                    this.client.time(function (err, time) {
                        _this.difference = Date.now() - time;
                    });
                }
            }
        }, {
            key: '_serverNow',
            value: function _serverNow() {
                return Math.round(Date.now() - this.difference);
            }
        }]);

        return AblyMonitorClient;
    }();

    exports.default = AblyMonitorClient;
});