define('ingenio-web/default-payment-method/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		redirectToReturnUrl: function redirectToReturnUrl() {
			var returnUrl = this.get('model.returnUrl') || '/myaccount';
			window.location = returnUrl;
		},

		actions: {
			onPaymentMethodChosen: function onPaymentMethodChosen(paymentMethodId, setSelectedPaymentAsDefault) {
				this.set("model.selectedPaymentMethodId", paymentMethodId);
			},
			onPrimaryButtonClick: function onPrimaryButtonClick() {
				var _this = this;

				var selectedPaymentMethodId = this.get("model.selectedPaymentMethodId");
				var paymentMethodSelected = this.get('model.paymentMethods').find(function (method) {
					return method.get('id') === selectedPaymentMethodId;
				});

				if (!paymentMethodSelected.get('isPrimary')) {
					this.get('model.paymentMethods').setEach('isPrimary', false);
					paymentMethodSelected.set('isPrimary', true);

					paymentMethodSelected.save().then(function () {
						_this.redirectToReturnUrl();
					});
				} else {
					this.redirectToReturnUrl();
				}
			},
			onSecondaryButtonClick: function onSecondaryButtonClick() {
				this.redirectToReturnUrl();
			}
		}
	});
});