define("ingenio-web/mixins/transaction-details/transaction-details-advisor/component", ["exports", "ingenio-web/utils/html-utils"], function (exports, _htmlUtils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userImage: function () {
      var isChat = this.get("model.isChat");
      var isVideoChat = this.get("model.isVideoChat");
      var isFixedPriceSession = this.get("model.transactionDetail.isFixedPriceSession");

      if (isFixedPriceSession) {
        return isVideoChat ? "videocam" : "phone";
      } else {
        return isChat ? "chat" : "phone";
      }
    }.property("model.isChat", "model.isVideoChat", "model.transactionDetail.isFixedPriceSession"),

    consumerLink: function () {
      var consumerId = this.get("model.transactionDetail.consumerId");

      return "/app/#/myaccount/customers/" + consumerId;
    }.property("model.transactionDetail.consumerId"),

    actions: {
      sendMail: function sendMail() {
        window.location = "/Mail/Compose.aspx?" + _htmlUtils.default.encode({
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get("model.transactionDetail.consumerName"),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      sendOffer: function sendOffer() {
        window.location = "/Mail/Compose.aspx?" + _htmlUtils.default.encode({
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get("model.transactionDetail.consumerName"),
            type: "19",
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      sendPing: function sendPing() {
        var consumerId = this.get("model.transactionDetail.consumerId");
        window.location.href = "/app/#/direct-messages/recipient/" + consumerId;
      }
    }
  });
});