define('ingenio-web/components/chat/idle-notify-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //TODO: hook up 3-minute countdown timer here. Make sure it's cleared every time the modal shows up.

        actions: {
            continue: function _continue() {
                this.sendAction('continue');
            },

            end: function end() {
                this.sendAction('end');
            }
        }
    });
});