define('ingenio-web/components/best-match/answers-details', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            $('[data-toggle="popover"]').popover();
        },

        nameQuestion: function () {
            return this.getAnswer('Name');
        }.property(),
        birthdayQuestion: function () {
            return this.getAnswer('BirthDay');
        }.property(),
        nameOrBirthdayQuestion: function () {
            return this.getAnswer('Name') || this.getAnswer('BirthDay');
        }.property(),
        specialityQuestion: function () {
            return this.getAnswer('Topics');
        }.property(),
        skillQuestion: function () {
            return this.getAnswer('Skills');
        }.property(),
        customerMoodQuestion: function () {
            return this.getAnswer('Emotions');
        }.property(),

        getAnswer: function getAnswer(label) {
            var answers = this.get('answers');
            console.log(answers);
            var answer = answers.find(function (a) {
                return a.label === label && a.answer;
            });

            return answer ? { questionLabel: answer.label, answerText: answer.answer } : null;
        }
    });
});