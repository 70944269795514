define('ingenio-web/components/scroll-to', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var navElements = Ember.$(this.get('scrollTo'));

            if (navElements.length > 0) {
                Ember.$('html, body').animate({
                    scrollTop: $(navElements[0]).offset().top
                }, 100);
            }
        }
    });
});