define('ingenio-web/components/phone-number', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['phone-edit'],

        didInsertElement: function didInsertElement() {
            this.$('.phone-number-usa').inputmask({ mask: '(999) 999-9999' });
            this.$('.phone-number-int').inputmask({ mask: '999999999[99999]', greedy: false });
            this.$('.country-code').inputmask({ mask: '9[99]', greedy: false });
            this.$('.extension').inputmask({ mask: '[999999]', greedy: false });

            var extension = this.get('phoneNumber.extension');

            if (!extension || extension.length === 0) {
                this.$('.extension').addClass('hidden');
            }
        },

        actions: {
            switchToInternational: function switchToInternational() {
                this.set('phoneNumber.isUSNumber', false);
                this.set('phoneNumber.isNumberValid', true);
                this.set('phoneNumber.isUSNumber', false);
                this.set('phoneNumber.usAreaCode', '');
                this.set('phoneNumber.usExchangeDigits', '');
                this.set('phoneNumber.usRemainingDigits', '');

                var self = this;

                //in simple words this will ensure that custom jquery plugin gets executed after DOM is rendered (google Ember Run Loop)
                Ember.run.scheduleOnce('afterRender', function () {
                    self.$('.phone-number-usa').inputmask({ mask: '(999) 999-9999' });
                    self.$('.phone-number-int').inputmask({ mask: '999999999[99999]', greedy: false });
                    self.$('.country-code').inputmask({ mask: '9[99]', greedy: false });
                    self.$('.extension').inputmask({ mask: '[999999]', greedy: false });

                    var extension = self.get('phoneNumber.extension');

                    if (!extension || extension.length === 0) {
                        self.$('.extension').addClass('hidden');
                    }
                });
            },

            switchToDomestic: function switchToDomestic() {
                this.set('phoneNumber.isUSNumber', true);
                this.set('phoneNumber.isNumberValid', true);
                this.set('phoneNumber.countryCode', '1');
                this.set('phoneNumber.isUSNumber', true);

                var self = this;

                //in simple words this will ensure that custom jquery plugin gets executed after DOM is rendered (google Ember Run Loop)
                Ember.run.scheduleOnce('afterRender', function () {
                    self.$('.phone-number-usa').inputmask({ mask: '(999) 999-9999' });
                    self.$('.phone-number-int').inputmask({ mask: '999999999[99999]', greedy: false });
                    self.$('.country-code').inputmask({ mask: '9[99]', greedy: false });
                    self.$('.extension').inputmask({ mask: '[999999]', greedy: false });

                    var extension = self.get('phoneNumber.extension');

                    if (!extension || extension.length === 0) {
                        self.$('.extension').addClass('hidden');
                    }
                });
            },

            phoneNumberChanged: function phoneNumberChanged() {
                this.sendAction('phoneNumberChanged');
            },

            toggleExtension: function toggleExtension() {
                this.$('.extension').toggleClass('hidden');

                if (this.$('.extension').hasClass('hidden')) {
                    this.set('phoneNumber.extension', '');
                }
            }
        }
    });
});