define('ingenio-web/utils/timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Timer = function () {
        function Timer(options) {
            _classCallCheck(this, Timer);

            this.options = {
                updateInterval: options.updateInterval || 1000,
                callbacks: options.callbacks || {
                    onUpdate: null,
                    onTimeout: null
                }
            };
            this.timeToString = this._timeToString.bind(this);
        }

        _createClass(Timer, [{
            key: 'countDown',
            value: function countDown(time) {
                var self = this;

                var startDate = new Date().getTime() / 1000;
                var onUpdate = this.options.callbacks.onUpdate;
                var onTimeout = this.options.callbacks.onTimeout;

                if (onUpdate) {
                    var intervalHandle = setInterval(function () {
                        var now = new Date().getTime() / 1000;
                        var duration = now - startDate;
                        var leftTime = time - duration;

                        if (leftTime >= 0) {
                            onUpdate(self.timeToString(Math.round(leftTime)));
                        } else {
                            onUpdate(self.timeToString(Math.round(0)));

                            if (onTimeout) {
                                onTimeout();
                            }

                            clearTimeout(intervalHandle);
                        }
                    }, this.options.updateInterval);
                }
            }
        }, {
            key: '_timeToString',
            value: function _timeToString(time) {
                //var hours = Math.floor(time / 3600);
                //time = time - hours * 3600;
                var minutes = Math.floor(time / 60);
                var seconds = time - minutes * 60;

                return this._strPadLeft(minutes, '0', 2) + ':' + this._strPadLeft(seconds, '0', 2);
            }
        }, {
            key: '_strPadLeft',
            value: function _strPadLeft(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
        }]);

        return Timer;
    }();

    exports.default = Timer;
});