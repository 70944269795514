define('ingenio-web/serializers/credit-card', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload) {
                payload.creditCard.isCardValid = payload.isValid;
            }
            return this._super.apply(this, arguments);
        }
    });
});