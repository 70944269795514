define('ingenio-web/adapters/user-offer', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    //overriding default path
    pathForType: function pathForType() {
      var userId = Ember.Application.user.id;

      return 'users/' + userId + '/offers';
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      //TODO: This is not the correct way and needs to be done in ember data way
      //Since the api endpoint /users/:userid/offers/:offerid returns a offer type we need to have User object have relationship with offers and loaded accordingly.
      if (payload && payload.Offer && !payload.userOffer && status === 200) {
        payload = {
          userOffer: payload.Offer
        };
        payload.userOffer.id = payload.userOffer.Id; //Ember model expects id to be in lowercase. But the serverside api returns like Id
      }

      return this._super(status, headers, payload, requestData);
    }
  });
});