define('ingenio-web/constants/tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ADVISOR_LISTING_CLICKED = exports.ADVISOR_LISTING_CLICKED = 'Advisor Listing Clicked';
  var CALL_NOW_BUTTON_CLICKED = exports.CALL_NOW_BUTTON_CLICKED = 'Call Now Button Clicked';
  var CHAT_NOW_BUTTON_CLICKED = exports.CHAT_NOW_BUTTON_CLICKED = 'Chat Now Button Clicked';

  var DEFAULT_MAX_PRICE = exports.DEFAULT_MAX_PRICE = "9.99";
});