define('ingenio-web/initializers/apple-pay', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'applePay',
        initialize: function initialize(application) {
            // Inject the applePay service to all components, controllers and routes as 'applePayService'
            application.inject('controller', 'applePayService', 'service:applePay');
            application.inject('component', 'applePayService', 'service:applePay');
            application.inject('route', 'applePayService', 'service:applePay');
        }
    };
});