define('ingenio-web/controllers/listing/edit', ['exports', 'ingenio-web/controllers/listing/listing-base'], function (exports, _listingBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listingBase.default.extend({
        primaryActionText: 'save your listing',
        canEditCategory: false,
        selectedVideoId: null,
        uploadError: '',
        isIntimacyCoachingCategory: false,
        isPsychicPartyCategory: false,

        showInfo: function () {
            return this.get('model.isDirty') || this.get('model.isInvalid');
        }.property('model.isDirty', 'model.isInvalid'),

        earningsHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360049891413-Making-Money';
            }
            return '/help/makingmoney';
        }.property(),

        onInit: function () {
            var _this = this;

            this.addObserver('model.categoryId', function () {
                var categoryId = _this.get('model.categoryId');
                if (categoryId === _this.get('icId')) {
                    // console.log('clear');
                    _this.set('isIntimacyCoachingCategory', true);
                    _this.set('isPsychicPartyCategory', false);
                } else if (categoryId === _this.get('psychicParty')) {
                    _this.set('isPsychicPartyCategory', true);
                    _this.set('isIntimacyCoachingCategory', false);
                } else {
                    _this.set('isIntimacyCoachingCategory', false);
                    _this.set('isPsychicPartyCategory', false);
                }
            });
        }.on('init'),

        actions: {
            save: function save() {
                var _this2 = this;

                this.get('model').clearErrors();
                this.set('uploadError', '');

                // check that minuteRate is not modified
                var changedAttributes = this.get('model').changedAttributes();

                if (changedAttributes.minuteRate && changedAttributes.minuteRate[0] !== changedAttributes.minuteRate[1]) {
                    // get session status
                    this.get('chatAvailability').reload().then(function (availability) {
                        // if minuteRate modified chack that there are no active sessions
                        if (availability.get('availability') === 'Busy') {
                            _this2.get('model').addError('generic-error', 'Changes to minute rate is not permitted while in an active chat session.');
                            return;
                        }
                    });
                }

                this.set('saving', true);
                this.get('model').save().catch(this.get('model').handleError.bind(this.get('model'))).finally(function () {
                    _this2.set('saving', false);
                });
            },
            setVideoId: function setVideoId(id) {
                this.set('model.introVideo', { uid: id });
            },
            uploadErrorMessage: function uploadErrorMessage(message) {
                this.set('uploadError', message);
            }
        }
    });
});