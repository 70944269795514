define('ingenio-web/components/credit-card/component', ['exports', 'ingenio-web/components/credit-card/countries', 'ingenio-web/components/credit-card/formatter', 'ingenio-web/components/credit-card/validator', 'ingenio-web/mixins/keyboard-events'], function (exports, _countries, _formatter, _validator, _keyboardEvents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_keyboardEvents.default, {
    classNames: ['credit-card-container'],

    expirationDateFormatForBackend: 'MMYY',

    maxCardNumberLength: 19,
    maxCVVLength: 4,
    maxNameOnCardLength: 26,

    expirationYearsToPopulate: 20,

    cardNumberIsValid: false,
    expirationDateIsValid: false,
    cvvIsValid: false,
    nameOnCardIsValid: false,
    postalCodeIsValid: false,
    checkoutRiskClient: Ember.inject.service('checkout-risk'),

    cardType: Ember.computed('creditCardType', 'cardNumber', function () {
      if (!this.get('cardNumber') && this.get('last4Digits')) {
        return this.get('creditCardType');
      }

      return _validator.default.getCardType(this.get('cardNumber'));
    }),

    cardScheme: null,

    cardNumberFormatted: Ember.computed('editCardDisabled', 'creditCardType', 'last4Digits', function () {
      var cardType = this.get('creditCardType');
      var lastFourDigits = this.get('last4Digits');

      if (cardType && lastFourDigits) {
        return _formatter.default.maskCreditCardFromLastFourDigits(cardType.toLowerCase(), lastFourDigits);
      }

      return null;
    }),

    // The backend API expects the expiration date to be in the format of MMYY...
    // Whereas this component have date in MM/YYYY format.
    // This computed property handles converting between formats...
    // Ideally the API should be adjusted to support the updated structure rather than involving this overhead.
    expirationDateForBackend: Ember.computed('expirationDate', 'expirationDateFormatForBackend', {
      get: function get() {
        var expirationDateFormatForBackend = this.get('expirationDateFormatForBackend');
        var expirationDate = this.get('expirationDate');

        if (this.validateExpirationDate(expirationDate)) {
          var validatedDate = moment('' + expirationDate, 'MMYYYY');

          return validatedDate.isValid() ? validatedDate.format(expirationDateFormatForBackend) : null;
        }

        return null;
      },
      set: function set(key, value) {
        var date = moment(value, this.get('expirationDateFormatForBackend'));

        if (date.isValid()) {
          this.set('expirationDate', date.format('MM/YYYY'));

          return value;
        }

        return null;
      }
    }),

    selectedCountry: Ember.computed('country', function () {
      var _this = this;

      return _countries.default.find(function (country) {
        return country.code === _this.get('country');
      });
    }),

    frameCardValid: false,
    frameCardNumberValidationMessage: '',
    frameExpirationValidationMessage: '',
    frameCVVValidationMessage: '',

    isFormValid: Ember.computed('editCardDisabled', 'frameCardValid', //checkout frame validation
    // 'cardNumberIsValid',
    // 'expirationDateIsValid',
    // 'cvvIsValid',
    'nameOnCardIsValid', 'postalCodeIsValid', function () {
      var editCardDisabled = this.get('editCardDisabled');
      // This custom form fields validation was implemented because we had issue with expirationDate field validation

      return editCardDisabled == true || editCardDisabled == false &&
      // && this.get('cardNumberIsValid')
      // this.get('expirationDateIsValid') &&
      // this.get('cvvIsValid') &&
      this.get('frameCardValid') && this.get('nameOnCardIsValid') && this.get('postalCodeIsValid');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('countries', _countries.default);

      this.initializeCreditCardValidators();
      this.get('checkoutRiskClient').get('framesReinitializedAt'); // start observing changes
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('expirationDateForBackend', this.get('expiration'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initializeFrames();
    },


    // Listens to 'framesReinitializedAt' time in the checkoutRiskClient service to re-initialize frames in case of an error that occurs outside of this component.
    framesReinitializationObserver: Ember.observer('checkoutRiskClient.framesReinitializedAt', function () {
      this.initializeFrames();
    }),

    initializeFrames: function initializeFrames() {
      var _this2 = this;

      Frames.init({
        publicKey: Ember.ENV.checkoutPublicKey,
        localization: {
          cardNumberPlaceholder: ' ',
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: ' '
        },
        style: {
          base: {
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Arial, sans-serif',
            borderBottom: '1px solid #e0dfe0',
            letterSpacing: 0
          },
          autofill: {
            backgroundColor: 'yellow'
          },
          hover: {
            color: 'black'
          },
          focus: {
            color: 'black',
            borderBottom: '2px solid black'
          },
          valid: {
            color: 'black'
          },
          invalid: {
            color: '#d32f2f',
            borderBottom: '2px solid #d32f2f'
          },
          placeholder: {
            base: {
              color: 'gray'
            },
            focus: {
              color: 'gray'
            }
          }
        }
      });

      Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, function (event) {
        console.log('CARD_VALIDATION_CHANGED: %o', event);

        if (_this2.get('isDestroyed') || _this2.get('isDestroying')) {
          // handle deferred case
          // if this component is destroyed, do nothing.
          return;
        }

        // setTokenButtonEnabled(Frames.isCardValid());
        if (Frames.isCardValid()) {
          console.log('card is valid');
          _this2.set('frameCardValid', true);
        } else {
          console.log('card is invalid');
          _this2.set('frameCardValid', false);
        }
        _this2.attrs.onCreditCardValidityChanged(_this2.get('isFormValid'));
      });

      Frames.addEventHandler(Frames.Events.CARD_BIN_CHANGED, function (event) {
        console.log('CARD_BIN_CHANGED: %o', event);
        var bin = event.bin,
            scheme = event.scheme;

        _this2.set('cardScheme', scheme.toLowerCase());
      });

      Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, function (event) {
        var element = event.element,
            isEmpty = event.isEmpty,
            isValid = event.isValid;


        if (element === 'card-number') {
          _this2.set('frameCardNumberValidationMessage', !isValid ? 'Please enter a valid card number' : '');
          if (isEmpty) {
            //reset card icon
            _this2.set('cardScheme', 'default');
          }
        }
        if (element === 'expiry-date') {
          _this2.set('frameExpirationValidationMessage', !isValid ? 'Please enter a valid expiration date' : '');
        }
        if (element === 'cvv') {
          _this2.set('frameCVVValidationMessage', !isValid ? 'Please enter a valid cvv' : '');
        }
      });
      console.log('initialized frames');
    },


    actions: {
      onValidityChange: function onValidityChange(fieldName, isValid) {
        this.set(fieldName + 'IsValid', isValid);

        this.attrs.onCreditCardValidityChanged(this.get('isFormValid'));
      },
      onCreditCardFieldChange: function onCreditCardFieldChange(id, value) {
        this.set(id, value);
        this.notifyCreditCardChange();
      },
      onExpirationDateChange: function onExpirationDateChange(id, value) {
        var expirationDate = value.replace('/', '');

        // We need this for iOS auto-complete: we have 8/2022 format in this case
        // is should be updated to 08/2022
        if (expirationDate.length == 5) {
          var expirationDateUpdated = '0' + expirationDate;

          if (this.validateExpirationDate(expirationDateUpdated)) {
            value = expirationDateUpdated;
            var maskedVal = value.slice(0, 2) + '/' + value.slice(2);
            $('#input-card-expiry').val(maskedVal);
            this.set('expirationDate', expirationDateUpdated);
          }
        }

        this.set(id, value);
        this.notifyCreditCardChange();
      },
      onNameOnCardKeyDown: function onNameOnCardKeyDown(event) {
        this.allowKeys(event, [this.keyType.ctrl, this.keyType.alpha, this.keyType.space, this.keyType.symbol, this.keyType.special, this.keyType.decimal]);
      },
      onCardNumberKeyDown: function onCardNumberKeyDown(event) {
        this.allowKeys(event, [this.keyType.ctrl, this.keyType.special, this.keyType.numeric, this.keyType.space]);
      },
      onCVVKeyDown: function onCVVKeyDown(event) {
        this.allowKeys(event, [this.keyType.ctrl, this.keyType.special, this.keyType.numeric]);
      }
    },

    // This method is used to call the closure action passed on from the router/controller
    notifyCreditCardChange: function notifyCreditCardChange() {
      var country = this.get('country.code') || this.get('country');

      this.attrs.onCreditCardChanged({
        country: country,
        cvv: this.get('cvv'),
        cardNumber: this.get('cardNumber'),
        nameOnCard: this.get('nameOnCard'),
        postalCode: this.get('postalCode'),
        creditCardType: this.get('cardType'),
        expiration: this.get('expirationDateForBackend')
      });
    },
    initializeCreditCardValidators: function initializeCreditCardValidators() {
      var _this3 = this;

      this.set('cardNumberValidation', [{
        message: 'Invalid Card Number provided.',
        validate: function validate(inputValue) {
          if (inputValue[0] == ' ') {
            return false;
          }

          var cardNumber = inputValue.replace(/\s+/g, '') + '';

          return _validator.default.validateLuhn(cardNumber) && _validator.default.isValidNumber(cardNumber);
        }
      }]);

      this.set('cvvValidations', [{
        message: 'Invalid CVV provided.',
        validate: function validate(inputValue) {
          var cvv = inputValue + '';
          return _validator.default.isValidCVV(cvv, _validator.default.getCardType(_this3.get('cardNumber')) || _this3.get('creditCardType').toLowerCase());
          //TODO: IsValidCVV is done only on change of the CVV input. But doesnt fire when we change the credit card. Need to revisit this check later
        }
      }]);

      this.set('expirationDateValidations', [{
        message: 'Invalid Expiration Date provided.',
        validate: function validate(inputValue) {
          return _this3.validateExpirationDate(inputValue);
        }
      }]);
    },


    // returns mohtns: 01 ... 12
    monthsAvailable: function monthsAvailable() {
      return [].concat(_toConsumableArray(Array(12).keys())).map(function (value) {
        return (value < 9 ? '0' : '') + (value + 1);
      });
    },


    // returns years from current to current + expirationYearsToPopulate, e.g. 2019 ... 2038
    yearsAvailable: function yearsAvailable(month) {
      var yearFormat = 'YYYY';
      var yearsAvailable = [].concat(_toConsumableArray(Array(this.get('expirationYearsToPopulate')).keys())).map(function (value) {
        return (value + Number(moment().format(yearFormat))).toString();
      });

      // we need to exclude from available years current year in case when month is before the current month
      // e.g. current YY/MM is 09/2019, so 08/2019 and previous are not valid
      return Number(month) <= moment().month() ? yearsAvailable.filter(function (year) {
        return year !== moment().format(yearFormat);
      }) : yearsAvailable;
    },


    // Expiration Date has MMYYYY  or MM/YYYY format, e.g. 092019 or 09/2019
    validateExpirationDate: function validateExpirationDate(inputValue) {
      var expirationDate = inputValue.replace('/', '');

      if (expirationDate == '') {
        return true;
      }

      if (expirationDate.length != 6) {
        return false;
      }

      var month = expirationDate.substr(0, 2);
      var monthIsValid = this.monthsAvailable().includes(month);

      var year = expirationDate.substr(2, 4);
      var yearIsValid = this.yearsAvailable(month).includes(year);

      if (monthIsValid && yearIsValid) {
        return true;
      }

      return false;
    }
  });
});