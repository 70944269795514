define('ingenio-web/models/listing-v2', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        advisorId: (0, _attr.default)('number'),
        picture: (0, _attr.default)(),
        name: (0, _attr.default)('string'),
        starRating: (0, _attr.default)('number'),
        averageRating: (0, _attr.default)('number'),
        points: (0, _attr.default)('number'),
        listingTitle: (0, _attr.default)('string'),
        serviceRate: (0, _attr.default)('string'),
        serviceRateAmount: (0, _attr.default)('number'),
        speakerName: (0, _attr.default)('string'),
        categoryId: (0, _attr.default)('number'),
        categoryName: (0, _attr.default)('string'),
        parentCategoryName: (0, _attr.default)('string'),
        ratingCount: (0, _attr.default)('number'),
        detailsUrl: (0, _attr.default)('string'),
        chatAvailability: (0, _attr.default)('number'),
        availability: (0, _attr.default)('number'),
        offerPrice: (0, _attributes.fragment)('offerPrice'),
        serviceRateAmountRounded: function () {
            var serviceRateAmount = this.get('serviceRateAmount');

            if (serviceRateAmount) {
                return serviceRateAmount.toFixed(2);
            }
            return 0;
        }.property()
    });
});