define("ingenio-web/utils/partner-header-types", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Object.freeze({
        None: 10,
        Minimal: 3,
        Full: 2,
        MinimalNoLink: 4,
        Custom: 5,
        NotSet: -1,
        UnKnown: 1
    });
});