define('ingenio-web/controllers/support/close-account', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        user: Ember.Application.user,
        continue: false,
        selectedReason: null,

        actions: {
            optionChanged: function optionChanged(option) {
                //
            },
            startOver: function startOver() {
                this.set('continue', false);
            },
            continue: function _continue() {
                this.set("continue", true);
            },
            cancel: function cancel() {
                window.location.href = '/help/support';
            }
        }
    });
});