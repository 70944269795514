define('ingenio-web/routes/featured-listings/bid/confirmation', ['exports', 'ingenio-web/utils/featured-listings-bid-steps', 'ingenio-web/utils/header-types'], function (exports, _featuredListingsBidSteps, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        featuredListingsBid: Ember.computed(function () {
            return this.modelFor('featured-listings.bid');
        }),

        beforeModel: function beforeModel() {
            var listingId = this.get('featuredListingsBid.listing.id');

            if (!listingId) {
                this.transitionTo('featured-listings.bid.select-listing');
            }
        },
        model: function model() {
            return {
                cancelBid: this.get('featuredListingsBid.cancelBid')
            };
        },
        afterModel: function afterModel() {
            // Model is reset after model hook to ensure "cancelBid" is available for view.
            // Text changes on confirmation page based on if the bid was cancelled or not.
            this.get('featuredListingsBid').resetModel();
            this.set('featuredListingsBid.steps', _featuredListingsBidSteps.default.create());
        },


        actions: {
            didTransition: function didTransition() {
                this.send('setCurrentStep', this.routeName);

                return true;
            }
        }
    });
});