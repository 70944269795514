define('ingenio-web/routes/direct-messages/base-router', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel() {
            var _this = this;

            var reloadTask = setTimeout(function () {
                _this.refresh();
            }, 2 * 60 * 1000);

            this.set('reloadTask', reloadTask);
        },
        willTransition: function willTransition() {
            var reloadTask = this.get('reloadTask');

            if (reloadTask) {
                clearTimeout(reloadTask);
            }
        }
    });
});