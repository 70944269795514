define('ingenio-web/adapters/platform-attribute', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'session/attributes';
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            if (status === 200) {
                payload['platformAttribute'] = payload;
            }

            return this._super.apply(this, arguments);
        }
    });
});