define('ingenio-web/components/add-funds/price-buckets/item/component', ['exports', 'ingenio-web/mixins/auto-reload-helper'], function (exports, _autoReloadHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_autoReloadHelper.default, {
        showAutoReloadIcon: Ember.computed('selected', 'autoReloadSelected', function () {
            var selected = this.get('selected');
            var autoReloadSelected = this.get('autoReloadSelected');

            return selected && autoReloadSelected;
        }),

        actions: {
            onBoxSelected: function onBoxSelected() {
                // skip bubbling up ember event in the event the component is disabled.
                if (this.attrs.disabled) {
                    return;
                }

                var onSelect = this.attrs.onSelect;

                if (onSelect) {
                    var boxOrder = parseFloat(this.get('boxOrder'));
                    var price = this.get('totalPrice');
                    var minutes = this.get('minuteQuantity');

                    onSelect(boxOrder, price, minutes);
                }
            }
        }
    });
});