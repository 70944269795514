define('ingenio-web/components/footer/acquisition-footer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		currentYear: function () {
			var theDate = new Date();

			return theDate.getFullYear();
		}.property(),

		helpPageUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs;
		}.property(),

		userAgreementHelpUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360060613353-Keen-User-Agreement';
		}.property(),

		privacyPolicyHelpUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs + '/articles/1500000300002-Ingenio-Privacy-Policy';
		}.property(),

		satisfactionGuaranteeHelpUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs + '/articles/1500000300362-Keen-Satisfaction-Guarantee';
		}.property()
	});
});