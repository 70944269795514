define('ingenio-web/models/balance', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        monetary: (0, _attr.default)('number'),
        chatMinutes: (0, _attr.default)('number'),
        callMinutes: (0, _attr.default)('number'),
        domainMinutes: (0, _attr.default)('number'),
        monetaryCoins: (0, _attr.default)('number'),
        sellerMinutes: (0, _attr.default)('number'),
        offerPromotionMinutes: (0, _attr.default)('number'),

        // TODO: Monetary is not a clear name here ... aligning with the api for now.
        monetaryAsNumber: Ember.computed('monetary', {
            get: function get() {
                return parseFloat(this.get('monetary') || 0);
            },
            set: function set(key, value) {
                this.set('monetary', parseFloat(value));
            }
        })
    });
});