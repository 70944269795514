define("ingenio-web/models/phone-types", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = [{
        "id": 0,
        "name": "Work"
    }, {
        "id": 1,
        "name": "Home"
    }, {
        "id": 2,
        "name": "Mobile"
    }];
});