define('ingenio-web/components/ing-intl-tel-input/component', ['exports', 'ingenio-web/mixins/keyboard-events', 'ember-intl-tel-input/components/intl-tel-input'], function (exports, _keyboardEvents, _intlTelInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _intlTelInput.default.extend(_keyboardEvents.default, {
    number: Ember.computed('selectedCountryData', 'numberFormat', 'value', {
      get: function get() {
        var numberFormat = intlTelInputUtils.numberFormat[this.get('numberFormat')];

        return this.$().intlTelInput('getNumber', numberFormat);
      },
      set: function set(key, value) {
        var _this = this;

        this.set('value', value);

        Ember.run.scheduleOnce('afterRender', this, function () {
          return _this.$().intlTelInput('setNumber', value || '');
        });
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.get('value')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          return _this2.bubbleValidationStateEvent();
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        return _this3.attrs.onSelectedCountryChange(_this3.$().intlTelInput('getSelectedCountryData'));
      });

      // the plugin does not appear to correctly propegate country selection changes.
      // this fixes the issue where validation is not re-triggered when toggling between countries.
      this.$().on('countrychange', function (e, countryData) {
        _this3.set('selectedCountryData', countryData);
        _this3.bubbleValidationStateEvent();

        _this3.attrs.onSelectedCountryChange(countryData);
      });
    },
    keyDown: function keyDown(event) {
      this.allowKeys(event, [this.keyType.ctrl, this.keyType.special, this.keyType.numeric]);
    },
    keyUp: function keyUp() {
      this.bubbleValidationStateEvent();
    },
    bubbleValidationStateEvent: function bubbleValidationStateEvent() {
      var errorCode = this.$().intlTelInput('getValidationError');

      this.attrs.onPhoneValidityChange({
        validNumber: this.$().intlTelInput('isValidNumber'),
        errors: errorCode > 0 ? [{
          code: errorCode,
          message: this.getValidationErrorTextByErrorCode(errorCode)
        }] : []
      });
    },
    getValidationErrorTextByErrorCode: function getValidationErrorTextByErrorCode(errorCode) {
      return Object.keys(intlTelInputUtils.validationError).find(function (key) {
        return intlTelInputUtils.validationError[key] === errorCode;
      });
    }
  });
});