define('ingenio-web/components/privacy-footnote', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		privacyPolicyHelpUrl: function () {
			if (Ember.ENV.domainId === 1) {
				return Ember.ENV.urls.zendeskDomainNameUs + '/articles/1500000300002-Ingenio-Privacy-Policy';
			}
			return '/help/privacypolicy';
		}.property()
	});
});