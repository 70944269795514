define('ingenio-web/utils/monitor-client', ['exports', 'ingenio-web/utils/pubnub-client', 'mobile-detect'], function (exports, _pubnubClient, _mobileDetect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var isMobile = function isMobile() {
        var md = new _mobileDetect.default(window.navigator.userAgent);
        var isPhone = md.phone() !== null;
        var isTablet = md.tablet() !== null;

        return isPhone && !isTablet;
    };

    var MonitorClient = function () {
        function MonitorClient(options, pubnubOptions, store, logger, route) {
            var _this = this;

            _classCallCheck(this, MonitorClient);

            this.store = store;
            this.route = route;
            this.advisorId = options.advisorId;
            this.isOnline = false;
            this.sessionId = null;

            this.logData = { advisorId: this.advisorId, source: 'MonitorClient', sessionId: null };

            this.logger = logger;

            this.networIsDown = false;

            pubnubOptions.callbacks = pubnubOptions.callbacks || {
                setStateFailure: function setStateFailure(message) {
                    return _this.log('Setting Status failed. ' + message);
                },
                subscribe: {
                    success: function success() {
                        return _this._subscribeSuccess();
                    },
                    failure: function failure() {
                        return _this._subscribeFail();
                    }
                },
                unsubscribe: {
                    success: function success() {
                        return _this._unsubscribeSuccess();
                    }
                },
                disconnect: function disconnect() {
                    return _this._disconnect();
                },
                resubscribe: {
                    success: function success() {
                        return _this._resubscribeSuccess();
                    }
                },
                messageReceived: function messageReceived(m) {
                    return _this.messageReceivedCommon(m.message);
                },
                timeout: function timeout(presenceMessage) {
                    if (presenceMessage.action === 'timeout') {
                        _this.log('Monitor timeout occured ' + JSON.stringify(presenceMessage));
                    } else if (presenceMessage.action === 'leave') {
                        _this.log('Monitor leave occured ' + JSON.stringify(presenceMessage));
                    }
                },
                multiOccupancy: function multiOccupancy(presenceMessage) {
                    _this.log('Monitor multiOccupancy occured ' + JSON.stringify(presenceMessage));
                },
                //TODO: switch on/off 'advisor will be put offline' banner in monitor
                networkDown: function networkDown() {
                    _this.networIsDown = true;
                    _this._disconnectLogOff();
                    _this._clearReservedMessage();
                },
                networkUp: function networkUp() {
                    _this.networIsDown = false;
                    _this.setChatOnline();
                }
            };

            pubnubOptions.logger = this.logger;

            this.pubnubClient = new _pubnubClient.default(pubnubOptions, pubnubOptions.pubnubClientInstance);

            this.startConnectionStatusMonitor();

            var callbacks = options.callbacks || {};

            this.onConnect = callbacks.onConnect;
            this.onDisconnect = callbacks.onDisconnect;

            this.onCallConnect = callbacks.onCallConnect;
            this.onCallDisconnect = callbacks.onCallDisconnect;
            var isMOB = isMobile();
            var doNothing = function doNothing() {
                console.log("Ignoring chat event in monitor-client in mobile/tablet!");
            };

            this.onChatRequestReceived = isMOB ? doNothing : callbacks.onChatRequestReceived;
            this.onChatEnded = isMOB ? doNothing : callbacks.onChatEnded;
            this.onChatAccepted = isMOB ? doNothing : callbacks.onChatAccepted;
            this.onChatDeclined = isMOB ? doNothing : callbacks.onChatDeclined;
            this.onDisconnectLogOff = isMOB ? doNothing : callbacks.onDisconnectLogOff;

            this.onChatMissedUpdateSuccess = isMOB ? doNothing : callbacks.onChatMissedUpdateSuccess;
            this.onChatMissedUpdateFailed = isMOB ? doNothing : callbacks.onChatMissedUpdateFailed;

            this.onCallStatusUpdateReceived = callbacks.onCallStatusUpdateReceived;
            this.onLockStatusUpdateReceived = callbacks.onLockStatusUpdateReceived;

            this._onBeforeunload();

            this.isOnline = false;
            this.callIsOnline = false;
            this.clearReservedMessage = callbacks.clearReservedMessage;
        }

        _createClass(MonitorClient, [{
            key: 'messageReceivedCommon',
            value: function messageReceivedCommon(message) {
                if (message.Body) {
                    this._messageReceivedV2(message);
                } else if (message.IsLockMessage) {
                    this._lockMessageReceived(message);
                } else {
                    this._callMessageReceived(message);
                }
            }
        }, {
            key: 'log',
            value: function log(eventName) {
                return this.logger.log(eventName, this.logData);
            }
        }, {
            key: 'logError',
            value: function logError(errorName, error) {
                return this.logger.error(errorName + ' Error: ' + JSON.stringify(error), this.logData, error);
            }
        }, {
            key: 'init',
            value: function init() {
                var _this2 = this;

                this.log('MonitorClient.init()');
                this.subscribe();

                this.store.findRecord('advisorChatAvailability', this.advisorId, { reload: true }).then(function (availability) {
                    _this2.availability = availability;
                    _this2._setInitialStatus();
                });
                this.log('MonitorClient.init() finish');
            }
        }, {
            key: 'subscribe',
            value: function subscribe() {
                this.pubnubClient.subscribe();

                if (this.updateAuthKeyCountDown) {
                    clearInterval(this.updateAuthKeyCountDown);
                }
                this.updateAuthKeyCountDown = this._updateAuthKeyCountDown();
            }
        }, {
            key: 'declineChatRequest',
            value: function declineChatRequest() {
                var _this3 = this;

                this.log('Declining chat request.');

                return this.store.findRecord('chatSession', this.sessionId).then(function (session) {
                    session.set('status', 'declined');
                    return session.save().then(function (s) {
                        _this3.log('chat - set session state as Declined success.');
                        _this3.sessionId = null;
                        _this3.logData.sessionId = null;
                        return s;
                    });
                }).catch(function (error) {
                    _this3.logError('Error during Declining chat request.', error);
                    return error;
                });
            }
        }, {
            key: 'missedChatRequest',
            value: function missedChatRequest() {
                var _this4 = this;

                this.log('Missed chat request.');

                //set monitor state from `wait for response` to `listening` before
                //update chatSession state to missedChatRequest in 2 sec
                //we are doing so to avoid corner case when advisor
                //has two open monitor and accept request in one monitor in the last second
                //which causes `missed session` and `accepted session` requests concurrency

                Ember.run(function () {
                    _this4.onChatMissedUpdateFailed();
                });

                return this.store.findRecord('chatSession', this.sessionId).then(function (session) {
                    session.set('status', 'missed');
                    return Ember.run.later(_this4, function () {
                        return session.save().then(function () {
                            _this4.log('chat - update session as missed success.');
                            _this4.sessionId = null;
                            _this4.logData.sessionId = null;
                            if (session.get('status').toLowerCase() === 'missed') {
                                _this4.log('chat - set session as missed success.');
                                if (_this4.onChatMissedUpdateSuccess) {
                                    Ember.run(function () {
                                        _this4.onChatMissedUpdateSuccess();
                                    });
                                }
                            }
                        });
                    }, 2000);
                }).catch(function (error) {
                    _this4.logError('Error during missed chat request send.', error);
                    return error;
                });
            }
        }, {
            key: 'setAvailable',
            value: function setAvailable() {
                var _this5 = this;

                this._updateAvailability(this.advisorId, 'available').then(function () {
                    _this5.log('chat - advisor available update success');
                    _this5.setChatOnline();

                    _this5.pubnubClient.isSubscribed().then(function (isSubscribed) {
                        if (!isSubscribed) {
                            _this5.log('MonitorClient after setAvailable is not subscribed. Call subscribe()');
                            _this5.pubnubClient.subscribe();
                        }
                    });
                });
            }
        }, {
            key: 'setUnavailable',
            value: function setUnavailable() {
                var _this6 = this;

                this._updateAvailability(this.advisorId, 'unavailable').then(function () {
                    _this6.log('chat - _updateAvailability to unavailable success');
                    _this6.setChatOffline();
                }).catch(function (error) {
                    return _this6.logError('Error during updateAvailability to unavailable', error);
                });
            }
        }, {
            key: '_subscribeSuccess',
            value: function _subscribeSuccess() {
                this.log('chat - advisor subscribe success.');
            }
        }, {
            key: '_subscribeFail',
            value: function _subscribeFail() {
                this.log('Subscribe Fail');
            }
        }, {
            key: '_unsubscribeSuccess',
            value: function _unsubscribeSuccess() {
                this.log('chat - _unsubscribeSuccess');
            }
        }, {
            key: 'setChatOnline',
            value: function setChatOnline() {
                var _this7 = this;

                this.log('MonitorClient.setChatOnline()');
                this.isOnline = true;
                if (this.onConnect) {
                    Ember.run(function () {
                        _this7.onConnect();
                    });
                }
                this.log('MonitorClient.setChatOnline() finish');
            }
        }, {
            key: 'setChatOffline',
            value: function setChatOffline() {
                var _this8 = this;

                this.log('MonitorClient.setChatOffline()');
                this.isOnline = false;
                if (this.onDisconnect) {
                    Ember.run(function () {
                        _this8.onDisconnect();
                    });
                }
                this.log('MonitorClient.setChatOffline() finish');
            }
        }, {
            key: 'setCallOnline',
            value: function setCallOnline() {
                var _this9 = this;

                this.log('MonitorClient.setCallOnline');
                this.callIsOnline = true;

                if (this.onCallConnect) {
                    Ember.run(function () {
                        return _this9.onCallConnect();
                    });
                }
                this.log('MonitorClient.setCallOnline finish');
            }
        }, {
            key: 'setCallOffline',
            value: function setCallOffline() {
                var _this10 = this;

                this.log('MonitorClient.setCallOffline');
                this.callIsOnline = false;
                this.pullAvailability = true;

                if (this.onCallDisconnect) {
                    Ember.run(function () {
                        return _this10.onCallDisconnect();
                    });
                }
                this.log('MonitorClient.setCallOffline finish');
            }
        }, {
            key: '_disconnectLogOff',
            value: function _disconnectLogOff() {
                this.log('MonitorClient._disconnectLogOff()');
                this.isOnline = false;
                if (this.onDisconnectLogOff) {
                    this.onDisconnectLogOff();
                }
                this.log('MonitorClient._disconnectLogOff() finish');
            }
        }, {
            key: '_clearReservedMessage',
            value: function _clearReservedMessage() {
                if (this.clearReservedMessage) {
                    this.clearReservedMessage();
                }
            }
        }, {
            key: '_resubscribeSuccess',
            value: function _resubscribeSuccess() {
                var _this11 = this;

                //If the advisor is already put offline by timeout callback on server,
                //make him offline on chat monitor also.

                this.log('MonitorClient._resubscribeSuccess() invoked');

                this.store.findRecord('advisorChatAvailability', this.advisorId, { reload: true }).then(function (availability) {
                    if (availability.get('availability').toLowerCase() !== 'available') {
                        if (_this11.isOnline) {
                            _this11._disconnectLogOff();
                            _this11.log('logging off after reconnect');
                        } else {
                            _this11.log('resubscribeSuccess: advisor is already offline');
                        }
                    } else if (availability.get('availability').toLowerCase() === 'available') {
                        _this11.setChatOnline();
                        _this11.log('logging on after reconnect');
                    } else {
                        _this11.log('resubscribeSuccess: unexpected availability - ' + availability.get('availability'));
                    }
                }).catch(function (error) {
                    _this11.logError('Error during resubscribeSuccess call.', error);
                    throw error;
                });
            }
        }, {
            key: '_messageReceivedV2',
            value: function _messageReceivedV2(msg) {
                var _this12 = this;

                if (!this.isOnline) {
                    this.log('Attempt to process message ' + JSON.stringify(msg) + ' with doNotUnsubscribe mode when monitor is offline');
                    return false;
                }
                this.log('MonitorClient._messageReceived2(' + JSON.stringify(msg) + ')');

                var data = msg.Data;

                if (!data) {
                    this.log('No Data to process');
                    return false;
                }

                var messageId = msg.MessageId;

                if (messageId) {
                    var latency = msg.ReceivedTimestamp - msg.ServerTimestamp;

                    this.log('ChatAnalysis - Command: ' + data.eventType + ', MessageId: ' + messageId + '. Latency: ' + latency + '.');

                    //TODO: clarify should we increse 80 sec chat request expiration?
                    if (latency > 80 * 1000) {
                        this.log('Too old Chat monitor message received. SKIP IT. Latency = ' + latency + ', message = ' + JSON.stringify(msg));
                        return true;
                    }
                }

                if (data.eventType === 'ChatInitiatedEvent') {
                    var sessionId = data.sessionId;

                    if (this.sessionId !== null) {
                        this.log('InitiateChat : ' + ('Attempt to initiate session with Id: ' + sessionId + ' ; ') + ('Active session: ' + this.sessionId + ' was not cleared'));
                    }
                    this.logData.sessionId = sessionId;
                    this.log('advisor received InitiateChat request for sessionId: ' + data.sessionId);

                    if (this.sessionId === sessionId) {
                        this.log('InitiateChat : ' + ('Attempt to initiate same session by repeated request: ' + sessionId));
                        return true;
                    }

                    this.sessionId = sessionId;
                    var advisorId = data.advisorId;
                    var consumerId = data.consumerId;
                    var listingId = data.listingId;

                    if (!this.onChatRequestReceived) {
                        this.log('InitiateChat: onChatRequestReceived callback is empty');
                        return false;
                    }

                    this.onChatRequestReceived({
                        sessionId: this.sessionId,
                        advisorId: advisorId,
                        consumerId: consumerId,
                        listingId: listingId
                    });
                    return true;
                } else if (data.eventType === 'ChatEndedEvent' && this.sessionId === data.sessionId) {
                    this.log('advisor received EndChat request.');
                    if (!this.onChatEnded) {
                        this.log('InitiateChat: onChatEnded is empty');
                        return false;
                    }
                    //we need fire onChatEnded with small delay to avoid race conditions during NetworkUp event
                    //it is important because we can receive 'EndChat' request before 'InitiateChat'
                    Ember.run.later(function () {
                        return _this12.onChatEnded();
                    }, 1000);
                    this.sessionId = null;
                    this.logData.sessionId = null;
                    return true;
                } else if (data.eventType === 'ChatRequestRespondedEvent') {
                    if (data.status === 'Accepted') {
                        if (!this.onChatAccepted) {
                            this.log('InitiateChat: onChatAccepted is empty');
                            return false;
                        }
                        this.onChatAccepted();
                        return true;
                    }

                    if (data.status === 'Declined') {
                        if (!this.onChatDeclined) {
                            this.log('InitiateChat: onChatDeclined is empty');
                            return false;
                        }
                        this.onChatDeclined();
                        return true;
                    }
                }

                return false;
            }
        }, {
            key: '_callMessageReceived',
            value: function _callMessageReceived(msg) {
                if (!this.callIsOnline) {
                    this.log('Attempt to process message ' + JSON.stringify(msg) + ' with doNotUnsubscribe mode when call monitor is offline');
                    return;
                }

                this.log('MonitorClient._callMessageReceived(' + JSON.stringify(msg) + ')');

                if (msg.ReceivedTimestamp && msg.ServerTimestamp) {
                    var latency = msg.ReceivedTimestamp - msg.ServerTimestamp;

                    if (latency > 80 * 1000) {
                        this.log('Too old Call Monitor message received. SKIP IT. Latency = ' + latency + ', message = ' + JSON.stringify(msg));
                        return;
                    }
                }

                if (msg) {
                    this.logData.callId = msg.CallId;
                    this.log('on call status update received: callId: ' + msg.CallId + ', callStatusId: ' + msg.CallStatusId);
                    if (this.onCallStatusUpdateReceived) {
                        this.onCallStatusUpdateReceived(msg);
                    }
                }
            }
        }, {
            key: '_lockMessageReceived',
            value: function _lockMessageReceived(msg) {
                this.log('MonitorClient._lockMessageReceived(' + JSON.stringify(msg) + ')');

                if (msg.ReceivedTimestamp && msg.ServerTimestamp) {
                    var latency = msg.ReceivedTimestamp - msg.ServerTimestamp;

                    if (latency > 80 * 1000) {
                        this.log('Too old Call/Chat Monitor message received. SKIP IT. Latency = ' + latency + ', message = ' + JSON.stringify(msg));
                        return;
                    }
                }
                if (msg) {
                    if (this.onLockStatusUpdateReceived) {
                        this.onLockStatusUpdateReceived(msg);
                    }
                }
            }
        }, {
            key: '_updateAvailability',
            value: function _updateAvailability(advisorId, availability) {
                var _this13 = this;

                this.log('MonitorClient._updateAvailability(' + advisorId + ', ' + availability);
                return this.store.findRecord('advisorChatAvailability', this.advisorId, { reload: true }).then(function (a) {
                    a.set('availability', availability);
                    a.set('availabilityUpdateSource', 'Web');
                    return a.save();
                }).catch(function (error) {
                    _this13.logError('Error during updateAvailability call.', error);
                    throw error;
                });
            }
        }, {
            key: '_updateAuthKeyCountDown',
            value: function _updateAuthKeyCountDown() {
                var _this14 = this;

                var seconds = 1000 * 60 * 60 * 6; //authkey for 6 hours
                //var seconds = 1000 * 30; //for test

                var interval = setInterval(function () {
                    _this14.log('Updating auth key');

                    Ember.run(function () {

                        var pubnubMonitorChannel = _this14.store.createRecord('pubnubMonitorChannel');

                        pubnubMonitorChannel.save().then(function (monitorChannel) {
                            var newAuthKey = monitorChannel.get('channel.authKey');

                            _this14.pubnubClient.setNewAuthKey(newAuthKey);
                            _this14.log('Updated auth key');
                        }).catch(function (error) {
                            _this14.logError('Updating auth key.', error);
                            throw error;
                        });
                    });
                }, seconds);

                return interval;
            }
        }, {
            key: '_setInitialStatus',
            value: function _setInitialStatus() {
                var availability = this.availability.get('availability');

                if (availability) {
                    availability = availability.toLowerCase();
                }

                if (availability === 'unavailable') {
                    this.log('initial status is unavailable');
                    this.setChatOffline();
                } else if (availability === 'available') {
                    this.log('initial status is available');
                    this.setChatOnline();
                }
            }
        }, {
            key: '_onBeforeunload',
            value: function _onBeforeunload() {
                var _this15 = this;

                $(window).bind('beforeunload', function () {
                    if (_this15.isOnline) {
                        var controller = _this15.route.get('controller');
                        var chatOnlineSubstatus = controller.chatOnlineSubstatus;
                        var chatDecisionIsExpected = controller.get('chatDecisionIsExpected');

                        if (chatDecisionIsExpected) {
                            return ''; //we need to show confirmation dialog only if chatDecisionIsExpected
                        }

                        _this15.log('Chat monitor window beforeunload. Closing session. Chat decision expected?: ' + chatDecisionIsExpected + '. Online sub-status: ' + chatOnlineSubstatus);
                        //this.unsubscribe(true);
                        clearInterval(_this15.updateAuthKeyCountDown);
                        _this15.pubnubClient.unsubscribe(true);

                        //TODO: find a way to do sync ajax call with help of Ember.DS
                        var user = Ember.Application.user;
                        var url = Ember.ENV.getApiRoot() + '/users/' + user.id + '/chat/availabilities';

                        $.ajax({
                            url: url,
                            async: false,
                            type: 'PUT',
                            data: { advisorChatAvailability: { availability: 'Unavailable', availabilityUpdateSource: 'Web' } }
                        });

                        //return 'Are you sure you want close Monitor?';
                    } else {
                        _this15.log('Chat monitor window beforeunload. Already offline.');
                    }
                });
            }
        }, {
            key: 'startConnectionStatusMonitor',
            value: function startConnectionStatusMonitor() {
                var _this16 = this;

                setInterval(function () {
                    return _this16.pubnubClient.isSubscribed().then(function (isSubscribed) {
                        if (!isSubscribed) {
                            _this16.log('MonitorClient is not subscribed. Call subscribe()');
                            _this16.pubnubClient.subscribe();
                        }

                        console.log('MonitorClient is subscribed = ' + isSubscribed + ', call is online = ' + _this16.callIsOnline + ', chat is online = ' + _this16.isOnline);

                        // if (this.offlineLog && this.offlineLog.length > 0) {
                        //     for (let index = 0; index < this.offlineLog.length; index++) {
                        //         this.log(this.offlineLog[index]);
                        //     }
                        // }
                        // this.offlineLog = [];
                    }).catch(function (error) {
                        console.error(error);
                        //this.offlineLog.push(`MonitorClient network was down at ${new Date().toISOString()}`)
                    });
                }, 5 * 60 * 1000);
            }
        }]);

        return MonitorClient;
    }();

    exports.default = MonitorClient;
});