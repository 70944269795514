define('ingenio-web/models/taf-referral-stat', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        qualifiedReferralCount: (0, _attr.default)('number'),

        earnedCredits: (0, _attr.default)('number')
    });
});