define('ingenio-web/components/checkout-flow/add-funds/component', ['exports', 'ingenio-web/objects/conversation-payment-methods'], function (exports, _conversationPaymentMethods) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        preferredCurrency: Ember.computed('Ember.Application.user.preferredCurrency', function () {
            return Ember.Application.user.preferredCurrency;
        }),

        actions: {
            // the 0-index price bucket is set to $0.00 for users who have sufficient funds to connect.
            // for auto-reload, we will toggle back to the $0.00 price point if the user does not need to
            // deposit funds to continue with the conversation & reset back to the first bucket for auto-reload
            // conversely.
            onConversationPaymentMethodChanged: function onConversationPaymentMethodChanged(method) {
                // if a user has provided a custom amount, we will ignore the price bucket resetting &
                // continue with the specified custom amount.
                if (!this.attrs.enteredAmount && this.attrs.onAutoReloadIncreaseTypeChanged) {
                    switch (method) {
                        case _conversationPaymentMethods.default.AutoReload:
                            this.attrs.onAutoReloadIncreaseTypeChanged(1);
                            break;

                        case _conversationPaymentMethods.default.PayBeforeConversation:
                            this.attrs.onAutoReloadIncreaseTypeChanged(this.attrs.isAddFundsRequired ? 1 : 0);
                            break;
                    }
                }
                this.attrs.onConversationPaymentMethodChanged(method);
            }
        }

    });
});