define('ingenio-web/components/phone-number/list/component', ['exports', 'ingenio-web/models/phone-types', 'ingenio-web/mixins/validatable', 'ingenio-web/mixins/scroll-helpers'], function (exports, _phoneTypes, _validatable, _scrollHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PhoneMode = {
    National: 0,
    International: 1
  };

  var PreferredPhoneCountryCodes = ['US', 'CA', 'GB'];
  var IncludedPhoneCountryCodes = ['US', 'CA', 'AU', 'AT', 'BH', 'CL', 'DK', 'FI', 'FR', 'DE', 'IS', 'IN', 'IE', 'IT', 'JP', 'KR', 'LU', 'MX', 'NL', 'AN', 'NZ', 'NO', 'ZA', 'SE', 'TW', 'AE', 'GB', 'BE'];

  exports.default = Ember.Component.extend(_validatable.default, _scrollHelpers.default, {
    classNames: ['phone-number-list-container'],

    multiEditMode: false,
    showChangePhonesLink: false,
    phoneMode: PhoneMode.National,
    defaultSelectedPhoneTypeId: 0,

    disableSingleEntryModeControls: Ember.computed('attrs.phoneNumbers.@each', 'disabled', function () {
      return this.get('disabled') || !Ember.isEmpty(this.get('phoneNumbers'));
    }),

    phoneModeLabel: Ember.computed('phoneMode', function () {
      return this.get('phoneMode') === PhoneMode.National ? 'International Phone Numbers' : 'U.S. and Canada Phone Numbers';
    }),

    includedPhoneCountriesFiltered: Ember.computed('phoneMode', function () {
      var _this = this;

      return IncludedPhoneCountryCodes.filter(function (country) {
        return _this.get('phoneMode') === PhoneMode.National ? country === 'US' || country === 'CA' : country !== 'US' && country !== 'CA';
      });
    }),

    preferredCountriesFiltered: Ember.computed('phoneMode', function () {
      var _this2 = this;

      return PreferredPhoneCountryCodes.filter(function (country) {
        return _this2.get('phoneMode') === PhoneMode.National ? country === 'US' || country === 'CA' : country !== 'US' && country !== 'CA';
      });
    }),

    phones: Ember.computed('attrs.phoneNumbers.@each', 'phoneMode', 'multiEditMode', function () {
      var _this3 = this;

      return _phoneTypes.default.map(function (phoneType) {
        var phone = _this3.get('attrs.phoneNumbers').find(function (phone) {
          return phone.get('phoneType') === phoneType.id && (!_this3.get('multiEditMode') || phone.get('isUSNumber') === (_this3.get('phoneMode') === PhoneMode.National));
        }) || {};

        return {
          type: phoneType,
          number: {
            id: Ember.get(phone, 'id'),
            isUSNumber: Ember.get(phone, 'isUSNumber'),
            countryCode: Ember.get(phone, 'countryCode'),
            isPrimary: Ember.get(phone, 'isPrimary') || false,
            number: !Ember.isEmpty(Ember.get(phone, 'countryCode')) && !Ember.isEmpty(Ember.get(phone, 'number')) ? '+' + Ember.get(phone, 'countryCode') + Ember.get(phone, 'number') : ''
          }
        };
      });
    }),

    primaryPhone: Ember.computed('selectedPhoneTypeId', 'phones.@each', function () {
      var _this4 = this;

      return this.get('phones').find(function (phone) {
        return Ember.get(phone, 'type.id') === _this4.get('selectedPhoneTypeId');
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.initializeValidationByPhoneType();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setPhoneMode();
      this.setSelectedPhoneType();
    },


    actions: {
      editPhoneNumbers: function editPhoneNumbers() {
        this.scrollToElementOnMobileViewWithDelay('.multi-phone-container', 30);
        this.set('multiEditMode', true);
        this.attrs.onEditPhoneNumbersClick();
      },
      changePhoneMode: function changePhoneMode() {
        this.set('phoneMode', this.get('phoneMode') === PhoneMode.National ? PhoneMode.International : PhoneMode.National);

        this.setSelectedPhoneType();
      },
      cancel: function cancel() {
        // reset new phone entries
        this.notifyPropertyChange('phones');
        this.attrs.onEditPhoneNumbersCancel();

        // validation state should be re-initialized as the phones collection has been mutated.
        this.initializeValidationByPhoneType();

        this.setPhoneMode();
        this.setSelectedPhoneType();
        this.set('multiEditMode', false);
      },
      onPhoneValidityChange: function onPhoneValidityChange(state) {
        // fixes a race condition where the validity change event bubbles
        // before the phone type is bound in the child component.
        if (!state.phoneType) {
          return;
        }

        this.sendPhoneNumberChangeEvent();
        this.syncValidationStateByPhoneType(state.phoneType, state.valid, state.hasValue);

        var phoneTypeId = Ember.get(_phoneTypes.default.find(function (type) {
          return type.name === state.phoneType;
        }), 'id');

        if (!state.hasValue && this.get('selectedPhoneTypeId') === phoneTypeId) {
          this.setInvalid('Please provide a valid ' + state.phoneType + ' number.');
        } else {
          this.validate();
        }
      },
      onPrimaryPhoneChange: function onPrimaryPhoneChange(primaryPhone) {
        var validationState = this.get('validationStateByPhoneType').find(function (state) {
          return state.phoneType === primaryPhone.type.name;
        });

        if (!validationState.valid || !validationState.hasValue) {
          this.setInvalid('Please provide a valid ' + primaryPhone.type.name + ' number.');
        } else {
          this.validate();
        }

        this.get('phones').forEach(function (phone) {
          return Ember.set(phone, 'number.isPrimary', primaryPhone.type.id === phone.type.id);
        });

        this.set('selectedPhoneTypeId', primaryPhone.type.id);

        // since the primary phone number is tightly coupled to the underlying
        // phone number model... propagate the change upward as a collection mutation.
        this.sendPhoneNumberChangeEvent();
      },
      onPhoneKeyDown: function onPhoneKeyDown(event) {
        if (event.target && /\d/.test(event.key)) {
          var targetPhoneType = $(event.target).parents('[data-phonetypeid]:first');

          if (targetPhoneType && Ember.isPresent(targetPhoneType.data('phonetypeid'))) {
            this.set('selectedPhoneTypeId', targetPhoneType.data('phonetypeid'));

            this.send('onPrimaryPhoneChange', this.get('primaryPhone'));
          }
        }
      }
    },

    validate: function validate() {
      var hasAtLeastOneValidNumber = this.get('validationStateByPhoneType').any(function (state) {
        return state.valid && state.hasValue;
      });

      var validityErrorsDetected = this.get('validationStateByPhoneType').any(function (state) {
        return !state.valid;
      });

      if (validityErrorsDetected || !hasAtLeastOneValidNumber) {
        this.setInvalid('At least one valid phone number is required.');
      } else {
        this.setValid();
      }
    },
    sendPhoneNumberChangeEvent: function sendPhoneNumberChangeEvent() {
      this.attrs.onPhoneNumberChange(this.get('phones'));
    },


    // if the user has existing phone numbers, determine the current mode
    // based on the existing numbers they have (only all U.S. or all non-U.S. numbers).
    setPhoneMode: function setPhoneMode() {
      if (Ember.isEmpty(this.get('phoneNumbers'))) {
        this.set('phoneMode', PhoneMode.National);
        return;
      }

      if (this.get('phoneNumbers').any(function (phone) {
        return phone.get('isUSNumber');
      })) {
        this.set('phoneMode', PhoneMode.National);
      } else {
        this.set('phoneMode', PhoneMode.International);
      }
    },
    setSelectedPhoneType: function setSelectedPhoneType() {
      var _this5 = this;

      var primaryNumber = this.get('phones').find(function (phone) {
        return phone.number.isPrimary;
      });

      if (Ember.isEmpty(primaryNumber)) {
        var defaultPhone = this.get('phones').find(function (phone) {
          return phone.type.id === _this5.get('defaultSelectedPhoneTypeId');
        });

        Ember.set(defaultPhone, 'number.isPrimary', true);
        this.set('selectedPhoneTypeId', this.get('defaultSelectedPhoneTypeId'));
      } else {
        this.set('selectedPhoneTypeId', primaryNumber.type.id);
      }
    },
    initializeValidationByPhoneType: function initializeValidationByPhoneType() {
      var _this6 = this;

      this.set('validationStateByPhoneType', _phoneTypes.default.map(function (phoneType) {
        var phone = _this6.get('phones').find(function (phone) {
          return Ember.get(phone, 'type.id') === phoneType.id;
        });

        return {
          valid: true,
          hasValue: !Ember.isEmpty(Ember.get(phone, 'number.number')),
          phoneType: phoneType.name
        };
      }));
    },
    syncValidationStateByPhoneType: function syncValidationStateByPhoneType(phoneType, validityState, hasValue) {
      var validationState = this.get('validationStateByPhoneType').find(function (state) {
        return state.phoneType === phoneType;
      });

      if (validationState) {
        Ember.set(validationState, 'hasValue', hasValue);
        Ember.set(validationState, 'valid', validityState);
      }
    }
  });
});