define('ingenio-web/controllers/chat/voice-to-text-test', ['exports', 'ingenio-web/controllers/chat/session-base'], function (exports, _sessionBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _sessionBase.default.extend({
        note: null,
        showConsumerDetails: false,
        status: 'ready',
        chatting: true,
        messages: Ember.A(),

        setup: function () {
            var _this = this;

            Ember.run.later(function () {
                _this.addMessage({ text: 'We suggest using a headphone set for best results.' }, false);
            }, 1000);
            Ember.run.later(function () {
                _this.addMessage({ text: 'Click microphone to start voice to text.' }, false);
            }, 3000);
            Ember.run.later(function () {
                _this.addMessage({ text: 'Click arrow to send message.' }, false);
            }, 4000);
        }.observes('model').on('init'),

        actions: {
            endSessionSelf: function endSessionSelf() {
                this.set('chatting', false);

                $('.footer').show();
                this.set('showChatFooter', true);

                this.hideTyping();

                this.set('status', 'ended');
            }
        }
    });
});