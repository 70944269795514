define('ingenio-web/models/chat-collision', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        listingId: (0, _attr.default)('number'),
        advisorId: (0, _attr.default)('number'),
        collisionType: (0, _attr.default)('string'),
        source: (0, _attr.default)('number'),
        postPay: (0, _attr.default)('boolean'),
        clickId: (0, _attr.default)('string')
    });
});