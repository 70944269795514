define('ingenio-web/appointment/phone-number/route', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/route', 'ingenio-web/utils/footer-types'], function (exports, _route, _footerTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    defineFooterType: function defineFooterType() {
      return _footerTypes.default.MINIMAL;
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
    },
    skipDetailsRedirect: function skipDetailsRedirect() {
      return true;
    }
  });
});