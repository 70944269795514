define('ingenio-web/routes/myaccount/feedback', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/models/grid/pagination-info', 'ingenio-web/models/myaccount/feedback/filtration-params'], function (exports, _headerTypes, _footerTypes, _resetScrollOnTransition, _paginationInfo, _filtrationParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        defineHeaderType: function defineHeaderType() {
            if (Ember.ENV.domainId === 6) {
                return _headerTypes.default.MINIMAL;
            }
            return _headerTypes.default.FULL;
        },

        defineFooterType: function defineFooterType() {
            if (Ember.ENV.domainId === 6) {
                return _footerTypes.default.MINIMAL;
            }
            return _footerTypes.default.FULL;
        },

        model: function model() {
            var filters = _filtrationParams.default.create();
            return Ember.RSVP.hash({
                listings: this.get('store').findAll('advisor-listing')
            }).then(function (result) {
                filters.set("listings", result.listings);
                return {
                    pagination: _paginationInfo.default.create(),
                    filters: filters
                };
            });;
        },

        afterModel: function afterModel(model) {
            var listings = model.filters.listings.content;

            if (listings.length > 0) {
                model.filters.selectedListingId = listings[0].id;
            }
        },

        actions: {
            didTransition: function didTransition() {
                Ember.run.next(this, 'initTooltip');
            }
        },
        initTooltip: function initTooltip() {
            Ember.$('[data-toggle="tooltip"]').tooltip();
        }
    });
});