define('ingenio-web/components/user-phone-numbers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this.$('.extension').inputmask({ mask: '9[99999]', greedy: false });
            this.handleCountryType();
        },

        countryCodeClasses: function () {
            if (this.get('user.isUSPhone')) {
                return 'form-control country-code hidden';
            }
            return 'form-control country-code';
        }.property('user.isUSPhone'),

        handleCountryType: function () {
            if (this.get('user.isUSPhone')) {
                this.$('.phone-number').inputmask({ mask: '(999) 999-9999' });
            } else {
                this.$('.phone-number').inputmask({ mask: '999999[99999999]', greedy: false });
            }

            this.$('.country-code').inputmask({ mask: '9[99]', greedy: false });
            this.$('.extension').inputmask({ mask: '[999999]', greedy: false });
        }.observes('user.isUSPhone'),

        actions: {
            save: function save() {
                if (this.get('user.isValid')) {
                    this.sendAction();
                }
            }
        }
    });
});