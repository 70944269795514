define('ingenio-web/models/member-picture-info', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr'], function (exports, _fragment, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        fileName: (0, _attr.default)('string'),
        directoryName: (0, _attr.default)('string'),
        url: (0, _attr.default)('string'),
        width: (0, _attr.default)('number'),
        height: (0, _attr.default)('number'),
        alternateText: (0, _attr.default)('string')
    });
});