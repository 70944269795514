define('ingenio-web/models/customers/create-new-list-criteria', ['exports', 'ingenio-web/models/criteria-base'], function (exports, _criteriaBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _criteriaBase.default.extend({
    listName: '',

    validate: function () {
      this.clearErrors();
      var listName = this.get('listName') || '';

      if (listName.length === 0) {
        this.addError('listName', 'List name is required.');
      } else if (listName.length < 5) {
        this.addError('listName', 'List name should be longer than 4 chars.');
      } else if (listName.length > 60) {
        this.addError('listName', 'List name should be shorter than 60 chars.');
      }
    }.observes('listName')
  });
});