define('ingenio-web/components/transaction-details/pay-details/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			$(this.element).find('.help-icon-container .fa-info-circle').popover({
				content: function content() {
					return $("#helpIconPopover").html();
				},
				trigger: "manual",
				placement: "top",
				html: true
			}).click(function (e) {
				$(this).popover("show");
				e.stopPropagation();
			});
		},

		seamlessPayHelpUrl: function () {
			if (Ember.ENV.domainId === 1) {
				return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360053785933-Seamless-Pay';
			}
			return '/help/seamlesspay';
		}.property(),

		actions: {
			closeHelpIconPopover: function closeHelpIconPopover() {
				$(".popover").popover("hide");
			}
		},

		balanceUsed: function () {
			var balanceUsed = this.get('model.balanceUsed');

			return balanceUsed.toFixed(2);
		}.property('model.balanceUsed'),

		autoReloadTotal: function () {
			var autoReloadTotal = this.get('model.autoReloadTotal');

			return autoReloadTotal.toFixed(2);
		}.property('model.autoReloadTotal'),

		unusedAutoReloadFunds: function () {
			var autoReloadBalanceLeft = this.get('model.autoReloadBalanceLeft');

			return autoReloadBalanceLeft.toFixed(2);
		}.property('model.autoReloadBalanceLeft'),

		// TODO: rename seamlessPayDetails to postPayDetails when it'll be possible to update data contract in apps
		displayPayDetails: function () {
			var balanceUsed = this.get('model.balanceUsed');
			var postPayDetails = this.get('model.seamlessPayDetails');

			return balanceUsed || postPayDetails;
		}.property('model.balanceUsed', 'model.seamlessPayDetails')
	});
});