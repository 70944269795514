define('ingenio-web/chat-setup/confirmation/controller', ['exports', 'ingenio-web/mixins/checkout-flow/confirmation/controller', 'ingenio-web/mixins/checkout-flow/chat-availability-helper'], function (exports, _controller, _chatAvailabilityHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_chatAvailabilityHelper.default, {
    userEvents: Ember.inject.service(),
    log_source: 'chat-flow-confirmation', //TODO: can be dynamically arrived from the parent route name?

    isKeen: Ember.computed(function () {
      return Ember.ENV.domainId === 1;
    }),

    chatSetup: Ember.inject.controller(),
    steps: Ember.computed.alias('chatSetup.steps'),
    arrangeChatLogging: Ember.inject.service('arrange-chat-logging'),

    arrangeChat: Ember.computed.alias('arrange'),

    enablePrimaryButton: Ember.computed('primaryButtonBusy', 'model.accountBalanceWithExtraFundsMeetsMinimum', function () {
      if (this.get('primaryButtonBusy')) {
        return false;
      }

      return this.get('model.accountBalanceWithExtraFundsMeetsMinimum');
    }),

    showChangeFundsLink: Ember.computed('primaryButtonBusyQS', function () {
      return !this.get('primaryButtonBusyQS');
    }),

    checkUserAndRedirect: function checkUserAndRedirect(queryParams) {
      var _this = this;

      var newUser = Ember.Application.user.firstBilledMinuteDate === null;
      if (newUser && this.get('isKeen')) {
        // Only for new non fbm users
        return new Ember.RSVP.Promise(function (resolve, _) {
          return resolve(_this.redirectToCollisionRecomendationPage(queryParams));
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, _) {
          return resolve(_this.redirectToAvailabilityErrorPage(queryParams));
        });
      }
    },

    actions: {
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this2 = this;

        this.clearErrors();
        var user = Ember.Application.user;
        var listingId = this.get('model.listing.id');

        if (this.get('model.customerWillBeCharged') && user.isAvsReqd) {
          var selectedCreditCard = this.get('model.paymentMethodSelected');
          var recheckAVSOnCancel = this.get('avsModel.recheckAVS') && this.get('avsModel.recheckAVS') == true;
          if (recheckAVSOnCancel || !this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
            this.showConfirmDepositDialog(user.isAvsReqd);

            //reset recheck state.
            var avsModel = this.get('avsModel');
            if (avsModel) {
              avsModel.set('recheckAVS', false);
            }

            return;
          }
        }

        this.set('primaryButtonBusy', true);

        this.get('mixpanelClient').trackNewEvent('Chat Confirmation - Chat Now Button Clicked', { eventData: { listing_id: listingId } });
        this.checkAvailabilityAndRedirectIfNotAvailable().then(function (availabilityCheck) {
          if (availabilityCheck) {
            return _this2.validateOffer().then(function (returnToStep) {
              if (returnToStep === 'return-to-details-step') {
                _this2.send('onUserOfferDeleted');
                _this2.set('primaryButtonBusy', false);
                _this2.transitionToRouteWithCurrentParams(_this2.getParentRouteName() + '.details');
              } else {
                _this2.addFundsToAccount().then(function () {
                  return _this2.initiateChat(availabilityCheck);
                }).catch(function (error) {
                  if (error.code == "CvnFailed" || error.code == "BothCvnAvsFailed") {
                    user.isCvnReqd = true;
                    _this2.set('model.paymentMethodSelected.cvvCode', null);
                  }
                  if (error.responseJSON && error.responseJSON.displayChangePaymentMethodPopUp) {
                    _this2.toggleShowChangePaymentMethodPopUpCommon();
                  } else {
                    _this2.handleError(error);
                  }
                  _this2.set('primaryButtonBusy', false);
                  _this2.get('logger').error('Problem in adding funds. userId=' + user.id, {
                    originalError: error
                  });
                });
              }
            });
          }
        });
      },
      onFundsAmountChanged: function onFundsAmountChanged(amount) {
        this.set('model.user.funds.additionalAmount', amount);
      },
      toggleShowChangePaymentMethodPopUp: function toggleShowChangePaymentMethodPopUp() {
        this.toggleShowChangePaymentMethodPopUpCommon();
      },
      onClickChangePaymentMethod: function onClickChangePaymentMethod() {
        this.toggleShowChangePaymentMethodPopUpCommon();

        this.transitionToPaymentDetails();
      }
    },

    checkAvailabilityAndRedirectIfNotAvailable: function checkAvailabilityAndRedirectIfNotAvailable() {
      var _this3 = this;

      // returning an Ember promise instead of a promise chain.
      // for some reason, ember is not resolving nested promises & instead returns an instance of a promise.

      return new Ember.RSVP.Promise(function (resolve, _) {
        var advisorId = _this3.get('model.listing.advisorId');

        _this3.checkChatAvailability(advisorId).then(function (result) {
          var chatAvailability = result.chatAvailability;

          _this3.set('model.chatAvailability', chatAvailability);

          if (!_this3.get('model.chatAvailable')) {
            var busy = _this3.get('model.chatArrange') || _this3.get('model.chatBusy');
            var queryParams = _this3.get('queryParamKeyValuePairs');

            queryParams.busy = busy;

            // note: we also track similar late collisions in the initiate REST endpoint.
            // this tracking was added to capture late collisions caught before the initiate endpoint is called.
            _this3.trackLateCollision().finally(function () {

              if (Ember.ENV.domainId === 6) {
                _this3.set('primaryButtonBusy', false);
                _this3.transitionToRoute('chat-setup.availability-error', { queryParams: queryParams });
              } else {
                _this3.checkUserAndRedirect(queryParams).then(function (result) {
                  if (result.isNewTest) {
                    return resolve(result);
                  } else {
                    window.location.href = result.redirectUrl;
                    _this3.set('primaryButtonBusy', false);
                    return resolve(false);
                  }
                });
              }
            });
          } else {
            resolve(true);
          }
        });
      });
    },


    objectToQueryString: function objectToQueryString(obj) {
      var str = [];
      for (var p in obj) {
        if (obj[p] !== undefined) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }
      return str.join("&");
    },

    redirectToAvailabilityErrorPage: function redirectToAvailabilityErrorPage(queryParams) {
      var queryString = this.objectToQueryString(queryParams);
      var redirectUrl = "/advisor-availability?" + queryString;
      return { redirectUrl: redirectUrl };
    },

    redirectToCollisionRecomendationPage: function redirectToCollisionRecomendationPage(queryParams) {
      queryParams.listingtype = 1;
      var queryString = this.objectToQueryString(queryParams);

      var baseUrl = '/collision-recommendation';
      var redirectUrl = baseUrl + '?' + queryString;
      return { redirectUrl: redirectUrl, isNewTest: true, baseUrl: baseUrl, queryString: queryString };
    },

    initiateChat: function initiateChat(availabilityCheck) {
      if (this.get('arrangeChat')) {
        this.transitionToRouteWithCurrentParams('chat.arrange');
      } else {
        // append chat guid to existing query params collection.
        var queryParams = this.get('queryParamKeyValuePairs');
        queryParams.guid = this.getGuidForChat();

        if (this.get('model.payAfterConversationSelected')) {
          queryParams.postPay = true;
          queryParams.source = 'chat-setup';
        } else {
          queryParams.postPay = false;
        }

        if (this.get('model.autoReloadSelected')) {
          var additionalAmount = Math.round(this.get('model.user.funds.additionalAmount') * 100);

          queryParams.autoReload = additionalAmount;
          queryParams.source = 'chat-setup';
        } else {
          delete queryParams.autoReload;
        }

        if (this.get('model.arrangedChat')) {
          var arrangeId = this.get('model.arrangedChat.id');
          queryParams.arrangeid = arrangeId;
        }

        if (availabilityCheck && typeof availabilityCheck !== 'boolean' && availabilityCheck.baseUrl) {
          var advname = this.get('model.listing.advisorName');
          queryParams.advname = advname;
          queryParams.listingtype = 1;
          if (availabilityCheck.queryString.busy) {
            queryParams.busy = availabilityCheck.queryString.busy;
          }
          var queryString = this.objectToQueryString(queryParams);
          var redirectUrl = availabilityCheck.baseUrl + '?' + queryString;
          window.location.href = redirectUrl;
          this.set('primaryButtonBusy', false);
        } else {
          this.transitionToRoute('chat.initiate', {
            queryParams: queryParams
          });
        }
      }

      this.set('primaryButtonBusy', false);
    },
    getGuidForChat: function getGuidForChat() {
      var timestamp = new Date().getTime();

      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
        var variant = (timestamp + Math.random() * 16) % 16 | 0; //eslint-disable-line no-bitwise

        return character === 'x' ? variant : (variant & 0x3 | 0x8).toString(16); //eslint-disable-line no-bitwise
      });
    },
    trackLateCollision: function trackLateCollision() {
      var _this4 = this;

      var queryParams = this.get('queryParamKeyValuePairs');

      var collisionEvent = this.store.createRecord('chatCollision', {
        collisionType: 'Late',
        source: queryParams.webtrack || 0,
        listingId: this.get('model.listing.id'),
        advisorId: this.get('model.listing.advisorId'),
        postPay: this.get('model.payAfterConversationSelected'),
        clickId: queryParams.clickid
      });

      return collisionEvent.save().catch(function (error) {
        // eslint-disable-next-line no-console
        console.log('Error saving collision event!', error);
        var data = collisionEvent.toJSON();
        _this4.get('logger').error('Error saving collision event!', data, error);
      });
    },
    toggleShowChangePaymentMethodPopUpCommon: function toggleShowChangePaymentMethodPopUpCommon() {
      this.toggleProperty('showChangePaymentMethodPopUp');
    }
  });
});