define('ingenio-web/adapters/advisor-offer-participation', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL() {
            return Ember.ENV.getApiRoot() + '/advisors/' + Ember.Application.user.id + '/offerParticipation';
        },

        handleResponse: function handleResponse(status, headers, payload, requestData) {

            if (payload) {
                var payloadWithId = {
                    advisorOfferParticipation: Object.assign(payload, {
                        id: Ember.Application.user.id
                    })
                };

                return this._super(status, headers, payloadWithId, requestData);
            }
            return this._super.apply(this, arguments);
        }
    });
});