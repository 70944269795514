define('ingenio-web/adapters/phone-number', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      var userId = Ember.Application.user.id;

      return Ember.ENV.getApiRoot() + '/users/' + userId + '/phones';
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      //TODO: This should be avoided if we had got the API wellformed :(
      if (payload && payload.userPhone && status === 200) {
        payload = {
          phoneNumber: payload.userPhone
        };
      }

      return this._super(status, headers, payload, requestData);
    }
  });
});