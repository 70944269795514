define('ingenio-web/routes/featured-listings/bid/confirm-bid', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        featuredListingsBid: Ember.computed(function () {
            return this.modelFor('featured-listings.bid');
        }),

        beforeModel: function beforeModel() {
            if (!this.get('featuredListingsBid.listing.id')) {
                this.transitionTo('featured-listings.bid');
            }
        },
        model: function model() {
            var featuredListingsBid = this.get('featuredListingsBid');
            var bidExpirationType = Ember.get(featuredListingsBid, 'bid.budgetAmount') > 0.00 ? 'budget' : 'no-expiration';

            return {
                bid: Ember.get(featuredListingsBid, 'bid'),
                bidExpirationType: bidExpirationType,
                cancelBid: Ember.get(featuredListingsBid, 'cancelBid'),
                listing: {
                    id: Ember.get(featuredListingsBid, 'listing.id'),
                    title: Ember.get(featuredListingsBid, 'listing.title'),
                    category: Ember.get(featuredListingsBid, 'listing.category')
                }
            };
        },


        actions: {
            didTransition: function didTransition() {
                this.send('setCurrentStep', this.routeName);

                // Set title dependent on if the bid is being created/updated or canceled.
                var title = this.get('featuredListingsBid.cancelBid') ? 'Cancel Your Featured Listing Bid' : 'Confirm Your Featured Listing Bid';

                this.set('featuredListingsBid.steps.currentStep.title', title);

                this.get('mixpanelClient').trackNewEvent('FL Confirm Bid Page Viewed', {
                    eventData: {
                        listing_id: this.get('featuredListingsBid.listing.id')
                    }
                });

                return true;
            }
        }
    });
});