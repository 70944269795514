define('ingenio-web/models/model-base', ['exports', 'ember-data/model', 'lodash'], function (exports, _model, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        allTouched: false,
        inProcess: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('validationErrors', []);
            this.set('touched', ['generic-error']);
            this.set('allTouched', false);
            this.set('inProcess', false);

            if (typeof this.validate === 'function') {
                this.validate();
            }
        },


        validationState: function () {
            return {
                valid: !this.hasError('client-side'),
                invalid: this.hasError('client-side'),
                onlyOneError: this.getErrors('client-side').length === 1,
                numberOfErrors: this.getErrors('client-side').length,
                message: this.errorMessage('client-side')
            };
        }.property('validationErrors.length'),

        generalErrorsState: function () {
            return Ember.Object.create({
                invalid: this.hasError(),
                message: this.errorMessage()
            });
        }.property('validationErrors.length'),

        clearErrors: function clearErrors() {
            this.get('validationErrors').clear();
        },

        clearGenericErrors: function clearGenericErrors() {
            this.removeErrors('generic-error');
        },

        markAllTouched: function markAllTouched() {
            this.set('allTouched', true);
        },

        clearTouched: function clearTouched() {
            this.set('touched', ['generic-error']);
            this.set('allTouched', false);
        },

        addTouched: function addTouched(attribute) {
            if (!attribute) {
                //disabling ESLint's re-assign warning, since I am afraid to touch this code
                attribute = 'generic-error'; //eslint-disable-line
            }
            var touched = this.get('touched').find(function (attr) {
                return attr === attribute;
            });
            if (!touched) {
                this.get('touched').addObject(attribute);
            }
        },

        isTouched: function isTouched(attribute) {
            if (this.get('allTouched')) {
                return true;
            }

            if (!attribute) {
                return this.touched.length > 0;
            }

            return this.get('touched').includes(attribute);
        },

        addError: function addError(attribute) {
            var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            // jshint ignore:line
            if (!attribute) {
                //disabling ESLint's re-assign warning, since I am afraid to touch this code
                attribute = 'generic-error'; //eslint-disable-line
            }

            var error = this.get('validationErrors').find(function (err) {
                return err.attribute === attribute;
            });
            if (error) {
                error.message = msg;
            } else {
                error = { attribute: attribute, message: msg };
                this.get('validationErrors').pushObject(error);
            }
        },

        getErrors: function getErrors(attribute) {
            if (!attribute) {
                //disabling ESLint's re-assign warning, since I am afraid to touch this code
                attribute = 'generic-error'; //eslint-disable-line
            }

            if (attribute === 'all') {
                return this.get('validationErrors');
            } else if (attribute === 'client-side') {
                return this.get('validationErrors').filter(function (item) {
                    return item.attribute !== 'generic-error';
                });
            }

            return this.get('validationErrors').filterBy('attribute', attribute);
        },

        removeErrors: function removeErrors(attribute) {
            var _this = this;

            var self = this;
            var errors = self.getErrors(attribute);

            if (errors && errors.length > 0) {
                errors.forEach(function (item) {
                    _this.get('validationErrors').removeObject(item);
                });
            }
        },

        handleError: function handleError(xhr) {
            var self = this;
            var message = '';

            this.markAllTouched();
            this.set('inProcess', false);

            if (xhr.status && xhr.status === 401) {
                throw xhr; //propagate 401 up, it should be handled globally
            }

            if (xhr.responseJSON && xhr.responseJSON.errors) {
                //disabling ESLint check (since it's a serialized POCO object and oesn't require check for hasOwnProps)
                for (var prop in xhr.responseJSON.errors) {
                    if (xhr.responseJSON.errors.hasOwnProperty(prop)) {
                        //eslint-disable-line guard-for-in
                        for (var i = 0; i < xhr.responseJSON.errors[prop].length; i++) {
                            self.addError(prop, xhr.responseJSON.errors[prop][i]);
                        }

                        self.addTouched(prop);
                    }
                }
                return;
            } else if (xhr.errors && _lodash.default.isArray(xhr.errors)) {
                for (var _i = 0; _i < xhr.errors.length; _i++) {
                    self.addError(null, xhr.errors[_i].detail);
                }
                return;
            } else if (xhr.errors) {
                //disabling ESLint check (since it's a serialized POCO object and oesn't require check for hasOwnProps)
                for (var _prop in xhr.errors) {
                    if (xhr.errors.hasOwnProperty(_prop)) {
                        //eslint-disable-line guard-for-in
                        for (var _i2 = 0; _i2 < xhr.errors[_prop].length; _i2++) {
                            self.addError(_prop, xhr.errors[_prop][_i2]);
                        }

                        self.addTouched(_prop);
                    }
                }
                return;
            } else if (xhr.responseJSON && xhr.responseJSON.Message) {
                message = xhr.responseJSON.Message;
            } else if (xhr.responseJSON && xhr.responseJSON.message) {
                message = xhr.responseJSON.message;
            } else if (xhr.responseText) {
                message = xhr.responseText;
            } else {
                message = 'Your request could not be completed due to a system error. Please try your request again or contact customer support for assistance.';
            }

            this.addError(null, message);
        },

        hasError: function hasError(attribute) {
            var errors = this.getErrors(attribute);

            return errors.length > 0;
        },

        errorMessage: function errorMessage(attribute) {
            var self = this;
            var result = '';
            var errors = self.getErrors(attribute);

            if (errors.length > 0) {
                errors.forEach(function (item) {
                    if (result === '') {
                        result = item.message;
                    } else {
                        result = result + ', ' + item.message;
                    }
                });
            }

            return result;
        },

        isDirty: function () {
            return this.get('hasDirtyAttributes');
        }.property('hasDirtyAttributes'),

        isInvalid: function () {
            return this.getErrors('client-side').length > 0;
        }.property('validationErrors.length'),

        isValid: function () {
            return this.getErrors('client-side').length === 0;
        }.property('validationErrors.length'),

        canSave: function () {
            return this.get('isValid') && this.get('isDirty');
        }.property('validationErrors.length', 'isDirty'),

        disableSave: function () {
            return !this.get('canSave');
        }.property('canSave')

    });
});