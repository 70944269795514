define('ingenio-web/components/info-text', ['exports', 'ingenio-web/templates/components/info-text'], function (exports, _infoText) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _infoText.default,
        tagName: 'span',

        classNames: ['info-text'],

        didInsertElement: function didInsertElement() {
            var message = this.get('help');
            var position = this.get('position') || 'bottom';
            var action = this.get('action') || 'hover';

            if (message && message.length > 0) {
                $(this.element).find('.glyphicon').popover({ content: message, trigger: action, placement: position, html: true });
            }
        },

        actions: {
            displayHelp: function displayHelp() {
                var link = this.get('link');

                if (link && link.length > 0) {
                    window.open(link, this.get('name'), 'width=600,height=400,top=20,left=200,scrollbars');
                }
            }
        }
    });
});