define('ingenio-web/routes/support/deactivate-account', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types'], function (exports, _headerTypes, _footerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        defineFooterType: function defineFooterType() {
            return _footerTypes.default.FULL;
        }
    });
});