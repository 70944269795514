define('ingenio-web/models/direct-message', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        message: (0, _attr.default)('string'),
        fromUserId: (0, _attr.default)('number'),
        toUserId: (0, _attr.default)('number'),
        isRead: (0, _attr.default)('boolean'),
        date: (0, _attr.default)('date'),
        toUserName: (0, _attr.default)('string'),
        fromUserName: (0, _attr.default)('string'),
        toUserImageUrl: (0, _attr.default)('string'),
        fromUserImageUrl: (0, _attr.default)('string')
    });
});