define('ingenio-web/models/advisor-call-availability', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        currentCallAvailabilityId: (0, _attr.default)('number'),
        callAvailabilities: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        })
    });
});