define('ingenio-web/helpers/format-date', ['exports', 'moment'], function (exports, _moment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatDate(_ref) {
    var _moment;

    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (!value || value.startsWith('0001-01-01')) return '-';
    return (_moment = (0, _moment2.default)(value)).format.apply(_moment, _toConsumableArray(rest));
  }

  exports.default = Ember.Helper.helper(formatDate);
});