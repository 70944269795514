define('ingenio-web/components/add-funds/currency-input/component', ['exports', 'ingenio-web/mixins/validatable', 'ingenio-web/mixins/keyboard-events'], function (exports, _validatable, _keyboardEvents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_keyboardEvents.default, _validatable.default, {
    classNames: ['add-funds-currency-input-container'],

    focusedOut: false,
    amountTouched: false,
    currency: '$',
    currencyText: '',

    didReceiveAttrs: function didReceiveAttrs() {
      this.validate();
    },
    didRender: function didRender() {
      var fundsInput = this.$().find('input:first');

      //for some reason we need to set initial value manully
      var amount = this.get('amount');

      if (amount) {
        this.set('amountTouched', true);
        this.set('focusedOut', true);
        fundsInput.val(amount);
      }

      // prepopulate minimum deposit amount if funds are required
      if (this.get('minimumDepositAmount') && this.get('isAddFundsRequired') && !this.get('amountTouched') && !fundsInput.val()) {
        this.attrs.onFundsAmountChanged(this.get('minimumDepositAmount').toFixed(2));
      }

      var currency = this.get('currency').toLowerCase();
      if (currency == "au$") {
        this.set('currencyText', 'aud');
      } else if (currency == "ca$") {
        this.set('currencyText', 'cad');
      } else if (currency == "£") {
        this.set('currencyText', 'bp');
      }
    },
    keyDown: function keyDown() {
      this.allowKeys(event, [this.keyType.ctrl, this.keyType.numeric, this.keyType.special, this.keyType.decimal]);
    },
    keyUp: function keyUp(event) {
      var targetValue = Ember.get(event, 'target.value');

      // don't propagate the change if the number is not a valid decimal.
      if (!/^\d*\.?\d{0,2}$/.test(targetValue)) {
        Ember.set(event, 'target.value', this.get('amount'));
        return;
      }

      this.set('amountTouched', true);
      this.attrs.onFundsAmountChanged(targetValue);
    },
    focusOut: function focusOut() {
      this.set('focusedOut', true);
      this.validate();
    },


    validate: function validate() {
      var amount = Number(this.get('amount'));
      var isAddFundsRequired = this.get('isAddFundsRequired');
      var minimumDepositAmount = this.get('minimumDepositAmount');
      var maximumDepositAmount = this.get('maximumDepositAmount');
      var currency = this.get('currency');

      // only perform validation after the user first touches the input field.
      // the controller handles determining if the primary CTA should be enabled or disabled per rules.
      // this only ensures that the validation messaging is silenced on the additional load.
      if (!this.get('amountTouched') || !this.get('focusedOut')) {
        return;
      }

      if (!amount && isAddFundsRequired) {
        return this.setInvalid('This field is required.');
      }

      if (amount && (!this.isValidAmount(amount) || isNaN(amount))) {
        return this.setInvalid('Invalid amount entered.');
      }

      // ignore a 0 dollar amount if the user is not required to add funds.
      if (amount && amount < minimumDepositAmount && (isAddFundsRequired || !isAddFundsRequired && amount !== 0)) {
        return this.setInvalid('Amount must be a minimum of ' + currency + minimumDepositAmount.toFixed(2));
      }

      if (amount && amount > maximumDepositAmount) {
        return this.setInvalid('Amount must be no greater than ' + currency + maximumDepositAmount.toFixed(2));
      }

      this.setValid();
    },

    isValidAmount: function isValidAmount(amount) {
      return (/^(\d+)?([.]?\d{0,3})?/.test(amount)
      );
    }
  });
});