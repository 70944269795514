define('ingenio-web/utils/footer-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Object.freeze({
        FULL: 'full-footer',
        CUSTOM: 'custom-footer',
        COLLAPSIBLE_CUSTOM: 'collapsible-custom-footer',
        MINIMAL: 'minimal-footer',
        COLLAPSIBLE_MINIMAL: 'collapsible-minimal-footer',
        EMPTY: 'empty-footer',
        ACQUISITION: 'acquisition-footer',
        BEST_MATCH_MINIMAL: 'best-match-minimal-footer'
    });
});