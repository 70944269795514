define('ingenio-web/routes/listings/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function redirect(model, transition) {
            var queryParams = transition.queryParams;


            this.set('queryParams', queryParams);
            this.createListingDraft();
        },


        ensureUserHasCreditCard: function ensureUserHasCreditCard() {
            var apiRoot = Ember.ENV.getApiRoot();
            var userId = Ember.Application.user.id;

            return Ember.$.getJSON(apiRoot + '/users/' + userId + '/payment-methods').then(function (response) {
                var paymentMethods = response.paymentMethods;

                var paymentMethodsTotalCount = response.TotalCount;

                if (paymentMethodsTotalCount === 0) {
                    window.location.href = window.location.origin + '/Secure/Payments/Pay/AddPaymentMethod.aspx?mode=1&returnUrl=' + encodeURIComponent(window.location.href);
                    this.reject();
                }

                return paymentMethods;
            });
        },

        ensureUserIsActive: function ensureUserIsActive(callBack) {
            return Ember.$.getJSON(Ember.ENV.getApiRoot() + ('/users/' + Ember.Application.user.id + '/activeDate')).then(function (response) {
                callBack(response);
            });
        },

        createListingDraft: function createListingDraft() {
            var _this = this;

            var newDraft = this.store.createRecord('listing-draft');

            var options = {};
            var queryParams = this.get('queryParams');

            if (queryParams) {
                options.queryParams = queryParams;
            }

            this.ensureUserIsActive(function (response) {
                if (!response) {
                    if (!options.queryParams || !options.queryParams.returnUrl) {
                        window.location.href = '/give-advice/get-started?withCheck=true';
                        return;
                    }

                    if (!options.queryParams) {
                        options.queryParams = {};
                    }
                    options.queryParams.returnUrl = '/give-advice/get-started?withCheck=true';
                }

                newDraft.set('domainId', Ember.ENV.domainId);
                newDraft.save().then(function () {
                    _this.replaceWith('listing/draft', newDraft, options);
                });
            });
        }
    });
});