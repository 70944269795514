define('ingenio-web/mixins/checkout-flow/conversation-payment-method-route-check', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        afterModel: function afterModel(model, transition) {
            var checkoutFlowModel = this.checkoutFlowModel();
            if (checkoutFlowModel.get('isUserEligibleForConversationPostPay') && !checkoutFlowModel.get('conversationPaymentMethod') && checkoutFlowModel.get('showAddMinutes') && !checkoutFlowModel.get('quickStart')) {
                this.replaceWith(this.getParentRouteName() + '.details', {
                    queryParams: transition.queryParams
                });
            }
            this._super.apply(this, arguments);
        }
    });
});