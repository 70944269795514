define('ingenio-web/models/chat-post-pay-session-extension', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        sessionId: (0, _attr.default)('number'),
        amount: (0, _attr.default)('number'),
        availableTimeSeconds: (0, _attr.default)('number'),
        remainingDuration: (0, _attributes.fragment)('remainingDuration'),
        eventSource: (0, _attr.default)('string', {
            defaultValue: 'WebSite'
        }),
        additionalFundsInfo: (0, _attributes.fragment)('additionalFundsInfo')
    });
});