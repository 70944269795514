define('ingenio-web/add-funds/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            this.replaceWith('add-funds.details', {
                queryParams: transition.queryParams
            });
        }
    });
});