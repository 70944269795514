define("ingenio-web/appointment/step-helpers", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ["listingid", "pmode", "webtrack", 'clickid', "arrange", "r", "ctype", "time", "survrespid", "surveyid", "apttypeid", "aptduration", "steptype", "mode", "apptid"],

        steps: Ember.computed('appointment.steps', 'surveyid', function () {
            var surveyid = this.get('surveyid');
            var steps = this.get('appointment.steps').filter(function (step) {
                if (surveyid) {
                    return true;
                }
                return step.get('name') !== 'Survey';
            });
            return steps;
        })
    });
});