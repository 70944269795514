define('ingenio-web/controllers/offer/not-qualified', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['returnUrl'],
        returnUrl: null,

        actions: {
            continue: function _continue() {
                window.location.replace(decodeURIComponent(this.get('returnUrl')));
            }
        }
    });
});