define('ingenio-web/transforms/address', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            return serialized;
        },

        serialize: function serialize(deserialized) {
            return deserialized;
        }
    });
});