define("ingenio-web/arrange-conversation/create/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    mixpanelClient: Ember.inject.service("mixpanel-client"),

    arrangeConversationFlowModel: Ember.computed(function () {
      return this.modelFor(this.getParentRouteName());
    }),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      // just in case if user doesn't have arranged call - direct user to the confirmation page
      if (this.get("arrangeConversationFlowModel.advisorConversationRequest")) {
        var message = "\n        <div class=\"alert-icon\"><i class=\"far fa-exclamation-triangle\"></i></div>\n        <div class=\"message-content\">\n          <div class=\"message-content-item\"> You already have an arranged call with the advisor.</div> \n        </div>";
        this.get("arrangeConversationFlowModel.notifications.success").push(message);

        this.replaceWith(this.getParentRouteName() + ".confirmation", {
          queryParams: transition.queryParams
        });
      } else {
        var listingId = transition.queryParams.listingid;
        var convType = 'CALL';
        if (transition.queryParams.ctype && transition.queryParams.ctype.toUpperCase() === 'CHAT') {
          convType = 'CHAT';
        }

        return Ember.RSVP.hash({
          coversationRequest: Ember.$.ajax(Ember.ENV.getApiRoot() + "/graphql", {
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify({
              operationName: 'MakeCoversationRequest',
              query: "mutation MakeCoversationRequest($conversationRequest:MakeAdvisorConversationRequest!){requestAdvisorConversation(request:$conversationRequest){id queuePosition requestedAt expiresAt requestedBy{id userName}requestedFor{id userName}forConversationType estimatedWaitTime onListing{id title}status}}",
              variables: {
                "conversationRequest": {
                  "userId": Ember.Application.user.id,
                  "listingId": listingId,
                  "forConversationType": convType,
                  "isSourceWeb": true
                }
              }
            })
          })
        }).then(function (payload) {
          // handle the payload
          if (payload.coversationRequest.errors) {
            payload.coversationRequest.errors.forEach(function (error) {
              _this.get("arrangeConversationFlowModel.notifications.errors").push(error.message);
            });
            _this.replaceWith(_this.getParentRouteName() + ".details", {
              queryParams: transition.queryParams
            });
          } else if (payload.coversationRequest.data && payload.coversationRequest.data.requestAdvisorConversation) {
            var advisorConversationRequest = payload.coversationRequest.data.requestAdvisorConversation;
            advisorConversationRequest.existing = false;
            _this.set("arrangeConversationFlowModel.advisorConversationRequest", advisorConversationRequest);

            var _message = "\n            <div class=\"alert-icon\"><i class=\"fal fa-check-circle\"></i></div>\n            <div class=\"message-content\">\n              <div class=\"message-content-header\"><b>Success!</b></div> \n              <div class=\"message-content-item\">You're now on the waitlist.</div> \n            </div>";
            _this.get("arrangeConversationFlowModel.notifications.success").push(_message);

            if (_this.get('arrangeConversationFlowModel.isChatConversation')) {

              _this.get('mixpanelClient').trackNewEvent('Arrange Chat Requested', {
                eventData: {
                  source: 'ACC',
                  conversation_type: _this.get('arrangeConversationFlowModel.conversationType'),
                  arrange_id: _this.get('arrangeConversationFlowModel.advisorConversationRequest.id'),
                  listing_id: listingId
                }
              });

              // if advisor is online (queuePosition is null) show notification
              if (_this.get("arrangeConversationFlowModel.advisorConversationRequest.queuePosition") == null) {
                var _message2 = "\n                <div class=\"alert-icon\"><i class=\"fal fa-check-circle\"></i></div>\n                <div class=\"message-content\">\n                  <div class=\"message-content-header\"><b>Your advisor is Available!</b></div> \n                  <div class=\"message-content-item\">The advisor you choose is available. Click the button to start conversation.</div> \n                </div>";
                _this.get("arrangeConversationFlowModel.notifications.success").push(_message2);
              }
            } else {
              _this.get('mixpanelClient').trackNewEvent('Arrange Call Requested', {
                eventData: {
                  source: 'ACC',
                  conversation_type: _this.get('arrangeConversationFlowModel.conversationType'),
                  arrange_id: _this.get('arrangeConversationFlowModel.advisorConversationRequest.id'),
                  listing_id: listingId
                }
              });
            }

            _this.replaceWith(_this.getParentRouteName() + ".confirmation", {
              queryParams: transition.queryParams
            });
          } else {
            _this.get("arrangeConversationFlowModel.notifications.errors").push("Unknown error during arranging the callback.");
            _this.replaceWith(_this.getParentRouteName() + ".details", {
              queryParams: transition.queryParams
            });
          }
        }).catch(function (response) {
          if (response && response.responseJSON && response.responseJSON.errors) {
            // handle the errors
            response.responseJSON.errors.forEach(function (error) {
              _this.get("arrangeConversationFlowModel.notifications.errors").push(error.message);
            });
            _this.replaceWith(_this.getParentRouteName() + ".details", {
              queryParams: transition.queryParams
            });
          } else {
            _this.get("arrangeConversationFlowModel.notifications.errors").push("Unknown error during cancel the callback.");

            _this.replaceWith(_this.getParentRouteName() + ".details", {
              queryParams: transition.queryParams
            });
          }
        });
      }
    }
  });
});