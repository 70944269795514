define('ingenio-web/video/session-ended/controller', ['exports', 'ingenio-web/utils/html-utils'], function (exports, _htmlUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['sessionId'],
    feedbackSubmitted: false,
    feedbackDismissed: false,
    canLeaveFeedback: Ember.computed('isRateable', 'feedbackStatus', function () {
      var feedbackStatus = this.get('feedbackStatus');
      var isRateable = this.get('isRateable');
      var response = feedbackStatus.Response;
      return isRateable && response === 0;
    }),

    isAdvisor: Ember.computed('Ember.Application.user', function () {
      return Ember.Application.user.isAdvisor;
    }),

    isPP: Ember.computed('listing.categoryId', function () {
      var listingCategoryId = this.get('listing.categoryId');
      return listingCategoryId === 17101;
    }),

    isIC: Ember.computed('listing.categoryId', function () {
      var listingCategoryId = this.get('listing.categoryId');
      return listingCategoryId === 17011;
    }),

    messageToDisplay: Ember.computed('endReason', 'failureReason', 'videoSessionInfo', function () {
      var title = 'Video Call Has Ended';
      var msg = '';
      var displayGreenTickView = false;
      var failureReason = this.get('failureReason');
      var endReason = this.get('endReason');
      var videoSessionInfo = this.get('videoSessionInfo');

      var advisorName = videoSessionInfo.advisorName || '';
      var consumerName = videoSessionInfo.consumerName || '';
      var isAdvisor = Ember.Application.user.isAdvisor;

      var contactSupportLink = '<a href="https://www.keen.com/help/support" target="_blank">contact support</a>';

      if (isAdvisor) {
        // Advisor
        if (failureReason === 'AdvisorEnded') {
          title = 'The Video Call Was Successfully Ended';
          msg = 'You left the session early. Your customer will be notified that the session is no longer active.';
          displayGreenTickView = true;
        }

        if (endReason === 'SystemEnded') {
          msg = 'Your session with ' + consumerName + ' has ended.';
        }

        if (endReason === 'ConsumerDisconnected') {
          msg = consumerName + ' was unable to reconnect within 5 min and the session has ended.';
        }

        if (endReason === 'ConsumerEnded') {
          msg = consumerName + ' has ended the call.';
        }

        if (endReason === 'AdvisorEnded') {
          msg = 'Your session with ' + consumerName + ' has ended.';
        }
      } else {
        // Consumer
        if (failureReason === 'ConsumerEnded') {
          msg = 'You left the session early and have been charged in full. If this was an error please ' + contactSupportLink + '.';
        }

        if (endReason === 'SystemEnded') {
          msg = 'Thanks for connecting with ' + advisorName + '.';
        }
        if (endReason === 'ConsumerEnded') {
          msg = 'Thanks for connecting with ' + advisorName + '.';
        }

        if (endReason === 'AdvisorDisconnected') {
          msg = 'Your coach was unable to reconnect within 5 min. Please ' + contactSupportLink + ' if your session time was incomplete.';
        }

        if (endReason === 'AdvisorEnded') {
          msg = 'Your coach ended the call early. Please ' + contactSupportLink + ' if your session time was incomplete.';
        }
      }

      return { title: title, description: msg, displayGreenTickView: displayGreenTickView };
    }),

    actions: {
      sendPing: function sendPing(recipientId) {
        window.location.href = '/app/#/direct-messages/recipient/' + recipientId;
      },
      sendEmail: function sendEmail(userName) {
        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: '0',
            to: userName,
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },


      dismissFeedback: function dismissFeedback() {
        this.set('feedbackDismissed', true);
      },

      onFeedbackSubmission: function onFeedbackSubmission() {
        this.set('feedbackSubmitted', true);
      }
    }
  });
});