define('ingenio-web/components/chat/arrange-notification', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isKeen: function () {
            return Ember.ENV.domainId === 1;
        }.property()
    });
});