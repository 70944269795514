define('ingenio-web/serializers/credit-card-new', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        extractId: function extractId() {
            return this._super.apply(this, arguments) || 1;
        },
        payloadKeyFromModelName: function payloadKeyFromModelName() {
            return 'creditCard';
        },
        modelNameFromPayloadKey: function modelNameFromPayloadKey() {
            return 'creditCardNew';
        }
    });
});