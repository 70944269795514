define('ingenio-web/models/prepare-appointment', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        fees: (0, _attr.default)(),
        totalAmount: (0, _attr.default)()
    });
});