define('ingenio-web/mixins/error-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),
    dummyPropForHotfix: {},

    errors: {},
    hasErrors: false,
    defaultErrorMessage: 'Your request could not be completed due to a system error. Please try your request again or contact customer support for assistance.',

    addError: function addError(attributeName) {
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      /*
        This method adds and groups the errors based on the attribute name in the following format.
        errors :{
            generic: [
                {message: 'Generic error1 here'}
                {message: 'Generic error2 here'}
            ],
            cardNumber: [
                {message: 'Invalid credit card number provided'},
                {message: 'Invalid credit card different error here'}
            ],
            postalCode: [
                {message: 'Invalid postal code'}
            ]
        }
         where, 
          generic => holds all generic or page level errors
          cardNumber, postalCode => hold all field specific errors
        */
      if (!attributeName) {
        attributeName = 'generic';
      }

      if (!this.errors[attributeName]) {
        this.errors[attributeName] = [];
      }
      this.errors[attributeName].pushObject({
        message: message
      });
    },
    clearErrors: function clearErrors() {
      this.errors = {};
      this.hasErrors = false;
      this.flashMessages.set('queue', []);
    },
    displayFlashMessage: function displayFlashMessage() {
      var errorsCollection = this.get('errors');

      if (errorsCollection && Object.keys(errorsCollection)) {
        var firstKey = Object.keys(errorsCollection)[0];

        this.flashMessages.danger(Ember.get(errorsCollection, firstKey + '.firstObject.message'), {
          sticky: true,
          showProgress: true,
          extendedTimeout: 500,
          destroyOnClick: false
        });
      }
    },
    handleError: function handleError(errorObj) {
      if (errorObj.status && errorObj.status === 401) {
        throw errorObj; //propagate 401 up, it should be handled globally
      }

      if (errorObj.responseJSON && errorObj.responseJSON.errors) {
        //errorObj is mostly an xhr object which comes in regular AJAX call
        //disabling ESLint check (since it's a serialized POCO object and oesn't require check for hasOwnProps)
        for (var prop in errorObj.responseJSON.errors) {
          if (errorObj.responseJSON.errors.hasOwnProperty(prop)) {
            //eslint-disable-line guard-for-in
            for (var i = 0; i < errorObj.responseJSON.errors[prop].length; i++) {
              this.addError(prop, errorObj.responseJSON.errors[prop][i] || this.get('defaultErrorMessage'));
            }
          }
        }
      } else if (errorObj.responseJSON && errorObj.responseJSON.message) {
        this.addError(null, errorObj.responseJSON.message);
      } else if (errorObj.errors && _.isArray(errorObj.errors)) {
        //In case of ember data, promise rejection returns ember Error class which contains errors[]
        for (var _i = 0; _i < errorObj.errors.length; _i++) {
          this.addError(null, (_.isArray(errorObj.errors[_i].detail) ? errorObj.errors[_i].detail[0].detail : errorObj.errors[_i].detail) || this.get('defaultErrorMessage'));
        }
      } else if (errorObj.errors) {
        //disabling ESLint check (since it's a serialized POCO object and oesn't require check for hasOwnProps)
        for (var _prop in errorObj.errors) {
          if (errorObj.errors.hasOwnProperty(_prop)) {
            //eslint-disable-line guard-for-in
            for (var _i2 = 0; _i2 < errorObj.errors[_prop].length; _i2++) {
              this.addError(_prop, errorObj.errors[_prop][_i2] || this.get('defaultErrorMessage'));
            }
          }
        }
      } else if (errorObj.responseJSON && errorObj.responseJSON.Message) {
        //errorObj is mostly an xhr object which comes in regular AJAX call
        this.addError(null, errorObj.responseJSON.Message);
      } else if (errorObj.responseJSON && errorObj.responseJSON.message) {
        //errorObj is mostly an xhr object which comes in regular AJAX call
        this.addError(null, errorObj.responseJSON.message);
      } else if (errorObj.responseText) {
        //errorObj is mostly an xhr object which comes in regular AJAX call
        this.addError(null, errorObj.responseText);
      } else {
        this.addError(null, this.get('defaultErrorMessage'));
      }

      this.displayFlashMessage();
    }
  });
});