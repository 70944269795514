define('ingenio-web/adapters/advisor-metric', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL() {
            var userId = Ember.Application.user.id;

            return Ember.ENV.getApiRoot() + '/featured-listings/users/' + userId + '/advisor-quality-metrics';
        },

        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

            if (payload) {
                payload.advisorMetric = payload.advisorMetrics;
                delete payload.advisorMetrics;
            }

            return this._super.apply(this, arguments);
        }
    });
});