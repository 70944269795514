define('ingenio-web/components/deactivate-account-advisor/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var domainName = 'Keen';
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            var user = Ember.Application.user;
            this.set('user', user);
        },


        store: Ember.inject.service(),
        user: null,

        displayDeactivateAccountConfirmationModal: false,
        displayCloseAccountSuccessModal: false,
        deactivateAccountStatus: null,

        logoutUser: function logoutUser() {
            var url = '/login/logout';
            Ember.$.post(url, {}).done(function (data) {
                console.log('Logged out successfully!');
            }).fail(function (reason) {
                console.log('Error logging out user: ', reason);
                //navigate the user to the home page.
                window.location.href = '/';
            });
        },


        actions: {
            confirmDeactivateAccount: function confirmDeactivateAccount() {
                this.set('displayDeactivateAccountConfirmationModal', true);
            },
            cancel: function cancel() {
                this.set('displayDeactivateAccountConfirmationModal', false);
                if (this.get("onCancel")) {
                    this.get("onCancel")();
                }
            },
            deactivateAccount: function deactivateAccount() {
                var _this = this;

                console.log("Will attempt to deactivate account");
                var user = this.get('user');
                var additionalFields = {};
                var userId = user.id;
                var url = Ember.ENV.getApiRoot() + '/help/support';
                var data = {
                    additionalFields: '',
                    userId: userId,
                    description: "",
                    email: user.email,
                    isAuthenticated: true,
                    source: 1,
                    subtopic: "Close Account - Deactivate Account",
                    topic: "Account Management",
                    userType: user.isAdvisor ? "advisor" : "consumer",
                    webSourceDetail: window.location.origin
                };
                Ember.$.post(url, data).done(function (reason) {
                    console.log('Response: ', reason);
                    console.log('Processing Account Deactivation...');
                    _this.set('deactivateAccountStatus', 'processing');
                    // this.logoutUser();
                }).fail(function (reason) {
                    console.log('Error submitting deleting account request: ', reason);
                    _this.set('deactivateAccountStatus', 'failure');
                    _this.get('loggerService').error('Error Submitting Deactivate Account Request', {}, reason);
                });
            }
        }
    });
});