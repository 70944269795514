define('ingenio-web/utils/cookies', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    //gets any cookie by it's name (based on http://stackoverflow.com/a/25490531/119493)
    function _getCookie(name, cookies) {
        //noinspection JSCheckFunctionSignatures (disables WebStorm warning for type mismatch string vs regex)

        var value = (cookies || document.cookie).match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

        return value ? decodeURIComponent(value.pop()) : '';
    }

    //gets keen cookie as object
    function getParsedKeenCookie() {
        /* just an example of keen cooke object, example of the cookie value itself:
         KeenCookie={'UserName':'Restuta','Uid':'rpF6p4efIsuAfqn4eeNUqw==','IsProvider':false,'Name':'Anton we','UserId':54120413}
         we trust that it's a JSON object for now, there can be more properties
         var keenCookie = {
            UserName: '',
            Uid: '',
            IsProvider: '',
            Name: '',
            UserId: ''
         };
         */

        var keenCookieStr = _getCookie('KeenCookie');

        return keenCookieStr ? JSON.parse(keenCookieStr.replace('KeenCookie=', '')) : '';
    }

    exports.default = {
        getUserId: function getUserId() {
            var keenCookie = getParsedKeenCookie();

            //todo restuta: +1 to Muthu
            //TODO: Muthu - figure out on mocking cookie data when running in proto mode
            if (Ember.ENV.name === 'proto') {
                return 1;
            }

            return keenCookie.UserId;
        },

        getSift: function getSift() {
            return _getCookie('SiftSessionId');
        },
        getDashboardMode: function getDashboardMode() {
            return _getCookie('DashboardToolMode');
        },


        isCloverFlow: function isCloverFlow() {
            var cookie = this.getGroupCookieUnescaped('CLOVER', 'Enabled');

            return cookie && parseInt(cookie) > 0;
        },

        getSessionId: function getSessionId(cookies) {
            return _getCookie('SessionId', cookies);
        },

        // fixes an issue caused by an infinite redirect where SessionID generation fails
        // but user is authenticated. 
        getAuthSessionRedirectCount: function getAuthSessionRedirectCount() {
            var cookieValue = this.getCookie('EmberAuthRedirectCount');

            if (isNaN(cookieValue)) {
                return 1;
            }

            return Number(cookieValue);
        },

        updateAuthSessionRedirectCount: function updateAuthSessionRedirectCount() {
            var cookieValue = this.getAuthSessionRedirectCount();

            this.createCookie('EmberAuthRedirectCount', cookieValue + 1, null, true);
        },

        clearAuthSessionRedirectCount: function clearAuthSessionRedirectCount() {
            this.eraseCookie('EmberAuthRedirectCount');
        },

        getCookie: function getCookie(name) {
            return _getCookie(name);
        },

        getGroupCookie: function getGroupCookie(name, part) {
            var value = '; ' + document.cookie;
            var parts = value.split('; ' + name + '=');

            if (parts.length === 2) {
                var cookie = parts.pop().split(';').shift();

                if (cookie) {
                    cookie = '&' + cookie;
                    var subparts = cookie.split('&' + part + '=');

                    if (subparts.length === 2) {
                        return subparts.pop().split('&').shift();
                    }
                }
            }
            return null;
        },
        getGroupCookieUnescaped: function getGroupCookieUnescaped(name, part) {
            var value = this.getGroupCookie(name, part);

            if (value) {
                return window.unescape(this.getGroupCookie(name, part));
            }

            return null;
        },


        createCookie: function createCookie(name, value, days, secure, sameSite) {
            var cookieValue = name + '=' + value + '; path=/';

            if (days) {
                var date = new Date();

                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                cookieValue += '; expires=' + date.toGMTString();
            }

            if (sameSite && window.location.hostname !== 'localhost') {
                cookieValue += '; SameSite=' + sameSite;
            }

            if (secure && window.location.hostname !== 'localhost') {
                cookieValue += '; Secure';
            }

            document.cookie = cookieValue;
        },

        readCookie: function readCookie(name) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];

                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length);
                }

                if (c.indexOf(nameEQ) === 0) {
                    return c.substring(nameEQ.length, c.length);
                }
            }
            return null;
        },

        eraseCookie: function eraseCookie(name) {
            this.createCookie(name, '', -1);
        },

        getDynamicYieldID: function getDynamicYieldID() {
            return _getCookie('_dyid');
        },

        getDynamicYieldSession: function getDynamicYieldSession() {
            return _getCookie('_dyjsession');
        }
    };
});