define('ingenio-web/services/impression-tracker', ['exports', 'ingenio-web/constants/tracking', 'impression-tracking'], function (exports, _tracking, _impressionTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function camelToSnakeCase(obj) {
        var snakeCase = Object.keys(obj).reduce(function (currentObj, key) {
            var v = obj[key];
            currentObj[key.replace(/[A-Z]/g, function (letter) {
                return '_' + letter.toLowerCase();
            })] = v;
            return currentObj;
        }, {});

        return snakeCase;
    }

    exports.default = Ember.Service.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        init: function init() {
            this.impressionTracking = new _impressionTracking.ImpressionTracking(this.get('mixpanelClient').instance());
        },
        getImpressionTracker: function getImpressionTracker(filtersDataExtractor, pageContext, eventSource) {

            var _this = this;

            function getImpressionData(htmlElement) {
                var position = htmlElement.getAttribute('data-position');
                var listingId = htmlElement.getAttribute('data-listing-id');
                var advisorId = htmlElement.getAttribute('data-advisor-id');
                var availabilityCall = htmlElement.getAttribute('data-call-availability-id');
                var availabilityChat = htmlElement.getAttribute('data-chat-availability-id');

                var impressionData = {
                    position: parseInt(position),
                    listingId: parseInt(listingId),
                    advisorId: parseInt(advisorId),
                    availabilityCall: _impressionTracking.ImpressionHelper.getCallAvailabilityText(parseInt(availabilityCall)),
                    availabilityChat: _impressionTracking.ImpressionHelper.getChatAvailabilityText(parseInt(availabilityChat)),
                    availabilityBusyStatus: _impressionTracking.ImpressionHelper.getAvailabilityBusyStatus(parseInt(availabilityCall), parseInt(availabilityChat)),

                    currentPage: pageContext.currentPage,
                    listingsPerPage: pageContext.listingsPerPage
                };

                return Object.assign(impressionData, eventSource);
            };

            function getImpressionDataWithFilters(htmlElement) {
                var impressionData = getImpressionData(htmlElement);

                var filtersData = filtersDataExtractor();

                return Object.assign(impressionData, filtersData);
            }

            function attachElementClickedTracking() {

                $(".listing-section").off('click');

                $(".listing-section").on('click', '.js_listing-details', function (event) {
                    trackEvent(_tracking.default.ADVISOR_LISTING_CLICKED, $(event.currentTarget)[0]);
                });

                $(".listing-section").on('click', '.call-button-container a[data-availability-id=1],[data-availability-id=0]', function (event) {
                    trackEvent(_tracking.default.CALL_NOW_BUTTON_CLICKED, $(this).closest('.listing-content').find('.js_listing-details')[0]);
                });

                $(".listing-section").on('click', '.chat-button-container a[data-availability=2]', function (event) {
                    trackEvent(_tracking.default.CHAT_NOW_BUTTON_CLICKED, $(this).closest('.listing-content').find('.js_listing-details')[0]);
                });
            }

            function trackEvent(eventName, htmlElement) {
                var mixPanelEvent = getImpressionData(htmlElement);

                mixPanelEvent.platform = 'web';

                _this.get('mixpanelClient').trackNewEvent(eventName, { eventData: camelToSnakeCase(mixPanelEvent) });
            }

            function observeImpression() {
                var map = {};

                var elements = Array.from(document.getElementsByClassName('js_listing-details'));

                elements.forEach(function (el) {
                    var listingId = el.getAttribute('data-listing-id');

                    el.id = listingId;

                    map[listingId] = el;
                });

                _this.impressionTracking.observe(elements, function (listingId) {
                    return getImpressionDataWithFilters(map[listingId]);
                });
            }

            return { observeImpression: observeImpression, attachElementClickedTracking: attachElementClickedTracking };
        },
        getBestMatchImpressionTracker: function getBestMatchImpressionTracker(getFiltersData) {
            var eventSource = {
                screen: _impressionTracking.ImpressionScreen.BestMatch
            };

            var pageContext = {
                currentPage: 1,
                listingsPerPage: 10
            };

            function filtersAndSortDataExtractor() {

                var filters = getFiltersData();

                var filterProperties = {
                    minPrice: filters.minPrice,
                    category: filters.category && filters.category.toLowerCase(),
                    skillsets: filters.skill && [filters.skill.toLowerCase()],
                    conversation: filters.availability && _impressionTracking.ImpressionHelper.getConversationText(parseInt(filters.availability))
                };

                if (filters.maxPrice !== _tracking.default.DEFAULT_MAX_PRICE) {
                    //when maxPrice is set to defaultValue we should skip it
                    filterProperties.maxPrice = filters.maxPrice;
                }

                var sort = _impressionTracking.ImpressionHelper.getSortTypeText(filters.sortBy);

                return { filters: filterProperties, sort: sort, tid: filters.tid };
            };

            return this.getImpressionTracker.bind(this, filtersAndSortDataExtractor, pageContext, eventSource)();
        }
    });
});