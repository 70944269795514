define('ingenio-web/components/ag-grid', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      run = Ember.run,
      assert = Ember.assert;
  exports.default = Ember.Component.extend({
    classNames: ['ag-grid-container'],
    tagName: 'div',
    theme: 'ag-fresh',
    width: '100%',
    height: '400px',

    containerStyle: computed('width', 'height', function () {
      var width = this.get('width');

      assert('invalid width property; use auto, px, % or em', this._escapeCSS(width));

      if (this.get('gridOptions.gridAutoHeight') === true) {
        return Ember.String.htmlSafe('width: ' + width);
      }

      var height = this.get('height');
      assert('invalid height property; use auto, px, % or em', this._escapeCSS(height));

      return Ember.String.htmlSafe('width: ' + width + '; height: ' + height + ';');
    }),

    _escapeCSS: function _escapeCSS(css) {
      return (/(^auto$|^\d*px$|^\d*%$|^\d*em$)/.test(css)
      );
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var defaults = { columnDefs: [], rowData: [] };
      var agGridOptions = typeof _emberGetConfig.default !== "undefined" ? _emberGetConfig.default.agGrid : false;

      if (!this.get('gridOptions')) {
        this.set('gridOptions', defaults);
      }

      run.scheduleOnce('afterRender', function () {
        if (agGridOptions && agGridOptions.useEnterprise && agGridOptions.licenseKey) {
          agGrid.LicenseManager.setLicenseKey(agGridOptions.licenseKey);
        }

        _this.get('gridOptions.columnDefs').forEach(function (col) {
          col.comparator = disableClientSideSorting;
        });

        new agGrid.Grid(_this.$('.agGrid')[0], _this.get('gridOptions'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('gridOptions') && this.get('gridOptions').api) {
        this.get('gridOptions').api.destroy();
      }
    }
  });


  function disableClientSideSorting(value1, value2) {
    return 0;
  }
});