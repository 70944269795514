define('ingenio-web/components/transaction-details/listing-information/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        listingLink: function () {
            var listingId = this.get('model.transactionDetail.listingId');

            return '/listingdetails?sid=' + listingId;
        }.property('model.transactionDetail.listingId')
    });
});