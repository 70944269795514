define('ingenio-web/adapters/mail-detail', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            var userId = Ember.Application.user.id;

            return 'users/' + userId + '/transactions/mail-details';
        },

        buildURL: function buildURL(type, id, snapshot) {
            var userId = Ember.Application.user.id;
            var recipientId = snapshot.adapterOptions['recipientId'];
            var apiBaseUrl = Ember.ENV.getApiRoot() + '/users/' + userId + '/transactions/mail-details/' + id;

            return apiBaseUrl + '?recipientId=' + recipientId;
        }
    });
});