define('ingenio-web/models/advisor-tax-document', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        documentTypeId: (0, _attr.default)('number'),
        documentType: (0, _attr.default)('string'),
        documentTypeDisplayName: (0, _attr.default)('string'),
        masterUserId: (0, _attr.default)('number'),
        fileName: (0, _attr.default)('string'),
        documentUrl: (0, _attr.default)('string', {
            defaultValue: null
        }),
        documentYear: (0, _attr.default)('number'),
        createdDateTime: (0, _attr.default)('date'),

        downloadUrlAvailable: Ember.computed('documentUrl', function () {
            return this.get('documentUrl') !== null;
        })
    });
});