define('ingenio-web/components/validation-results-aggregator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        numberOfInvalidComponents: 0,
        validatableComponents: null,

        setup: function () {
            this.set('validatableComponents', []);
        }.on('init'),

        willInsertElement: function willInsertElement() {
            //iterate through child views and put ones that are validatable into array to track + observe their validation state
            this._traverseChildViews(this);
            this._updateNumberOfInvalidComponents();

            //can be a good time to re-initialize validation related variables in controllers or elsewhere
            this.sendAction('onValidationInit', this);
            this.sendAction('numberOfInvalidComponentsChanged', this, this.get('numberOfInvalidComponents'));

            if (this.get('numberOfInvalidComponents') > 0) {
                this.sendAction('onSomethingBecomeInvalid', this);
            } else {
                this.sendAction('onEverythingIsValid', this);
            }
        },

        willDestroyElement: function willDestroyElement() {
            var _this = this;

            $.each(this.get('validatableComponents'), function (index, component) {
                component.removeObserver('validationState.valid', _this, _this.validationStateObserver);
            });
        },

        _traverseChildViews: function _traverseChildViews(view) {
            var _this2 = this;

            if (!(view instanceof Ember.Component)) {
                return;
            }

            $.each(view.get('childViews'), function (index, childView) {
                if (!(childView instanceof Ember.Component)) {
                    return;
                }

                if (childView.get('validatable')) {
                    _this2.get('validatableComponents').addObject(childView);
                    childView.addObserver('validationState.valid', _this2, _this2.validationStateObserver);
                }

                if (childView.get('childViews')) {
                    _this2._traverseChildViews(childView);
                }
            });
        },

        everythingUsedToBeValid: function everythingUsedToBeValid() {
            return this.get('numberOfInvalidComponents') === 0;
        },

        onlyOneComponentUsedToBeInvalid: function onlyOneComponentUsedToBeInvalid() {
            return this.get('numberOfInvalidComponents') === 1;
        },

        everythingIsValid: function everythingIsValid() {
            //every .valid property is not "false" meaning it wasn't explicitly set to "false", null or undefined is fine
            return this.get('validatableComponents').every(function (x) {
                return x.get('validationState.valid') !== false;
            });
        },

        /*  This is a main aggregation method, it:
            - observes "validationState.valid of" validatable components
            - emits "onEverythingIsValid" action, does this only once, when change of state really happens
            - e.g if everything has already been valid and for some reason component's state changes to "valid" again action won't
                be emitted
            - emits "onSomethingIsInvalid" action when everything was valid and now one of the components become invalid
            - emits "numberOfInvalidComponentsChanged" when number changed from previous time even was emitted
         */
        validationStateObserver: function validationStateObserver(component, observedProperty) {
            var someComponentBecomeValid = component.get(observedProperty);

            if (this.everythingUsedToBeValid() && !someComponentBecomeValid) {
                this.sendAction('onSomethingBecomeInvalid', this);
            }

            if (this.onlyOneComponentUsedToBeInvalid() && someComponentBecomeValid && this.everythingIsValid()) {
                this.sendAction('onEverythingIsValid', this);
            }

            var oldNumberOfInvalidComponents = this.get('numberOfInvalidComponents');

            this._updateNumberOfInvalidComponents();
            var newNumberOfInvalidComponents = this.get('numberOfInvalidComponents');

            if (oldNumberOfInvalidComponents !== newNumberOfInvalidComponents) {
                this.sendAction('numberOfInvalidComponentsChanged', this, newNumberOfInvalidComponents);
            }
        },

        _updateNumberOfInvalidComponents: function _updateNumberOfInvalidComponents() {
            var numberOfInvalidComponents = this.get('validatableComponents') //eslint-disable-line newline-after-var
            .filter(function (component) {
                return component.get('validationState.valid') === false;
            }).length;
            this.set('numberOfInvalidComponents', numberOfInvalidComponents);
        }
    });
});