define('ingenio-web/models/chat-history', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        consumerId: (0, _attr.default)('number'),
        advisorId: (0, _attr.default)('number'),
        lastFinishedSessionId: (0, _attr.default)('number')
    });
});