define('ingenio-web/components/myaccount/tax-documents', ['exports', 'ingenio-web/utils/file-download'], function (exports, _fileDownload) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        showDownloadError: false,

        actions: {
            downloadFile: function downloadFile(documentUrl) {
                var _this = this;

                this.set('showDownloadError', false);

                _fileDownload.default.downloadFile(documentUrl).catch(function (error) {
                    var httpStatusCode = error.httpStatusCode;

                    switch (httpStatusCode) {
                        default:
                            // handle error
                            _this.set('showDownloadError', true);

                            return;
                        case 401:
                            _this.send('redirectToLoginPage');
                            return;
                    }
                });
            },
            redirectToLoginPage: function redirectToLoginPage() {
                this.sendAction('redirectToLoginPage');
            }
        }
    });
});