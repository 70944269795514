define('ingenio-web/controllers/featured-listings/results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        activeTab: Ember.computed('target.currentRouteName', function () {
            var self = this;
            var routePieces = self.get('target.currentRouteName').split('.');

            return routePieces[routePieces.length - 1];
        })
    });
});