define('ingenio-web/components/arranged-conversations/advisor/waitlist-item-card/component', ['exports', 'ingenio-web/utils/html-utils', 'ingenio-web/constants/mail-mode-type'], function (exports, _htmlUtils, _mailModeType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OfferType = Object.freeze({
    None: 0,
    DiscountedServiceRate: 1,
    RegularOffer: 2
  });

  exports.default = Ember.Component.extend({
    offerType: null,
    offerText: null,
    avatarUrl: null,
    hidePrice: false,
    advisorName: null,
    roundedRating: null,
    averageRating: null,
    pricePerMinute: null,
    numberOfReviews: null,

    mixpanelClient: Ember.inject.service("mixpanel-client"),

    isKeen: Ember.computed(function () {
      return Ember.ENV.domainId === 1;
    }),

    discountedServiceRateOffer: Ember.computed('offerType', function () {
      return this.get('offerType') === OfferType.DiscountedServiceRate;
    }),

    pricePerMinuteDisplayText: Ember.computed('pricePerMinute', function () {
      var pricePerMinuteFixed = (this.get('pricePerMinute') * 1).toFixed(2);
      return '$' + pricePerMinuteFixed;
    }),

    offerDisplayText: Ember.computed('offerType', 'offerText', 'discountedServiceRateOffer', function () {
      var offerType = this.get('offerType');

      switch (offerType) {
        case 1:
          return this.get('offerText');

        case 2:
          return !this.get('discountedServiceRateOffer') ? this.get('offerText') : '';

        default:
          return '';
      }
    }),

    strikePriceText: Ember.computed('offerType', function () {
      return this.get('offerType') === OfferType.RegularOffer;
    }),

    cssTypeIndicator: Ember.computed("model.forConversationType", function () {
      var ctype = this.get("model.forConversationType").toString().toLowerCase();
      if (ctype == "chat") return "chat";
      return "phone";
    }),

    pastEarnings: Ember.computed("model.requestedBy", function () {
      var user = this.get("model.requestedBy");
      if (user && user.pastEarnings && user.pastEarnings.value) {
        return user.pastEarnings.value;
      }
      return "-";
    }),

    itemTypeIndicator: Ember.computed("model.type", function () {
      var ctype = this.get("model.forConversationType").toString().toLowerCase();
      if (ctype == "chat") return "chat".toUpperCase();
      return "call".toUpperCase();
    }),

    buttonText: Ember.computed("model.type", function () {
      var convesationType = this.get("model.forConversationType").toString();
      return 'Take ' + convesationType.charAt(0).toUpperCase() + convesationType.substr(1).toLowerCase() + ' Now';
    }),

    nicknameText: Ember.computed("model.requestedBy", function () {
      var nickname = this.get("model.requestedBy.nickname");
      return nickname == null ? "-" : nickname;
    }),

    activeCallStatus: Ember.computed("model", function () {
      var ctype = this.get("model.forConversationType").toString().toLowerCase();
      if (ctype == 'call') {
        var activeCall = this.get("model.conversation.legacyCodes");
        if (activeCall) {
          switch (activeCall.statusCode) {
            case "INITIATE_FAILED":
            case "UNKNOWN":
              return "ERROR";

            case "INITIATED":
              {
                switch (activeCall.resultCode) {
                  case "BOTH_ANSWER":
                  case "CALLING_BUYER":
                  case "BUYER_RING_NO_ANSWER":
                  case "BUYER_BUSY":
                  case "BUYER_SIT_TONE":
                  case "BUYER_DEAD_AIR":
                  case "BUYER_CALL_INIT_FAILED":
                  case "BUYER_NO_VALID_RESPONSE":
                  case "BUYER_CALL_IN_ERROR":
                  case "BUYER_HANGUP_AFTER_ACCEPT":
                  case "BUYER_SUPERVISION_ERROR":
                  case "BUYER_REJECT_CALL":
                  case "BUYER_NO_VALID_RESPONSE_TIMEOUT":
                    return "CONNECTING";

                  default:
                    {
                      if (activeCall.source == "CALL_ME_BACK_WEB") {
                        return "CALLINGYOU";
                      } else {
                        return "CONNECTING";
                      }
                    }
                }
              }

            case "SUBMITTED":
              return "CONNECTING";

            case "CONNECTED":
              return "ONCALL";

            case "TERMINATED":
            case "PROCESSED":
              switch (activeCall.resultCode) {
                case "BOTH_ANSWER":
                  return "CALLENDED";

                case "BUYER_RING_NO_ANSWER":
                case "BUYER_BUSY":
                case "BUYER_SIT_TONE":
                case "BUYER_DEAD_AIR":
                case "BUYER_CALL_INIT_FAILED":
                case "BUYER_NO_VALID_RESPONSE":
                case "BUYER_CALL_IN_ERROR":
                case "BUYER_HANGUP_AFTER_ACCEPT":
                case "BUYER_SUPERVISION_ERROR":
                case "BUYER_REJECT_CALL":
                case "BUYER_NO_VALID_RESPONSE_TIMEOUT":
                  return "UNAVAILABLE";

                case "SELLER_RING_NO_ANSWER":
                  return "YOUDIDNTANSWER";

                case "SELLER_BUSY":
                  return "PHONEBUSY";

                case "SELLER_SIT_TONE":
                case "SELLER_DEAD_AIR":
                case "SELLER_REJECT_CALL":
                  return "CALLNOTACCEPTED";

                case "SELLER_CALL_INIT_FAILED":
                case "SELLER_NO_VALID_RESPONSE":
                case "SELLER_CALL_IN_ERROR":
                case "SELLER_SUPERVISION_ERROR":
                  return "ERRORCALLINGYOU";
              }

            default:
              return "";
          }
        }
      } else {
        if (this.get("model.status") == "SERVICING") {
          return "INITIATING";
        }
      }
      return "";
    }),

    activeCallStatusCss: Ember.computed("activeCallStatus", function () {
      var activeCallStatus = this.get("activeCallStatus");
      if (activeCallStatus) {
        switch (activeCallStatus) {
          case "CONNECTING":
          case "CALLINGYOU":
            return "connecting";

          case "ONCALL":
            return "oncall";

          default:
            return '';
        }
      }

      return "";
    }),

    activeCallStatusText: Ember.computed("model", "activeCallStatus", function () {
      var activeCall = this.get("model.conversation.legacyCodes");
      var activeCallStatus = this.get("activeCallStatus");
      if (activeCall && !!activeCallStatus) {
        switch (activeCallStatus) {
          case "ERROR":
            return "Error";

          case "CONNECTING":
            return "Connecting ";

          case "CALLINGYOU":
            return "Calling You";

          case "ONCALL":
            return "On Call With ";

          case "CALLENDED":
            return "Call Ended";

          case "YOUDIDNTANSWER":
            return "Missed Call";

          case "PHONEBUSY":
            return "Your Line Is Busy";

          case "CALLNOTACCEPTED":
            return "Call Wasn't Accepted";

          case "ERRORCALLINGYOU":
            return "Error Calling You";

          case "UNAVAILABLE":
            return "Unavailable";

          case "TAKEACTION":
            return " is waiting for you";

          default:
            return "";
        }
      } else {
        if (this.get("model.status") == "SERVICING") {
          var activeChat = this.get("model.conversation");
          if (activeChat) {
            switch (activeChat.status) {
              case "INITIATING":
                return "Customer Notified";

              case "ENDED":
                return "Ended";

              case "ERRORED":
                return "Error";

              case "IN_PROGRESS":
                {
                  if (activeChat.chatUserParty.state == "JOINED") {
                    return this.get("model.requestedBy.userName") + " Connected";
                  }
                  if (activeChat.chatAdvisorParty.state == "JOINED") {
                    return "You Joined";
                  }
                  return "Customer Notified";
                }
            }
          } else {
            return "Customer Notified";
          }
        }
      }
      return "";
    }),

    activeCallStatusUsername: Ember.computed("model", "activeCallStatus", function () {
      var arrangedCall = this.get("model");
      var activeCallStatus = this.get("activeCallStatus");
      if (arrangedCall && !!activeCallStatus) {
        switch (activeCallStatus) {
          case "CONNECTING":
          case "ONCALL":
          case "TAKEACTION":
            return arrangedCall.requestedBy.userName;

          default:
            return '';
        }
      }
      return "";
    }),

    takeActionCss: Ember.computed("model.queuePosition", function () {
      var queuePosition = this.get("model.queuePosition");
      if (queuePosition == 1) {
        return "takeActionNow";
      }

      return "";
    }),

    showTakeActionNow: Ember.computed("model.queuePosition", function () {
      var activeCallPresent = this.get("model.activeCallPresent");
      return this.get("model.queuePosition") == 1 && !!!activeCallPresent;
    }),

    showActiveCallStatus: Ember.computed("model.activeCallPresent", function () {
      var activeCallPresent = this.get("model.activeCallPresent");
      return !!activeCallPresent && this.get("model.status") == "SERVICING";
    }),

    showViewCustomerNote: Ember.computed("model.note", function () {
      var note = this.get("model.note");
      return note && note.id;
    }),

    showActionContainer: Ember.computed("showActiveCallStatus", "showTakeActionNow", function () {
      var showActiveCallStatus = this.get("showActiveCallStatus");
      var showTakeActionNow = this.get("showTakeActionNow");
      return showActiveCallStatus || showTakeActionNow;
    }),

    expiresAtText: Ember.computed("model.expiresAt", function () {
      var expiresAt = this.get('model.expiresAt');
      var diff = new moment(expiresAt).diff(new moment());

      var expiresAtDays = 0;
      var expiresAtHours = 0;
      var expiresAtMinutes = 1;
      var expiresAtText = '';

      if (diff > 0) {
        expiresAtDays = moment.duration(new moment(expiresAt).diff(new moment())).days();
        expiresAtHours = moment.duration(new moment(expiresAt).diff(new moment())).hours();
        expiresAtMinutes = moment.duration(new moment(expiresAt).diff(new moment())).minutes();

        expiresAtText = pluralize(expiresAtText, expiresAtDays, 'day');
        expiresAtText = pluralize(expiresAtText, expiresAtHours, 'hour');
        expiresAtText = pluralize(expiresAtText, expiresAtMinutes, 'minute');

        expiresAtText = expiresAtText.trim();
      } else {
        expiresAtText = 'Now';
      }

      return expiresAtText;

      function pluralize(text, value, ending) {
        if (value === 1) {
          text += value.toString() + ' ' + ending;
        } else if (value > 1) {
          text += value.toString() + ' ' + ending + 's';
        }

        return text + ' ';
      }
    }),

    actions: {
      takeActionNow: function takeActionNow() {
        var that = this;
        if (this.get("itemTypeIndicator") === 'CHAT') {

          var advisorId = Ember.Application.user.id;
          this.get('mixpanelClient').trackNewEvent('Take Chat Now Clicked In Waitlist', {
            eventData: {
              advisor_id: advisorId
            }
          });

          Ember.$.ajax(Ember.ENV.getApiRoot() + '/users/' + advisorId + '/chat/availabilities', {
            method: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify({
              advisorChatAvailability: {
                availability: 'Available',
                availabilityUpdateSource: 'Web'
              }
            })
          }).then(function () {
            that.sendAction("openMonitor", true);
            that.sendAction("refresh");
          });
        } else {
          Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql', {
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify({
              operationName: null,
              query: "mutation{ serviceNextAdvisorConversationRequest }"
            })
          }).then(function (result) {
            that.sendAction("openMonitor");
            that.sendAction("refresh");
          });
        }
      },

      sendPing: function sendPing() {
        var customerId = this.get('model.requestedBy.id');
        console.log(customerId);
        if (window.location.hostname === 'localhost') {
          window.location.href = '/#/direct-messages/recipient/' + customerId;
        } else {
          window.location.href = '/app/#/direct-messages/recipient/' + customerId;
        }
      },
      sendEmail: function sendEmail() {
        var params = _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.requestedBy.userName'),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
        window.location = '/Mail/Compose.aspx?' + params;
      },
      viewNote: function viewNote() {
        var noteId = this.get('model.note.id');
        var userId = this.get('model.user.id');
        window.location = '/Mail/Read.aspx?' + _htmlUtils.default.encode({
          mailId: noteId,
          rcptId: userId,
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: _mailModeType.default.Sent,
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      }
    }
  });
});