define('ingenio-web/error/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DEFAULT_ERROR_PAGE = 'general-error';

    exports.default = Ember.Controller.extend({
        queryParams: ['error_code'],
        error_code: DEFAULT_ERROR_PAGE,

        errorComponent: Ember.computed('error_code', function () {
            var errorCode = this.get('error_code');

            if (!errorCode || !errorCode.includes('-')) {
                return 'error/' + DEFAULT_ERROR_PAGE;
            }

            return 'error/' + errorCode;
        })
    });
});