define('ingenio-web/routes/sms-notifications', ['exports', 'ingenio-web/routes/route-base'], function (exports, _routeBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeBase.default.extend({
        model: function model() {
            return this.store.findAll('userPhone', { reload: true });
        },
        setupController: function setupController(controller, model) {
            var userInfo = Ember.Application.user;
            var userPhones = model;

            var primaryPhone = userPhones.findBy('isPrimary', true);

            if (!primaryPhone) {
                primaryPhone = this.store.createRecord('userPhone');
                primaryPhone.set('userId', userInfo.id);
            }

            var phoneWithSmsNotificaitonEnabled = userPhones.findBy('isSmsNotificationEnabled', true);

            var phoneWithPromotionalSmsEnabled = userPhones.findBy('isPromotionalSmsEnabled', true);

            if (controller.get('type') === 'promotional') {
                if (phoneWithPromotionalSmsEnabled) {
                    controller.set('smsNotificationEnabled', true);
                }
            } else {
                if (phoneWithSmsNotificaitonEnabled) {
                    controller.set('smsNotificationEnabled', true);
                }
            }

            controller.set('userPhones', userPhones);
            controller.set('primaryPhone', primaryPhone);

            if (controller.get('type') === 'promotional') {
                if (phoneWithPromotionalSmsEnabled) {
                    var phoneId = phoneWithPromotionalSmsEnabled.get('id');

                    controller.set('selectedUserPhoneId', phoneId);
                } else {
                    controller.set('selectedUserPhoneId', primaryPhone.get('id'));
                }
            } else {
                if (phoneWithSmsNotificaitonEnabled) {
                    var _phoneId = phoneWithSmsNotificaitonEnabled.get('id');

                    controller.set('selectedUserPhoneId', _phoneId);
                } else {
                    controller.set('selectedUserPhoneId', primaryPhone.get('id'));
                }
            }
        }
    });
});