define('ingenio-web/helpers/lower-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lowerCase = lowerCase;
  function lowerCase(value) {
    if (!value) return '';

    if (Array.isArray(value)) {
      return value.map(function (item) {
        return item.toLowerCase();
      });
    } else {
      return value.toLowerCase();
    }
  }

  exports.default = Ember.Helper.helper(lowerCase);
});