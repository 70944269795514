define('ingenio-web/adapters/historical-bid-per-position', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot) {
            var listingId = snapshot.record.get('id');

            return Ember.ENV.getApiRoot() + '/featured-listings/listings/' + listingId + '/historical-bid-per-position';
        }
    });
});