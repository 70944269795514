define('ingenio-web/controllers/chat/initiate', ['exports', 'ingenio-web/objects/chat/end-reasons'], function (exports, _endReasons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        initialTimerSeconds: 0,
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        queryParams: ['listingid', 'guid', 'pmode', 'webtrack', 'clickid', 'postPay', 'autoReload', 'source', 'arrangeid'],
        listingid: null,
        guid: null,
        pmode: null,
        webtrack: null,
        postPay: false,
        autoReload: false,
        clickid: null,

        status: 'requesting',
        requesting: Ember.computed.equal('status', 'requesting'),
        canceled: Ember.computed.equal('status', 'canceled'),
        requestingOrCanceling: Ember.computed.or('requesting', 'canceled'),
        error: Ember.computed.equal('status', 'error'),

        advisorRejected: Ember.computed.equal('errorReason', 'AdvisorRejected'),
        errorReason: '',
        showAdvisorDetails: false,
        showTimer: true,
        user: Ember.computed('Ember.Application.user', function () {
            return Ember.Application.user;
        }),

        errorMessageHeader: function () {
            var errorReason = this.get('errorReason');

            this.get('logger').error('Error has occurred during initiate: errorReason = ' + errorReason);

            if (errorReason === '') {
                return '';
            }

            if (errorReason === 'InvalidSession') {
                return 'Sorry, this session is not valid.';
            }

            if (errorReason === 'AdvisorRejected' || errorReason === 'AdvisorUnavailable') {
                var advisorName = this.get('model.speakerName') || 'Advisor';

                return 'We\u2019re sorry, ' + advisorName + ' can\u2019t take your request at this time.';
            }

            if (errorReason === 'InsufficientFunds') {
                return 'Sorry, you do not have sufficient funds. Please add more funds and start session.';
            }

            if (errorReason === 'InvalidSessionStatus') {
                return 'Sorry, session is already ended. Please try again.';
            }

            return 'Sorry, there was a problem starting your session. ' + 'Please try later or try another advisor. ';
        }.property('errorReason'),

        recommendedAdvisorsShow: function () {
            var recommendedAdvisors = this.get('recommendedAdvisors');

            return recommendedAdvisors && recommendedAdvisors.get('length') > 0;
        }.property('recommendedAdvisors'),

        errorMessage: function () {
            return 'However you were not billed. You are never charged until your advisor connects';
        }.property('errorReason'),

        title: function () {
            if (this.get('requesting')) {
                return 'Contacting Advisor';
            }

            return 'Chat Status';
        }.property('status'),

        actions: {
            redirectToCategory: function redirectToCategory() {
                window.location.href = '/listings?category=' + this.get('model.categoryId');
            },
            toggleShowAdvisorDetails: function toggleShowAdvisorDetails() {
                this.toggleProperty('showAdvisorDetails');
            },
            hideTimer: function hideTimer() {
                this.toggleProperty('showTimer');
            },
            cancelChat: function cancelChat() {
                var _this = this;

                this.get('logger').log('Cancel chat during initial chat');
                var session = this.get('session');

                this.set('status', 'canceled');
                session.set('endReason', _endReasons.default.consumerCancelled);
                session.set('status', 'Ended');
                session.save().then(function (s) {
                    _this.get('logger').log('Chat was cancelled during initial chat ' + s.get('status'));

                    window.addEventListener('unload', _this.get('boundOnUnload'), false);
                    window.history.back();
                });
            }
        }
    });
});