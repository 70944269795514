define('ingenio-web/components/chat/send-area', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        enteredText: null,
        finalText: null,
        sessionReady: false,
        ended: false,
        showEndChatModal: false,

        didDestroyElement: function didDestroyElement() {
            if (this.$('.incorrect-browser-alert')) {
                this.$('.incorrect-browser-alert').hide();
            }
            if (this.$('.use-headphone-set-alert')) {
                this.$('.use-headphone-set-alert').hide();
            }
        },
        didInsertElement: function didInsertElement() {
            var initialText = this.get('initialText');
            if (initialText) {
                this.set('enteredText', initialText);
            }
        },


        sendDisabled: function () {
            var enteredText = this.get('enteredText');

            return !enteredText || enteredText.trim().length === 0 || !this.get('sessionReady');
        }.property('enteredText', 'sessionReady'),

        typingDisabled: function () {
            return !this.get('sessionReady');
        }.property('sessionReady'),

        showEndButton: function () {
            return this.get('sessionReady');
        }.property('sessionReady'),

        onEnteredTextChange: Ember.observer('enteredText', function () {
            var text = this.get('enteredText');

            var callback = this.get('onTextChange');
            if (callback && typeof callback === 'function') {
                callback(text);
            }
        }),

        charsNumber: function () {
            var text = this.get('enteredText');

            return text ? text.length : 0;
        }.property('enteredText'),

        showTooManyCharactersModal: function () {
            return this.get('charsNumber') > 300;
        }.property('charsNumber'),

        actions: {
            messageSent: function messageSent() {
                var _this = this;

                var promise = this.get('messageSent')(this.get('enteredText'));

                this.$('.message').focus();
                Promise.resolve(promise).then(function (sendError) {
                    if (typeof sendError === 'undefined') {
                        _this.set('enteredText', '');
                        _this.set('finalText', '');
                    }
                }).catch(function () {
                    //do nothing because there was a sending error
                });
            },

            end: function end() {
                this.sendAction('endSessionSelf');
            },

            otherPartySend: function otherPartySend() {
                this.sendAction('otherPartyMessageSent', this.get('enteredText'));
                this.set('enteredText', '');
                this.set('finalText', '');
            },

            keyPress: function keyPress(e) {
                this.sendAction('keyPress', e);
            },

            toggleEndChatModal: function toggleEndChatModal() {
                this.toggleProperty('showEndChatModal');
            },

            endSessionSelf: function endSessionSelf() {
                this.sendAction('endSessionSelf');
            },

            hideIncorrectBrowserModal: function hideIncorrectBrowserModal() {
                this.$('.incorrect-browser-alert').hide();
            },
            hideUseHeadphoneModal: function hideUseHeadphoneModal() {
                this.$('.use-headphone-set-alert').hide();
            }
        }
    });
});