define('ingenio-web/redeem-offer/payment-details/controller', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    getNextRouteName: function getNextRouteName() {
      return this.getParentRouteName() + '.details';
    }
  });
});