define('ingenio-web/components/validatable/text-area', ['exports', 'ingenio-web/mixins/validatable-model'], function (exports, _validatableModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatableModel.default, {
        classNames: ['validatable-model'],

        cssClass: function () {
            return this.get('inputClass') || 'form-control';
        }.property('inputClass'),

        numberOfRows: function () {
            return this.get('rows') || 3;
        }.property('rows')
    });
});