define('ingenio-web/components/featured-listings/quality-score-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        isScoreNotCalculated: function () {
            return this.get('score.scoreInPercent') <= 0;
        }.property('score'),

        componentCss: function () {
            if (this.get('isScoreNotCalculated')) {
                return 'score-not-calculated';
            }
            return '';
        }.property('score')
    });
});