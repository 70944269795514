define('ingenio-web/redeem-offer/no-offer/controller', ['exports', 'ingenio-web/mixins/transition-helpers'], function (exports, _transitionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_transitionHelpers.default, {
        actions: {
            onDone: function onDone() {
                var returnUrl = this.get('model.returnUrl') || '/myaccount';
                window.location = returnUrl;
            }
        }
    });
});