define('ingenio-web/components/listings/call-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.get('onAvailabilityChange'); //trigger observer since this property 'availability' is not used anywhere in the template.
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);
            this.get('onAvailabilityChange');
        },


        availability: null, //passed in
        buttonTypeCss: 'send-mail',
        icon: 'email',
        callButtonText: 'Send Mail',

        mixpanelClient: Ember.inject.service('mixpanel-client'),

        // observe for changes to availability and set button text, icon and css classes appropriately.
        onAvailabilityChange: function () {
            var availability = this.get('availability');
            var isKeen = Ember.ENV.domainId === 1;

            switch (availability) {
                case 0:
                case 1:
                    //call now or phone alerts
                    this.set('buttonTypeCss', 'call-now');
                    this.set('icon', 'phone');
                    this.set('callButtonText', isKeen ? 'Call' : 'Call Now');
                    break;
                case 3:
                case 4:
                case 5:
                case 7:
                    //arrange a call, busy and away
                    this.set('buttonTypeCss', 'arrange-call');
                    this.set('icon', 'phone');
                    this.set('callButtonText', isKeen ? 'Arrange' : 'Arrange Call');
                    break;
                case 2:
                case 6:
                    //Send Mail, busy and away
                    this.set('buttonTypeCss', 'send-mail');
                    this.set('icon', 'email');
                    this.set('callButtonText', isKeen ? 'Mail' : 'Send Mail');
                    break;
                default:
                    this.set('buttonTypeCss', 'send-mail');
                    this.set('icon', 'email');
                    this.set('callButtonText', isKeen ? 'Mail' : 'Send Mail');
            }
        }.property('availability'),

        callUrl: function () {
            var tid = this.get('tid');
            var listingId = this.get('listingId');
            var availability = this.get('availability');
            var webTrack = this.get('webTrack') || '';
            var returnUrl = this.get('returnUrl') || '';
            var clickId = this.get('clickId') || '';
            var tidParam = tid ? '&tid=' + tid : '';

            var qsParams = 'sid=' + listingId + ('&ReturnUrl=' + returnUrl) + ('&CancelUrl=' + returnUrl) + ('&WebTrack=' + webTrack) + '&dpr=1' + ('&mrp=' + availability) + tidParam + '&dooneclickcall=1' + ('&clickid=' + clickId);

            return '/calls/initiatecallstarter.aspx?' + qsParams;
        }.property('listingId', 'availability', 'webTrack', 'returnUrl', 'clickId')
    });
});