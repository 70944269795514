define('ingenio-web/serializers/funds', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
            Ember.merge(hash, this.serialize(record, options));
        },

        serialize: function serialize(snapshot) {
            var json = this._super.apply(this, arguments);

            json = Object.assign(json, {
                userAccountId: json.paymentMethodId,
                amount: snapshot.record.get('amountAsNumber')
            });

            delete json.paymentMethodId;

            return json;
        },
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            switch (requestType) {
                case 'createRecord':
                case 'updateRecord':
                    var modelClass = store.modelFor('balance');
                    var serializer = store.serializerFor('balance');

                    // TODO: This could be moved to a serializer for balance to avoid doing this in multiple places
                    // (this currently is also performed in the balance adapter)
                    payload = {
                        'balance': Object.assign(payload, {
                            id: Ember.Application.user.id
                        })
                    };

                    return serializer.normalizeSingleResponse(store, modelClass, payload, Ember.Application.user.id, requestType);

                default:
                    return this._super.apply(this, arguments);
            }
        }
    });
});