define('ingenio-web/routes/myaccount/customer-details', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/models/grid/relay-connection-pagination', 'ingenio-web/models/grid/pagination-info'], function (exports, _headerTypes, _footerTypes, _resetScrollOnTransition, _relayConnectionPagination, _paginationInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    defineHeaderType: function defineHeaderType() {
      return _headerTypes.default.FULL;
    },

    defineFooterType: function defineFooterType() {
      return _footerTypes.default.EMPTY;
    },

    model: function model(params) {
      var _this = this;

      var customerId = params.id;
      var advisorId = Ember.Application.user.id;

      return Ember.RSVP.hash({
        customerQuery: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql2', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            "query": "query($customerId:Int!){user{customer(id:$customerId){id userName nickname blockedOn profile{dateOfBirth}feedback{averageRating}list{id name type}offers{summary{totalSent totalRedeemed unexpired last{offerMinutes date}unexpired}}contacts{summary{last{date amount{amount displayAmount}}totalEarnings{amount displayAmount}totalBonus{amount displayAmount}calls{last{date amount{amount displayAmount}}totalEarnings{amount displayAmount}totalCalls totalTime averageDuration}chats{last{date amount{amount displayAmount}}totalEarnings{amount displayAmount}totalChats totalTime averageDuration}mail{paid{last{date amount{amount displayAmount}}totalPaidMail totalEarnings{amount displayAmount}}lastReceived lastSent totalReceived totalSent}pings{lastReceived lastSent totalReceived totalSent}}}customerSince userSince comment}}}",
            "variables": {
              "customerId": params.id
            }
          })
        }),
        customerLists: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists'),
        rating: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/advisor/' + advisorId + '/average-rating/' + customerId)
      }).then(function (result) {
        if (!result.customerQuery.data.user.customer) {
          _this.transitionTo('no-customer-data-yet');
        }

        try {
          var dateOfBirth = result.customerQuery.data.user.customer.profile.dateOfBirth;
          result.customerQuery.data.user.customer.profile.dateOfBirth = moment(dateOfBirth).format('MM/DD' + (dateOfBirth.startsWith('--') ? "" : "/YYYY"));
        } catch (error) {}

        result.customerQuery.data.user.customer.feedback.averageRating = result.rating.averageRating;

        var offersPagination = _paginationInfo.default.create();
        offersPagination.set('pageSize', 10);

        var feedbackPagination = _paginationInfo.default.create();
        feedbackPagination.set('pageSize', 10);

        var contactHistoryPagination = _paginationInfo.default.create();
        contactHistoryPagination.set('pageSize', 10);

        return {
          contactHistoryPagination: contactHistoryPagination,
          offersPagination: offersPagination,
          feedbackPagination: feedbackPagination,
          customerLists: result.customerLists.customerLists,
          customer: result.customerQuery.data.user.customer
        };
      }, function () {
        _this.transitionTo('no-customer-data-yet');
      });
    },

    actions: {
      didTransition: function didTransition() {
        var _this2 = this;

        Ember.run.next(this, 'initTooltip');

        var bodyWidth = Ember.$('body').width();
        if (bodyWidth < 700) {
          setTimeout(function () {
            _this2.controller.collapseAllSections();
          }, 2500);
        }
      }
    },

    initTooltip: function initTooltip() {
      Ember.$('[data-toggle="tooltip"]').tooltip();
    }
  });
});