define('ingenio-web/components/chat/add-time', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        tenMinutesPrice: function () {
            return '$' + this.get('timeLimit.tenMinutesPrice').toFixed(2);
        }.property('timeLimit.tenMinutesPrice'),

        twentyMinutesPrice: function () {
            return '$' + this.get('timeLimit.twentyMinutesPrice').toFixed(2);
        }.property('timeLimit.twentyMinutesPrice'),

        buyMinutesPrice: function () {
            var price = this.get('timeLimit.buyMinutesPrice');

            if (!price || price === 0) {
                return '';
            }

            return '( $' + price.toFixed(2) + ' )';
        }.property('timeLimit.buyMinutesPrice'),

        actions: {
            save: function save() {
                if (this.get('timeLimit.isValid')) {
                    this.get('timeLimit').clearGenericErrors(); //clear any existing server-side errors.
                    this.sendAction('addMoney');
                }
            },

            cancel: function cancel() {
                this.sendAction('cancel');
            }
        }
    });
});