define('ingenio-web/models/remaining-duration', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        availableTime: (0, _attr.default)('number', { defaultValue: 0 }),
        startTime: (0, _attr.default)('number', { defaultValue: 0 }),
        endTime: (0, _attr.default)('number', { defaultValue: 0 })
    });
});