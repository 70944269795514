define('ingenio-web/utils/html-utils', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var encode = function encode(o) {
        return Object.keys(o).map(function (key) {
            return encodeURIComponent(key).replace(' ', '+') + '=' + encodeURIComponent(o[key]).replace(' ', '+');
        }).join('&');
    };

    exports.encode = encode;
});