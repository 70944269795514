define('ingenio-web/helpers/fbm-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.setFbmUserVar = setFbmUserVar;
    function setFbmUserVar() {
        window.isKeenUserFBM = Ember.Application.user.firstBilledMinuteDate != null; // set global variable for optimizely AB test
    }

    exports.default = Ember.Helper.helper(setFbmUserVar);
});