define('ingenio-web/models/experiment-bucket', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _model.default.extend({
      activeBuckets: (0, _attr.default)(),
      inactiveBuckets: (0, _attr.default)(),
      mergedProperties: (0, _attr.default)(),
      mergedSettings: (0, _attr.default)(),
      experimentOffer: (0, _attr.default)()
   });
});