define('ingenio-web/components/add-money-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        inProgress: false,
        _errorMessage: '',
        userEvents: Ember.inject.service(),

        hasError: function () {
            return this.get('_errorMessage').length > 0;
        }.property('_errorMessage'),

        errorMessage: function () {
            return this.get('_errorMessage');
        }.property('_errorMessage'),

        disableAddMoney: function () {
            var amountAdded = this.get('settings.amountAdded') || 0;
            var minimumAmountToAdd = this.get('settings.minimumAmountToAdd') || 0;
            var maximumAmountToAdd = this.get('settings.maximumAmountToAdd') || 9999;

            return !(amountAdded >= minimumAmountToAdd && amountAdded > 0 && amountAdded <= maximumAmountToAdd);
        }.property('settings.amountAdded', 'settings.minimumAmountToAdd', 'settings.maximumAmountToAdd'),

        actions: {
            addMoney: function addMoney() {
                var _this = this;

                // don't lunch add money async call before
                // previous call is in progress
                if (this.get('inProcess')) {
                    return;
                }

                var apiUrl = Ember.ENV.getApiRoot();
                var userId = Ember.Application.user.id;
                var url = apiUrl + '/users/' + userId + '/balance';

                var amount = this.get('settings.amountAdded');

                var addFundData = {
                    amount: amount,
                    usePrimaryAccount: true,
                    eventSource: 'WebSite',
                    additionalFundsInfo: {
                        priceBucketMinutes: null,
                        source: 'featured_listing_bid',
                        rechargeMethod: 'ManualInput' //ManualInput, PriceBucket, PostPay
                    }
                };

                this.set('inProcess', true);
                Ember.$.ajax({
                    url: url,
                    type: 'POST',
                    data: JSON.stringify(addFundData),
                    contentType: 'application/json'
                }).done(function () {
                    _this.set('inProcess', false);
                    _this.set('_errorMessage', '');

                    _this.sendAction('moneyAdded', amount);
                    _this.get('userEvents').fundsAdded('Purchase');
                }).fail(function (response) {
                    _this.set('inProcess', false);
                    var errorMessage = 'An error has occurred. Please try again or contact customer support.';

                    var jsonError = response.responseJSON;

                    if (jsonError) {
                        if (jsonError.errorCode === 1) {
                            errorMessage = // eslint-disable-line
                            'There is no payment method on file for your account <br/> Please add a payment method using <a href="/Secure/Payments/Pay/Details.aspx">payment method page</a>'; // eslint-disable-line
                        } else if (jsonError.message) {
                            errorMessage = jsonError.message;
                        }
                    }
                    _this.set('_errorMessage', errorMessage);
                });
            },

            closeModal: function closeModal() {
                this.sendAction('cancel');
            }
        }
    });
});