define('ingenio-web/controllers/chat/session-base', ['exports', 'ingenio-web/objects/chat/message', 'ingenio-web/objects/chat/end-reasons', 'ingenio-web/objects/chat/chat-notification', 'ingenio-web/mixins/message-delivery-handler', 'ingenio-web/mixins/end-chat-helpers'], function (exports, _message, _endReasons, _chatNotification, _messageDeliveryHandler, _endChatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_messageDeliveryHandler.default, _endChatHelpers.default, {

        sessionId: null,
        logoLinkActive: false,

        webtrack: null, //TODO: integrate new field
        messages: null,
        systemChannelClient: null,
        chatChannelClient: null,
        clickId: null,

        typingMessage: null,
        keyPressNotifier: null,
        rechargeTimeLimit: 0, //set in route
        idleWarnDuration: 0, //set in route

        status: 'connecting',

        connecting: Ember.computed.equal('status', 'connecting'),
        ready: Ember.computed.equal('status', 'ready'),
        rechargePaused: Ember.computed.equal('status', 'rechargePaused'),
        idlePaused: Ember.computed.equal('status', 'idlePaused'),
        ended: Ember.computed.equal('status', 'ended'),
        mobileSession: Ember.computed.equal('webtrack', 101),
        error: Ember.computed.equal('status', 'error'), //TODO: check, do we need it (no such status on consumer session)
        sessionEnded: Ember.computed.alias('model.sessionEnded'),

        elapsedDurationV2: '00:00',

        notification: null,

        listingCategoryName: null,
        listingCategoryId: null,

        setup: function setup() {
            this.set('messages', Ember.A());
            this.set('chatting', true);
            this.set('ending', false);

            this.set('chatEndReason', null);
            this.set('sessionEndedAndSave', false);

            this.set('status', 'connecting');
            this.set('notification', _chatNotification.default.create({
                text: '',
                closeAfter: null
            }));
            this.set('logoLinkActive', false);
            this.set('showChatFooter', false);
        },


        onRechargePausedChanged: function () {
            if (this.get('rechargePaused')) {
                this.get('logger').log('onRechargePausedChanged true');
            } else {
                this.get('logger').log('onRechargePausedChanged false');
            }
        }.observes('rechargePaused'),

        actions: {
            toggleModal: function toggleModal() {
                this.set('status', 'idlePaused');
            },
            messageSent: function messageSent(text) {
                if (!text || text.trim().length === 0) {
                    return;
                }

                this.hideTyping();

                var deliverableMessage = _message.DeliverableMessage.create({
                    text: text
                });

                this.addMessage(deliverableMessage, true);
                this.get('chatChannelClient').sendChatMessage(null, deliverableMessage); //I don't think we need a recipient. Setting null for now.

                this.get('keyPressNotifier').notifyMessageSent();
            },
            keyPress: function keyPress(e) {
                this.get('keyPressNotifier').notifyKeyPress(e);
            },
            sendKeyPressMessage: function sendKeyPressMessage(state) {
                this.get('chatChannelClient').sendStateMessage(null, state);
            },
            onSessionIdleTimeoutImminent: function onSessionIdleTimeoutImminent(timeoutAfter) {
                this.get('logger').log('onSessionIdleTimeoutImminent ' + timeoutAfter);
                this.set('status', 'idlePaused');
                this.get('chatDurationManagerV2').stop();
            },
            onSessionIdleTimeout: function onSessionIdleTimeout() {
                //todo: add here logic for showing timeout due to idleness warning and close session.
                this.get('logger').log('onSessionIdleTimeoutImminent');
                this.endSession(_endReasons.default.advisorIdleTimeout);
            },
            resumeSessionFromIdle: function resumeSessionFromIdle() {
                this.get('logger').log('onSessionIdleTimeoutResume');
                this.get('chatChannelClient').sendStateMessage(null, 'ping');
                this.get('keyPressNotifier').startTimeoutCountdown();
                this.set('status', 'ready');
            },
            endSessionSelf: function endSessionSelf() {
                //there's probably more to do here.
                this.get('logger').log('endSessionSelf');
                this.endSession(_endReasons.default.advisorEnded);
            },
            onSystemMessageReceivedV2: function onSystemMessageReceivedV2(message) {
                //Handling common for advisor and consumer system messages
                //called from child controllers with already validated message (message.Data != null)
                var eventData = message.Data;

                switch (eventData.eventType) {
                    case 'ChatEndedEvent':
                        if (Number(this.get('sessionId')) === Number(eventData.sessionId)) {
                            this.get('logger').log('EndSession based on system message from Consumer.');
                            //Session was finished by customer or system
                            this.endSession();
                        }
                        return true;
                    default:
                        return false;
                }
            },
            onChatMessageReceived: function onChatMessageReceived(m) {
                var chatMessage = m.message;
                if (!this.get('idlePaused') && chatMessage.Type !== 'system') {
                    this.get('keyPressNotifier').notifyMessageReceived();
                }

                if (chatMessage.Type === 'chat' && chatMessage.Body) {
                    this.hideTyping();

                    this.addMessage({ text: chatMessage.Body }, false); //TODO: line-breaks should not be escaped to display multi-line messages.

                    this.handleMessageReceive(m);
                } else if (chatMessage.Type === 'state') {
                    if (chatMessage.Body === 'composing') {
                        this.showTyping();
                    } else if (chatMessage.Body === 'paused') {
                        this.hideTyping();
                    } else if (chatMessage.Body === 'ping') {
                        this.get('keyPressNotifier').startTimeoutCountdown();
                    }
                }
            },
            endSessionDuringRecharge: function endSessionDuringRecharge() {
                this.get('logger').log('endSessionDuringRecharge');
                this.endSession(_endReasons.default.advisorEndedDuringRecharge);
            },
            endSessionUserLeave: function endSessionUserLeave() {
                this.get('logger').log('ending session due to other participant leave');
                //Session was finished by customer or system
                this.endSession(_endReasons.default.consumerEnded);
            },
            endSessionOnError: function endSessionOnError() {
                this.get('logger').log('ending session due to error');
                this.endSession(_endReasons.default.advisorPubnubError);
            },
            endSessionOnBackButtonClick: function endSessionOnBackButtonClick() {
                this.get('logger').log('ending session due to back button clicked');
                this.endSession(_endReasons.default.advisorBackButtonClicked);
            }
        },

        sendSystemMessage: function sendSystemMessage(text) {
            //this is needed for system messages to be seen in transcript.
            this.get('chatChannelClient').sendSystemMessage(null, text);
        },
        addMessage: function addMessage(message, textIsMine) {
            var avatarUrl = null;

            if (!textIsMine) {
                avatarUrl = this.get('model.chatAdvisor.profilePictureUrl');
            }

            //We want to display raw html so line breaks are shown.
            //First make user input safe, then replace '/n' with '<br/>'
            var escapedText = Ember.Handlebars.Utils.escapeExpression(message.text).replace(new RegExp('\n', 'g'), '<br/>'); //eslint-disable-line no-control-regex

            var newMessage = _message.default.create({
                avatarUrl: avatarUrl,
                text: escapedText,
                sentAt: new Date(),
                isMine: textIsMine,
                sendContext: message
            });

            newMessage.sendContext.parent = newMessage;

            this.get('messages').addObject(newMessage);
        },
        showTyping: function showTyping() {
            this._showStatusMessage('typingMessage');
        },
        hideTyping: function hideTyping() {
            this._hideStatusMessage('typingMessage');
        },
        endSession: function endSession(endReason) {
            var _this = this;

            var session = this.get('model');
            var sessionId = session.get('id');

            // as we use simple cache without synchronization we need to send few sec after customer
            // to avoid same time requests
            setTimeout(function () {
                if (_this.get('chatChannelClient')) {
                    _this.get('chatChannelClient').sendAnalytics(sessionId);
                }
            }, 3000);

            this.set('notification', this._createNotification({
                text: '',
                closeAfter: null
            }));

            // Allow the user to click logo and navigate to myaccount page
            this.set('logoLinkActive', true);

            if (this.get('status') === 'ended') {
                return false;
            }

            this.set('chatting', false);

            $('.footer').show();
            this.set('showChatFooter', true);

            this.get('logger').log('ending session');
            //this.sendSystemMessage('Session Ended');

            this.hideTyping();

            if (this.get('keyPressNotifier')) {
                this.get('keyPressNotifier').stopTimeoutCountdown();
            }

            if (this.get('chatDurationManagerV2')) {
                this.get('chatDurationManagerV2').stop();
            }

            if (this.get('elapsedDurationTimeoutV2')) {
                this.get('logger').log('clearTimeout elapsedDurationTimeoutV2');
                clearTimeout(this.get('elapsedDurationTimeoutV2'));
            }

            if (endReason) {
                session.set('endReason', endReason);
            }

            var sessionEnded = this.get('sessionEnded');

            if (!sessionEnded) {
                session.set('status', 'Ended');
                this.set('ending', true);

                session.save().then(function () {
                    console.log('session ended'); //eslint-disable-line no-console
                    _this.set('ending', false);

                    var clientLog = _this.get('logger').getLogAndClear().join('\n');

                    _this.onSessionEnd(sessionId, clientLog, true).then(function (result) {
                        _this.set('sessionEndedAndSave', true);
                        _this.set('chatEndReason', result.endResult.endReason);
                        var sessionAmount = result.endResult.sessionAmount >= 0 ? Number(result.endResult.sessionAmount).toFixed(2) : 0.00;

                        _this.set('sessionAmount', sessionAmount);
                        _this.set('chargedTime', result.endResult.chargedTime);
                    });
                });
            }

            //unsubscribe from both channels
            if (this.systemChannelClient) {
                this.systemChannelClient.unsubscribe();
            }
            if (this.chatChannelClient) {
                this.chatChannelClient.unsubscribe();
            }

            this.set('status', 'ended');
            $(document).attr('title', Ember.ENV.domainName + ': Chat Ended');

            //Some child controllers may need possibility to run some action after
            //Session end. Such controllers could set to 'afterSessionEndCallback'
            //callback function in setup method. See advisor-session for example.
            var afterSessionEndCallback = this.get('afterSessionEndCallback');

            if (afterSessionEndCallback) {
                afterSessionEndCallback();
            }

            return true;
        },
        _showStatusMessage: function _showStatusMessage(messageType) {
            var message = this.get(messageType);

            if (message === null) {
                message = _message.default.create({
                    avatarUrl: this.get('model.chatAdvisor.profilePictureUrl'),
                    sentAt: new Date(),
                    isMine: false
                });

                switch (messageType) {
                    case 'typingMessage':
                        message.typingNotificationMessage = true;
                        break;
                    case 'reviewingMessage':
                        message.reviewingNotificationMessage = true;
                        break;
                }

                message.messageType = messageType;
                this.set(messageType, message);
            }

            var messages = this.get('messages');
            var contains = messages.includes(message);

            if (contains) {
                this.get('messages').removeObject(message);
            }

            this.get('messages').addObject(message);
        },
        _hideStatusMessage: function _hideStatusMessage(messageType) {
            var message = this.get(messageType);

            this.get('messages').removeObject(message);
        },
        _createNotification: function _createNotification(notification) {
            return _chatNotification.default.create(notification);
        }
    });
});