define('ingenio-web/mixins/end-chat-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var apiRoot = Ember.ENV.getApiRoot();

    exports.default = Ember.Mixin.create({
        onSessionEnd: function onSessionEnd(sessionId, clientLog, skipRateable) {
            var userId = Ember.Application.user.id;

            Ember.$.ajax(apiRoot + '/users/' + userId + '/chat/sessions/' + sessionId + '/logs', {
                method: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({ clientLog: clientLog })
            });

            var promises = {
                endResult: Ember.$.ajax(apiRoot + '/users/' + userId + '/chat/sessions/' + sessionId + '/end-result', {
                    method: 'GET',
                    contentType: 'application/json'
                }),
                user: Ember.$.ajax(apiRoot + '/users/current', {
                    method: 'GET',
                    contentType: 'application/json'
                }),
                orderCredits: Ember.ENV.domainId === 1 ? Ember.$.ajax(apiRoot + '/orders/chat/' + sessionId + '/credit', {
                    method: 'GET',
                    contentType: 'application/json'
                }).then(function (result) {
                    return result.data && result.data.credits;
                }) : null
            };

            if (!skipRateable) {
                promises.rateable = Ember.$.ajax(apiRoot + '/chat/' + sessionId + '/feedback/rateable', {
                    method: 'GET',
                    contentType: 'application/json'
                });

                promises.feedbackStatus = Ember.$.ajax(apiRoot + '/chat/' + sessionId + '/feedback/status', {
                    method: 'GET',
                    contentType: 'application/json'
                });
            }

            return new Ember.RSVP.hash(promises);
        }
    });
});