define('ingenio-web/views/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.View.extend({
        didInsertElement: function didInsertElement() {
            /* we use global click handler to hide any popups we find on the page */
            $('body').on('click', function (e) {
                if ($('.popover').length > 0) {
                    //todo restuta: this is a hack, we cannot afford to do this for all pages where we will need multiple pop-ups
                    $('.editable-list-content').each(function () {

                        // e.target is not the element which triggers the popup
                        // the popover doesn't contain the target : Which means that we are not clicking inside the popover
                        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                            $(this).popover('hide');
                        }
                    });
                }
            });
        }
    });
});