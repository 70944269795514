define('ingenio-web/models/paypal-token', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        userId: (0, _attr.default)('number'),
        returnUrl: (0, _attr.default)('string'),
        cancelUrl: (0, _attr.default)('string'),
        token: (0, _attr.default)('string')
    });
});