define('ingenio-web/components/add-money-selector-postpay-seamlesspay/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        numberMinutes: Ember.computed('minutes', function () {
            if (Number(this.get('minutes')) === 1) {
                return 'per';
            }

            return this.get('minutes');
        }),

        price: Ember.computed('minutes', 'minuteRate', function () {
            var minuteRate = parseFloat(this.get('minuteRate'));
            var numberMinutes = parseFloat(this.get('minutes'));

            if (this.get('enablePricePerMinuteActivationExperiment')) {
                return minuteRate.toFixed(2);
            }

            return this.getPrice(numberMinutes);
        }),

        getPrice: function getPrice(min) {
            var minuteRate = this.get('minuteRate');

            return (minuteRate * min).toFixed(2);
        }
    });
});