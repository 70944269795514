define('ingenio-web/adapters/order-prepare', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'orders/prepare';
        },

        createRecord: function createRecord(_, type, snapshot) {
            var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

            // the request payload and the response payload differ for this model type.
            var _snapshot$adapterOpti = snapshot.adapterOptions,
                listingId = _snapshot$adapterOpti.listingId,
                additionalFunds = _snapshot$adapterOpti.additionalFunds,
                isArrange = _snapshot$adapterOpti.isArrange;

            var data = { listingId: listingId, additionalFunds: additionalFunds, isArrange: isArrange };

            return this.ajax(url, 'POST', { data: data }).then(function (response) {
                return {
                    id: Date.now(),
                    fees: response.data.fees,
                    totalAmount: response.data.totalAmount
                };
            });
        }
    });
});