define('ingenio-web/mixins/logger', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        loggerService: Ember.inject.service('logger'),

        /**
         * Helper method to log events using the logger service.
         * @param {string} eventName A descriptive name of the event that occured that needs to be logged.
         * @param {object} logData An optional data object (can represent, and used to create, a logEntry model). The properties of this object will be sent to sentry.
         * @returns {void}
         */
        log: function log(eventName) {
            var logData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('logData') || {};

            this.get('loggerService').log(eventName, logData);
        },
        setCreateLogEntry: function setCreateLogEntry(createLogEntry) {
            this.get('loggerService').createLogEntry = createLogEntry;
        },


        /**
        * Helper method to log errors using the logger service.
        * @param {string} errorName A descriptive name of the event that occured that needs to be logged.
        * @param {object} errorObject An error or error-like object that will be sent to sentry.
        * @param {object} logData An optional data object (can represent, and used to create, a logEntry model). The properties of this object will be sent to sentry.
        * @returns {void}
        */
        logError: function logError(errorName) {
            var errorObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var logData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('logData') || {};

            this.get('loggerService').error(errorName, logData, errorObject);
        }
    });
});