define('ingenio-web/helpers/format-duration-unit', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDurationUnit = formatDurationUnit;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatDurationUnit(_ref, namedArgs) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    var duration = null;

    if (namedArgs && namedArgs.noZero) {
      if (!value) {
        duration = _moment.default.duration(1, "minutes");
      } else {
        duration = _moment.default.duration.apply(_moment.default, [value].concat(_toConsumableArray(rest)));

        if (duration < _moment.default.duration(1, "minutes")) {
          duration = _moment.default.duration(1, "minutes");
        }
      }
    } else {
      if (!value) {
        return 'N/A';
      } else {
        duration = _moment.default.duration.apply(_moment.default, [value].concat(_toConsumableArray(rest)));
      }
    }

    var day = "day";
    var hour = "hour";
    var minute = "minute";
    var second = "second";
    if (namedArgs && namedArgs.shortText) {
      day = "day";
      hour = "hour";
      minute = "min";
      second = "sec";

      if (duration.days() > 0 || duration.hours() > 9) {
        day = "d";
        hour = "h";
        minute = "m";
        second = "s";
      }
    }

    var highlightValue = namedArgs && namedArgs.highlightValue;
    var hideSeconds = namedArgs && namedArgs.hideSeconds;

    var r = [];
    if (namedArgs && namedArgs.abbr) {
      if (duration.days() > 0) r.push(duration.days() + 'd');

      if (duration.hours() > 0) r.push(duration.hours() + 'h');

      if (duration.minutes() > 0) r.push(duration.minutes() + 'm');

      if (duration.seconds() > 0) r.push(duration.seconds() + 's');
      return r.join(" ");
    } else {
      if (duration.days() > 0) {
        var s = duration.days() + ' ' + day + (duration.days() !== 1 ? 's' : '');
        if (highlightValue) s = '<strong>' + duration.days() + '</strong> ' + day + (duration.days() !== 1 ? 's' : '');
        r.push(s);
      }

      if (duration.hours() > 0) {
        var _s = duration.hours() + ' ' + hour + (duration.hours() !== 1 ? 's' : '');
        if (highlightValue) _s = '<strong>' + duration.hours() + '</strong> ' + hour + (duration.hours() !== 1 ? 's' : '');
        r.push(_s);
      }

      if (duration.minutes() > 0) {
        var _s2 = duration.minutes() + ' ' + minute + (duration.minutes() !== 1 ? 's' : '');
        if (highlightValue) _s2 = '<strong>' + duration.minutes() + '</strong> ' + minute + (duration.minutes() !== 1 ? 's' : '');
        r.push(_s2);
      }

      if (duration.seconds() > 0 && !hideSeconds) {
        var _s3 = duration.seconds() + ' ' + second + (duration.seconds() !== 1 ? 's' : '');
        if (highlightValue) _s3 = '<strong>' + duration.seconds() + '</strong> ' + second + (duration.seconds() !== 1 ? 's' : '');;
        r.push(_s3);
      }

      if (r.length == 0 && duration.minutes() == 0) {
        var _s4 = duration.minutes() + ' ' + minute + (duration.minutes() !== 1 ? 's' : '');
        if (highlightValue) _s4 = '<strong>' + duration.minutes() + '</strong> ' + minute + (duration.minutes() !== 1 ? 's' : '');
        r.push(_s4);
      }

      return r.join(", ");
    }
  }

  exports.default = Ember.Helper.helper(formatDurationUnit);
});