define('ingenio-web/adapters/special-offer', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            return Ember.ENV.getApiRoot() + '/special-offers?userId=' + Ember.Application.user.id;
        }
    });
});