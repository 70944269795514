define('ingenio-web/components/picture-cropper/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['picture-cropper-container'],

        picture: null,
        pictureData: null,
        croppedData: null,

        pictureObserver: Ember.observer('picture', function () {
            var _this = this;

            var picture = this.get('picture');

            if (!picture) {
                return;
            }

            var reader = new FileReader();

            reader.onload = function () {
                return _this.initializeJsCropperPlugin(reader.result);
            };
            reader.readAsDataURL(picture);
        }).on('init'),

        croppedDataObserver: Ember.observer('croppedData', function () {
            if (this.attrs.onPictureCropped) {
                this.attrs.onPictureCropped(this.get('croppedData'));
            }
        }),

        initializeJsCropperPlugin: function initializeJsCropperPlugin(pictureData) {
            var _this2 = this;

            var cropper = this.get('cropper');
            var elementToBind = this.$('.js-cropper')[0];

            // handle plugin reinitialization.
            if (cropper) {
                cropper.destroy();
            }

            elementToBind.src = pictureData;

            this.set('cropper', new Cropper(elementToBind, {
                viewMode: 2,
                autoCropArea: 1.0,
                minContainerHeight: 250,
                checkOrientation: false,
                aspectRatio: Ember.ENV.listings.edit.photo.aspectRatio,
                ready: function ready() {
                    return _this2.set('croppedData', _this2.get('cropper').getData());
                },
                cropend: function cropend() {
                    return _this2.set('croppedData', _this2.get('cropper').getData());
                }
            }));
        },


        actions: {
            zoomIn: function zoomIn() {
                this.get('cropper').zoom(0.2);
            },
            zoomOut: function zoomOut() {
                this.get('cropper').zoom(-0.2);
            }
        }
    });
});