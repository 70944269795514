define('ingenio-web/controllers/offer/redeem-success', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        balance: function () {
            var balance = this.get('model.balance');

            return balance.toFixed(2);
        }.property('model.balance')
    });
});