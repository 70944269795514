define('ingenio-web/routes/route-base', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        //todo restuta: document this, hard to read and understand
        userId: function () {
            var userInfo = Ember.Application.user;

            return userInfo.id;
        }.property()
    });
});