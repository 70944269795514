define('ingenio-web/objects/chat/end-reasons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var EndReasons = Ember.Object.extend({
        unknown: 0,
        consumerEnded: 1,
        advisorEnded: 2,
        consumerEndedDuringRecharge: 3,
        advisorEndedDuringRecharge: 4,
        consumerEndedRechargeTimeout: 5,
        advisorEndedRechargeTimeout: 6,
        consumerDisconnect: 7,
        advisorDisconnect: 8,

        consumerRechargeError: 9,
        consumerIdleTimeout: 10,
        consumerPubnubError: 11,
        consumerBackButtonClicked: 12,

        advisorRechargeError: 13,
        advisorIdleTimeout: 14,
        advisorPubnubError: 15,
        advisorBackButtonClicked: 16,
        advisorStateChangeTimeOut: 17,
        pubnubTimeOutCallback: 18,

        consumerUnloadDuringInitiate: 19,
        addPostPayFundsFailed: 20,
        consumerErrorDuringRequest: 21,
        clearedStuckSession: 22,

        advisorDeclined: 23,
        advisorNoResponse: 24,
        advisorMissed: 25,
        consumerCancelled: 26
    });

    exports.default = EndReasons.create();
});