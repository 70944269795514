define('ingenio-web/routes/chat/voice-to-text-test', ['exports', 'ingenio-web/utils/key-press-notifier', 'ingenio-web/utils/logger', 'ingenio-web/utils/header-types'], function (exports, _keyPressNotifier, _logger, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        logger: null,
        previousSessionTranscriptLoader: Ember.inject.service('previous-session-transcript-loader'),

        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.COLLAPSIBLE_MINIMAL;
        },
        nonPrimaryModelData: null,

        sessionSavePromise: null,
        sessionStarted: false,

        afterModel: function afterModel() {
            this.set('sessionStarted', false);
            var sessionId = 0;
            var advisorId = Ember.Application.user.id;

            var loggerOptions = {
                advisorId: advisorId,
                sessionId: sessionId,
                source: 'Voice-to-text-test'
            };
            var logger = new _logger.default(this.store, loggerOptions);

            this.set('logger', logger);
        },
        setupController: function setupController(controller) {
            this.get('logger').log('route: advisor-session setupController.');

            //setup timers.
            var rechargeTimeLimit = 60;

            controller.set('rechargeTimeLimit', rechargeTimeLimit);

            controller.set('logger', this.get('logger'));

            var timeoutPostWarningThreshold = 180;

            controller.set('idleWarnDuration', timeoutPostWarningThreshold);

            var keyPressNotifier = new _keyPressNotifier.default({
                timeoutPostWarningThreshold: timeoutPostWarningThreshold,
                timeoutWarningThreshold: 3 * 60,
                callbacks: {}
            });

            controller.set('keyPressNotifier', keyPressNotifier);
            var chatChannelClient = {
                sendChatMessage: function sendChatMessage() {}
            };

            controller.set('chatChannelClient', chatChannelClient);
        }
    });
});