define('ingenio-web/models/myaccount/feedback/filtration-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    listings: [],
    selectedListingId: '0'
  });
});