define('ingenio-web/chat/chat-details/route', ['exports', 'ingenio-web/routes/transaction-details-base'], function (exports, _transactionDetailsBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transactionDetailsBase.default.extend({
        previousSessionTranscriptLoader: Ember.inject.service('previous-session-transcript-loader'),
        model: function model(params) {
            var _this = this;

            return this.store.findRecord('chat-detail', params.session_id).then(function (chatDetail) {
                var listingId = chatDetail.get('listingId');

                var sessionId = chatDetail.get('id');
                var advisorId = chatDetail.get('advisorId');
                var consumerId = chatDetail.get('consumerId');
                var isAdvisor = Ember.Application.user.id === chatDetail.get('advisorId');
                var isChat = true;

                return _this.store.queryRecord('listing-v2', { id: listingId, includeDeletedListing: true, includeArrangeChatStatus: true }).then(function (listing) {
                    var advisorAvatarUrl = listing.get('picture.primaryVersion.url');

                    return _this.get('previousSessionTranscriptLoader').getTranscript(sessionId, advisorId, consumerId, isAdvisor, advisorAvatarUrl, _this.store).then(function (transcriptData) {
                        // TODO: this should be using Ember data instead of a direct ajax call. 
                        // This feature had a more urgent deadline so pushing changes as written for now.
                        return {
                            transactionDetail: chatDetail,
                            chatTranscript: transcriptData.transcript,
                            listing: listing,
                            isAdvisor: isAdvisor,
                            isChat: isChat,
                            hideChatTranscripts: transcriptData.optedOutTranscripts
                        };
                    });
                });
            });
        },


        actions: {
            error: function error(_error, transition) {
                if (_error.errors[0].status === '400') {
                    this.intermediateTransitionTo('not-authorized');
                } else if (_error.errors[0].status === '404') {
                    transition.abort();
                    window.location.href = '404error';
                } else {
                    return true;
                }
            }
        }
    });
});