define('ingenio-web/components/best-match/question-one', ['exports', 'ingenio-web/components/best-match/question-base'], function (exports, _questionBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _questionBase.default.extend({
        actions: {
            searchListings: function searchListings(keyword) {
                this.sendAction('searchListings', keyword);
            }
        }
    });
});