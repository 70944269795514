define('ingenio-web/routes/chat/advisor-session', ['exports', 'ingenio-web/utils/pubnub-client', 'ingenio-web/utils/key-press-notifier', 'ingenio-web/utils/chat-duration-manager-v2', 'ingenio-web/utils/logger', 'ingenio-web/utils/header-types', 'ingenio-web/objects/chat/end-reasons', 'ingenio-web/mixins/visibility-handler'], function (exports, _pubnubClient, _keyPressNotifier, _chatDurationManagerV, _logger, _headerTypes, _endReasons, _visibilityHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_visibilityHandler.default, {
        toast: Ember.inject.service(),
        activate: function activate() {
            this._super.apply(this, arguments);
            $(document).attr('title', Ember.ENV.domainName + ': Active Chat Session');
        },

        logger: null,
        previousSessionTranscriptLoader: Ember.inject.service('previous-session-transcript-loader'),

        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.EMPTY;
        },
        nonPrimaryModelData: null,

        sessionSavePromise: null,
        sessionStarted: false,
        currentConsumerList: null,
        disableAssignToList: false,

        makeLogoClickable: function makeLogoClickable() {
            this.controller.set('logoLinkActive', true);
        },
        model: function model(params) {
            return this.store.findRecord('chatSession', params.session_id);
        },


        actions: {
            didTransition: function didTransition() {
                var _this = this;

                var sessionId = this.controller.get('sessionId');
                var userId = Ember.Application.user.id;
                var apiRoot = Ember.ENV.getApiRoot();
                var url = apiRoot + '/users/' + userId + '/chat/sessions/' + sessionId;
                var sessionEnded = this.controller.get('sessionEnded');

                window.addEventListener('unload', function () {
                    if (!navigator.sendBeacon) {
                        return;
                    }
                    //we are in the middle of ending chat session which probably will be canceled
                    //becaseu started in same time page was closing
                    var ending = _this.controller.get('ending');

                    if (!_this.get('sendBeaconStarted') && (!sessionEnded || ending)) {
                        _this.set('sendBeaconStarted', true);
                        navigator.sendBeacon(url);
                    }
                }, true);

                return true; // Bubble the didTransition event
            },


            willTransition: function willTransition(transition) {
                var chatting = this.controller.get('chatting');

                if (chatting) {
                    if (!confirm( //eslint-disable-line no-alert
                    'Navigating away will end your session. Do you want to continue?')) {
                        transition.abort();
                    } else {
                        this.controller.send('endSessionOnBackButtonClick');
                        alert('Session ended'); //eslint-disable-line no-alert
                    }
                }
            },

            assignToList: function assignToList(list) {
                var self = this;
                var customerId = this.get('consumerId');

                var customerIds = [customerId];
                var advisorId = Ember.Application.user.id;
                this.controller.set('assignToListInProgress', true);

                return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists/' + list.id, {
                    method: 'PUT',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        customerIds: customerIds
                    })
                }).done(function (result) {
                    self.get("toast").success('Assigned To List');
                    self.set('currentConsumerList', list);
                    self.controller.set("assignToListInProgress", false);
                    self.controller.set('currentConsumerList', list);
                }).fail(function (jqXHR, textStatus) {
                    self.controller.set("assignToListInProgress", false);
                    self.get("toast").error('Error Assigning To List');
                    var logData = { advisorId: advisorId, customerId: customerId, listId: list.id, source: 'Advisor Chat Session' };

                    self.get('logger').error('Error: Could not assign user to list.', logData, textStatus);
                });
            }
        },

        getAdvisorLists: function getAdvisorLists(advisorId) {
            var _this2 = this;

            return Ember.$.getJSON(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists').done(function (result) {
                var customerLists = result.customerLists;
                _this2.set('customerLists', customerLists);
                var controller = _this2.controllerFor('chat.advisorSession');
                controller.set('customerLists', customerLists);
                return customerLists;
            });
        },
        getConsumerList: function getConsumerList(consumerId) {
            var _this3 = this;

            return Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql2', {
                method: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    "query": "query($customerId: Int!) {user {customer(id: $customerId) {id list {id name type}}}}",
                    "variables": {
                        "customerId": consumerId
                    }
                })
            }).done(function (result) {
                var list = result.data.user.customer.list || null;
                _this3.set('currentConsumerList', list);
                var controller = _this3.controllerFor('chat.advisorSession');
                controller.set('currentConsumerList', list);
                if (list === null) {
                    controller.set('hideAssignToList', true);
                }
                return list;
            });
        },
        afterModel: function afterModel(model) {
            var _this4 = this;

            this.set('sessionStarted', false);
            var sessionId = Number(model.get('id'));
            var advisorId = Number(model.get('chatAdvisor.userId'));
            var userId = Number(Ember.Application.user.id);
            var consumerId = model.get('chatConsumer.userId');
            this.set('consumerId', consumerId);

            if (advisorId !== userId) {
                throw new Error('incorrect request');
            }

            this.getAdvisorLists(advisorId);
            this.getConsumerList(consumerId);

            var loggerOptions = {
                advisorId: advisorId,
                sessionId: sessionId,
                source: 'AdvisorSession'
            };
            var logger = new _logger.default(this.store, loggerOptions);

            this.set('logger', logger);

            var notesPromise = this.store.query('chatAdvisorNote', { consumerId: consumerId }).then(function (notes) {
                return notes.objectAt(0);
            });

            //model is chat-session
            if (!model.get('uuid')) {
                model.set('uuid', _pubnubClient.default.guid());
            }
            //logger.log('advisor-session afterModel. transition: ' + transition);
            logger.log('advisor-session afterModel. chat channel name: ' + model.get('chatChannel.channelName') + ' uuid: ' + model.get('uuid'));

            var listingDataPromise = this.store.findRecord('listing-v2', model.get('listingId'));

            var promises = {
                listingData: listingDataPromise,
                notes: notesPromise
            };

            this.set('loadListingsStart', performance.now());

            //waiting for all promises to resolve
            return Ember.RSVP.hash(promises).then(function (result) {
                var data = {
                    listingCategoryName: result.listingData.get('categoryName'),
                    listingCategoryId: result.listingData.get('categoryId'),
                    note: result.notes
                };

                _this4.set('noteValue', data.note.get('note'));
                _this4.set('nonPrimaryModelData', data);
            });
        },
        setupController: function setupController(controller, model) {
            var _this5 = this;

            var loadListingsStart = this.get('loadListingsStart');
            var loadListingT1 = performance.now();

            this.get('logger').log('route: advisor-session setupController. loadListing took ' + (loadListingT1 - loadListingsStart) + 'ms.');
            controller.set('logoLinkActive', false);
            controller.set('status', 'connecting');
            controller.set('logger', this.get('logger'));
            controller.set('sessionId', Number(model.get('id')));
            controller.set('webtrack', model.get('webtrack'));
            controller.set('model', model);
            controller.set('customerLists', this.get('customerLists'));
            controller.set('currentConsumerList', this.get('currentConsumerList'));
            controller.set('consumerId', this.get("consumerId"));
            controller.set('clickId', model.get('clickId'));

            var data = this.get('nonPrimaryModelData');

            controller.set('note', data.note);

            if (!model.get('isValidToAcceptChat')) {
                this.get('logger').log('Error: Invalid session status. status:' + model.get('status'));
                controller.endSession(_endReasons.default.AdvisorBackButtonClicked);
                this.makeLogoClickable();
                return;
            }

            controller.send('showConnectingNotification');
            controller.set('listingCategoryName', data.listingCategoryName);
            controller.set('listingCategoryId', data.listingCategoryId);

            //setup timers.
            var rechargeTimeLimit = 60;

            controller.set('rechargeTimeLimit', rechargeTimeLimit);

            var isPostPay = model.get('isPostPay');

            controller.set('isPostPay', isPostPay);

            var isAutoReload = model.get('autoReload') > 0;

            controller.set('isAutoReload', isAutoReload);

            var timeoutPostWarningThreshold = 180;

            controller.set('idleWarnDuration', timeoutPostWarningThreshold);

            var keyPressNotifier = new _keyPressNotifier.default({
                timeoutPostWarningThreshold: timeoutPostWarningThreshold,
                timeoutWarningThreshold: 3 * 60,
                callbacks: {
                    timeoutImminent: function timeoutImminent(timeoutAfter) {
                        return Ember.run(function () {
                            return controller.send('onSessionIdleTimeoutImminent', timeoutAfter);
                        });
                    },
                    timeout: function timeout() {
                        return Ember.run(function () {
                            return controller.send('onSessionIdleTimeout');
                        });
                    },
                    sendChatState: function sendChatState(state) {
                        return controller.send('sendKeyPressMessage', state);
                    }
                }
            });

            controller.set('keyPressNotifier', keyPressNotifier);

            //TODO: Evaluate if below piece of code should go in afterModel hook.
            this.subscribeToChannels(model, controller, function () {
                return _this5.runTimeoutTimer(controller);
            });

            var advisorId = model.get('chatAdvisor.userId');
            var consumerId = model.get('chatConsumer.userId');
            var advisorAvatarUrl = model.get('chatAdvisor.profilePictureUrl');

            this.get('previousSessionTranscriptLoader').getPreviousSessionTranscript(advisorId, consumerId, true, advisorAvatarUrl, this.store).then(function (chatMessages) {
                controller.set('previousChatMessagesWithSameAdvisor', chatMessages.transcript);
                controller.set('hideChatTranscripts', chatMessages.optedOutTranscripts);
            });
        },
        createChatDurationManagerV2: function createChatDurationManagerV2(controller, serverNow, logger) {
            var chatDurationManagerV2 = new _chatDurationManagerV.default({
                rechargeWarningTimeout: 60,
                rechargeTimeout: 60,
                postPayExtendTimeout: 20,

                syncInterval: 5,
                syncDelta: 200,
                serverNow: serverNow,
                logger: logger,

                timerCallbacks: {
                    onRechargeWarning: function onRechargeWarning() {
                        controller.send('sessionTimeoutWarning');
                    },
                    onRecharge: function onRecharge() {
                        controller.send('demandForRecharge');
                    },
                    onRechargeTimeout: function onRechargeTimeout() {
                        controller.send('onRechargeTimeout');
                    }
                }
            });

            var elapsedDurationTimeoutV2 = setInterval(function () {
                var durationV2 = chatDurationManagerV2.durationFormatted();

                Ember.run(function () {
                    return controller.set('elapsedDurationV2', '' + durationV2);
                });
            }, 1000);

            controller.set('chatDurationManagerV2', chatDurationManagerV2);
            controller.set('elapsedDurationTimeoutV2', elapsedDurationTimeoutV2);
        },
        startSession: function startSession(controller, session) {
            var _this6 = this;

            if (this.get('sessionStarted')) {
                this.get('logger').log('Session is already started');
                return;
            }

            this.set('sessionStarted', true);

            var stateChangeTimeOut = this.get('stateChangeTimeOut');

            if (stateChangeTimeOut) {
                clearTimeout(stateChangeTimeOut);
            }

            var sessionSavePromise = this.get('sessionSavePromise');

            if (sessionSavePromise) {
                sessionSavePromise.then(function () {
                    if (!session.get('isValidToStartChat')) {
                        _this6.get('logger').log('Invalid session status :' + session.get('status'));
                        controller.set('status', 'ended');
                        _this6.makeLogoClickable();
                        return;
                    }

                    controller.send('onSessionStarted');
                    var sessionStartedT1 = performance.now();
                    var chatSubscribeStart = _this6.get('chatSubscribeStart');

                    _this6.get('logger').log('Session started after ' + (sessionStartedT1 - chatSubscribeStart) + 'ms');
                });
            } else {
                this.get('logger').log('route.sessionSavePromise is null');
                this.makeLogoClickable();
                controller.set('status', 'ended');
            }
        },
        runTimeoutTimer: function runTimeoutTimer(controller) {
            var advisorStateChangeTimeOutInSeconds = 18;
            var timeOut = setTimeout(function () {
                controller.endSession(_endReasons.default.advisorStateChangeTimeOut);
            }, advisorStateChangeTimeOutInSeconds * 1000);

            this.set('stateChangeTimeOut', timeOut);
        },
        subscribeToChannels: function subscribeToChannels(session, controller, successSubscribeCallback) {
            var _this7 = this;

            var advisorId = session.get('chatAdvisor.userId');
            var chatUserName = session.get('chatAdvisor.advisorName') || session.get('chatAdvisor.userId');
            //setup system channel
            var systemChannel = session.get('systemChannel');
            var systemChannelCallbacks = {
                setStateFailure: function setStateFailure(message) {
                    return _this7.get('logger').log('Setting Status failed. ' + message);
                },
                subscribe: {
                    success: function success() {
                        var systemChannelSubscribeT2 = performance.now();

                        var systemChannelSubscribeStart = _this7.get('systemChannelSubscribeStart');

                        _this7.get('logger').log('systemChannel(' + advisorId + ') subscribe took ' + (systemChannelSubscribeT2 - systemChannelSubscribeStart) + 'ms.');
                    },
                    failure: function failure(error) {
                        return _this7.onSystemChannelSubscribeFailure(error);
                    }
                },
                unsubscribe: {
                    success: function success() {
                        return _this7.get('logger').log('systemChannel unsubscribe success.');
                    }
                },
                messageReceived: function messageReceived(m) {
                    return controller.send('onSystemMessageReceivedV2', m.message);
                }
            };

            var systemChannelOptions = {
                channelName: systemChannel.get('channelName'),
                subKey: systemChannel.get('subscribeKey'),
                pubKey: systemChannel.get('publishKey'),
                authKey: systemChannel.get('authKey'),
                uuid: session.get('uuid'),
                userName: chatUserName,
                userId: session.get('chatAdvisor.userId'),
                callbacks: systemChannelCallbacks,
                heartbeatSeconds: 60,
                logger: this.get('logger')
            };
            var systemChannelClient = new _pubnubClient.default(systemChannelOptions);

            controller.set('systemChannelClient', systemChannelClient);
            this.set('systemChannelSubscribeStart', performance.now());
            systemChannelClient.subscribe();

            //setup chat channel
            var chatChannel = session.get('chatChannel');

            var chatChannelCallbacks = {
                subscribe: {
                    success: function success() {
                        var chatSubscribeT2 = performance.now();

                        var chatSubscribeStart = _this7.get('chatSubscribeStart');

                        _this7.get('logger').log('chatChannel(' + advisorId + ') subscribe took ' + (chatSubscribeT2 - chatSubscribeStart) + 'ms.');

                        var status = session.get('status');

                        if (status && status.toLowerCase() === 'created') {
                            session.set('status', 'Accepted');
                            _this7.set('sessionSavePromise', session.save());
                            if (successSubscribeCallback) {
                                successSubscribeCallback();
                            }
                        }
                    },
                    failure: function failure(erorr) {
                        return _this7.onChatChannelSubscribeFailure(erorr);
                    }
                },
                unsubscribe: {
                    success: function success() {
                        return _this7.get('logger').log('chatChannel unsubscribe success.');
                    }
                },

                timeout: function timeout(presenceMessage) {
                    var clientType = '';

                    if (presenceMessage.data && presenceMessage.data.clientType) {
                        clientType = presenceMessage.data.clientType;
                    } else if (presenceMessage.state && presenceMessage.state.clientType) {
                        clientType = presenceMessage.state.clientType;
                    }

                    if (presenceMessage.action === 'leave' && clientType === 'consumerSession') {
                        _this7.get('logger').log('ConsumerSession leave occured');
                        controller.send('endSessionUserLeave');
                    }
                },

                messageReceived: function messageReceived(m) {
                    var message = m.message;
                    if (message.Type === 'presencePing' && message.Body === 'ping') {
                        var presencePingT1 = performance.now();
                        var chatSubscribeStart = _this7.get('chatSubscribeStart');

                        _this7.get('logger').log('Received Presence Ping after ' + (presencePingT1 - chatSubscribeStart) + 'ms, SessionAlreadyStarted: ' + _this7.get('sessionStarted'));

                        _this7.sendPresencePingReceived();
                    }
                    if (!_this7.get('sessionStarted')) {
                        _this7.startSession(controller, session);
                    }
                    controller.send('onChatMessageReceived', m);
                },
                messageDelivered: function messageDelivered(timeToken, type, value) {
                    controller.send('onChatMessageDelivered', timeToken, type, value);
                },

                multiOccupancy: function multiOccupancy(presenceMessage) {
                    var action = presenceMessage.action;
                    var occupancy = presenceMessage.occupancy;
                    var uuid = presenceMessage.uuid;
                    var data = presenceMessage.data || presenceMessage.state;

                    var presenceLog = 'presence message with action: ' + action + (' and uuid: ' + uuid + ' and occupancy: ' + occupancy);

                    if (data) {
                        presenceLog += ' and data.clientType: ' + data.clientType;
                    }

                    var presenceEventT1 = performance.now();
                    var chatSubscribeStart = _this7.get('chatSubscribeStart');

                    presenceLog += ' ' + (presenceEventT1 - chatSubscribeStart) + 'ms after chatChannel subscribe';

                    _this7.get('logger').log(presenceLog);

                    if ((action === 'join' || action === 'state-change') && data && data.clientType === 'consumerSession') {

                        if (!_this7.get('sessionStarted')) {
                            _this7.startSession(controller, session);
                        }
                    }
                },

                networkUp: function networkUp() {
                    controller.set('networkUp', true);
                },

                networkDown: function networkDown() {
                    controller.set('networkUp', false);
                }
            };

            var chatChannelOptions = {
                channelName: chatChannel.get('channelName'),
                subKey: chatChannel.get('subscribeKey'),
                pubKey: chatChannel.get('publishKey'),
                authKey: chatChannel.get('authKey'),
                uuid: session.get('uuid'),
                userName: chatUserName,
                userId: session.get('chatAdvisor.userId'),
                callbacks: chatChannelCallbacks,
                heartbeatSeconds: 60,
                restore: { value: true },
                subscribeState: {
                    clientType: 'advisorSession',
                    username: chatUserName
                },
                logger: this.get('logger')
            };

            var chatChannelClient = new _pubnubClient.default(chatChannelOptions);
            var serverNow = chatChannelClient.serverNow.bind(chatChannelClient);

            this.createChatDurationManagerV2(controller, serverNow, this.get('logger'));

            controller.set('chatChannelClient', chatChannelClient);
            this.set('chatChannelClient', chatChannelClient);
            this.set('chatSubscribeStart', performance.now());
            chatChannelClient.subscribe();

            window.addEventListener('beforeunload', function (event) {
                if (_this7.getStatus() === 'Ended') {
                    return null;
                }

                _this7.get('logger').log('Ending session based on AdvisorSession event :' + event.type);

                // Cancel the event as stated by the standard.
                event.preventDefault();

                // Chrome requires returnValue to be set.
                event.returnValue = '';
            });

            window.addEventListener('unload', function (event) {
                controller.endSession(_endReasons.default.advisorDisconnect);
            });

            // NOTE: pagehide event is also being used because beforeunload
            // event is not supported on iOS safari and possibly other mobile browsers.
            window.addEventListener('pagehide', function (event) {
                controller.endSession(_endReasons.default.advisorDisconnect);
            });
        },
        sendPresencePingReceived: function sendPresencePingReceived() {
            this.get('chatChannelClient').sendPresencePingMessage(null, 'received');
        },
        onSystemChannelSubscribeFailure: function onSystemChannelSubscribeFailure(error) {
            this.get('logger').log('system channel subscribe failure. Pubnub error : ' + JSON.stringify(error));
            this.controller.send('endSessionOnError');
        },
        onChatChannelSubscribeFailure: function onChatChannelSubscribeFailure(error) {
            this.get('logger').log('chat channel subscribe failure. Pubnub error : ' + JSON.stringify(error));
            this.controller.send('endSessionOnError');
        },
        getStatus: function getStatus() {
            return this.controller.get('model').get('status');
        }
    });
});