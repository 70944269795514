define('ingenio-web/components/best-match/question-five', ['exports', 'moment', 'ingenio-web/components/best-match/question-base'], function (exports, _moment, _questionBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _questionBase.default.extend({
        didInsertElement: function didInsertElement() {
            var userInfo = Ember.Application.user;
            var minimumBirthYear = (0, _moment.default)().subtract(100, 'years').format('YYYY');

            this.set('minimumBirthYear', minimumBirthYear);

            if (userInfo) {
                this.set('answer.firstName', userInfo.firstName);

                if (userInfo.birthDate) {
                    var birthDate = (0, _moment.default)(userInfo.birthDate);

                    var birthDateYear = userInfo.birthDate && birthDate.format('YYYY') >= minimumBirthYear ? birthDate.format('YYYY') : '';

                    this.set('birthdayMonth', birthDate.format('MM'));
                    this.set('birthdayDay', birthDate.format('DD'));
                    this.set('birthdayYear', birthDateYear);
                }
            }
        },


        answer: {},

        actions: {
            selectAnswer: function selectAnswer() {
                var questionOneId = this.get('questionOneId');
                var questionTwoId = this.get('questionTwoId');
                var answer = this.get('answer');

                var birthdayMonth = parseInt(this.get('birthdayMonth') || null);
                var birthdayDay = parseInt(this.get('birthdayDay') || null);
                var birthdayYear = parseInt(this.get('birthdayYear') || null);

                /*
                    The default date we store on the db is 1776 if the year is:
                    1) not provided / null
                    2) the year provided is greater than 100 years ago (rules also enforced on Personal Info page)
                     To avoid friction, we do not provide any client-side validation for invalid or excluded DOBs.
                */
                var birthday = birthdayMonth && birthdayDay ? (0, _moment.default)(birthdayMonth + '/' + birthdayDay + '/1776', 'MM/DD/YYYY') : null;

                if (birthday && birthdayYear >= this.get('minimumBirthYear')) {
                    birthday.set('year', birthdayYear);
                }

                answer.birthDay = birthday && birthday.isValid() ? birthday.format('MM/DD/YYYY') : '';

                var answers = [{ questionId: questionOneId, answer: answer.firstName }, { questionId: questionTwoId, answer: answer.birthDay }];

                this.get('onAnswer')(answers);
            },
            monthChange: function monthChange() {
                var month = this.get('birthdayMonth') || '';

                if (month.length > 2) {
                    this.set('birthdayMonth', month.slice(0, 2));
                } else if (month.length === 2) {
                    this.$('#day').focus();
                }
            },
            dayChange: function dayChange() {
                var day = this.get('birthdayDay') || '';

                if (day.length > 2) {
                    this.set('birthdayDay', day.slice(0, 2));
                } else if (day.length === 2) {
                    this.$('#year').focus();
                }
            },
            yearChange: function yearChange() {
                var year = this.get('birthdayYear') || '';

                if (year.length > 4) {
                    this.set('birthdayYear', year.slice(0, 4));
                }
            }
        }
    });
});