define('ingenio-web/models/redeem-offer-flow', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var OfferRewardTypes = Object.freeze({
        Money: 1,
        Minutes: 2
    });

    exports.default = Ember.Object.extend({
        user: {
            funds: null,
            balance: null
        },

        hasValidOffer: Ember.computed('user.offer', function () {
            var offer = this.get('user.offer');

            if (!offer) {
                return false;
            }

            var rewardType = offer.get('rewardType');

            return offer.get('isOfferAssociatedAndNotRewarded') && offer.get('qualifierAmount') > 0 && (rewardType === OfferRewardTypes.Money || rewardType === OfferRewardTypes.Minutes);
        }),

        moneyRewardOffer: Ember.computed('user.offer', function () {
            var offer = this.get('user.offer');

            return offer.get('rewardType') === OfferRewardTypes.Money;
        }),

        offerCredit: Ember.computed('user.offer', function () {
            var offer = this.get('user.offer') || 0;

            return offer.get('rewardAmount').toFixed(2);
        }),

        amountToCharge: Ember.computed('user.funds.additionalAmount', function () {
            var additionalAmount = this.get('user.funds.additionalAmount') || 0;

            return parseFloat(additionalAmount).toFixed(2);
        }),

        offerCost: Ember.computed('user.offer.qualifierAmount', function () {
            var qualifierAmount = parseFloat(this.get('user.offer.qualifierAmount'));

            return qualifierAmount.toFixed(2);
        }),

        offerRequirementsMet: Ember.computed('user.offer.qualifierAmount', 'amountToCharge', function () {
            var qualifierAmount = parseFloat(this.get('user.offer.qualifierAmount'));
            var amountToCharge = parseFloat(this.get('amountToCharge'));

            return amountToCharge >= qualifierAmount;
        }),

        hasExistingPaymentMethods: Ember.computed('user.paymentMethods.@each', function () {
            return !Ember.isEmpty(this.get('user.paymentMethods'));
        }),

        paymentMethodSelected: Ember.computed('user.funds.paymentMethodId', 'user.paymentMethods.@each', function () {
            var _this = this;

            return this.get('user.paymentMethods').find(function (method) {
                return method.get('id') === _this.get('user.funds.paymentMethodId');
            });
        }),

        minimumDepositAmount: Ember.computed('user.offer.qualifierAmount', function () {
            var qualifierAmount = parseFloat(this.get('user.offer.qualifierAmount'));

            return qualifierAmount;
        }),

        maximumDepositAmount: Ember.computed('', function () {
            return Ember.ENV.maximumDepositAmount;
        }),

        balanceAmountRounded: Ember.computed('user.balance.monetaryAsNumber', function () {
            if (!this.get('user.balance.monetaryAsNumber')) {
                return '0.00';
            }

            return this.get('user.balance.monetaryAsNumber').toFixed(2);
        })
    });
});