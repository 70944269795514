define('ingenio-web/best-match/results/route', ['exports', 'ingenio-web/utils/cookies', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/routes/public-route', 'ingenio-web/utils/pubnub-advisor-availability-channel-client'], function (exports, _cookies, _headerTypes, _footerTypes, _publicRoute, _pubnubAdvisorAvailabilityChannelClient) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _publicRoute.default.extend({
    experimentation: Ember.inject.service('experimentation'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    defineHeaderType: function defineHeaderType() {
      return _headerTypes.default.BEST_MATCH_MINIMAL;
    },
    defineFooterType: function defineFooterType() {
      return _footerTypes.default.BEST_MATCH_MINIMAL;
    },

    getSort: function getSort() {
      var mergedExperimentProps = this.get('experimentation.mergedProperties');

      if (mergedExperimentProps.defaultSortId > 0 && mergedExperimentProps.availableSorts.length > 0) {
        return mergedExperimentProps.defaultSortId;
      }

      return 14;
    },
    model: function model(params, transition) {
      var _this = this;

      var id = transition.queryParams.id;
      var listingsTid = transition.queryParams.listingstid;
      var hlt = transition.queryParams.hlt || '0';
      var minPrice = transition.queryParams.minprice;
      var maxPrice = transition.queryParams.maxprice;

      var c = transition.queryParams.c;

      if (!c) {
        c = 4;
      }

      var sortBy = this.getSort();

      this.set('id', id);
      this.set('listingsTid', listingsTid);
      this.set('hlt', hlt);
      this.set('sortBy', sortBy);
      this.set('minPrice', minPrice);
      this.set('maxPrice', maxPrice);

      return Ember.RSVP.hash({
        answers: this.store.queryRecord('bestMatchAnswer', { token: id }),
        listings: this.loadListings(id, c, listingsTid, hlt, sortBy, minPrice, maxPrice)
      }).then(function (payload) {

        //required for impression tracking
        var answers = payload.answers.get('answers');

        var category = answers.findBy('questionId', 1);
        var skill = answers.findBy('questionId', 2);

        if (category) {
          _this.set('category', category.answerText);
        }

        if (skill) {
          _this.set('skill', skill.answerText);
        }

        return payload.listings;
      });
    },
    loadListings: function loadListings(id, listingsCount, tid, hlt, sortBy, minPrice, maxPrice) {
      var _this2 = this;

      //eslint-disable-line max-params
      var url = Ember.ENV.getApiRoot() + '/listings?type=best-match&token=' + id + '&index=0&size=' + listingsCount;

      if (tid) {
        url += '&listingsTid=' + tid;
      }

      if (hlt) {
        url += '&HLT=' + hlt;
      }

      if (sortBy) {
        url += '&sortBy=' + sortBy;
      }

      if (minPrice) {
        url += '&minPrice=' + minPrice;
      }

      if (maxPrice) {
        url += '&maxPrice=' + maxPrice;
      }

      return Ember.RSVP.hash({
        listings: $.ajax({ url: url, type: 'GET' })
      }).then(function (payload) {
        var index = 1;
        var experimentOffer = _this2.get('experimentation.experimentOffer');

        payload.listings.listings.forEach(function (l) {
          l.index = index++;

          if (experimentOffer && !_cookies.default.isCloverFlow() && Ember.isEmpty(tid)) {
            l.offerPrice = {
              LongDescription: experimentOffer.longDescription,
              OfferPitch: experimentOffer.offerPitch,
              PitchType: experimentOffer.pitchType
            };
          }
        });
        var advisorIds = payload.listings.listings.map(function (l) {
          return l.advisorId;
        });

        _this2.subscribeToAdvisorAvailablility(advisorIds);

        return payload.listings.listings;
      }).finally(function () {
        if (_this2.controller) {
          _this2.controller.set('loading', false);
        }
      });
    },
    getAvailabilityChannelDetails: function getAvailabilityChannelDetails(advisorIds) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax(Ember.ENV.getApiRoot() + '/availability-channels', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(advisorIds)
        }).then(function (response) {
          return resolve(response.availabilityChannels);
        }, reject);
      });
    },
    subscribeToAdvisorAvailablility: function subscribeToAdvisorAvailablility(advisorIds) {
      var _this3 = this;

      if (advisorIds.length > 0) {
        this.getAvailabilityChannelDetails(advisorIds).then(function (availabilityChannels) {
          var publishKey = availabilityChannels.publishKey,
              subscribeKey = availabilityChannels.subscribeKey,
              authKey = availabilityChannels.authKey,
              channelNames = availabilityChannels.channelNames;


          var pubnubOptions = {
            pubKey: publishKey,
            subKey: subscribeKey,
            authKey: authKey,
            channelName: channelNames,
            userName: Ember.Application.user.id
          };

          var logger = _this3.get('loggerService');

          var advisorAvailabilityChannelClient = new _pubnubAdvisorAvailabilityChannelClient.default(pubnubOptions, logger);

          advisorAvailabilityChannelClient.onConnect = function () {
            return logger.log('Advisor availability monitor successfully connected to pubnub');
          };

          advisorAvailabilityChannelClient.onConnectFailed = function () {
            return logger.error('Advisor availability monitor failed to connect to pubnub.');
          };

          advisorAvailabilityChannelClient.onAdvisorAvailabilityChanged = function (eventData) {
            var advisorId = eventData.advisorId,
                callAvailability = eventData.callAvailability,
                chatAvailability = eventData.chatAvailability;


            var listings = _this3.controller.get('listings');

            listings.forEach(function (l) {
              if (l.advisorId === advisorId) {
                Ember.set(l, 'availability', callAvailability);
                Ember.set(l, 'chatAvailability', chatAvailability);
              }
            });

            _this3.controller.set('listings', listings);
          };

          advisorAvailabilityChannelClient.subscribe();
        }).catch(function (e) {
          var logger = _this3.get('loggerService');
          logger.log('An error occured while fetching availability channel details: ', e);
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this4 = this;

      controller.set('listingstid', this.get('listingsTid'));
      controller.set('listings', model);
      controller.set('sortBy', this.get('sortBy'));
      controller.set('hltOverride', this.get('hlt'));
      controller.set('category', this.get('category'));
      controller.set('skill', this.get('skill'));
      controller.set('loadListings', function (listingsCount, hlt, sortBy, minPrice, maxPrice) {
        var id = _this4.get('id');
        var listingsTid = _this4.get('listingsTid');

        _this4.loadListings(id, listingsCount, listingsTid, hlt, sortBy, minPrice, maxPrice).then(function (listings) {
          controller.set('listings', listings);
        });
      });
    },

    actions: {
      willTransition: function willTransition() {},
      loading: function loading() {
        //overriding default loading event to prevent minimal header from showing up. Setup a custom loader here if needed.
      }
    }
  });
});