define('ingenio-web/redeem-offer/details/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    redeemOfferFlowModel: function redeemOfferFlowModel() {
      return this.modelFor(this.getParentRouteName());
    },

    beforeModel: function beforeModel(transition) {
      if (!this.redeemOfferFlowModel().get('hasValidOffer')) {
        this.replaceWith(this.getParentRouteName() + '.no-offer', {
          queryParams: transition.queryParams
        });
      }
    },
    model: function model() {
      return this.redeemOfferFlowModel();
    },
    setupController: function setupController(controller, model) {
      //this._super(...arguments);
      controller.set('model', model);
      controller.initialize();
    }
  });
});