define('ingenio-web/components/transaction-details/feedback-consumer/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		feedbackHelpUrl: function () {
			if (Ember.ENV.domainId === 1) {
				return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360053005994-Leaving-Feedback-for-Advisors';
			}
			return '/documents/help/feedback.asp';
		}.property()
	});
});