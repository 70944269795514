define('ingenio-web/routes/index', ['exports', 'ingenio-web/utils/footer-types'], function (exports, _footerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.MINIMAL;
        },

        model: function model() {
            return {
                myAccountUrl: Ember.ENV.urls.myAccountPage
            };
        }
    });
});