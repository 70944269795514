define('ingenio-web/components/enter-cvv-modal-content', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        canContinue: function () {
            var cvvCode = this.get('entry.cvvCode') || '';
            var ccType = this.get('paymentMethod.creditCardType') || '';

            ccType = ccType.toLowerCase();

            if ((ccType === 'visa' || ccType === 'mastercard') && cvvCode.length === 3) {
                return true;
            } else if (ccType === 'amex' && cvvCode.length === 4) {
                return true;
            } else if (ccType === 'discover' && cvvCode.length === 3) {
                return true;
            }

            return false;
        }.property('entry.cvvCode'),

        actions: {
            cancel: function cancel() {
                this.sendAction('cancel');
            },

            continue: function _continue() {
                this.sendAction('continue', this.get('entry.cvvCode'));
            }
        }
    });
});