define('ingenio-web/utils/pubnub-client-v4', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var PubnubClient = function () {
        function PubnubClient(options, pubnubClientInstance) {
            _classCallCheck(this, PubnubClient);

            var self = this;
            var defaultPresenceTimeoutSeconds = 120;

            this.logger = options.logger || { log: function log(message) {
                    return console.log(message);
                } };

            this.PUBNUB = pubnubClientInstance || PubNub; //property injection (for mocks in unit tests) otherwise use global PUBNUB object
            if (!options) {
                var errorDetails = 'options must be provided, required ones are pubKey or subKey, authKey, uuid, userName and channelName';

                this.logger.log(errorDetails);
                throw new Error(errorDetails);
            }
            this.offlineLog = [];

            this.options = {
                pubKey: options.pubKey || '',
                subKey: options.subKey,
                authKey: options.authKey,
                uuid: options.uuid,
                userName: options.userName,
                userId: options.userId || 0,
                channelName: options.channelName,
                presenceTimeoutSeconds: options.heartbeatSeconds || defaultPresenceTimeoutSeconds,
                subscribeState: options.subscribeState || null,
                restore: options.restore ? options.restore.value : true,
                callbacks: options.callbacks || {
                    subscribe: {
                        success: null,
                        failure: null
                    },
                    disconnect: null,
                    resubscribe: {
                        success: null
                    },
                    unsubscribe: {
                        success: null
                    },
                    messageSent: {
                        success: null,
                        failure: null
                    },
                    messageReceived: null,
                    setStateFailure: null,
                    timeout: null,
                    multiOccupancy: null //more than one user on channel
                }
            };

            this.sentMessages = [];
            this.receivedMessages = [];

            var pubnubOptions = {
                logVerbosity: false,
                publishKey: this.options.pubKey,
                subscribeKey: this.options.subKey,
                authKey: this.options.authKey,
                uuid: this.options.uuid,
                ssl: true,
                presenceTimeout: this.options.presenceTimeoutSeconds,
                heartbeatInterval: 30,
                autoNetworkDetection: true,
                listenToBrowserNetworkEvents: true,
                restore: this.options.restore,
                origin: 'h2.pubnubapi.com'
            };

            this.LogExceptionWrapper = function (method, name) {
                try {
                    self.logger.log('pubnub-client-wrapper-debug: EmberRunWrapper ' + name + ' pnId: ' + self.pnId);
                    method();
                } catch (error) {
                    self.logger.log('pubnub-client-v4: EmberRunWrapper ' + name + ': ' + (error ? error.message : '') + ' pnId: ' + self.pnId);
                    throw error;
                }
            };

            // Initialize the PubNub client
            this.pubnub = new PubNub(pubnubOptions);
            if (this.pubnub._configuration && this.pubnub._configuration._instanceId) {
                self.logger.log('pubnub-client-debug: Pubnub.InstanceId ' + this.pubnub._configuration._instanceId);
                this.pnId = this.pubnub._configuration._instanceId;
            } else {
                self.logger.log('pubnub-client-debug: Pubnub.InstanceId EMPTY');
                this.pnId = '0';
            }

            self.logger.log('pubnub-client-debug: pubnubOptions ' + JSON.stringify(pubnubOptions) + ' pnId: ' + self.pnId);

            if (!options.disableTimeSync) {
                this._setTimeDifference();
            }

            if (self.options.subscribeState) {
                self.pubnub.setState({
                    channels: [self.options.channelName],
                    state: self.options.subscribeState
                }, function (status) {
                    if (status.error) {
                        if (self.options.callbacks.setStateFailure) {
                            Ember.run(function () {
                                return self.LogExceptionWrapper(function () {
                                    return self.options.callbacks.setStateFailure(status.message);
                                }, 'setStateFailure');
                            });
                        }
                    }
                });
            }

            // Add Listeners
            var listeners = {
                status: function status(statusEvent) {
                    try {
                        // const operation = statusEvent.operation;
                        var category = statusEvent.category;

                        //console.log(`Status Received. Operation: (${operation}) ; Category: (${category}) ; AffectedChannels: (${statusEvent.affectedChannels})`);

                        if (category === 'PNConnectedCategory') {
                            //Subscribed (Connected)
                            if (self.options.callbacks.subscribe && self.options.callbacks.subscribe.success) {
                                Ember.run(function () {
                                    return self.LogExceptionWrapper(function () {
                                        return self.options.callbacks.subscribe.success();
                                    }, 'subscribe.success');
                                });
                                //Ember.run(() => self.options.callbacks.subscribe.success());
                            }
                            //When network is down during a chat session, Safari and mobile browsers are emitting PNNetworkIssuesCategory.
                            //Most desktop browsers are emitting PNNetworkDownCategory.
                        } else if (category === 'PNNetworkDownCategory' || category === 'PNNetworkIssuesCategory') {
                            if (self.options.callbacks.networkDown) {
                                Ember.run(function () {
                                    return self.LogExceptionWrapper(function () {
                                        return self.options.callbacks.networkDown();
                                    }, 'networkDown');
                                });
                                //Ember.run(() => self.options.callbacks.networkDown());
                            }
                            self.networkDownAt = new Date();
                        } else if (category === 'PNNetworkUpCategory') {
                            if (self.options.callbacks.networkUp) {
                                Ember.run(function () {
                                    return self.LogExceptionWrapper(function () {
                                        return self.options.callbacks.networkUp();
                                    }, 'networkUp');
                                });
                                //Ember.run(() => self.options.callbacks.networkUp());
                            }
                            if (self.networkDownAt) {
                                self.logger.log('Network was Down at ' + self.networkDownAt.toISOString());
                                self.networkDownAt = null;
                            }
                            self.logger.log('Network Up');
                        } else if (category === 'PNReconnectedCategory') {
                            //Reconnected
                            if (self.options.callbacks.resubscribe && self.options.callbacks.resubscribe.success) {
                                // Pubnub SDK emits reconnected event more than once within a very short span of time.
                                // So, delay calling the resubscribe callback until debounce period has elaspsed.
                                // This prevents calling backend api multiple times within the resubscribe success callback
                                //Ember.run.debounce(() => self.options.callbacks.resubscribe.success(), 1000, true); //calls immediately for the first time and then waits for 1 second
                                Ember.run.debounce(function () {
                                    return self.LogExceptionWrapper(function () {
                                        return self.options.callbacks.resubscribe.success();
                                    }, 'resubscribe.success');
                                }, 1000, true);
                            }
                        } else {
                            self.logger.log('pubnub-client-debug: unknown category ' + category + ', statusEvent: ' + JSON.stringify(statusEvent));
                        }
                        //Temporarily commenting this code. Need to figure best way to detect any subscribe failures.
                        /*
                        if (category === 'PNNetworkIssuesCategory') {
                            //Network Issue (not connected to internet or behind proxy)
                            // if (self.options.callbacks.disconnect) {
                            //   Ember.run(() => self.options.callbacks.disconnect());
                            // }
                            if (operation === 'PNSubscribeOperation' && statusEvent.error === true) {
                                if (
                                    self.options.callbacks.subscribe
                                    && self.options.callbacks.subscribe.failure
                                ) {
                                    Ember.run(() => self.options.callbacks.subscribe.failure());
                                }
                            }
                        }
                        */
                    } catch (error) {
                        self.logger.log('pubnub-client-v4: listeners.status: ' + (error ? error.message : ''));
                    }
                }, //end status listener

                // Handle message events
                message: function message(m) {
                    try {
                        var msg = m.message;
                        if (msg.Type === 'chat') {
                            self.logger.log('pubnub-client-debug: listeners.message: ' + msg.MessageId + ', ' + msg.From);
                        }

                        if (msg.From !== self.options.userName && self.options.callbacks.messageReceived) {
                            if (self.receivedMessages) {
                                msg.ReceivedTimestamp = self.serverNow();
                                self.receivedMessages.push(msg);
                            }
                            Ember.run(function () {
                                return self.LogExceptionWrapper(function () {
                                    return self.options.callbacks.messageReceived(m);
                                }, 'messageReceived ' + msg.MessageId);
                            });
                        }
                    } catch (error) {
                        self.logger.log('pubnub-client-v4: listeners.message: ' + (error ? error.message : ''));
                    }
                },

                // Handle presence events
                presence: function presence(p) {
                    try {
                        self.logger.log('pubnub-client-debug: listeners.presence: Presence received. Action: (' + p.action + ') ; Channel: (' + p.channel + ') ; Occupancy: (' + p.occupancy + ') ; State: (' + JSON.stringify(p.state) + ') ; uuid: (' + p.uuid + ') ; timestamp: (' + p.timestamp + ') ; timetoken: (' + p.timetoken + ') ; pnId: ' + self.pnId);
                        //console.log(`Presence received. Action: (${p.action}) ; Channel: (${p.channel}) ; Occupancy: (${p.occupancy}) ; State: (${JSON.stringify(p.state)}) ; uuid: (${p.uuid}) ; timestamp: (${p.timestamp}) ; timetoken: (${p.timetoken}) ; `);
                        var action = p.action; // Can be join, leave, state-change or timeout

                        if (self.options.callbacks.multiOccupancy && (action === 'join' || action === 'state-change')) {
                            Ember.run(function () {
                                return self.LogExceptionWrapper(function () {
                                    return self.options.callbacks.multiOccupancy(p);
                                }, 'multiOccupancy-' + action);
                            });
                            //Ember.run(() => self.options.callbacks.multiOccupancy(p));
                        }

                        if (self.options.callbacks.timeout && (action === 'timeout' || action === 'leave')) {
                            Ember.run(function () {
                                return self.LogExceptionWrapper(function () {
                                    return self.options.callbacks.timeout(p);
                                }, 'timeout-' + action);
                            });
                            //Ember.run(() => self.options.callbacks.timeout(p));
                        }
                    } catch (error) {
                        self.logger.log('pubnub-client-v4: listeners.presence: ' + (error ? error.message : ''));
                    }
                }
            };

            if (self.options.callbacks.messageDelivered) {
                listeners.messageAction = function (action) {
                    try {
                        self.options.callbacks.messageDelivered(action.data.messageTimetoken, action.data.type, action.data.value);
                    } catch (error) {
                        self.logger.log('pubnub-client-v4: listeners.messageAction: ' + (error ? error.message : ''));
                    }
                };
            }

            this.pubnub.addListener(listeners); // End adding listners
        } // End of constructor

        _createClass(PubnubClient, [{
            key: 'sendMessageReceivedAction',
            value: function sendMessageReceivedAction(message) {
                this.addMessageAction(message, 'received');
            }
        }, {
            key: 'sendMessageReadAction',
            value: function sendMessageReadAction(message) {
                this.addMessageAction(message, 'read');
            }
        }, {
            key: 'addMessageAction',
            value: function addMessageAction(message, type) {
                if (!this.options.pubKey || message.message.Type !== 'chat') {
                    return;
                }

                var channelName = this.options.channelName;

                this.pubnub.addMessageAction({
                    channel: channelName,
                    messageTimetoken: message.timetoken,
                    action: {
                        type: 'receipt',
                        value: type
                    }
                });
                //throw new Error('addMessageAction error');
            }
        }, {
            key: '_setTimeDifference',
            value: function _setTimeDifference() {
                var _this = this;

                var requestSentTime = Date.now();

                console.log('requestSentTime: ' + requestSentTime);

                this.pubnub.time(function (status, response) {
                    if (status.error) {
                        if (!_this.differenceCalculated) {
                            //if we don't have calculated difference we should show alert to advisor
                            // eslint-disable-next-line no-alert
                            alert('There is a problem connecting to network. Please close and reopen window.');
                        }
                    } else {
                        var responseReceivedTime = Date.now();

                        console.log('responseReceivedTime: ' + responseReceivedTime);

                        var serverTime = Math.round(response.timetoken / 10000);
                        var now = Date.now();

                        _this.difference = Math.round(now - serverTime - (responseReceivedTime - requestSentTime) / 2);
                        _this.differenceCalculated = true;

                        var differenceThreshold = 60 * 1000;

                        if (_this.difference > differenceThreshold || _this.difference < -1 * differenceThreshold) {
                            _this.logger.log('TIME: Local time is off. now(' + now + ') - serverTime(' + serverTime + ') = difference(' + _this.difference + ')');
                        } else {
                            console.log('TIME: now(' + now + ') - serverTime(' + serverTime + ') = difference(' + _this.difference + ')');
                        }
                    }
                });
                if (!this.disableTimeSync) {
                    this.setTimeDifferenceTimeout = setTimeout(function () {
                        return _this._setTimeDifference();
                    }, 10 * 60 * 1000);
                }
            }
        }, {
            key: 'serverNow',
            value: function serverNow() {
                return Math.round(Date.now() - (this.difference || 0));
            }
        }, {
            key: 'sendAnalytics',
            value: function sendAnalytics(sessionId) {
                var _this2 = this;

                try {
                    if (this.analyticsSent) {
                        return;
                    }
                    var mapMessage = function mapMessage(m) {
                        return {
                            fromUserId: m.FromUserId,
                            messageId: m.MessageId,
                            timetoken: m.ReceivedTimestamp || m.SentTimestamp
                        };
                    };
                    var receivedMessages = this.receivedMessages.filter(function (m) {
                        return m.Type === 'chat';
                    }).map(mapMessage);
                    var sentMessages = this.sentMessages.filter(function (m) {
                        return m.Type === 'chat';
                    }).map(mapMessage);
                    var analyticsBody = JSON.stringify({
                        sessionId: sessionId,
                        receivedMessages: receivedMessages,
                        sentMessages: sentMessages
                    });

                    this.logger.log('pubnub-client-debug: sendAnalytics: ' + analyticsBody);

                    Ember.$.ajax(Ember.ENV.getApiRoot() + '/chat/analytics', {
                        method: 'POST',
                        contentType: 'application/json',
                        data: analyticsBody
                    }).then(function () {
                        _this2.receivedMessages = [];
                        _this2.sentMessages = [];
                        _this2.analyticsSent = true;
                    }).catch(function (e) {
                        return _this2.logger.log('pubnub-client-v4: Error in sendAnalytics.catch: ' + error ? error.message : '');
                    });
                } catch (error) {
                    this.logger.log('pubnub-client-v4: Error in sendAnalytics: ' + error ? error.message : '');
                }
            }
        }, {
            key: 'subscribe',
            value: function subscribe() {
                var self = this;
                var channelName = self.options.channelName;
                console.log("pubnub.subscribe()");
                this.logger.log('pubnub-client-debug: subscribe channelName: ' + channelName + ', pnId: ' + self.pnId);
                try {
                    self.pubnub.subscribe({
                        channels: [channelName],
                        //state: self.options.subscribeState,
                        withPresence: true
                        //   withoutCallback: true
                    });
                } catch (error) {
                    this.logger.log('pubnub-client-v4: pubnub.subscribe() failed!\' ' + error + ', ChannelName: ' + channelName);
                    console.log('pubnub.subscribe() failed!', error, 'ChannelName: ', channelName);
                    if (self.options.callbacks.subscribe && self.options.callbacks.subscribe.failure) {
                        Ember.run(function () {
                            return self.LogExceptionWrapper(function () {
                                return self.options.callbacks.subscribe.failure();
                            }, 'subscribe.failure');
                        });
                        //Ember.run(() => self.options.callbacks.subscribe.failure());
                    }
                }
            }
        }, {
            key: 'isSubscribed',
            value: function isSubscribed() {
                var _this3 = this;

                var uuid = this.pubnub.getUUID();

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    _this3.pubnub.hereNow({ channels: [_this3.options.channelName] }, function (state, response) {
                        if (state.error) {
                            reject(state);
                            return;
                        }
                        var currntUUID = response.channels[_this3.options.channelName].occupants.find(function (occupant) {
                            return occupant.uuid === uuid;
                        });

                        resolve(!!currntUUID);
                    });
                });
            }
        }, {
            key: 'unsubscribe',
            value: function unsubscribe(withoutCallback) {
                var self = this;

                self.pubnub.unsubscribe({
                    channels: [self.options.channelName]
                });

                this.disableTimeSync = true;

                if (this.setTimeDifferenceTimeout) {
                    clearTimeout(this.setTimeDifferenceTimeout);
                }

                if (withoutCallback) {
                    return;
                }

                if (self.options.callbacks.unsubscribe && self.options.callbacks.unsubscribe.success) {
                    Ember.run(function () {
                        return self.LogExceptionWrapper(function () {
                            return self.options.callbacks.unsubscribe.success();
                        }, 'unsubscribe.success');
                    });
                    // Ember.run(function() {
                    //     self.options.callbacks.unsubscribe.success();
                    // });
                }
            }
        }, {
            key: '_publish',
            value: function _publish(recipient, message, type, storeMessageInHistory) {
                var self = this;

                var msg = {
                    Type: type || 'chat',
                    To: recipient,
                    From: self.options.userName,
                    FromUserId: self.options.userId,
                    Body: message.text ? message.text : message,
                    Timestamp: new Date().getTime(),
                    MessageId: PubnubClient.guid()
                };

                msg.SentTimestamp = self.serverNow();

                try {
                    if (msg.Type === 'chat') {
                        self.logger.log('pubnub-client-debug: publish ' + msg.MessageId);
                    }

                    self.pubnub.publish({
                        channel: self.options.channelName,
                        message: msg,
                        storeInHistory: storeMessageInHistory
                    }, function (status) {
                        var response = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

                        try {
                            self.logger.log('pubnub-client-debug: publish.callback status.statusCode: ' + (status ? status.statusCode : '-') + ', status.error: ' + (status ? status.error : '-') + ', response.timetoken: ' + (response ? response.timetoken : '-'));

                            if (message.text) {
                                if (response && response.timetoken) {
                                    Ember.set(message, 'timetoken', response.timetoken);
                                }

                                if (status.error) {
                                    Ember.set(message, 'deliveryError', true);
                                    message.get('parent').set('deliveryStatus', 'error');
                                } else {
                                    Ember.set(message, 'sent', true);
                                    Ember.set(message, 'deliveryError', false);
                                    message.get('parent').set('deliveryStatus', 'sent');
                                }
                            }

                            if (status && status.statusCode === 200 && self.sentMessages) {
                                self.sentMessages.push(msg);
                            }
                            if (status.error) {
                                self.logger.log('pubnub-client-v4: publish status.error: ' + status.error);
                                if (self.options.callbacks.messageSent && self.options.callbacks.messageSent.failure) {
                                    Ember.run(function () {
                                        return self.LogExceptionWrapper(function () {
                                            return self.options.callbacks.messageSent.failure();
                                        }, 'messageSent.failure');
                                    });
                                    //Ember.run(() => self.options.callbacks.messageSent.failure());
                                }
                            } else if (self.options.callbacks.messageSent && self.options.callbacks.messageSent.success) {
                                Ember.run(function () {
                                    return self.LogExceptionWrapper(function () {
                                        return self.options.callbacks.messageSent.success();
                                    }, 'messageSent.success');
                                });
                                //Ember.run(() => self.options.callbacks.messageSent.success());
                            }
                        } catch (error) {
                            self.logger.log('pubnub-client-v4: publish error: ' + (error ? error.message : ''));
                        }
                    });
                } catch (error) {
                    console.log('pubnub.publish() failed!', error, 'Channel Name: ', self.options.channelName);
                    self.logger.log('pubnub-client-v4: pubnub.publish() failed!\', ' + error + ', Channel Name: ' + self.options.channelName);
                    if (self.options.callbacks.messageSent && self.options.callbacks.messageSent.failure) {
                        Ember.run(function () {
                            return self.LogExceptionWrapper(function () {
                                return self.options.callbacks.messageSent.failure();
                            }, 'messageSent.failure');
                        });
                        //Ember.run(() => self.options.callbacks.messageSent.failure());
                    }
                }
            }
        }, {
            key: 'sendChatMessage',
            value: function sendChatMessage(recipient, message) {
                this._publish(recipient, message, 'chat', true);
            }
        }, {
            key: 'sendPresencePingMessage',
            value: function sendPresencePingMessage(recipient, message) {
                this._publish(recipient, message, 'presencePing', false);
            }
        }, {
            key: 'sendStateMessage',
            value: function sendStateMessage(recipient, state) {
                this._publish(recipient, state, 'state', false);
            }
        }, {
            key: 'sendSystemMessage',
            value: function sendSystemMessage(recipient, message) {
                this._publish(recipient, message, 'system', true);
            }
        }, {
            key: 'setNewAuthKey',
            value: function setNewAuthKey(authKey) {
                this.pubnub.setAuthKey(authKey);
                this.options.authKey = authKey;
            }
        }], [{
            key: 'guid',
            value: function guid() {
                var uuid = PubNub.generateUUID();

                return uuid;
            }
        }]);

        return PubnubClient;
    }();

    exports.default = PubnubClient;
});