define('ingenio-web/components/featured-listings/bid-results', ['exports', 'ingenio-web/components/featured-listings/listing-bid-errors'], function (exports, _listingBidErrors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        bidResults: null,
        selectedListingId: null,
        enableListingSelect: false,
        accordianDurationMilliseconds: 400,

        bidContentContainerClassName: 'js_bid-content-container',
        bidContentContainerHiddenClassName: 'mobile-display',
        bidContentContainerMobileClassName: 'js_mobile-collapse',

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var firstListingToExpand = this.get('bidResults').get('firstObject');

            // Listing should pre-select the first listing when the component is first rendered.
            if (firstListingToExpand) {
                this.send('listingClicked', firstListingToExpand.id);
            }
        },


        selectedListing: function () {
            var selectedListingId = this.get('selectedListingId');

            if (!selectedListingId) {
                return null;
            }

            return this.get('bidResults').findBy('id', selectedListingId);
        }.property('selectedListingId', 'bidResults.@each.id'),

        disableGoNext: function () {
            var listing = this.get('selectedListing');

            return !listing || listing.get('bidEligibilityErrors.length') !== 0;
        }.property('selectedListing'),

        selectedListingBidErrorMessage: function () {
            var selectedListing = this.get('selectedListing');

            if (selectedListing && this.get('enableListingSelect')) {
                return this.getListingBidErrorMessage(selectedListing.get('bidEligibilityErrors'));
            }

            return null;
        }.property('selectedListing', 'enableListingSelect'),

        selectedListingHasError: function () {
            return this.get('selectedListingBidErrorMessage.length') > 0;
        }.property('selectedListing'),

        resultsTitle: Ember.computed('bidResults.@each.timePeriod', function () {
            if (this.get('enableListingSelect')) {
                var timePeriod = this.get('bidResults.firstObject.timePeriod');

                if (!timePeriod) {
                    return 'Results';
                }

                switch (timePeriod.toLowerCase()) {
                    case 'currentyear':
                        return 'Results Year To Date';

                    case 'currentquarter':
                        return 'Results Quarter To Date';
                }
            }

            return 'Results';
        }),

        bidContentContainerSelector: Ember.computed(function () {
            return this.$('.' + this.get('bidContentContainerClassName'));
        }).property('bidContentContainerClassName'),

        activeListingSelector: Ember.computed(function () {
            var filter = '[data-listingid="' + this.get('selectedListingId') + '"]';
            var containerMobileSelector = '.' + this.get('bidContentContainerMobileClassName');

            return this.get('bidContentContainerSelector').filter(filter).first().find(containerMobileSelector);
        }).property('selectedListingId', 'bidContentContainerSelector', 'bidContentContainerMobileClassName'),

        nonActiveListingSelector: Ember.computed(function () {
            var activeListingSelector = this.get('activeListingSelector');
            var containerMobileSelector = '.' + this.get('bidContentContainerMobileClassName');

            return this.get('bidContentContainerSelector').not(activeListingSelector.parent()).find(containerMobileSelector);
        }).property('activeListingSelector', 'bidContentContainerSelector', 'bidContentContainerMobileClassName'),

        _expandListingResults: function _expandListingResults() {
            var activeListingSelector = this.get('activeListingSelector');
            var nonActiveListingSelector = this.get('nonActiveListingSelector');
            var collapseListingClass = this.get('bidContentContainerHiddenClassName');

            var accordianDurationMilliseconds = this.get('accordianDurationMilliseconds');
            var animationEnabled = !this.$('.js_header-total-clicks').is(':visible');

            if (animationEnabled) {
                activeListingSelector.slideDown(accordianDurationMilliseconds, function () {
                    var activeListing = $(this);

                    activeListing.css('display', '');
                    activeListing.removeClass(collapseListingClass);
                });

                nonActiveListingSelector.slideUp(accordianDurationMilliseconds, function () {
                    var nonActiveListing = $(this);

                    nonActiveListing.addClass(collapseListingClass);
                    nonActiveListing.css('display', '');
                });
            } else {
                nonActiveListingSelector.addClass(collapseListingClass);
                activeListingSelector.removeClass(collapseListingClass);
            }
        },
        getListingBidErrorMessage: function getListingBidErrorMessage(errors) {
            var _this = this;

            if (!errors || errors.length === 0) {
                return '';
            }

            var result = '<ul>';

            errors.forEach(function (item) {
                result = result + '<li>' + _this.mapErrorStatusToDescription(item) + '</li>';
            });
            return result + '</ul>';
        },
        mapErrorStatusToDescription: function mapErrorStatusToDescription(errorStatus) {
            var item = _listingBidErrors.default.findBy('status', errorStatus);

            if (item) {
                return item.message;
            }

            return '';
        },


        actions: {
            listingClicked: function listingClicked(listingId) {
                this.set('selectedListingId', listingId);

                this._expandListingResults();
            },
            listingChanged: function listingChanged() {
                var listing = this.get('selectedListing.firstObject');

                if (!listing) {
                    console.error('A listing with the id "' + this.get('selectedListingId') + '" could not be found in the bid results collection.');
                    return;
                }

                this.sendAction('listingChanged', listing.get('id'), listing.get('listingTitle'), listing.get('listingCategory'));
            },
            previousStep: function previousStep() {
                this.sendAction('previousStep');
            },
            nextStep: function nextStep() {
                this.sendAction('nextStep');
            }
        }
    });
});