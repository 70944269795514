define('ingenio-web/components/listings/chat-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        mixpanelClient: Ember.inject.service('mixpanel-client'),

        linkButton: function () {
            var availability = this.get('availability');

            return !!(availability === 2 || this.shouldArrangeChat(availability));
        }.property('availability'),

        shouldArrangeChat: function shouldArrangeChat(availability) {
            var currentDomain = Ember.ENV.domainName;

            return availability === 4 || availability === 3 && currentDomain === 'Keen';
        },


        chatUrl: function () {
            var tid = this.get('tid');
            var listingId = this.get('listingId');
            var availability = this.get('availability');
            var webTrack = this.get('webTrack') || '';
            var clickId = this.get('clickId') || '';
            var tidParam = tid ? '&tid=' + tid : '';
            var arrangeChat = this.shouldArrangeChat(availability);

            if (arrangeChat) {
                return '/app/#/arrange-conversation/details?ctype=chat&listingid=' + listingId + '&pmode=0&webtrack=' + webTrack + '&clickid=' + clickId;
            }
            return '/Chat/ChatSetup.aspx?sid=' + listingId + tidParam + '&webtrack=' + webTrack + '&clickid=' + clickId;
        }.property('listingId', 'availability', 'webTrack', 'clickId'),

        chatButtonText: function () {
            var availability = this.get('availability');
            var isKeen = Ember.ENV.domainId === 1;

            if (availability === 2) {
                return isKeen ? 'Chat' : 'Chat Now';
            } else if (this.shouldArrangeChat(availability)) {
                return isKeen ? 'Arrange' : 'Arrange Chat';
            }

            return isKeen ? 'Chat' : 'Chat Later';
        }.property('availability'),

        arrangeChat: function () {
            var availability = this.get('availability');

            return this.shouldArrangeChat(availability) ? 'arrange-chat' : '';
        }.property('availability')
    });
});