define('ingenio-web/models/credit-card-token', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        tokenId: (0, _attr.default)('string'),
        cardType: (0, _attr.default)('string'),
        cardLastFourDigits: (0, _attr.default)('string')
    });
});