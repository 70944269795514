define('ingenio-web/serializers/offer', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        extractArray: function extractArray(store, type, payload) {
            //Wrapping payload with root element
            return this._super(store, type, { offers: payload });
        }
    });
});