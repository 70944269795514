define('ingenio-web/models/funds', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-data-model-fragments/attributes'], function (exports, _attr, _model, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        offerId: (0, _attr.default)('number'),
        cvvCode: (0, _attr.default)('string'),
        deviceId: (0, _attr.default)('string'),
        checkoutComDeviceId: (0, _attr.default)('string'),
        paymentMethodId: (0, _attr.default)('number'),
        eventSource: (0, _attr.default)('string', {
            defaultValue: 'WebSite'
        }),
        offerDisplayAmount: (0, _attr.default)('number'), // Todo: do not use, only required for international currency test

        // TODO: refactor API to utilize "totalAmount"
        amount: Ember.computed('amountAsNumber', {
            get: function get() {
                return this.get('amountAsNumber').toFixed(2);
            },
            set: function set(key, value) {
                // funds model currently is utilized one-way. Apply setter logic if needed for GET in addition to POST.
            }
        }),

        amountAsNumber: Ember.computed('offerAmount', 'additionalAmount', 'offerDisplayAmount', {
            get: function get() {
                var offerAmount = parseFloat(this.get('offerAmount') || 0);
                var additionalAmount = parseFloat(this.get('additionalAmount') || 0);
                var offerDisplayAmount = parseFloat(this.get('offerDisplayAmount') || 0);

                if (offerDisplayAmount != 0) {
                    return offerDisplayAmount + additionalAmount;
                }

                return offerAmount + additionalAmount;
            }
        }),

        // private local fields that are only utilized for tracking the amount breakdown.
        offerAmount: (0, _attr.default)('number'),
        additionalAmount: (0, _attr.default)('number'),
        additionalFundsInfo: (0, _attributes.fragment)('additionalFundsInfo')
    });
});