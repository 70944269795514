define("ingenio-web/arrange-conversation/confirmation/route", ["exports", "ingenio-web/utils/footer-types", "ingenio-web/mixins/reset-scroll-on-transition"], function (exports, _footerTypes, _resetScrollOnTransition) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    flashMessages: Ember.inject.service(),

    defineFooterType: function defineFooterType() {
      if (Ember.ENV.domainId === 6) {
        return _footerTypes.default.MINIMAL;
      }
      return _footerTypes.default.FULL;
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var model = this.modelFor(this.getParentRouteName());

      // just in case if user doesn't have arranged call - direct user to the confirmation page
      if (!model.get("advisorConversationRequest")) {
        this.replaceWith(this.getParentRouteName() + ".details", {
          queryParams: transition.queryParams
        });
      }

      if (model.get('isChatConversation') && model.get('advisorConversationRequest.status') === 'SERVICING') {
        var route = 'chat-setup.confirmation';

        this.replaceWith(route, {
          queryParams: {
            arrangeid: model.get('advisorConversationRequest.id'),
            listingid: model.get('listing.id')
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      model.set("userOperation", "View Arranged Conversation");

      model.get("notifications.success").forEach(function (item) {
        _this.flashMessages.success(item, {
          showProgress: true,
          timeout: 5000
        });
      });
      model.get("notifications.success").clear();

      model.get("notifications.errors").forEach(function (item) {
        _this.flashMessages.danger(item, {
          showProgress: true,
          timeout: 5000
        });
      });

      model.get("notifications.errors").clear();

      if (model.get('addedToWaitlist')) {
        setTimeout(function () {
          return window.location.href = '/arranged-conversations?tab=my-advisors';
        }, 3000);
        model.set('addedToWaitlist', false);
      }
    }
  });
});