define('ingenio-web/add-funds/details/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    addFundsFlowModel: Ember.computed(function () {
      return this.modelFor(this.getParentRouteName()); //Get model for routes like 'call-setup' or 'chat-setup'
    }),

    beforeModel: function beforeModel(transition) {
      if (!this.get('addFundsFlowModel.hasExistingPaymentMethods')) {
        this.replaceWith(this.getParentRouteName() + '.payment-details', {
          queryParams: transition.queryParams
        });
      }
    },
    model: function model() {
      return this.get('addFundsFlowModel');
    },
    setupController: function setupController(controller, model) {
      //this._super(...arguments);
      controller.set('model', model);
      controller.initialize();
    }
  });
});