define('ingenio-web/components/input-mask', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        attributeBindings: ['data-inputmask-regex', 'data-inputmask'],
        initializeMask: function () {
            var mask = this.get('mask');

            this.$().inputmask(mask, {
                onBeforeMask: function onBeforeMask(value) {
                    if (mask === 'mm/dd/yyyy') {
                        return (0, _moment.default)(new Date(value)).format('L');
                    }
                },
                placeholder: 'MM/DD/YYYY'
            });

            // The input mask changes the value of the input from the original to a
            // formatted version. We need to manually send that change back to the
            // controller.
            this.set('value', this.$().val());
        }.on('didInsertElement')
    });
});