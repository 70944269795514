define('ingenio-web/services/arrange-chat-logging', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    init: function init() {},
    trackEvent: function trackEvent(activityName, arrangeId, activityData) {
      if (!activityData) {
        activityData = {};
      }
      activityData.arrangeId = arrangeId;
      activityData.userId = Ember.Application.user.id;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax(Ember.ENV.getApiRoot() + '/arranged-chat/' + arrangeId + '/activities', {
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            activity: activityName,
            properties: activityData
          })
        }).then(function (result) {
          console.log('activity ' + activityName + ' for id: ' + arrangeId + ' logged succesfully', activityData);
          resolve(result);
        }).catch(function (error) {
          console.log('error: ' + error + ' reporting activity ' + activityName + ' for id: ' + arrangeId);
          reject(error);
        });
      });
    }
  });
});