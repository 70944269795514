define('ingenio-web/call/call-verification/controller', ['exports', 'ingenio-web/utils/pubnub-user-channel-client'], function (exports, _pubnubUserChannelClient) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        subscribedToChannel: false,
        cancelTestInProgress: false,
        verificationInProgress: false,
        currentUser: null,
        verificationErrorHeader: '',
        verificationErrorMessage: '',
        verificationErrorMessage2: '',
        verificationStatusMessage: '',
        verificationAdditionalMessage: '',
        verificationAdditionalMessage2: '',
        verificationResponse: null,
        screenId: 'Connecting',
        isKeen: function () {
            return Ember.ENV.domainId === 1;
        }.property(),

        init: function init() {
            this._super.apply(this, arguments);
            var currentUser = Ember.Application.user;

            this.set('currentUser', currentUser);
            this.setupPushChannel();
            this.set('domainName', Ember.ENV.domainName);
        },
        resetTest: function resetTest() {
            this.setProperties({
                cancelTestInProgress: false,
                verificationInProgress: false,
                verificationStatusMessage: '',
                verificationErrorHeader: '',
                verificationErrorMessage: '',
                verificationErrorMessage2: '',
                verificationAdditionalMessage: '',
                verificationAdditionalMessage2: '',
                verificationResponse: null,
                screenId: 'Connecting'
            });
        },
        setupPushChannel: function setupPushChannel() {
            var _this = this;

            var channel = this.store.createRecord('pubnubUserChannel');

            channel.save().then(function (savedChannel) {
                _this.subscribeToChannel(savedChannel);
            }).catch(function (error) {
                _this.toast.error('An error occured! Could not verify phone.');
                _this.get('loggerService').error('Error getting channel information', {}, error);
            });
        },
        subscribeToChannel: function subscribeToChannel(pubnubUserChannelRecord) {
            var _this2 = this;

            var logger = this.get('loggerService');
            // The channel info is available in the channel property.
            var channel = pubnubUserChannelRecord.get('channel');
            var userId = Ember.Application.user.id;
            var channelOptions = {
                channelName: channel.get('channelName'),
                subKey: channel.get('subscribeKey'),
                pubKey: channel.get('publishKey'),
                authKey: channel.get('authKey'),
                uuid: _pubnubUserChannelClient.default.guid(),
                subscribeState: {
                    clientType: 'callVerificationClient',
                    username: userId
                },
                disableTimeSync: true
            };

            var channelClient = new _pubnubUserChannelClient.default(userId, channelOptions, this.store, logger);

            channelClient.onConnect = function () {
                _this2.set('subscribedToChannel', true);
            };

            channelClient.onConnectFailed = function (error) {
                _this2.get('loggerService').log('callVerificationClient failed to connect to pubnub.', error);
            };

            channelClient.onCallVerificationMessage = this.handleCallVerificationMessage.bind(this);

            channelClient.subscribe();
            this.set('channelClient', channelClient);
        },
        handleCallVerificationMessage: function handleCallVerificationMessage(data) {
            var callVerificationId = this.get('verificationResponse.callVerificationId');

            // only display messages if the call verification id matches.
            if (callVerificationId === data.callVerificationID) {
                this.set('verificationStatusMessage', data.primaryMessage);
                this.set('verificationAdditionalMessage', data.additionalMessage);
                this.set('screenId', data.screenID);
                this.set('eventTime', data.EventTime);

                if (data.screenID === 'DTMFRequest') {
                    // verificationAdditionalMessage2 as sent as errorMessage for this state.
                    this.set('verificationAdditionalMessage2', data.errorMessage);
                } else {
                    this.set('verificationAdditionalMessage2', '');
                    this.set('verificationErrorMessage', data.errorMessage);
                }
            }
        },


        actions: {
            verify: function verify() {
                var _this3 = this;

                var userId = Ember.Application.user.id;
                var url = Ember.ENV.getApiRoot() + '/CallVerification?userId=' + userId;
                var domainName = Ember.ENV.domainName;

                this.set('verificationInProgress', true);
                this.set('verificationStatusMessage', domainName + ' is calling your primary phone number at ' + this.get('model.serverPhone'));
                this.set('verificationAdditionalMessage', 'When your phone rings, press 1 to verify your number.');

                Ember.$.post(url, {}).done(function (response) {
                    _this3.set('verificationResponse', response);
                }).fail(function (response) {
                    var verificationErrorHeader = response.responseJSON.header || '';
                    var additionalMessage = response.responseJSON.additionalMessage || '';
                    var errorMessage = response.responseJSON.Message || response.responseJSON.message;

                    // reset previous status message.
                    _this3.set('verificationAdditionalMessage', '');
                    _this3.set('verificationAdditionalMessage2', '');

                    // set error message and header
                    _this3.set('verificationErrorHeader', verificationErrorHeader);
                    _this3.set('verificationErrorMessage', additionalMessage);
                    _this3.set('verificationErrorMessage2', errorMessage);

                    _this3.get('loggerService').error('Error recieving verification request information', {}, response);
                });
            },
            cancelTest: function cancelTest() {
                var _this4 = this;

                //Do not trigger a cancel if one is already in progress.
                if (this.get('cancelTestInProgress')) {
                    return;
                }

                this.set('cancelTestInProgress', true);

                var callVerificationId = this.get('verificationResponse.callVerificationId');
                var userId = Ember.Application.user.id;
                var url = Ember.ENV.getApiRoot() + '/CallVerification?userId=' + userId + '&callverificationid=' + callVerificationId;

                $.ajax({
                    url: url,
                    type: 'PUT',
                    contentType: 'application/json',
                    success: function success() {
                        // Do something with the result
                        // this.resetTest();
                    },
                    error: function error(reason) {
                        var failureReason = reason.responseJSON.message || 'An unknown error occured!';

                        _this4.toast.error(failureReason, 'Oops! Could not cancel!');
                        _this4.get('loggerService').error('Error cancelling call.', {}, response);
                        _this4.set('cancelTestInProgress', false);
                    }
                });
            },
            testAgain: function testAgain() {
                //Chances are there that the user has changed their primary number. So, reload page to prevent cache issues.
                window.location.reload();
            }
        }
    });
});