define('ingenio-web/models/offer-price', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        PitchType: (0, _attr.default)('number'),
        OfferPitch: (0, _attr.default)('string'),
        LongDescription: (0, _attr.default)('string')
    });
});