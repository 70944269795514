define('ingenio-web/routes/direct-messages/inbox', ['exports', 'ingenio-web/routes/direct-messages/base-router', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types'], function (exports, _baseRouter, _headerTypes, _footerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRouter.default.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.FULL;
        },
        model: function model() {
            var userId = Ember.Application.user.id;

            return Ember.RSVP.hash({
                user: this.modelFor('application').get('userData'),
                messages: this.store.findAll('directMessage', { reload: true }).then(function (messages) {
                    return messages.map(function (m) {
                        if (m.get('fromUserId') === userId) {
                            m.set('recipientId', m.get('toUserId'));
                            m.set('isMine', true);
                        } else {
                            m.set('recipientId', m.get('fromUserId'));
                        }
                        m.set('unread', !m.get('isRead'));
                        return m;
                    }).reduce(function (rv, x) {
                        var key = x.get('recipientId');

                        (rv[key] = rv[key] || Ember.A()).addObject(x);
                        return rv;
                    }, {});
                }).then(function (aggregatedMessages) {
                    var result = [];

                    for (var key in aggregatedMessages) {
                        if (aggregatedMessages.hasOwnProperty(key)) {

                            var sortedArray = aggregatedMessages[key].toArray().sort(function (a, b) {
                                return a.get('id') - b.get('id');
                            });

                            var last = sortedArray[sortedArray.length - 1];

                            result.push(last);
                        }
                    }

                    return result.sort(function (a, b) {
                        return b.get('id') - a.get('id');
                    });
                })
            });
        }
    });
});