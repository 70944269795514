define('ingenio-web/adapters/call-post-pay-eligibility', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(modelName, id) {
            var apiRoot = Ember.ENV.getApiRoot();

            return apiRoot + '/users/' + id + '/call/post-pay-eligibility';
        }
    });
});