define('ingenio-web/controllers/myaccount/customer-details', ['exports', 'ingenio-web/models/customers/create-new-list-criteria', 'ingenio-web/utils/html-utils'], function (exports, _createNewListCriteria, _htmlUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('toast'),
    busy: Ember.inject.service('busy'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    preservedText: '',
    isEditNicknameMode: false,

    sampleText: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',

    customersGrid: null,

    _showDeleteConfirmationDialog: false,
    showDeleteConfirmationDialog: Ember.computed.equal('_showDeleteConfirmationDialog', true),

    _showBlockConfirmationDialog: false,
    showBlockConfirmationDialog: Ember.computed.equal('_showBlockConfirmationDialog', true),

    _showCreateNewListDialog: false,
    showCreateNewListDialog: Ember.computed.equal('_showCreateNewListDialog', true),

    isBlocked: function () {
      var list = this.get('model.customerLists').find(function (list) {
        return list.type === "Block";
      });
      if (parseInt(this.get('model.customer.list.id')) === list.id) {
        return true;
      }
      return false;
    }.property('model.customer.list'),

    disableSendEmail: function () {
      if (this.get('isBlocked')) {
        return true;
      }
      return false;
    }.property('model.customer.list'),

    disableSendOffer: function () {
      if (this.get('isBlocked')) {
        return true;
      }
      return false;
    }.property('model.customer.list'),

    disableBlock: function () {
      if (this.get('isBlocked')) {
        return true;
      }
      return false;
    }.property('model.customer.list'),

    disableSendPing: function () {
      if (this.get('isBlocked')) {
        return true;
      }
      return false;
    }.property('model.customer.list'),

    disableAssignToList: function () {
      return false;
    }.property('model.customer.list'),

    isKeen: function () {
      return !(Ember.ENV.domainId === 6);
    }.property('Ember.ENV.domainId'),

    assignToListItems: function () {
      var currentListId = this.get('model.customer.list.id');
      var customerLists = this.get('model.customerLists');
      var result = [];
      customerLists.forEach(function (customerList) {
        if (currentListId != customerList.id) {
          result.push(customerList);
        };
      });

      return result;
    }.property('model.customer.list'),

    assignToList: function assignToList(list) {
      var customerIds = [this.get('model.customer.id')];
      var advisorId = Ember.Application.user.id;

      return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists/' + list.id, {
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          customerIds: customerIds
        })
      });
    },
    errorMessage: function errorMessage(xhr) {
      if (!xhr || !xhr.responseJSON || !xhr.responseJSON.errors) return '';
      return xhr.responseJSON.errors.reduce(function (agregator, item) {
        if (agregator === '') return item.message;else return agregator + ', ' + item.message;
      }, '');
    },
    collapseAllSections: function collapseAllSections() {
      Ember.$('.collapse').collapse('hide');
    },


    handleEditMode: function () {
      var input = $('#nickname-field-regular');
      if (input.is(":hidden")) {
        input = $('#nickname-field-small');
      }

      if (this.get('isEditNicknameMode')) {
        input.removeAttr("readonly");
        input.focus();
      } else {
        input.attr("readonly", true);
      }
    }.observes('isEditNicknameMode'),

    actions: {
      sendEmail: function sendEmail() {
        if (this.get('disableSendEmail')) return;

        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.customer.userName'),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      sendOffer: function sendOffer() {
        if (this.get('disableSendOffer')) return;

        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.customer.userName'),
            type: "19",
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      confirmBlock: function confirmBlock() {
        if (this.get('disableBlock')) return;
        this.set('_showBlockConfirmationDialog', true);
      },
      cancelBlock: function cancelBlock() {
        this.set('_showBlockConfirmationDialog', false);
      },
      block: function block() {
        var _this = this;

        var me = this;
        me.get('busy').show();

        var list = this.get('model.customerLists').find(function (list) {
          return list.type === "Block";
        });
        return this.assignToList(list).then(function () {
          _this.set('model.customer.list', list);
          _this.set('model.customer.blockedOn', new Date().toISOString());

          _this.set('_showBlockConfirmationDialog', false);
          me.get('busy').hide();

          var notifications = me.get('notifications');
          notifications.info('Success', 'Block Customers', {
            positionClass: 'toast-top-right'
          });
        }, function (xhr) {
          me.get('busy').hide();

          var message = me.errorMessage(xhr);
          if (message === '') message = 'Error';else message = 'Error: ' + message;
          notifications.error(message, 'Block', {
            positionClass: 'toast-top-right'
          });
        });
      },
      sendPing: function sendPing() {
        if (this.get('disableSendPing')) return;

        var customerId = this.get('model.customer.id');
        if (window.location.hostname === 'localhost') {
          window.location.href = '/#/direct-messages/recipient/' + customerId;
        } else {
          window.location.href = '/app/#/direct-messages/recipient/' + customerId;
        }
      },
      assignToList: function assignToList(list) {
        var _this2 = this;

        var blockedlist = this.get('model.customerLists').find(function (list) {
          return list.type === "Block";
        });
        if (list.id === blockedlist.id) {
          this.actions.confirmBlock.bind(this)();
          return;
        }

        var me = this;
        var notifications = me.get('notifications');
        me.get('busy').show();

        return this.assignToList(list).then(function (response) {
          notifications.info('Success', 'Assign To List', {
            positionClass: 'toast-top-right'
          });

          _this2.set('model.customer.list', list);

          me.get('busy').hide();
        }, function (xhr) {
          me.get('busy').hide();

          var message = me.errorMessage(xhr);
          if (message === '') message = 'Error';else message = 'Error: ' + message;
          notifications.error(message, 'Assign To List', {
            positionClass: 'toast-top-right'
          });
        });
      },
      confirmCreateNewList: function confirmCreateNewList() {
        if (this.get('disableAssignToList')) return;

        this.set('model.newListCriteria', _createNewListCriteria.default.create());

        this.set('_showCreateNewListDialog', true);
      },
      createNewList: function createNewList(criteria) {
        var _this3 = this;

        if (this.get('disableAssignToList')) return;

        var me = this;

        var advisorId = Ember.Application.user.id;
        criteria.clearErrors();

        return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            name: criteria.get('listName')
          })
        }).then(function (response) {
          var list = response.customerList;

          me.get('model.customerLists').addObject(list);

          return me.assignToList(list).then(function (response) {
            _this3.set('_showCreateNewListDialog', false);

            _this3.set('model.customer.list', list);

            var notifications = me.get('notifications');
            notifications.info('Success', 'Create New List', {
              positionClass: 'toast-top-right'
            });
          }, criteria.handleError.bind(criteria));
        }, criteria.handleError.bind(criteria));
      },
      cancelCreateNewList: function cancelCreateNewList() {
        this.set('_showCreateNewListDialog', false);
      },
      saveKeynotes: function saveKeynotes() {
        var me = this;
        var notifications = me.get('notifications');
        me.get('busy').show();

        var advisorId = Ember.Application.user.id;
        var consumerId = this.get('model.customer.id');

        return Ember.$.ajax(Ember.ENV.getApiRoot() + '/users/' + advisorId + '/chat/note?consumerId=' + consumerId, {
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            chatAdvisorNote: {
              advisorId: Ember.Application.user.id,
              consumerId: this.get('model.customer.id'),
              note: this.get('model.customer.comment')
            }
          })
        }).then(function () {
          me.get('busy').hide();

          notifications.info('Success', 'Update Keynotes', {
            positionClass: 'toast-top-right'
          });
        }, function (xhr) {
          me.get('busy').hide();

          var message = xhr.responseJSON.Message;
          if (message === '') message = 'Error';else message = 'Error: ' + message;
          notifications.error(message, 'Assign To List', {
            positionClass: 'toast-top-right'
          });
        });
      },
      editNickname: function editNickname() {
        this.set('preservedText', this.get('value'));
        this.set('isEditNicknameMode', true);
      },
      saveNickname: function saveNickname() {
        var _this4 = this;

        var me = this;
        var notifications = me.get('notifications');

        var value = "";
        var regularInput = $('#nickname-field-regular');
        if (regularInput.is(":visible")) {
          value = regularInput.val();
        } else {
          value = $('#nickname-field-small').val();
        }

        this.set('model.customer.nickname', value);
        me.get('busy').show();

        var advisorId = Ember.Application.user.id;
        var customerId = this.get('model.customer.id');

        return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customers/' + customerId + '/nickname', {
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            nickname: this.get('model.customer.nickname')
          })
        }).then(function (result) {
          me.get('busy').hide();

          _this4.set('isEditNicknameMode', false);

          notifications.info('Success', 'Update Nickname', {
            positionClass: 'toast-top-right'
          });
        }, function (xhr) {
          me.get('busy').hide();

          var message = xhr.responseJSON.Message;
          if (message === '') message = 'Error';else message = 'Error: ' + message;
          notifications.error(message, 'Update Nickname', {
            positionClass: 'toast-top-right'
          });
        });
      },
      listNameTouched: function listNameTouched() {
        if (this.get('model.newListCriteria')) {
          this.get('model.newListCriteria').addTouched('listName');
        }
      },
      offersGridGoPreviousPage: function offersGridGoPreviousPage() {},
      offersGridGoNextPage: function offersGridGoNextPage() {},
      contactHistoryGridGoPreviousPage: function contactHistoryGridGoPreviousPage() {},
      contactHistoryGridGoNextPage: function contactHistoryGridGoNextPage() {},
      customerFeedbackGridGoPreviousPage: function customerFeedbackGridGoPreviousPage() {},
      customerFeedbackGridGoNextPage: function customerFeedbackGridGoNextPage() {},
      scrollToTop: function scrollToTop() {
        Ember.$("html, body").animate({ scrollTop: 0 }, 600);
      }
    }
  });
});