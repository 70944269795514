define('ingenio-web/objects/chat/chat-notification', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ChatNotification = Ember.Object.extend({
        text: '', //clear text to force hide.
        closeAfter: null, //optional Number. Use null to never close. Default is null.
        hideCloseButton: true //optional Boolean. Defaults to true.
    });

    exports.default = ChatNotification;
});