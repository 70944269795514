define('ingenio-web/components/transaction-feedback', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        rating: Ember.computed({
            get: function get(key) {
                return parseInt(Ember.$('#input-id').val());
            },
            set: function set(key, value) {
                Ember.$('#input-id').rating('update', value);
                return parseInt(value);
            }
        }),
        lowRating: Ember.computed.lte('rating', 2),
        feedbackType: Ember.computed('lowRating', 'selectedfeebackType', function () {
            var lowRating = this.get('lowRating');
            var selectedfeebackType = this.get('selectedfeebackType');

            return lowRating ? selectedfeebackType : 2;
        }),
        technicalFeedback: Ember.computed.equal('feedbackType', 1),
        advisorFeedback: Ember.computed.equal('feedbackType', 2),
        advisorFeedbackComment: null,
        disallowSubmitFeedback: Ember.computed('feedbackType', 'isRateable', 'advisorFeedbackComment', 'orignalAdvisorFeedbackComment', 'feedbackInprogress', function () {
            var feedbackType = this.get('feedbackType');
            var isRateable = this.get('isRateable');
            var advisorFeedbackComment = this.get('advisorFeedbackComment');
            var orignalAdvisorFeedbackComment = this.get('orignalAdvisorFeedbackComment');
            var feedbackInprogress = this.get('feedbackInprogress');

            return feedbackType !== 1 && feedbackType !== 2 || !isRateable && advisorFeedbackComment === orignalAdvisorFeedbackComment || feedbackInprogress;
        }),

        feedbackCommentPlaceholder: Ember.computed('isKeen', function () {
            return this.get("isKeen") ? 'Your feedback helps other customers decide whom to choose. Please be responsible, constructive and in accordance with Keen\'s policy.' : 'Your feedback helps other customers decide whom to choose. Please be responsible, constructive and in accordance with PsychicCenter\'s policy.';
        }),

        isKeen: Ember.computed(function () {
            return Ember.ENV.domainId === 1;
        }),

        feedbackPolicyHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360060613533-Feedback-Policy';
            }
            return '/help/feedbackpolicy';
        }.property(),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('feedbackInprogress', false);

            var isRateable = this.get('isRateable');

            $('#rating').rating('refresh', {
                starwidth: 30,
                starheight: 30,
                resetable: false,
                step: 1,
                min: 0,
                max: 5,
                showClear: false,
                showCaption: false,
                readonly: !isRateable
            });

            var strengths = [{ Id: 'Honest', Name: 'Honest' }, { Id: 'Kind', Name: 'Kind' }, { Id: 'Helpful', Name: 'Helpful' }, { Id: 'Detailed', Name: 'Detailed' }, { Id: 'Accurate', Name: 'Accurate' }];

            this.set('strengths', strengths);

            var rating = this.get('rating');
            if (!!rating && $('#rating').val() != rating) $('#rating').rating('update', rating);
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);

            var isRateable = this.get('isRateable');
            $('#rating').rating('refresh', {
                starwidth: 30,
                starheight: 30,
                resetable: false,
                step: 1,
                min: 0,
                max: 5,
                showClear: false,
                showCaption: false,
                readonly: !isRateable
            });

            var rating = this.get('rating');
            if (!!rating && $('#rating').val() != rating) $('#rating').rating('update', rating);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var self = this;
            Ember.run.scheduleOnce('afterRender', this, function () {
                var sessionId = this.get('sessionId');

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/chat/' + sessionId + '/feedback', {
                    method: 'GET',
                    headers: {
                        'X-EpcApi-ID': _cookies.default.getSessionId(),
                        'X-Domain-ID': Ember.ENV.domainId
                    }
                }).done(function (data) {
                    self.set('rating', data.rating);
                    self.set('advisorFeedbackComment', data.comment);
                    self.set('orignalAdvisorFeedbackComment', data.comment);
                    self.set('selectedfeebackType', 2);
                }).always(function () {
                    var rating = self.get('rating');
                    var isRateable = self.get('isRateable');
                    self.$('#rating').rating({
                        starwidth: 30,
                        starheight: 30,
                        resetable: false,
                        step: 1,
                        min: 0,
                        max: 5,
                        showClear: false,
                        showCaption: false,
                        readonly: !isRateable
                    }).on('rating.change', function (event, value, caption) {
                        self.set('rating', Number(value));
                        self.set('selectedfeebackType', null);

                        if (!self.get('lowRating')) {
                            self.actions.selectFeedbackType.bind(self)(1);
                        }
                    });

                    if (rating) self.$('#rating').rating('update', rating);
                });
            });
        },


        showFeedbackStrengths: Ember.computed('rating', function () {
            var isKeen = Ember.ENV.domainId === 1;

            var rating = this.get('rating');
            var showStrengths = rating == 4 || rating == 5;

            return isKeen && showStrengths;
        }),

        saveFeedback: function saveFeedback(rating, comment, callback) {
            var _this = this;

            var self = this;
            var sessionId = this.get('sessionId');

            var settingRating = rating !== undefined && rating !== null;

            if (!settingRating) {
                rating = this.get('rating');
            }

            var settingComment = comment !== undefined && comment !== null;

            if (!settingComment) {
                comment = this.get('advisorFeedbackComment');
            }

            this.set('feedbackInprogress', true);

            var strengthsSelected = [];
            if (this.get('showFeedbackStrengths')) {
                this.get('strengths').forEach(function (strength) {
                    if (strength.IsSelected) {
                        strengthsSelected.push(strength.Id);
                    }
                });
            }

            Ember.$.ajax(Ember.ENV.getApiRoot() + '/chat/' + sessionId + '/feedback', {
                method: 'POST',
                headers: {
                    'X-EpcApi-ID': _cookies.default.getSessionId(),
                    'X-Domain-ID': Ember.ENV.domainId
                },
                data: {
                    feedbackDate: new Date(),
                    rating: rating,
                    comment: comment,
                    memberId: Ember.Application.user.id,
                    strengths: strengthsSelected
                }
            }).done(function () {
                if (callback) {
                    callback();
                }
                _this.set('feedbackInprogress', false);
            }).fail(function (response) {
                var jsonError = response.responseJSON;

                if (jsonError) {
                    var errorMessage = jsonError.Message;
                    if (errorMessage) {
                        _this.set('showFeedbackError', true);

                        if (errorMessage === "ProfanityFeedbackDetected") {
                            _this.set('isProfanityFeedbackError', true);
                        } else {
                            _this.set('isProfanityFeedbackError', false);
                            _this.set('feedbackError', errorMessage);
                        }

                        _this.set('feedbackInprogress', false);
                    }
                }
            });
        },

        handleFeedbackSubmission: function handleFeedbackSubmission() {
            this.set('feedbackSent', true);
            if (this.get('onFeedbackSubmission')) {
                this.get('onFeedbackSubmission')();
            }
        },


        actions: {
            selectFeedbackType: function selectFeedbackType(feebackType) {
                var _this2 = this;

                this.set('selectedfeebackType', Number(feebackType));

                var feedbackType = this.get('feedbackType');

                Ember.run.later(this, function () {
                    var placeholder = _this2.get('feedbackCommentPlaceholder');
                    if (feedbackType === 1) {
                        placeholder = 'Please provide as much information as possible about the issue so that we can assist you.';
                    }
                    Ember.$("#feedbackComment").attr('placeholder', placeholder);
                }, 100);
            },
            saveFeedback: function saveFeedback() {
                var _this3 = this;

                var feedbackType = this.get('feedbackType');
                var rating = this.get('rating');
                var sessionId = this.get('sessionId');
                var comments = this.get('advisorFeedbackComment');
                var isFeedbackQualifiedForDisplay = this.get('isFeedbackQualifiedForDisplay');
                var lowRating = this.get('lowRating');
                var tempFeedbackType = feedbackType;

                //if feedback is not qualified for display, force to submit
                //as an advisor issue
                if (lowRating && !isFeedbackQualifiedForDisplay) {
                    tempFeedbackType = 2;
                }

                switch (tempFeedbackType) {
                    case 1:
                        var feedback = {
                            rating: rating,
                            sessionId: sessionId,
                            sessionType: 4,
                            fromAdvisor: false,
                            reasonIds: [],
                            comment: comments
                        };

                        this.get('store').createRecord('technicalFeedback', feedback).save().then(function () {
                            _this3.handleFeedbackSubmission();
                        }).catch(function (e) {
                            console.error(e);
                            _this3.set('status', 'error');
                        });

                        break;
                    case 2:
                        this.saveFeedback(null, comments, function () {
                            _this3.handleFeedbackSubmission();
                        });
                        break;
                }
            },
            clickStrength: function clickStrength(strength) {
                strength.IsSelected = !strength.IsSelected;

                Ember.$("#strength_" + strength.Id).attr('selected', strength.IsSelected);
            }
        }
    });
});