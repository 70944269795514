define('ingenio-web/models/recommended-advisor', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        listingId: (0, _attr.default)('number'),
        advisorId: (0, _attr.default)('number'),
        available: (0, _attr.default)('boolean', { defaultValue: false }),
        title: (0, _attr.default)('string'),
        cost: (0, _attr.default)('string'),
        imageUrl: (0, _attr.default)('string'),
        detailsUrl: (0, _attr.default)('string'),
        advisorName: (0, _attr.default)('string'),
        rank: (0, _attr.default)('number'),
        starRating: (0, _attr.default)('number'),
        ratingCount: (0, _attr.default)('number'),
        lifetimeTransactionCount: (0, _attr.default)('number'),
        //TODO: check that code
        listingInfo: (0, _attributes.fragment)('listing') //??? listing is not frabment
    });
});