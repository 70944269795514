define('ingenio-web/components/checkout-flow/payment-options/seamless/component', ['exports', 'ingenio-web/objects/conversation-payment-methods'], function (exports, _conversationPaymentMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['conversation-payment-options-container'],

    actions: {
      onPayAfterConversationSelected: function onPayAfterConversationSelected() {
        this.attrs.onConversationPaymentMethodChanged(_conversationPaymentMethods.default.PayAfterConversation);
      },
      onPayBeforeConversationSelected: function onPayBeforeConversationSelected() {
        this.attrs.onConversationPaymentMethodChanged(_conversationPaymentMethods.default.PayBeforeConversation);
      }
    }
  });
});