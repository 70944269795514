define('ingenio-web/components/chat/chat-main-window', ['exports', 'ingenio-web/helpers/platform-name'], function (exports, _platformName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            scrollChatWindowToBottom: function scrollChatWindowToBottom() {
                var messagesDiv = this.$('#main-window');
                var messageSpacer = this.$('.message-spacer');
                var chatMessagesDiv = this.$('.chat-messages');

                if (messagesDiv) {
                    messagesDiv.scrollTop(messagesDiv.get(0).scrollHeight);
                }
                //only add height to spacer if the platform is iOS.
                //On IOS devices, when keyboard is up top few messages are not visible. This "hack" is to display messages from bottom to top
                if ((0, _platformName.isIOS)()) {
                    messageSpacer.height(messagesDiv.height() - chatMessagesDiv.height() - 10);
                }
            }
        }
    });
});