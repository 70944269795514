define('ingenio-web/components/myaccount/customers-grid', ['exports', 'moment', 'lodash', 'ingenio-web/utils/customer-utils'], function (exports, _moment, _lodash, _customerUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gridApi: null,
    gridColumnApi: null,
    busy: Ember.inject.service('busy'),
    notifications: Ember.inject.service('toast'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    query: 'query ($listId: Int, $first: Int, $after: String, $last: Int, $before: String, $filter: String, $sortBy: [String], $sortDescending: [Boolean], $fetch: Int, $offset: Int) { user { id customers(first: $first, after: $after, last: $last, before: $before, filter: $filter, sortBy: $sortBy, sortDescending: $sortDescending, listId: $listId, fetch: $fetch, offset: $offset) { totalCount pageInfo { hasNextPage hasPreviousPage } edges { cursor node { id userName nickname customerSince alerts { name } contacts { last { id activityId mailId masterTransactionId date amount { amount displayAmount(format: "c2") } type } } cumulativeSummary { totalCallCount totalChatCount totalPaidMails totalEarnings { amount displayAmount(format: "c2") } } list { id name } } } } } }',

    didInsertElement: function didInsertElement() {
      this.set('gridOptions.parent', this);
      this.set('register-as', this);
    },

    gridOptions: {
      pagination: true,
      paginationPageSize: 25,
      suppressPaginationPanel: true,
      gridAutoHeight: true,
      enableColResize: true,
      enableSorting: true,
      suppressCellSelection: true,
      suppressClickEdit: true,
      multiSortKey: 'ctrl',
      sortingOrder: ['desc', 'asc'],
      rowSelection: 'multiple',
      rowGroupPanelShow: 'never',

      parent: null,
      icons: {
        sortAscending: '<i class="grid-sort-icon fa fa-caret-up"/>',
        sortDescending: '<i class="grid-sort-icon fa fa-caret-down"/>'
      },

      columnDefs: [{
        //headerName: '<span class="select-checkbox fa fa-square-o" data-selected="false"></span> Customer',
        headerName: 'Customer',
        headerClass: 'col-header-name',
        field: 'userName',
        width: 180,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        pinned: true,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return null;
          }

          var newLabel = '';

          if (params.data && params.data.customerSince && params.data.customerSince !== '0001-01-01') {
            var date = (0, _moment.default)(params.data.customerSince);

            if (date.isSameOrAfter((0, _moment.default)().subtract(30, 'days'))) {
              newLabel = '<span class="new-customer-label">new</span>';
            }
          }
          var url = '#/myaccount/customers/' + params.data.id;

          return '<a class="customer-link" href=\'' + url + '\' data-object=\'' + JSON.stringify(params.data) + '\'>' + params.data.userNameMarked + '</a> ' + newLabel;
        }
      }, {
        headerName: 'Nickname',
        field: 'nickname',
        width: 150,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return null;
          }

          return '' + params.data.nicknameMarked;
        }
      }, {
        headerName: '<span class="fa fa-flag-o alert-column-header">&nbsp;&nbsp;</span>',
        headerClass: 'col-header-alerts',
        field: 'alerts',
        suppressMenu: true,
        width: 60,
        suppressResize: true,
        suppressSizeToFit: true,
        cellRenderer: function cellRenderer(params) {
          if (!params || !params.data || !_lodash.default.isArray(params.data.alerts)) {
            return '';
          }
          var result = params.data.alerts.reduce(function (accumulator, currentValue) {
            var text = '';

            switch (currentValue.name) {
              case 'MISSED_LAST_CALL':
                text = '<i class="fa fa-flag-o grid-alert missed-call" title="Missed Call"></i>';
                break;
              default:
                text = '';
            }
            return accumulator + text;
          }, '');

          return result;
        }
      }, {
        headerName: 'Last Contact',
        headerClass: 'col-header-last-contact',
        field: 'contacts.last.date',
        width: 120,
        sort: 'desc',
        suppressMenu: true,
        suppressSizeToFit: true,
        valueFormatter: function valueFormatter(params) {
          if (!params.value && !params.data) {
            return '';
          }

          var value = params.value || params.data && params.data.customerSince;

          if (!value || value === '0001-01-01') {
            return '';
          }
          return (0, _moment.default)(value).format('MM/DD/YYYY');
        }
      }, {
        headerName: 'Last $',
        headerClass: 'col-header-last-money money-column',
        colId: 'contacts.last.amount.amount',
        field: 'contacts.last.amount',
        width: 100,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true,
        valueFormatter: function valueFormatter(params) {
          return params.value && params.value.amount ? params.value.displayAmount : '‒';
        }
      }, {
        headerName: 'Contact Type',
        headerClass: 'col-header-contact-type',
        field: 'contacts.last.type',
        width: 120,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return '';
          }

          var url = _customerUtils.default.getLastActivityUrl(params.data);
          var text = _customerUtils.default.getFormattedText(params.data);

          if (!url) {
            return text;
          }
          return '<a class="contact-type-link" href="' + url + '" data-object=\'' + JSON.stringify(params.data) + '\'>' + text + ' </a>';
        }
      }, {
        headerName: '<img class="icon-phone" src="assets/svg/phone.svg">',
        headerClass: 'col-header-total-calls numeric-column',
        field: 'cumulativeSummary.totalCallCount',
        width: 60,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true
      }, {
        headerName: '<img class="icon-chat" src="assets/svg/chat.svg">',
        headerClass: 'col-header-total-chats numeric-column',
        field: 'cumulativeSummary.totalChatCount',
        width: 60,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true
      }, {
        headerName: '<img class="icon-paid-email" src="assets/svg/paid_mail.svg">',
        headerClass: 'col-header-total-emails numeric-column',
        field: 'cumulativeSummary.totalPaidMails',
        width: 60,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true
      }, {
        headerName: 'Lifetime $',
        headerClass: 'col-header-lifetime-revenue money-column',
        colId: 'cumulativeSummary.totalEarnings.amount',
        field: 'cumulativeSummary.totalEarnings',
        width: 100,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true,
        valueFormatter: function valueFormatter(params) {
          return params.value && params.value.amount ? params.value.displayAmount : '‒';
        }
      }, {
        headerName: 'Customer List',
        headerClass: 'col-header-customer-list',
        field: 'list.name',
        width: 150,
        suppressMenu: true
      }],

      onGridReady: function onGridReady(params) {
        if (this.parent && this.parent.onGridReady) {
          this.parent.onGridReady(params);
        }
      }
    },

    // bind all required events
    onGridReady: function onGridReady(params) {
      // get the current grids width and based on it define how columns are sized
      var gridWidth = this.get('element').offsetWidth;

      if (gridWidth > 1000) {
        params.api.sizeColumnsToFit();
      }

      /*
      // temporary log grid events
      params.api.addGlobalListener(function (type, event) {
        if (type.includes('cellMouse') || type.includes('columnHover')) return;
        console.log('Got event: ', event);
      });
      */

      // control sort change
      params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));

      // control row selection
      params.api.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));

      // just in case store refs to grid and column api
      this.set('gridApi', params.api);
      this.set('gridColumnApi', params.columnApi);

      // get dats from server side
      this.refreshGrid();
    },

    // control grid pagination
    gridActualPageSizeChanged: function () {
      if (this.get('gridApi')) {
        this.get('gridApi').paginationSetPageSize(this.get('pagination.actualPageSize'));

        if (this.get('pagination.actualPageSize') == 0) {
          this.get('gridApi').showNoRowsOverlay();
        }
      }
    }.observes('pagination.totalRecords', 'pagination.actualPageSize'),

    gridPaginationChanged: function () {
      this.refreshGrid();
    }.observes('pagination.currentPage', 'pagination.pageSize'),

    // control grid row selection
    onSelectionChanged: function onSelectionChanged() {
      var _this = this;

      if (!this.get('selectedRows')) {
        return;
      }

      this.get('selectedRows').clear();

      var gridSelectedRows = this.get('gridApi').getSelectedRows();

      gridSelectedRows.forEach(function (selectedRow) {
        _this.get('selectedRows').addObject(selectedRow);
      });
    },

    // handle grid sorting
    onSortChanged: function onSortChanged(params) {
      this.refreshGrid();
    },

    // retreive grid data
    refreshGrid: function refreshGrid() {
      var _this2 = this;

      var gridApi = this.get('gridApi');

      gridApi.deselectAll();
      this.get('busy').show();
      this.get('gridApi').hideOverlay();

      var sorting = '';

      gridApi.getSortModel().forEach(function (item) {
        if (sorting === '') {
          sorting = item.colId + ' ' + item.sort;
        } else {
          sorting = sorting + ', ' + item.colId + ' ' + item.sort;
        }
      });

      var listId = parseInt(this.get('filtration.customerList'));

      if (listId === '0') {
        listId = null;
      }

      return Ember.RSVP.hash({
        graphQLResult: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphqlv0', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            query: this.get('query'),
            variables: {
              offset: this.get('pagination.fromRecordNumber') - 1,
              fetch: this.get('pagination.toRecordNumber') - this.get('pagination.fromRecordNumber') + 1,
              filter: this.get('filtration.customerName').trim(),
              sortBy: sorting ? sorting.split(', ').map(function (s) {
                return s.split(' ')[0];
              }) : null,
              sortDescending: sorting ? sorting.split(', ').map(function (s) {
                return s.split(' ')[1] !== 'asc';
              }) : null,
              listId: listId
            }
          })
        })
      }).then(function (result) {
        var customersConnection = result.graphQLResult.data.user.customers;

        _this2.get('pagination').set('totalRecords', customersConnection.totalCount);

        var filter = _this2.get('filtration.customerName').trim();
        var htmlEscape = function htmlEscape(s) {
          return s ? s.replace(/&/g, '&amp;').replace(/</g, '&lt;') : s;
        };
        var replacementRegex = filter ? new RegExp(htmlEscape(filter).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i') : null;

        var mark = function mark(text) {
          text = htmlEscape(text);
          return replacementRegex && text ? text.replace(replacementRegex, '<mark>$&</mark>') : text;
        };

        gridApi.setRowData(customersConnection.edges.map(function (edge) {
          edge.node.userNameMarked = mark(edge.node.userName);
          edge.node.nicknameMarked = mark(edge.node.nickname);
          return edge.node;
        }));
      }, function () {
        _this2.get('notifications').error('Error getting data from server', 'Customers Grid', {
          positionClass: 'toast-top-right'
        });
      }).finally(function () {
        _this2.get('busy').hide();
      });
    }
  });
});