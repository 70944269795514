define('ingenio-web/chat-setup/confirmation/route', ['exports', 'ingenio-web/mixins/checkout-flow/confirmation/route', 'ingenio-web/mixins/checkout-flow/conversation-payment-method-route-check'], function (exports, _route, _conversationPaymentMethodRouteCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend(_conversationPaymentMethodRouteCheck.default, {
    flashMessages: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.get('isArrangedChatError')) {
        var message = model.get('arrangedChatErrorMessage');
        this.flashMessages.danger(message, {
          extendedTimeout: 3000,
          destroyOnClick: true
        });
      }
    },


    actions: {
      didTransition: function didTransition() {
        this.get('mixpanelClient').trackNewEvent('Chat Setup - Confirmation Viewed');
      }
    }
  });
});