define('ingenio-web/controllers/featured-listings/bid/confirm-bid', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        needToAddMoney: false,
        addMoneySettings: null,
        bidAmount: 0,
        budgetAmount: 0,

        insufficientFundsErrorMessage: 'InsufficientFunds',

        actions: {
            confirm: function confirm() {
                var _this = this;

                this.saveBid().then(function () {
                    return _this.send('nextStep');
                }).catch(function (error) {
                    return _this.handleBidApiError(error);
                });
            },


            moneyAdded: function moneyAdded() {
                this.set('needToAddMoney', false);
            },

            cancelAddMoney: function cancelAddMoney() {
                this.set('needToAddMoney', false);
            }
        },

        saveBid: function saveBid() {
            var _this2 = this;

            var bid = this.get('model.bid');
            var bidSnapshot = bid.toJSON();

            // persist amounts
            this.set('bidAmount', bid.get('bidAmount'));
            this.set('budgetAmount', bid.get('budgetAmount') || 0);

            var newBid = Ember.isEmpty(bid.get('id'));
            var cancelBid = this.get('model.cancelBid');

            return new Ember.RSVP.Promise(function (resolve, reject) {
                if (newBid) {
                    bid.validate().then(function (payload) {
                        bid.save();

                        _this2.get('mixpanelClient').trackNewEvent('FL Bid Set', {
                            eventData: {
                                listing_id: _this2.get('model.listing.id'),
                                is_created: true
                            }
                        });

                        resolve(payload);
                    }).catch(function (error) {
                        return reject(error);
                    });
                } else if (cancelBid) {
                    bid.destroyRecord().then(function () {
                        _this2.get('mixpanelClient').trackNewEvent('FL Bid Cancelled', {
                            eventData: {
                                listing_id: _this2.get('model.listing.id')
                            }
                        });

                        resolve(null);
                    });
                } else {
                    var updatedBid = _this2.store.createRecord('bid', bidSnapshot);

                    updatedBid.validate().then(function () {
                        bid.destroyRecord().then(function () {
                            resolve(updatedBid.save());

                            _this2.get('mixpanelClient').trackNewEvent('FL Bid Set', {
                                eventData: {
                                    listing_id: _this2.get('model.listing.id'),
                                    is_created: false
                                }
                            }).catch(function (error) {
                                return reject(error);
                            });
                        }).catch(function (error) {
                            return reject(error);
                        });
                    });
                }
            });
        },
        handleBidApiError: function handleBidApiError(error) {
            var insufficientFundsErrorMessage = this.get('insufficientFundsErrorMessage');
            var errorMessage = !Ember.isEmpty(error.errors) ? Ember.get(error, 'errors.errorMessage') || Ember.get(error, 'errors.firstObject.detail') : Ember.get(error, 'message');

            if (error && error.errors && error.errors.errorCode === 'InsufficientFunds' || errorMessage && (errorMessage === insufficientFundsErrorMessage || errorMessage.Code === insufficientFundsErrorMessage) || errorMessage instanceof Array && errorMessage.includes(insufficientFundsErrorMessage)) {
                this.resetDirtyBidOnError();

                this.handleInsufficientFundsError();

                return;
            }

            // in the event the api bombs, rollback any local DS changes & then reapply bid changes.
            this.resetDirtyBidOnError();

            throw new Error(errorMessage);
        },


        // Calculates shortfall amount and launches
        // add money flow with the calculated settings
        handleInsufficientFundsError: function handleInsufficientFundsError() {
            var _this3 = this;

            this.getUserShortfallAmount(Number(this.get('bidAmount')), Number(this.get('budgetAmount'))).then(function (shortfallAmount) {
                _this3.set('addMoneySettings', {
                    title: 'Add money to feature your listings',
                    minimumAmountToAdd: Number(shortfallAmount.toFixed(2)),
                    maximumAmountToAdd: 1000,
                    amountAdded: 0
                });

                _this3.set('needToAddMoney', true);
            });
        },


        // Calculates user shortfall amount based on a bid
        // and user balance
        getUserShortfallAmount: function getUserShortfallAmount(bidAmount, budgetAmount) {
            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            var requiredAmount = bidAmount > budgetAmount ? bidAmount : budgetAmount;

            var result = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/balance').then(function (response) {
                    var userBalance = response;

                    if (userBalance.monetary < requiredAmount) {
                        var shortfallAmount = requiredAmount - userBalance.monetary;

                        var minAmount = 10;
                        shortfallAmount = shortfallAmount < minAmount ? minAmount : shortfallAmount;

                        resolve(shortfallAmount);
                    } else {
                        resolve(0);
                    }
                }, function (error) {
                    reject(error);
                });
            });

            return result;
        },
        resetDirtyBidOnError: function resetDirtyBidOnError() {
            if (!this.get('model.bid')) {
                return;
            }

            var bidAmount = this.get('bidAmount');
            var budgetAmount = this.get('budgetAmount');

            if (!this.get('model.bid.isNew')) {
                this.get('model.bid').rollbackAttributes();
            }

            this.set('model.bid.bidAmount', bidAmount);
            this.set('model.bid.budgetAmount', budgetAmount);
        }
    });
});