define('ingenio-web/models/special-offer', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var OfferType = Object.freeze({
        Money: 1,
        Minutes: 2
    });

    exports.default = _model.default.extend({
        userId: (0, _attr.default)('number'),
        serviceId: (0, _attr.default)('number'),
        flashSaleOfferText: (0, _attr.default)('string'),
        tinyDescription: (0, _attr.default)('string'),
        shortDescription: (0, _attr.default)('string'),
        longDescription: (0, _attr.default)('string'),
        startDate: (0, _attr.default)('date'),
        endDate: (0, _attr.default)('date'),
        offerRedeemed: (0, _attr.default)('date'),
        expirationDate: (0, _attr.default)('serverDate'),
        expireInDays: (0, _attr.default)('number'),
        rewardExpired: (0, _attr.default)('date'),
        readTerms: (0, _attr.default)('boolean'),
        terms: (0, _attr.default)('string'),
        origin: (0, _attr.default)('number'),
        freeMinutes: (0, _attr.default)('number'),
        rewardStatus: (0, _attr.default)('number'),
        rewardType: (0, _attr.default)('number'),
        rewardAmount: (0, _attr.default)('number'),
        qualifierAmount: (0, _attr.default)('number'),
        isOptInOffer: (0, _attr.default)('boolean'),
        assetRequirementType: (0, _attr.default)('number'),
        isOfferAssociatedAndNotRewarded: (0, _attr.default)('boolean', {
            default: false
        }),
        isRetentionOffer: (0, _attr.default)('boolean', {
            default: false
        }),

        rewardMinutes: Ember.computed('rewardType', 'rewardAmount', function () {
            return this.get('rewardType') === OfferType.Minutes ? Number(this.get('rewardAmount')) : 0.00;
        }),

        rewardMoney: Ember.computed('rewardType', 'rewardAmount', function () {
            return this.get('rewardType') === OfferType.Money ? Number(this.get('rewardAmount')) : 0.00;
        }),

        ignoreOfferForCheckoutFlow: Ember.computed('isRetentionOffer', 'isOfferAssociatedAndNotRewarded', function () {
            return this.get('isRetentionOffer') || !this.get('isOfferAssociatedAndNotRewarded');
        })
    });
});