define('ingenio-web/components/supplementary-rewards-points/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var TranscendTier = 'transcend';

	exports.default = Ember.Component.extend({
		isKeenRewardsTranscendTier: Ember.computed('fees.data.fees.charges', function () {
			var charges = this.get('fees.data.fees.charges');
			if (Ember.isEmpty(charges)) {
				return false;
			}

			var discount = charges[0].discount;
			if (discount) {
				var tier = discount.reason;

				if (tier) {
					return tier.toLowerCase() === TranscendTier;
				}
			}

			return false;
		})

	});
});