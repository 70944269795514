define('ingenio-web/serializers/listings-search', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
            var newPayload = { listingsSearch: payload };

            return this._super(store, primaryModelClass, newPayload);
        }
    });
});