define('ingenio-web/models/additional-funds-info', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        priceBucketMinutes: (0, _attr.default)('string', { defaultValue: '' }),
        source: (0, _attr.default)('string', { defaultValue: '' }),
        rechargeMethod: (0, _attr.default)('string', { defaultValue: '' })
    });
});