define('ingenio-web/services/connection', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.online = navigator.onLine;
            window.addEventListener('online', Ember.run.bind(this, this.handleReconnection));
            window.addEventListener('offline', Ember.run.bind(this, this.handleDisconnection));
        },


        online: false,

        handleDisconnection: function handleDisconnection() {
            this.set('online', false);
        },
        handleReconnection: function handleReconnection() {
            this.set('online', true);
        }
    });
});