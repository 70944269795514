define('ingenio-web/services/busy', ['exports', 'ember-busy-blocker/services/busy'], function (exports, _busy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _busy.default;
    }
  });
});