define('ingenio-web/components/checkout-flow/chat/payment-options/autoreload/component', ['exports', 'ingenio-web/objects/conversation-payment-methods'], function (exports, _conversationPaymentMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['conversation-payment-options-container'],

    tenMinPrice: Ember.computed('minuteRate', function () {
      var minuteRate = this.get('minuteRate');

      return (minuteRate * 10).toFixed(2);
    }),

    actions: {
      onAutoReloadSelected: function onAutoReloadSelected() {
        this.attrs.onConversationPaymentMethodChanged(_conversationPaymentMethods.default.AutoReload);
      },
      onPayBeforeConversationSelected: function onPayBeforeConversationSelected() {
        this.attrs.onConversationPaymentMethodChanged(_conversationPaymentMethods.default.PayBeforeConversation);
      }
    }
  });
});