define('ingenio-web/components/chat/direct-messages', ['exports', 'ingenio-web/components/chat/chat-messages'], function (exports, _chatMessages) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _chatMessages.default.extend({
        didInsertElement: function didInsertElement() {
            Ember.run.scheduleOnce('afterRender', this, 'scrollMessage');
        },


        newMessageObserver: function () {
            var prevLength = this.get('prevLength');
            var curLength = this.get('messages.length');

            if (!prevLength || curLength > prevLength) {
                //we need to scroll only if we add message
                Ember.run.scheduleOnce('afterRender', this, 'scrollMessage');
            }
            this.set('prevLength', curLength);
        }.observes('messages.@each'),

        actions: {
            hideMessage: function hideMessage(messageId) {
                this.get('onHideMessage')(messageId);
            }
        }
    });
});