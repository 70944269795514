define('ingenio-web/arrange-conversation/add-funds/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/utils/footer-types'], function (exports, _resetScrollOnTransition, _footerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        defineFooterType: function defineFooterType() {
            if (Ember.ENV.domainId === 6) {
                return _footerTypes.default.MINIMAL;
            }
            return _footerTypes.default.FULL;
        },

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);

            var model = this.modelFor(this.getParentRouteName());

            // just in case confirm that user got to the page from the flow
            if (!model.get('userOperation')) {
                if (!model.get('advisorConversationRequest')) {
                    this.replaceWith(this.getParentRouteName() + '.details', {
                        queryParams: transition.queryParams
                    });
                } else {
                    this.replaceWith(this.getParentRouteName() + '.confirmation', {
                        queryParams: transition.queryParams
                    });
                }
            }

            // check that user has payment method on file
            if (!model.get('hasExistingPaymentMethods')) {
                this.replaceWith(this.getParentRouteName() + '.payment-details', {
                    queryParams: transition.queryParams
                });
            } else {
                var amountToAdd = model.get('minimumDepositAmount');

                model.set('user.funds.additionalAmount', amountToAdd.toFixed(2));
                model.set('user.additionalAmount', amountToAdd);
            }
        },
        setupController: function setupController(controller, model) {
            //this._super(...arguments);
            controller.set('model', model);
            controller.initialize();
        }
    });
});