define('ingenio-web/models/user-notification', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        unreadMail: (0, _attr.default)('number'),
        hasUnacknowledgedMail: (0, _attr.default)('boolean'),

        unreadPings: (0, _attr.default)('number'),
        hasUnacknowledgedPings: (0, _attr.default)('boolean'),

        unusedFreeMinutes: (0, _attr.default)('number'),
        hasUnacknowledgedUnusedFreeMinutes: (0, _attr.default)('boolean'),

        unredeemedOffers: (0, _attr.default)('number'),
        hasUnacknowledgedUnredeemedOffers: (0, _attr.default)('boolean'),

        outstandingFeedback: (0, _attr.default)('number'),
        hasUnacknowledgedOutstandingFeedback: (0, _attr.default)('boolean'),

        arrangedConversations: (0, _attr.default)('number'),
        hasUnacknowledgedArrangedConversations: (0, _attr.default)('boolean'),

        menuLastExpandedDate: (0, _attr.default)('string'),
        hasUnacknowledgedNotifications: (0, _attr.default)('boolean')
    });
});