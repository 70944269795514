define('ingenio-web/chat-setup/availability-error/controller', ['exports', 'ingenio-web/utils/html-utils', 'ingenio-web/constants/events'], function (exports, _htmlUtils, _events) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['listingid', 'pmode', 'webtrack', 'clickid', 'busy'],

        mixpanelClient: Ember.inject.service('mixpanel-client'),

        browseCategoryUrl: Ember.computed('model.listing.categoryId', function () {
            var categoryId = this.get('model.listing.categoryId');

            return '/listings?category=' + categoryId + '&hlt=3';
        }),

        isBusy: Ember.computed('busy', function () {
            var busy = this.get('busy');

            return busy === 'true';
        }),

        showSimplifiedOptions: Ember.computed('Ember.Application.user.hasAddedFundsInPast', 'Ember.ENV.domainId', function () {
            //we should no show arrange chat and ping advisor option for PC customers
            //so to simplify implementataion we consider all PC customer as isNewCustomer for Keen
            //and as result they will see only Browse other advisors button
            return !Ember.Application.user.hasAddedFundsInPast || Ember.ENV.domainId === 6;
        }),

        actions: {
            sendPing: function sendPing() {
                var advisorId = this.get('model.listing.advisorId');

                window.location.href = '/app/#/direct-messages/recipient/' + advisorId;
            },
            arrangeChat: function arrangeChat() {
                var listingid = this.get('model.listing.id');
                var pmode = this.get('pmode');
                var clickid = this.get('clickid');
                var webtrack = this.get('webtrack');
                var queryParams = {
                    listingid: listingid,
                    pmode: pmode,
                    webtrack: webtrack,
                    clickid: clickid,
                    ctype: 'chat'
                };

                this.replaceRoute('arrange-conversation.details', {
                    queryParams: queryParams
                });
            },
            sendEmail: function sendEmail() {
                var advisorName = this.get('model.listing.advisorName');

                window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
                    Subroutine: 'Mail',
                    SubroutineParams: _htmlUtils.default.encode({
                        mode: '0',
                        to: advisorName,
                        ReturnURL: window.location,
                        CancelURL: window.location
                    })
                });
            }
        }
    });
});