define('ingenio-web/components/invisible-text-area-with-title', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        rows: 6,
        shouldShowCharsLeft: false,

        actions: {
            textAreaFocusOut: function textAreaFocusOut() {
                this.set('shouldShowCharsLeft', false);
            },

            textAreaFocusIn: function textAreaFocusIn() {
                this.set('shouldShowCharsLeft', true);
            }
        }
    });
});