define('ingenio-web/models/feedback', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        id: (0, _attr.default)('number'),
        memberName: (0, _attr.default)('string'),
        feedbackDate: (0, _attr.default)('number'),
        rating: (0, _attr.default)('number'),
        comment: (0, _attr.default)('string'),
        memberId: (0, _attr.default)('number'),
        activityId: (0, _attr.default)('number')
    });
});