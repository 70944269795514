define('ingenio-web/arrange-conversation/expiration-extension/controller', ['exports', 'ingenio-web/arrange-conversation/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    queryParams: ['listingid', 'pmode', 'webtrack', 'clickid', 'arrange', 'r', 'ctype'],

    expiresAt: Ember.computed('model.advisorConversationRequest.expiresAt', function () {
      var expiresAt = this.get('model.advisorConversationRequest.expiresAt');
      var diff = new moment(expiresAt).diff(new moment());

      var expiresAtDays = 0;
      var expiresAtHours = 0;
      var expiresAtMinutes = 1;
      var expiresAtText = '';
      var expiresAtObj = {
        value: '0',
        displayText: 'Now'
      };

      if (diff > 0) {
        expiresAtDays = moment.duration(new moment(expiresAt).diff(new moment())).days();
        expiresAtHours = moment.duration(new moment(expiresAt).diff(new moment())).hours();
        expiresAtMinutes = moment.duration(new moment(expiresAt).diff(new moment())).minutes();

        expiresAtText = pluralize(expiresAtText, expiresAtDays, 'd');
        expiresAtText = pluralize(expiresAtText, expiresAtHours, 'hr');
        expiresAtText = pluralize(expiresAtText, expiresAtMinutes, 'min');

        expiresAtText = expiresAtText.trim();

        expiresAtObj.value = expiresAtDays * 24 * 60 + expiresAtHours * 60 + expiresAtMinutes;
        expiresAtObj.displayText = expiresAtText;
      }

      return expiresAtObj;

      function pluralize(text, value, ending) {
        if (value == 1) {
          text += value.toString() + ending;
        } else if (value > 1) {
          text += value.toString() + ' ' + ending + 's';
        }

        return text + ' ';
      }
    }),

    timeDropdownOptions: [{ value: '60', displayText: '1 hour' }, { value: '120', displayText: '2 hours' }, { value: '180', displayText: '3 hours' }, { value: '240', displayText: '4 hours' }, { value: '360', displayText: '6 hours' }, { value: '480', displayText: '8 hours' }, { value: '720', displayText: '12 hours' }, { value: '1440', displayText: '24 hours' }, { value: '2880', displayText: '2 days' }, { value: '4320', displayText: '3 days' }, { value: '10080', displayText: '1 week' }, { value: '43200', displayText: '1 month' }],

    arrangeConversation: Ember.inject.controller(),
    steps: Ember.computed.alias('arrangeConversation.steps'),

    secondaryButtonBusy: false,
    showLeaveWaitlistConfirmationDialog: false,

    infoMessage: function () {
      return '\n      <strong>Update Arranged Conversation.</strong> To update your arranged conversation,\n      please select how long you\'re willing to wait for an arranged conversation. \n      You will not lose your place in line.\n    ';
    }.property('model.advisorConversationRequest.forConversationType'),

    successMessage: function () {
      if (this.get('model.isChatConversation')) {
        if (this.get('model.advisorConversationRequest.queuePosition') == null) {
          return '\n          <div class="alert-icon"> \n            <i class="fal fa-check-circle"></i>\n          </div > \n          <div class="message-content">Advisor is available for chat.</div>\n        ';
        }

        return '\n        <div class="alert-icon"> \n          <i class="fal fa-check-circle"></i>\n        </div > \n        <div class="message-content">Arranged chat confirmed!</div>\n      ';
      }

      return '\n      <div class="alert-icon"> \n        <i class="fal fa-check-circle"></i>\n      </div > \n      <div class="message-content">Arranged call confirmed!</div>\n    ';
    }.property('model.advisorConversationRequest.forConversationType'),

    actions: {
      onUpdate: function onUpdate() {
        var id = this.model.advisorConversationRequest.id;
        var duration = $('#expiratiomExtensionDropdown')[0].value;
        this.clearErrors();

        this.updateConversationWaitTime(id, duration).then(function (msg) {
          return window.location.href = '/arranged-conversations?tab=my-advisors';
        });
      },
      onLeaveWaitlistClick: function onLeaveWaitlistClick() {
        this.clearErrors();

        this.set('showLeaveWaitlistConfirmationDialog', true);
      },
      onLeaveWaitlist: function onLeaveWaitlist() {
        this.set('showLeaveWaitlistConfirmationDialog', false);
        window.location.href = '/arranged-conversations?tab=my-advisors';
      },
      onCancelLeaveWaitlist: function onCancelLeaveWaitlist() {
        this.set('showLeaveWaitlistConfirmationDialog', false);
      }
    }
  });
});