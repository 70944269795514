define("ingenio-web/models/call-result-codes", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        success: 0,
        validationError: 1,
        insufficientFunds: 2,
        aniBlocked: 3,
        userBlocked: 4,
        busy: 5,
        arrange: 6,
        phoneUpdateError: 7,
        away: 8,
        unknown: 9,
        postPayError: 10
    };
});