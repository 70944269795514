define('ingenio-web/components/menu-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        hoverTargetId: '',
        hovered: false,

        setup: function () {
            //initializing optional actions with empty functions if they were not set form parents
            this.attrs.onHover = this.attrs.onHover || function () {};
            this.set('hoverTargetId', 'hover-target-' + this.getRandomInt(0, 1000000));
        }.on('init'),

        didInsertElement: function didInsertElement() {
            var id = this.get('hoverTargetId');

            this.$('#' + id).hover(this.onHoverIn.bind(this), this.onHoverOut.bind(this));

            this.$('#menu-item-popover-content').hover(this.onHoverIn.bind(this), this.onHoverOut.bind(this));
        },
        willDestroyElement: function willDestroyElement() {
            var id = this.get('hoverTargetId');

            this.$('#' + id).off();
            this.$('#menu-item-popover-content').off();
        },


        //todo restuta: add a class to target element
        onHoverIn: function onHoverIn() {
            this.toggleProperty('hovered');

            if (this.attrs.onHover) {
                this.attrs.onHover();
            }
        },
        onHoverOut: function onHoverOut() {
            this.toggleProperty('hovered');

            if (this.attrs.onCollapse) {
                this.attrs.onCollapse();
            }
        },


        //todo restuta:move to utils
        getRandomInt: function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },


        targetSvgName: Ember.computed('target-svg', function () {
            return 'header/header-' + this.get('target-svg') + '-icon';
        })
    });
});