define('ingenio-web/components/notepad-control', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        preservedText: '',
        errorText: '',
        isEditMode: false,

        initControl: function () {
            this.set('preservedText', this.get('value') || '');

            var that = this;
            function setAttribute() {
                if (that.element) {
                    var textarea = $(that.element).find('.keynotes-note').find("textarea");
                    textarea.attr("autocomplete", "nope");
                } else {
                    window.setTimeout(setAttribute, 500);
                }
            }
            setAttribute();
        }.on('init'),

        numberOfRows: function () {
            return this.get('rows') || 3;
        }.property('rows'),

        controlMaxlength: function () {
            return this.get('maxlength') || 7000;
        }.property('maxlength'),

        controlStatusCss: function () {
            if (this.get('errorText.length') > 0) {
                return 'error';
            } else if (this.get('isEditMode')) {
                var textarea = $(this.element).find('.keynotes-note').find("textarea");
                textarea.attr("autocomplete", "nope");

                return 'edit';
            }
            return 'valid';
        }.property('errorText', 'isEditMode'),

        disableSave: function () {
            if (this.get('errorText.length') > 0) {
                return true;
            } else if (this.get('isEditMode')) {
                return false;
            }
            return true;
        }.property('errorText', 'isEditMode'),

        handleEditMode: function () {
            var textarea = Ember.$('.textarea-control');
            if (this.get('isEditMode')) {
                textarea.focus();
            }
        }.observes('isEditMode'),

        handleEditing: function () {
            if (!this.get('isEditMode')) {
                this.send('edit');
            }
            var text = this.get('value');

            if (!text) {
                return;
            }
            if (text.indexOf('Show Error') > 0) {
                this.set('errorText', 'Showing sample error. Please fix it ASAP...');
            } else if (text.length >= this.get('controlMaxlength')) {
                this.set('errorText', this.get('controlMaxlength') + ' chars max');
            } else {
                this.set('errorText', '');
            }
        }.observes('value'),

        actions: {
            edit: function edit() {
                this.set('isEditMode', true);

                var textarea = $(this.element).find('.keynotes-note').find("textarea");
                textarea.attr("autocomplete", "nope");

                this.sendAction('edit');
            },
            save: function save() {
                this.set('isEditMode', false);
                this.set('preservedText', this.get('value') || '');

                this.sendAction('save', this.get('value'));
            },
            cancelEdit: function cancelEdit() {
                this.set('value', this.get('preservedText'));
                this.set('isEditMode', false);

                this.sendAction('cancelEdit');
            }
        }
    });
});