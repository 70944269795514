define('ingenio-web/components/transaction-details/charged-time/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        chargedTime: function () {
            var totalTime = this.get('model.totalTime');
            var offerTime = this.get('model.offerTime');

            return totalTime - offerTime;
        }.property('model.totalTime', 'model.offerTime')
    });
});