define('ingenio-web/adapters/listing-quality-score', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id) {
            var userId = Ember.Application.user.id;

            return id ? Ember.ENV.getApiRoot() + '/featured-listings/listings/' + id + '/quality-scores' : Ember.ENV.getApiRoot() + '/featured-listings/users/' + userId + '/listings/quality-scores';
        }
    });
});