define('ingenio-web/objects/conversation-payment-methods', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var ConversationPaymentMethods = Object.freeze({
        PayAfterConversation: 'pay-after-conversation',
        PayBeforeConversation: 'pay-before-conversation',
        AutoReload: 'auto-reload'
    });

    exports.default = ConversationPaymentMethods;
});