define('ingenio-web/models/base/listing-base', ['exports', 'ember-data/attr', 'ingenio-web/models/model-base', 'ember-data-model-fragments/attributes', 'ingenio-web/helpers/platform-domain'], function (exports, _attr, _modelBase, _attributes, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        domainId: (0, _attr.default)('number'), //Todo: This needs to be moved out of this model and to be passed in header
        primaryPhoneNumber: (0, _attributes.fragment)('phoneNumber'),
        countryCode: (0, _attr.default)('string'),
        categoryName: (0, _attr.default)('string'),
        chatEnabled: (0, _attr.default)('boolean', { defaultValue: true }),
        videoChatEnabled: (0, _attr.default)('boolean', { defaultValue: false }),
        tippingEnabled: (0, _attr.default)('boolean', { defaultValue: true }),

        title: (0, _attr.default)('string'),
        advisorId: (0, _attr.default)('number'),
        advisorName: (0, _attr.default)('string'),
        salesPitch: (0, _attr.default)('string'),
        profilePicture: (0, _attr.default)({ defaultValue: null }),
        profilePictureUrl: Ember.computed('profilePicture.primaryVersion.url', function () {
            return this.get('profilePicture') && this.get('profilePicture.primaryVersion') ? this.get('profilePicture.primaryVersion.url').replace('ad-products.cdn.', '') : './images/profile-placeholder.svg';
        }),

        introVideo: (0, _attr.default)({ defaultValue: null }),
        specializedSituations: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }), //array of json object
        skills: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }), //array of json object
        tools: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }), //array of json object
        languages: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }), //array of json object
        communicationStyles: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }), //array of json object

        approach: (0, _attr.default)('string'),
        approachPicture: (0, _attr.default)({ defaultValue: null }),
        approachPictureUrl: Ember.computed('approachPicture', function () {
            return this.get('approachPicture') && this.get('approachPicture.primaryVersion') ? this.get('approachPicture.primaryVersion.url').replace('ad-products.cdn.', '') : './images/image-upload-placeholder.svg';
        }),

        background: (0, _attr.default)('string'),
        backgroundPicture: (0, _attr.default)({ defaultValue: null }),
        backgroundPictureUrl: Ember.computed('backgroundPicture', function () {
            return this.get('backgroundPicture') && this.get('backgroundPicture.primaryVersion') ? this.get('backgroundPicture.primaryVersion.url').replace('ad-products.cdn.', '') : './images/image-upload-placeholder.svg';
        }),
        platformCommission: Ember.computed('categoryId', function () {
            var categoryId = this.get('categoryId');
            if (categoryId === 17101) //this is for psychic party
                {
                    return 10;
                }
            return 6;
        }),
        priceBreakdown: Ember.computed('minuteRate', 'domainInfo', 'hourlySessionRate', 'categoryId', 'isSessionBasedPricing', 'platformCommission', {
            get: function get() {
                var domainFeePercentage = (this.get('domainInfo.domainFeePercentage') || 0) * 1;
                var minuteRate = (this.get('minuteRate') || 0) * 1;
                var sessionRate = (this.get('hourlySessionRate') || 0) * 1;
                var rate = this.get('isSessionBasedPricing') ? sessionRate : minuteRate;
                var connectionCharges = this.get('isSessionBasedPricing') ? this.get('platformCommission') : (this.get('domainInfo').connectionCharges || 0) * 1;
                var commission = ((rate - connectionCharges) * domainFeePercentage / 100 || 0) * 1;
                var earnings = rate - commission - connectionCharges;

                return {
                    domainFeePercentage: domainFeePercentage.toFixed(2),
                    minuteRate: minuteRate.toFixed(2),
                    sessionRate: sessionRate.toFixed(2),
                    rate: rate.toFixed(2),
                    connectionCharges: connectionCharges.toFixed(2),
                    commission: commission.toFixed(2),
                    earnings: earnings.toFixed(2)
                };
            }
        }),

        minAllowedMinuteRate: function () {
            var connectionCharge = this.get('isSessionBasedPricing') ? this.get('platformCommission') : this.get('domainInfo.connectionCharges');
            var minRate = Ember.ENV.minimumListingPrice;

            if (connectionCharge > minRate) {
                minRate = connectionCharge;
            }
            return minRate;
        }.property('domainInfo.connectionCharges', 'platformCommission'),

        hourlySessionRate: (0, _attr.default)('number', { defaultValue: 100 }),

        minAllowedSessionRate: function () {
            var connectionCharge = this.get('isSessionBasedPricing') ? this.get('platformCommission') : this.get('domainInfo.connectionCharges');
            if (this.get('categoryId') === 17101) {
                var minRate = Ember.ENV.minimumPsychicPartySessionPrice;
            } else {
                var minRate = Ember.ENV.minimumHourlySessionPrice;
            }

            if (connectionCharge > minRate) {
                minRate = connectionCharge;
            }
            return minRate;
        }.property('domainInfo.connectionCharges', 'categoryId', 'platformCommission'),

        isSessionBasedPricing: function () {
            if (this.get('categoryId') === 17011 || this.get('categoryId') === 17101) {
                return true;
            }
            return false;
        }.property('categoryId'),

        isListingCategoryValid: function () {
            if (this.get('categoryId') === 0) {
                return false;
            }
            return true;
        }.property('categoryId'),

        maxAllowedHourlySessionRate: function () {
            if (this.get('categoryId') === 17101) {
                return 500; //psychic party max rate
            } else {
                return 400; //intimacy coaching max rate
            }
        }.property('categoryId'),

        defaultSessionRate: function () {
            if (this.get('categoryId') === 17101) {
                return 250; //psychic party min rate
            } else {
                return 100; //intimacy coaching min rate
            }
        }.property('categoryId'),

        defaultRate: function () {
            if (this.get('categoryId') === 17101) {
                return 250; //psychic party min rate
            } else if (this.get('categoryId') === 17011) {
                return 100; //intimacy coaching min rate
            } else {
                return 3.99;
            }
        }.property('categoryId'),

        maxAllowedMinuteRate: 50,

        disablePPM: function () {
            var advisorActiveCampaigns = this.get('advisorActiveCampaigns');
            if (!advisorActiveCampaigns) return false;

            if (advisorActiveCampaigns.length > 0 && advisorActiveCampaigns[0].advisorOptedIn) return true;

            return false;
        }.property('model.advisorActiveCampaigns'),

        afterLoad: function () {
            var minuteRate = ((this.get('minuteRate') || 0) * 1).toFixed(2);
            this.set('minuteRate', minuteRate);

            //TODO: Remove the below code once the skills in database are cleaned.
            var skills = this.get('skills');
            var tools = this.get('tools');

            if (skills.length > 0 && tools.length > 0) {
                this.set('skills', skills.filter(function (skill) {
                    return !tools.some(function (tool) {
                        return tool.value === skill.value;
                    });
                }));
            }
        }.on('didLoad'),

        validateListingCategory: function validateListingCategory() {
            var isListingCategoryValid = this.get('isListingCategoryValid');
            this.removeErrors('categoryId');
            if (!isListingCategoryValid) {
                this.addError('categoryId', 'Listing category must be selected.');
            }
        },
        validateTitle: function validateTitle() {
            var title = this.get('title') || '';
            this.removeErrors('title');
            if (title.length > 0 && Ember.isBlank(title)) {
                this.addError('title', 'Listing title cannot be blank.');
            } else if (title.length < 20 || title.length > 50) {
                this.addError('title', 'Listing title must be between 20 and 50 characters.');
            }
        },
        validateSalesPitch: function validateSalesPitch() {
            var salesPitch = this.get('salesPitch') || '';
            this.removeErrors('salesPitch');
            var hasSessionBasedPricing = this.get('isSessionBasedPricing');
            if (salesPitch.length > 0 && Ember.isBlank(salesPitch)) {
                this.addError('salesPitch', 'Listing sales pitch cannot be blank.');
            } else if (!hasSessionBasedPricing && (salesPitch.length < 25 || salesPitch.length > 225)) {
                this.addError('salesPitch', 'Listing sales pitch must be between 25 and 225 characters.');
            } else if (hasSessionBasedPricing && (salesPitch.length < 25 || salesPitch.length > 190)) {
                this.addError('salesPitch', 'Listing sales pitch must be between 25 and 190 characters.');
            }
        },
        validateAdvisorName: function validateAdvisorName() {
            var advisorName = this.get('advisorName') || '';
            var minLength = Ember.ENV.domainId === 6 ? 1 : 5;
            if (advisorName.length < minLength || advisorName.length > 30) {
                this.addError('advisorName', 'This is the name your customers will know you by. It must be between ' + minLength + ' and 30 characters.');
            } else {
                this.removeErrors('advisorName');
            }
        },
        validateMinuteRate: function validateMinuteRate() {
            if (this.get('isSessionBasedPricing')) {
                this.validateSessionRate();
            } else {
                var minuteRate = (this.get('minuteRate') || 0) * 1;
                if (minuteRate < this.get('minAllowedMinuteRate') || minuteRate > this.get('maxAllowedMinuteRate')) {
                    this.addError('minuteRate', 'Listing price should be between $' + this.get('minAllowedMinuteRate') + ' and $' + this.get('maxAllowedMinuteRate'));
                } else {
                    var minuteRateStr = minuteRate.toFixed(2);
                    if (minuteRateStr * 1 != minuteRate) {
                        this.addError('minuteRate', 'Listing price should have only up to 2 decimal places');
                    } else {
                        this.removeErrors('minuteRate');
                    }
                }
            }
        },
        validateSessionRate: function validateSessionRate() {
            var sessionRate = (this.get('hourlySessionRate') || 0) * 1;
            if (sessionRate < this.get('minAllowedSessionRate') || sessionRate > this.get('maxAllowedHourlySessionRate')) {
                this.addError('hourlySessionRate', 'Session price should be between $' + this.get('minAllowedSessionRate') + ' and $' + this.get('maxAllowedHourlySessionRate'));
            } else {
                var sessionRateStr = sessionRate.toFixed(2);
                if (sessionRateStr * 1 != sessionRate) {
                    this.addError('hourlySessionRate', 'Session price should have only up to 2 decimal places');
                } else {
                    this.removeErrors('hourlySessionRate');
                }
            }
        },
        validateApproach: function validateApproach() {
            var approach = this.get('approach') || '';
            var maxLength = Ember.ENV.domainId === 6 ? 10000 : 4000;

            this.removeErrors('approach');
            if (approach.length > 0 && Ember.isBlank(approach)) {
                this.addError('approach', 'This section cannot be blank.');
                return;
            }
            if (Ember.ENV.domainId === 1 && this.get('approachPicture') && (approach.length <= 0 || approach.length > maxLength)) {
                this.addError('approach', 'Listing approach description must be between 200 and ' + maxLength + ' characters.');
            } else if (approach.length > 0 && (approach.length < 200 || approach.length > maxLength)) {
                this.addError('approach', 'Listing approach description must be between 200 and ' + maxLength + ' characters.');
            }
        },
        validateBackground: function validateBackground() {
            var background = this.get('background') || '';

            this.removeErrors('background');
            if (background.length > 0 && Ember.isBlank(background)) {
                this.addError('background', 'This section cannot be blank.');
                return;
            }

            if (Ember.ENV.domainId === 1 && this.get('backgroundPicture') && (background.length <= 0 || background.length > 4000)) {
                this.addError('background', 'Listing background description must be between 200 and 4000 characters.');
            } else if (background.length > 0 && (background.length < 200 || background.length > 4000)) {
                this.addError('background', 'Listing background description must be between 200 and 4000 characters.');
            }
        },


        onPhoneChanges: function () {
            this.validatePhoneNumber();
        }.observes('primaryPhoneNumber.number', 'primaryPhoneNumber.countryCode'),

        validatePhoneNumber: function validatePhoneNumber() {
            var phoneNumber = this.get('primaryPhoneNumber');
            if (!phoneNumber) return;

            var number = (phoneNumber.get('number') || '').replace(/\D+/g, '');

            if (phoneNumber.get('isUSNumber')) {
                if (number.length < 10 || number.length > 11) {
                    this.addError('primaryPhoneNumber', 'Phone number is incorrect.');
                } else {
                    this.removeErrors('primaryPhoneNumber');
                }
            } else {
                var countryCode = phoneNumber.get('countryCode') || '';
                if (number.length < 5 || number.length > 16) {
                    this.addError('primaryPhoneNumber', 'Phone number is incorrect.');
                } else if (countryCode.length < 1 || countryCode.length > 6) {
                    this.addError('primaryPhoneNumber', 'Country Code is incorrect.');
                } else {
                    this.removeErrors('primaryPhoneNumber');
                }
            }
        },
        validateCommunicationStyles: function validateCommunicationStyles() {
            if (this.isKeen) {
                var communicationStyles = this.get('communicationStyles');
                var communicationStylesSelected = communicationStyles.length;

                if (communicationStylesSelected < 1) {
                    this.addError('communicationStyles', 'Communication style is required.');
                } else {
                    this.removeErrors('communicationStyles');
                }
            }
        },
        validateSpecializedSituations: function validateSpecializedSituations() {
            if (this.isKeen) {
                var specializedSituations = this.get('specializedSituations');
                var specializedSituationsSelected = specializedSituations.length;

                if (specializedSituationsSelected < 1) {
                    this.addError('specializedSituations', 'Specialized Situations is required.');
                } else {
                    this.removeErrors('specializedSituations');
                }
            }
        },
        validateSkills: function validateSkills() {
            if (this.isKeen) {
                var skills = this.get('skills');
                var skillsSelected = skills.length;

                if (skillsSelected < 1 && this.get('categoryId') !== 17011) {
                    this.addError('skills', 'Skills  is required.');
                } else {
                    this.removeErrors('skills');
                }
            }
        },
        validateTools: function validateTools() {
            if (this.isKeen) {
                var tools = this.get('tools');
                var toolsSelected = tools.length;

                if (toolsSelected < 1) {
                    this.addError('tools', 'Tools  is required.');
                } else {
                    this.removeErrors('tools');
                }
            }
        },
        validateLanguages: function validateLanguages() {
            if (this.isKeen) {
                var languages = this.get('languages');
                var languagesSelected = languages.length;

                if (languagesSelected < 1) {
                    this.addError('languages', 'Languages  is required.');
                } else {
                    this.removeErrors('languages');
                }
            }
        },
        validate: function validate() {
            this.validateListingCategory();
            this.validateTitle();
            this.validateAdvisorName();
            this.validateMinuteRate();
            this.validateSalesPitch();
            this.validatePhoneNumber();
            this.validateApproach();
            this.validateBackground();
            this.validateCommunicationStyles();
            this.validateSpecializedSituations();
            this.validateSkills();
            this.validateTools();
            this.validateLanguages();
        },


        isKeen: (0, _platformDomain.platformIsKeen)()
    });
});