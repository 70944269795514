define('ingenio-web/routes/listing/edit', ['exports', 'ingenio-web/routes/listing/route-base'], function (exports, _routeBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeBase.default.extend({
        //for immutable data to that is getting loaded separately from mutable primary model
        immutableData: {
            specSkillsAndLanguages: {},
            listingCategories: {},
            domainInfo: {},
            chatAvailability: {}
        },

        //todo: in case there is no model (page opened with direct url) model loading and loading of other data
        // will be sequential, which delays view rendering
        afterModel: function afterModel(model) {
            var _this = this;

            this._super(model);

            var userInfo = Ember.Application.user;

            //todo restuta: newly created listings won't pass this with proto API, investigate
            if (userInfo.id !== model.get('advisorId')) {
                var error = {
                    customMessage: 'Access to listings which belong to other advisors is prohibited'
                };

                throw error;
            }

            var apiUrl = Ember.ENV.getApiRoot();
            var loadListingCategories = Ember.$.getJSON(apiUrl + '/listing-categories?domainid=' + Ember.ENV.domainId).then(function (response) {
                return response.listingCategories;
            });
            var loadSpecSkillsAndLanguages = Ember.$.getJSON(apiUrl + '/specializations-skills-languages?domainid=' + Ember.ENV.domainId);

            var loadDomainInfo = Ember.$.getJSON(apiUrl + '/domain-info?domainid=' + Ember.ENV.domainId + '&isAdvisor=true').then(function (response) {
                return response.domainInfo;
            });

            var chatAvailability = this.store.findRecord('advisorChatAvailability', Ember.Application.user.id, { reload: true });

            var advisorActiveCampaigns = Ember.$.getJSON(apiUrl + '/advisors/' + userInfo.id + '/offer-campaigns?campaignActive=true');

            var promises = {
                listingCategories: loadListingCategories,
                specSkillsAndLanguages: loadSpecSkillsAndLanguages,
                domainInfo: loadDomainInfo,
                chatAvailability: chatAvailability,
                advisorActiveCampaigns: advisorActiveCampaigns
            };

            //waiting for all promises to resolve
            return Ember.RSVP.hash(promises).then(function (result) {
                model.set('domainInfo', result.domainInfo);
                _this.immutableData.listingCategories = result.listingCategories;
                _this.immutableData.specSkillsAndLanguages = result.specSkillsAndLanguages;
                _this.immutableData.domainInfo = result.domainInfo;
                _this.immutableData.chatAvailability = result.chatAvailability;
                model.set('advisorActiveCampaigns', result.advisorActiveCampaigns);
            });
        },

        activate: function activate() {
            this._super.apply(this, arguments);
            this.bindPopovers();
        },


        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var tools = this.immutableData.specSkillsAndLanguages.tools;
            //TODO: Remove this filter once the skills in database are cleaned.
            var skills = this.immutableData.specSkillsAndLanguages.skills.filter(function (skill) {
                return !tools.some(function (tool) {
                    return tool.value === skill.value;
                });
            });

            controller.setProperties({
                listingCategories: this.immutableData.listingCategories,
                specializationList: this.immutableData.specSkillsAndLanguages.specializations.filter(function (specialization) {
                    return specialization.categoryId != 17011;
                }).sortBy('value'),
                specializationListForIC: this.immutableData.specSkillsAndLanguages.specializations.filter(function (specialization) {
                    return specialization.categoryId == 17011;
                }).sortBy('value'),
                domainInfo: this.immutableData.domainInfo,
                skillList: skills.sort(function (a, b) {
                    a = a.value.replace(/–/g, '');
                    b = b.value.replace(/–/g, '');
                    return a.localeCompare(b);
                }),
                toolList: tools.sort(function (a, b) {
                    a = a.value.replace(/–/g, '');
                    b = b.value.replace(/–/g, '');
                    return a.localeCompare(b);
                }),
                languageList: this.immutableData.specSkillsAndLanguages.languages.sortBy('value'),
                chatAvailability: this.immutableData.chatAvailability,
                communicationStyleList: this.immutableData.specSkillsAndLanguages.communicationStyles.sortBy("value")
            });
            controller.set('cantEditUserName', true);
            controller.set('allowVideoUpload', true);
            controller.set('advisorActiveCampaigns', model.advisorActiveCampaigns);
        }
    });
});