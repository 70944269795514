define('ingenio-web/components/myaccount/contact-summary-paid', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isKeen: function () {
      return !(Ember.ENV.domainId === 6);
    }.property('Ember.ENV.domainId'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var model = this.model;

      function formatDurationAsMinutes(value) {
        if (value) {
          var duration = _moment.default.duration(value);
          if (_moment.default.isDuration(duration) && duration.asMinutes() > 0) return duration.asMinutes().toLocaleString() + " minute" + (duration.asMinutes() !== 1 ? 's' : '');
        }

        return '-';
      }

      model.calls.displayTotalMinutes = formatDurationAsMinutes(model.calls.totalTime);
      model.chats.displayTotalMinutes = formatDurationAsMinutes(model.chats.totalTime);
    }
  });
});