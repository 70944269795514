define('ingenio-web/arranged-conversations/route', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/utils/pubnub-user-channel-client'], function (exports, _headerTypes, _footerTypes, _resetScrollOnTransition, _pubnubUserChannelClient) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        pollInterval: 0,

        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        defineFooterType: function defineFooterType() {
            return _footerTypes.default.FULL;
        },

        servicingSinceItems: {},

        activate: function activate() {
            this._super.apply(this, arguments);

            $(window).scroll(function (event) {
                var windowScrollTop = $(window).scrollTop();

                if ($('.takeActionNow').offset()) {
                    var elementScrolltop = $('.takeActionNow').offset().top;

                    if (windowScrollTop > elementScrolltop) {
                        $('#div-take-action').removeClass('hidden');
                    } else {
                        $('#div-take-action').addClass('hidden');
                    }
                }
            });
        },

        beforeModel: function beforeModel(transition) {
            if (transition.params['arranged-conversations']) {
                var appointmentId = transition.params['arranged-conversations'].appointment_id;

                if (appointmentId) {
                    this.set('appointmentId', appointmentId);
                }
            }
        },
        model: function model(params, transition) {
            var _this = this;

            this.set('params', params);
            this.set('transition', transition);

            var pubnubUserChannelPromise = null;

            if (!this.get('pubnubUserChannel')) {
                var pubnubUserChannel = this.store.createRecord('pubnubUserChannel');

                pubnubUserChannelPromise = pubnubUserChannel.save();
            }

            var queries = {
                dataQuery: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql', {
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        operationName: null,
                        query: 'query ArrangedConversations($forAdvisor:Boolean!){currentUser{profile{preferredTimeZone{displayName shortDisplayName}}... on Advisor @include(if:$forAdvisor){busyState{isBusy}conversateWaitList{beingServiced{...requestDetailsForAdvisor}requests{edges{node{...requestDetailsForAdvisor}}}}listingAppointments{...appoitmentDetails user{...customerDetails}listing{...listingDetails}}}advisorConversationRequests{edges{node{...requestDetails requestedFor{...userDetails availability{callAvailability{type isSelected selectedAsOf}chatAvailability}}onListing{...consumerListingDetails}}}}requestedListingAppointments{...appoitmentDetails listing{...consumerListingDetails advisor{id userName}}}}}fragment requestDetails on AdvisorConversationRequest{id status requestedAt expiresAt estimatedWaitTime queuePosition forConversationType conversationId}fragment requestDetailsForAdvisor on AdvisorConversationRequest{...requestDetails onListing{...listingDetails}requestedBy{...customerDetails}conversation{... on ListingCallConversation{legacyCodes{statusCode resultCode source}}... on ListingChatConversation{chatAdvisorParty:advisorParty{state}chatUserParty:userParty{state}status}}}fragment userDetails on User{id userName}fragment customerDetails on User{...userDetails ... on AdvisorCustomer{nickname pastEarnings{value}}}fragment listingDetails on Listing{pricePerMinute{value}category{name}}fragment consumerListingDetails on Listing{...listingDetails id picture{default{uri}}}fragment appoitmentDetails on ListingAppointment{id scheduledStart scheduledEnd status note{id} surveyResponseId canBeRescheduled canBeCancelled sessionPrice cancellationPrice isSessionBased apptTypeID videoSessionId}',
                        variables: {
                            forAdvisor: Ember.Application.user.isAdvisor
                        }
                    })
                }),
                phoneNumbers: this.get('phoneNumbers') || this.store.findAll('userPhone'),
                pubnubUserChannel: this.get('pubnubUserChannel') || pubnubUserChannelPromise
            };

            return Ember.RSVP.hash(queries).then(function (result) {
                _this.set('phoneNumbers', result.phoneNumbers);
                _this.set('pubnubUserChannel', result.pubnubUserChannel);

                var pubnubUserChannel = result.pubnubUserChannel;

                result.userPhones = result.phoneNumbers;

                function getLength(array) {
                    return array != null ? array.length : 0;
                }

                var requestResult = {

                    advisor: {
                        conversateWaitList: result.dataQuery.data.currentUser.conversateWaitList,
                        listingAppointments: result.dataQuery.data.currentUser.listingAppointments,
                        busyState: result.dataQuery.data.currentUser.busyState
                    },
                    customer: {
                        advisorConversationRequests: result.dataQuery.data.currentUser.advisorConversationRequests,
                        requestedListingAppointments: result.dataQuery.data.currentUser.requestedListingAppointments
                    },
                    profile: result.dataQuery.data.currentUser.profile,
                    userPhones: result.phoneNumbers,
                    pubnubUserChannel: pubnubUserChannel,
                    totalCustomerEntriesCount: 0,
                    totalAdvisorEntriesCount: 0,
                    headerItem: {
                        cssClass: 'hidden'
                    }
                };

                if (requestResult.customer) {
                    if (requestResult.customer.advisorConversationRequests && requestResult.customer.advisorConversationRequests.edges) {
                        requestResult.customer.advisorConversationRequests.edges = requestResult.customer.advisorConversationRequests.edges.filter(function (item) {
                            return item.node != null;
                        });
                    } else {
                        requestResult.customer.advisorConversationRequests = { edges: [] };
                    }

                    if (!requestResult.customer.requestedListingAppointments) {
                        requestResult.customer.requestedListingAppointments = [];
                    }

                    requestResult.totalAdvisorEntriesCount = getLength(requestResult.customer.advisorConversationRequests.edges) + getLength(requestResult.customer.requestedListingAppointments);
                } else {
                    requestResult.customer = { advisorConversationRequests: { edges: [] }, requestedListingAppointments: [] };
                }

                if (requestResult.advisor && requestResult.advisor.conversateWaitList && requestResult.advisor.conversateWaitList.requests && requestResult.advisor.conversateWaitList.requests.edges) {
                    requestResult.advisor.conversateWaitList.requests.edges = requestResult.advisor.conversateWaitList.requests.edges.filter(function (item) {
                        return item.node != null;
                    });

                    var arrangements = requestResult.advisor.conversateWaitList.requests.edges.map(function (item) {
                        return item.node;
                    });

                    requestResult.totalCustomerEntriesCount = getLength(arrangements) + getLength(requestResult.advisor.listingAppointments);

                    arrangements = arrangements.sort(function (a, b) {
                        if (a.queuePosition == b.queuePosition) {
                            return new moment(a.expiresAt) - new moment(b.expiresAt);
                        }
                        return a.queuePosition - b.queuePosition;
                    });

                    _this.set('refreshInterval', 60);
                    var beingServiced = requestResult.advisor.conversateWaitList.beingServiced;

                    var activeCallPresent = beingServiced != null && beingServiced.status == 'SERVICING';

                    if (activeCallPresent) {
                        requestResult.actionCall = beingServiced;
                        //this.set("refreshInterval", 3);
                        arrangements.forEach(function (item) {
                            return item.activeCallPresent = true;
                        });
                    }

                    var advisorBusy = requestResult.advisor.busyState && requestResult.advisor.busyState.isBusy;

                    arrangements.forEach(function (item) {
                        return item.advisorBusy = !!advisorBusy;
                    });
                    if (advisorBusy) {
                        //this.set("refreshInterval", 3);
                    }

                    if (requestResult.actionCall) {
                        var arrangementsContainsActiveCall = arrangements.filter(function (item) {
                            return item && item.id == requestResult.actionCall.id;
                        });

                        if (arrangementsContainsActiveCall.length == 0) {
                            arrangements.unshift(requestResult.actionCall);
                            //this.set("refreshInterval", 3);
                            arrangements.forEach(function (item) {
                                return item.activeCallPresent = true;
                            });
                        }
                    }

                    //we will skip conversations (details about call and chat) in status ENDED
                    arrangements = arrangements.filter(function (item) {
                        return !item.conversation || !item.conversation.status || item.conversation.status.toLowerCase() !== 'ended';
                    });

                    requestResult.advisor.conversateWaitList.requests.edges = [];
                    for (var i = 0; i < arrangements.length; i++) {
                        requestResult.advisor.conversateWaitList.requests.edges.push({ node: arrangements[i] });
                    }

                    var actionItems = arrangements.filter(function (item) {
                        return item.queuePosition == 1;
                    });

                    if (actionItems.length > 0 && !requestResult.actionCall && !advisorBusy) {
                        requestResult.actionCall = actionItems[0];
                    }

                    if (requestResult.actionCall) {
                        requestResult.headerItem = {
                            type: requestResult.actionCall.forConversationType.toLowerCase(),
                            typeIndicator: requestResult.actionCall.forConversationType,
                            cssClass: ''
                        };
                    }
                } else {
                    if (!requestResult.advisor) {
                        requestResult.advisor = { conversateWaitList: { requests: { edges: [] } } };
                    }

                    if (!requestResult.advisor.conversateWaitList) {
                        requestResult.advisor.conversateWaitList = { requests: { edges: [] } };
                    }
                }

                if (requestResult.advisor && requestResult.advisor.listingAppointments) {
                    var appointments = requestResult.advisor.listingAppointments;

                    appointments = appointments.sort(function (a, b) {
                        return new moment(a.scheduledStart) - new moment(b.scheduledStart);
                    });
                    requestResult.advisor.listingAppointments = appointments;
                } else {
                    requestResult.advisor.listingAppointments = [];
                }

                //Set servicingSince property for servicing item when it appears in model first time
                var servicingSinceItems = _this.get('servicingSinceItems');

                requestResult.customer.advisorConversationRequests.edges = requestResult.customer.advisorConversationRequests.edges.filter(function (item) {
                    return !item.node.conversationId;
                }) //we filter such items because conversation already started
                .map(function (item) {
                    if (item.node.status === 'SERVICING') {
                        if (!servicingSinceItems[item.node.id]) {
                            servicingSinceItems[item.node.id] = moment();
                            _this.set('servicingSinceItems', servicingSinceItems);
                        }

                        item.node.servicingSince = servicingSinceItems[item.node.id];
                    }
                    return item;
                });

                // Get isNewCustomer data for all advisor's appointments, if any, we can't have a new customer in a waitlist items
                if (requestResult.advisor.listingAppointments && requestResult.advisor.listingAppointments.length > 0) {
                    var customerIds = "";
                    requestResult.advisor.listingAppointments.forEach(function (item) {
                        return customerIds = customerIds + "&customerIds=" + item.user.id;
                    });
                    // remove leading "&" character
                    customerIds = customerIds.substring(1, customerIds.length);

                    var advisorId = Ember.Application.user.id;
                    var _queries = {
                        dataQuery: Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customers?' + customerIds, {
                            method: 'GET',
                            contentType: 'application/json'
                        }),
                        requestResult: requestResult
                    };

                    return Ember.RSVP.hash(_queries).then(function (result) {
                        var data = result.dataQuery;

                        result.requestResult.advisor.listingAppointments.forEach(function (item) {
                            data.forEach(function (d) {
                                return d.id == item.user.id ? item.user.isNewUser = d.isNew : "";
                            });
                        });

                        return result.requestResult;
                    });
                }

                return requestResult;
            });
        },
        refreshModelWithoutInterval: function refreshModelWithoutInterval() {
            var noRepeatCall = true;

            this.refreshModel(noRepeatCall);
        },
        refreshModel: function refreshModel(noRepeatCall) {
            var _this2 = this;

            this.model(this.get('params'), this.get('transition')).then(function (model) {
                _this2.controllerFor('arranged-conversations').set('model', model);

                if (!noRepeatCall && _this2.get('enableRefresh')) {
                    var refreshInterval = _this2.get('refreshInterval');

                    if (!refreshInterval) {
                        refreshInterval = 60;
                    }

                    Ember.run.later(function () {
                        _this2.refreshModel();
                    }, refreshInterval * 1000);
                }
            });
        },


        actions: {
            didTransition: function didTransition() {
                this.get('mixpanelClient').trackNewEvent('Arranged Conversations Viewed', {
                    eventData: {
                        user_type: Ember.Application.user.isAdvisor ? 'advisor' : 'consumer'
                    }
                });
                this.actions.startUpdates.call(this);
            },
            willTransition: function willTransition() {
                this.actions.stopUpdates.call(this);
            },
            refresh: function refresh() {
                this.refreshModel();
            },
            stopUpdates: function stopUpdates() {
                this.set('enableRefresh', false);
            },
            startUpdates: function startUpdates() {
                this.set('enableRefresh', true);
                this.refreshModel();
            }
        },

        setupController: function setupController(controller, model) {
            var _this3 = this;

            this._super(controller, model);
            if (Ember.Application.user.isAdvisor && this.get('appointmentId')) {
                var appointment = model.advisor.listingAppointments.find(function (x) {
                    return x.id == _this3.get('appointmentId');
                });

                if (appointment && appointment.status == 'PENDING') {
                    controller.set('pendingAppointment', appointment);
                    controller.set('showAcceptAppointmentDialog', true);
                }
            }
            controller.set('model', model);

            var userId = Ember.Application.user.id;

            this.subscribeToUserChannel(userId, model.pubnubUserChannel);
        },
        subscribeToUserChannel: function subscribeToUserChannel(advisorId, pubnubUserChannel) {
            var _this4 = this;

            var userChannel = pubnubUserChannel.get('channel');
            var userChannelPubnubOptions = {
                pubKey: '',
                subKey: userChannel.get('subscribeKey'),
                authKey: userChannel.get('authKey'),
                uuid: _pubnubUserChannelClient.default.guid() + '-' + advisorId,
                channelName: userChannel.get('channelName'),
                heartbeatSeconds: 180,
                subscribeState: {
                    clientType: 'arrangeChatAdvisorMonitor',
                    username: advisorId
                },
                disableTimeSync: true
            };

            var logger = this.get('loggerService');

            var pubnubUserChannelClient = new _pubnubUserChannelClient.default(advisorId, userChannelPubnubOptions, this.store, logger);

            pubnubUserChannelClient.onConnect = function () {
                return logger.log('arrangeChatMonitor successfully connected to pubnub');
            };
            pubnubUserChannelClient.onConnectFailed = function () {
                logger.error('arrangeChatMonitor failed to connect to pubnub.');
            };
            pubnubUserChannelClient.onArrangedChat = function (eventData) {
                //TODO: uncomment it after we confirm view of taskBar notidication window

                // if (!Ember.Application.user.isAdvisor && eventData.smsSent) {
                //   const taskBarNotification = new TaskBarNotification({
                //     title: Ember.ENV.domainName + ' Notification',
                //     icon: Ember.ENV.domainId === 1
                //         ? 'https://si.keen.com/D1_new-keen-logo_V1.png'
                //         : 'https://si.keen.com/D1_pc-logo_V2.png'
                //       }, logger);

                //     taskBarNotification.showNotification(
                //       'Arranged Chat', () => window.location.href = `/ar/${eventData.arrangedConversationId}`);
                // }

                logger.log('Arranged chat with id ' + JSON.stringify(eventData) + '.');
                _this4.refreshModel();
            };

            pubnubUserChannelClient.onArrangedConversationExpired = function (eventData) {
                logger.log('Arranged conversation expired ' + JSON.stringify(eventData) + '.');
                _this4.refreshModelWithoutInterval();
            };

            pubnubUserChannelClient.onArrangedChatStarted = function (eventData) {
                logger.log('Arranged conversation started ' + JSON.stringify(eventData) + '.');
                _this4.refreshModelWithoutInterval();
            };

            pubnubUserChannelClient.onCallDetailsMessage = function (eventData) {
                if (eventData.IsCallbackOrAppointment) {
                    logger.log('Arranged Call status update ' + JSON.stringify(eventData) + '.');
                    _this4.refreshModelWithoutInterval();
                }
            };

            pubnubUserChannelClient.subscribe();
        }
    });
});