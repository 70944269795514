define('ingenio-web/components/phone-number/item/component', ['exports', 'ingenio-web/mixins/validatable'], function (exports, _validatable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatable.default, {
        classNames: ['phone-number-item-container'],
        required: false,
        disabled: false,
        showLabel: true,
        showTypeBadge: false,
        numberInputHasFocus: false,

        hasValue: Ember.computed('countryCode', 'number', function () {
            if (this.get('number') && this.get('countryCode')) {
                return !Ember.isEmpty(this.get('number').replace('+' + this.get('countryCode'), ''));
            }

            return false;
        }),

        didInsertElement: function didInsertElement() {
            var _this = this;

            // listener hack to toggle the material design label state when
            // the user focuses on the phone number input.
            this.$('.intl-tel-input > input').focusin(function () {
                return _this.set('numberInputHasFocus', true);
            }).focusout(function () {
                return _this.set('numberInputHasFocus', false);
            });

            this._super.apply(this, arguments);
        },


        actions: {
            onPhoneValidityChange: function onPhoneValidityChange(validationState) {
                var numberValid = validationState.validNumber || !this.get('hasValue') && !this.get('required');

                // Removing the invalid number front end validation because the library ember-intl-tel-input2 is not updated to allow the new U.S directory phone numbers.
                // if (numberValid) {
                this.setValid();
                // } else {
                //     this.setInvalid('Invalid phone number entered.');
                // }

                this.attrs.onPhoneValidityChange({
                    valid: true,
                    phoneType: this.get('type'),
                    hasValue: this.get('hasValue')
                });
            },
            onSelectedCountryChange: function onSelectedCountryChange(countryData) {
                this.set('countryCode', countryData.dialCode);
                this.set('isUSNumber', countryData.iso2 === 'us');
            }
        }
    });
});