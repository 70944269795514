define('ingenio-web/metrics-adapters/tiktok-pixel', ['exports', 'ember-metrics/utils/can-use-dom', 'ingenio-web/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'tiktok-pixel';
    },
    init: function init() {
      // TikTok Pixel should be initialized in Google Tag Manager
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!_canUseDom.default) {
        return;
      }
      var compactedOptions = compact(options);
      var event = compactedOptions.event;


      if (window.ttq && options.event) {
        window.ttq.track(event);
      }
    }
  });
});