define('ingenio-web/components/countdown-timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['countdown-timer'],
        shouldStart: false,
        fromSeconds: 0, //total seconds from when countdown should begin.
        current: 0,
        formattedTime: function () {
            return this._formatSecondsToTime(this.get('current'));
        }.property('current'),

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.clearTimer();
        },


        shouldStartChanged: function () {
            if (this.get('shouldStart')) {
                this._start();
            } else {
                this.set('current', 0);
                this.clearTimer();
            }
        }.observes('shouldStart').on('init'),

        clearTimer: function clearTimer() {
            var intervalHandle = this.get('intervalHandle');

            if (intervalHandle) {
                clearTimeout(intervalHandle);
            }
        },


        _start: function _start() {
            var _this = this;

            this.set('current', this.get('fromSeconds'));

            var intervalHandle = setInterval(function () {
                var currentTime = _this.get('current');

                if (currentTime > 0) {
                    _this.decrementProperty('current');
                } else {
                    clearTimeout(intervalHandle);
                }
            }, 1 * 1000);

            this.set('intervalHandle', intervalHandle);
        },

        _formatSecondsToTime: function _formatSecondsToTime(originalSeconds) {
            //TODO: this method is same as in chatdurationmanager, remove redundancy
            var minutes = Math.floor(originalSeconds / 60);
            var hours = Math.floor(minutes / 60);
            var seconds = originalSeconds % 60;

            minutes %= 60;
            hours %= 24;

            if (hours === 0) {
                return this._padLeft(minutes) + ':' + this._padLeft(seconds);
            }

            return this._padLeft(hours) + ':' + this._padLeft(minutes) + ':' + this._padLeft(seconds);
        },

        _padLeft: function _padLeft(time) {
            return time < 10 ? '0' + time : time;
        }
    });
});