define('ingenio-web/mixins/end-chat-reason-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        getChatEndReasonTextForConsumer: function getChatEndReasonTextForConsumer(chatEndReason, advisorName) {
            switch (chatEndReason) {
                case 'ConsumerEnded':
                    return 'You ended the chat session with ' + advisorName + '.';
                case 'ConsumerEndedDuringRecharge':
                    return 'You ended the chat session with ' + advisorName + '.';
                case 'Unknown':
                    return 'Please try again later or contact with another advisor.';
                case 'AdvisorEnded':
                    return advisorName + ' has ended the chat session.';
                case 'AdvisorDisconnect':
                    return advisorName + ' lost internet connection. Please try again later or contact another advisor.';
                case 'AdvisorEndedRechargeTimeout':
                    return 'Chat ended due to low balance. Add funds and try again for new session.';
                case 'ConsumerDisconnect':
                    return 'Your internet connection was lost. Please make sure your device has an active internet connection.';
                case 'ClearedStuckSession':
                    return 'There was a problem  with starting your chat session. Please try to reconnect with ' + advisorName + ' or contact other advisor.';
                case 'ConsumerEndedRechargeTimeout':
                    return 'Chat session has ended due to insufficient balance. Add funds and try again later.';
                case 'AdvisorBackButtonClicked':
                    return advisorName + ' has ended the chat session.';
                case 'ConsumerIdleTimeout':
                    return 'We ended the chat session due to your inactivity. Please try to reconnect for a new session.';
                case 'AdvisorIdleTimeout':
                    return 'We ended the chat due to ' + advisorName + ' inactivity. Try again later or contact other advisor.';
                case 'AdvisorRechargeError':
                    return 'There was a problem with continuing your chat session. Please try to reconnect with ' + advisorName + ' or contact other advisor.';
            }
            return null;
        },
        getChatEndReasonTextForAdvisor: function getChatEndReasonTextForAdvisor(chatEndReason) {
            switch (chatEndReason) {
                case 'ConsumerEnded':
                case 'ConsumerEndedDuringRecharge':
                case 'ConsumerBackButtonClicked':
                case 'ConsumerIdleTimeout':
                    return 'Customer has ended the chat session.';
                case 'Unknown':
                    return 'You can follow up with your customer via mail or a ping.';
                case 'AdvisorEnded':
                    return 'You ended the chat session.';
                case 'AdvisorDisconnect':
                    return 'Your internet connection was lost, please make sure your device has an active internet connection.';
                case 'ConsumerDisconnect':
                    return 'Your customer lost internet connection. Please send a mail or a ping with your availability.';
                case 'AdvisorIdleTimeout':
                    return 'We ended the chat session due to your inactivity. You can follow up with your customer via mail or a ping.';
                case 'AdvisorRechargeError':
                    return 'There was a problem with continuing your chat.';
                case 'AdvisorStateChangeTimeOut':
                    return 'Customer did not join the chat session.';
                case 'ConsumerEndedRechargeTimeout':
                case 'AdvisorEndedRechargeTimeout':
                    return 'Chat session has ended due to the customer’s insufficient balance.';

            }
            return null;
        },


        endChatResonText: function () {
            var chatEndReason = this.get('chatEndReason');
            var isAdvisor = this.get('isAdvisor');

            if (isAdvisor) {
                return this.getChatEndReasonTextForAdvisor(chatEndReason);
            }

            var advisorName = this.get('userName');

            return this.getChatEndReasonTextForConsumer(chatEndReason, advisorName);
        }.property('chatEndReason', 'isAdvisor', 'userName')
    });
});