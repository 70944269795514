define('ingenio-web/components/transaction-details/card-payment-detail/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        paymentCardSvg: function () {
            var cardType = this.get('model.cardType');

            return "payment-cards/" + cardType;
        }.property('model.cardType'),

        payAmount: function () {
            return this.get('model.payAmount').toFixed(2);
        }.property('model.payAmount'),

        payPalEmailTruncated: function () {
            var payPalEmail = this.get('model.payPalEmail');
            var maxLength = 15;

            return payPalEmail.length > maxLength ? payPalEmail.substring(0, maxLength - 3) + '...' : payPalEmail;
        }.property('model.payPalEmail'),

        isFixedPriceSession: false //passed-in

    });
});