define('ingenio-web/models/advisor-availability-for-customer', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        advisorChatAvailability: (0, _attr.default)(),
        advisorCallAvailability: (0, _attr.default)()
    });
});