define("ingenio-web/objects/offer-reward-types", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var OfferRewardTypes = {
        MONEY: 1,
        MINUTES: 2,
        ASSETS: 5
    };

    exports.default = OfferRewardTypes;
});