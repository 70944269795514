define('ingenio-web/components/myaccount/customer-feedback-grid', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gridApi: null,
    gridColumnApi: null,
    busy: Ember.inject.service('busy'),
    notifications: Ember.inject.service('toast'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    didInsertElement: function didInsertElement() {
      this.set('gridOptions.parent', this);
      this.set('register-as', this);
    },

    gridOptions: {
      pagination: true,
      suppressPaginationPanel: true,
      gridAutoHeight: true,
      enableColResize: true,
      enableSorting: true,
      suppressCellSelection: true,
      suppressClickEdit: true,
      sortingOrder: ['desc', 'asc'],
      rowSelection: 'multiple',
      rowGroupPanelShow: 'never',

      parent: null,
      icons: {
        sortAscending: '<i class="grid-sort-icon fa fa-caret-up"/>',
        sortDescending: '<i class="grid-sort-icon fa fa-caret-down"/>'
      },

      columnDefs: [{
        headerName: 'Date',
        field: 'createdDate',
        width: 120,
        suppressResize: true,
        suppressSizeToFit: true,
        valueFormatter: function valueFormatter(params) {
          return params.value ? (0, _moment.default)(params.value).format('L') : '‒';
        }
      }, {
        headerName: 'Rating',
        cellClass: 'cell-wrap-text',
        field: 'rating',
        autoHeight: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.data.rating) return null;

          var width = params.data.rating / 5 * 100;
          return '<div class="star-rating rating-xss"><div class="rating-container rating-gly-star" data-content="\uE006\uE006\uE006\uE006\uE006"><div class="rating-stars" data-content="\uE006\uE006\uE006\uE006\uE006" style="width: ' + width + '%;"></div></div>\n                  <div class="feedback-comments">' + params.data.comment + '<div> ';
        }
      }],

      onGridReady: function onGridReady(params) {
        if (this.parent && this.parent.onGridReady) {
          this.parent.onGridReady(params);
        }
      }
    },

    // bind all required events
    onGridReady: function onGridReady(params) {
      params.api.sizeColumnsToFit();

      // control sort change
      params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));

      // control row selection
      params.api.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));

      setTimeout(function () {
        params.api.resetRowHeights();
      }, 500);

      // just in case store refs to grid and column api
      this.set('gridApi', params.api);
      this.set('gridColumnApi', params.columnApi);

      // get data from server side
      this.refreshGrid();
    },

    // control grid pagination
    gridActualPageSizeChanged: function () {
      if (this.get('gridApi')) {
        this.get('gridApi').paginationSetPageSize(this.get('pagination.actualPageSize'));

        if (this.get('pagination.actualPageSize') == 0) {
          this.get('gridApi').showNoRowsOverlay();
        }
      }
    }.observes('pagination.totalRecords', 'pagination.actualPageSize'),

    gridPaginationChanged: function () {
      this.refreshGrid();
    }.observes('pagination.currentPage', 'pagination.pageSize'),

    // control grid row selection
    onSelectionChanged: function onSelectionChanged() {
      var _this = this;

      if (!this.get('selectedRows')) return;

      this.get('selectedRows').clear();

      var gridSelectedRows = this.get('gridApi').getSelectedRows();
      gridSelectedRows.forEach(function (selectedRow) {
        _this.get('selectedRows').addObject(selectedRow);
      });
    },

    // handle grid sorting
    onSortChanged: function onSortChanged(params) {
      this.refreshGrid();
    },

    // retreive grid data
    refreshGrid: function refreshGrid() {
      var _this2 = this;

      var gridApi = this.get('gridApi');

      gridApi.deselectAll();
      this.get('busy').show();
      this.get('gridApi').hideOverlay();

      var sorting = null;
      sorting = gridApi.getSortModel().map(function (item) {
        return {
          field: item.colId,
          order: item.sort == "desc" ? "Descending" : "Ascending"
        };
      });

      var advisorId = Ember.Application.user.id;
      var customerId = this.get('customerId');

      var pageNumber = this.get('pagination.currentPage');
      var rowsPerPage = this.get('pagination.pageSize');

      var sortField = 'createdDate';
      var sortOrder = 'Descending';
      if (sorting.length === 1) {
        sortField = sorting[0].field;
        sortOrder = sorting[0].order;
      }

      var queryParams = 'pageInfo.pageNumber=' + pageNumber + '&pageInfo.rowsPerPage=' + rowsPerPage + '&pageInfo.sortField=' + sortField + '&pageInfo.sortOrder=' + sortOrder;

      return Ember.RSVP.hash({
        data: Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customers/' + customerId + '/feedback?' + queryParams, {
          method: 'GET',
          contentType: 'application/json'
        })
      }).then(function (result) {
        var feedbackConnection = result.data.customerFeedback;

        _this2.get('pagination').set('totalRecords', feedbackConnection.totalItems);

        gridApi.setRowData(feedbackConnection.data);

        setTimeout(function () {
          gridApi.resetRowHeights();
        }, 500);
      }, function () {
        _this2.get('notifications').error('Error getting data from server', 'Feedback History Grid', {
          positionClass: 'toast-top-right'
        });
      }).finally(function () {
        _this2.get('busy').hide();
      });
    }
  });
});