define('ingenio-web/components/footer/collapsible-minimal-footer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		privacyPolicyHelpUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs + '/articles/1500000300002-Ingenio-Privacy-Policy';
		}.property()
	});
});