define('ingenio-web/mixins/checkout-flow/controller-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function debounce(func, delay) {
    var timeoutId = void 0;

    return function () {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      clearTimeout(timeoutId);
      if (args[3]) {
        timeoutId = setTimeout(function () {
          func.apply(_this, args);
        }, delay);
      } else {
        func.apply(undefined, args);
      }
    };
  }

  function getAmountWithFees(current, listingId, amount) {
    current.store.createRecord('orderPrepare').save({ adapterOptions: { listingId: listingId, additionalFunds: amount } }).then(function (result) {
      return current.set('model.orderPrepare', result);
    });
  }
  var debouncedGetAmountWithFees = debounce(getAmountWithFees, 1000);

  exports.default = Ember.Mixin.create({
    isAddFundsAmountValid: true,

    showConversationPaymentMethodConfirmBlock: Ember.computed('showAddMoney', 'model.isUserEligibleForConversationPostPay', 'model.conversationPaymentMethod', 'model.payAfterConversationSelected', function () {
      var showAddMoney = this.get('showAddMoney');

      if (showAddMoney) {
        return false;
      }

      var conversationPaymentMethod = this.get('model.conversationPaymentMethod');
      var isUserEligibleForConversationPostPay = this.get('model.isUserEligibleForConversationPostPay');
      var payAfterConversationSelected = this.get('model.payAfterConversationSelected');

      return isUserEligibleForConversationPostPay && (!conversationPaymentMethod || payAfterConversationSelected);
    }),

    showAddMinutes: Ember.computed.equal('model.showDepositeType', 'addMinutes'),
    showAddMoney: Ember.computed.equal('model.showDepositeType', 'addMoney'),
    hideSteps: Ember.computed.equal('model.showStepsType', 'hide'),
    show3Steps: Ember.computed.equal('model.showStepsType', 'show3Steps'),
    show2Steps: Ember.computed.equal('model.showStepsType', 'show2Steps'),

    actions: {
      getChargeableAmount: function getChargeableAmount(listingId, amount) {
        var debounce = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

        debouncedGetAmountWithFees(this, listingId, amount, debounce);
      },
      onAmountChanged: function onAmountChanged(amount) {
        this.set('model.user.selectedBox', '$custom');
        this.set('model.user.enteredAmount', amount);
      },
      onAddFundsValidityChange: function onAddFundsValidityChange(validationState) {
        this.set('isAddFundsAmountValid', validationState.valid);
      },
      onConversationPaymentMethodChanged: function onConversationPaymentMethodChanged(conversationPaymentMethod) {
        this.set('model.conversationPaymentMethod', conversationPaymentMethod);
      },
      onAutoReloadIncreaseTypeChanged: function onAutoReloadIncreaseTypeChanged(selectedMinuteOption) {
        this.set('model.user.selectedBox', selectedMinuteOption);
        this.set('model.user.buyMinutes', null);
      },
      onSelectedBoxChanged: function onSelectedBoxChanged(selectedMinuteOption) {
        this.set('model.user.selectedBox', selectedMinuteOption);
        this.set('model.user.buyMinutes', null);
      }
    }
  });
});