define('ingenio-web/appointment/controller', ['exports', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/scroll-helpers', 'ingenio-web/models/step', 'ingenio-web/mixins/checkout-flow/controller-base'], function (exports, _errorHandler, _transitionHelpers, _scrollHelpers, _step, _controllerBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_controllerBase.default, _errorHandler.default, _transitionHelpers.default, _scrollHelpers.default, {
    pmode: 0,
    webtrack: 1,
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    r: true, //we use in for redirect from confirmation to details without back redirect in case of enough money
    iovationClient: Ember.inject.service('iovation'),
    flashMessages: Ember.inject.service(),
    userEvents: Ember.inject.service(),

    _showConfirmDepositDialog: false,

    displayConfirmDepositDialog: Ember.computed.equal('_showConfirmDepositDialog', true),

    steps: Ember.computed('target.currentPath', function () {
      var activeRoute = this.get('target.currentPath');

      var allSteps = [_step.default.create({
        name: 'Time Selection',
        title: '',
        route: 'appointment.time-selection'
      }), _step.default.create({
        name: 'Survey',
        title: '',
        route: 'appointment.details'
      }), _step.default.create({
        name: 'Payment',
        title: 'Payment',
        route: 'appointment.payment-details'
      }), _step.default.create({
        name: 'Number Confirmation',
        title: 'Number Confirmation',
        route: 'appointment.phone-number'
      }), _step.default.create({
        name: 'Booking Confirmation',
        title: 'Booking Confirmation',
        route: 'appointment.confirmation'
      })];

      return allSteps.map(function (step) {
        step.set('active', step.get('route') === activeRoute);
        return step;
      });
    }),

    showChangePhonesLink: Ember.computed('model.user.phoneNumbers.@each', function () {
      return !Ember.isEmpty(this.get('model.user.phoneNumbers'));
    }),

    showChangePhonesLinkQS: Ember.computed('showChangePhonesLink', 'quickStartFlow', function () {
      var quickStartFlow = this.get('quickStartFlow');

      if (quickStartFlow) {
        return false;
      }

      var showChangePhonesLink = this.get('showChangePhonesLink');

      return showChangePhonesLink;
    }),

    selectedPhoneNumber: Ember.computed('model.user.phoneNumbers.@each', function () {
      return this.get('model.user.phoneNumbers').find(function (phone) {
        return phone.number.isPrimary;
      });
    }),

    savePhoneNumbers: function savePhoneNumbers() {
      var _this = this;

      // only in case of changes (actions.onPhoneNumberChange) the property will be populated
      // if not simply return list of user phones
      if (!this.get('phones')) {
        return this.store.findAll('userPhone');
      }

      var promiseHashes = [];

      this.get('phones').forEach(function (phone) {
        var phoneNumberWithoutCountryCode = phone.number.number.replace('+' + phone.number.countryCode, '');

        var dsPhone = _this.get('model.user.phoneNumbers').find(function (dsPhone) {
          return dsPhone.get('id') === phone.number.id;
        });

        if (Ember.isEmpty(phoneNumberWithoutCountryCode) && !Ember.isEmpty(dsPhone)) {
          promiseHashes.push(dsPhone.destroyRecord());

          return;
        }

        if (!Ember.isEmpty(phoneNumberWithoutCountryCode) && Ember.isEmpty(dsPhone)) {
          dsPhone = _this.store.createRecord('userPhone');
        }

        if (!Ember.isEmpty(dsPhone)) {

          var phoneChanged = dsPhone.get('phoneChanged') || !(dsPhone.get('number') === phoneNumberWithoutCountryCode && dsPhone.get('countryCode') === phone.number.countryCode);

          if (phoneChanged) {
            if (!dsPhone.get('oldNumber')) {
              dsPhone.setProperties({
                oldNumber: dsPhone.get('id') ? dsPhone.get('number') : ''
              });
            }

            if (!dsPhone.get('oldCountryCode')) {
              dsPhone.setProperties({
                oldCountryCode: dsPhone.get('id') ? dsPhone.get('countryCode') : ''
              });
            }
          }

          dsPhone.setProperties({
            phoneType: phone.type.id,
            isPrimary: phone.number.isPrimary,
            isUSNumber: phone.number.isUSNumber,
            countryCode: phone.number.countryCode,
            number: phoneNumberWithoutCountryCode,
            phoneChanged: phoneChanged
          });

          promiseHashes.push(dsPhone.save({ adapterOptions: { filters: { ntvCheckEnabled: true } } }));
        }
      });

      return Ember.RSVP.hash(promiseHashes).then(function () {
        _this.get('model.user.phoneNumbers').forEach(function (p) {
          return p.setProperties({ phoneChanged: false, oldNumber: null, oldCountryCode: null });
        });

        return _this.store.findAll('userPhone');
      });
    },
    addFundsToAccount: function addFundsToAccount() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var amountToCharge = _this2.get('model.amountToCharge');

        // don't need to add money if user didn't select amount to add or selected pay after chat option
        if (!amountToCharge || _this2.get('model.payAfterConversationSelected')) {
          resolve(null);
        } else {
          _this2.get('model.user.funds').setProperties({
            eventSource: 'WebSite',
            additionalAmount: amountToCharge,
            deviceId: _this2.get('iovationClient').getDeviceId(),
            additionalFundsInfo: _this2.store.createFragment('additionalFundsInfo', {
              priceBucketMinutes: null,
              source: _this2.get('model.isChatConversation') ? 'arrange_chat' : 'arrange_call',
              rechargeMethod: 'ManualInput' //ManualInput, PriceBucket, PostPay
            })
          });

          _this2.get('model.user.funds').save().then(function () {
            _this2.store.findRecord('balance', Ember.Application.user.id, {
              adapterOptions: {
                listingId: _this2.get('model.listing.id')
              },
              reload: true
            }).then(function (balance) {
              _this2.getCurrentSpecialOffer().finally(function () {
                _this2.set('model.user.balance', balance);

                // the current 'add funds' api does not include a designated primary key and
                // within Ember we hard-code the identity value to the user's id. unloading the funds
                // model(s) from the store fixes a primary key collision error between add funds operations.
                _this2.store.unloadAll('funds');

                var newFundsModel = _this2.store.createRecord('funds');
                var currentFundsModel = _this2.get('model.user.funds');

                newFundsModel.set('cvvCode', currentFundsModel.get('cvvCode'));
                newFundsModel.set('paymentMethodId', currentFundsModel.get('paymentMethodId'));

                _this2.set('model.user.funds', newFundsModel);

                resolve(balance);
              });
            });
            _this2.get('userEvents').fundsAdded('Purchase');
          }).catch(function (error) {
            return reject(error);
          });
        }
      });
    },
    getCurrentSpecialOffer: function getCurrentSpecialOffer() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.store.queryRecord('specialOffer', {
          listingId: _this3.get('model.listing.id')
        }).then(function (specialOffer) {
          _this3.set('model.user.offer', specialOffer);
          resolve(specialOffer);
        }).catch(function (adapterError) {
          if (adapterError && Ember.get(adapterError, 'errors.firstObject.status') === 404) {
            _this3.set('model.user.offer', undefined);
            resolve(null);
          } else {
            reject(adapterError);
          }
        });
      });
    },
    showConfirmDepositDialog: function showConfirmDepositDialog(isAvsReqd) {
      var _this4 = this;

      var selectedCreditCard = this.get('model.paymentMethodSelected');

      // get credit card and show dialog to provide new address or cvv code
      this.get('store').findRecord('creditCard', selectedCreditCard.id).then(function (entity) {
        //selectedCreditCard.set('address', entity.get('address'));
        entity.set('isAvsRequired', isAvsReqd);
        entity.set('isCvnRequired', true);

        // validate credit card with newly assigned attributes
        entity.validate();

        _this4.set('avsModel', entity);
        _this4.set('_showConfirmDepositDialog', true);
      });
    },
    startEditPhones: function startEditPhones() {
      var oldPrimaryPhone = this.get('model.user.phoneNumbers').find(function (dsPhone) {
        return dsPhone.get('isPrimary');
      });

      if (oldPrimaryPhone) {
        this.set('oldPrimaryPhoneId', oldPrimaryPhone.get('id'));
      }
    },
    cancelEditPhones: function cancelEditPhones() {
      var phones = this.get('model.user.phoneNumbers');

      var oldPrimaryPhoneId = this.get('oldPrimaryPhoneId');

      phones.forEach(function (phone) {
        if (phone.get('phoneChanged')) {
          phone.setProperties({
            countryCode: phone.get('oldCountryCode'),
            number: phone.get('oldNumber')
          });
        }
        if (oldPrimaryPhoneId) {
          phone.setProperties({
            isPrimary: phone.get('id') === oldPrimaryPhoneId
          });
        }
      });
    }
  });
});