define("ingenio-web/services/iovation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {},
    getDeviceId: function getDeviceId() {
      return window.io_blackbox ? window.io_blackbox.value : "";
    }
  });
});