define('ingenio-web/services/audio-notifications', ['exports', 'ingenio-web/utils/logger'], function (exports, _logger) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function HowlExtended(options, logger) {
        options['onloaderror'] = onloaderror;
        options['onplayerror'] = onplayerror;

        var howl = new Howl(options);

        function onloaderror(spriteId, errorCode) {
            var mediaError = '';

            if (errorCode === 1) {
                // Fetching process for media resource was aborted by useragent.
                mediaError = 'MEDIA_ERR_ABORTED';
            } else if (errorCode === 2) {
                // A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.
                mediaError = 'MEDIA_ERR_NETWORK';
            } else if (errorCode === 3) {
                // An error of some description occured while decoding the media resource, after the resource was established to be usable.
                mediaError = 'MEDIA_ERR_DECODE';
            } else if (errorCode === 4) {
                // The media resource indicated by the src attribute or assigned media provider object was not suitable.
                mediaError = 'MEDIA_ERR_SRC_NOT_SUPPORTED';
            }

            console.error('Unable to load sound:', spriteId, errorCode, mediaError);

            logger.error('Error Loading Sound', {
                originalError: {
                    errorCode: errorCode,
                    mediaErrorType: mediaError,
                    page: window.document.title
                }
            });
        }

        function onplayerror(spriteId, error) {
            console.error(error);

            logger.error('Error Playing Sound', {
                originalError: {
                    error: error,
                    page: window.document.title
                }
            });
            // in the event a play error is encountered, reset the loop counter & stop playback.
            counter = 0;
            howl.stop();
        }

        howl.playIfNotPlaying = function () {
            if (!howl.playing()) {
                howl.play();
            }
        };

        howl.stopIfPlaying = function () {
            if (howl.playing()) {
                howl.stop();
            }
        };

        return howl;
    }

    var getHowl = function getHowl(audioFile, logger) {
        var playCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

        var counter = 0;

        // by default, HowlerJS does not appear to support functionality to loop playback x times.
        // this code uses Howler's documented callbacks to handle looping playback up to the playCount
        // parameter specified. This was implemented to maintain existing functionality that was implemented
        // with SoundManager2.
        var howl = new HowlExtended({
            src: [audioFile],
            onplay: function onplay() {
                return howl.loop(playCount > 1);
            },
            onend: function onend() {
                if (counter >= playCount - 1) {
                    counter = 0;
                    howl.stop();
                } else {
                    counter++;
                }
            }
        }, logger);

        return howl;
    };

    var getChatHowl = function getChatHowl(audioFile, logger) {
        var howl = new HowlExtended({
            src: [audioFile],
            loop: true
        }, logger);

        return howl;
    };

    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),

        init: function init() {
            var advisorId = Ember.Application.user.id;
            var loggerOptions = {
                advisorId: advisorId,
                source: 'Monitor'
            };

            var logger = new _logger.default(this.get('store'), loggerOptions);

            this.howls = {
                connected: getHowl('Sounds/Ding.mp3', logger),
                testAlert: getHowl(Ember.ENV.domainId === 1 ? 'Sounds/ChatMonitorAlert.mp3' : 'Sounds/CallMonitorAlert.mp3', logger),
                incomingCall: getHowl('Sounds/CallMonitorAlert.mp3', logger),
                incomingChat: getChatHowl('Sounds/ChatMonitorAlert.mp3', logger)
            };
        },
        playConnectedAlert: function playConnectedAlert() {
            this.howls.connected.playIfNotPlaying();
        },
        playTestAlert: function playTestAlert() {
            this.howls.testAlert.playIfNotPlaying();
        },
        playIncomingCallAlert: function playIncomingCallAlert() {
            this.howls.incomingCall.playIfNotPlaying();
        },
        playIncomingChatAlert: function playIncomingChatAlert() {
            this.howls.incomingChat.playIfNotPlaying();
        },
        stop: function stop() {
            var _this = this;

            var _loop = function _loop() {
                var h = _this.howls[howl];

                h.stopIfPlaying();
                //To prevent case when we start playing after user gesture which could start few sec after we called stop()
                setTimeout(function () {
                    return h.stopIfPlaying();
                }, 1000);
            };

            for (var howl in this.howls) {
                _loop();
            }
        }
    });
});