define('ingenio-web/models/quality-score', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        componentName: (0, _attr.default)('string'),
        score: (0, _attr.default)('number', { defaultValue: 0 }),
        measure: (0, _attr.default)('string')
    });
});