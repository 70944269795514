define('ingenio-web/components/terms-of-service-link/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        link: Ember.computed('type', function () {
            var isKeen = Ember.ENV.domainId === 1;

            return isKeen ? 'https://help.keen.com/hc/en-us/articles/4407128751635-Mobile-Messaging-Terms-of-Service' : '';
        })
    });
});