define('ingenio-web/chat-setup/controller', ['exports', 'ingenio-web/models/step', 'ingenio-web/mixins/checkout-flow/controller-base', 'ingenio-web/helpers/fbm-user'], function (exports, _step, _controllerBase, _fbmUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controllerBase.default, {
        steps: Ember.computed('target.currentPath', function () {
            var activeRoute = this.get('target.currentPath');
            (0, _fbmUser.setFbmUserVar)();

            var allSteps = [_step.default.create({
                name: 'Details',
                title: 'Details',
                route: 'chat-setup.details'
            }), _step.default.create({
                name: 'Payment Method',
                title: 'Payment',
                route: 'chat-setup.payment-details'
            }), _step.default.create({
                name: 'Chat',
                title: 'Confirmation',
                route: 'chat-setup.confirmation'
            })];

            return allSteps.map(function (step) {
                step.set('active', step.get('route') === activeRoute);

                return step;
            });
        })
    });
});