define('ingenio-web/redeem-offer/route', ['exports', 'ingenio-web/models/redeem-offer-flow'], function (exports, _redeemOfferFlow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params, transition) {
      var _this = this;

      var user = Ember.Application.user;
      var returnUrl = transition.queryParams.returnUrl;

      return Ember.RSVP.hash({
        returnUrl: returnUrl,
        countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
        offer: this.store.findRecord('specialOffer', user.id).then(function (payload) {
          return payload;
        }).catch(function () {
          _this.replaceWith('redeem-offer.no-offer', {
            queryParams: transition.queryParams
          });
        }),
        balance: this.store.findRecord('balance', user.id, {
          adapterOptions: {}
        }),
        paymentMethods: this.store.findAll('paymentMethod')
      }).then(function (payload) {
        return _this.transformPromiseHashIntoViewModel(payload);
      });
    },
    transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
      var fundsModel = this.store.createRecord('funds');

      // use the default payment method, by default, if found.
      // handle a weird edge case where the user does not have any primary
      // payment methods and choose the first object by default.
      if (!Ember.isEmpty(payload.paymentMethods)) {
        var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
          return method.get('isPrimary');
        }) || payload.paymentMethods.get('firstObject');

        fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
      }

      return _redeemOfferFlow.default.create({
        domain: {
          id: Ember.ENV.domainId,
          name: Ember.ENV.domainName
        },
        returnUrl: payload.returnUrl,
        user: {
          funds: fundsModel,
          offer: payload.offer,
          balance: payload.balance,
          paymentMethods: payload.paymentMethods
        },
        countries: payload.countries
      });
    }
  });
});