define('ingenio-web/models/checkout-flow', ['exports', 'ingenio-web/objects/conversation-payment-methods'], function (exports, _conversationPaymentMethods) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        flowType: 'checkout-flow',

        domain: null,
        listing: null,
        user: {
            offer: null,
            funds: null,
            balance: null,
            phoneNumbers: null,
            priceBuckets: null
        },
        arrangedChat: null,

        preferredCurrency: Ember.computed('Ember.Application.user.preferredCurrency', function () {
            return Ember.Application.user.preferredCurrency;
        }),

        conversationPaymentMethod: null,

        quickStart: false,

        payAfterConversationSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.PayAfterConversation),
        payBeforeConversationSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.PayBeforeConversation),

        conversationPaymentMethodSelected: Ember.computed('conversationPaymentMethod', function () {
            return !!this.get('conversationPaymentMethod');
        }),

        autoReloadSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.AutoReload),

        chatAvailable: Ember.computed.equal('chatAvailability', 'Available'),
        chatUnavailable: Ember.computed.equal('chatAvailability', 'Unavailable'),
        chatArrange: Ember.computed.equal('chatAvailability', 'Arrange'),
        chatBusy: Ember.computed.equal('chatAvailability', 'Busy'),

        hasExistingPaymentMethods: Ember.computed('user.paymentMethods.@each', function () {
            return !Ember.isEmpty(this.get('user.paymentMethods'));
        }),

        hasExistingPhoneNumber: Ember.computed('user.phoneNumbers.@each', function () {
            return !Ember.isEmpty(this.get('user.phoneNumbers'));
        }),

        hasOffer: Ember.computed('user.offer', function () {
            return !Ember.isEmpty(this.get('user.offer'));
        }),

        paymentMethodSelected: Ember.computed('user.funds.paymentMethodId', 'user.paymentMethods.@each', function () {
            var _this = this;

            return this.get('user.paymentMethods').find(function (method) {
                return method.get('id') === _this.get('user.funds.paymentMethodId');
            });
        }),

        totalFeesDue: Ember.computed('orderPrepare.fees.charges', function () {
            return this.get('orderPrepare.fees.charges').reduce(function (aggregator, item) {
                return aggregator + item.amount;
            }, 0);
        }),

        feesNames: Ember.computed('orderPrepare.fees.charges', function () {
            return this.get('orderPrepare.fees.charges').reduce(function (aggregator, item) {
                if (aggregator.length > 0) {
                    return aggregator + ', ' + item.name;
                }
                return item.name;
            }, "").replace(/,(?=[^,]+$)/, ' and');
        }),

        showFeesMessaging: Ember.computed('totalFeesDue', 'isAddFundsRequired', function () {
            if (!this.get('isAddFundsRequired')) {
                return false;
            }

            return this.get('totalFeesDue') > 0;
        }),

        absoluteMinimumDepositAmount: Ember.computed('hasOffer', function () {
            return this.get('hasOffer') || !Ember.Application.user.hasAddedFundsInPast ? Ember.ENV.minimumDepositAmount : this.get('repeatUserMinimumDepositAmount');
        }),

        minimumBalanceRequiredForListing: Ember.computed('absoluteMinimumDepositAmount', 'user.balance.sellerMinutes', 'listing.minuteRateAsNumber', 'totalFeesDue', function () {
            var minimumAmountDue = this.get('listing.minuteRateAsNumber') * Ember.ENV.minimumBalanceMinutesForConversation;
            var sellerMinutes = this.get('user.balance.sellerMinutes');
            var totalFees = this.get('totalFeesDue');

            if (sellerMinutes > 0) {
                var absoluteMinimumDepositAmount = this.get('absoluteMinimumDepositAmount');
                return absoluteMinimumDepositAmount > totalFees ? absoluteMinimumDepositAmount : totalFees;
            }

            if (this.get("user.offer.rewardType") === 6) {
                var rewardAmount = this.get("user.offer.rewardAmount");

                var discountedMinuteRate = this.get('listing.minuteRateAsNumber') * 100 * (100 - rewardAmount) / 100;
                discountedMinuteRate = Math.ceil(discountedMinuteRate) / 100;
                minimumAmountDue = discountedMinuteRate * Ember.ENV.minimumBalanceMinutesForConversation;;
            }

            return Number((minimumAmountDue + totalFees).toFixed(2));
        }),

        minimumDepositAmount: Ember.computed('minimumBalanceRequiredForListing', 'user.balance.monetaryAsNumber', 'absoluteMinimumDepositAmount', 'listing.minuteRateAsNumber', 'isAddFundsRequired', 'hasOffer', function () {
            var absoluteMinimumDepositAmount = this.get('absoluteMinimumDepositAmount');
            var accountBalance = this.get('user.balance.monetaryAsNumber');

            var minimumBalanceRequired = this.get('minimumBalanceRequiredForListing');
            if (accountBalance > 0) {
                minimumBalanceRequired -= accountBalance;
            }

            if (this.get('isAddFundsRequired') && minimumBalanceRequired > absoluteMinimumDepositAmount) {
                return Number(minimumBalanceRequired.toFixed(2));
            }

            return absoluteMinimumDepositAmount;
        }),

        minimumDepositAmountForAutoreload: Ember.computed('minimumBalanceRequiredForListing', 'user.balance.monetaryAsNumber', 'absoluteMinimumDepositAmount', 'listing.minuteRateAsNumber', 'autoReloadSelected', 'minimumDepositAmount', function () {

            if (this.get('autoReloadSelected')) {
                var absoluteMinimumDepositAmount = this.get('absoluteMinimumDepositAmount');

                if (this.get('minimumBalanceRequiredForListing') * 2 > absoluteMinimumDepositAmount) {
                    return this.get('minimumBalanceRequiredForListing') * 2;
                }

                return absoluteMinimumDepositAmount;
            }
            return this.get('minimumDepositAmount');
        }),

        maximumDepositAmount: Ember.computed('user.offer.qualifierAmount', function () {
            return Number(Ember.ENV.maximumDepositAmount) - Number(this.get('user.offer.qualifierAmount') || 0);
        }),

        amountToCharge: Ember.computed('user.funds.additionalAmount', function () {
            var additionalFunds = Number(this.get('user.funds.additionalAmount') || 0);
            return Number(additionalFunds.toFixed(2));
        }),

        talkTimeMinutes: Ember.computed("listing.minuteRateAsNumber", "user.balance.monetaryAsNumber", "amountToCharge", "user.offer.rewardMinutes", "user.offer.rewardMoney", "user.offer.qualifierAmount", "user.balance.offerPromotionMinutes", "user.balance.sellerMinutes", "totalFeesDue", function () {
            var userBalance = Number(this.get("user.balance.monetaryAsNumber"));
            var amountToCharge = this.get('amountToCharge');

            // TODO: we should migrate talk time calc to backend in next.js port
            // the long-term plan is to utilize the orders/prepare endpoint to owe calculating talk time to
            // standardize web & app.
            var totalFeesDue = this.get("totalFeesDue");
            var fundsAvailable = Number((userBalance + amountToCharge).toFixed(2));

            var balance = Number((fundsAvailable >= totalFeesDue ? fundsAvailable - totalFeesDue : 0).toFixed(2));

            if (this.get("user.offer.rewardMoney") > 0) {
                var offerMoney = this.get("user.offer.rewardMoney");

                // if (this.get('user.offer.qualifierAmount')) {
                //     offerMoney += this.get('user.offer.qualifierAmount');
                // }

                balance += offerMoney;
            }

            var talkMinutes = Math.floor(balance / this.get("listing.minuteRateAsNumber"));

            // we can't have offer.rewardMinutes and balance.offerPromotionMinutes at the same time
            if (this.get("user.offer.rewardMinutes") > 0 && !(this.get("user.balance.offerPromotionMinutes") > 0)) {
                talkMinutes += this.get("user.offer.rewardMinutes");
            }

            if (this.get("user.balance.offerPromotionMinutes") > 0) {
                talkMinutes += this.get("user.balance.offerPromotionMinutes");
            }

            if (this.get("user.balance.sellerMinutes") > 0) {
                talkMinutes += this.get("user.balance.sellerMinutes");
            }

            return talkMinutes;
        }),

        totalMinutesWithCurrentOffer: Ember.computed('listing.minuteRateAsNumber', 'user.balance.callMinutes', 'user.offer.rewardMinutes', 'user.offer.rewardMoney', 'user.offer.qualifierAmount', function () {
            var talkMinutes = Number(this.get('user.balance.callMinutes')) || 0;

            if (this.get('user.offer.rewardMoney') > 0) {

                var offerMoney = this.get('user.offer.rewardMoney');

                if (this.get('user.offer.qualifierAmount')) {
                    offerMoney += this.get('user.offer.qualifierAmount');
                }

                talkMinutes += Math.floor(offerMoney / this.get('listing.minuteRateAsNumber'));
            }

            if (this.get('user.offer.rewardMinutes') > 0) {
                talkMinutes += this.get('user.offer.rewardMinutes');
            }

            return talkMinutes;
        }),

        isAddFundsRequired: Ember.computed('minimumBalanceRequiredForListing', 'user.balance.monetaryAsNumber', 'totalMinutesWithCurrentOffer', function () {
            // check if user has promotional minutes and if they are sufficient to start a call per domain.
            if (this.get('totalMinutesWithCurrentOffer') >= Ember.ENV.minimumBalanceMinutesForConversation) {
                return false;
            }

            return this.get('user.balance.monetaryAsNumber') < this.get('minimumBalanceRequiredForListing');
        }),

        offerQualifierAmount: Ember.computed('user.offer.qualifierAmount', 'hasOffer', function () {
            if (!this.get('hasOffer')) {
                return null;
            }

            return this.get('user.offer.qualifierAmount');
        }),

        offerDiscountAmount: Ember.computed('user.offer.qualifierAmount', 'user.offer.rewardMinutes', 'listing.minuteRateAsNumber', 'user.offer.rewardType', function () {
            var rewardMinutes = this.get('user.offer.rewardMinutes');
            var qualifierAmount = this.get('user.offer.qualifierAmount');
            var minuteRateAsNumber = this.get('listing.minuteRateAsNumber');
            var rewardType = this.get('user.offer.rewardType');

            if (Ember.Application.user.firstBilledMinuteDate == null) {
                if (rewardType == 2) {
                    var discountAmount = rewardMinutes * minuteRateAsNumber - qualifierAmount;
                    if (discountAmount > 0) return discountAmount.toFixed(2);
                }
            }
            return 0;
        }),

        isNewUser: Ember.computed(function () {
            return Ember.Application.user.firstBilledMinuteDate == null;
        }),

        offerDescription: Ember.computed('user.offer.tinyDescription', 'hasOffer', function () {
            if (!this.get('hasOffer')) {
                return null;
            }

            return this.get('user.offer.tinyDescription');
        }),

        showAddMinutes: Ember.computed.equal('showDepositeType', 'addMinutes'),

        accountBalanceMinimumMet: Ember.computed('minimumBalanceRequiredForListing', 'user.balance.monetaryAsNumber', 'totalMinutesWithCurrentOffer', function () {
            if (this.get('totalMinutesWithCurrentOffer') >= Ember.ENV.minimumBalanceMinutesForConversation) {
                return true;
            }

            return this.get('user.balance.monetaryAsNumber') >= this.get('minimumBalanceRequiredForListing');
        }),

        accountBalanceWithExtraFundsMeetsMinimum: Ember.computed('minimumBalanceRequiredForListing', 'user.balance', 'user.balance.monetaryAsNumber', 'user.funds.amountAsNumber', 'accountBalanceMinimumMet', 'payAfterConversationSelected', function () {
            var accountBalanceMinimumMet = this.get('accountBalanceMinimumMet');
            var payAfterConversationSelected = this.get('payAfterConversationSelected');

            if (accountBalanceMinimumMet || payAfterConversationSelected) {
                return true;
            }

            var additionalFunds = this.get('user.funds.amountAsNumber');
            var userBalance = this.get('user.balance.monetaryAsNumber');
            var minimumBalanceForListing = this.get('minimumBalanceRequiredForListing');

            var availableFunds = Number((userBalance + additionalFunds).toFixed(2));
            return availableFunds >= minimumBalanceForListing;
        }),

        customerWillBeCharged: Ember.computed('user.funds.amountAsNumber', 'payAfterConversationSelected', function () {
            return this.get('user.funds.amountAsNumber') > 0 || this.get('payAfterConversationSelected');
        }),

        balanceAmountRounded: Ember.computed('user.balance', 'user.balance.monetaryAsNumber', function () {
            if (!this.get('user.balance.monetaryAsNumber')) {
                return '0.00';
            }

            return this.get('user.balance.monetaryAsNumber').toFixed(2);
        }),

        talkMinutesBasedOnBalance: Ember.computed('user.balance.monetaryAsNumber', 'user.balance.sellerMinutes', 'listing.minuteRateAsNumber', 'totalFeesDue', function () {
            // TODO: we should migrate talk time calc to backend in next.js port
            // the long-term plan is to utilize the orders/prepare endpoint to owe calculating talk time to
            // standardize web & app.
            var sellerMinutes = Number(this.get('user.balance.sellerMinutes') || 0);
            var userBalance = Number(this.get('user.balance.monetaryAsNumber'));
            var totalFeesDue = Number(this.get('totalFeesDue'));

            var balance = userBalance >= totalFeesDue ? userBalance - totalFeesDue : 0;
            var balanceMinutes = Math.floor(balance / this.get('listing.minuteRateAsNumber'));

            return balanceMinutes + sellerMinutes;
        }),

        isArrangedChatError: Ember.computed('arrangedChat', function () {
            var arrangedChatInfo = this.get('arrangedChat');

            if (arrangedChatInfo) {
                if (arrangedChatInfo.get('status') != 'CustomerNotified') {
                    return true;
                }
            }
            return false;
        }),

        arrangedChatErrorMessage: Ember.computed('arrangedChat', function () {
            var arrangedChatInfo = this.get('arrangedChat');

            if (arrangedChatInfo) {
                if (arrangedChatInfo.get('status') == 'Inactive') {
                    return 'Your arranged chat was previously accepted or has expired.';
                } else if (arrangedChatInfo.get('status') == 'Pending') {
                    return 'Your chat request has not yet been accepted.';
                }
            }
            return null;
        })
    });
});