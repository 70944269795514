define('ingenio-web/components/listings/listing-price', ['exports', 'ingenio-web/constants/domain', 'ingenio-web/objects/offer-reward-types'], function (exports, _domain, _offerRewardTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currency: function () {
            var price = this.get('price');

            return price.substring(0, 1);
        }.property('price'),

        rate: function () {
            var price = this.get('price');

            return price.substring(1);
        }.property('price'),

        strikeThrough: function () {
            var offerPrice = this.get('offerPrice');
            var isKeen = Ember.ENV.domainId === _domain.default.KEEN;
            var isClover = this.get('isClover') || false;

            if (isKeen && !isClover && offerPrice.PitchType && [_offerRewardTypes.default.MINUTES, _offerRewardTypes.default.ASSETS].includes(offerPrice.RewardType)) {
                return 'strike-through';
            }

            return '';
        }.property('offerPrice', 'isClover')
    });
});