define('ingenio-web/appointment/route', ['exports', 'ingenio-web/models/arrange-conversation-flow'], function (exports, _arrangeConversationFlow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        experimentation: Ember.inject.service('experimentation'),

        model: function model(params, transition) {
            var _this = this;

            var user = Ember.Application.user;
            var listingId = transition.queryParams.listingid;
            var aptTypeId = transition.queryParams.apttypeid;
            var useSessionBasedPricing = transition.queryParams.apttypeid ? true : false;

            var promises = {
                listing: this.store.findRecord('listing', listingId),
                countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
                balance: this.store.findRecord('balance', user.id, {
                    adapterOptions: {
                        listingId: listingId
                    },
                    reload: true
                }),
                userOffer: this.store.queryRecord('specialOffer', {
                    listingId: listingId
                }),
                paymentMethods: this.store.findAll('paymentMethod'),
                phoneNumbers: this.store.findAll('userPhone', { reload: true }),
                orderPrepare: this.store.createRecord('orderPrepare').save({ adapterOptions: { listingId: listingId } }),
                prepareAppointment: this.store.createRecord('prepareAppointment').save({ adapterOptions: { listingId: listingId, useSessionBasedPricing: useSessionBasedPricing, appointmentTypeId: aptTypeId } })
            };

            if (aptTypeId) {
                promises.appointmentType = Ember.$.getJSON(Ember.ENV.getApiRoot() + '/appointments/appointment-types/' + aptTypeId);
            }

            //TODO: try to reduce arranged conversation code reusage
            return Ember.RSVP.hash(promises).then(function (payload) {
                return _this.transformPromiseHashIntoViewModel(payload);
            });
        },


        actions: {
            onUserOfferDeleted: function onUserOfferDeleted() {
                var model = this.modelFor(this.routeName);

                model.set('showOfferRemovalMessage', true);
            }
        },

        transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
            var fundsModel = this.store.createRecord('funds');

            // use the default payment method, by default, if found.
            // handle a weird edge case where the user does not have any primary
            // payment methods and choose the first object by default.
            if (!Ember.isEmpty(payload.paymentMethods)) {
                var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
                    return method.get('isPrimary');
                }) || payload.paymentMethods.get('firstObject');

                fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
            }

            // when setting up the funds model ... populate the offer price if in an offer flow.
            if (payload.userOffer && payload.userOffer.get('qualifierAmount')) {
                fundsModel.set('offerId', payload.userOffer.get('id'));
                fundsModel.set('offerAmount', parseFloat(payload.userOffer.get('qualifierAmount')).toFixed(2));
            }

            return _arrangeConversationFlow.default.create({
                //conversationType: convType,
                notifications: { success: [], errors: [] },
                domain: {
                    id: Ember.ENV.domainId,
                    name: Ember.ENV.domainName
                },
                listing: payload.listing,
                user: {
                    notifications: { viaSms: { enabled: !!payload.phoneNumbers.findBy('isSmsNotificationEnabled', true) } },
                    hasAddedFundsInPast: Ember.Application.user.hasAddedFundsInPast,
                    funds: fundsModel,
                    balance: payload.balance,
                    offer: payload.userOffer,
                    paymentMethods: payload.paymentMethods,
                    phoneNumbers: payload.phoneNumbers
                },
                countries: payload.countries,

                showStepsType: 'hide',
                showDepositeType: 'addMoney',
                showOfferRemovalMessage: false, //Flag to display the message upon user offer
                orderPrepare: payload.orderPrepare,
                prepareAppointment: payload.prepareAppointment,
                appointmentType: payload.appointmentType ? payload.appointmentType.data : null
            });
        }
    });
});