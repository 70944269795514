define('ingenio-web/mixins/checkout-flow/confirmation/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    checkoutFlowModel: function checkoutFlowModel() {
      return this.modelFor(this.getParentRouteName()); //Get model for routes like 'call-setup' or 'chat-setup'
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var checkoutFlowModel = this.checkoutFlowModel();

      if (!checkoutFlowModel.get('accountBalanceWithExtraFundsMeetsMinimum')) {
        this.replaceWith(this.getParentRouteName() + '.details', {
          queryParams: transition.queryParams
        });
      }

      if (!checkoutFlowModel.get('hasExistingPaymentMethods')) {
        this.replaceWith(this.getParentRouteName() + '.payment-details', {
          queryParams: transition.queryParams
        });
      }
    },


    actions: {
      onUserOfferDeleted: function onUserOfferDeleted() {
        return true;
      }
    },

    // The setupController hook gets called after the controller's init() method.
    // In order to access the model when setting up the page components, we must call
    // a custom initializer during this hook to access the route's model.
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var checkoutFlowModel = this.checkoutFlowModel();

      if (checkoutFlowModel.get('quickStart')) {
        checkoutFlowModel.set('quickStart', false);
        controller.set('quickStart', true);
      }

      if (!model.get('user.selectedBox')) {
        model.set('user.selectedBox', model.get('isAddFundsRequired') ? 1 : 0);
      }

      controller.initialize();
    }
  });
});