define('ingenio-web/components/listings/price-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        values: function () {
            var minPrice = Number(this.get('minPrice'));
            var maxPrice = Number(this.get('maxPrice'));

            return [minPrice, maxPrice];
        }.property('minPrice', 'maxPrice'),

        sliderTicks: function () {
            var max = Number(this.get('sliderMax'));

            return [1.99, max];
        }.property('sliderMax'),

        sliderTicksLabels: function () {
            var max = Number(this.get('sliderMax'));

            return ['$1.99', '$' + max + '+'];
        }.property('sliderMax'),

        didInsertElement: function didInsertElement() {
            var _this = this;

            var minPrice = Number(this.get('minPrice')).toFixed(2);
            var maxPrice = Number(this.get('maxPrice')).toFixed(2);

            Ember.run.later(function () {
                _this.setTicks(minPrice, maxPrice);
            }, 1000);
        },
        setTicks: function setTicks(minPrice, maxPrice) {
            var max = Number(this.get('sliderMax'));

            $('.slider-tick-label:first').text('$' + minPrice).find('+ .slider-tick-label').text('$' + (maxPrice >= max ? maxPrice + '+' : maxPrice));
        },


        actions: {
            filterChanged: function filterChanged(value) {
                if (value[0] && value[1]) {
                    var minPrice = parseFloat(value[0]).toFixed(2);
                    var maxPrice = parseFloat(value[1]).toFixed(2);

                    this.setTicks(minPrice, maxPrice);

                    this.sendAction('action', minPrice, maxPrice);
                }
            }
        }
    });
});