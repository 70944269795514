define('ingenio-web/components/listings/star-rating', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        feedbackExist: false,
        halfStar: false,
        oneStar: false,
        oneAndHalfStars: false,
        twoStars: false,
        twoAndHalfStars: false,
        threeStars: false,
        threeAndHalfStars: false,
        fourStars: false,
        fourAndHalfStars: false,
        fiveStars: false,

        didInsertElement: function didInsertElement() {
            var stars = this.get('stars');

            stars = Math.floor(stars * 2) / 2;

            if (stars > 0) {
                this.set('feedbackExist', true);

                switch (stars) {
                    case 0.5:
                        this.set('halfStar', true);
                        break;
                    case 1:
                    case 1.0:
                        this.set('oneStar', true);
                        break;
                    case 1.5:
                        this.set('oneAndHalfStars', true);
                        break;
                    case 2:
                    case 2.0:
                        this.set('twoStars', true);
                        break;
                    case 2.5:
                        this.set('twoAndHalfStars', true);
                        break;
                    case 3:
                    case 3.0:
                        this.set('threeStars', true);
                        break;
                    case 3.5:
                        this.set('threeAndHalfStars', true);
                        break;
                    case 4:
                    case 4.0:
                        this.set('fourStars', true);
                        break;
                    case 4.5:
                        this.set('fourAndHalfStars', true);
                        break;
                    case 5:
                    case 5.0:
                        this.set('fiveStars', true);
                        break;
                }
            }
        },


        averageRatingRounded: function () {
            var rating = this.get('averageRating');

            return rating.toFixed(1);
        }.property('averageRating'),

        numberOfReviews: function () {
            var reviews = this.get('ratings');

            return reviews.toLocaleString();
        }.property('ratings'),

        formattedNumberOfReviews: function () {
            var reviews = this.get('ratings');

            if (reviews > 9999) {
                return Math.floor(reviews / 1000) + 'K';
            }
            return reviews.toLocaleString();
        }.property('ratings')
    });
});