define('ingenio-web/mixins/transaction-details/total-charged-advisor/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        sessionRevenue: function () {
            var totalTime = this.get('model.totalTime');
            var offerTime = this.get('model.offerTime');
            var rate = this.get('model.rate');

            return ((totalTime - offerTime) * rate).toFixed(2);
        }.property('model.totalTime', 'model.offerTime', 'model.rate'),

        offerRevenue: function () {
            var sessionRevenue = this.get('sessionRevenue');
            var offerTime = this.get('model.offerTime');
            var advisorEarnings = this.get('model.advisorEarnings');
            var platformFee = this.get('model.platformFee');
            var commission = this.get('model.commission');

            return (offerTime > 0 ? advisorEarnings + platformFee + commission - sessionRevenue : 0).toFixed(2);
        }.property('sessionRevenue', 'model.offerTime', 'model.advisorEarnings', 'model.platformFee', 'model.commission'),

        rate: function () {
            var rate = this.get('model.rate');

            return rate.toFixed(2);
        }.property('model.rate'),

        platformFee: function () {
            var platformFee = this.get('model.platformFee');

            return platformFee.toFixed(2);
        }.property('model.platformFee'),

        commission: function () {
            var commission = this.get('model.commission');

            return commission.toFixed(2);
        }.property('model.commission'),

        advisorEarnings: function () {
            var commission = this.get('model.advisorEarnings');

            return commission.toFixed(2);
        }.property('model.advisorEarnings')
    });
});