define('ingenio-web/utils/key-press-notifier', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var KeyPressNotifier = function () {
        function KeyPressNotifier(options) {
            _classCallCheck(this, KeyPressNotifier);

            if (!options) {
                throw new Error('Options must be provided, required ones are callbacks');
            }

            this.options = {
                timeoutWarningThreshold: options.timeoutWarningThreshold || 3 * 60,
                timeoutPostWarningThreshold: options.timeoutPostWarningThreshold || 3 * 60,
                callbacks: options.callbacks || {
                    timeoutImminent: null,
                    timeout: null,
                    sendChatState: null
                }
            };

            this.lastKeyPress = new Date();
            this.state = 'active';
            this.messageSent = false;
            this._disabled = false;

            this._timeoutTimer = null;
        }

        _createClass(KeyPressNotifier, [{
            key: 'startTimeoutCountdown',
            value: function startTimeoutCountdown() {
                if (this._disabled) {
                    console.info('startTimeoutCountdown() suppressed : Chat Inactivity Timeout Disabled');
                    return;
                }

                if (this._timeoutTimer) {
                    clearTimeout(this._timeoutTimer);
                }

                this._timeoutTimer = setTimeout(this._onTimeoutImminent.bind(this), this.options.timeoutPostWarningThreshold * 1000);
            }
        }, {
            key: 'stopTimeoutCountdown',
            value: function stopTimeoutCountdown() {
                if (this._disabled) {
                    console.info('stopTimeoutCountdown() suppressed : Chat Inactivity Timeout Disabled');
                    return;
                }

                if (this._timeoutTimer) {
                    clearTimeout(this._timeoutTimer);
                }
            }
        }, {
            key: 'ignoreChatInactivity',
            value: function ignoreChatInactivity() {
                console.info('Disabling Chat InActivity Timeout');
                this._disabled = true;
            }
        }, {
            key: 'notifyKeyPress',
            value: function notifyKeyPress(e) {
                if (!this._keyIsChar(e)) {
                    return;
                }

                var now = new Date();

                if (this.state !== 'composing') {
                    this.state = 'composing';
                    var sendChatState = this.options.callbacks.sendChatState;

                    if (sendChatState) {
                        sendChatState(this.state);
                    }

                    // Now set up event for when the user pauses
                    setTimeout(this._checkIsTyping.bind(this), 1000);

                    this.startTimeoutCountdown();
                }
                this.lastKeyPress = now;
                this.messageSent = false;
            }
        }, {
            key: 'notifyMessageSent',
            value: function notifyMessageSent() {
                this.state = '';
                this.messageSent = true;
                this.startTimeoutCountdown();
            }
        }, {
            key: 'notifyMessageReceived',
            value: function notifyMessageReceived() {
                this.startTimeoutCountdown();
            }
        }, {
            key: '_checkIsTyping',
            value: function _checkIsTyping() {

                if (this._disabled) {
                    console.info('checkIsTyping() suppressed : Chat Inactivity Timeout Disabled');
                    return;
                }

                if (this.messageSent) {
                    return;
                }

                var now = new Date();
                var elapsedTime = (now.getTime() - this.lastKeyPress.getTime()) / 1000;

                // If we've waited longer than 4 seconds then the user has stopped typing
                if (elapsedTime > 4) {
                    this.state = 'paused';
                    var sendChatState = this.options.callbacks.sendChatState;

                    if (sendChatState) {
                        sendChatState(this.state);
                    }
                } else {
                    // Otherwise wait again
                    setTimeout(this._checkIsTyping.bind(this), 1000);
                }
            }
        }, {
            key: '_onTimeoutImminent',
            value: function _onTimeoutImminent() {
                if (this._disabled) {
                    console.info('onTimeoutImminent() suppressed : Chat Inactivity Timeout Disabled');
                    return;
                }

                console.info('Issue chat session timeout warning.');
                var timeoutImminentCallback = this.options.callbacks.timeoutImminent;

                if (timeoutImminentCallback) {
                    timeoutImminentCallback(this.options.timeoutPostWarningThreshold);
                }

                this._timeoutTimer = setTimeout(this._onTimeout.bind(this), this.options.timeoutWarningThreshold * 1000);
            }
        }, {
            key: '_onTimeout',
            value: function _onTimeout() {
                if (this._disabled) {
                    console.info('onTimeout() suppressed : Chat Inactivity Timeout Disabled');
                    return;
                }

                console.info('Timing out chat');

                var timeoutCallback = this.options.callbacks.timeout;

                if (timeoutCallback) {
                    timeoutCallback();
                }
            }
        }, {
            key: '_keyIsChar',
            value: function _keyIsChar(evt) {
                if (typeof evt.which === 'undefined') {
                    // This is IE, which only fires keypress events for printable keys
                    return true;
                } else if (typeof evt.which === 'number' && evt.which > 0) {
                    // In other browsers except old versions of WebKit, evt.which is
                    // only greater than zero if the keypress is a printable key.
                    // We need to filter out backspace and ctrl/alt/meta key combinations
                    return !evt.ctrlKey && !evt.metaKey && !evt.altKey && evt.which !== 8;
                }
                return false;
            }
        }]);

        return KeyPressNotifier;
    }();

    exports.default = KeyPressNotifier;
});