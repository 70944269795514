define('ingenio-web/routes/chat/initiate', ['exports', 'ingenio-web/utils/pubnub-client', 'ingenio-web/utils/logger', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types'], function (exports, _pubnubClient, _logger, _headerTypes, _footerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentSession: {},
        timeoutHandle: null,
        errorData: { errorReason: null },
        errorReason: null,
        systemChannelClient: null,
        chatChannelClient: null,

        onPageUnload: function onPageUnload() /*event*/{
            this.sendSessionBeaconMessage();
        },
        sendSessionBeaconMessage: function sendSessionBeaconMessage() {
            var self = this;
            var userId = Ember.Application.user.id;
            var apiRoot = Ember.ENV.getApiRoot();
            var cookie = this.get('cookie');
            var sessionId = cookie.getCookie('chat-sid');
            var currentSessionStatus = self.controller.get('status');

            var logger = this.get('logger');

            if (logger) {
                this.get('logger').log('Initiate route: sendSessionBeaconMessage');
            }

            if (currentSessionStatus === 'requesting' && Ember.isEmpty(this.get('errorReason'))) {
                if (!Ember.isEmpty(sessionId)) {
                    var url = apiRoot + '/users/' + userId + '/chat/sessions/' + sessionId + '?endReason=ConsumerUnloadDuringInitiate';

                    if (navigator.sendBeacon) {
                        if (navigator.sendBeacon(url)) {
                            this.get('logger').log('Beacon Message Queued!');
                        }
                    }
                }
            }
        },
        init: function init() {
            this._super.apply(this, arguments);
            this.set('boundOnUnload', this.get('onPageUnload').bind(this));
        },


        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.COLLAPSIBLE_MINIMAL_NOLINK;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.EMPTY;
        },

        mixpanelClient: Ember.inject.service('mixpanel-client'),
        arrangeChatLogging: Ember.inject.service('arrange-chat-logging'),

        onError: function () {
            Ember.run.next(this, 'setError', this.get('errorReason'));
        }.observes('errorReason'),

        beforeModel: function beforeModel(transition) {
            if (transition.queryParams.arrangeid) {
                var arrangeId = transition.queryParams.arrangeid;

                arrangeId = arrangeId.replace(/[^\d]/g, '');
                this.set('arrangeId', arrangeId);
            }
        },
        model: function model(params, transition) {
            this.set('listingId', transition.queryParams.listingid);
            return this.store.findRecord('listing-v2', transition.queryParams.listingid);
        },


        actions: {
            didTransition: function didTransition() {
                window.addEventListener('unload', this.get('boundOnUnload'), false);
            },
            willTransition: function willTransition(transition) {
                var transitionTargetName = transition.targetName;
                var logger = this.get('logger');

                if (logger) {
                    this.get('logger').log('Initiate route: willTransition ' + transitionTargetName);
                }

                if (this.controller.get('requesting') && transition.targetName !== 'chat.consumerSession' && !this.get('postPayError') && !this.get('autoReloadError')
                // eslint-disable-next-line no-alert
                && !confirm('It can take up to a minute for your advisor to respond. Are you sure you want to leave this page?')) {
                    transition.abort();
                    window.history.forward(); //Go forward in history as clicking back button changes the page url
                    window.addEventListener('unload', this.get('boundOnUnload'), false);
                    return;
                }

                this.clenupRepeatRequest();

                // User wants to leave page.
                if (this.controller.get('requesting') && transition.targetName !== 'chat.consumerSession') {
                    this.sendSessionBeaconMessage();
                }

                $(window).unbind('beforeunload');
                window.removeEventListener('unload', this.get('boundOnUnload'), false);
                var timeoutHandle = this.get('timeoutHandle');

                if (timeoutHandle) {
                    clearTimeout(timeoutHandle);
                }

                var hideTimerTimoutHandle = this.get('hideTimerTimoutHandle');

                if (hideTimerTimoutHandle) {
                    clearTimeout(hideTimerTimoutHandle);
                }

                if (transition.targetName !== 'chat.consumerSession') {
                    //we do not want to unsubscribe from channels before starting a session.
                    //Because this could result in session being ended based on channel-inactive callback.
                    this.cleanup();
                }
            }
        }, // end of actions

        afterModel: function afterModel(model, transition) {
            var _this = this;

            var self = this;

            var cookie = this.get('cookie');
            var cookieGuid = cookie.getCookie('chat-guid-init');

            var sessionId = cookie.getCookie('chat-sid');

            var listingId = this.get('listingId');
            var loggerOptions = {
                consumerId: Ember.Application.user.id,
                source: 'ChatInitiate_' + listingId,
                sessionId: sessionId
            };
            var logger = new _logger.default(this.store, loggerOptions);

            if (transition.queryParams.source) {
                this.set('source', transition.queryParams.source);
            }

            this.set('logger', logger);

            var urlGuid = transition.queryParams.guid;

            if (!urlGuid) {
                this.errorData.errorReason = 'InvalidGuid';
                return;
            }

            //get tracking contextId from cookie, if available. e.g. tid=HRIMMTEX&trackingContextId=3416705

            var trackingContextId = 0;

            var tidTrackingValues = cookie.getCookie('tidTracking');

            if (tidTrackingValues) {
                var trackingContextIdMatch = tidTrackingValues.match(/trackingContextId\=([^\&]*)/);

                if (trackingContextIdMatch && trackingContextIdMatch.length === 2) {
                    trackingContextId = trackingContextIdMatch[1];
                }
            }

            // report arrange chat event
            if (this.get('arrangeId')) {
                this.get('arrangeChatLogging').trackEvent('ChatSessionInitiation', this.get('arrangeId'));
            }

            if (cookieGuid && cookieGuid === urlGuid && sessionId) {
                //When user refreshes page, do not create new session or send another chat request to advisor.
                //Just subscribe to system channel and wait for advisor to respond.
                return this.store.findRecord('chatSession', sessionId).then(function (session) {
                    var status = session.get('status');

                    status = status.toLowerCase();

                    // if status is 'created' or 'requested' continue... Else, fail the session.
                    if (status === 'created' || status === 'requested') {
                        session.set('status', 'Requested'); //TODO: Server needs to set this.
                        self.get('logger').log('Attempting to subscribe to channels during reload. ChatSessionId: ' + sessionId);
                        self.subscribeToChannels(session);
                    } else {
                        // fail the session
                        self.initiateSessionFailed('InvalidSession');
                    }
                }).catch(function (error) {
                    self.initiateSessionFailed(error);
                });
            }

            //create session
            var session = this.store.createRecord('chatSession', {
                listingId: transition.queryParams.listingid
            });

            session.set('listingId', transition.queryParams.listingid);
            this.set('webtrack', transition.queryParams.webtrack);
            session.set('webtrack', transition.queryParams.webtrack);
            this.set('pmode', transition.queryParams.pmode);
            session.set('pmode', transition.queryParams.pmode);
            session.set('uuid', _pubnubClient.default.guid());
            session.set('trackingContextId', trackingContextId);

            this.set('clickid', transition.queryParams.clickid);
            session.set('clickId', transition.queryParams.clickid);

            var isPostPay = transition.queryParams.postPay;
            var autoReload = transition.queryParams.autoReload;

            //queryparam value is a string, not boolean. Assigning as-is can cause "Undefined" to become true.
            if (isPostPay === 'true') {
                session.set('isPostPay', true);
            } else {
                session.set('isPostPay', false);
            }

            if (autoReload && parseFloat(autoReload) > 0) {
                session.set('autoReload', autoReload);
            }

            this.get('cookie').setCookie('chat-guid-init', urlGuid, { path: '/app' }).then(function () {
                return console.info('chat-guid saved');
            });

            var sessionSavePromise = session.save();

            var promises = {
                savedSession: sessionSavePromise
            };

            if (this.get('arrangeId')) {
                var arrangedChat = this.store.findRecord('arrangedChat', this.get('arrangeId'));

                promises.arrangedChat = arrangedChat;
            }
            this.get('logger').log('Initiate route: afterModel');

            return Ember.RSVP.hash(promises).then(function (result) {
                if (result.arrangedChat) {
                    result.arrangedChat.set('status', 'InProgress');
                    result.arrangedChat.set('isSourceWeb', true);
                    result.arrangedChat.set('chatId', result.savedSession.get('id'));
                    return result.arrangedChat.save().then(function () {
                        _this.subscribeToChannels(result.savedSession);
                    });
                } else {
                    _this.subscribeToChannels(result.savedSession);
                }
            }).catch(function (error) {
                return self.initiateSessionFailed(error);
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);

            var session = this.get('currentSession');

            controller.set('session', session);
            controller.set('logger', this.get('logger'));

            controller.set('status', 'requesting');
            controller.set('errorReason', '');
            controller.set('showTimer', true);

            try {
                var countdownAnimationDuration = session.get('countdownAnimationDuration');

                controller.set('initialTimerSeconds', countdownAnimationDuration);
            } catch (er) {
                this.get('logger').log('Error during getting countdownAnimationDuration ' + er);
            }

            if (this.errorData.errorReason) {
                this.set('errorReason', this.errorData.errorReason);
            }
        },
        subscribeToChannels: function subscribeToChannels(session) {
            var _this2 = this;

            var self = this;

            this.set('currentSession', session);
            var status = session.get('status').toLowerCase();

            if (status !== 'created' && status !== 'requested' && status !== 'requestrepeated' || Number(session.get('id')) < 1) {
                Ember.run.next(self, 'setError', 'InvalidSessionStatus'); //Wait until setupController fires
                return;
            }

            var logger = this.get('logger');

            logger.sessionId = session.get('id'); //set sessionId to logger for future log messages
            logger.log('Initiate route: subscribeToChannels');

            this.get('cookie').setCookie('chat-sid', session.get('id'), { path: '/app' }).then(function () {
                return console.info('chat-sid saved');
            });

            var systemChannel = session.get('systemChannel');
            var systemChannelCallbacks = {
                setStateFailure: function setStateFailure(message) {
                    return _this2.get('logger').log('Setting Status failed. ' + message);
                },
                subscribe: {
                    success: this.onSubscribe.bind(this),
                    failure: this.onSubscribeFailure.bind(this)
                },

                messageReceived: this.onMessageReceived.bind(this)
            };
            var systemChannelOptions = {
                channelName: systemChannel.get('channelName'),
                subKey: systemChannel.get('subscribeKey'),
                pubKey: systemChannel.get('publishKey'),
                authKey: systemChannel.get('authKey'),
                uuid: session.get('uuid'),
                callbacks: systemChannelCallbacks,
                logger: this.get('logger')
            };

            this.set('errorReason', null);

            this.systemChannelClient = new _pubnubClient.default(systemChannelOptions);
            this.systemChannelClient.subscribe();

            //We are subscribing to chat-channel with a small time-out (heartbeat) for an edge-case.
            //If customer closes his browser or goes out of network while still requesting, chat-channel will time-out
            //and this will send a notification to our server to close session.
            //Closing session will send advisor chat monitor client a message that'll cancel the request.

            var chatChannel = session.get('chatChannel');
            var chatChannelCallbacks = {
                setStateFailure: function setStateFailure(message) {
                    return _this2.get('logger').log('Setting Status failed. ' + message);
                },
                subscribe: {
                    failure: this.onSubscribeFailure.bind(this)
                }
            };
            var chatUserName = session.get('chatConsumer.name') || session.get('chatConsumer.userId');

            var chatChannelOptions = {
                channelName: chatChannel.get('channelName'),
                subKey: chatChannel.get('subscribeKey'),
                pubKey: chatChannel.get('publishKey'),
                authKey: chatChannel.get('authKey'),
                uuid: session.get('uuid'),
                callbacks: chatChannelCallbacks,
                heartbeatSeconds: 60,
                subscribeState: { clientType: 'consumerRequest', username: chatUserName },
                logger: this.get('logger')
            };

            this.chatChannelClient = new _pubnubClient.default(chatChannelOptions);
            this.chatChannelClient.subscribe();
        },
        initiateSessionFailed: function initiateSessionFailed(reason) {
            var self = this;

            var errorReason = null;

            this.clenupRepeatRequest();

            if (reason === 'InvalidSession') {
                this.set('errorReason', 'InvalidSession');

                if (this.get('arrangeId')) {
                    this.get('arrangeChatLogging').trackEvent('ChatSessionInitiationFailure', this.get('arrangeId'), {
                        code: 'GENERAL_FAILURE',
                        reason: 'InvalidSession'
                    });
                }

                return;
            }

            if (!reason || !reason.errors) {
                Ember.run.next(self, 'setError', 'RequestFailed');

                if (this.get('arrangeId')) {
                    this.get('arrangeChatLogging').trackEvent('ChatSessionInitiationFailure', this.get('arrangeId'), {
                        code: 'GENERAL_FAILURE',
                        reason: 'RequestFailed'
                    });
                }

                return;
            }

            if (Ember.isArray(reason.errors)) {
                errorReason = reason.errors.get('firstObject.detail');
            }

            if (errorReason === 'AdvisorUnavailable') {
                Ember.run.next(self, 'setError', 'AdvisorUnavailable');
            } else if (errorReason === 'AddPostPayFundsFailed') {
                //TODO redirect back to confirmation pageSize
                var listingId = this.get('listingId');
                var webtrack = this.get('webtrack');
                var pmode = this.get('pmode');
                var clickId = this.get('clickId');

                $(window).unbind('beforeunload');
                this.set('postPayError', true);

                if (this.get('source') === 'chat-setup') {
                    this.transitionTo('chat-setup.details', { queryParams: { listingId: listingId, webtrack: webtrack, postpayerror: true, clickId: clickId } });
                } else {
                    this.transitionTo('chat.confirmation', { queryParams: { listingId: listingId, webtrack: webtrack, pmode: pmode, postpayerror: true, clickId: clickId } });
                }
            } else if (errorReason === 'AddAutoReloadFundsFailed') {
                //TODO redirect back to confirmation pageSize
                var _listingId = this.get('listingId');
                var _webtrack = this.get('webtrack');
                var _pmode = this.get('pmode');
                var _clickId = this.get('clickId');
                $(window).unbind('beforeunload');
                this.set('autoReloadError', true);

                if (this.get('source') === 'chat-setup') {
                    this.transitionTo('chat-setup.details', { queryParams: { listingId: _listingId, webtrack: _webtrack, autoreloaderror: true, clickId: _clickId } });
                } else {
                    this.transitionTo('chat.confirmation', { queryParams: { listingId: _listingId, webtrack: _webtrack, pmode: _pmode, autoreloaderror: true, clickId: _clickId } });
                }
            } else if (errorReason === 'InsufficientFunds') {
                Ember.run.next(self, 'setError', 'InsufficientFunds');
            } else {
                Ember.run.next(self, 'setError', 'RequestFailed');
            }

            // report arrange chat event
            if (this.get('arrangeId')) {
                this.get('arrangeChatLogging').trackEvent('ChatSessionInitiationFailure', this.get('arrangeId'), {
                    code: errorReason
                });
            }
        },
        onSubscribe: function onSubscribe() {
            var _this3 = this;

            this.get('logger').log('Initiate route: onSubscribe');

            var session = this.get('currentSession');

            if (session.get('status').toLowerCase() === 'created') {
                session.set('status', 'Requested');

                $(window).bind('beforeunload', function () {
                    return ''; //new browsers don't allow to change confirmation text or do any actions
                });
                //server will notify advisor of chat request.
                session.save().catch(this.initiateSessionFailed.bind(this));

                var requestSent = 0;
                var repeatRequest = function repeatRequest() {
                    var repeatRequestTimeoutHandler = setTimeout(function () {
                        var currentSession = _this3.get('currentSession');
                        var status = currentSession.get('status').toLowerCase();

                        if (requestSent++ < 5 && (status === 'requested' || status === 'requestrepeated')) {
                            currentSession.set('status', 'RequestRepeated');

                            currentSession.save().then(function (data) {
                                if (!data.get('advisorReceivedRequest')) {
                                    repeatRequest();
                                } else {
                                    _this3.get('logger').log('Initiate route: advisorReceivedRequest');
                                }
                            });
                        }
                    }, 10000);

                    _this3.set('repeatRequestTimeoutHandler', repeatRequestTimeoutHandler);
                };

                repeatRequest();
            }

            var countdownAnimationDuration = session.get('countdownAnimationDuration');
            var consumerWaitDuration = session.get('consumerWaitDuration');

            this.set('timeoutHandle', setTimeout(this.endSessionNoAdvisorResponse.bind(this), consumerWaitDuration * 1000));

            this.set('hideTimerTimoutHandle', setTimeout(function () {
                return _this3.controller.send('hideTimer');
            }, (countdownAnimationDuration + 1) * 1000));
        },
        endSessionNoAdvisorResponse: function endSessionNoAdvisorResponse() {
            var _this4 = this;

            //Typically advisor will either accept or reject chat (either manually or when he misses).
            //In a rare scenario where there is no response from advisor client (i.e. current state is still requesting),
            //we should close session.This also will unbusy the advisor.
            var session = this.get('currentSession');

            if (this.controller.get('requesting')) {
                session.set('status', 'RequestTimedOut');
                session.save().then(function () {
                    return _this4.set('errorReason', 'AdvisorRejected');
                }).catch(this.initiateSessionFailed.bind(this));
            }
        },
        onSubscribeFailure: function onSubscribeFailure() {
            var _this5 = this;

            this.get('logger').log('onSubscribeFailure during initial chat');
            var session = this.get('currentSession');

            session.set('status', 'Ended');
            session.save().then(function () {
                return _this5.set('errorReason', 'SubscribeFailure');
            });
        },
        onMessageReceived: function onMessageReceived(m) {
            var _this6 = this;

            var message = m.message;
            var session = this.get('currentSession');

            if (message.Type === 'system' || message.Type === 'state') {
                return;
            }

            var data = message.Data;

            if (!data) {
                this.get('logger').log('No Data to process');
                return;
            }

            var messageId = message.MessageId;

            if (messageId) {
                var latency = message.ReceivedTimestamp - message.ServerTimestamp;

                this.get('logger').log('ChatAnalysis - Command: ' + data.eventType + ', MessageId: ' + messageId + '. Latency: ' + latency + '.');
            }

            var advisorRejected = function advisorRejected() {
                // report arrange chat event
                if (_this6.get('arrangeId')) {
                    _this6.get('arrangeChatLogging').trackEvent('ChatSessionInitiationFailure', _this6.get('arrangeId'), {
                        code: 'AdvisorRejected'
                    });
                }

                _this6.cleanup();
                Ember.run.next(_this6, 'setError', 'AdvisorRejected');
            };

            switch (data.eventType) {
                case 'ChatEndedEvent':
                    this.clenupRepeatRequest();
                    if (session.id && parseInt(session.id) === parseInt(data.sessionId)) {
                        advisorRejected();
                    }
                    break;
                case 'ChatRequestRespondedEvent':

                    this.clenupRepeatRequest();

                    if (session.id && parseInt(session.id) === parseInt(data.sessionId)) {
                        if (data.status === 'Accepted') {

                            // report arrange chat event
                            if (this.get('arrangeId')) {
                                this.get('arrangeChatLogging').trackEvent('ChatSessionCreated', this.get('arrangeId'), {
                                    chatSessionId: session.id
                                });
                            }

                            session.set('availableTimeSeconds', data.availableTime);
                            $(window).unbind('beforeunload');

                            session.initClieanUp = this.cleanup.bind(this);
                            session.redirect_time = Date.now();

                            this.replaceWith('chat.consumerSession', session);
                        } else {
                            advisorRejected();
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        setError: function setError(errorReason) {
            if (!errorReason) {
                //We are clearing errorReason, no need to perform following code
                return;
            }

            $(window).unbind('beforeunload');
            //should not be called before setupController()
            var controller = this.controllerFor(this.fullRouteName);

            if (!Ember.isEmpty(errorReason)) {
                controller.set('status', 'error');
            }
            controller.set('errorReason', errorReason);

            var listingId = this.get('listingId');

            var recommendedAdvisorsDisabled = true; //uncomment before release of RAW

            if (!recommendedAdvisorsDisabled && errorReason !== '') {
                controller.set('recommendedAdvisorsLoading', true);

                this.store.findRecord('recommended-advisor', { listingId: listingId }).then(function (recommendedAdvisors) {
                    var apiUrl = Ember.ENV.getApiRoot();
                    var userInfo = Ember.Application.user;

                    Ember.$.getJSON(apiUrl + '/special-offers?userId=' + userInfo.id).then(function (response) {
                        controller.set('specialOffer', response.specialOffer);
                        controller.set('recommendedAdvisors', recommendedAdvisors);
                    });
                });
            }

            this.cleanup.apply(this);
        },
        cleanup: function cleanup() {
            if (this.systemChannelClient) {
                this.systemChannelClient.unsubscribe();
            }
            if (this.chatChannelClient) {
                this.chatChannelClient.unsubscribe();
            }

            this.clenupRepeatRequest();

            var timeoutHandle = this.timeoutHandle;

            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }

            var hideTimerTimoutHandle = this.get('hideTimerTimoutHandle');

            if (hideTimerTimoutHandle) {
                clearTimeout(hideTimerTimoutHandle);
            }
        },
        clenupRepeatRequest: function clenupRepeatRequest() {
            var repeatRequestTimeoutHandler = this.get('repeatRequestTimeoutHandler');

            if (repeatRequestTimeoutHandler) {
                clearTimeout(repeatRequestTimeoutHandler);
            }
        }
    });
});