define('ingenio-web/components/chat/chat-messages', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        /*Note: didInsertElement is fired only once for collections, so observing changes to collection.
        Scrolling needs to happen after view is re-rendered. So, scheduling the job in 'afterRender' queue.
        */
        networkUp: true, //passed in
        newMessageObserver: function () {
            Ember.run.scheduleOnce('afterRender', this, 'scrollMessage');
        }.observes('messages.[]'),

        deliveryStatusObserver: function () {
            Ember.run.scheduleOnce('afterRender', this, 'scrollMessage');
        }.observes('messages.@each.deliveryStatus'),

        isKeen: Ember.computed('Ember.ENV.domainId', function () {
            return Ember.ENV.domainId === 1;
        }),

        showCustomerAvatarAsText: function () {
            var consumerName = this.get('consumerName');

            if (consumerName.length > 0) {
                return true;
            }
            return false;
        }.property('consumerName'),

        customerNameFirstLetter: function () {
            return this.get('consumerName').charAt(0).toUpperCase();
        }.property('consumerName'),

        scrollMessage: function scrollMessage() {
            this.get('onScrollMessage')();
        },


        actions: {
            openUserInfoModal: function openUserInfoModal() {
                this.get('onAvatarClick')();
            }
        }
    });
});