define('ingenio-web/components/snack-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['snackbar-container'],
        snackBarObserver: Ember.observer('show', function () {
            if (this.get('show')) {
                this.showSnackbar();
            }
        }),
        showSnackbar: function showSnackbar() {
            var element = document.getElementById('snackbar');
            element.classList.add('show');
            setTimeout(function () {
                var element = document.getElementById('snackbar');
                element.classList.remove('show');
                this.attrs.onClose();
            }.bind(this), 3000);
        }
    });
});