define('ingenio-web/components/checkout-flow/change-payment-method-pop-up/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['checkout-flow-change-payment-method-pop-up-container'],
        actions: {
            closeDialog: function closeDialog() {
                this.get('onClose')();
            },
            changePaymentMethod: function changePaymentMethod() {
                this.get('onChangePaymentMethod')();
            }
        }
    });
});