define('ingenio-web/utils/pubnub-user-channel-client', ['exports', 'ingenio-web/utils/pubnub-client'], function (exports, _pubnubClient) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var PubnubUserChannelClient = function () {
        function PubnubUserChannelClient(userId, pubnubOptions, store, logger) {
            var _this = this;

            _classCallCheck(this, PubnubUserChannelClient);

            this.userId = userId;
            this.store = store;
            this.logger = logger;
            this.logData = {
                userId: userId
            };

            pubnubOptions.callbacks = pubnubOptions.callbacks || {
                subscribe: {
                    success: function success() {
                        return _this._subscribeSuccess();
                    },
                    failure: function failure(error) {
                        return _this._subscribeFail(error);
                    }
                },
                messageReceived: function messageReceived(m) {
                    return _this._messageReceived(m.message);
                }
            };

            pubnubOptions.logger = logger;
            this.pubnubClient = new _pubnubClient.default(pubnubOptions, pubnubOptions.pubnubClientInstance);
        }

        _createClass(PubnubUserChannelClient, [{
            key: 'subscribe',
            value: function subscribe() {
                var _this2 = this;

                this.pubnubClient.subscribe();
                this.updateAuthKeyCountDown = this._updateAuthKeyCountDown();
                $(window).bind('beforeunload', function () {
                    if (_this2.updateAuthKeyCountDown) {
                        clearInterval(_this2.updateAuthKeyCountDown);
                    }
                });
            }
        }, {
            key: '_subscribeSuccess',
            value: function _subscribeSuccess() {
                var _this3 = this;

                if (this.onConnect) {
                    Ember.run(function () {
                        _this3.onConnect();
                    });
                }
            }
        }, {
            key: '_subscribeFail',
            value: function _subscribeFail(error) {
                var _this4 = this;

                if (this.onConnectFailed) {
                    Ember.run(function () {
                        _this4.onConnectFailed(error);
                    });
                }
            }
        }, {
            key: '_messageReceived',
            value: function _messageReceived(msg) {
                //console.log(JSON.stringify(msg));
                if (msg.Type === 'ArrangedChatMessage' && this.onArrangedChat) {
                    this.onArrangedChat(msg.Data);
                }

                if (msg.Type === 'ArrangedConversationExpiredMessage' && this.onArrangedConversationExpired) {
                    this.onArrangedConversationExpired(msg.Data);
                }

                if (msg.Type === 'ArrangedChatStartedMessage' && this.onArrangedChatStarted) {
                    this.onArrangedChatStarted(msg.Data);
                }

                if (msg.Type === 'CallVerificationMessage' && this.onCallVerificationMessage) {
                    this.onCallVerificationMessage(msg.Data);
                }

                if (msg.Type === 'CallDetailsMessage' && this.onCallDetailsMessage) {
                    this.onCallDetailsMessage(msg.Data);
                }
            }
        }, {
            key: 'log',
            value: function log(eventName) {
                this.logger.log(eventName, this.logData);
            }
        }, {
            key: 'logError',
            value: function logError(errorName, error) {
                this.logger.error(errorName, this.logData, error);
            }
        }, {
            key: '_updateAuthKeyCountDown',
            value: function _updateAuthKeyCountDown() {
                var _this5 = this;

                var seconds = 1000 * 60 * 60 * 6; //authkey for 6 hours
                //var seconds = 1000 * 30; //for test

                var interval = setInterval(function () {
                    _this5.logger.log('Updating auth key');

                    Ember.run(function () {

                        var pubnubUserChannel = _this5.store.createRecord('pubnubUserChannel');

                        pubnubUserChannel.save().then(function (userChannel) {
                            var newAuthKey = userChannel.get('channel.authKey');

                            _this5.pubnubClient.setNewAuthKey(newAuthKey);
                            _this5.log('Updated auth key');
                        }).catch(function (error) {
                            _this5.logError('Updating auth key.', error);
                            throw error;
                        });
                    });
                }, seconds);

                return interval;
            }
        }], [{
            key: 'guid',
            value: function guid() {
                return _pubnubClient.default.guid();
            }
        }]);

        return PubnubUserChannelClient;
    }();

    exports.default = PubnubUserChannelClient;
});