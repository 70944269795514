define('ingenio-web/routes/myaccount/listings', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _headerTypes, _resetScrollOnTransition) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        defineHeaderType: function defineHeaderType() {
            if (Ember.ENV.domainId === 6) {
                return _headerTypes.default.MINIMAL;
            }
            return _headerTypes.default.FULL;
        },

        model: function model() {
            return Ember.RSVP.hash({
                listings: this.get('store').findAll('advisor-listing'),
                draftListings: this.get('store').findAll('listing-draft')
            });
        },

        actions: {
            didTransition: function didTransition() {
                Ember.run.next(this, 'initTooltip');
            },
            updateListing: function updateListing(listing) {
                listing.save();
            },
            refreshDraftListings: function refreshDraftListings() {
                var draftListings = this.get('store').findAll('listing-draft');
                this.controllerFor('myaccount.listings').set('model.draftListings', draftListings);
            }
        },
        initTooltip: function initTooltip() {
            Ember.$('[data-toggle="tooltip"]').tooltip();
        }
    });
});