define('ingenio-web/models/chat-session', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        /* uuid is PUBNUB's id to identify a particular client that connects to a particular channel
         so for instance, if a client opens chat in two browser windows they would need to have same uuid, to act as the same client
         this is NOT a server-side property*/
        uuid: (0, _attr.default)('string'),

        listingId: (0, _attr.default)('string'),

        //current session status, could be "pending", "ended", "in-progress", etc, comes from Keen DB
        status: (0, _attr.default)('string'),
        availableTimeSeconds: (0, _attr.default)('number'),
        remainingDuration: (0, _attributes.fragment)('remainingDuration'),

        pmode: (0, _attr.default)('number'),
        webtrack: (0, _attr.default)('number'),
        systemChannel: (0, _attributes.fragment)('pubnubChannel'),
        chatChannel: (0, _attributes.fragment)('pubnubChannel'),
        clickId: (0, _attr.default)('string'),

        chatAdvisor: (0, _attributes.fragment)('chatAdvisor'),
        chatConsumer: (0, _attributes.fragment)('chatConsumer'),
        chatResult: (0, _attributes.fragment)('chatResult'),

        log: (0, _attr.default)('string'),

        trackingContextId: (0, _attr.default)('number'),
        isPostPay: (0, _attr.default)('boolean', { defaultValue: false }),
        isReceiptEnabled: (0, _attr.default)('boolean', { defaultValue: false }),
        autoReload: (0, _attr.default)('number'),
        endReason: (0, _attr.default)('number'),
        advisorReceivedRequest: (0, _attr.default)('string'),

        countdownAnimationDuration: (0, _attr.default)('number'),
        consumerWaitDuration: (0, _attr.default)('number'),
        advisorWaitDuration: (0, _attr.default)('number'),

        sessionEnded: function () {
            var status = this.get('status');

            if (status) {
                status = status.toLowerCase();
                return status === 'ended' || status === 'invalid' || status === 'missed' || status === 'declined';
            }
            return false;
        }.property('status'),

        isValidToStartChat: function () {
            var availableTime = this.get('availableTimeSeconds');

            if (!availableTime || availableTime < 1) {
                return false;
            }

            var status = this.get('status');

            if (status) {
                status = status.toLowerCase();
                return status === 'created' || status === 'requested' || status === 'requestrepeated' || status === 'accepted';
            }
            return false;
        }.property('status', 'availableTimeSeconds'),

        isValidToAcceptChat: function () {
            var status = this.get('status');

            if (status) {
                return status.toLowerCase() === 'created';
            }
            return false;
        }.property('status')
    });
});