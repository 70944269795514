define('ingenio-web/controllers/support/deactivate-account', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        user: Ember.Application.user,

        actions: {
            cancel: function cancel() {
                window.location.href = '/help/support';
            }
        }
    });
});