define('ingenio-web/components/additional-fees/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var TranscendTier = 'transcend';
	var ClarityTier = 'clarity';

	exports.default = Ember.Component.extend({
		credit: Ember.computed('credits.[]', function () {
			return this.get('credits') && this.get('credits').get('firstObject');
		}),

		isHideSessionFee: Ember.computed('credit', function () {
			return Ember.isEmpty(this.get('credit'));
		}),

		isThresholdUser: Ember.computed('credit', function () {
			return !Ember.isEmpty(this.get('credit.displayText'));
		}),

		thresholdDescription: Ember.computed('credit', function () {
			return this.get('credit.displayText');
		}),

		isKeenRewardsTranscendTier: Ember.computed('fees.data.fees.charges', function () {
			var charges = this.get('fees.data.fees.charges');
			if (Ember.isEmpty(charges)) {
				return false;
			}

			var discount = charges[0].discount;
			if (discount) {
				var tier = discount.reason;

				if (tier) {
					return tier.toLowerCase() === TranscendTier;
				}
			}

			return false;
		}),

		isKeenRewardsClarityTier: Ember.computed('fees.data.fees.charges', function () {
			var charges = this.get('fees.data.fees.charges');
			if (Ember.isEmpty(charges)) {
				return false;
			}

			var discount = charges[0].discount;
			if (discount) {
				var tier = discount.reason;

				if (tier) {
					return tier.toLowerCase() === ClarityTier;
				}
			}

			return false;
		}),

		isFeePayable: Ember.computed('fees.data.fees.charges', function () {
			var charges = this.get('fees.data.fees.charges');
			if (Ember.isEmpty(charges)) {
				return false;
			}

			var discount = charges[0].discount;

			if (discount) {
				return false;
			}

			return true;
		})
	});
});