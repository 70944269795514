define('ingenio-web/components/redeem-offer/offer-details-info-modal/component', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		showOfferDetailsModal: false,

		expirationDateFormatted: Ember.computed('expirationDate', function () {
			var date = (0, _moment.default)(this.get('expirationDate'));

			if (date.isValid()) {
				return date.format('MM/DD/YYYY');
			}

			return 'N/A';
		}),

		actions: {
			showModal: function showModal() {
				this.set('showOfferDetailsModal', true);
			},

			hideModal: function hideModal() {
				this.set('showOfferDetailsModal', false);
			}
		}
	});
});