define('ingenio-web/controllers/listings/idv', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        idvUrl: function () {
            var myAccountPage = encodeURIComponent(Ember.ENV.urls.myAccountPage);
            var idvUrl = '/secure/credentials/IDAuth.asp?Subroutine=IDVerify&SubroutineParams' + ('=src%3d1&ReturnURL=%2fLogin%2fIDVerificationReturn.aspx&CancelURL=' + myAccountPage);

            return idvUrl;
        }.property()

    });
});