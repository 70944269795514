define('ingenio-web/models/experiment', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _model.default.extend({
      name: (0, _attr.default)('string'),
      buckets: (0, _attr.default)(),
      settings: (0, _attr.default)(),
      enabled: (0, _attr.default)(),
      lastUpdatedAt: (0, _attr.default)(),
      createdAt: (0, _attr.default)()
   });
});