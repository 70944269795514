define('ingenio-web/models/time-limit', ['exports', 'ember-data/attr', 'ingenio-web/models/model-base'], function (exports, _attr, _modelBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        minuteRate: (0, _attr.default)('number', { defaultValue: 0 }),

        currentChargeAccountId: (0, _attr.default)('number', { defaultValue: 0 }),
        //If this is set to true, user's primary account will be used and currentChargeAccountId will be ignored.
        usePrimaryAccount: (0, _attr.default)('boolean', { defaultValue: false }),
        increaseType: (0, _attr.default)('string', { defaultValue: '10' }),
        buyMinutes: (0, _attr.default)('number', { defaultValue: 0 }),
        customAmount: (0, _attr.default)('number', { defaultValue: 0 }),
        maxAmountToAdd: 1000,
        cvvCode: (0, _attr.default)('string', { defaultValue: '' }),
        cvvRequired: (0, _attr.default)('boolean', { defaultValue: false }),

        //Variables to hold partner related settings
        assetRequirementType: (0, _attr.default)('number', { defaultValue: null }),
        //During partner flow, this is used to specify the minimum required amount to be added under call flow entity (in $)
        minimumAmountToBeAdded: (0, _attr.default)('number', { defaultValue: 0 }),
        //In case of add money offer, this is used to specify the required amount to be added to receive the offer
        //rewards (in $). Configured under offer entity
        addMoneyOfferAmountToBeAdded: (0, _attr.default)('number', { defaultValue: 0 }),

        // global validation method will be executed on init
        validate: function () {
            this.validateCurrentChargeAccountId();
            this.validateIncreaseType();
            this.validateBuyMinutes();
            this.validateCustomAmount();
            // this.validateCvvCode();
        }.on('init'),

        /*todo: muthu - We need to merge both minimumAmountToBeAdded and addMoneyOfferAmountToBeAdded into one like offerAmountToBeAdded.
         Reason is that currently in our system the offer is configured in different places 1) as minimum amount required
         under call flow entity 2) as a offer separately under offer entity.
         When an offer is configured we need to show the user as an option like $1.99 and default it. With the current code
         based on these two different variables there is a situation that we might display twice the same value.
         So the best option is to have a new calculated property offerAmountToBeAdded which will get the values from either
         of the existing properties and we will have the display logic around this alone
         * */

        specialOfferExists: function () {
            var specialOfferExists = this.get('specialOffer.id') && this.get('specialOffer.serviceId') === 0;

            return specialOfferExists;
        }.property('specialOffer.id', 'specialOffer.serviceId'),

        hasSpecialOffersObserver: function () {
            var specialOfferExists = this.get('specialOfferExists');

            //Default to the custom amount if there is an offer
            if (specialOfferExists) {
                if (this.get('addMoneyOfferAmountToBeAdded') > 0) {
                    //For add money offer default the min amount to be added
                    this.set('increaseType', '$' + this.get('addMoneyOfferAmountToBeAdded'));
                } else if (this.get('minimumAmountToBeAdded') > 0) {
                    //if the minimum amount to be added is set in call flow entity, set that as the default
                    this.set('increaseType', '$' + this.get('minimumAmountToBeAdded'));
                } else {
                    //For any other offer default to custom
                    this.set('increaseType', '$20');
                }
            } else if (this.get('supportAddMoneyOnlyInAmountFormat')) {
                if (this.get('minimumAmountToBeAdded') > 0) {
                    //if the minimum amount to be added is set in call flow entity, set that as the default
                    this.set('increaseType', '$' + this.get('minimumAmountToBeAdded'));
                } else {
                    //For any other offer default to first in list
                    this.set('increaseType', '$20');
                }
            } else {
                this.set('increaseType', '10'); //Meaning the values are in TENS
            }

            //return specialOfferExists;
        }.observes('specialOfferExists'),

        additionalAmount: function () {
            var result = 0;
            var increaseType = this.get('increaseType');
            var minuteRate = this.get('minuteRate');

            //TODO: instead of hardcoding these values on templates and arriving at values here,
            // we can maintain a collection of values which we can be used templates and here

            //Assumption here is that the values without $ represent minutes. For example 20 denotes mumber of minutes and $20 is the amount
            if (increaseType === '2') {
                result = minuteRate * 2;
            } else if (increaseType === '3') {
                result = minuteRate * 3;
            } else if (increaseType === '10') {
                result = minuteRate * 10;
            } else if (increaseType === '20') {
                result = minuteRate * 20;
            } else if (increaseType === '$20') {
                result = 20;
            } else if (increaseType === '$50') {
                result = 50;
            } else if (increaseType === 'custom') {
                result = this.get('buyMinutesPrice');
            } else if (increaseType === '$custom') {
                result = this.get('customAmount');
            } else if (increaseType === '$' + this.get('minimumAmountToBeAdded')) {
                result = this.get('minimumAmountToBeAdded');
            } else if (increaseType === '$' + this.get('addMoneyOfferAmountToBeAdded')) {
                result = this.get('addMoneyOfferAmountToBeAdded');
            }

            return Math.round(result * 100) / 100;
        }.property('increaseType', 'buyMinutes', 'minuteRate', 'customAmount', 'buyMinutesPrice'),

        buyMinutesPrice: function () {
            return this.get('buyMinutes') * this.get('minuteRate');
        }.property('buyMinutes', 'minuteRate'),

        tenMinutesPrice: function () {
            return 10 * this.get('minuteRate');
        }.property('minuteRate'),

        twentyMinutesPrice: function () {
            return 20 * this.get('minuteRate');
        }.property('minuteRate'),

        maxMinutesCount: function () {
            return Math.round(this.get('maxAmountToAdd') / this.get('minuteRate'));
        }.property('minuteRate'),

        validateCurrentChargeAccountId: function () {
            this.removeErrors('currentChargeAccountId');

            if (this.get('usePrimaryAccount')) {
                return;
            }

            var accountId = this.get('currentChargeAccountId');

            if (!accountId || accountId === 0) {
                this.addError('currentChargeAccountId', 'Current charge account Id is required to perform charge');
            }
        }.observes('currentChargeAccountId', 'usePrimaryAccount'),

        validateIncreaseType: function () {
            this.removeErrors('increaseType');

            var increaseType = this.get('increaseType') || '';

            if (increaseType.length === 0) {
                this.addError('increaseType', 'Type of increase is required to perform charge');
            }
        }.observes('increaseType'),

        validateBuyMinutes: function () {
            this.removeErrors('buyMinutes');

            var buyMinutes = this.get('buyMinutes') || 0;

            var maxMinutesCount = this.get('maxMinutesCount');

            if (this.get('increaseType') === 'custom') {
                var minMinutesToAdd = Math.ceil(10 / this.get('minuteRate'));

                if (this.get('hasAddedFundsInPast') && buyMinutes < minMinutesToAdd) {
                    this.addError('buyMinutes', 'Minutes must equal $10 or more.');
                } else if (buyMinutes <= 0) {
                    this.addError('buyMinutes', 'Sorry, you need to add at least 1 minute.');
                }

                if (buyMinutes > maxMinutesCount) {
                    this.addError('buyMinutes', 'Sorry, you can\'t add more than ' + maxMinutesCount + ' minutes.');
                }

                if (buyMinutes % 1 > 0) {
                    // user put decimal point
                    this.addError('buyMinutes', 'It is not possible to add fraction of the minute. Please specify integer number of minutes');
                }
            }
        }.observes('increaseType', 'buyMinutes'),

        validateCustomAmount: function () {
            this.removeErrors('customAmount');

            var customAmount = this.get('customAmount') || 0;
            var maxAmountToAdd = this.get('maxAmountToAdd');
            var minimumAmountToBeAdded = this.get('minimumAmountToBeAdded') || 1;

            if (this.get('increaseType') === '$custom') {
                if (this.get('assetRequirementType') === 3 && customAmount < minimumAmountToBeAdded) {
                    //User must add the specified amount during the flow
                    this.addError('customAmount', 'Sorry, the minimum deposit required is $' + minimumAmountToBeAdded);
                } else if (customAmount < 1 || customAmount > maxAmountToAdd) {
                    this.addError('customAmount', 'Please select amount in $ you would like to add ($1 - $' + maxAmountToAdd + ')');
                }
            }
        }.observes('increaseType', 'customAmount'),

        // validateCvvCode: function() {
        //     this.removeErrors('cvvCode');

        //     var cvvRequired = this.get('cvvRequired');

        //     if (cvvRequired) {
        //         var cvvCode = this.get('cvvCode') || '';

        //         if (cvvCode.length === 0) {
        //             this.addError('cvvCode', 'Please enter the security code found on the back of your credit card to continue.');
        //         }

        //         if (cvvCode.length !== 0 && (cvvCode.length < 3 || cvvCode.length > 4)) {
        //             this.addError('cvvCode', 'Incorrect number of digits in the security code. It should be 3-4 digits');
        //         }
        //     }
        // }.observes('cvvCode', 'cvvRequired'),

        rewardAmount: function () {
            var rewardAmount = this.get('specialOffer.rewardAmount') || 0;

            return rewardAmount.toFixed(2);
        }.property(),

        isMoneyRewardOffer: function () {
            var rewardType = this.get('specialOffer.rewardType');

            return rewardType === 1;
        }.property()

    });
});