define('ingenio-web/mixins/checkout-flow/payment-details/route', ['exports', 'ingenio-web/utils/logger', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/mixins/checkout-flow/payment-details/offerRemovalHelper', 'ingenio-web/mixins/checkout-flow/chat-availability-helper'], function (exports, _logger, _resetScrollOnTransition, _offerRemovalHelper, _chatAvailabilityHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CHARGE_ACCOUNT_TYPE_PAYPAL = 1;

  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, _offerRemovalHelper.default, _chatAvailabilityHelper.default, {
    userEvents: Ember.inject.service(),
    iovationClient: Ember.inject.service('iovation'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    checkoutFlowModel: function checkoutFlowModel() {
      return this.modelFor(this.getParentRouteName()); //Get model for routes like 'call-setup' or 'chat-setup'
    },


    flowModel: Ember.computed(function () {
      return this.modelFor(this.getParentRouteName());
    }),

    beforeModel: function beforeModel(transition) {
      if (Ember.get(transition, 'queryParams.token')) {
        this.setAdditionalAmountIfInUrl(transition);

        if (Ember.get(transition, 'queryParams.processPaypalToken')) {
          return this.processPaypalToken(transition);
        }

        this.replaceWith({
          queryParams: {
            pmode: Ember.get(transition, 'queryParams.pmode'),
            webtrack: Ember.get(transition, 'queryParams.webtrack'),
            listingid: Ember.get(transition, 'queryParams.listingid'),
            clickid: Ember.get(transition, 'queryParams.clickid')
          }
        });
      } else if (!this.skipDetailsRedirect() && !this.get('flowModel.accountBalanceWithExtraFundsMeetsMinimum')) {
        this.replaceWith(this.getParentRouteName() + '.details', {
          queryParams: transition.queryParams
        });
      }
    },
    skipDetailsRedirect: function skipDetailsRedirect() {
      return false; //all flows where we do not need to redirect to details page for selecting amount to add we should return true (add-funds, arrange-conversation, redeem-offer)
    },


    actions: {
      onUserOfferDeleted: function onUserOfferDeleted() {
        return true;
      }
    },

    // The setupController hook gets called after the controller's init() method.
    // In order to access the model when setting up the page components, we must call
    // a custom initializer during this hook to access the route's model.
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.initialize();
    },


    /*  
             Process PAYPAL token
             1) If there is any token provided by the paypal expresscheckout, create a new payment method on our end
             2) If #1 is SUCCESS, redirect to confirmation page
             3) In case of FAILURE, display error on the page and keep the paypal tab selected
         */
    processPaypalToken: function processPaypalToken(transition) {
      var _this = this;

      var logger = new _logger.default(this.store, {
        source: 'payment-details',
        consumerId: Ember.Application.user.id
      });

      if (Ember.get(transition, 'queryParams.token')) {
        var newPaypalAccount = this.store.createRecord('paymentMethod', {
          isPrimary: true,
          eventSource: 'WebSite',
          chargeAccountType: CHARGE_ACCOUNT_TYPE_PAYPAL,
          deviceId: this.get('iovationClient').getDeviceId(),
          paypalExpressCheckoutToken: transition.queryParams.token
        });

        return newPaypalAccount.save().then(function (paypalPaymentMethod) {
          _this.get('userEvents').paymentMethodAdded('paypal');

          var advisorId = _this.get('flowModel.listing.advisorId');
          var listingId = _this.get('flowModel.listing.id');

          if (_this.getParentRouteName() === 'chat-setup') {
            _this.checkChatAvailability(advisorId).then(function (result) {
              _this.get('mixpanelClient').trackNewEvent('Chat Collision During Payment (Web-Only)', {
                eventData: {
                  advisor_id: advisorId,
                  chat_status: result.chatAvailability,
                  listing_id: listingId
                }
              });
            });
          }

          return _this.checkOfferEligibility(logger, _this.get('mixpanelClient')).then(function (returnToStep) {
            return _this.refreshPaymentMethodsInStore().then(function () {
              _this.set('flowModel.user.funds.paymentMethodId', paypalPaymentMethod.get('id'));

              if (returnToStep === 'return-to-details-step') {
                //Offer removed
                //TODO: The instead of returning the step, its better to return the offer removal status
                transition.send('onUserOfferDeleted');
                _this.transitionTo(_this.getParentRouteName() + '.details', {
                  queryParams: {
                    pmode: Ember.get(transition, 'queryParams.pmode'),
                    webtrack: Ember.get(transition, 'queryParams.webtrack'),
                    listingid: Ember.get(transition, 'queryParams.listingid'),
                    clickid: Ember.get(transition, 'queryParams.clickid')
                  }
                });
              } else {
                var redirectRouteName = _this.get('flowModel.flowType') === 'add-funds' ? 'details' : 'confirmation';

                // Offer is not removed so proceed to next step
                _this.replaceWith(_this.getParentRouteName() + '.' + redirectRouteName, {
                  queryParams: {
                    pmode: Ember.get(transition, 'queryParams.pmode'),
                    webtrack: Ember.get(transition, 'queryParams.webtrack'),
                    listingid: Ember.get(transition, 'queryParams.listingid'),
                    clickid: Ember.get(transition, 'queryParams.clickid')
                  }
                });
              }
            });
          });
        }).catch(function (xhr) {
          /*
          TODO: 
          1) Handle error and display it in a page
          2) Since the failure is on our end should we remove the token and ask the user to go through the entire paypal add thing or should we just reuse the token and retry adding?
          */
          //this.handleError(xhr);
          newPaypalAccount.deleteRecord();
          if (xhr && xhr.errors && xhr.errors['generic-error'] && xhr.errors['generic-error'].length > 0) {
            alert(xhr.errors['generic-error'][0]); //TODO: Muthu display it in ember flash way
          } else if (xhr && xhr['generic-error'] && xhr['generic-error'].length > 0) {
            alert(xhr['generic-error'][0]);
          }

          logger.error('Problem in saving user\'s paypal account. userId=' + Ember.Application.user.id + ', paypalToken=' + transition.queryParams.token, {
            originalError: xhr
          });

          //Remove the query params related to paypal
          _this.replaceWith({
            queryParams: {
              pmode: Ember.get(transition, 'queryParams.pmode'),
              arrange: Ember.get(transition, 'queryParams.arrange'),
              webtrack: Ember.get(transition, 'queryParams.webtrack'),
              listingid: Ember.get(transition, 'queryParams.listingid'),
              clickid: Ember.get(transition, 'queryParams.clickid')
            }
          });
        });
      }
    },
    checkOfferEligibility: function checkOfferEligibility(logger, mixpanelClient) {
      var _this2 = this;

      /*
            At this point the offer qualification is checked for non-qualification and is deleted
            If the user has sufficient balance proceed to the confirmation step
            Else redirect the user to the add funds step
          */
      return this.removeOfferIfNotQualified(this.get('flowModel.user.offer.id'), logger).then(function (offerStatus) {
        return _this2.onNonQualifiedOfferRemoval(offerStatus, _this2.get('flowModel'), logger, mixpanelClient);
      }).catch(function (error) {
        logger.error('Problem in removeOfferIfNotQualified(). userId=' + Ember.Application.user.id, {
          originalError: error
        });
      });
    },
    setAdditionalAmountIfInUrl: function setAdditionalAmountIfInUrl(transition) {
      var additionalAmount = Ember.get(transition, 'queryParams.addamt');

      if (additionalAmount) {
        this.set('flowModel.user.funds.additionalAmount', additionalAmount);
      }
    },
    refreshPaymentMethodsInStore: function refreshPaymentMethodsInStore() {
      var _this3 = this;

      return this.store.findAll('paymentMethod', {
        reload: true
      }).then(function (paymentMethods) {
        _this3.set('flowModel.user.paymentMethods', paymentMethods);

        return paymentMethods;
      });
    }
  });
});