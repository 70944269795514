define('ingenio-web/components/toggle-switch-buttons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        items: null,
        optionValuePath: null,
        optionLabelPath: null,

        options: function () {
            var optionValuePath = this.get('optionValuePath');
            var optionLabelPath = this.get('optionLabelPath');
            var items = this.get('items');

            return items.map(function (item) {
                var option = {};

                option.optionValue = item[optionValuePath];
                option.optionLabel = item[optionLabelPath];
                return option;
            });
        }.property('items'),

        selectedItemId: null,

        actions: {
            itemSelected: function itemSelected(itemId) {
                this.sendAction('itemSelected', itemId);
            }
        }
    });
});