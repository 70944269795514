define('ingenio-web/models/chat-monitor', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        name: (0, _attr.default)('string'),
        availability: (0, _attr.default)('string'),
        channelNeedRefresh: (0, _attr.default)('boolean'),
        systemChannel: (0, _attributes.fragment)('pubnubChannel'),
        chatEnabled: (0, _attr.default)('boolean'),
        chatAvailabilityUpdateSource: (0, _attr.default)('string', { defaultValue: 'Web' })
    });
});