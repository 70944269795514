define('ingenio-web/adapters/pubnub-monitor-channel', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot) {
            var userId = Ember.Application.user.id;
            var channelType = snapshot.adapterOptions ? snapshot.adapterOptions['channelType'] : 'MonitorV2';

            return Ember.ENV.getApiRoot() + ('/users/' + userId + '/pubnubchannels?channelType=' + channelType);
        }
    });
});