define('ingenio-web/components/arranged-conversations/common/no-cod-message/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        showDialog: false,
        chargeAmount: null,
        classNames: 'no-cod-message-component',

        actions: {
            showDialog: function showDialog() {
                this.set('showDialog', true);
            },
            closeDialog: function closeDialog() {
                this.set('showDialog', false);
            }
        }
    });
});