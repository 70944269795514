define('ingenio-web/components/checkout-flow/step-indicator/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        activeStep: Ember.computed('steps', function () {
            return this.get('steps').find(function (step) {
                return step.get('active');
            });
        })
    });
});