define("ingenio-web/birth-chart/payment-details/controller", ["exports", "ingenio-web/mixins/scroll-helpers", "ingenio-web/birth-chart/step-helpers", "ingenio-web/mixins/checkout-flow/payment-details/controller"], function (exports, _scrollHelpers, _stepHelpers, _controller) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
    birthChart: Ember.inject.controller(),
    hideSteps: Ember.computed.equal('steptype', '1'),

    getNextRouteName: function getNextRouteName() {
      var step = ".confirmation";

      return this.getParentRouteName() + step;
    }
  });
});