define('ingenio-web/services/mixpanel-client', ['exports', 'ingenio-web/constants/domain'], function (exports, _domain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      // skip initialization if MP is not found in the window.
      // this could be due to an ad blocker on the user's browser.
      if (!window.mixpanel) {
        return;
      }

      //mutates global mixpanel object, therefore assignment to a private variable
      //happens after we call all mutating methods

      //Only for Keen register the new mixpanel project
      if (Ember.ENV.domainId == _domain.default.KEEN) {
        mixpanel.init(Ember.ENV.newMixPanelToken, {
          loaded: function loaded(mixpanel) {
            var userIsKnown = Ember.Application.user && Ember.Application.user.id > 0;
            var mpDistinctIdContainsUserId = !isNaN(mixpanel.get_distinct_id());
            mixpanel.register_once({
              platform: 'web'
            });
            if (userIsKnown) {
              //When we already know the userid make sure we call mixpanel.identify so that the distinctid will be now set to the userid
              mixpanel.identify(Ember.Application.user.id);
            } else if (!userIsKnown && mpDistinctIdContainsUserId) {
              mixpanel.reset();
              mixpanel.register_once({
                platform: 'web'
              });
            }
            mixpanel.register({
              logged_in: userIsKnown
            });
          },
          cross_subdomain_cookie: false
        }, "newProject");
      }
    },
    instance: function instance() {
      if (window.mixpanel && mixpanel.newProject) return mixpanel.newProject;
    },
    trackEvent: function trackEvent(eventName, eventData, callback) {
      //TODO: remove after confirmation all usages were removed
      console.warn('trackEvent is obsolete, please use trackNewEvent instead. eventName ' + eventName);
      if (window.mixpanel) {
        mixpanel.track(eventName + '_ember', eventData, callback);
      } else {
        console.log('Mixpanel tracking might be disabled');
        return;
      }
    },


    //Function to track event in new Mixpanel instance. Currently restricted to only keen
    trackNewEvent: function trackNewEvent(eventName) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$eventData = _ref.eventData,
          eventData = _ref$eventData === undefined ? {} : _ref$eventData,
          callback = _ref.callback;

      if (Ember.ENV.domainId == _domain.default.KEEN) {
        //Only for keen handle the new MP instance
        if (window.mixpanel && mixpanel.newProject) {
          mixpanel.newProject.track(eventName, eventData, callback);
        } else {
          console.log('Mixpanel tracking might be disabled');
          return;
        }
      }
    },
    trackLinks: function trackLinks(linkId, eventName, eventData, callback) {
      //TODO: remove after confirmation all usages were removed
      console.warn('trackLinks is obsolete, please use trackLinksNew instead. eventName ' + eventName);
      if (window.mixpanel) {
        mixpanel.track_links(linkId, eventName, eventData, callback);
      } else {
        console.log('Mixpanel tracking might be disabled');
        return;
      }
    },


    //Function to track links in new Mixpanel instance. Currently restricted to only keen
    trackLinksNew: function trackLinksNew(linkId, eventName, eventData, callback) {
      if (Ember.ENV.domainId == _domain.default.KEEN) {
        if (window.mixpanel && mixpanel.newProject) {
          mixpanel.newProject.track_links(linkId, eventName, eventData, callback);
        } else {
          console.log('Mixpanel tracking might be disabled');
          return;
        }
      }
    }
  });
});