define('ingenio-web/mixins/reset-scroll-on-transition', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        activate: function activate() {
            this._super();
            window.scrollTo(0, 0);
        }
    });
});