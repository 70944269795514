define('ingenio-web/components/best-match/question-base', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        questionSwitcher: Ember.inject.service('question-switcher'),

        selectedAnswer: function () {
            var answers = this.get('answers');
            var questionId = this.get('questionId');

            return answers[questionId.toString()];
        }.property('answers'),

        isAnswerSelected: function () {
            var selectedAnswer = this.get('selectedAnswer');

            return selectedAnswer >= 0;
        }.property('selectedAnswer'),

        shouldShowCloverSkipButton: Ember.computed('answers', function () {
            var isCloverFlow = _cookies.default.isCloverFlow();

            return isCloverFlow && this.get('answers')[0] === 0;
        }),

        actions: {
            selectAnswer: function selectAnswer(answer) {
                var questionId = this.get('questionId');

                this.get('onAnswer')([{
                    questionId: questionId,
                    answer: answer
                }]);
            },
            goForward: function goForward() {
                this.get('questionSwitcher').goForward();
            }
        }
    });
});