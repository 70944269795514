define('ingenio-web/models/phone-number', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr'], function (exports, _fragment, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        phoneId: (0, _attr.default)('number', { defaultValue: 0 }),
        userId: (0, _attr.default)('number', { defaultValue: 0 }),
        isUSNumber: (0, _attr.default)('boolean', { defaultValue: true }),
        phoneType: (0, _attr.default)('number', { defaultValue: 0 }), //Default 0=Work
        countryCode: (0, _attr.default)('string', { defaultValue: '1' }),
        number: (0, _attr.default)('string'),
        extension: (0, _attr.default)('string'),
        isPrimary: (0, _attr.default)('boolean', { defaultValue: true })
    });
});