define('ingenio-web/routes/direct-messages/conversation', ['exports', 'ingenio-web/routes/direct-messages/base-router', 'ingenio-web/objects/chat/message', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/utils/date-utils'], function (exports, _baseRouter, _message, _headerTypes, _footerTypes, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRouter.default.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.MINIMAL;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.EMPTY;
        },

        beforeModel: function beforeModel(transition) {
            var source = transition.queryParams.source;

            if (source) {
                this.set('source', source);
            }
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);

            var source = this.get('source');

            if (source) {
                controller.set('source', source);
            }
        },
        model: function model(params) {
            var _this = this;

            var userId = Ember.Application.user.id;

            var userNames = {};
            var userAvatarUrls = {};

            var convertDirectMessagesToMessages = function convertDirectMessagesToMessages(directMessages, currentUserId) {
                if (!directMessages || directMessages.get('length') === 0) {
                    return null;
                }

                var firstMessage = directMessages.objectAt(0);

                var firstFromUserId = firstMessage.get('fromUserId');
                var firstToUserId = firstMessage.get('toUserId');

                userNames[firstFromUserId] = firstMessage.get('fromUserName');
                userAvatarUrls[firstFromUserId] = firstMessage.get('fromUserImageUrl');

                userNames[firstToUserId] = firstMessage.get('toUserName');
                userAvatarUrls[firstToUserId] = firstMessage.get('toUserImageUrl');

                return directMessages.map(function (m) {
                    var fromUserId = m.get('fromUserId');
                    var toUserId = m.get('toUserId');
                    var messageIsMine = fromUserId.toString() === currentUserId.toString();

                    if (!m.get('fromUserName')) {
                        m.set('fromUserName', userNames[fromUserId]);
                        m.set('toUserName', userNames[toUserId]);
                        m.set('fromUserImageUrl', userAvatarUrls[fromUserId]);
                        m.set('toUserImageUrl', userAvatarUrls[toUserId]);
                    }

                    return _message.default.create({
                        id: m.get('id'),
                        avatarUrl: m.get('fromUserImageUrl'),
                        text: m.get('message'),
                        date: m.get('date'),
                        isMine: messageIsMine,
                        delivered: messageIsMine && m.get('isRead')
                    });
                });
            };

            var handleNewMessages = function handleNewMessages(messages) {
                messages.filter(function (m) {
                    return m.get('fromUserId') !== userId && !m.get('isRead');
                }).map(function (m) {
                    m.set('isRead', true);
                    m.save();
                });
                return messages;
            };

            var addDateMessages = function addDateMessages(messages) {
                if (!messages) {
                    return;
                }
                var prevDate = null;
                var newArray = Ember.A();

                for (var i = 0; i < messages.get('length'); i++) {
                    if (!prevDate || (0, _dateUtils.sameDay)(prevDate, messages[i].date) === false) {
                        prevDate = messages[i].date;

                        var dateMessage = _message.default.create({
                            dateMessage: true,
                            date: prevDate
                        });

                        newArray.addObject(dateMessage);
                    }
                    newArray.addObject(messages[i]);
                }
                return newArray;
            };

            return Ember.RSVP.hash({
                conversation: this.store.query('directMessage', { recipientId: params.user_id }).then(function (messages) {
                    return handleNewMessages(messages);
                }).then(function (messages) {
                    return convertDirectMessagesToMessages(messages, userId);
                }).then(function (messages) {
                    return addDateMessages(messages);
                }),
                user: this.modelFor('application').get('userData'),
                recipient: this.store.findRecord('user', params.user_id),
                userPhones: this.store.findAll('userPhone'),
                directMessagesEligibility: this.store.queryRecord('directMessagesEligibility', { recipientId: params.user_id }).then(function (eligibility) {
                    return eligibility.get('name') === 'Eligible';
                })
            }).then(function (m) {
                if (m.recipient.get('primaryListingId')) {
                    var listingV2Query = {
                        id: m.recipient.get('primaryListingId'),
                        platformId: 'web',
                        pageId: 2001
                    };

                    return Ember.RSVP.hash(Object.assign({}, { listingDetails: _this.store.queryRecord('listing-v2', listingV2Query) }, m));
                }

                return m;
            });
        }
    });
});