define('ingenio-web/utils/chat-session-timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var chatSessionTimer = function () {
        function chatSessionTimer(options) {
            _classCallCheck(this, chatSessionTimer);

            if (!options) {
                throw new Error('Options must be provided, required ones are callbacks');
            }

            this.options = {
                warnTimeoutOffsetSeconds: options.warnTimeoutOffsetSeconds || 5,
                callbacks: options.callbacks || {
                    sessionTimeoutWarning: null,
                    sessionTimeout: null
                }
            };
            this.timer = null;
        }

        _createClass(chatSessionTimer, [{
            key: 'start',
            value: function start(availableTimeInSeconds) {
                var self = this;
                var warnTimeout = (availableTimeInSeconds - self.options.warnTimeoutOffsetSeconds) * 1000;

                self.timer = Ember.run.later(self, self._onWarnTimeout, warnTimeout);
            }
        }, {
            key: 'cancel',
            value: function cancel() {
                var self = this;

                if (self.timer) {
                    Ember.run.cancel(self.timer);
                }
            }
        }, {
            key: '_onWarnTimeout',
            value: function _onWarnTimeout() {
                var self = this;

                if (self.options.callbacks.sessionTimeoutWarning) {
                    self.options.callbacks.sessionTimeoutWarning();
                }

                self.timer = Ember.run.later(self, function () {
                    if (self.options.callbacks.sessionTimeout) {
                        self.options.callbacks.sessionTimeout();
                    }
                }, self.options.warnTimeoutOffsetSeconds * 1000);
            }
        }]);

        return chatSessionTimer;
    }();

    exports.default = chatSessionTimer;
});