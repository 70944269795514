define('ingenio-web/adapters/experiment', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'experiments';
        },

        query: function query(store, type, _query) {
            var _this = this,
                _arguments = arguments;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                _this._super.apply(_this, _arguments).then(function (data) {
                    return resolve({
                        experiments: data
                    });
                }).catch(function (error) {
                    return reject(error);
                });
            });
        }
    });
});