define('ingenio-web/components/featured-listings/bid/historical-bid-per-position', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentPage: 1,
        resultsPerPage: 5,
        emptyBidAmountText: 'NA',
        historicalBidPerPosition: [],

        timeRanges: Ember.computed(function () {
            var timeRanges = this.get('historicalBidPerPosition.timeRanges');

            return timeRanges ? timeRanges.toArray().sortBy('id') : [];
        }).property('historicalBidPerPosition'),

        positionRanges: Ember.computed(function () {
            var positionRanges = this.get('historicalBidPerPosition.positionRanges');

            return positionRanges ? positionRanges.toArray().sortBy('id') : [];
        }).property('historicalBidPerPosition'),

        pages: Ember.computed(function () {
            var _this = this;

            var pageCount = Math.ceil(this.get('positionRanges.length') / this.get('resultsPerPage'));

            return Array.from(Array(pageCount > 0 ? pageCount : 1), function (_, index) {
                var number = index + 1;

                return {
                    number: number,
                    selected: number === _this.get('currentPage')
                };
            });
        }).property('currentPage', 'positionRanges.@each'),

        lastPage: Ember.computed(function () {
            var pages = this.get('pages');

            if (pages.length < 1) {
                return 1;
            }

            return pages.reduce(function (previousPage, currentPage) {
                return previousPage.number > currentPage.number ? previousPage : currentPage;
            });
        }).property('pages.@each'),

        historicalBidPerPositionNormalized: Ember.computed(function () {
            var _this2 = this;

            var bidResults = [];
            var timeRanges = this.get('timeRanges');
            var positionRanges = this.get('positionRanges');

            var positionStartIndex = (this.get('currentPage') - 1) * this.get('resultsPerPage');
            var positionEndIndex = positionStartIndex + this.get('resultsPerPage');

            positionRanges.slice(positionStartIndex, positionEndIndex).forEach(function (positionRange) {
                var positionRangeResult = {
                    id: positionRange.id,
                    text: positionRange.positionRange,
                    timeRanges: []
                };

                timeRanges.forEach(function (timeRange) {
                    positionRangeResult.timeRanges.push({
                        bidAmount: _this2._getBidAmountInCentsForTimePeriodAndPosition(positionRange.id, timeRange.id)
                    });
                });

                bidResults.push(positionRangeResult);
            });

            return bidResults;
        }).property('historicalBidPerPosition.bidData.@each', 'pages.@each', 'currentPage', 'resultsPerPage', 'timeRanges', 'positionRanges'),

        historicalBidPerPositionResults: Ember.computed(function () {
            var _this3 = this;

            var historicalBids = this.get('historicalBidPerPositionNormalized').reverse();

            var maxPositionRange = null;

            historicalBids.some(function (positionRange) {
                var doesRangeContainAtLeastOneBidAmount = positionRange.timeRanges.some(function (element) {
                    return !Ember.isEqual(element.bidAmount, _this3.get('emptyBidAmountText'));
                });

                return doesRangeContainAtLeastOneBidAmount ? (maxPositionRange = positionRange, true) : false;
            });

            return maxPositionRange ? this.get('historicalBidPerPositionNormalized').filter(function (range) {
                return range.id <= maxPositionRange.id;
            }).sortBy('id') : this.get('historicalBidPerPositionNormalized').sortBy('id');
        }).property('historicalBidPerPositionNormalized'),

        _getBidAmountInCentsForTimePeriodAndPosition: function _getBidAmountInCentsForTimePeriodAndPosition(positionRange, timePeriod) {
            var historicalBidPerPositionData = this.get('historicalBidPerPosition.bidData');
            var bidData = historicalBidPerPositionData.filter(function (data) {
                return Ember.isEqual(data.positionRange, positionRange) && Ember.isEqual(data.timePeriod, timePeriod);
            }).get('firstObject');

            return bidData && bidData.bidAmountInCents !== 'NA' ? '$' + (bidData.bidAmountInCents / 100).toFixed(2) : this.get('emptyBidAmountText');
        },


        actions: {
            previousPage: function previousPage() {
                var pageNumber = this.get('currentPage') - 1;

                this.set('currentPage', pageNumber >= 1 ? pageNumber : 1);
            },
            setPage: function setPage(pageNumber) {
                this.set('currentPage', pageNumber);
            },
            nextPage: function nextPage() {
                var pageNumber = this.get('currentPage') + 1;

                this.set('currentPage', pageNumber);
            }
        }
    });
});