define('ingenio-web/routes/featured-listings/bid/select-listing', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        featuredListingsBid: Ember.computed(function () {
            return this.modelFor('featured-listings.bid');
        }),

        beforeModel: function beforeModel() {
            var featuredListingsBid = this.get('featuredListingsBid');

            // Unload Bid Data From Store & Reset Parent Route Model
            // (if a user updates the model with invalid data in step 2, the cache should be dumped to prevent an invalid state.)
            this.store.unloadAll('bid');
            featuredListingsBid.set('bid', null);
        },
        model: function model() {
            return Ember.RSVP.hash({
                listing: this.get('featuredListingsBid.listing'),
                bidResults: this.store.query('listingBidResult', {
                    timePeriod: 'current-year'
                })
            });
        },


        actions: {
            didTransition: function didTransition() {
                this.send('setCurrentStep', this.routeName);

                return true;
            },
            previousStep: function previousStep() {
                window.location.replace('/myaccount/advisor');

                return false;
            },
            listingChanged: function listingChanged(listingId, title, category) {
                this.controller.set('model.listing.id', listingId);
                this.controller.set('model.listing.title', title);
                this.controller.set('model.listing.category', category);
            }
        }
    });
});