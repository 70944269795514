define('ingenio-web/components/transaction-details/total-charged-consumer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        sessionCost: function () {
            var sessionCost = this.get('model.sessionCost');

            return sessionCost.toFixed(2);
        }.property('model.sessionCost'),

        rate: function () {
            var rate = this.get('model.rate');

            return rate.toFixed(2);
        }.property('model.rate')
    });
});