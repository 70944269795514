define('ingenio-web/components/best-match/step-indicator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        questionSwitcher: Ember.inject.service('question-switcher'),

        stepItems: function () {
            var steps = this.get('steps');
            var step = this.get('step');
            var stepItems = Ember.A();

            for (var index = 0; index < steps; index++) {
                stepItems.push({ selected: index < step });
            }
            return stepItems;
        }.property(),

        showBackLink: function () {
            return this.get('step') > 1;
        }.property('step'),

        showForwardLink: function () {
            return this.get('isAnswerSelected');
        }.property('isAnswerSelected'),

        actions: {
            goBack: function goBack() {
                this.get('questionSwitcher').goBack();
            },
            goForward: function goForward() {
                this.get('questionSwitcher').goForward();
            }
        }
    });
});