define('ingenio-web/components/chat/info-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        profilePictureUrl: null,
        userName: '',
        roundedRating: 0,
        averageRating: 0,
        numberOfReviews: 0,
        showRightSide: false,
        showCustomerInfoModal: false,

        showCustomerAvatarAsText: function () {
            var consumerName = this.get('consumerName');

            return consumerName && consumerName.length > 0;
        }.property('consumerName'),

        customerNameFirstLetter: function () {
            var consumerName = this.get('consumerName');

            if (consumerName && consumerName.length > 0) {
                return this.get('consumerName').charAt(0).toUpperCase();
            }
            return null;
        }.property('consumerName'),

        actions: {
            openUserInfoModal: function openUserInfoModal() {
                this.get('onClick')();
            }
        }
    });
});