define('ingenio-web/call-setup/details/controller', ['exports', 'ingenio-web/mixins/checkout-flow/details/controller'], function (exports, _controller) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _controller.default.extend({
		callSetup: Ember.inject.controller(),
		steps: Ember.computed.alias('callSetup.steps'),

		showSupplementaryRewardsPoints: Ember.computed('model.conversationPaymentMethodSelected', 'showConversationPaymentMethodConfirmBlock', 'model.payAfterConversationSelected', function () {
			return this.get('model.conversationPaymentMethodSelected') || !this.get('showConversationPaymentMethodConfirmBlock') && !this.get('model.payAfterConversationSelected');
		})
	});
});