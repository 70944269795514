define('ingenio-web/models/log-entry', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        message: (0, _attr.default)('string'),
        source: (0, _attr.default)('string'),
        type: (0, _attr.default)('string'),
        consumerId: (0, _attr.default)('number'),
        advisorId: (0, _attr.default)('number'),
        sessionId: (0, _attr.default)('number'),
        callId: (0, _attr.default)('number'),
        time: (0, _attr.default)('date')
    });
});