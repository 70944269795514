define('ingenio-web/routes/public-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        init: function init() {
            //When we don't need authentication we can derive from this route.
            //first page load will not redirect to login page.
            Ember.InitialRouteSettings.skipAuth = true;
        }
    });
});