define('ingenio-web/components/checkout-flow/currency-note/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isKeen: Ember.computed(function () {
			return Ember.ENV.domainId === 1;
		}),

		isInternationalCurrency: Ember.computed(function () {
			return Ember.Application.user.preferredCurrency != '$';
		})
	});
});