define('ingenio-web/app', ['exports', 'ingenio-web/resolver', 'ember-load-initializers', 'ingenio-web/config/environment', '@sentry/browser', 'ingenio-web/utils/cookies'], function (exports, _resolver, _emberLoadInitializers, _environment, _browser, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appVersion = _environment.default.APP.version;

    // eslint-disable-next-line no-console
    console.log('Ember App Version: ', appVersion);

    var sentryConfig = Ember.ENV['@sentry/ember'];

    if (sentryConfig.enabled) {

        _browser.init({
            release: 'web-ember@' + appVersion,
            environment: Ember.ENV.name,
            dsn: sentryConfig.dsn,
            ignoreErrors: sentryConfig.ignoreErrors,
            beforeSend: function beforeSend(event) {
                // Modify the event here before sending it to sentry
                // if (event.user) {
                //     // Don't send user's email address
                //     delete event.user.email;
                // }
                return event;
            }
        });

        // Set user information, as well as tags and further extras
        _browser.configureScope(function (scope) {
            var ENV = Ember.ENV;

            // set tags
            if (ENV && ENV.name) {
                scope.setTag('environment', ENV.name);
            }
            if (ENV && ENV.domainId) {
                scope.setTag('domainId', ENV.domainId);
            }
            if (ENV && ENV.domainName) {
                scope.setTag('domainName', ENV.domainName);
            }

            // set extra information
            if (ENV && ENV.apiRoot) {
                scope.setExtra('apiRoot', ENV.apiRoot);
            }
        });
    } // end sentry init and configuration

    var App;

    App = Ember.Application.extend({
        modulePrefix: _environment.default.modulePrefix,
        podModulePrefix: _environment.default.podModulePrefix,
        Resolver: _resolver.default
    });

    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

    //For the ajax request made through jquery, the API header isn't getting attached.
    Ember.$.ajaxPrefilter(function (options, oriOptions, jqXHR) {
        var sessionId = _cookies.default.getSessionId();
        var isCloverApi = options.url.indexOf(Ember.ENV.cloverApiRoot) > -1;

        if (!isCloverApi) {
            jqXHR.setRequestHeader('X-EpcApi-ID', sessionId);
            jqXHR.setRequestHeader('X-Uid', window.ENV.keenUid);
            jqXHR.setRequestHeader('X-Domain-ID', Ember.ENV.domainId);
        }
    });

    exports.default = App;
});