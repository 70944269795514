define('ingenio-web/mixins/checkout-flow/quick-start-handler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        quickStartFlow: Ember.computed('quickStart', function () {
            var quickStart = this.get('quickStart');

            return quickStart;
        }),

        initializeQuickStartFlow: function initializeQuickStartFlow() {
            this.set('counter', 7);
            this.set('countDownCanceled', false);
            this.startCountDown();
        },
        startCountDown: function startCountDown() {
            var _this = this;

            var countDownCanceled = this.get('countDownCanceled');

            if (countDownCanceled) {
                return;
            }

            var counter = this.get('counter');

            if (counter > 0) {
                Ember.run.later(function () {
                    return _this.startCountDown();
                }, 1000);
            } else {
                this.send('start');
            }

            this.set('counter', counter - 1);
        },
        stopCountDownAndExitQuickStartFlow: function stopCountDownAndExitQuickStartFlow() {
            this.set('countDownCanceled', true);
            this.set('quickStart', false);
        },

        actions: {
            start: function start() {
                this.stopCountDownAndExitQuickStartFlow();
                this.send('onPrimaryButtonClick');
            },
            cancelCountDown: function cancelCountDown() {
                this.stopCountDownAndExitQuickStartFlow();
                var conversationPaymentMethodSelected = this.get('model.conversationPaymentMethodSelected');
                var isUserEligibleForConversationPostPay = this.get('model.isUserEligibleForConversationPostPay');

                if (isUserEligibleForConversationPostPay && !conversationPaymentMethodSelected) {
                    this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.details', { r: true });
                }
            }
        }
    });
});