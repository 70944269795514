define('ingenio-web/components/credit-card/card-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        jcb: 'Jcb',
        visa: 'Visa',
        discover: 'Discover',
        americanExpress: 'AMEX',
        masterCard: 'MasterCard',
        dinersClub: 'DinersClub'
    };
});