define('ingenio-web/arrange-conversation/sms/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var model = this.modelFor(this.getParentRouteName());

      if (!model.get("userOperation")) {
        if (!model.get("arrangedCall")) {
          this.replaceWith(this.getParentRouteName() + ".details", {
            queryParams: transition.queryParams
          });
        } else {
          this.replaceWith(this.getParentRouteName() + ".confirmation", {
            queryParams: transition.queryParams
          });
        }
      }
    },
    setupController: function setupController(controller, model) {
      var userInfo = Ember.Application.user;
      var userPhones = model.user.phoneNumbers;

      var primaryPhone = userPhones.findBy('isPrimary', true);

      if (!primaryPhone) {
        primaryPhone = this.store.createRecord('userPhone');
        primaryPhone.set('userId', userInfo.id);
      }
      model.set('smsPageDisplayed', true);

      var phoneWithSmsNotificationEnabled = userPhones.findBy('isSmsNotificationEnabled', true);

      if (phoneWithSmsNotificationEnabled) {
        controller.set('smsNotificationEnabled', true);
      }

      controller.set('userPhones', userPhones);
      controller.set('primaryPhone', primaryPhone);

      if (phoneWithSmsNotificationEnabled) {
        var phoneId = phoneWithSmsNotificationEnabled.get('id');

        controller.set('selectedUserPhoneId', phoneId);
      } else {
        controller.set('selectedUserPhoneId', primaryPhone.get('id'));
      }

      controller.set('model', model);
    }
  });
});