define('ingenio-web/helpers/format-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDefault = formatDefault;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatDefault(_ref, namedArgs) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (rest.some(function (dv) {
      return dv == value;
    })) {
      return namedArgs.default;
    }

    return namedArgs.value;
  }

  exports.default = Ember.Helper.helper(formatDefault);
});