define('ingenio-web/initializers/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /*
    Reopening a Controller means giving functionality to ALL controllers in the app. Be very-very careful
    while changing stuff here.
   */
  function initialize() /* application */{
    Ember.Controller.reopen({
      getParentRouteName: function getParentRouteName() {
        var currentRouteName = this.get('target.currentRouteName');
        var parentRouteName = '';
        if (currentRouteName.lastIndexOf('.')) {
          parentRouteName = currentRouteName.substring(0, currentRouteName.lastIndexOf('.'));
        }
        return parentRouteName;
      }
    });
  }

  exports.default = {
    name: 'controller',
    initialize: initialize
  };
});