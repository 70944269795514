define('ingenio-web/birth-chart/controller', ['exports', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/scroll-helpers', 'ingenio-web/models/step', 'ingenio-web/mixins/checkout-flow/controller-base'], function (exports, _errorHandler, _transitionHelpers, _scrollHelpers, _step, _controllerBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_controllerBase.default, _errorHandler.default, _transitionHelpers.default, _scrollHelpers.default, {
    pmode: 0,
    webtrack: 1,
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    r: true, //we use in for redirect from confirmation to details without back redirect in case of enough money
    iovationClient: Ember.inject.service('iovation'),
    flashMessages: Ember.inject.service(),
    userEvents: Ember.inject.service(),

    _showConfirmDepositDialog: false,

    displayConfirmDepositDialog: Ember.computed.equal('_showConfirmDepositDialog', true),

    steps: Ember.computed('target.currentPath', function () {
      var activeRoute = this.get('target.currentPath');

      var allSteps = [_step.default.create({
        name: 'Form',
        title: '',
        route: 'birth-chart.form'
      }), _step.default.create({
        name: 'Payment',
        title: 'Payment',
        route: 'birth-chart.payment-details'
      }), _step.default.create({
        name: 'Confirmation',
        title: 'Confirmation',
        route: 'birth-chart.confirmation'
      })];

      return allSteps.map(function (step) {
        step.set('active', step.get('route') === activeRoute);
        return step;
      });
    }),

    showConfirmDepositDialog: function showConfirmDepositDialog(isAvsReqd) {
      var _this = this;

      var selectedCreditCard = this.get('model.paymentMethodSelected');

      // get credit card and show dialog to provide new address or cvv code
      this.get('store').findRecord('creditCard', selectedCreditCard.id).then(function (entity) {
        //selectedCreditCard.set('address', entity.get('address'));
        entity.set('isAvsRequired', isAvsReqd);
        entity.set('isCvnRequired', true);

        // validate credit card with newly assigned attributes
        entity.validate();

        _this.set('avsModel', entity);
        _this.set('_showConfirmDepositDialog', true);
      });
    }
  });
});