define('ingenio-web/models/transaction', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        amount: (0, _attr.default)('number'),
        currency: (0, _attr.default)('string'),
        date: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        listingTitle: (0, _attr.default)('string'),
        serviceRate: (0, _attr.default)('string'),
        buyerUserId: (0, _attr.default)('number'),
        isRateable: (0, _attr.default)('boolean'),
        majorType: (0, _attr.default)('string'),
        sellerUserId: (0, _attr.default)('number'),
        customerId: (0, _attr.default)('number'),
        customerName: (0, _attr.default)('string'),
        advisorId: (0, _attr.default)('number'),
        advisorName: (0, _attr.default)('string'),
        serviceId: (0, _attr.default)('number'),
        transactionReferenceId: (0, _attr.default)('number'),
        customerTechnicalFeedback: (0, _attr.default)(''),
        customerFeedback: (0, _attr.default)('')
    });
});