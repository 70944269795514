define('ingenio-web/models/transaction-detail', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        listingId: (0, _attr.default)('number'),

        advisorId: (0, _attr.default)('number'),

        consumerId: (0, _attr.default)('number'),
        consumerName: (0, _attr.default)('string'),
        consumerNickname: (0, _attr.default)('string'),

        startDate: (0, _attr.default)('date'),
        startDateFormatted: (0, _attr.default)('string'),
        startTimeFormatted: (0, _attr.default)('string'),

        endDate: (0, _attr.default)('date'),
        endTimeFormatted: (0, _attr.default)('string'),

        timeZone: (0, _attr.default)('string'),
        totalTime: (0, _attr.default)('number'),

        offerTime: (0, _attr.default)('number'),
        offerDescription: (0, _attr.default)('string'),

        rate: (0, _attr.default)('number'),

        sessionCost: (0, _attr.default)('number'),

        platformFee: (0, _attr.default)('number'),
        commission: (0, _attr.default)('number'),
        advisorEarnings: (0, _attr.default)('number'),
        hideAdvisorFinancialDetails: (0, _attr.default)('boolean'),
        hideFinancialDetails: (0, _attr.default)('boolean'),

        balanceUsed: (0, _attr.default)('number'),
        balanceUsedDate: (0, _attr.default)('date'),
        balanceUsedDateFormatted: (0, _attr.default)('string'),

        transactionIsRateable: (0, _attr.default)('boolean'),
        feedbackRating: (0, _attr.default)('number'),
        advisorStrengths: (0, _attr.default)('string'),
        feedbackComment: (0, _attr.default)('string'),
        feedbackIsQualifiedForDisplay: (0, _attr.default)('boolean'),

        bonusIsApplicable: (0, _attr.default)('boolean'),
        bonusDetails: (0, _attr.default)(),

        postPayType: (0, _attr.default)('string'),
        // TODO: rename to postPayDetails when it'll be possible to update data contract in apps
        seamlessPayDetails: (0, _attr.default)(),
        autoReloadTotal: (0, _attr.default)('number'),
        autoReloadBalanceLeft: (0, _attr.default)('number'),

        isFixedPriceSession: (0, _attr.default)('boolean'),
        sessionDuration: (0, _attr.default)('number'),
        isPromotionalSession: (0, _attr.default)('boolean'),

        fees: (0, _attr.default)(),

        feedbackExists: Ember.computed("feedbackComment", function () {
            return this.get("feedbackComment") && this.get("feedbackComment").length > 0;
        }),

        sessionDurationInHours: Ember.computed("sessionDuration", function () {
            return this.get("sessionDuration") / 60;
        }),

        feedbackCommentExistAndQualifiedForDisplay: Ember.computed("feedbackComment", "feedbackIsQualifiedForDisplay", function () {
            return this.get("feedbackComment") && this.get("feedbackComment").length > 0 && this.get("feedbackIsQualifiedForDisplay");
        }),

        feedbackRatingExistsButNotQualifiedForDisplay: Ember.computed("feedbackRating", "feedbackIsQualifiedForDisplay", function () {
            var feedbackRatingExists = this.get("feedbackRating") > 0;
            return feedbackRatingExists && !this.get("feedbackIsQualifiedForDisplay");
        }),

        feedbackRatingExistsAndQualifiedForDisplay: Ember.computed("feedbackRating", "feedbackIsQualifiedForDisplay", function () {
            var feedbackRatingExists = this.get("feedbackRating") && this.get("feedbackRating") > 0;
            return feedbackRatingExists && this.get("feedbackIsQualifiedForDisplay");
        }),
        transactionIsNotRateableAndNoRatingExists: Ember.computed('transactionIsRateable', 'feedbackRating', function () {
            return !this.get("transactionIsRateable") && this.get("feedbackRating") <= 0;
        }),

        advisorStrengthsExists: Ember.computed("advisorStrengths", function () {
            return this.get("advisorStrengths") && this.get("advisorStrengths").length > 0;
        })
    });
});