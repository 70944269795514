define('ingenio-web/components/delete-account/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var domainName = 'Keen';
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            var user = Ember.Application.user;
            this.set('user', user);
        },


        store: Ember.inject.service(),
        user: null,

        displayDeleteAccountConfirmationModal: true,
        displayCloseAccountSuccessModal: false,
        deleteAccountStatus: null,

        deactivateAccount: function deactivateAccount() {
            var _this = this;

            var userId = this.get('user.id');
            var C_RequestedByCustomer = 6;
            var url = Ember.ENV.getApiRoot() + '/users/' + userId + '/account-closure';
            var data = {
                userId: userId,
                userAccountClosureType: C_RequestedByCustomer,
                comments: 'Requested by customer'
            };
            Ember.$.post(url, data).done(function (data) {
                if (data.accountClosed) {
                    _this.set('deleteAccountStatus', 'processing');
                } else {
                    // Account deactivation failure.
                    console.log('Error deactivating account!');
                    _this.set('deleteAccountStatus', 'failure');
                    _this.get('loggerService').error('Error deactivating account', {}, reason);
                }
            }).fail(function (reason) {
                console.log('Error deactivating account: ', reason);
                _this.set('deleteAccountStatus', 'failure');
                _this.get('loggerService').error('Error deactivating account', {}, reason);
            });
        },


        actions: {
            confirmDeleteAccount: function confirmDeleteAccount() {
                this.set('displayDeleteAccountConfirmationModal', true);
            },
            logoutUser: function logoutUser() {
                var url = '/login/logout';
                Ember.$.post(url, {}).done(function (data) {
                    console.log('Logged out successfully!');
                    window.location.href = '/';
                }).fail(function (reason) {
                    console.log('Error logging out user: ', reason);
                    //navigate the user to the home page.
                    window.location.href = '/';
                });
            },
            cancel: function cancel() {
                this.set('displayDeleteAccountConfirmationModal', false);
                if (this.get("onCancel")) {
                    this.get("onCancel")();
                }
            },
            deleteAccount: function deleteAccount() {
                var _this2 = this;

                console.log("Will attempt to delete account");
                var user = this.get('user');
                var additionalFields = {};
                var userId = user.id;
                var url = Ember.ENV.getApiRoot() + '/help/support';
                var data = {
                    additionalFields: '',
                    userId: userId,
                    description: "",
                    email: user.email,
                    isAuthenticated: true,
                    source: 1,
                    subtopic: "Close Account - Delete Account",
                    topic: "Account Management",
                    userType: user.isAdvisor ? "advisor" : "consumer",
                    webSourceDetail: window.location.origin
                };
                Ember.$.post(url, data).done(function (reason) {
                    if (user.isAdvisor) {
                        _this2.set('deleteAccountStatus', 'processing');
                        console.log('Processing Account Deletion...');
                    } else {
                        _this2.deactivateAccount();
                    }
                }).fail(function (reason) {
                    console.log('Error submitting deleting account request: ', reason);
                    _this2.set('deleteAccountStatus', 'failure');
                    _this2.get('loggerService').error('Error Submitting Delete Account Request', {}, reason);
                });
            }
        }
    });
});