define('ingenio-web/arrange-conversation/details/controller', ['exports', 'ingenio-web/arrange-conversation/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    queryParams: ["listingid", "pmode", "webtrack", 'clickid', "arrange", "r", "ctype"],

    arrangeConversation: Ember.inject.controller(),
    steps: Ember.computed.alias("arrangeConversation.steps"),

    enablePrimaryButton: Ember.computed("model.accountBalanceWithExtraFundsMeetsMinimum", "primaryButtonBusy", "phonesValid", function () {
      if (this.get("primaryButtonBusy") || !this.get("phonesValid")) {
        return false;
      }

      return true;
    }),

    showChangeFundsLink: Ember.computed("phonesInMultiEditMode", "primaryButtonBusy", function () {
      return !this.get("primaryButtonBusy") && !this.get("phonesInMultiEditMode");
    }),

    infoMessage: function () {
      if (this.get('model.isChatConversation')) return "<strong>The advisor isn't available right now.</strong > You can join their waitlist and we will text you when they return. You will receive updates via text.";

      return "<strong>The advisor isn't available right now.</strong > You can join their waitlist and we will call you when they return. You will receive updates via text.";
    }.property('model.isChatConversation'),

    actions: {
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();

        var user = Ember.Application.user;

        this.set("model.returnRoute", null);
        this.set("model.userInAddFundsFlow", false);

        this.set("primaryButtonBusy", true);
        this.savePhoneNumbers().then(function (phones) {
          _this.set("model.user.phoneNumbers", phones);

          if (!_this.get("phonesInMultiEditMode")) {
            if (_this.get("model.isAddPaymentMethodRequired")) {
              _this.set("primaryButtonBusy", false);
              // user need to add payment method
              _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + ".payment-details");
            } else if (_this.get("model.isAddFundsRequired")) {
              _this.set("primaryButtonBusy", false);
              _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + ".add-funds");
            } else if (_this.get("model.isSmsPageRequired")) {
              _this.set("primaryButtonBusy", false);
              _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + ".sms");
            } else if (_this.get("model.canArrangeConversation")) {
              // user has all required criteria met to arrange the conversation
              _this.arrangeConversationRequest().then(function (arrangedCall) {
                _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + ".confirmation");
              }).catch(function (error) {
                _this.get("model.notifications.errors").forEach(function (item) {
                  _this.flashMessages.danger(item, {
                    showProgress: true,
                    timeout: 5000
                  });
                });
                _this.get("model.notifications.errors").clear();
              }).finally(function () {
                _this.set("primaryButtonBusy", false);
              });
            } else {
              _this.set("primaryButtonBusy", false);
            }
          } else {
            _this.set("phonesInMultiEditMode", false);
            _this.set("primaryButtonBusy", false);
          }
        }).catch(function (error) {
          _this.set("primaryButtonBusy", false);
          _this.handleError(error);
        });
      },
      onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
        this.set("phonesInMultiEditMode", true);
        this.startEditPhones();
      },
      onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
        this.cancelEditPhones();
        this.set("phonesInMultiEditMode", false);
      },
      onPhoneNumberChange: function onPhoneNumberChange(phones) {
        this.set("phones", phones);
      },
      onPhoneNumbersValidityChange: function onPhoneNumbersValidityChange(validationState) {
        this.set("phonesValid", validationState.valid);
      },
      onChangeFundsClick: function onChangeFundsClick() {
        this.set("model.returnRoute", null);
        this.set("model.userInAddFundsFlow", false);

        if (this.get("model.isAddPaymentMethodRequired")) {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".payment-details");
        } else {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".add-funds");
        }
      },
      onChangeTextAlertsClick: function onChangeTextAlertsClick() {
        var update = 1;
        if (this.get("model.user.notifications.viaSms.enabled")) {
          update = 0;
        }

        this.transitionToRouteWithCurrentParams("smsNotifications", {
          "returnUrl": window.location.href,
          "update": update
        });
      }
    }
  });
});