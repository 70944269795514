define('ingenio-web/components/recommended-advisors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        recommendedAdvisors: null,
        categoryId: null,
        didInsertElement: function didInsertElement() {
            $('#slider-container').slick({
                // dots: true,
                centerMode: true,
                infinite: true,
                speed: 300,
                //slidesToShow: 5,
                slidesToScroll: 1,
                initialSlide: 2,
                variableWidth: true,
                prevArrow: $('#prevArrow'),
                nextArrow: $('#nextArrow'),
                responsive: [{
                    breakpoint: 480,
                    settings: {
                        //slidesToShow: 1,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        //slidesToShow: 2,
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        //slidesToShow: 3,
                    }
                }, {
                    breakpoint: 1200,
                    settings: {
                        //slidesToShow: 4,
                    }
                }]
            });
        },
        categoryUrl: function () {
            return '/listings?category=' + this.get('categoryId');
        }.property(),
        actions: {
            selectAdvisor: function selectAdvisor(recommendedAdvisor) {
                window.location.href = '/listingdetails?sid=' + recommendedAdvisor.get('id');
            }
        }
    });
});