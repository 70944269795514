define('ingenio-web/routes/featured-listings/results/summary', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        }

    });
});