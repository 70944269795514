define('ingenio-web/models/add-funds-flow', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        flowType: 'add-funds',

        user: {
            funds: null,
            balance: null
        },

        hasExistingPaymentMethods: Ember.computed('user.paymentMethods.@each', function () {
            return !Ember.isEmpty(this.get('user.paymentMethods'));
        }),

        paymentMethodSelected: Ember.computed('user.funds.paymentMethodId', 'user.paymentMethods.@each', function () {
            var _this = this;

            return this.get('user.paymentMethods').find(function (method) {
                return method.get('id') === _this.get('user.funds.paymentMethodId');
            });
        }),

        minimumDepositAmount: Ember.computed('', function () {
            var absoluteMinimumDepositAmount = !Ember.Application.user.hasAddedFundsInPast ? Ember.ENV.minimumDepositAmount : this.get('repeatUserMinimumDepositAmount');

            return absoluteMinimumDepositAmount;
        }),

        maximumDepositAmount: Ember.computed('', function () {
            return Ember.ENV.maximumDepositAmount;
        }),

        balanceAmountRounded: Ember.computed('user.balance.monetaryAsNumber', function () {
            if (!this.get('user.balance.monetaryAsNumber')) {
                return '0.00';
            }

            return this.get('user.balance.monetaryAsNumber').toFixed(2);
        })
    });
});