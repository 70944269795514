define('ingenio-web/routes/listing/draft', ['exports', 'ingenio-web/routes/listing/route-base', 'ingenio-web/helpers/platform-domain'], function (exports, _routeBase, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeBase.default.extend({
        //for immutable data to that is getting loaded separately from mutable primary model
        immutableData: {
            specSkillsAndLanguages: {},
            listingCategories: {},
            domainInfo: {}
        },

        //todo: in case there is no model (page opened with direct url) model loading and loading of other data
        // will be sequential, which delays view rendering
        afterModel: function afterModel(model) {
            var self = this;

            var apiUrl = Ember.ENV.getApiRoot();
            var loadListingCategories = Ember.$.getJSON(apiUrl + '/listing-categories?domainid=' + Ember.ENV.domainId).then(function (response) {
                return response.listingCategories;
            });

            var loadSpecSkillsAndLanguages = Ember.$.getJSON(apiUrl + '/specializations-skills-languages?domainid=' + Ember.ENV.domainId);

            var loadDomainInfo = Ember.$.getJSON(apiUrl + '/domain-info?domainid=' + Ember.ENV.domainId + '&isAdvisor=true').then(function (response) {
                return response.domainInfo;
            });

            var promises = {
                listingCategories: loadListingCategories,
                specSkillsAndLanguages: loadSpecSkillsAndLanguages,
                domainInfo: loadDomainInfo
            };

            //waiting for all promises to resolve
            return Ember.RSVP.hash(promises).then(function (result) {
                model.set('domainInfo', result.domainInfo);

                var defaultCategory = {
                    id: 0,
                    name: 'Select Category',
                    parentId: 195
                };
                _platformDomain.platformIsKeen && result.listingCategories.push(defaultCategory);
                self.immutableData.listingCategories = result.listingCategories;

                self.immutableData.specSkillsAndLanguages = result.specSkillsAndLanguages;
                self.immutableData.domainInfo = result.domainInfo;
            });
        },

        activate: function activate() {
            this._super.apply(this, arguments);
            this.bindPopovers();
        },


        setupController: function setupController(controller, model) {
            controller.set('model', model);

            var tools = this.immutableData.specSkillsAndLanguages.tools;
            //TODO: Remove this filter once the skills in database are cleaned.
            var skills = this.immutableData.specSkillsAndLanguages.skills.filter(function (skill) {
                return !tools.some(function (tool) {
                    return tool.value === skill.value;
                });
            });

            controller.set('listingCategories', this.immutableData.listingCategories);
            controller.set('specializationList', this.immutableData.specSkillsAndLanguages.specializations.filter(function (specialization) {
                return specialization.categoryId != 17011;
            }).sortBy('value'));
            controller.set('specializationListForIC', this.immutableData.specSkillsAndLanguages.specializations.filter(function (specialization) {
                return specialization.categoryId == 17011;
            }).sortBy('value'));
            controller.set('domainInfo', this.immutableData.domainInfo);
            controller.set('skillList', skills.sort(function (a, b) {
                a = a.value.replace(/–/g, '');
                b = b.value.replace(/–/g, '');
                return a.localeCompare(b);
            }));
            controller.set('toolList', tools.sort(function (a, b) {
                a = a.value.replace(/–/g, '');
                b = b.value.replace(/–/g, '');
                return a.localeCompare(b);
            }));
            controller.set('languageList', this.immutableData.specSkillsAndLanguages.languages.sortBy('value'));
            controller.set('communicationStyleList', this.immutableData.specSkillsAndLanguages.communicationStyles.sortBy('value'));

            //we can't use property for cantEditUserName because it can make input readonly in the middle of edit
            var advisorName = model.get('advisorName');
            var cantEditUserName = !advisorName.startsWith('User');

            controller.set('cantEditUserName', cantEditUserName);
            controller.set('allowVideoUpload', true);
        }
    });
});