define('ingenio-web/adapters/chat-advisor-note', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(modelName, id, snapshot, requestType) {
            var userId = Ember.Application.user.id;

            if (requestType === 'query') {
                return Ember.ENV.getApiRoot() + ('/users/' + userId + '/chat/note');
            }

            var consumerId = snapshot.record.get('consumerId');

            return Ember.ENV.getApiRoot() + ('/users/' + userId + '/chat/note?consumerId=' + consumerId);
        }
    });
});