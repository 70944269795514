define('ingenio-web/components/grid/pagination-control', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: [],

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
        },


        onPageSizeChanged: function () {
            this.$('select.page-size-select').val(this.get('pagination.pageSize'));
        }.observes('pagination.pageSize'),

        actions: {
            gridGoPreviousPage: function gridGoPreviousPage() {
                this.sendAction('previousPage');
                this.get('pagination').previousPage();
            },
            gridGoNextPage: function gridGoNextPage() {
                this.sendAction('nextPage');
                this.get('pagination').nextPage();
            }
        }
    });
});