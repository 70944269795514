define('ingenio-web/adapters/consumer', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            var userId = Ember.Application.user.id;

            return 'users/' + userId + '/consumers';
        }
    });
});