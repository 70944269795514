define("ingenio-web/arrange-conversation/confirmation/controller", ["exports", "ingenio-web/arrange-conversation/controller"], function (exports, _controller) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    queryParams: ["listingid", "pmode", "webtrack", 'clickid', "arrange", "r", "ctype"],

    arrangeConversation: Ember.inject.controller(),
    steps: Ember.computed.alias("arrangeConversation.steps"),

    secondaryButtonBusy: false,
    showLeaveWaitlistConfirmationDialog: false,

    enablePrimaryButton: Ember.computed("model.accountBalanceWithExtraFundsMeetsMinimum", "primaryButtonBusy", "phonesValid", function () {
      if (this.get("primaryButtonBusy") || !this.get("phonesValid")) {
        return false;
      }

      return true;
    }),

    showChangeFundsLink: Ember.computed("phonesInMultiEditMode", "primaryButtonBusy", function () {
      return !this.get("primaryButtonBusy") && !this.get("phonesInMultiEditMode");
    }),

    infoMessage: function () {
      if (this.get('model.isChatConversation')) return '<strong class="alert-text">Arranged chat confirmed.</strong> We will text you when the advisor returns. You will receive updates via text.';

      return '<strong class="alert-text">Arranged call confirmed.</strong> We will call you when the advisor returns. You will receive updates via text.';
    }.property('model.advisorConversationRequest.forConversationType'),

    successMessage: function () {
      if (this.get('model.isChatConversation')) {
        if (this.get('model.advisorConversationRequest.queuePosition') == null) {
          return '<div class="alert-icon"> <i class="fal fa-check-circle"></i></div > <div class="message-content">Advisor is available for chat.</div>';
        }
        return '<div class="alert-icon"> <i class="fal fa-check-circle"></i></div > <div class="message-content">Arranged chat confirmed.</div>';
      }

      return '<div class="alert-icon"> <i class="fal fa-check-circle"></i></div > <div class="message-content">Arranged call confirmed.</div>';
    }.property('model.advisorConversationRequest.forConversationType'),

    primaryButtonText: function () {
      if (this.get('phonesInMultiEditMode')) {
        return "Save Numbers";
      } else {
        if (this.get('model.isChatConversation') && this.get("model.advisorConversationRequest.status") == "SERVICING") {
          return "Start Chat";
        }
        return "View Waitlist";
      }
    }.property('phonesInMultiEditMode', 'model.advisorConversationRequest.queuePosition', 'model.isChatConversation'),

    showLeaveWaitlistButton: function () {
      if (this.get('phonesInMultiEditMode')) {
        return false;
      } else {
        if (this.get('model.isChatConversation') && this.get("model.advisorConversationRequest.queuePosition") == null) {
          return false;
        }
        return true;
      }
    }.property('phonesInMultiEditMode', 'model.advisorConversationRequest.queuePosition', 'model.isChatConversation'),

    actions: {
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();

        if (this.get("phonesInMultiEditMode")) {
          // save phone numbers
          this.set("model.returnRoute", null);
          this.set("model.userInAddFundsFlow", false);

          this.set("primaryButtonBusy", true);
          this.savePhoneNumbers().then(function (phones) {
            _this.set("model.user.phoneNumbers", phones);
            _this.set("phonesInMultiEditMode", false);
          }).catch(function (error) {
            _this.handleError(error);
            _this.get("logger").error("Problem in adding/updating phone number. userId=" + user.id, {
              originalError: error
            });
          }).finally(function () {
            _this.set("primaryButtonBusy", false);
          });
        } else {
          if (this.get('model.isChatConversation') && this.get("model.advisorConversationRequest.status") == "SERVICING") {
            // redirect to initiate chat flow
            var route = "chat-setup.confirmation";
            this.transitionToRoute(route, {
              queryParams: {
                arrangeid: this.get('model.advisorConversationRequest.id'),
                listingid: this.get('model.listing.id')
              }
            });
          } else {
            // redirect to the View Waitlist
            window.location.href = '/arranged-conversations?tab=my-advisors';
          }
        }
      },
      onLeaveWaitlistClick: function onLeaveWaitlistClick() {
        this.clearErrors();

        this.set("showLeaveWaitlistConfirmationDialog", true);
      },
      onLeaveWaitlist: function onLeaveWaitlist() {
        var _this2 = this;

        this.set("showLeaveWaitlistConfirmationDialog", false);

        this.set("secondaryButtonBusy", true);
        this.cancelArrangedCall().then(function (message) {
          _this2.set("model.advisorConversationRequest", null);
          window.location.href = '/arranged-conversations?tab=my-advisors';
        }).catch(function (error) {
          _this2.get("model.notifications.errors").forEach(function (item) {
            _this2.flashMessages.danger(item, {
              showProgress: true,
              timeout: 5000
            });
          });
          _this2.get("model.notifications.errors").clear();
        }).finally(function () {
          _this2.set("secondaryButtonBusy", false);
        });
      },
      onCancelLeaveWaitlist: function onCancelLeaveWaitlist() {
        this.set("showLeaveWaitlistConfirmationDialog", false);
      },
      onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
        this.set('phonesInMultiEditMode', true);
        this.startEditPhones();
      },
      onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
        this.cancelEditPhones();
        this.set("phonesInMultiEditMode", false);
      },
      onPhoneNumberChange: function onPhoneNumberChange(phones) {
        this.set("phones", phones);
      },
      onPhoneNumbersValidityChange: function onPhoneNumbersValidityChange(validationState) {
        this.set("phonesValid", validationState.valid);
      },
      onChangeFundsClick: function onChangeFundsClick() {
        this.set("model.returnRoute", this.getParentRouteName() + ".confirmation");
        this.set("model.userInAddFundsFlow", true);

        if (this.get("model.isAddPaymentMethodRequired")) {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".payment-details");
        } else {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".add-funds");
        }
      },
      onChangeTextAlertsClick: function onChangeTextAlertsClick() {
        window.location.href = "/MyAccount/UpdatePersonalInfo.aspx?expand=email-notify";
      }
    }
  });
});