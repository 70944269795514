define('ingenio-web/metrics-adapters/snapchat-pixel', ['exports', 'ember-metrics/utils/can-use-dom', 'ingenio-web/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'snapchat-pixel';
    },
    init: function init() {
      var config = this.get('config');
      var id = config.id;


      if (_canUseDom.default) {
        /* eslint-disable */
        (function (e, t, n) {
          if (e.snaptr) return;var a = e.snaptr = function () {
            a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
          };
          a.queue = [];var s = 'script';var r = t.createElement(s);r.async = !0;
          r.src = n;var u = t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r, u);
        })(window, document, 'https://sc-static.net/scevent.min.js');
        /* eslint-enable */

        window.snaptr('init', id, {
          'user_email': Ember.Application.user.email
        });
      }
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!_canUseDom.default) {
        return;
      }

      var compactedOptions = compact(options);
      var event = compactedOptions.event;


      if (!event) {
        return;
      }
      delete compactedOptions.event;

      if (window.snaptr) {
        window.snaptr('track', event, compactedOptions);
      }
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!_canUseDom.default) {
        return;
      }

      window.snaptr('track', 'PAGE_VIEW', options);
    },
    willDestroy: function willDestroy() {
      if (!_canUseDom.default) {
        return;
      }

      removeFromDOM('script[src*="scevent.min.js"]');

      delete window.snaptr;
      delete window._snaptr;
    }
  });
});