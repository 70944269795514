define('ingenio-web/best-match/results/controller', ['exports', 'impression-tracking', 'ingenio-web/utils/cookies'], function (exports, _impressionTracking, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init: function init() {
            this._super.apply(this, arguments);

            if (!_cookies.default.isCloverFlow()) {
                var tracker = this.get('impressionTracker').getBestMatchImpressionTracker(this.getFiltersData.bind(this));
                this.set('observeImpression', tracker.observeImpression);
                this.set('attachClickEvents', tracker.attachElementClickedTracking);
            }
        },


        impressionTracker: Ember.inject.service('impression-tracker'),
        impressionScreen: _impressionTracking.ImpressionScreen.BestMatch,

        queryParams: ['id', 'listingstid', 'hlt', 'c', 'minprice', 'maxprice'],
        id: null,
        listingstid: null,
        c: null,
        minprice: null,
        maxprice: null,
        category: null,
        skill: null,
        showMoreListingsButton: Ember.computed.lt('c', 10),
        serviceTypes: [{ name: 'Call', statusId: '1' }, { name: 'Chat', statusId: '3' }, { name: 'Any', statusId: '0' }],

        showAvailabilityFilter: function () {
            return this.get('minprice') && this.get('maxprice');
        }.property('minprice', 'maxprice'),

        minPrice: function () {
            var minprice = this.get('minprice');
            var minPriceOverride = this.get('minPriceOverride');

            if (minPriceOverride) {
                return minPriceOverride;
            }
            return minprice;
        }.property('minprice', 'minPriceOverride'),

        maxPrice: function () {
            var maxprice = this.get('maxprice');
            var maxpriceOverride = this.get('maxPriceOverride');

            if (maxpriceOverride) {
                return maxpriceOverride;
            }
            return maxprice;
        }.property('maxprice', 'maxPriceOverride'),

        filterListings: function filterListings() {
            var listingsCount = this.get('c') || 4;
            var hlt = this.get('hltOverride');

            this.get('loadListings')(listingsCount, hlt, this.get('sortBy'), this.get('minPrice'), this.get('maxPrice'));
        },
        getFiltersData: function getFiltersData() {
            return {
                skill: this.get('skill'),
                sortBy: this.get('sortBy'),
                tid: this.get('listingsTid'),
                minPrice: this.get('minPrice'),
                maxPrice: this.get('maxPrice'),
                category: this.get('category'),
                availability: this.get('hltOverride')
            };
        },


        actions: {
            loadMoreListings: function loadMoreListings() {
                this.set('c', 10);
                this.filterListings();
            },
            priceFilterChanged: function priceFilterChanged(minPrice, maxPrice) {
                this.set('minPriceOverride', minPrice);
                this.set('maxPriceOverride', maxPrice);

                this.filterListings();
            },
            filterByServiceType: function filterByServiceType(hlt) {
                this.set('hltOverride', hlt);

                this.filterListings();
            },
            trackListingsImpression: function trackListingsImpression() {
                if (!_cookies.default.isCloverFlow()) {
                    this.get('observeImpression')();
                    this.get('attachClickEvents')();
                }
            }
        }
    });
});