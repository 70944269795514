define('ingenio-web/routes/featured-listings/listing-quality-score', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        model: function model() {
            return Ember.RSVP.hash({
                listings: this.store.query('listingQualityScore', {})
            });
        }
    });
});