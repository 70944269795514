define('ingenio-web/controllers/monitor', ['exports', 'moment', 'ingenio-web/utils/browser', 'ingenio-web/mixins/logger', 'mobile-detect'], function (exports, _moment, _browser, _logger, _mobileDetect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _Ember$Controller$ext;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Controller.extend(_logger.default, (_Ember$Controller$ext = {
        init: function init() {
            this._super.apply(this, arguments);
            $('.visibleonmot').removeClass('hidden');
            $('.hideonmob').addClass('hidden');
        },

        initialTimerSeconds: 0,
        domainName: Ember.ENV.domainName,
        isMobile: Ember.computed(function () {
            var md = new _mobileDetect.default(window.navigator.userAgent);
            var isPhone = md.phone() !== null;
            var isTablet = md.tablet() !== null;
            console.log('MobileDetection - Phone:', md.phone(), ' - Tablet: ', md.tablet(), 'isPhone:', isPhone, 'isTablet:', isTablet);

            return isPhone && !isTablet;
        }),
        isKeen: Ember.computed(function () {
            return Ember.ENV.domainId === 1;
        }),
        audioNotifications: Ember.inject.service(),
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        logData: {
            source: 'Monitor',
            advisorId: null //value will be set in the setupController hook.
        },

        queryParams: ['chatOnline'],

        status: 'offline',
        callMonitorStatus: 'offline',
        callMonitorOnlineSubstatus: '',
        offlineReason: '',

        chatStatuses: [{ name: 'OFFLINE', statusId: 'offline' }, { name: 'TAKING CHAT', statusId: 'online' }],

        advisorCallAvailability: null,
        advisorCallAvailabilityReloading: false,
        advisorCallAvailabilityUpdating: false,

        monitorClient: null,
        chatRequest: null,
        callStatusUpdateMessage: null,
        consumerDetails: null,
        availableTime: 0,

        requestTimeOutAfterSec: 0,
        requestTimeout: null,
        updateTimeOutSeconds: null,
        requestTimeoutDate: null,

        showInitedCallStatusTimeoutSec: 30,
        showTerminatedCallStatusTimeoutSec: 15,

        showInitedCallStatusTimeoutId: null,
        showTerminatedCallStatusTimeoutId: null,
        lastCallStatusUpdateDate: null,

        isDisplayConsumerOffer: false,
        consumerOffer: null,
        advisorLockStatus: "",
        conversationType: "",

        transcendTierId: "zrl_diamond",
        clarityTierId: "zrl_platinum",

        secondsToTime: function secondsToTime(seconds) {
            var tempTime = _moment.default.duration(seconds, 'seconds');

            var sec = tempTime.seconds().toString();

            if (sec.length === 1) {
                sec = '0' + sec;
            }
            return tempTime.minutes() + ':' + sec;
        },


        chatRequestTimeLeft: function () {
            var requestTimeOutAfterSec = this.get('requestTimeOutAfterSec');

            return this.secondsToTime(requestTimeOutAfterSec);
        }.property('requestTimeOutAfterSec'),

        monitorIsOnline: function () {
            return this.get('online') || this.get('onlineCallMonitor');
        }.property('online', 'onlineCallMonitor'),

        monitorIsOffline: function () {
            return this.get('offline') && this.get('offlineCallMonitor');
        }.property('offline', 'offlineCallMonitor'),

        monitorIsListening: function () {
            return this.get('chatIsListening') && this.get('callListening') && !this.get('advisorIsLocked');
        }.property('chatIsListening', 'callListening', 'advisorIsLocked'),

        monitorIsBusy: function () {
            var callListening = this.get('callListening');
            var chatIsListening = this.get('chatIsListening');

            var chatOnline = this.get('online');
            var callOnline = this.get('onlineCallMonitor');

            var callIsBusy = !callListening && callOnline;
            var chatIsBusy = !chatIsListening && chatOnline;

            return callIsBusy || chatIsBusy || this.get('advisorIsLocked');
        }.property('chatIsListening', 'callListening', 'status', 'callMonitorStatus', 'advisorIsLocked'),

        connecting: Ember.computed.equal('status', 'connecting'),
        online: Ember.computed.equal('status', 'online'),

        onlineCallMonitor: Ember.computed.equal('callMonitorStatus', 'online'),
        callListening: Ember.computed.equal('callMonitorOnlineSubstatus', 'listening'),
        callInited: Ember.computed.equal('callMonitorOnlineSubstatus', 'inited'),
        callConnected: Ember.computed.equal('callMonitorOnlineSubstatus', 'connected'),
        callTerminated: Ember.computed.equal('callMonitorOnlineSubstatus', 'terminated'),

        callIsActive: Ember.computed.or('callInited', 'callConnected'),
        callAccepted: false,
        acceptCallRequestInProgress: false,

        chatIsListening: Ember.computed.equal('chatOnlineSubstatus', 'listening'),
        chatIsBusy: Ember.computed.equal('chatOnlineSubstatus', 'busy'),
        chatDecisionIsExpected: Ember.computed.equal('chatOnlineSubstatus', 'decision-is-expected'),

        chatIsActive: Ember.computed.or('chatIsBusy', 'chatDecisionIsExpected'),

        offline: Ember.computed.equal('status', 'offline'),
        offlineCallMonitor: Ember.computed.equal('callMonitorStatus', 'offline'),
        advisorIsLocked: Ember.computed.equal('advisorLockStatus', 'locked'),

        showCallStatusSwitcher: function () {
            return !this.get('monitorIsBusy') || this.get('callInited') || this.get('callConnected') || this.get('advisorIsLocked');
        }.property('monitorIsBusy', 'callInited', 'callConnected', 'advisorIsLocked'),

        showConsumerDetails: function () {
            return this.get('callInited') || this.get('callConnected') || this.get('callTerminated') || this.get('chatDecisionIsExpected') || this.get('chatIsBusy');
        }.property('callMonitorStatus', 'callMonitorOnlineSubstatus', 'chatOnlineSubstatus'),

        missedChat: false,
        disconnected: Ember.computed.equal('offlineReason', 'disconnected'),

        canPingConsumer: false,
        checkDirectMessageEligibility: function checkDirectMessageEligibility() {
            var self = this;
            var consumerId = this.get('consumerDetails').get('userId');

            if (consumerId) {
                this.store.queryRecord('directMessagesEligibility', { recipientId: consumerId }).then(function (eligibility) {
                    if (eligibility.get('name') === 'Eligible') {
                        self.set('canPingConsumer', true);
                    } else {
                        self.set('canPingConsumer', false);
                    }
                });
            }
        },
        getCurrentCallAvailability: function getCurrentCallAvailability(currentCallAvailabilityId) {
            var availabilityId = currentCallAvailabilityId;
            var callAvailabilities = this.get('callAvailabilities');

            if (typeof currentCallAvailabilityId === 'undefined') {
                availabilityId = this.get('advisorCallAvailability.currentCallAvailabilityId');
            }

            return callAvailabilities.find(function (element) {
                return element.availabilityId === availabilityId;
            });
        },
        syncCallMonitorStatus: function syncCallMonitorStatus(currentCallAvailabilityId) {
            this.set('missedChat', false);
            var callAvailability = this.getCurrentCallAvailability(currentCallAvailabilityId);

            var callMonitorStatus = this.get('callMonitorStatus');

            if (callAvailability.canReceiveCallNotification && callMonitorStatus === 'offline') {
                this.get('monitorClient').setCallOnline();
            } else if (!callAvailability.canReceiveCallNotification && callMonitorStatus === 'online') {
                this.send('clearCallStatusUpdateMessage');
                this.get('monitorClient').setCallOffline();
            }
        },
        syncChatMonitorStatus: function syncChatMonitorStatus(currentChatAvailability) {
            var status = this.get('status');

            if (status === 'online' && currentChatAvailability === 'Unavailable') {
                this.log('syncChatMonitorStatus(Unavailable).setChatOffline... ');
                this.send('clearRequestTimeout');
                this.get('monitorClient').setChatOffline();
            } else if (status === 'offline' && currentChatAvailability === 'Available') {
                this.log('syncChatMonitorStatus(Available).setChatOnline... ');
                this.get('monitorClient').setChatOnline();
                if (this.get('disconnected')) {
                    this.set('offlineReason', '');
                    this.set('missedChat', false);
                }
            }
        },
        reloadAvailabilityStatus: function reloadAvailabilityStatus() {
            var _this = this;

            var advisorCallAvailabilityUpdating = this.get('advisorCallAvailabilityUpdating');

            if (advisorCallAvailabilityUpdating) {
                return;
            }

            this.set('advisorCallAvailabilityReloading', true);
            var advisorAvailability = this.get('advisorAvailability');
            var currentCallAvailabilityId = this.get('advisorCallAvailability.currentCallAvailabilityId');

            advisorAvailability.reload().then(function (a) {
                var newCallAvailabilityId = a.get('advisorCallAvailability.currentCallAvailabilityId');

                if (newCallAvailabilityId !== currentCallAvailabilityId) {
                    _this.syncCallMonitorStatus(newCallAvailabilityId);
                    _this.set('advisorCallAvailability.currentCallAvailabilityId', newCallAvailabilityId);
                    _this.set('prevCallAvailabilityId', newCallAvailabilityId);
                }

                if (_this.get('chatEnabled')) {
                    var newChatAvailability = a.get('advisorChatAvailability.availability');

                    _this.syncChatMonitorStatus(newChatAvailability);
                }
            }).finally(function () {
                var advisorCallAvailabilityReloadingCallBack = _this.get('advisorCallAvailabilityReloadingCallBack');

                if (advisorCallAvailabilityReloadingCallBack) {
                    advisorCallAvailabilityReloadingCallBack();
                    _this.set('advisorCallAvailabilityReloadingCallBack', null);
                }

                _this.set('advisorCallAvailabilityReloading', false);
            });
        },
        sortAndRenameAvailabilities: function sortAndRenameAvailabilities(availabilities) {
            var sortedAvailabilitites = [3];

            for (var index = 0; index < availabilities.length; ++index) {
                var a = availabilities[index];

                a.name = a.name.toUpperCase();
                switch (a.name) {
                    case 'TAKING CALLS':
                        sortedAvailabilitites[2] = a;
                        break;
                    case 'ARRANGE CALLS':
                        sortedAvailabilitites[1] = a;
                        break;
                    case 'OFFLINE':
                        sortedAvailabilitites[0] = a;
                        break;
                }
            }
            return sortedAvailabilitites;
        },
        playSoundOnTakingCalls: function playSoundOnTakingCalls(currentCallAvailabilityId, prevCallAvailabilityId) {
            var callAvailabilities = this.getCurrentCallAvailability(currentCallAvailabilityId);

            var prevCallAvailabilityName = null;

            if (typeof prevCallAvailabilityId !== 'undefined') {
                var prevCallAvailability = this.getCurrentCallAvailability(prevCallAvailabilityId);

                if (prevCallAvailability) {
                    prevCallAvailabilityName = prevCallAvailability.name;
                }
            }

            if (callAvailabilities && callAvailabilities.name === 'TAKING CALLS' && prevCallAvailabilityName !== 'OFFLINE') {
                this.get('audioNotifications').playConnectedAlert();
            }
        },
        reloadOpenerWindow: function reloadOpenerWindow() {
            if (window.opener) {
                window.opener.location.reload(true);
            }
        },


        advisorChatHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360049890833-Chat-for-Advisors';
            }
            return '/help/advisorchat#add';
        }.property()

    }, _defineProperty(_Ember$Controller$ext, 'isDisplayConsumerOffer', function () {
        var isKeen = Ember.ENV.domainId === 1;
        var consumerHasNewCustomerOffer = void 0;
        if (this.get('callStatusUpdateMessage')) {
            consumerHasNewCustomerOffer = this.get('callStatusUpdateMessage').ConsumerOfferDescription;
        } else if (this.get('consumerDetails')) {
            consumerHasNewCustomerOffer = this.get('consumerDetails.offerDescription');
        }

        return isKeen && consumerHasNewCustomerOffer ? true : false;
    }.property('consumerDetails', 'callStatusUpdateMessage')), _defineProperty(_Ember$Controller$ext, 'consumerOffer', function () {
        var isDisplayConsumerOffer = this.get('isDisplayConsumerOffer');

        var consumerOfferText = void 0;
        if (this.get('callStatusUpdateMessage')) {
            consumerOfferText = this.get('callStatusUpdateMessage').ConsumerOfferDescription;
        } else if (this.get('consumerDetails')) {
            consumerOfferText = this.get('consumerDetails.offerDescription');
        }

        return isDisplayConsumerOffer ? consumerOfferText : "";
    }.property('consumerDetails', 'callStatusUpdateMessage')), _defineProperty(_Ember$Controller$ext, 'displayKeenRewardsTier', function () {
        var isKeen = Ember.ENV.domainId === 1;
        var currentKeenRewardTierId = this.get('consumerDetails.currentKeenRewardTierId');

        var consumerHasClarityOrTranscendTier = currentKeenRewardTierId === this.get("transcendTierId") || currentKeenRewardTierId === this.get("clarityTierId");

        return isKeen && consumerHasClarityOrTranscendTier ? true : false;
    }.property('consumerDetails')), _defineProperty(_Ember$Controller$ext, 'isKeenRewardsTranscendTier', function () {
        var currentKeenRewardTierId = this.get('consumerDetails.currentKeenRewardTierId');

        return this.get("transcendTierId") === currentKeenRewardTierId ? true : false;
    }.property('consumerDetails')), _defineProperty(_Ember$Controller$ext, 'isKeenRewardsClarityTier', function () {
        var currentKeenRewardTierId = this.get('consumerDetails.currentKeenRewardTierId');

        return this.get("clarityTierId") === currentKeenRewardTierId ? true : false;
    }.property('consumerDetails')), _defineProperty(_Ember$Controller$ext, 'actions', {
        playTestSound: function playTestSound() {
            this.get('audioNotifications').playTestAlert();
        },
        chatStateSelected: function chatStateSelected(chatStateId) {
            var _this2 = this;

            if (this.get('monitorClient').networIsDown) {
                Ember.run.schedule('afterRender', function () {
                    _this2.send('onDisconnect', true);
                });
                return;
            }

            this.get("mixpanelClient").trackNewEvent("Chat Availability Changed", {
                eventData: {
                    user_id: Ember.Application.user.id,
                    source: 'web',
                    availability: chatStateId
                }
            });

            this.log('chatStateSelected ' + chatStateId);
            this.set('offlineReason', '');
            if (chatStateId === 'online') {
                this.set('status', 'online');
                this.set('chatOnlineSubstatus', 'listening');
                if (this.get('disconnectLogOff')) {
                    // this.get('chatMonitorClient').unsubscribe();
                    // this.get('chatMonitorClient').subscribe();
                    //TODO: recall why we need unsubscribe in that case
                    this.get('monitorClient').setAvailable();
                    this.set('disconnectLogOff', false);
                } else {
                    this.get('monitorClient').setAvailable();
                }
            } else if (chatStateId === 'offline') {
                this.send('clearRequestTimeout');
                this.get('monitorClient').setUnavailable();
            }
            this.reloadOpenerWindow();
        },
        callAvailabilitySelected: function callAvailabilitySelected(callAvailabilityId) {
            var _this3 = this;

            var prevCallAvailabilityId = this.get('prevCurrentCallAvailabilityId');

            this.set('prevCurrentCallAvailabilityId', callAvailabilityId);

            var advisorCallAvailability = this.get('advisorCallAvailability');
            var updating = function updating() {
                _this3.set('advisorCallAvailabilityUpdating', true);
                advisorCallAvailability.save().then(function (a) {
                    var currentCallAvailabilityId = a.get('currentCallAvailabilityId');

                    _this3.syncCallMonitorStatus(currentCallAvailabilityId);
                    _this3.playSoundOnTakingCalls(currentCallAvailabilityId, prevCallAvailabilityId);
                }).finally(function () {
                    return _this3.set('advisorCallAvailabilityUpdating', false);
                });
            };

            var advisorCallAvailabilityReloading = this.get('advisorCallAvailabilityReloading');

            if (advisorCallAvailabilityReloading) {
                this.set('advisorCallAvailabilityReloadingCallBack', updating);
            } else {
                updating();
            }
            this.reloadOpenerWindow();
        },
        setup: function setup(monitorClient, chatEnabled) {
            this.set('monitorClient', monitorClient);

            this.send('clearRequestTimeout');
            this.send('clearCallStatusUpdateMessage');
            this.setCreateLogEntry(true);

            this.set('chatEnabled', chatEnabled);
        },
        setupCallAvailability: function setupCallAvailability(advisorCallAvailability) {
            this.set('advisorCallAvailability', advisorCallAvailability);

            var currentCallAvailabilityId = advisorCallAvailability.get('currentCallAvailabilityId');

            var callAvailabilities = this.sortAndRenameAvailabilities(advisorCallAvailability.get('callAvailabilities'));

            this.set('callAvailabilities', callAvailabilities);

            this.syncCallMonitorStatus(currentCallAvailabilityId, callAvailabilities);
        },
        setupAvailability: function setupAvailability(advisorAvailability) {
            var _this4 = this;

            this.set('advisorAvailability', advisorAvailability);

            setInterval(function () {
                return _this4.reloadAvailabilityStatus();
            }, 15 * 1000);
        },
        setupSounds: function setupSounds(sounds) {
            for (var key in sounds) {
                if (!sounds.hasOwnProperty(key)) {
                    continue;
                }
                this.set(key, sounds[key]);
            }
        },
        acceptCall: function acceptCall() {
            var _this5 = this;

            this.set('acceptCallRequestInProgress', true);
            var callId = this.get('callId');
            var apiUrl = Ember.ENV.getApiRoot();
            var url = apiUrl + ('/call/AdvisorAcceptCall?callId=' + callId);
            // const url = `http://localhost:3008/call/${callId}/accept`;

            var data = null;

            console.log('call accept request initiated!');
            Ember.$.ajax({
                url: url,
                type: 'POST',
                data: data
            }).done(function (result, textStatus, jqXHR) {
                var status = jqXHR.status;

                console.log('Result: ', result, textStatus, jqXHR);

                if (status === 200) {
                    _this5.toast.success('Call Accepted!');
                    _this5.set('callAccepted', true);
                }

                _this5.set('acceptCallRequestInProgress', false);
            }).fail(function (jqXHR) {
                var status = jqXHR.status;
                var message = jqXHR.responseJSON.message;

                console.log('Request failed: ', jqXHR, 'message: ', message);

                _this5.set('callAccepted', false);

                if (status === 409) {
                    //Conflict
                    _this5.toast.error(message || 'Call Already Accepted!');
                    _this5.set('callAccepted', true);
                } else if (status === 404) {
                    //Not Found
                    _this5.toast.error(message || 'Invalid Request!');
                } else if (status === 406) {
                    //
                    _this5.toast.error(message || 'Invalid Request!');
                } else if (status === 500) {
                    _this5.toast.error(message || 'Failed to accept!');
                } else {
                    _this5.toast.error('An error occured while sending accept call request', 'Accept Call Request Failed!');
                }
                _this5.set('acceptCallRequestInProgress', false);
            });
        },
        acceptChatRequest: function acceptChatRequest() {
            if (this.get('monitorClient').networIsDown) {
                alert('Your network connection is unstable. Please retry after sometime.');
                return;
            }
            this.log('acceptChatRequest');
            this.send('clearRequestTimeout');
            this.set('chatOnlineSubstatus', 'busy');

            this.send('openChatWindow');
        },
        openUrlInWindow: function openUrlInWindow(chatUrl, w, h, resize, enableScrollbars) {
            var left = screen.width / 2 - w / 2;
            var top = screen.height / 2 - h / 2;

            var windowParams = 'width=' + w + ',height=' + h + ',top=' + top + ',left=' + left + 'toolbar=no,location=no,directories=no,status=no,menubar=no,' + ('scrollbars=' + enableScrollbars + ',resizable=' + resize + ',copyhistory=no');

            this.log('openWindow with uri ' + chatUrl);

            var chatWindow = window.open(chatUrl, 'chatWindow', windowParams);

            chatWindow.focus();
        },
        openChatWindow: function openChatWindow() {
            this.log('openChatWindow');

            var chatRequest = this.get('chatRequest');
            var chatUrl = '#/chat/sessions/' + chatRequest.sessionId + '/advisor';

            //with code below we open new tab in browser with chat url
            if (_browser.browserName && _browser.browserName.toLowerCase().startsWith('ie')) {
                var w = 900;
                var h = 700;

                this.send('openUrlInWindow', chatUrl, w, h, 'yes', 'yes');
            } else {
                var anchor = document.createElement('a');

                anchor.href = chatUrl;
                anchor.target = '_blank';

                var evt = document.createEvent('MouseEvents');

                evt.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                anchor.dispatchEvent(evt);
            }
        },
        declineChatRequest: function declineChatRequest() {
            if (this.get('monitorClient').networIsDown) {
                alert('Your network connection is unstable. Please retry after sometime.');
                return;
            }
            this.log('declineChatRequest');
            this.send('clearRequestTimeout');
            this.get('monitorClient').declineChatRequest();
            this.set('status', 'online');
            this.set('chatOnlineSubstatus', 'listening');
        },
        showDecisionIsExpectedForm: function showDecisionIsExpectedForm() {
            this.set('chatOnlineSubstatus', 'decision-is-expected');
            this.log('chat - showDecisionIsExpectedForm');
        },
        onChatRequestMissed: function onChatRequestMissed() {
            this.log('onChatRequestMissed');
            this.send('clearRequestTimeout');
            this.set('requestTimeoutDate', new Date());

            this.get('monitorClient').missedChatRequest();
        },
        onChatMissedUpdateSuccess: function onChatMissedUpdateSuccess() {
            this.log('onChatMissedUpdateSuccess');
            this.set('status', 'offline');
            this.set('chatOnlineSubstatus', '');
            this.set('offlineReason', 'missed-chat');
            this.set('missedChat', true);
            this.checkDirectMessageEligibility(); //check if advisor can ping consumer.
            this.get('monitorClient').setUnavailable();
        },
        onChatMissedUpdateFailed: function onChatMissedUpdateFailed() {
            //This can happen when two chat monitors are open. Advisor accepts chat on one chat monitor, while the other monitor times out.
            this.log('onChatMissedUpdateFailed');
            this.set('status', 'online');
            this.set('chatOnlineSubstatus', 'listening');
        },
        onConnect: function onConnect() {
            if (this.get('chatDecisionIsExpected')) {
                //we don't need to change status if there is chat request
                this.log('onConnect - chatDecisionIsExpected');
                return;
            }
            this.log('onConnect');
            this.set('status', 'online');
            this.set('chatOnlineSubstatus', 'listening');

            this.get('audioNotifications').playConnectedAlert();

            this.set('missedChat', false);
            if (this.get('disconnected')) {
                this.set('offlineReason', '');
            }
        },
        onConnectCallMonitor: function onConnectCallMonitor() {
            this.set('callMonitorStatus', 'online');
            this.set('callMonitorOnlineSubstatus', 'listening');
            this.set('callStatusUpdateMessage', null);
            this.set('callId', null);
            this.set('missedChat', false); //clear missed chat message if shown.

            this.playSoundOnTakingCalls();
        },
        onDisconnectCallMonitor: function onDisconnectCallMonitor() {
            this.set('callMonitorStatus', 'offline');
            this.set('callMonitorOnlineSubstatus', '');
        },
        onChatRequestReceived: function onChatRequestReceived(request, consumerDetails) {
            var _this6 = this;

            var sessionId = request.sessionId;

            var showRequest = function showRequest(advisorWaitDuration, countdownAnimationDuration) {
                _this6.log('onChatRequestReceived');
                _this6.set('chatRequest', request);
                request.showNotification();
                _this6.set('consumerDetails', consumerDetails);
                _this6.set('canPingConsumer', false);
                _this6.set('advisorLockStatus', 'unlocked');

                if (_this6.get('callIsActive')) {
                    var callId = _this6.get('callId');

                    _this6.log('InitiateChat : ' + ('Attempt to initiate chat with Id: ' + sessionId + ' ; ') + ('During: active call with id: ' + callId));
                }

                _this6.set('requestTimeOutAfterSec', countdownAnimationDuration);
                _this6.set('initialTimerSeconds', countdownAnimationDuration);

                var updateTimeOutSeconds = setInterval(function () {
                    return _this6.decrementProperty('requestTimeOutAfterSec');
                }, 1000);

                _this6.set('updateTimeOutSeconds', updateTimeOutSeconds);

                var requestTimeout = setTimeout(function () {
                    clearInterval(updateTimeOutSeconds);
                    _this6.send('onChatRequestMissed');
                }, advisorWaitDuration * 1000);

                _this6.set('requestTimeout', requestTimeout);

                _this6.send('showDecisionIsExpectedForm');

                _this6.get('audioNotifications').playIncomingChatAlert();
            };

            this.store.findRecord('chatSession', sessionId).then(function (session) {
                var advisorWaitDuration = session.get('advisorWaitDuration');
                var countdownAnimationDuration = session.get('countdownAnimationDuration');

                showRequest(advisorWaitDuration, countdownAnimationDuration);

                session.set('status', 'AdvisorReceivedRequest');
                session.save().then(function () {
                    _this6.log('AdvisorReceivedRequest for ' + sessionId);
                });
            });
        },
        onCallStatusUpdateReceived: function onCallStatusUpdateReceived(msg, consumerDetails) {
            var _this7 = this;

            this.set('callStatusUpdateMessage', msg);
            this.set('missedChat', false); //clear missed chat message if shown.
            this.set('advisorLockStatus', 'unlocked');

            if (msg.CallStatusId === 2) {
                //Inited
                var callId = this.get('callId');

                this.set('callAccepted', false); //re-enable the accept call button

                if (callId !== null && callId !== msg.CallId && callId !== this.get('terminatedCallId')) {
                    //we need to to check was this call already rerminated by call termiante event
                    this.log('InitiateCall : ' + ('Attempt to initiate call with Id: ' + msg.CallId + ' ; ') + ('Active call: ' + callId + ' was not cleared'));
                }

                if (this.get('chatIsActive')) {
                    var chatRequest = this.get('chatRequest');
                    var sessionId = chatRequest.sessionId;

                    this.log('InitiateCall : ' + ('Attempt to initiate call with Id: ' + msg.CallId + ' ; ') + ('During: active chat with id: ' + sessionId));
                }

                if (callId !== msg.CallId) {
                    this.set('callMonitorOnlineSubstatus', 'inited');
                    this.set('lastCallStatusUpdateDate', new Date());
                    msg.showNotification();
                    this.set('consumerDetails', consumerDetails);

                    var showInitedCallStatusTimeoutSec = this.get('showInitedCallStatusTimeoutSec');

                    var showInitedCallStatusTimeoutId = setTimeout(function () {
                        return _this7.send('clearCallStatusUpdateMessage');
                    }, showInitedCallStatusTimeoutSec * 1000);

                    this.set('showInitedCallStatusTimeoutId', showInitedCallStatusTimeoutId);

                    this.get('audioNotifications').playIncomingCallAlert();
                }
                this.set('callId', msg.CallId);
            } else if (msg.CallStatusId === 3) {
                //Connected
                this.send('clearCallStatusUpdateMessage');
                this.set('callMonitorOnlineSubstatus', 'connected');
            } else if (msg.CallStatusId === 4 || msg.CallStatusId === 5) {
                //Terminated/Processed
                if (this.get('callMonitorOnlineSubstatus') === 'terminated') {
                    return;
                }

                //we need fire terminatedCall with small delay to avoid race conditions during NetworkUp event
                //it is important because we can receive 'terminatedCall' request before 'InitiateCall'
                Ember.run.later(function () {
                    //to avoid broke some existing UI we will not set callId to null reght after terminate event
                    // but we will set terminatedCallId property for avoiding incorrect call collision logging
                    _this7.set('terminatedCallId', msg.CallId);

                    _this7.send('clearCallStatusUpdateMessage');
                    _this7.set('callMonitorOnlineSubstatus', 'terminated');

                    var showTerminatedCallStatusTimeoutSec = _this7.get('showTerminatedCallStatusTimeoutSec');

                    var showTerminatedCallStatusTimeoutId = setTimeout(function () {
                        _this7.send('clearCallStatusUpdateMessage');
                        _this7.set('callMonitorOnlineSubstatus', 'listening');
                        _this7.set('callStatusUpdateMessage', null);
                        _this7.set('callId', null);
                    }, showTerminatedCallStatusTimeoutSec * 1000);

                    _this7.set('showTerminatedCallStatusTimeoutId', showTerminatedCallStatusTimeoutId);
                }, 1000);
            }
        },
        onLockStatusUpdateReceived: function onLockStatusUpdateReceived(msg) {
            this.log('onLockStatusUpdateReceived');
            if (this.get('isMobile') && msg.ConversationType === 2) {
                // ignore this message on mobile for chat
                return;
            }
            if (msg.IsLocked) {
                this.set('advisorLockStatus', 'locked');
                this.set('chatOnlineSubstatus', 'listening');
                this.set('callMonitorOnlineSubstatus', 'listening');
                if (msg.ConversationType == 2) {
                    this.set('conversationType', 'chat');
                } else {
                    this.set('conversationType', 'call');
                }
            } else {
                this.set('advisorLockStatus', 'unlocked');
            }
        },
        clearRequestTimeout: function clearRequestTimeout() {
            this.log('clearRequestTimeout');
            var requestTimeout = this.get('requestTimeout');

            if (requestTimeout) {
                clearTimeout(requestTimeout);
                this.log('requestTimeout cleared');
            }

            var updateTimeOutSeconds = this.get('updateTimeOutSeconds');

            if (updateTimeOutSeconds) {
                clearTimeout(updateTimeOutSeconds);
            }

            this.set('requestTimeOutAfterSec', this.get('initialTimerSeconds'));

            this.get('audioNotifications').stop();

            var chatRequest = this.get('chatRequest');

            if (chatRequest && chatRequest.closeNotification) {
                chatRequest.closeNotification();
            }
        },
        clearCallStatusUpdateMessage: function clearCallStatusUpdateMessage() {
            var showInitedCallStatusTimeoutId = this.get('showInitedCallStatusTimeoutId');

            if (showInitedCallStatusTimeoutId) {
                clearTimeout(showInitedCallStatusTimeoutId);
            }

            var showTerminatedCallStatusTimeoutId = this.get('showTerminatedCallStatusTimeoutId');

            if (showTerminatedCallStatusTimeoutId) {
                clearTimeout(showTerminatedCallStatusTimeoutId);
            }

            this.get('audioNotifications').stop();

            var callStatusUpdateMessage = this.get('callStatusUpdateMessage');

            if (callStatusUpdateMessage && callStatusUpdateMessage.closeNotification) {
                callStatusUpdateMessage.closeNotification();
            }
        },
        onChatEnded: function onChatEnded() {
            this.log('onChatEnded');
            this.send('clearRequestTimeout');
            this.set('status', 'online');
            this.set('chatOnlineSubstatus', 'listening');
        },
        onChatAccepted: function onChatAccepted() {
            if (this.get('chatDecisionIsExpected')) {
                this.log('onChatAccepted');
                this.send('clearRequestTimeout');
                this.set('chatOnlineSubstatus', 'busy');
            } else {
                this.log('skip onChatAccepted');
            }
        },
        onChatDeclined: function onChatDeclined() {
            if (this.get('chatDecisionIsExpected')) {
                this.log('onChatDeclined');
                this.send('clearRequestTimeout');
                this.set('chatOnlineSubstatus', 'listening');
            } else {
                this.log('skip onChatDeclined');
            }
        },
        onDisconnect: function onDisconnect(logOff) {
            if (this.get('chatDecisionIsExpected')) {
                //we don't need to change status if there is chat request
                return;
            }

            this.set('status', 'offline');
            this.set('chatOnlineSubstatus', '');

            if (logOff) {
                this.set('disconnectLogOff', true);
                this.set('offlineReason', 'disconnected');
                this.log('onDisconnectLogOff');
            } else {
                this.log('onDisconnect');
            }
        },
        clearReservedMessage: function clearReservedMessage() {
            this.set('advisorLockStatus', 'unlocked');
        }
    }), _Ember$Controller$ext));
});