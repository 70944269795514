define('ingenio-web/arrange-conversation/add-funds/controller', ['exports', 'ingenio-web/arrange-conversation/controller', 'ingenio-web/utils/logger'], function (exports, _controller, _logger) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _controller.default.extend({
        queryParams: ['listingid', 'pmode', 'webtrack', 'clickid', 'arrange', 'r', 'ctype'],

        arrangeConversation: Ember.inject.controller(),
        steps: Ember.computed.alias('arrangeConversation.steps'),

        log_source: 'add-funds-flow-details',

        enablePrimaryButton: Ember.computed('isBusy', 'isAddFundsAmountValid', 'model.isAddFundsRequired', 'model.user.additionalAmount', 'model.user.funds.additionalAmount', function () {
            if (!this.get('isAddFundsAmountValid') || this.get('isBusy')) {
                return false;
            }

            return this.get('model.user.funds.additionalAmount');
        }),

        hasSmsEnabled: Ember.computed('model.user.phoneNumbers', function () {
            var userPhones = this.get('model.user.phoneNumbers');
            var phoneWithSmsNotificationEnabled = userPhones.findBy('isSmsNotificationEnabled', true);

            return !!phoneWithSmsNotificationEnabled;
        }),

        userInAddFundsFlow: Ember.computed('model.isAddPaymentMethodRequired', 'model.hasExistingPhoneNumber', 'model.userInAddFundsFlow', function () {
            if (this.get('model.userInAddFundsFlow') || !this.get('model.hasExistingPhoneNumber')) {
                return true;
            }
            return false;
        }),

        roundedAmountToCharge: Ember.computed('model.amountToCharge', function () {
            return (this.get('model.amountToCharge') || 0).toFixed(2);
        }),

        initialize: function initialize() {
            this._super.apply(this, arguments);
            if (!this.get('model.user.funds.additionalAmount')) {
                var minimumDepositAmount = 10;

                this.set('model.user.funds.additionalAmount', minimumDepositAmount);
                this.set('model.user.additionalAmount', minimumDepositAmount);
            }

            var loggerOptions = {
                consumerId: Ember.Application.user.id,
                source: 'arrange-conversation-add-funds'
            };
            var logger = new _logger.default(this.store, loggerOptions);

            this.set('logger', logger);
        },


        actions: {
            onFundsAmountChanged: function onFundsAmountChanged(amount) {
                this.set('model.user.funds.additionalAmount', amount);
                this.set('model.user.additionalAmount', amount);
            },
            onPrimaryButtonClick: function onPrimaryButtonClick() {
                var _this = this;

                this.clearErrors();

                var user = Ember.Application.user;

                this.set('isBusy', true);
                if (user.isAvsReqd) {
                    var selectedCreditCard = this.get('model.paymentMethodSelected');

                    //shoud we consider case when user enter cvcode and reopen ConfirmDepositDialog???
                    var recheckAVSOnCancel = this.get('avsModel.recheckAVS') && this.get('avsModel.recheckAVS') == true;
                    if (recheckAVSOnCancel || !this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
                        this.showConfirmDepositDialog(user.isAvsReqd);

                        //reset recheck state.
                        var avsModel = this.get('avsModel');
                        if (avsModel) {
                            avsModel.set('recheckAVS', false);
                        }

                        return;
                    }
                }

                var amountToBeAdded = this.get('model.user.additionalAmount');

                var transitToNextStep = function transitToNextStep() {
                    if (_this.get('model.userInAddFundsFlow') && _this.get('model.returnRoute')) {
                        var route = _this.get('model.returnRoute');

                        _this.transitionToRouteWithCurrentParams(route);
                        _this.set('isBusy', false);
                    } else if (!_this.get('model.hasExistingPhoneNumber')) {
                        var _route = _this.getParentRouteName() + '.details';

                        _this.transitionToRouteWithCurrentParams(_route);
                        _this.set('isBusy', false);
                    } else if (_this.get('model.isAddPaymentMethodRequired')) {
                        _this.set('isBusy', false);
                        // user need to add payment method
                        _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.payment-details');
                    } else if (_this.get('model.isSmsPageRequired')) {
                        _this.set('isBusy', false);
                        _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.sms');
                    } else if (_this.get('model.canArrangeConversation')) {
                        // user has all required criteria met to arrange the conversation
                        _this.arrangeConversationRequest().then(function (arrangedCall) {
                            _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.confirmation');
                        }).catch(function (error) {
                            var route = _this.getParentRouteName() + '.details';

                            _this.transitionToRouteWithCurrentParams(route);
                        }).finally(function () {
                            _this.set('isBusy', false);
                        });
                    } else {
                        var _route2 = _this.getParentRouteName() + '.details';

                        _this.transitionToRouteWithCurrentParams(_route2);
                        _this.set('isBusy', false);
                    }
                };

                if (amountToBeAdded == 0) {
                    transitToNextStep();
                    return;
                }

                this.addFundsToAccount().then(function () {
                    var userBalance = _this.get('model.balanceAmountRounded');
                    var message = '\n            <div class="alert-icon"><i class="fal fa-check-circle"></i></div>\n            <div class="message-content">\n              <div class="message-content-header"><b>Success!</b></div> \n              <div class="message-content-item"> You added $' + amountToBeAdded + '.</div> \n              <div class="message-content-item"> Your balance is now $' + userBalance + '.</div>\n            </div>';

                    _this.get('model.notifications.success').push(message);
                    transitToNextStep();
                }).catch(function (error) {
                    if (error.code == "CvnFailed" || error.code == "BothCvnAvsFailed") {
                        user.isCvnReqd = true;
                        _this.set('model.paymentMethodSelected.cvvCode', null);
                    }
                    _this.set('isBusy', false);
                    _this.handleError(error);
                    _this.set('primaryButtonBusy', false);
                    _this.get('logger').error('Problem in adding funds. userId=' + user.id, {
                        originalError: error
                    });
                });
            },
            cancelConfirmDeposit: function cancelConfirmDeposit(creditCard) {
                this.set('isBusy', false);
                this.set('_showConfirmDepositDialog', false);
                creditCard.clearTouched();
                creditCard.removeErrors();
                this.set('avsModel.cvvCode', '');
                creditCard.set('recheckAVS', true); // required for handling avs modal cancel button click.
            },
            avscvnContinueConfirmDeposit: function avscvnContinueConfirmDeposit(creditCard) {
                var _this2 = this;

                this.set('model.paymentMethodSelected.cvvCode', this.get('avsModel.cvvCode'));
                this.set('model.user.funds.cvvCode', this.get('avsModel.cvvCode'));
                this.set('model.paymentMethodSelected.address', this.get('avsModel.address'));

                if (creditCard.get('isAvsRequired') || creditCard.get('isCvnRequired')) {
                    // we need to update card details (address, name, etc)
                    // if AVS or CVN is required

                    // set flag that card is under processing
                    creditCard.set('inProcess', true);
                    // remove previous server side errors
                    creditCard.clearGenericErrors();

                    creditCard.save().then(function () {
                        // reset indicator
                        creditCard.set('inProcess', false);

                        _this2.set('_showConfirmDepositDialog', false);
                        _this2.send('onPrimaryButtonClick');
                    }, function (error) {
                        creditCard.handleError(error);
                    });
                } else {
                    // submit CVN code to add money function
                    this.set('_showConfirmDepositDialog', false);
                    this.send('onPrimaryButtonClick');
                }
            },
            changePaymentMethod: function changePaymentMethod() {
                this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.payment-details');

                this.scrollToElementOnMobileViewWithDelay('.payment-details-container');
            }
        }
    });
});