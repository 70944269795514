define("ingenio-web/utils/date-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var sameDay = function sameDay(date, anotherDate) {
        return date.getFullYear() === anotherDate.getFullYear() && date.getMonth() === anotherDate.getMonth() && date.getDate() === anotherDate.getDate();
    };

    exports.sameDay = sameDay;
});