define('ingenio-web/components/price-input', ['exports', 'ingenio-web/mixins/validatable-model'], function (exports, _validatableModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatableModel.default, {
        min: 0,
        max: 9999,

        helpText: function () {
            return 'Our suggested  ' + (this.get('displaySessionRate') ? 'per-session' : 'per-minute') + ' fee is $' + this.get('defaultValue') + '. You may choose a higher or lower fee, as long as it is at least  $' + (this.get('displaySessionRate') ? this.get('minSessionRate') : this.get('minMinuteRate')) + ' for US listings.';
        }.property('defaultValue', 'minMinuteRate', 'displaySessionRate', 'minSessionRate'),

        cssClass: function () {
            return this.get('inputClass') || 'form-control';
        }.property('inputClass')
    });
});