define('ingenio-web/chat-setup/payment-details/route', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/route', 'ingenio-web/mixins/checkout-flow/conversation-payment-method-route-check'], function (exports, _route, _conversationPaymentMethodRouteCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend(_conversationPaymentMethodRouteCheck.default, {
    actions: {
      didTransition: function didTransition() {
        this.get('mixpanelClient').trackNewEvent('Chat Setup - Payment Details Viewed');
      }
    }
  });
});