define('ingenio-web/components/payment-method/item/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['payment-method-item-container'],

		cardExpired: Ember.computed('attrs.expiration', function () {
			var expiration = this.get('expiration');
			var month = expiration.slice(0, 2);
			var year = expiration.slice(-2);
			var expirationDate = new Date('20' + year, month, 0);
			var todayDate = new Date();

			if (todayDate.getTime() > expirationDate.getTime()) {
				return true;
			}
			return false;
		})
	});
});