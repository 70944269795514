define('ingenio-web/controllers/chat/session', ['exports', 'ingenio-web/objects/chat/message'], function (exports, _message) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var MY_ID = Math.round(Math.random() * 100);
    //var advisor_ID = Math.round(Math.random() * 100);

    exports.default = Ember.Controller.extend({
        messages: null,

        monitorChannelNamePr: function () {
            var newChannelName = this.get('model.monitorChannelName');

            return {
                monitorChannelNameNew1: newChannelName,
                monitorChannelNameNew2: 'sandeepTest'
            };
        }.property('model.monitorChannelName'),

        setup: function () {
            this.set('messages', Ember.A());
        }.on('init'),

        actions: {
            messageSent: function messageSent(text) {
                var newMessage = _message.default.create({
                    avatarUrl: 'http://api.adorable.io/avatars/85/' + MY_ID + '.png',
                    text: text,
                    sentAt: new Date(),
                    isMine: true
                });

                this.get('messages').addObject(newMessage);
            }
        }
    });
});