define('ingenio-web/models/listing-draft', ['exports', 'ingenio-web/models/base/listing-base', 'ember-data/attr', 'ingenio-web/helpers/platform-domain'], function (exports, _listingBase, _attr, _platformDomain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listingBase.default.extend({
    // 0 is the default, "Select Category" value
    categoryId: (0, _attr.default)('number', { defaultValue: 0 }),

    minuteRate: (0, _attr.default)('number', { defaultValue: 3.99 }),
    requiredFieldsToSave: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return ['categoryId', 'title', 'advisorName', 'minuteRate', 'primaryPhoneNumber', 'hourlySessionRate'];
      }
    }),
    isKeen: (0, _platformDomain.platformIsKeen)(),

    canSaveDraft: function () {
      var _this = this;

      // check that listing has all required fields to be saved as draft
      // and the listing is dirty
      var valid = true;
      var categoryId = this.get('categoryId');

      if ([17011, 17101].includes(categoryId)) {
        this.get('requiredFieldsToSave').forEach(function (field) {
          if (field === 'minuteRate') {
            valid = valid;
          } else {
            valid = valid && !_this.hasError(field);
          }
        });
      } else {
        this.get('requiredFieldsToSave').forEach(function (field) {
          if (field === 'hourlySessionRate') {
            valid = valid;
          } else {
            valid = valid && !_this.hasError(field);
          }
        });
      }

      return valid && this.get('isDirty');
    }.property('validationErrors.length', 'isDirty', 'model.categoryId'),

    disableSaveDraft: function () {
      return !this.get('canSaveDraft');
    }.property('canSaveDraft')
  });
});