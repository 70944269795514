define('ingenio-web/services/experimentation', ['exports', 'ingenio-web/utils/logger', 'ingenio-web/utils/cookies'], function (exports, _logger, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var USER_UID_PROP = 'user_uid';
    var ACTIVE_BUCKETS_PROP = 'active_buckets';
    var EXPERIMENTS_COOKIE = 'platform_experiment';

    var getActiveBuckets = function getActiveBuckets() {
        var activeBuckets = _cookies.default.getGroupCookieUnescaped(EXPERIMENTS_COOKIE, ACTIVE_BUCKETS_PROP);
        var activeBucketsCollection = activeBuckets && activeBuckets.length ? activeBuckets.split(',') : [];

        return activeBucketsCollection.map(Number);
    };

    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),

        buckets: [],
        mergedSettings: {},
        mergedProperties: {},

        experimentOffer: null,

        init: function init() {
            this.set('logger', new _logger.default(this.get('store'), {
                source: 'experimentation-service'
            }));
        },
        syncStore: function syncStore() {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve) {
                var userUid = _cookies.default.getGroupCookieUnescaped(EXPERIMENTS_COOKIE, USER_UID_PROP);

                var handleError = function handleError(error) {
                    if (error && error.errors && error.errors.length > 0) {
                        var err = error.errors[0];
                        if (err.hasOwnProperty('status') && err.status !== "401" || !err.hasOwnProperty('status')) {
                            _this.get('logger').error('Experimentation : An error occurred when fetching experiment metadata. uid=' + userUid, {
                                consumerUid: userUid,
                                originalError: error
                            });
                        }
                    }

                    resolve({});
                };

                try {
                    var allocations = _this.get('store').createRecord('experimentBucket', {
                        domainId: Ember.ENV.domainId,
                        activeBuckets: getActiveBuckets(),
                        tempUid: window.ENV.keenUid
                    });

                    allocations.save().then(function (allocationsResult) {
                        _this.set('buckets', allocationsResult.get('activeBuckets'));
                        _this.set('mergedSettings', allocationsResult.get('mergedSettings'));
                        _this.set('experimentOffer', allocationsResult.get('experimentOffer'));
                        _this.set('mergedProperties', allocationsResult.get('mergedProperties'));

                        resolve(allocationsResult);
                    }).catch(handleError);
                } catch (error) {
                    handleError(error);
                }
            });
        }
    });
});