define('ingenio-web/routes/dev', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        actions: {
            didTransition: function didTransition() {
                this.send('showOfferRibbon', { text: 'Get your code reviewed today for just $8.88 per line. ' });
            }
        }
    });
});