define('ingenio-web/utils/chat-duration-manager', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var ChatDurationManager = function () {
        function ChatDurationManager(options) {
            _classCallCheck(this, ChatDurationManager);

            this.options = {
                //(availableTime - warnTimeOffsetSeconds) is when recharge warning is issued
                warnTimeOffsetSeconds: options.warnTimeOffsetSeconds || 60,
                postpayOffsetSeconds: options.postpayOffsetSeconds || 20,
                postTimeOutSeconds: options.postTimeOutSeconds || 150, //this is max time allowed for consumer to recharge
                callbacks: options.callbacks || {
                    postPayTimeout: null,
                    sessionTimeoutWarning: null,
                    sessionTimeout: null,
                    postSessionTimeout: null
                }
            };
            this._timeoutHandle = null;
            this._postPayExtensionHandle = null;
            this._postSessionTimeoutHandle = null;
            this._startDate = null;
            this._durationUntilLastPause = 0;
            this._paused = true;
            this._availableTimeSeconds = 0;
        }

        _createClass(ChatDurationManager, [{
            key: 'start',
            value: function start(availableTimeSeconds) {
                console.warn('start ' + availableTimeSeconds);
                if (!this._paused) {
                    console.warn('Warning! ChatDurationManager was not paused.');
                    return;
                }
                this._availableTimeSeconds = availableTimeSeconds;
                this._paused = false;
                this._startDate = new Date().getTime() / 1000;

                if (this._timeoutHandle) {
                    clearTimeout(this._timeoutHandle);
                }
                if (this._postSessionTimeoutHandle) {
                    clearTimeout(this._postSessionTimeoutHandle);
                }
                if (this._postPayExtensionHandle) {
                    clearTimeout(this._postPayExtensionHandle);
                }
                if (availableTimeSeconds < this.options.postpayOffsetSeconds) {
                    //available time is less than postpayOffsetSeconds seconds. So, trigger postpay extension immediately.
                    this._postPayExtensionHandle = setTimeout(this._onPostPayTimeout.bind(this), 0);
                } else {
                    var postPaySessionExtensionTime = availableTimeSeconds - this.options.postpayOffsetSeconds;

                    this._postPayExtensionHandle = setTimeout(this._onPostPayTimeout.bind(this), postPaySessionExtensionTime * 1000);
                    // console.log(`PostPay extension will be triggered in ${postPaySessionExtensionTime} seconds from now at ${moment(new Date()).add(postPaySessionExtensionTime, 'seconds').format('LTS')}`);
                }

                if (availableTimeSeconds < this.options.warnTimeOffsetSeconds) {
                    this._timeoutHandle = setTimeout(this._onSessionTimeout.bind(this), availableTimeSeconds * 1000);
                } else {
                    var warnTime = availableTimeSeconds - this.options.warnTimeOffsetSeconds;

                    this._timeoutHandle = setTimeout(this._onWarnTimeOut.bind(this), warnTime * 1000);
                }
            }
        }, {
            key: 'pause',
            value: function pause() {
                console.warn('pause');
                if (this._paused) {
                    console.warn('Warning! ChatDurationManager was already paused.');
                    return;
                }
                this._paused = true;
                clearTimeout(this._timeoutHandle);

                var now = new Date().getTime() / 1000;

                this._durationUntilLastPause = this._durationUntilLastPause + now - this._startDate;
            }
        }, {
            key: 'resume',
            value: function resume() {
                console.warn('resume');
                if (!this._paused) {
                    console.warn('Warning! ChatDurationManager was not paused.');
                    return;
                }
                var remainingTime = this._availableTimeSeconds - this._durationUntilLastPause;

                this.start(remainingTime);
            }
        }, {
            key: 'stop',
            value: function stop() {
                console.warn('stop');
                if (!this._paused) {
                    this.pause();
                }
                if (this._postSessionTimeoutHandle) {
                    clearTimeout(this._postSessionTimeoutHandle);
                }
                if (this._postPayExtensionHandle) {
                    clearTimeout(this._postPayExtensionHandle);
                }
            }
        }, {
            key: 'duration',
            value: function duration() {
                if (this._paused) {
                    return this._durationUntilLastPause;
                }

                var now = new Date().getTime() / 1000;

                return this._durationUntilLastPause + now - this._startDate;
            }
        }, {
            key: 'durationFormatted',
            value: function durationFormatted() {
                var seconds = Math.floor(this.duration());
                var minutes = Math.floor(seconds / 60);
                var hours = Math.floor(minutes / 60);

                seconds %= 60;
                minutes %= 60;
                hours %= 24;

                if (hours === 0) {
                    return this._padLeft(minutes) + ':' + this._padLeft(seconds);
                }

                return this._padLeft(hours) + ':' + this._padLeft(minutes) + ':' + this._padLeft(seconds);
            }
        }, {
            key: 'isPaused',
            value: function isPaused() {
                return this._paused === true;
            }
        }, {
            key: '_onPostPayTimeout',
            value: function _onPostPayTimeout() {
                if (this.options.callbacks.postPayTimeout) {
                    this.options.callbacks.postPayTimeout();
                }
            }
        }, {
            key: '_onWarnTimeOut',
            value: function _onWarnTimeOut() {
                var sessionTimeoutWarning = this.options.callbacks.sessionTimeoutWarning;

                if (sessionTimeoutWarning) {
                    sessionTimeoutWarning();
                }

                this._timeoutHandle = setTimeout(this._onSessionTimeout.bind(this), this.options.warnTimeOffsetSeconds * 1000);
            }
        }, {
            key: '_onSessionTimeout',
            value: function _onSessionTimeout() {
                var sessionTimeout = this.options.callbacks.sessionTimeout;

                if (sessionTimeout) {
                    sessionTimeout();
                }

                this._postSessionTimeoutHandle = setTimeout(this._onPostSessionTimeout.bind(this), this.options.postTimeOutSeconds * 1000);
            }
        }, {
            key: '_onPostSessionTimeout',
            value: function _onPostSessionTimeout() {
                var postSessionTimeout = this.options.callbacks.postSessionTimeout;

                if (postSessionTimeout) {
                    postSessionTimeout();
                }
            }
        }, {
            key: '_padLeft',
            value: function _padLeft(time) {
                return time < 10 ? '0' + time : time;
            }
        }]);

        return ChatDurationManager;
    }();

    exports.default = ChatDurationManager;
});