define('ingenio-web/components/listings/offer-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        longOfferMessage: function () {
            return this.get('offerPrice.OfferPitch').replace('Get ', '');
        }.property('offerPrice.OfferPitch'),

        shortOfferMessage: function () {
            var offerPitch = this.get('offerPrice.OfferPitch');

            return offerPitch.replace('Minutes', 'Min').replace('Get ', '');
        }.property('offerPrice.OfferPitch')
    });
});