define('ingenio-web/controllers/myaccount/listings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        selectedDraftListing: null,

        _showDeleteDraftConfirmationDialog: false,
        _showRejectionDetailsPopup: false,
        showDeleteDraftConfirmationDialog: Ember.computed.equal('_showDeleteDraftConfirmationDialog', true),

        hasDraftListings: function () {
            return this.get('model.draftListings.length') > 0;
        }.property('model.draftListings'),

        isKeen: Ember.computed('Ember.ENV.domainId', function () {
            return Ember.ENV.domainId === 1;
        }),

        actions: {
            confirmDeleteDraft: function confirmDeleteDraft(listingId) {
                var draftListing = this.get('model.draftListings').find(function (item) {
                    return item.id === listingId;
                });

                this.set('selectedDraftListing', draftListing);
                this.set('_showDeleteDraftConfirmationDialog', true);
            },
            deleteDraftListing: function deleteDraftListing() {
                var _this = this;

                this.get('selectedDraftListing').destroyRecord().then(function () {
                    _this.send('refreshDraftListings');
                    _this.set('_showDeleteDraftConfirmationDialog', false);
                });
            },
            cancelDeleteDraftListing: function cancelDeleteDraftListing() {
                this.set('_showDeleteDraftConfirmationDialog', false);
                this.set('selectedDraftListing', null);
            },
            toggleisApproved: function toggleisApproved(item, newvalue) {
                item.set('isApproved', newvalue);
                this.send('updateListing', item);
            },
            showRejectionDetails: function showRejectionDetails(listingId) {
                var listing = this.get('model.listings').find(function (item) {
                    return item.id === listingId;
                });

                this.set('selectedListing', listing);
                this.set('_showRejectionDetailsPopup', true);
            },
            closeRejectionDetails: function closeRejectionDetails() {
                this.set('selectedListing', null);
                this.set('_showRejectionDetailsPopup', false);
            }
        }
    });
});