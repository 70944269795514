define('ingenio-web/components/advisor-availability-status-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        avatarUrl: null,
        advisorName: null,
        busy: false
    });
});