define('ingenio-web/components/advisor-card/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var OfferType = Object.freeze({
        None: 0,
        DiscountedServiceRate: 1,
        RegularOffer: 2,
        Discount: 6
    });

    exports.default = Ember.Component.extend({
        offerType: null,
        offerText: null,
        avatarUrl: null,
        hidePrice: false,
        advisorName: null,
        roundedRating: null,
        averageRating: null,
        pricePerMinute: null,
        numberOfReviews: null,
        preferredCurrency: '$',

        discountedServiceRateOffer: Ember.computed('offerType', function () {
            return this.get('offerType') === OfferType.DiscountedServiceRate;
        }),

        pricePerMinuteDisplayText: Ember.computed('pricePerMinute', function () {

            var pricePerMinuteFixed = (this.get('pricePerMinute') * 1).toFixed(2);

            var preferredCurrency = this.get('preferredCurrency');
            return '' + preferredCurrency + pricePerMinuteFixed;
        }),

        offerDisplayText: Ember.computed('offerType', 'offerText', 'discountedServiceRateOffer', function () {
            var offerType = this.get('offerType');

            switch (offerType) {
                case 1:
                    return this.get('offerText');

                case 2:
                case 6:
                    return !this.get('discountedServiceRateOffer') ? this.get('offerText') : '';

                default:
                    return '';
            }
        }),

        strikePriceText: Ember.computed('offerType', function () {
            return this.get('offerType') === OfferType.RegularOffer || this.get('offerType') === OfferType.Discount;
        })
    });
});