define('ingenio-web/helpers/platform-domain', ['exports', 'ingenio-web/constants/domain'], function (exports, _domain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.platformIsKeen = platformIsKeen;
    function platformIsKeen() {
        return Ember.ENV.domainId === _domain.default.KEEN;
    }

    exports.default = Ember.Helper.helper(platformIsKeen);
});