define('ingenio-web/add-funds/route', ['exports', 'ingenio-web/models/add-funds-flow'], function (exports, _addFundsFlow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    experimentation: Ember.inject.service('experimentation'),
    model: function model(params, transition) {
      var _this = this;

      var user = Ember.Application.user;
      var returnUrl = transition.queryParams.returnUrl;

      return Ember.RSVP.hash({
        returnUrl: returnUrl,
        countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
        balance: this.store.findRecord('balance', user.id, {
          adapterOptions: {}
        }),
        paymentMethods: this.store.findAll('paymentMethod')
      }).then(function (payload) {
        return _this.transformPromiseHashIntoViewModel(payload);
      });
    },
    getRepeatUserMinimumDepositAmount: function getRepeatUserMinimumDepositAmount() {
      var mergedExperimentProps = this.get('experimentation.mergedProperties');

      if (mergedExperimentProps.minimumDepositAmount > 0) {
        return mergedExperimentProps.minimumDepositAmount;
      }
      return Ember.ENV.repeatUserMinimumDepositAmount;
    },
    transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
      var fundsModel = this.store.createRecord('funds');

      // use the default payment method, by default, if found.
      // handle a weird edge case where the user does not have any primary
      // payment methods and choose the first object by default.
      if (!Ember.isEmpty(payload.paymentMethods)) {
        var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
          return method.get('isPrimary');
        }) || payload.paymentMethods.get('firstObject');

        fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
      }

      return _addFundsFlow.default.create({
        domain: {
          id: Ember.ENV.domainId,
          name: Ember.ENV.domainName
        },
        user: {
          funds: fundsModel,
          balance: payload.balance,
          paymentMethods: payload.paymentMethods
        },
        returnUrl: payload.returnUrl,
        countries: payload.countries,
        repeatUserMinimumDepositAmount: this.getRepeatUserMinimumDepositAmount(),

        // TODO: hack to skip fees for add funds flow until we revisit
        skipFees: true
      });
    }
  });
});