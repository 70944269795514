define('ingenio-web/components/featured-listings/results/bid-results-time-period', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        lookbackMonths: null,
        timePeriodsCumulative: null,
        sortProps: ['year:desc', 'month:desc'],

        timePeriodsMonths: Ember.computed('lookbackMonths', function () {
            var timePeriodMonths = [];
            var endDate = (0, _moment.default)();
            var startDate = (0, _moment.default)().subtract(this.get('lookbackMonths'), 'months');

            while (endDate > startDate) {
                timePeriodMonths.pushObject(Ember.Object.create({
                    timePeriod: 'month',
                    timePeriodText: startDate.format('MMMM, YYYY'),
                    month: parseInt(startDate.format('M')),
                    year: parseInt(startDate.format('YYYY'))
                }));

                startDate.add(1, 'months');
            }

            return timePeriodMonths;
        }),

        timePeriodsMonthsSorted: Ember.computed.sort('timePeriodsMonths', 'sortProps'),

        timePeriods: Ember.computed('lookbackMonths', 'timePeriodsCumulative.@each.timePeriod', function () {
            var timePeriodsCumulative = this.get('timePeriodsCumulative');
            var timePeriodsMonthsSorted = this.get('timePeriodsMonthsSorted');

            return timePeriodsCumulative.concat(timePeriodsMonthsSorted);
        }).readOnly(),

        init: function init() {
            this.set('timePeriodsCumulative', [Ember.Object.create({
                timePeriod: 'current-year',
                timePeriodText: 'Year To Date'
            }), Ember.Object.create({
                timePeriod: 'current-quarter',
                timePeriodText: 'Quarter To Date'
            }), Ember.Object.create({
                timePeriod: 'month',
                timePeriodText: 'Month To Date',
                month: (0, _moment.default)().format('M'),
                year: (0, _moment.default)().format('YYYY')
            })]);

            this._super();
        },


        actions: {
            setTimePeriod: function setTimePeriod(selectedIndex) {
                var timePeriod = this.get('timePeriods')[selectedIndex];

                this.sendAction('action', timePeriod);
            }
        }
    });
});