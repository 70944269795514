define('ingenio-web/components/editable-list/component', ['exports', 'ingenio-web/mixins/validatable', 'ingenio-web/helpers/platform-domain'], function (exports, _validatable, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatable.default, {
        required: false,
        popoverVisible: false,
        maxSelection: 10,
        isKeen: (0, _platformDomain.platformIsKeen)(),

        classNameBindings: ['getClassNames'],
        getClassNames: Ember.computed('required', 'maxSelection', 'selectedItems', function () {
            var required = this.get('required');
            var maxSelection = this.get('maxSelection');
            var valuesSelected = this.get('selectedItems').length;

            var cssClasses = 'hvr-fade editable-list';

            if (required && valuesSelected < maxSelection) {
                cssClasses += ' invalid';
            }

            return cssClasses;
        }),

        hasPopoverBeenOpened: false,

        setup: function () {
            this.set('selectedItems', this.get('selectedItems') || []);
            this.set('masterListItems', this.get('masterListItems') || []);

            if (this.get('masterListItems').length < this.get('maxSelection')) {
                this.set('maxSelection', this.get('masterListItems').length);
            }
        }.on('init'),

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.initializePopover();
            this.bindEvents();

            //note restuta: adding observer it's here and not on function level, since it breaks unit test other way
            this.addObserver('selectedItems.length', this.validate);
            this.addObserver('categoryId', function () {
                $('.popover-tools .editable-list-content').popover('hide');
                $('.popover-specialities .editable-list-content').popover('hide');
                $('.popover-skills .editable-list-content').popover('hide');
                $('.popover-language .editable-list-content').popover('hide');

                _this.clearList();
            });
        },

        initializePopover: function initializePopover() {
            var self = this;

            this.$('.editable-list-content').popover({
                title: function title() {
                    return self.$('.editable-list-popover-title').html();
                },
                content: function content() {
                    return self.$('.editable-list-popover-content').html();
                },
                placement: 'top',
                trigger: 'manual',
                html: true
            });
            this.updateSelectedCount();
        },

        //todo restuta: we need to make popover hidden by default with 'hidden' class, this will allow bindings to work
        //todo restuta: make popover separate component
        //todo restuta: make checkboxes to use {{input type="checkbox"}}
        bindEvents: function bindEvents() {
            var self = this;
            /*
             Since checkbox content within the popover will not be present while defining the popover, we need to
             bind events to the container of the popover.
             */
            //todo restuta: ember binding is not fully working with popover content and idk why
            if (this.maxSelection > 1) {
                this.$().on('change', '.popover input[type="checkbox"]', function (e) {
                    self.onItemChanged(self.$(e.target));
                });
            } else {
                this.$().on('change', '.popover input[type="radio"]', function (e) {
                    $('.popover input[type="radio"]').each(function () {
                        if (!self.$(this).is(self.$(e.target))) {
                            self.$(this).prop('checked', false);
                        }
                    });

                    self.onItemChanged(self.$(e.target));
                });
            }
        },

        onItemChanged: function onItemChanged($element) {
            var itemId = parseInt($element.attr('data-id'));

            if (this.maxSelection > 1) {
                if ($element.is(':checked')) {
                    this.addItemToList(itemId);
                } else {
                    this.removeItemFromList(itemId);
                }
            } else {
                this.clearList();
                this.addItemToList(itemId);
            }

            this.updateSelectedCount();
            this.checkIfMaxSelectionReached();
        },

        thereAreSelectedItems: function () {
            return this.get('selectedItems') && this.get('selectedItems').length > 0;
        }.property('selectedItems.length'),

        computedListItems: function () {
            //This property creates a new list by merging the selectedItems & masterListItems and adds a new property "isSelected"
            // for each item present in selectedItems list
            var self = this;
            var computedList = this.get('masterListItems').copy( /* deep: */true);

            var itemIsAmongSelectedItems = function itemIsAmongSelectedItems(item) {
                return self.get('selectedItems').any(function (selectedItem) {
                    return selectedItem.id === item.id;
                });
            };

            computedList.forEach(function (computedItem) {
                if (itemIsAmongSelectedItems(computedItem)) {
                    computedItem.isSelected = true;
                }
            });

            return computedList;
        }.property('selectedItems.[]', 'masterListItems'),

        firstSelectedItem: function () {
            var computedItems = this.get('computedListItems').filter(function (item) {
                return item.isSelected === true;
            });
            if (computedItems.length > 0) {
                return computedItems[0];
            }

            return {};
        }.property('computedListItems'),

        actions: {
            togglePopover: function togglePopover() {
                if (!this.get('hasPopoverBeenOpened')) {
                    this.set('hasPopoverBeenOpened', true);
                }

                this.$('.editable-list-content').popover('toggle');

                this.checkIfMaxSelectionReached();
                this.validate();
            },
            doneClicked: function doneClicked() {
                this.$('.editable-list-content').popover('hide');
            }
        },

        addItemToList: function addItemToList(itemId) {
            /* If the selected checkbox value not in the selected list - add the item to the selectedItems list
             * Note: Since the selectedItems is a bound attribute for <li> items, ember automatically adds/removes the
             * corresponding DOM element based on the list values */
            if (!this.itemHasAlreadyBeenSelected(itemId)) {
                this.get('selectedItems').pushObject({
                    id: itemId
                });
                this.sendAction('onEditListChanged', true);
            }
        },

        removeItemFromList: function removeItemFromList(itemId) {
            var itemToBeRemoved = null;

            /* If the unchecked checkbox value PRESENT in the selected list,
             *  - Remove the item from the selectedItems list
             * Note: Since the selectedItems is a bound attribute for <li> items, ember automatically adds/removes the
             * corresponding DOM element based on the list values */
            this.get('selectedItems').forEach(function (item) {
                if (item.id === itemId) {
                    itemToBeRemoved = item;
                    return;
                }
            });

            if (itemToBeRemoved) {
                this.get('selectedItems').removeObject(itemToBeRemoved);
                this.sendAction('onEditListChanged', false);
            }
        },

        clearList: function clearList() {
            this.get('selectedItems').clear();
        },

        //todo restuta: since data binding doesn't work in popover we have to do all updates manually
        updateSelectedCount: function updateSelectedCount() {
            this.$().find('.popover span.selected-count').first().text(this.get('selectedItems').length);
        },

        checkIfMaxSelectionReached: function checkIfMaxSelectionReached() {
            if (this.maxSelection > 1) {
                var $notCheckedItems = this.$().find('.popover input:not(:checked)');

                if (this.get('selectedItems').length === this.maxSelection) {
                    //If the number of selected checkboxes match the maxSelection, then disable the non-selected checkboxes
                    $notCheckedItems.attr('disabled', 'disabled').parents('.checkbox').addClass('disabled');
                } else if (this.get('selectedItems').length < this.maxSelection) {
                    //If the number of selected checkboxes is less the maxSelection, then re-enable the non-selected checkboxes
                    $notCheckedItems.removeAttr('disabled', 'disabled').parents('.checkbox').removeClass('disabled');
                }
            }
        },

        itemHasAlreadyBeenSelected: function itemHasAlreadyBeenSelected(itemId) {
            return this.get('selectedItems').any(function (item) {
                return item.id === itemId;
            });
        },

        validate: function validate() {
            var $btnDone = this.$('.popover-btn-done');

            var $validationMessage = this.$('.validation-message');

            if (this.get('selectedItems').length === 0 && this.get('hasPopoverBeenOpened')) {
                var validationMessage = this.maxSelection > 1 ? 'This field is required. Select at least one item.' : 'This field is required';

                this.setInvalid(validationMessage);

                $btnDone.addClass('disabled');
                $validationMessage.text(validationMessage);
            } else {
                this.setValid();
                $btnDone.removeClass('disabled');
                $validationMessage.text('');
            }
        }
    });
});