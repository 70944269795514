define('ingenio-web/services/google-analytics-client', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            if (window.ga) {
                this.tracker = window.ga.create(Ember.ENV.gaProfile, 'auto');
            }
        },
        trackEvent: function trackEvent(eventName, eventData) {
            if (!this.tracker) {
                this.init();
            }

            if (this.tracker) {
                this.tracker.send(eventName, eventData);
            }
        },
        trackPageview: function trackPageview(page) {
            if (!this.tracker) {
                this.init();
            }

            if (this.tracker) {
                this.tracker.send('pageview', page);
            }
        }
    });
});