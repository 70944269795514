define('ingenio-web/initializers/route', ['exports', 'ingenio-web/utils/string-utils', 'ingenio-web/utils/header-types', 'ingenio-web/utils/partner-header-types'], function (exports, _stringUtils, _headerTypes, _partnerHeaderTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /* IMPORTANT, please read.
    This is one of those places in the app where we use experimental approach to make things work somewhat
    magically. Reopening a Route means giving functionality to ALL Routes in the app. Be very-very careful
    while changing stuff here and resist any temptation to do so. At very minimum ask another pair of eyes
    to validate your decision.
   */
  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');

    Ember.Route.reopen({
      activate: function activate() {
        this._super.apply(this, arguments);

        var routeName = this.get('routeName');

        //application route is an exception, it's the one that handles header rendering
        if (routeName === 'application') {
          return;
        }

        var headerType = '';
        var footerType = '';

        //show minimal header during loading
        if ((0, _stringUtils.default)(routeName).contains('loading')) {
          headerType = _headerTypes.default.MINIMAL;
        }

        //a function optionally defined on a Route that defines header type that is supposed to be used by that Route
        var defineHeaderType = this.get('defineHeaderType');

        if (typeof defineHeaderType === 'function') {
          headerType = defineHeaderType.call(this) || '';
        }

        var defineFooterType = this.get('defineFooterType');

        if (typeof defineFooterType === 'function') {
          footerType = defineFooterType.call(this) || '';
        }

        //console.info('  ' + this.routeName + '.setHeaderTemplate -> ' + (headerType || '""'));
        this.send('setHeaderType', headerType);
        this.send('setFooterType', footerType);
      },
      defineHeaderType: function defineHeaderType() {
        var appModel = this.modelFor('application');
        var headerType = _headerTypes.default.MINIMAL;

        if (appModel.get('isInPartnerMode')) {
          if (appModel.get('partnerSetting.callFlowSetting.header.type') && appModel.get('partnerSetting.callFlowSetting.header.type') !== _partnerHeaderTypes.default.NotSet && appModel.get('partnerSetting.callFlowSetting.header.type') !== _partnerHeaderTypes.default.UnKnown) {
            switch (appModel.get('partnerSetting.callFlowSetting.header.type')) {
              case _partnerHeaderTypes.default.None:
              case _partnerHeaderTypes.default.Minimal:
                headerType = _headerTypes.default.MINIMAL;
                break;
              case _partnerHeaderTypes.default.MinimalNoLink:
                headerType = _headerTypes.default.MINIMALNOLINK;
                break;
              case _partnerHeaderTypes.default.Custom:
                headerType = _headerTypes.default.CUSTOM;
                break;
              case _partnerHeaderTypes.default.Full:
                headerType = _headerTypes.default.FULL;
                break;
              default:
                headerType = _headerTypes.default.MINIMAL;
            }
          } else if (appModel.get('partnerSetting.listingDisplaySetting.header.type') && appModel.get('partnerSetting.listingDisplaySetting.header.type') !== _partnerHeaderTypes.default.NotSet && appModel.get('partnerSetting.listingDisplaySetting.header.type') !== _partnerHeaderTypes.default.UnKnown) {
            switch (appModel.get('partnerSetting.listingDisplaySetting.header.type')) {
              case _partnerHeaderTypes.default.None:
              case _partnerHeaderTypes.default.Minimal:
              case _partnerHeaderTypes.default.MinimalNoLink:
                headerType = _headerTypes.default.MINIMAL;
                break;
              case _partnerHeaderTypes.default.Custom:
                headerType = _headerTypes.default.CUSTOM;
                break;
              case _partnerHeaderTypes.default.Full:
                headerType = _headerTypes.default.FULL;
                break;
              default:
                headerType = _headerTypes.default.MINIMAL;
            }
          }
        }

        return headerType;
      },
      deactivate: function deactivate() {
        this._super.apply(this, arguments);
        var routeName = this.get('routeName');

        //don't do anything it it's intermediate route transition such as "loading"
        if ((0, _stringUtils.default)(routeName).contains('loading')) {
          return;
        }

        this.send('hideOfferRibbon');
      },
      getParentRouteName: function getParentRouteName() {
        var parentRouteName = '';
        if (this.routeName.lastIndexOf('.')) {
          parentRouteName = this.routeName.substring(0, this.routeName.lastIndexOf('.'));
        }
        return parentRouteName;
      }
    });
  }

  exports.default = {
    name: 'route',
    initialize: initialize
  };
});