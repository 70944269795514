define('ingenio-web/components/listings/reserve-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        eventEmitter: Ember.inject.service('event-emitter'),

        actions: {
            reserveButtonClick: function reserveButtonClick(listingId) {
                this.sendAction('reserveClicked', listingId);
            }
        }
    });
});