define('ingenio-web/components/picture-list/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['row', 'picture-list-container'],
        selectedPictureId: 0,

        init: function init() {
            this._super.apply(this, arguments);

            if (this.attrs.selectable && !this.attrs.onPictureSelected) {
                throw new Error('"picture-list" component is set as selectable. Requires "onPictureSelected" props');
            }
        },


        actions: {
            onPictureSelected: function onPictureSelected(pictureId) {
                this.set("selectedPictureId", pictureId);
                this.attrs.onPictureSelected(pictureId);
            }
        }
    });
});