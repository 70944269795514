define('ingenio-web/models/transaction-feedback', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        rating: (0, _attr.default)('number'),
        sessionId: (0, _attr.default)('number'),
        sessionType: (0, _attr.default)('number'),
        fromAdvisor: (0, _attr.default)('boolean'),
        reasonIds: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }),
        comment: (0, _attr.default)('string')
    });
});