define("ingenio-web/utils/string-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = stringUtils;
    //sample utility covered with sample Unit-test
    function stringUtils(sourceString) {
        return {
            startsWith: function startsWith(_startsWith) {
                return !sourceString ? false : sourceString.indexOf(_startsWith) === 0;
            },
            contains: function contains(subStr) {
                return !sourceString ? false : sourceString.indexOf(subStr) > -1;
            }
        };
    }
});