define('ingenio-web/components/add-funds/price-buckets/grid/component', ['exports', 'ingenio-web/mixins/validatable', 'ingenio-web/mixins/keyboard-events', 'ingenio-web/mixins/auto-reload-helper'], function (exports, _validatable, _keyboardEvents, _autoReloadHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_keyboardEvents.default, _validatable.default, _autoReloadHelper.default, {
    amountTouched: false,
    showAutoReloadIcon: Ember.computed.equal('selectedBox', '$custom'),

    filteredPriceBuckets: Ember.computed('isAddFundsRequired', function () {
      return this.get('isAddFundsRequired') ? this.get('priceBuckets').filter(function (priceBucket) {
        return priceBucket.get('boxOrder') !== 0;
      }) : this.get('priceBuckets');
    }),

    amountComputed: Ember.computed('selectedBox', 'amount', 'autoReloadSelected', 'priceBuckets', function () {
      var selectedBox = this.get('selectedBox');

      if (selectedBox === '$custom') {
        return this.get('amount');
      }
      var priceBucket = this.get('priceBuckets').findBy('boxOrder', selectedBox);

      return priceBucket ? priceBucket.get('totalPrice') : null;
    }),

    amountComputedObserver: Ember.observer('amountComputed', function () {
      var computedAmount = this.get('amountComputed');
      if (this.attrs.onFundsAmountChanged) {
        this.attrs.onFundsAmountChanged(computedAmount);
      }
      if (this.attrs.onAmountChanged) {
        this.attrs.onAmountChanged(this.get('amount'));
      }
      this.validate(true);
    }),

    autoReloadSelectedObserver: Ember.observer('autoReloadSelected', 'selectedBox', function () {
      if (this.get('selectedBox') === '$custom') {
        this.set('amountTouched', true);
      }
      this.validate();
    }),

    amountObserver: Ember.observer('amount', function () {
      this.attrs.onAmountChanged(this.get('amount'));
      this.validate(true);
    }),

    selectedBoxObserver: Ember.observer('selectedBox', 'autoReloadSelected', function () {
      if (this.attrs.onFundsAmountChanged) {
        this.attrs.onFundsAmountChanged(this.get('amountComputed'));
      }

      this.set('amountTouched', this.get('selectedBox') === '$custom');
      this.validate(true);
    }),

    roundedMinutes: Ember.computed('balanceAmount', 'sellerMinutes', 'totalFeesDue', 'minuteRate', 'amount', function () {
      var minuteRate = Number(this.get('minuteRate'));
      var sellerMinutes = Number(this.get('sellerMinutes') || 0);

      var additionalFunds = Number(this.get('amount') || 0);
      var totalFeesDue = Number(this.get('totalFeesDue') || 0);
      var balanceAmount = Number(this.get('balanceAmount') || 0);

      if (additionalFunds > 0) {
        var amount = (additionalFunds + balanceAmount - totalFeesDue).toFixed(2);
        var balanceTalkMinutes = this.getMinutes(amount, minuteRate);

        var talkMinutes = sellerMinutes;
        if (balanceTalkMinutes > 0) talkMinutes += balanceTalkMinutes;

        return talkMinutes;
      }

      return 0;
    }),

    showZeroDollarBucket: Ember.computed('autoReloadSelected', 'isAddFundsRequired', function () {
      return !this.get('autoReloadSelected') && !this.get('isAddFundsRequired');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('filteredPriceBuckets').isAny('default')) {
        var defaultPriceBucket = this.get('filteredPriceBuckets').findBy('default', true);

        defaultPriceBucket.set('selected', true);

        this.set('selectedBox', defaultPriceBucket.get('boxOrder'));

        if (this.attrs.onIncreaseTypeChanged) {
          this.attrs.onIncreaseTypeChanged(defaultPriceBucket.get('boxOrder'), defaultPriceBucket.get('totalPrice'), defaultPriceBucket.get('minuteQuantity'));
        }
      }
    },


    actions: {
      onBoxSelected: function onBoxSelected(selectedBox, amount, minutes) {
        this.set('selectedBox', selectedBox);
        this.set('selectedAmount', amount);

        var newSelectedBox = this.get('filteredPriceBuckets').findBy('boxOrder', selectedBox);

        if (newSelectedBox) {
          this.get('filteredPriceBuckets').forEach(function (box) {
            box.set('selected', false);
          });
          newSelectedBox.set('selected', true);
        }

        if (selectedBox === '$custom') {
          this.set('amountTouched', true);
          $('#amount').focus();
        } else {
          this.setValid();
          this.set('amount', null);
          this.set('amountTouched', false);
        }
        if (this.attrs.onIncreaseTypeChanged) {
          this.attrs.onIncreaseTypeChanged(selectedBox, amount, minutes);
        }
        this.validate();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.attrs.onFundsAmountChanged) {
        this.attrs.onFundsAmountChanged(this.get('amountComputed'));
      }
      $('#amount').focusin(function () {
        _this.set('amountTouched', true);
        _this.set('selectedBox', '$custom');
      });
      if (this.get('selectedBox') === '$custom') {
        this.validate(true);
      }
    },
    keyDown: function keyDown(event) {
      this.set('amountTouched', true);
      this.allowKeys(event, [this.keyType.ctrl, this.keyType.numeric, this.keyType.special, this.keyType.decimal]);
    },
    keyUp: function keyUp() {
      var targetValue = Ember.get(event, 'target.value');

      // don't propagate the change if the number is not a valid decimal.
      if (!/^\d*\.?\d{0,2}$/.test(targetValue)) {
        Ember.set(event, 'target.value', this.get('amount'));
        return;
      }

      this.set('amountTouched', true);
      if (this.attrs.onFundsAmountChanged) {
        this.attrs.onFundsAmountChanged(targetValue);
      }
      this.set('amount', targetValue);
    },
    focusOut: function focusOut() {
      this.set('amountTouched', false);
      this.validate();
    },
    focusIn: function focusIn() {},
    validate: function validate(ignoreTouched) {
      var amount = Number(this.get('amount'));

      var minimumDepositAmount = this.get('minimumDepositAmount');
      var maximumDepositAmount = this.get('maximumDepositAmount');

      // only perform validation after the user first touches the input field.
      // the controller handles determining if the primary CTA should be enabled or disabled per rules.
      // this only ensures that the validation messaging is silenced on the additional load.
      if (!this.get('amountTouched') && !ignoreTouched) {
        return;
      }

      if (!amount && this.get('amountTouched') && (this.get('isAddFundsRequired') || this.get('autoReloadSelected'))) {
        return this.setInvalid('');
      }

      if (amount && (!this.isValidAmount(amount) || isNaN(amount))) {
        return this.setInvalid('Invalid amount entered.');
      }

      if (amount && amount < minimumDepositAmount) {
        var mins = this.getMinutes(minimumDepositAmount, this.get('minuteRate'));

        if (mins > 0) {
          return this.setInvalid('Amount must be a minimum of $' + minimumDepositAmount.toFixed(2));
        }

        return this.setInvalid('Amount must be a minimum of $' + minimumDepositAmount.toFixed(2));
      }

      if (amount && amount > maximumDepositAmount) {
        return this.setInvalid('Amount must be no greater than $' + maximumDepositAmount.toFixed(2));
      }

      this.setValid();
    },
    isValidAmount: function isValidAmount(amount) {
      return (/^(?:\d*\.\d{1,2}|\d+)$/.test(amount)
      );
    }
  });
});