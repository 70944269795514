define('ingenio-web/adapters/listing-draft', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        //overriding default path, by default it will be based on model name "listing-drafts"
        pathForType: function pathForType() {
            return 'listings/drafts';
        }
    });
});