define("ingenio-web/birth-chart/confirmation/controller", ["exports", "ingenio-web/arrange-conversation/controller", "ingenio-web/mixins/scroll-helpers", "ingenio-web/birth-chart/step-helpers"], function (exports, _controller, _scrollHelpers, _stepHelpers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
    birthChart: Ember.inject.controller(),
    secondaryButtonBusy: false,
    showLeaveWaitlistConfirmationDialog: false,

    enablePrimaryButton: Ember.computed("primaryButtonBusy", "model.user.funds.paymentMethodId", "model.user.paymentMethods", function () {
      var paymentMethodId = this.get("model.user.funds.paymentMethodId");
      var paymentMethod = this.get("model.user.paymentMethods").findBy("id", paymentMethodId);

      if (!paymentMethodId || !paymentMethod || paymentMethod.get("isExpired")) {
        return false;
      }

      if (this.get("primaryButtonBusy")) {
        return false;
      }

      return true;
    }),

    hideSteps: Ember.computed.equal("steptype", "1"),

    actions: {
      changeForm: function changeForm() {
        var url = Ember.ENV.urls.nextJSRoot + "/birth-chart/form";
        this.transitionToUrlWithCurrentParams(url);
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();
        this.set("primaryButtonBusy", true);

        var user = Ember.Application.user;

        if (user.isAvsReqd) {
          var selectedCreditCard = this.get('model.paymentMethodSelected');

          //shoud we consider case when user enter cvcode and reopen ConfirmDepositDialog???

          if (!this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
            this.showConfirmDepositDialog(user.isAvsReqd);
            return;
          }
        }

        this.get('mixpanelClient').trackNewEvent('Birth Chart Confirmation Clicked', {
          eventData: {
            survrespid: this.get('survrespid'),
            source: this.get('source')
          }
        });

        setTimeout(function () {
          var url = Ember.ENV.urls.nextJSRoot + "/birth-chart/generate";
          _this.transitionToUrlWithCurrentParams(url);
        }, 2000);
      },
      changePaymentMethod: function changePaymentMethod() {
        this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".payment-details");

        this.scrollToElementOnMobileViewWithDelay(".payment-details-container");
      },
      cancelConfirmDeposit: function cancelConfirmDeposit(creditCard) {
        this.set('isBusy', false);
        this.set('_showConfirmDepositDialog', false);
        creditCard.clearTouched();
        creditCard.removeErrors();
        this.set('avsModel.cvvCode', '');
        this.set("primaryButtonBusy", false);
      },
      avscvnContinueConfirmDeposit: function avscvnContinueConfirmDeposit(creditCard) {
        var _this2 = this;

        this.set('model.paymentMethodSelected.cvvCode', this.get('avsModel.cvvCode'));
        //this.set('model.user.funds.cvvCode', this.get('avsModel.cvvCode'));
        this.set('model.paymentMethodSelected.address', this.get('avsModel.address'));

        if (creditCard.get('isAvsRequired')) {
          // we need to update card details (address, name, etc)
          // if AVS or CVN is required

          // set flag that card is under processing
          creditCard.set('inProcess', true);
          // remove previous server side errors
          creditCard.clearGenericErrors();

          creditCard.save().then(function () {
            // reset indicator
            creditCard.set('inProcess', false);

            _this2.set('_showConfirmDepositDialog', false);
            _this2.send('onPrimaryButtonClick');
          }, function (error) {
            creditCard.handleError(error);
            _this2.set("primaryButtonBusy", false);
          });
        } else {
          // submit CVN code to add money function
          this.set('_showConfirmDepositDialog', false);
          this.send('onPrimaryButtonClick');
        }
      }
    }
  });
});