define('ingenio-web/components/title-with-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        title: '',
        enteredText: '',
        minLength: 0,
        maxLength: 0,
        shouldShowCharsLeft: false,

        anyChangesWereMade: function () {
            return this.get('enteredText') && this.get('enteredText').length > 0;
        }.property('enteredText'),

        charsToGo: function () {
            var charsToGo = this.get('minLength') - this.get('enteredText').length;

            return charsToGo < 0 ? 0 : charsToGo;
        }.property('enteredText'),

        charsLeftMessage: function () {
            var charsLeft = this.get('maxLength') - this.get('enteredText').length;
            var maxLimitReached = charsLeft === 0;

            if (maxLimitReached) {
                return 'You reached maximum allowed characters';
            }

            return charsLeft + ' left';
        }.property('enteredText'),

        anyCharsLeftToGo: function () {
            return this.get('charsToGo') > 0;
        }.property('charsToGo')
    });
});