define("ingenio-web/utils/router-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    //normalizes queryParams casing to be same as provided in expected, e.g.
    /*
    input:
        original: {"MYPARAM": 1}, expectedName: "myParam"
    output:
        {normalizedParams: {"myParam": 1}, anythingChanged: true}
    Please note that "anythingChanged" signifies if any params were actually normalizedParams
    this is useful if you trying to decide weather to redirect to a route with correct parameters
    or current ones are good.
    */

    function normalizeQueryParamsCase(originalQueryParams, expectedQueryParamNames) {
        var normalizedQueryParams = {};
        var anythingChanged = false;

        var _loop = function _loop(originalName) {
            if (!originalQueryParams.hasOwnProperty(originalName)) {
                return {
                    v: {
                        normalizedQueryParams: normalizedQueryParams,
                        anythingChanged: anythingChanged
                    }
                };
            }

            //find expected name that matches original with different casing
            /*jshint loopfunc: true */ //disabling jshint rule since we are not "producing" a function in a loop
            //we are calling it right away (inside find)
            var expectedName = expectedQueryParamNames.find(function (expected) {
                return originalName.toLowerCase() === expected.toLowerCase() && originalName !== expected;
            });

            if (expectedName) {
                normalizedQueryParams[expectedName] = originalQueryParams[originalName];
                anythingChanged = true;
            } else {
                normalizedQueryParams[originalName] = originalQueryParams[originalName];
            }
        };

        for (var originalName in originalQueryParams) {
            var _ret = _loop(originalName);

            if ((typeof _ret === "undefined" ? "undefined" : _typeof(_ret)) === "object") return _ret.v;
        }

        return {
            normalizedQueryParams: normalizedQueryParams,
            anythingChanged: anythingChanged
        };
    }

    exports.default = {
        normalizeQueryParamsCase: normalizeQueryParamsCase
    };
});