define('ingenio-web/add-funds/payment-details/route', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _route.default.extend({
        skipDetailsRedirect: function skipDetailsRedirect() {
            return true;
        }
    });
});