define('ingenio-web/redeem-offer/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redeemOfferFlowModel: function redeemOfferFlowModel() {
            return this.modelFor(this.getParentRouteName());
        },

        beforeModel: function beforeModel(transition) {
            var redeemOfferFlowModel = this.redeemOfferFlowModel();

            if (redeemOfferFlowModel.get('hasValidOffer')) {
                this.replaceWith('redeem-offer.details', {
                    queryParams: transition.queryParams
                });
            } else {
                this.replaceWith('redeem-offer.no-offer', {
                    queryParams: transition.queryParams
                });
            }
        }
    });
});