define('ingenio-web/models/arranged-chat', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        listingId: (0, _attr.default)('number'),
        consumerId: (0, _attr.default)('number'),
        chatId: (0, _attr.default)('number'),
        status: (0, _attr.default)('string'),
        isSourceWeb: (0, _attr.default)('boolean', { defaultValue: true })
    });
});