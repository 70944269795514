define('ingenio-web/components/myaccount/advisor-feedback-grid', ['exports', 'moment', 'lodash', 'ingenio-web/utils/customer-utils', 'ingenio-web/utils/cookies'], function (exports, _moment, _lodash, _customerUtils, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gridApi: null,
    gridColumnApi: null,
    busy: Ember.inject.service('busy'),
    notifications: Ember.inject.service('toast'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    query: 'query($advisorId:Int $listingId:Int $isHighlighted:Boolean $sortBy:[Sort] $pageSize:Int $pageNumber:Int){ratingsAndReviews(advisorId:$advisorId listingId:$listingId isHighlighted:$isHighlighted sortBy:$sortBy pageSize:$pageSize pageNumber:$pageNumber){averageRating totalEdges edges{node{id rating review date isHighlighted advisorStrengths target{type source{id sessionExists masterTransactionId customer{id userName nickname}listing{id category{id name}advisor{id userName}}}}}}}}',
    showPinModal: false,
    showUnpinModal: false,
    showReplaceModal: false,
    showErrorModal: false,
    modalParams: null,
    showTip: false,
    showAllListingsTip: false,

    didInsertElement: function didInsertElement() {
      this.set('gridOptions.parent', this);
      this.set('register-as', this);
    },

    gridOptions: {
      pagination: true,
      paginationPageSize: 25,
      suppressPaginationPanel: true,
      gridAutoHeight: true,
      enableColResize: true,
      enableSorting: true,
      suppressCellSelection: true,
      suppressClickEdit: true,
      multiSortKey: 'ctrl',
      sortingOrder: ['desc', 'asc'],
      rowSelection: 'multiple',
      rowGroupPanelShow: 'never',
      tooltipShowDelay: 0,

      parent: null,
      icons: {
        sortAscending: '<i class="grid-sort-icon fa fa-caret-up"/>',
        sortDescending: '<i class="grid-sort-icon fa fa-caret-down"/>'
      },

      columnDefs: [{
        headerName: 'Customer',
        headerClass: 'col-header-name',
        field: 'target.source.customer.userName',
        width: 180,
        pinned: true,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return null;
          }
          var url = '#/myaccount/customers/' + params.data.target.source.customer.id;
          return '<a class="customer-link" href=\'' + url + '\' data-object=\'' + JSON.stringify(params.data) + '\'>' + params.value + '</a>';
        }
      }, {
        headerName: 'Nickname',
        field: 'target.source.customer.nickname',
        width: 150,
        suppressMenu: true
      }, {
        headerName: 'Rating Date',
        headerClass: 'col-header-rating-date',
        field: 'date',
        width: 120,
        sort: 'desc',
        suppressMenu: true,
        suppressSizeToFit: true,
        valueFormatter: function valueFormatter(params) {
          if (params.value) {
            return (0, _moment.default)(params.value).format('MM/DD/YYYY');
          }
        }
      }, {
        headerName: 'Listing Category',
        headerClass: 'col-header-listing-category',
        field: 'target.source.listing.category.name',
        suppressMenu: true
      }, {
        headerName: 'Rating',
        headerClass: 'col-header-rating',
        field: 'rating',
        width: 120,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) return null;

          var width = params.value / 5 * 100;
          return '<div class="granular-star-rating-container"><div class="star-rating rating-xss" style="font-size:16px"><div class="rating-container rating-gly-star" data-content="\uE006\uE006\uE006\uE006\uE006"><div class="rating-stars" data-content="\uE006\uE006\uE006\uE006\uE006" style="width: ' + width + '%;"></div></div></div> ';
        }
      }, {
        headerName: 'Strength',
        headerClass: 'col-header-strength',
        field: 'advisorStrengths',
        width: 200,
        cellClass: 'cell-wrap-text',
        autoHeight: true,
        suppressMenu: true
      }, {
        headerName: 'Comments',
        headerClass: 'col-header-comments',
        cellClass: 'cell-wrap-text',
        field: 'review',
        autoHeight: true,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          var text = params.data.review;

          if (params.node.rowPinned) {
            var listingId = params.data.target.source.listing.id;
            var url = "/listingdetails?sid=" + listingId + "#highlightedReview";
            return '<a href="' + url + '">' + text + '</a>';
          }

          return text;
        }
      }, {
        headerName: 'Contact Type',
        headerClass: 'col-header-contact-type',
        field: 'target.type',
        width: 120,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return '';
          }

          var text = params.data.target.type;
          var atype = params.data.target.type.toUpperCase();
          switch (atype) {
            case 'CHAT':
              atype = 'CHATRECEIVED';
              text = 'Chat';
              break;
            case 'CALL':
              atype = 'CALLLIVERECEIVED';
              text = 'Call';
              break;
            case 'MAIL':
              atype = 'MAILSENT';
              text = 'Mail';
              break;
          }

          var url = _customerUtils.default.getActivityUrl(params.data.target.source.customer.id, {
            type: atype,
            activityId: params.data.target.source.id,
            mailId: params.data.target.source.id,
            masterTransactionId: params.data.target.source.masterTransactionId,
            sessionExists: !!params.data.target.source.sessionExists
          });
          if (!url) {
            return text;
          }
          return '<a class="contact-type-link" href="' + url + '" data-object=\'' + JSON.stringify(params.data) + '\'>' + text + ' </a>';
        }
      }, {
        headerName: '',
        headerClass: '',
        field: 'pin',
        width: 40,
        suppressMenu: true,
        suppressSorting: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.node.data.review) {
            //no comments in the feedback, need to show disabled pin
            return '\n                  <div class="pin-container disabled">\n                      <i class="far fa-thumbtack"></i>\n                  </div>\n              ';
          }

          var element = document.createElement("a");
          element.setAttribute("role", "link");
          var icon = params.node.rowPinned ? "fas" : "far";

          var toolTip = params.node.rowPinned ? "Unpin" : "Pin";

          element.innerHTML = '\n\t\t\t\t<div class="pin-container" data-toggle="tooltip" data-placement="top" title="' + toolTip + '">\n\t\t\t\t\t<i class="' + icon + ' fa-thumbtack"></i>\n\t\t\t\t</div>\n\t\t\t';

          element.addEventListener("click", function () {
            var parent = params.api.gridOptionsWrapper.gridOptions.parent;
            params.node.rowPinned ? parent.unPinFeedback(params) : parent.pinFeedback(params);
          });

          return element;
        },
        cellRendererParams: {}
      }],
      onGridReady: function onGridReady(params) {
        if (this.parent && this.parent.onGridReady) {
          this.parent.onGridReady(params);
        }
      }
    },

    pinFeedback: function pinFeedback(params) {
      //set modalParams object to be used inside action methods as params is not accessible there
      this.set('modalParams', params);

      //show modal
      if (params.data.target.source.customer.id > 0 && params.api.pinnedRowModel.pinnedTopRows.length > 0) {
        //Pinned top row exists
        this.set('showReplaceModal', true);
      } else {
        this.set('showPinModal', true);
      }
    },
    unPinFeedback: function unPinFeedback(params) {
      //set modalParams object to be used inside action methods as params is not accessible there
      this.set('modalParams', params);

      //show modal
      this.set('showUnpinModal', true);
    },


    // bind all required events
    onGridReady: function onGridReady(params) {
      this.resizeGridWidth(params.api);

      // control sort change
      params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));

      // control row selection
      params.api.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));

      setTimeout(function () {
        params.api.resetRowHeights();
      }, 500);

      // just in case store refs to grid and column api
      this.set('gridApi', params.api);
      this.set('gridColumnApi', params.columnApi);

      // get dats from server side
      this.refreshGrid();
    },

    // control grid pagination
    gridActualPageSizeChanged: function () {
      if (this.get('gridApi')) {
        this.get('gridApi').paginationSetPageSize(this.get('pagination.actualPageSize'));

        if (this.get('pagination.actualPageSize') == 0) {
          this.get('gridApi').showNoRowsOverlay();
        }
      }
    }.observes('pagination.totalRecords', 'pagination.actualPageSize'),

    gridPaginationChanged: function () {
      Ember.run.once(this, 'refreshGrid');
    }.observes('pagination.currentPage', 'pagination.pageSize'),

    filtrationChanged: function () {
      if (this.get('pagination.currentPage') != 1) {
        this.get('pagination').set('currentPage', 1);
      } else {
        Ember.run.once(this, 'refreshGrid');
      }
    }.observes('filtration.selectedListingId'),

    // control grid row selection
    onSelectionChanged: function onSelectionChanged() {
      var _this = this;

      if (!this.get('selectedRows')) return;

      this.get('selectedRows').clear();

      var gridSelectedRows = this.get('gridApi').getSelectedRows();
      gridSelectedRows.forEach(function (selectedRow) {
        _this.get('selectedRows').addObject(selectedRow);
      });
    },

    // handle grid sorting
    onSortChanged: function onSortChanged(params) {
      this.refreshGrid();
    },

    // retreive grid data
    refreshGrid: function refreshGrid() {
      var _this2 = this;

      var gridApi = this.get('gridApi');

      //default "show tip banner" to not show
      this.set('showTip', false);
      this.set('showAllListingsTip', false);

      //default "pin" column to not show
      gridApi.gridOptionsWrapper.gridOptions.columnApi.setColumnVisible('pin', false);
      this.resizeGridWidth(gridApi);

      this.get("busy").show();
      gridApi.deselectAll();
      gridApi.hideOverlay();

      var sorting = null;
      sorting = gridApi.getSortModel().map(function (item) {
        return {
          field: item.colId,
          order: item.sort == "desc" ? "DESCENDING" : "ASCENDING"
        };
      });

      var allListingsSelected = this.get('filtration.selectedListingId') == 0;

      var listingId = allListingsSelected ? null : this.get('filtration.selectedListingId');
      var isHighlighted = allListingsSelected ? null : false;

      return Ember.RSVP.hash({
        graphQLResult: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql2', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            query: this.get('query'),
            variables: {
              listingId: listingId,
              advisorId: Ember.Application.user.id,
              isHighlighted: isHighlighted,
              pageNumber: this.get('pagination.currentPage'),
              pageSize: this.get('pagination.pageSize'),
              sortBy: sorting
            }
          })
        })
      }).then(function (result) {
        var feedbackData = result.graphQLResult.data.ratingsAndReviews;

        _this2.get('pagination').set('totalRecords', feedbackData.totalEdges);
        gridApi.setRowData(feedbackData.edges.map(function (edge) {
          return edge.node;
        }));

        if (listingId) {
          _this2.PopulateHighlightedFeedbackRow(listingId, feedbackData.edges.length > 0);
        } else {
          gridApi.setPinnedTopRowData([]);
        }

        if (listingId) {
          _this2.set('showAllListingsTip', false);
        } else {
          _this2.set('showAllListingsTip', true);
        }

        setTimeout(function () {
          gridApi.resetRowHeights();
        }, 500);
      }, function () {
        _this2.get('notifications').error('Error getting data from server', 'Customers Grid', {
          positionClass: 'toast-top-right'
        });
      }).finally(function () {
        _this2.get('busy').hide();
      });
    },
    PopulateHighlightedFeedbackRow: function PopulateHighlightedFeedbackRow(listingId, gridDataExists) {
      var _this3 = this;

      var gridApi = this.get('gridApi');
      Ember.RSVP.hash({
        graphQLResult: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql2', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            query: this.get('query'),
            variables: {
              listingId: listingId,
              advisorId: Ember.Application.user.id,
              isHighlighted: true
            }
          })
        })
      }).then(function (result) {
        var highlightedFeedbackData = result.graphQLResult.data.ratingsAndReviews.edges;
        gridApi.setPinnedTopRowData(highlightedFeedbackData.map(function (edge) {
          return edge.node;
        }));

        //show/hide tip
        if (listingId && highlightedFeedbackData.length == 0 && gridDataExists) {
          _this3.set('showTip', true);
        } else {
          _this3.set('showTip', false);
        }

        //show/hide pin column
        if (listingId && gridDataExists) {
          gridApi.gridOptionsWrapper.gridOptions.columnApi.setColumnVisible('pin', true);
          _this3.resizeGridWidth(gridApi);
        }
      }, function () {
        _this3.get('notifications').error('Error getting data from server', 'Customers Grid', {
          positionClass: 'toast-top-right'
        });
      });
    },
    setHighlightedFeedback: function setHighlightedFeedback(httpMethodType) {
      var _this4 = this;

      var params = this.get('modalParams');

      var targetUrl = Ember.ENV.getApiRoot() + '/listings/' + params.data.target.source.listing.id + '/highlighted-feedback/' + params.data.id;

      Ember.$.ajax(targetUrl, {
        method: httpMethodType,
        headers: {
          'X-EpcApi-ID': _cookies.default.getSessionId(),
          'X-Domain-ID': Ember.ENV.domainId
        }
      }).done(function (data) {
        params.api.gridOptionsWrapper.gridOptions.parent.onGridReady(params);
      }).fail(function () {
        _this4.set('showErrorModal', true);
      });
    },


    // get the current grids width and based on it define how columns are sized
    resizeGridWidth: function resizeGridWidth(_gridApi) {
      var gridWidth = this.get('element').offsetWidth;
      if (gridWidth > 500) {
        _gridApi.sizeColumnsToFit();
      }
    },


    actions: {
      onClosePinModalClick: function onClosePinModalClick() {
        this.set('showPinModal', false);
      },
      onPinClick: function onPinClick() {
        this.set('showPinModal', false);
        this.setHighlightedFeedback('PUT');
      },
      onCloseUnpinModalClick: function onCloseUnpinModalClick() {
        this.set('showUnpinModal', false);
      },
      onUnpinClick: function onUnpinClick() {
        this.set('showUnpinModal', false);
        this.setHighlightedFeedback('DELETE');
      },
      onCloseReplaceModalClick: function onCloseReplaceModalClick() {
        this.set('showReplaceModal', false);
      },
      onReplaceClick: function onReplaceClick() {
        this.set('showReplaceModal', false);
        this.setHighlightedFeedback('PUT');
      },
      onErrorClick: function onErrorClick() {
        this.set('showErrorModal', false);
      }
    }
  });
});