define('ingenio-web/adapters/credit-card-new', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            var userId = Ember.Application.user.id;

            return 'users/' + userId + '/payment-methods/checkout/cards';
        },

        handleResponse: function handleResponse(status, headers, payload, requestData) {
            payload['creditCardNew'] = payload['creditCard'];
            delete payload['creditCard'];

            return this._super.apply(this, arguments);
        }
    });
});