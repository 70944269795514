define('ingenio-web/initializers/router', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() /* application */{
        Ember.Router.reopen({
            metrics: Ember.inject.service(),
            mixpanelClient: Ember.inject.service('mixpanel-client'),
            googleAnalyticsClient: Ember.inject.service('google-analytics-client'),

            didTransition: function didTransition() {
                var _this = this;

                this._super.apply(this, arguments);

                var page = window.location.hash.split('?')[0].replace('#', '');

                Ember.run.next(function () {
                    _this.get('googleAnalyticsClient').trackPageview(page);
                    // report route change to hotjar
                    window.hj('stateChange', window.location.href);

                    // todo: migrate remaining tracking scripts to ember metrics.
                    _this.get('metrics').trackPage({
                        page: _this.currentURL,
                        title: _this.currentRouteName || 'Unknown',
                        channel: _cookies.default.getGroupCookie('PassThrough', 'Channel') || 'Direct/SEO'
                    });
                });
            }
        });
    }

    exports.default = {
        name: 'router',
        initialize: initialize
    };
});