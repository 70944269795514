define('ingenio-web/call-setup/index/route', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        checkoutFlowModel: function checkoutFlowModel() {
            return this.modelFor(this.getParentRouteName());
        },
        beforeModel: function beforeModel(transition) {
            var arrangeFlow = transition.queryParams.arrange || transition.state.fullQueryParams && transition.state.fullQueryParams.arrange || false;
            var checkoutFlowModel = this.checkoutFlowModel();

            var redirectStep = 'details';

            if (this.userReadyForCall()) {
                redirectStep = 'confirmation';
                checkoutFlowModel.set('quickStart', !arrangeFlow && checkoutFlowModel.get('hasExistingPhoneNumber'));
            }
            window.newUserQuickCheckout = checkoutFlowModel.get('showAddMinutes'); // set global variable for optimizely AB test

            this.replaceWith(this.getParentRouteName() + '.' + redirectStep, {
                queryParams: transition.queryParams
            });
        },
        userReadyForCall: function userReadyForCall() {
            var checkoutFlowModel = this.checkoutFlowModel();
            var callMinutes = checkoutFlowModel.get('user.balance.callMinutes');

            return callMinutes >= Ember.ENV.minimumBalanceMinutesForConversation && checkoutFlowModel.get('hasExistingPaymentMethods');
        }
    });
});