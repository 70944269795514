define('ingenio-web/components/credit-card/formatter', ['exports', 'ingenio-web/components/credit-card/card-types'], function (exports, _cardTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        maskCreditCardFromLastFourDigits: function maskCreditCardFromLastFourDigits(cardType, lastFourDigits) {
            return cardType === _cardTypes.default.americanExpress ? 'XXXX-XXXXXX-X' + lastFourDigits : 'XXXX-XXXX-XXXX-' + lastFourDigits;
        }
    };
});