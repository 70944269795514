define('ingenio-web/utils/pubnub-advisor-availability-channel-client', ['exports', 'ingenio-web/utils/pubnub-client'], function (exports, _pubnubClient) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var AVAILABILITY_MAJOR_RANK = {
        UNKNOWN: 0,
        CALL_NOW: 1,
        BUSY_ARRANGE_HIGH: 2,
        BUSY_APPOINTMENT_HIGH: 3,
        PHONE_ALERT: 4,
        BUSY_ARRANGE_MEDIUM: 5,
        BUSY_ARRANGE_LOW: 6,
        BUSY_APPOINTMENT_LOW: 7,
        BUSY_MAIL_TAKING_CALLS: 8,
        BUSY_MAIL: 9,
        ARRANGE: 10,
        APPOINTMENT: 11,
        AWAY: 12
    };

    var CALL_AVAILABILITY_TYPE = {
        CALL_NOW: 0,
        CALL_NOW_ALERT: 1,
        SEND_MAIL_BUSY: 2,
        ARRANGE_APPOINTMENTS_BUSY: 3,
        ARRANGE_CALL_BUSY: 4,
        ARRANGE_CALL_AWAY: 5,
        SEND_MAIL_AWAY: 6,
        ARRANGE_APPOINTMENTS_AWAY: 7
    };

    var PubnubAdvisorAvailabilityChannelClient = function () {
        function PubnubAdvisorAvailabilityChannelClient(pubnubOptions, logger) {
            var _this = this;

            _classCallCheck(this, PubnubAdvisorAvailabilityChannelClient);

            pubnubOptions.callbacks = pubnubOptions.callbacks || {
                subscribe: {
                    success: function success() {
                        return _this._subscribeSuccess();
                    },
                    failure: function failure(error) {
                        return _this._subscribeFail(error);
                    }
                },
                messageReceived: function messageReceived(m) {
                    return _this._messageReceived(m.message);
                }
            };

            pubnubOptions.logger = logger;

            this.pubnubClient = new _pubnubClient.default(pubnubOptions, pubnubOptions.pubnubClientInstance);
        }

        _createClass(PubnubAdvisorAvailabilityChannelClient, [{
            key: 'subscribe',
            value: function subscribe() {
                this.pubnubClient.subscribe();
            }
        }, {
            key: '_subscribeSuccess',
            value: function _subscribeSuccess() {
                var _this2 = this;

                if (this.onConnect) {
                    Ember.run(function () {
                        return _this2.onConnect();
                    });
                }
            }
        }, {
            key: '_subscribeFail',
            value: function _subscribeFail(error) {
                var _this3 = this;

                if (this.onConnectFailed) {
                    Ember.run(function () {
                        return _this3.onConnectFailed(error);
                    });
                }
            }
        }, {
            key: '_messageReceived',
            value: function _messageReceived(msg) {
                this.onAdvisorAvailabilityChanged({
                    advisorId: msg.AdvisorId,
                    chatAvailability: msg.ChatUserStatus,
                    callAvailability: this._getCallAvailabilityTypeByMajorRank(msg.CallMajorRank)
                });
            }
        }, {
            key: '_getCallAvailabilityTypeByMajorRank',
            value: function _getCallAvailabilityTypeByMajorRank(majorRank) {
                switch (majorRank) {
                    case AVAILABILITY_MAJOR_RANK.UNKNOWN:
                    case AVAILABILITY_MAJOR_RANK.CALL_NOW:
                    case AVAILABILITY_MAJOR_RANK.PHONE_ALERT:
                        return CALL_AVAILABILITY_TYPE.CALL_NOW;

                    case AVAILABILITY_MAJOR_RANK.BUSY_MAIL:
                    case AVAILABILITY_MAJOR_RANK.BUSY_MAIL_TAKING_CALLS:
                        return CALL_AVAILABILITY_TYPE.SEND_MAIL_BUSY;

                    case AVAILABILITY_MAJOR_RANK.BUSY_ARRANGE_LOW:
                    case AVAILABILITY_MAJOR_RANK.BUSY_ARRANGE_HIGH:
                    case AVAILABILITY_MAJOR_RANK.BUSY_ARRANGE_MEDIUM:
                        return CALL_AVAILABILITY_TYPE.ARRANGE_CALL_BUSY;

                    case AVAILABILITY_MAJOR_RANK.BUSY_APPOINTMENT_LOW:
                    case AVAILABILITY_MAJOR_RANK.BUSY_APPOINTMENT_HIGH:
                        return CALL_AVAILABILITY_TYPE.ARRANGE_APPOINTMENTS_BUSY;

                    case AVAILABILITY_MAJOR_RANK.AWAY:
                        return CALL_AVAILABILITY_TYPE.SEND_MAIL_AWAY;

                    case AVAILABILITY_MAJOR_RANK.ARRANGE:
                        return CALL_AVAILABILITY_TYPE.ARRANGE_CALL_AWAY;

                    case AVAILABILITY_MAJOR_RANK.APPOINTMENT:
                        return CALL_AVAILABILITY_TYPE.ARRANGE_APPOINTMENTS_AWAY;

                    default:
                        throw 'Availability: No call availability type is mapped for MajorRank=' + majorRank;
                }
            }
        }], [{
            key: 'guid',
            value: function guid() {
                return _pubnubClient.default.guid();
            }
        }]);

        return PubnubAdvisorAvailabilityChannelClient;
    }();

    exports.default = PubnubAdvisorAvailabilityChannelClient;
});