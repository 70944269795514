define('ingenio-web/components/error/blocked-advisor/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		browseAdvisorsUrl: '',

		init: function init() {
			this._super.apply(this, arguments);

			var domainName = Ember.ENV.domainName;
			if (domainName === 'Keen') {
				this.set('browseAdvisorsUrl', '/psychic-readings/195');
			} else if (domainName === 'PsychicCenter') {
				this.set('browseAdvisorsUrl', '/all-psychics/17004');
			}
		}
	});
});