define('ingenio-web/call-setup/confirmation/controller', ['exports', 'ingenio-web/mixins/checkout-flow/confirmation/controller', 'ingenio-web/models/call-result-codes', 'ingenio-web/models/phone-types'], function (exports, _controller, _callResultCodes, _phoneTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    userEvents: Ember.inject.service(),
    callSetup: Ember.inject.controller(),
    steps: Ember.computed.alias('callSetup.steps'),

    phonesValid: false,
    phonesInMultiEditMode: false,

    log_source: 'call-flow-confirmation', //TODO: can be dynamically arrived from the parent route name?

    enablePrimaryButton: Ember.computed('model.accountBalanceWithExtraFundsMeetsMinimum', 'primaryButtonBusy', 'phonesValid', function () {
      if (this.get('primaryButtonBusy') || !this.get('phonesValid')) {
        return false;
      }

      return this.get('model.accountBalanceWithExtraFundsMeetsMinimum');
    }),

    showChangeFundsLink: Ember.computed('phonesInMultiEditMode', 'primaryButtonBusyQS', function () {
      return !this.get('primaryButtonBusyQS') && !this.get('phonesInMultiEditMode');
    }),

    showChangePhonesLink: Ember.computed('model.user.phoneNumbers.@each', function () {
      return !Ember.isEmpty(this.get('model.user.phoneNumbers'));
    }),

    showChangePhonesLinkQS: Ember.computed('showChangePhonesLink', 'quickStartFlow', function () {
      var quickStartFlow = this.get('quickStartFlow');

      if (quickStartFlow) {
        return false;
      }

      var showChangePhonesLink = this.get('showChangePhonesLink');

      return showChangePhonesLink;
    }),

    selectedPhoneNumber: Ember.computed('phones.@each', function () {
      return this.get('phones').find(function (phone) {
        return phone.number.isPrimary;
      });
    }),

    actions: {
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();

        var user = Ember.Application.user;
        var listingId = this.get('model.listing.id');

        if (this.get('model.customerWillBeCharged') && user.isAvsReqd) {
          var selectedCreditCard = this.get('model.paymentMethodSelected');

          var recheckAVSOnCancel = this.get('avsModel.recheckAVS') && this.get('avsModel.recheckAVS') == true;
          if (recheckAVSOnCancel || !this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
            this.showConfirmDepositDialog(user.isAvsReqd);

            //reset recheck state.
            var avsModel = this.get('avsModel');
            if (avsModel) {
              avsModel.set('recheckAVS', false);
            }

            return;
          }
        }

        this.set('primaryButtonBusy', true);

        var callButtonIsClicked = true;
        if (this.get('phonesInMultiEditMode')) {
          callButtonIsClicked = false;
        }

        if (callButtonIsClicked) {
          this.get('mixpanelClient').trackNewEvent('Call Confirmation - Call Now Button Clicked', { eventData: { listing_id: listingId } });
        }

        var editPhonesFlow = this.get('phonesInMultiEditMode');

        this.savePhoneNumbers().then(function (phones) {
          _this.set('model.user.phoneNumbers', phones);

          if (!editPhonesFlow) {
            return _this.validateOffer().then(function (returnToStep) {
              if (returnToStep === 'return-to-details-step') {
                _this.set('primaryButtonBusy', false);
                _this.send('onUserOfferDeleted');
                _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.details');
              } else {
                return _this.addFundsToAccount().then(function () {
                  return _this.setupCall().finally(function () {
                    return _this.set('primaryButtonBusy', false);
                  });
                }).catch(function (error) {
                  if (error.responseJSON && error.responseJSON.displayChangePaymentMethodPopUp) {
                    _this.toggleShowChangePaymentMethodPopUpCommon();
                  } else {
                    _this.handleError(error);
                  }

                  _this.set('primaryButtonBusy', false);
                  _this.get('logger').error('Problem in adding funds. userId=' + user.id, {
                    originalError: error
                  });
                });
              }
            });
          } else {
            _this.set('primaryButtonBusy', false);
            _this.set('phonesInMultiEditMode', false);
          }
        }).catch(function (error) {
          _this.handleError(error);
          _this.set('primaryButtonBusy', false);
          _this.get('logger').error('Problem in adding/updating phone number. userId=' + user.id, {
            originalError: error
          });
        });
      },
      onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
        this.set('phonesInMultiEditMode', true);

        var oldPrimaryPhone = this.get('model.user.phoneNumbers').find(function (dsPhone) {
          return dsPhone.get('isPrimary');
        });

        if (oldPrimaryPhone) {
          this.set('oldPrimaryPhoneId', oldPrimaryPhone.get('id'));
        }
      },
      onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
        var phones = this.get('model.user.phoneNumbers');

        var oldPrimaryPhoneId = this.get('oldPrimaryPhoneId');

        phones.forEach(function (phone) {
          if (phone.get('phoneChanged')) {
            phone.setProperties({
              countryCode: phone.get('oldCountryCode'),
              number: phone.get('oldNumber')
            });
          }
          if (oldPrimaryPhoneId) {
            phone.setProperties({
              isPrimary: phone.get('id') === oldPrimaryPhoneId
            });
          }
        });

        this.set('phonesInMultiEditMode', false);
      },
      onPhoneNumberChange: function onPhoneNumberChange(phones) {
        this.set('phones', phones);
      },
      onPhoneNumbersValidityChange: function onPhoneNumbersValidityChange(validationState) {
        this.set('phonesValid', validationState.valid);
      },
      onFundsAmountChanged: function onFundsAmountChanged(amount) {
        this.set('model.user.funds.additionalAmount', amount);
      },
      toggleShowChangePaymentMethodPopUp: function toggleShowChangePaymentMethodPopUp() {
        this.toggleShowChangePaymentMethodPopUpCommon();
      },
      onClickChangePaymentMethod: function onClickChangePaymentMethod() {
        this.toggleShowChangePaymentMethodPopUpCommon();

        this.transitionToPaymentDetails();
      }
    },

    savePhoneNumbers: function savePhoneNumbers() {
      var _this2 = this;

      var promiseHashes = [];

      this.get('phones').forEach(function (phone) {
        var phoneNumberWithoutCountryCode = phone.number.number.replace('+' + phone.number.countryCode, '');

        var dsPhone = _this2.get('model.user.phoneNumbers').find(function (dsPhone) {
          return dsPhone.get('id') === phone.number.id;
        });

        if (Ember.isEmpty(phoneNumberWithoutCountryCode) && !Ember.isEmpty(dsPhone)) {
          promiseHashes.push(dsPhone.destroyRecord());

          return;
        }

        if (!Ember.isEmpty(phoneNumberWithoutCountryCode) && Ember.isEmpty(dsPhone)) {
          dsPhone = _this2.store.createRecord('userPhone');
        }

        if (!Ember.isEmpty(dsPhone)) {
          var phoneChanged = dsPhone.get('phoneChanged') || !(dsPhone.get('number') === phoneNumberWithoutCountryCode && dsPhone.get('countryCode') === phone.number.countryCode);

          if (phoneChanged) {
            if (!dsPhone.get('oldNumber')) {
              dsPhone.setProperties({
                oldNumber: dsPhone.get('id') ? dsPhone.get('number') : ''
              });
            }

            if (!dsPhone.get('oldCountryCode')) {
              dsPhone.setProperties({
                oldCountryCode: dsPhone.get('id') ? dsPhone.get('countryCode') : ''
              });
            }
          }

          dsPhone.setProperties({
            phoneType: phone.type.id,
            isPrimary: phone.number.isPrimary,
            isUSNumber: phone.number.isUSNumber,
            countryCode: phone.number.countryCode,
            number: phoneNumberWithoutCountryCode,
            phoneChanged: phoneChanged
          });

          promiseHashes.push(dsPhone.save({
            adapterOptions: {
              filters: {
                ntvCheckEnabled: phoneChanged
              }
            }
          }));
        }
      });

      return Ember.RSVP.hash(promiseHashes).then(function () {
        _this2.get('model.user.phoneNumbers').forEach(function (p) {
          return p.setProperties({ phoneChanged: false, oldNumber: null, oldCountryCode: null });
        });

        _this2.store.unloadAll('userPhone');
        return _this2.store.findAll('userPhone');
      });
    },
    setupCall: function setupCall() {
      var _this3 = this;

      var call = this.store.createRecord('call');

      var countryCode = this.get('selectedPhoneNumber.number.countryCode');
      var number = this.get('selectedPhoneNumber.number.number').replace('+' + countryCode, '');

      var phoneType = this.getPhoneTypeForCall();

      call.set('phoneNumberType', phoneType);
      call.set('phoneCountryCode', countryCode);
      call.set('webTrackId', this.get('webtrack'));
      call.set('phoneNumber', '' + countryCode + number);
      call.set('serviceId', this.get('model.listing.id'));
      call.set('isPostPay', this.get('model.payAfterConversationSelected'));
      call.set('clickId', this.get('clickid'));

      var startCall = function startCall() {
        return call.save().then(function (call) {
          _this3.transitionToRoute('call.status', {
            queryParams: {
              callid: call.get('id'),
              listingid: _this3.get('model.listing.id')
            }
          });
        }).catch(function (error) {
          _this3.get('logger').error('Problem in initating Call. userId=' + Ember.Application.user.id, {
            originalError: error
          });
          var callError = error ? error.errors[0] : null;
          var genericErrorMessage = 'An unknown error occurred when starting your call. Please try again or contact customer support for assistance.';

          if (!callError) {
            return _this3.handleError({
              errors: [{
                detail: genericErrorMessage
              }]
            });
          }

          switch (callError.resultCode) {
            // in the event the advisor is busy or in an arrange a call status... redirect to the arrange a call flow (aspx).
            case _callResultCodes.default.arrange:
            case _callResultCodes.default.busy:
              return window.location.href = '/calls/initiatecallstarter.aspx?sid=' + _this3.get('model.listing.id');

            case _callResultCodes.default.postPayError:
              _this3.set('model.isUserEligibleForConversationPostPay', false);
              _this3.set('model.conversationPaymentMethod', null);
              if (!_this3.get('model.accountBalanceWithExtraFundsMeetsMinimum')) {
                _this3.transitionToRouteWithCurrentParams(_this3.getParentRouteName() + '.details');
              }
              _this3.flashMessages.danger('Oops! We\'re sorry. You\'re not qualified for Seamless Pay. Your card has not been charged.', {
                sticky: true,
                showProgress: true,
                extendedTimeout: 500,
                destroyOnClick: false
              });
              return;
            default:
              return _this3.handleError({
                errors: [{
                  detail: callError.message || genericErrorMessage
                }]
              });
          }
        });
      };

      return startCall();
    },


    // TODO: The api only supports accepting a string description for phone type
    // (e.g.: work, home, mobile). This can be removed once the api is refactored.
    getPhoneTypeForCall: function getPhoneTypeForCall() {
      var _this4 = this;

      var phoneType = _phoneTypes.default.find(function (type) {
        return type.id === _this4.get('selectedPhoneNumber.type.id');
      });

      return phoneType ? phoneType.name.toLowerCase() : null;
    },
    toggleShowChangePaymentMethodPopUpCommon: function toggleShowChangePaymentMethodPopUpCommon() {
      this.toggleProperty('showChangePaymentMethodPopUp');
    }
  });
});