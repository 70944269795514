define('ingenio-web/mixins/scroll-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        scrollToElementOnMobileViewWithDelay: function scrollToElementOnMobileViewWithDelay(selector, offset) {
            if ($(window).width() < 700) {
                setTimeout(function () {
                    var aid = $(selector);
                    var topOffset = aid && aid.offset && aid.offset().top ? aid.offset().top : 0;

                    $('html,body').animate({ scrollTop: topOffset - (offset || 15) }, 'slow');
                }, 1000);
            }
        }
    });
});