define('ingenio-web/components/best-match/search', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        keyword: null,

        actions: {
            searchListings: function searchListings() {
                this.sendAction('searchListings', this.get('keyword'));
            }
        }
    });
});