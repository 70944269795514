define('ingenio-web/mixins/message-delivery-handler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        unreadNotifications: [],
        onVisibilityChanges: function () {
            var visible = this.get('visible');
            if (!visible) {
                return;
            }

            var unreadNotifications = this.get('unreadNotifications');

            if (!unreadNotifications || unreadNotifications.length == 0) {
                return;
            }
            unreadNotifications.forEach(function (notification) {
                return notification();
            });
            this.set('unreadNotifications', []);
        }.observes('visible'),

        handleMessageReceive: function handleMessageReceive(m) {
            var _this = this;

            this.get('chatChannelClient').sendMessageReceivedAction(m);

            var sendRead = function sendRead() {
                return _this.get('chatChannelClient').sendMessageReadAction(m);
            };

            if (this.get('visible')) {
                sendRead();
            } else {
                this.get('unreadNotifications').push(sendRead);
            }
        },

        actions: {
            onChatMessageDelivered: function onChatMessageDelivered(timeToken, type, value) {
                var message = this.get('messages').find(function (item) {
                    return item.get('sendContext.timetoken') === timeToken;
                });

                if (message) {
                    if (type === 'receipt' && value === 'received') {
                        message.get('sendContext').set('received', true);
                    }if (type === 'receipt' && value === 'read') {
                        setTimeout(function () {
                            return message.get('sendContext').set('read', true);
                        }, 500);
                    }
                    message.set('deliveryStatus', value);
                }
            }
        }
    });
});