define('ingenio-web/adapters/technical-feedback', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL() {
            var userId = Ember.Application.user.id;

            return Ember.ENV.getApiRoot() + '/users/' + userId + '/technical-feedbacks';
        }
    });
});