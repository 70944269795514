define('ingenio-web/models/pubnub-channel', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        channelName: (0, _attr.default)('string', { defaultValue: '' }),
        subscribeKey: (0, _attr.default)('string', { defaultValue: '' }),
        publishKey: (0, _attr.default)('string', { defaultValue: '' }),
        authKey: (0, _attr.default)('string', { defaultValue: '' })
    });
});