define('ingenio-web/models/chat-transcript', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        messages: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return [];
            }
        }),
        totalCount: (0, _attr.default)('number'),
        pageCount: (0, _attr.default)('number'),
        page: (0, _attr.default)('number'),
        pageSize: (0, _attr.default)('number'),
        hasPreviousPage: (0, _attr.default)('boolean'),
        hasNextPage: (0, _attr.default)('boolean'),
        optedOutTranscripts: (0, _attr.default)('boolean')
    });
});