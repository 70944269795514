define('ingenio-web/components/listings/draft-idv-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        idvUrl: Ember.ENV.urls.idvAuthPage + encodeURIComponent(Ember.ENV.urls.myAccountPage),

        didInsertElement: function didInsertElement() {
            this.$('.draft-idv-modal').modal('show');
        }
    });
});