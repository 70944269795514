define('ingenio-web/adapters/balance', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot) {
            var userId = Ember.Application.user.id;
            var apiBaseUrl = Ember.ENV.getApiRoot() + '/users/' + userId + '/balance';

            // TODO: ListingId should be moved to a query string for the api, rather than a part of the main URI.
            var listingId = snapshot.adapterOptions['listingId'] || null;

            return listingId ? apiBaseUrl + '/' + listingId : apiBaseUrl;
        },

        handleResponse: function handleResponse(status, headers, payload, requestData) {
            payload = {
                balance: Object.assign(payload, {
                    id: Ember.Application.user.id
                })
            };

            return this._super(status, headers, payload, requestData);
        }
    });
});