define('ingenio-web/video/video-details/route', ['exports', 'ingenio-web/routes/transaction-details-base'], function (exports, _transactionDetailsBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transactionDetailsBase.default.extend({
        model: function model(params) {
            var _this = this;

            return this.store.findRecord('videoChatDetail', params.session_id).then(function (videoChatDetail) {

                var listingId = videoChatDetail.get('listingId');
                var isVideoChat = true;

                var promises = {
                    listing: _this.store.queryRecord('listing-v2', { id: listingId, includeDeletedListing: true, includeArrangeChatStatus: true }),
                    transactionDetail: videoChatDetail,
                    isAdvisor: Ember.Application.user.id === videoChatDetail.get('advisorId'),
                    isVideoChat: true
                };

                return Ember.RSVP.hash(promises).then(function (result) {
                    return result;
                });
            });
        },


        actions: {
            error: function error(_error, transition) {
                if (_error.errors[0].status === '400') {
                    this.intermediateTransitionTo('not-authorized');
                } else if (_error.errors[0].status === '404') {
                    transition.abort();
                    window.location.href = '404error';
                } else {
                    return true;
                }
            }
        }
    });
});