define('ingenio-web/models/step', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        name: null,
        route: null,
        active: false
    });
});