define('ingenio-web/components/help-icon/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'span',

		didInsertElement: function didInsertElement() {
			$(this.element).find('.help-icon-container .fa-info-circle').popover({
				content: function content() {
					return $("#helpIconPopover").html();
				},
				trigger: "manual",
				placement: "top",
				html: true
			}).click(function (e) {
				$(this).popover("show");
				e.stopPropagation();
			});
		},

		actions: {
			closeHelpIconPopover: function closeHelpIconPopover() {
				$(".popover").popover("hide");
			}
		}
	});
});