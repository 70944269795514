define('ingenio-web/components/chat/send-text-area', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		enteredText: '',
		typingDisabled: false,

		initControl: function () {
			var that = this;
			function setAttribute() {
				if (that.element) {
					var textarea = $(that.element).find("textarea");
					textarea.attr("autocomplete", "nope");
				} else {
					window.setTimeout(setAttribute, 500);
				}
			}
			setAttribute();
		}.on('init'),

		placeholder: function () {
			if (this.get('customPlaceholder')) {
				return this.get('customPlaceholder');
			}

			var listening = this.get('listening');
			if (listening) {
				return 'Begin Speaking';
			}

			var userName = this.get('userName');
			if (userName) {
				return 'Message ' + userName;
			}

			return 'Type your message!';
		}.property('userName', 'listening', 'customPlaceholder'),

		keyDown: function keyDown(e) {
			var _this = this;

			//eslint-disable-line consistent-return
			if (this.attrs.onTyping) {
				this.attrs.onTyping.update(true);
			}

			//allow multiple line input by using ctrl+enter. keycode 13 = enter.
			if (e.keyCode === 13) {
				if (e.ctrlKey) {
					this.set('enteredText', this.get('enteredText') + '\n');
				} else {
					var userInput = this.get('enteredText');

					//make sure there is some text after removing all spaces and line breaks
					if ($.trim(userInput) && userInput.replace(/(\r\n|\n|\r)/gm, '').length > 0) {
						this.get('messageSent')(userInput);
					}
					if (this.attrs.onTyping) {
						Ember.run.later(this, function () {
							return _this.attrs.onTyping.update(false);
						}, 1000);
					}
					return false;
				}
			}

			this.sendAction('keyPress', e);
			if (this.attrs.onTyping) {
				Ember.run.later(this, function () {
					return _this.attrs.onTyping.update(false);
				}, 1000);
			}
		},

		onTypingDisabled: Ember.observer('typingDisabled', function () {
			var typingDisabled = this.get("typingDisabled");

			if (typingDisabled) {
				Ember.$('textarea.send-textarea').trigger('blur');
			}
		})
	});
});