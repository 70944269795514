define('ingenio-web/helpers/cents-to-dollar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatDate = formatDate;

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    function formatDate(_ref) {
        var _ref2 = _toArray(_ref),
            value = _ref2[0],
            rest = _ref2.slice(1);

        if (!value) {
            return '$0.00';
        }
        return (value / 100).toFixed(2);
    }

    exports.default = Ember.Helper.helper(formatDate);
});