define('ingenio-web/appointment/payment-details/route', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/route', 'ingenio-web/utils/footer-types'], function (exports, _route, _footerTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    defineFooterType: function defineFooterType() {
      return _footerTypes.default.MINIMAL;
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var model = this.modelFor(this.getParentRouteName());

      if (!model.get("userOperation")) {

        var paymentMethods = model.get('user.paymentMethods');

        var defaultPaymentMethod = paymentMethods.find(function (method) {
          return method.get('isPrimary');
        }) || paymentMethods.get('firstObject');
        if (defaultPaymentMethod && defaultPaymentMethod.get('id') > 0 && !defaultPaymentMethod.get('isExpired')) {
          var step = ".confirmation";

          if (model.get('user.phoneNumbers').filter(function (phone) {
            return phone.get('isPrimary') && phone.get('isSmsNotificationEnabled');
          }).length === 0) {
            step = ".phone-number";
          }
          this.replaceWith('' + this.getParentRouteName() + step, {
            queryParams: transition.queryParams
          });
        }
      }
    },
    skipDetailsRedirect: function skipDetailsRedirect() {
      return true;
    }
  });
});