define('ingenio-web/components/field-error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        fieldState: function () {
            var fieldName = this.get('fieldName');
            var model = this.get('model');
            var checkTouched = this.get('checkTouched') || false;

            if (!model.isTouched(fieldName) && checkTouched) {
                return {
                    valid: true,
                    message: ''
                };
            }

            var result = {
                valid: !model.hasError(fieldName),
                message: model.errorMessage(fieldName)
            };

            return result;
        }.property('model.validationErrors.length', 'model.touched.length'),

        fieldStateObserver: function () {
            var markGroup = this.get('markGroup');
            var checkTouched = this.get('checkTouched') || false;

            if (markGroup) {
                var fieldName = this.get('fieldName');
                var model = this.get('model');

                // Handle touched / pristine
                if (model.isTouched(fieldName) && checkTouched) {
                    $(this.element).parent().removeClass('pristine');
                    $(this.element).parent().addClass('touched');
                } else {
                    $(this.element).parent().removeClass('touched');
                    $(this.element).parent().addClass('pristine');
                }

                // Handle error / invalid
                if (model.hasError(fieldName) && (model.isTouched(fieldName) || !checkTouched)) {
                    $(this.element).parent().addClass('invalid has-error');
                } else {
                    $(this.element).parent().removeClass('invalid has-error');
                }
            }
        }.observes('model.validationErrors.length', 'model.touched.length')
    });
});