define('ingenio-web/adapters/user-offer-qualification', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    //overriding default path
    pathForType: function pathForType() {
      var userId = Ember.Application.user.id;

      return 'users/' + userId + '/offer-qualification';
    }
  });
});