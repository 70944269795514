define('ingenio-web/models/chat-result', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr'], function (exports, _fragment, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        isRateable: (0, _attr.default)('boolean'),
        isTakingCalls: (0, _attr.default)('boolean')
    });
});