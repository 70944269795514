define("ingenio-web/video/session-ended/route", ["exports", "ingenio-web/utils/header-types"], function (exports, _headerTypes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    defineHeaderType: function defineHeaderType() {
      return _headerTypes.default.FULL;
    },

    model: function model(_params, transition) {
      var apiUrl = Ember.ENV.getApiRoot();
      var userInfo = Ember.Application.user;
      var sessionId = transition.queryParams.sessionId;

      return Ember.RSVP.hash({
        sessionId: sessionId,
        user: userInfo,
        endResult: Ember.$.getJSON(apiUrl + "/users/" + userInfo.id + "/video/sessions/" + sessionId + "/end-result").then(function (response) {
          return response.data;
        }).catch(function (error) {
          //session termination is in progress, we need to refresh the page
          setTimeout(function () {
            location.reload();
          }, 5000);
        }),
        videoChatTransactionDetails: Ember.$.getJSON(apiUrl + "/users/" + userInfo.id + "/transactions/video-chat-details/" + sessionId).then(function (response) {
          return response.videoChatDetail;
        }),
        videoSessionInfo: Ember.$.getJSON(apiUrl + "/users/" + userInfo.id + "/video/sessions/" + sessionId).then(function (response) {
          return response.data;
        }),
        rateable: Ember.$.getJSON(apiUrl + "/video/" + sessionId + "/feedback/rateable").then(function (response) {
          return response;
        }),
        feedbackStatus: Ember.$.getJSON(apiUrl + "/video/" + sessionId + "/feedback/status").then(function (response) {
          return response;
        })
      });
    },
    setupController: function setupController(controller, model) {
      var videoSessionInfo = model.videoSessionInfo || {};
      var videoChatTransactionDetails = model.videoChatTransactionDetails || {};
      var rateable = model.rateable || {};
      var feedbackStatus = model.feedbackStatus || {};
      var endResult = model.endResult || {};
      var appointmentDetails = videoSessionInfo.appointmentDetails || {};
      var cents = appointmentDetails.sessionPriceInCents || 0;
      var isRateable = rateable.isRateable || false;
      var isFeedbackQualifiedForDisplay = feedbackStatus.IsQualifiedForDisplay || false;
      var sessionPriceInDollars = (cents / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
      controller.set("listing", this.store.findRecord("listing", videoSessionInfo.listingId));
      controller.set("failureReason", endResult.failureReason);
      controller.set("endReason", endResult.endReason);
      controller.set("status", endResult.status);
      controller.set("appointmentDetails", appointmentDetails);
      controller.set("videoSessionInfo", videoSessionInfo);
      controller.set("videoChatTransactionDetails", videoChatTransactionDetails);
      controller.set("sessionPriceInDollars", sessionPriceInDollars);
      controller.set("feedbackStatus", feedbackStatus);
      controller.set("isRateable", isRateable);
      controller.set("isFeedbackQualifiedForDisplay", isFeedbackQualifiedForDisplay);
    }
  });
});