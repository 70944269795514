define('ingenio-web/serializers/payment-method', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        // TODO: This removes properties from the API response that do not conform to Ember Data
        // to suppress console warnings. Should be removed after the API is refactored.
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload) {
                delete payload.TotalCount;
            }

            return this._super.apply(this, arguments);
        }
    });
});