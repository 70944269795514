define('ingenio-web/mixins/checkout-flow/details/controller', ['exports', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/checkout-flow/controller-base'], function (exports, _errorHandler, _transitionHelpers, _controllerBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_controllerBase.default, _errorHandler.default, _transitionHelpers.default, {
    queryParams: ['listingid', 'pmode', 'webtrack', 'clickid', 'arrange', 'r', 'postpayerror', 'autoreloaderror', 'arrangeid'],

    pmode: 0,
    webtrack: 1,
    clickid: null,
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    enablePrimaryButton: Ember.computed('model.payAfterConversationSelected', 'isAddFundsAmountValid', 'model.isAddFundsRequired', 'model.user.funds.additionalAmount', function () {
      if (this.get('model.isUserEligibleForConversationPostPay') && !this.get('model.conversationPaymentMethodSelected')) {
        return false;
      }

      if (this.get('model.payAfterConversationSelected')) {
        return true;
      }

      if (!this.get('isAddFundsAmountValid')) {
        return false;
      }

      if (!this.get('model.isAddFundsRequired')) {
        return true;
      }

      return this.get('model.user.funds.additionalAmount');
    }),

    addFundsLabelText: Ember.computed('model.isAddFundsRequired', function () {
      return this.get('model.isAddFundsRequired') ? 'It looks like you don\'t have enough funds. Please add funds' : 'Want to speak longer? Add extra funds';
    }),

    actions: {
      onFundsAmountChanged: function onFundsAmountChanged(amount) {
        this.set('model.user.funds.additionalAmount', amount);
      },
      onIncreaseTypeChanged: function onIncreaseTypeChanged(selectedMinuteOption) {
        if (this.get('model.user.selectedMinuteOption') !== selectedMinuteOption) {
          this.set('model.user.selectedMinuteOption', selectedMinuteOption);
          this.set('model.user.buyMinutes', null);
        }
      },
      onBuyMinutesChanged: function onBuyMinutesChanged(buyMinutes) {
        this.set('model.user.selectedMinuteOption', 'custom');
        this.set('model.user.buyMinutes', buyMinutes);
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var additionalAmount = this.get('model.user.funds.additionalAmount');
        var fundsAmount = additionalAmount && !isNaN(additionalAmount) ? Number(additionalAmount) : null;

        if (fundsAmount) {
          // add decimal precision to funds / extra funds amount if specified.
          this.set('model.user.funds.additionalAmount', fundsAmount.toFixed(2));
        }

        if (this.get('model.hasExistingPaymentMethods')) {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.confirmation');
        } else {
          this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.payment-details');
        }
      }
    }
  });
});