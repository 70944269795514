define('ingenio-web/routes/featured-listings/results/listings', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        year: null,
        month: null,
        timePeriod: 'current-year',

        model: function model() {
            return this.store.query('listingBidResult', {
                timePeriod: this.get('timePeriod'),
                month: this.get('month'),
                year: this.get('year')
            });
        },


        actions: {
            setTimePeriod: function setTimePeriod(timePeriod) {
                this.set('year', timePeriod.get('year') || null);
                this.set('month', timePeriod.get('month') || null);
                this.set('timePeriod', timePeriod.get('timePeriod'));

                this.refresh();
            }
        }
    });
});