define('ingenio-web/serializers/price-bucket', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
            payload.userPriceBuckets.forEach(function (p) {
                return p.id = p.boxOrder;
            });
            return this._super(store, primaryModelClass, { priceBuckets: payload.userPriceBuckets });
        }
    });
});