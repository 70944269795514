define('ingenio-web/redeem-offer/details/controller', ['exports', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/checkout-flow/confirmation/controller', 'ingenio-web/mixins/checkout-flow/payment-details/offerRemovalHelper'], function (exports, _errorHandler, _transitionHelpers, _controller, _offerRemovalHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_errorHandler.default, _transitionHelpers.default, _offerRemovalHelper.default, {
    log_source: 'redeem-offer-flow-details',

    enablePrimaryButton: Ember.computed('isBusy', 'isAddFundsAmountValid', 'model.isAddFundsRequired', 'model.user.funds.additionalAmount', 'model.offerRequirementsMet', function () {
      if (!this.get('isAddFundsAmountValid') || this.get('isBusy') || !this.get('model.offerRequirementsMet')) {
        return false;
      }

      return this.get('model.user.funds.additionalAmount');
    }),

    initialize: function initialize() {
      this._super.apply(this, arguments);
      if (!this.get('model.user.funds.additionalAmount')) {
        var minimumDepositAmount = this.get('model.user.offer.qualifierAmount').toFixed(2);
        this.set('model.user.funds.additionalAmount', minimumDepositAmount);
        this.set('model.user.additionalAmount', minimumDepositAmount);
      }
    },
    checkOffer: function checkOffer() {
      var _this = this;

      return this.removeOfferIfNotQualified(this.get('model.user.offer.id'), this.get('logger')).then(function (offerStatus) {
        if (offerStatus === 'user-offer-deleted') {
          var offerId = _this.get('model.user.offer.id');

          _this.get('logger').log('Removed User Offer. userId=' + Ember.Application.user.id + ', offerId=' + offerId);

          _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.no-offer');
        } else {
          return 'add-funds';
        }
      }).catch(function (error) {
        _this.get('logger').error('Problem in removeOfferIfNotQualified(). userId=' + Ember.Application.user.id, {
          originalError: error
        });
        _this.set('isBusy', false);
        _this.handleError(error);
      });
    },


    actions: {
      onCancelButtonClick: function onCancelButtonClick() {
        var returnUrl = this.get('model.returnUrl') || '/myaccount';
        window.location = returnUrl;
      },
      onFundsAmountChanged: function onFundsAmountChanged(amount) {
        this.set('model.user.funds.additionalAmount', amount);
        this.set('model.user.additionalAmount', amount);
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this2 = this;

        this.clearErrors();

        var user = Ember.Application.user;

        this.set('isBusy', true);
        if (user.isAvsReqd) {
          var selectedCreditCard = this.get('model.paymentMethodSelected');

          //shoud we consider case when user enter cvcode and reopen ConfirmDepositDialog???
          var recheckAVSOnCancel = this.get('avsModel.recheckAVS') && this.get('avsModel.recheckAVS') == true;
          if (recheckAVSOnCancel || !this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
            this.showConfirmDepositDialog(user.isAvsReqd);
            //reset recheck state.
            var avsModel = this.get('avsModel');
            if (avsModel) {
              avsModel.set('recheckAVS', false);
            }
            return;
          }
        }

        this.checkOffer().then(function (result) {
          if (result === 'add-funds') {

            _this2.set('model.user.funds.offerId', _this2.get('model.user.offer.id'));
            _this2.set('model.user.tinyDescription', _this2.get('model.user.offer.tinyDescription'));

            _this2.addFundsToAccount().then(function () {
              _this2.set('isBusy', false);

              _this2.transitionToRouteWithCurrentParams(_this2.getParentRouteName() + '.success');
            }).catch(function (error) {
              if (error.code == "CvnFailed" || error.code == "BothCvnAvsFailed") {
                user.isCvnReqd = true;
                _this2.set('model.paymentMethodSelected.cvvCode', null);
              }
              _this2.set('isBusy', false);
              _this2.handleError(error);
              _this2.set('primaryButtonBusy', false);
              _this2.get('logger').error('Problem in adding funds. userId=' + user.id, {
                originalError: error
              });
            });
          }
        });
      }
    }
  });
});