define('ingenio-web/models/listing', ['exports', 'ingenio-web/models/base/listing-base', 'ember-data/attr'], function (exports, _listingBase, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listingBase.default.extend({
        categoryId: (0, _attr.default)('number', { defaultValue: 198 }),
        minuteRate: (0, _attr.default)('number', { defaultValue: 3.99 }),
        listingDraftId: (0, _attr.default)('number'),

        totalRatingPoints: (0, _attr.default)('number'),
        lifetimeTransactionCount: (0, _attr.default)('number'),
        starRating: (0, _attr.default)('number'),
        averageRating: (0, _attr.default)('number'),
        ratingsCount: (0, _attr.default)('number'),
        cumulativeRatingsCount: (0, _attr.default)('number'),
        pendingOnIDV: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        statusCode: (0, _attr.default)('number'),

        StatusCodes: {
            Accepted: 1,
            NotAccepted: 2,
            Pending: 3,
            PendingVisible: 4,
            AcceptedEdited: 5,
            NotAcceptedEdited: 6,
            PendingOnIDV: 7
        },

        minuteRateAsNumber: Ember.computed('minuteRate', {
            get: function get() {
                return parseFloat(this.get('minuteRate') || 0);
            },
            set: function set(key, value) {
                this.set('minuteRate', parseFloat(value));
            }
        }),

        isUnderReview: Ember.computed('statusCode', {
            get: function get() {
                var UnderReviewStatusCodes = [this.get("StatusCodes").NotAccepted, this.get("StatusCodes").Pending, this.get("StatusCodes").NotAcceptedEdited];
                return UnderReviewStatusCodes.includes(this.get('statusCode'));
            }
        })
    });
});