define('ingenio-web/components/payment-details/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PaymentMode = Object.freeze({
		NewPaypal: 'new-paypal',
		ListPayment: 'list-payment',
		NewCreditCard: 'new-credit-card',
		EditCreditCard: 'edit-credit-card'
	});

	exports.default = Ember.Component.extend({
		hideApplePay: false, //passed in (to hide apple pay as a payment method)
		classNames: ['payment-details-container'],
		mixpanelClient: Ember.inject.service('mixpanel-client'),

		hasExistingPaymentMethods: Ember.computed('paymentMethods.@each', function () {
			return !Ember.isEmpty(this.get('paymentMethods'));
		}),

		showPaymentMethodsList: Ember.computed('paymentMode', function () {
			return this.get('paymentMode') === PaymentMode.ListPayment;
		}),

		showCreditCardForm: Ember.computed('paymentMode', function () {
			var paymentMode = this.get('paymentMode');

			return paymentMode === PaymentMode.NewCreditCard || paymentMode === PaymentMode.EditCreditCard;
		}),

		showPaypalForm: Ember.computed('paymentMode', function () {
			return this.get('paymentMode') === PaymentMode.NewPaypal;
		}),

		showCancelButton: Ember.computed('hasExistingPaymentMethods', 'paymentMode', function () {
			return this.get('hasExistingPaymentMethods') && this.get('paymentMode') !== PaymentMode.ListPayment;
		}),

		editCardDisabled: Ember.computed('paymentMode', function () {
			return this.get('paymentMode') === PaymentMode.EditCreditCard;
		}),

		hideAppleGooglePlay: function hideAppleGooglePlay() {
			this.set('showApplePayForm', false);
			this.set('showGooglePayForm', false);
		},


		isKeen: Ember.computed('Ember.ENV.domainId', function () {
			return Ember.ENV.domainId === 1;
		}),

		actions: {
			onNewCreditCard: function onNewCreditCard() {
				this.attrs.onPaymentModeChanged(PaymentMode.NewCreditCard);
				this.hideAppleGooglePlay();
			},
			onNewPaypal: function onNewPaypal() {
				this.attrs.onPaymentModeChanged(PaymentMode.NewPaypal);
				this.hideAppleGooglePlay();
			},
			onNewApplePay: function onNewApplePay() {
				this.attrs.onPaymentModeChanged(null);
				this.set('showPaymentMethodsList', false);
				this.hideAppleGooglePlay();
				this.set('showApplePayForm', true);

				//mixpanel event
				var mixpanel = this.get('mixpanelClient');
				if (mixpanel) {
					mixpanel.trackNewEvent('Apple Pay Tab Clicked');
				}
			},
			onNewGooglePay: function onNewGooglePay() {
				this.attrs.onPaymentModeChanged(null);
				this.set('showPaymentMethodsList', false);
				this.hideAppleGooglePlay();
				this.set('showGooglePayForm', true);

				//mixpanel event
				var mixpanel = this.get('mixpanelClient');
				if (mixpanel) {
					mixpanel.trackNewEvent('Google Pay Tab Clicked');
				}
			},
			onEditCreditCard: function onEditCreditCard(paymentMethodId) {
				this.attrs.onPaymentModeChanged(PaymentMode.EditCreditCard, {
					"paymentMethodId": paymentMethodId
				});
				this.hideAppleGooglePlay();
			},
			onCancel: function onCancel() {
				this.attrs.onPaymentModeChanged(PaymentMode.ListPayment);
				this.hideAppleGooglePlay();
			}
		}
	});
});