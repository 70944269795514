define('ingenio-web/utils/header-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Object.freeze({
        FULL: 'full-header',
        CUSTOM: 'custom-header',
        COLLAPSIBLE_CUSTOM: 'collapsible-custom-header',
        MINIMAL: 'minimal-header',
        MINIMALNOLINK: 'minimal-header-no-link',
        COLLAPSIBLE_MINIMAL: 'collapsible-minimal-header',
        COLLAPSIBLE_MINIMAL_NOLINK: 'collapsible-minimal-nolink-header',
        EMPTY: 'empty-header',
        COLLAPSIBLE_BEST_MATCH_MINIMAL: 'collapsible-best-match-minimal-header',
        BEST_MATCH_MINIMAL: 'best-match-minimal-header'
    });
});