define('ingenio-web/best-match/index/route', ['exports', 'ingenio-web/routes/public-route', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/utils/cookies'], function (exports, _publicRoute, _headerTypes, _footerTypes, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _publicRoute.default.extend({
        headTags: [{
            type: 'link',
            tagId: 'canonical-link',
            attrs: {
                href: 'https://www.keen.com/app/best-match',
                rel: 'canonical'
            }
        }],

        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.COLLAPSIBLE_BEST_MATCH_MINIMAL;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.BEST_MATCH_MINIMAL;
        },

        beforeModel: function beforeModel(transition) {
            var callId = transition.queryParams.callId;
            var agentId = transition.queryParams.agentId;
            var operatorId = transition.queryParams.operatorId;
            var repeatUserType = transition.queryParams.r;
            var minPrice = transition.queryParams.minprice;
            var maxPrice = transition.queryParams.maxprice;

            this.set('repeatUserType', repeatUserType);
            this.set('minPrice', minPrice);
            this.set('maxPrice', maxPrice);

            if (callId && agentId && operatorId) {
                var cookieValue = 'Enabled=1&AgentId=' + agentId + '&CallId=' + callId + '&OperatorId=' + operatorId;

                _cookies.default.createCookie('CLOVER', cookieValue, null, true, 'None');
            }
        },
        setupController: function setupController(controller) {
            var repeatUserType = this.get('repeatUserType');
            var minPrice = this.get('minPrice');
            var maxPrice = this.get('maxPrice');

            if (repeatUserType) {
                controller.set('repeatUserType', repeatUserType);
            }

            if (minPrice) {
                controller.set('minPrice', minPrice);
            }

            if (maxPrice) {
                controller.set('maxPrice', maxPrice);
            }
        },


        actions: {
            signIn: function signIn() {
                window.location.href = '/Registration/Login.aspx';
            },
            register: function register() {
                var tid = this.get('controller.listingstid');
                var tidParm = tid ? '?tid=' + tid : '';

                window.location.href = '/Registration/Registration.aspx' + tidParm;
            },
            searchListings: function searchListings(keyword) {
                this.transitionTo('best-match.clover.search', keyword || '');
            }
        }
    });
});