define('ingenio-web/components/featured-listings/quality-score', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        singleColumn: false,

        sortedListingQualityMetrics: Ember.computed('listingQualityMetrics.[]', function () {
            return this.get('listingQualityMetrics').sortBy('componentType');
        })

    });
});