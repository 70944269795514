define('ingenio-web/components/picture-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['picture-item-container'],

        actions: {
            onDeleteButtonClicked: function onDeleteButtonClicked() {
                this.attrs.onDeleteButtonClicked();
            }
        }
    });
});