define('ingenio-web/models/advisor-metric', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        callSuccessRate: (0, _attr.default)('string'),
        chatSuccessRate: (0, _attr.default)('string'),
        blockRate: (0, _attr.default)('string'),
        optedInForOffers: (0, _attr.default)('boolean'),
        totalRevenue: (0, _attr.default)('string'),
        satisfactionGuarenteeRate: (0, _attr.default)('string'),
        callChatSuccessRate: (0, _attr.default)('string'),
        newCustomerConversionRate: (0, _attr.default)('string'),
        repeatBondRate: (0, _attr.default)('string')
    });
});