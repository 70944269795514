define('ingenio-web/components/myaccount/customer-contact-history-grid', ['exports', 'moment', 'lodash', 'ingenio-web/utils/customer-utils', 'ingenio-web/helpers/format-duration-unit'], function (exports, _moment, _lodash, _customerUtils, _formatDurationUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gridApi: null,
    gridColumnApi: null,
    busy: Ember.inject.service('busy'),
    notifications: Ember.inject.service('toast'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    didInsertElement: function didInsertElement() {
      this.set('gridOptions.parent', this);
      this.set('register-as', this);
    },

    gridOptions: {
      pagination: true,
      suppressPaginationPanel: true,
      gridAutoHeight: true,
      enableColResize: true,
      enableSorting: true,
      suppressCellSelection: true,
      suppressClickEdit: true,
      sortingOrder: ['desc', 'asc'],
      rowSelection: 'multiple',
      rowGroupPanelShow: 'never',

      parent: null,
      icons: {
        sortAscending: '<i class="grid-sort-icon fa fa-caret-up"/>',
        sortDescending: '<i class="grid-sort-icon fa fa-caret-down"/>'
      },

      columnDefs: [{
        headerName: 'Date',
        field: 'contactDate',
        width: 160,
        suppressMenu: true,
        suppressSizeToFit: true,

        valueFormatter: function valueFormatter(params) {
          return params.value ? (0, _moment.default)(params.value).format('L h:mma') : '‒';
        }
      }, {
        headerName: 'Description',
        field: 'activityType',
        cellRenderer: function cellRenderer(params) {
          if (!params.value) return '';

          var activityTypeName = _customerUtils.default.getActivityTypeNameById(params.value);
          params.data.type = activityTypeName;

          var url = _customerUtils.default.getActivityUrl(params.data.customerId, params.data);
          var text = _customerUtils.default.getFormattedText2(activityTypeName);

          if (!url) {
            return text;
          }
          return '<a class="contact-type-link" href="' + url + '" data-object=\'' + JSON.stringify(params.data) + '\'>' + text + ' </a>';
        }
      }, {
        headerName: 'Title',
        field: 'listingTitle'
      }, {
        headerName: 'Amount',
        headerClass: 'col-header-money numeric-column',
        field: 'displayAmount',
        width: 100,
        suppressResize: true,
        suppressSizeToFit: true,
        type: 'numericColumn',
        suppressMenu: true,
        valueFormatter: function valueFormatter(params) {
          return params.value !== '$0.00' ? params.value : '‒';
        }
      }, {
        headerName: 'Duration',
        field: 'duration',
        valueFormatter: function valueFormatter(params) {
          var duration = _moment.default.duration(params.value);
          if (_moment.default.isDuration(duration) && duration.asMinutes() > 0) return (0, _formatDurationUnit.formatDurationUnit)([params.value]);

          return '‒';
        }
      }],

      onGridReady: function onGridReady(params) {
        if (this.parent && this.parent.onGridReady) {
          this.parent.onGridReady(params);
        }
      }
    },

    // bind all required events
    onGridReady: function onGridReady(params) {
      // get the current grids width and based on it define how columns are sized
      var gridWidth = this.get('element').offsetWidth;
      if (gridWidth > 500) {
        params.api.sizeColumnsToFit();
      }

      // control sort change
      params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));

      // control row selection
      params.api.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));

      // just in case store refs to grid and column api
      this.set('gridApi', params.api);
      this.set('gridColumnApi', params.columnApi);

      // get dats from server side
      this.refreshGrid();
    },

    // control grid pagination
    gridActualPageSizeChanged: function () {
      if (this.get('gridApi')) {
        this.get('gridApi').paginationSetPageSize(this.get('pagination.actualPageSize'));

        if (this.get('pagination.actualPageSize') == 0) {
          this.get('gridApi').showNoRowsOverlay();
        }
      }
    }.observes('pagination.totalRecords', 'pagination.actualPageSize'),

    gridPaginationChanged: function () {
      this.refreshGrid();
    }.observes('pagination.currentPage', 'pagination.pageSize'),

    // control grid row selection
    onSelectionChanged: function onSelectionChanged() {
      var _this = this;

      if (!this.get('selectedRows')) return;

      this.get('selectedRows').clear();

      var gridSelectedRows = this.get('gridApi').getSelectedRows();
      gridSelectedRows.forEach(function (selectedRow) {
        _this.get('selectedRows').addObject(selectedRow);
      });
    },

    // handle grid sorting
    onSortChanged: function onSortChanged(params) {
      this.refreshGrid();
    },

    // retreive grid data
    refreshGrid: function refreshGrid() {
      var _this2 = this;

      var gridApi = this.get('gridApi');

      this.get("busy").show();
      gridApi.deselectAll();
      gridApi.hideOverlay();

      var sorting = null;
      sorting = gridApi.getSortModel().map(function (item) {
        return {
          field: item.colId,
          order: item.sort == "desc" ? "Descending" : "Ascending"
        };
      });

      var advisorId = Ember.Application.user.id;
      var customerId = this.get('customerId');

      var pageNumber = this.get('pagination.currentPage');
      var rowsPerPage = this.get('pagination.pageSize');

      var sortField = 'contactDate';
      var sortOrder = 'Descending';
      if (sorting.length === 1) {
        sortField = sorting[0].field;
        sortOrder = sorting[0].order;
      }

      var queryParams = 'pageInfo.pageNumber=' + pageNumber + '&pageInfo.rowsPerPage=' + rowsPerPage + '&pageInfo.sortField=' + sortField + '&pageInfo.sortOrder=' + sortOrder;

      return Ember.RSVP.hash({
        data: Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customers/' + customerId + '/contact-history?' + queryParams, {
          method: 'GET',
          contentType: 'application/json'
        })
      }).then(function (result) {
        var contactHistoryConnection = result.data.customerContactHistory;

        _this2.get('pagination').set('totalRecords', contactHistoryConnection.totalItems);

        gridApi.setRowData(contactHistoryConnection.data.map(function (item) {
          item.customerId = _this2.get('customerId');
          return item;
        }));
      }, function () {
        _this2.get('notifications').error('Error getting data from server', 'Contact History Grid', {
          positionClass: 'toast-top-right'
        });
      }).finally(function () {
        _this2.get('busy').hide();
      });
    }
  });
});