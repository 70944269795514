define('ingenio-web/routes/call/status', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/helpers/fbm-user'], function (exports, _resetScrollOnTransition, _fbmUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        queryParams: {
            callid: {
                refreshModel: true
            }
        },

        //for non-primary model data
        nonPrimaryModelData: {
            listingInfo: {},
            specialOffer: {}
        },

        model: function model() {
            return this.store.createRecord('callStatus');
        },

        afterModel: function afterModel(model, transition) {
            var _this = this;

            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            var specialOffer = Ember.$.getJSON(apiUrl + '/special-offers?userId=' + userInfo.id).then(function (response) {
                return response.specialOffer;
            });

            var promises = {
                listingInfo: this.store.findRecord('listing', transition.queryParams.listingid),
                specialOffer: specialOffer
            };
            (0, _fbmUser.setFbmUserVar)();

            //waiting for all promises to resolve
            return Ember.RSVP.hash(promises).then(function (result) {
                _this.nonPrimaryModelData.listingInfo = result.listingInfo;
                _this.nonPrimaryModelData.specialOffer = result.specialOffer;
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('listing', this.nonPrimaryModelData.listingInfo);
            controller.set('specialOffer', this.nonPrimaryModelData.specialOffer);
            controller.fetchCallStatusAndMessage();
            controller.setInitialMessageFromBestMatchCookies();
            //TODO: muthu - Fetch advisor info also for the corresponding callid to be displayed on the page
        }
    });
});