define('ingenio-web/adapters/payment-method', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        //overriding default path, by default it will be based on model name "payment-method"
        pathForType: function pathForType() {
            var userId = Ember.Application.user.id;

            return 'users/' + userId + '/payment-methods';
        }
    });
});