define('ingenio-web/components/transaction-details/leave-feedback-consumer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        feedbackLink: function () {
            var listingId = this.get('model.transactionDetail.listingId');
            var transactionId = this.get('model.transactionDetail.id');
            var feedbackType = this.get('model.isChat') ? 4 : this.get('model.isMail') ? 2 : this.get('model.isVideoChat') ? 5 : 0;

            return '/Feedback/UserFeedback?serviceId=' + listingId + '&activityId=' + transactionId + '&Type=' + feedbackType + '&TAF=0&ReturnURL=/myaccountredirect';
        }.property('model.transactionDetail.listingId', 'model.transactionDetail.id', 'model.isChat')
    });
});