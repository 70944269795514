define('ingenio-web/components/video-list/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['video-list-container'],
        selectedVideoId: 0,

        actions: {
            onSelectExistingVideo: function onSelectExistingVideo(video) {
                this.set("selectedVideoId", video.get('uid'));
                console.log(video.get('uid'), 'is selected');
                this.attrs.onVideoSelected(video);
            }
        }
    });
});