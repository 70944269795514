define('ingenio-web/utils/chat-duration-manager-v2', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var ChatDurationManagerV2 = function () {
        function ChatDurationManagerV2(options) {
            _classCallCheck(this, ChatDurationManagerV2);

            this.options = {
                rechargeWarningTimeout: options.rechargeWarningTimeout || 60, //sec
                rechargeTimeout: options.rechargeTimeout || 60, //sec
                postPayExtendTimeout: options.postPayExtendTimeout || 20, //sec

                syncInterval: options.syncInterval || 5, //sec
                syncDelta: options.syncDelta || 200, //ms

                timerCallbacks: options.timerCallbacks || {
                    onRechargeWarning: onRechargeWarning,
                    onRecharge: onRecharge,
                    onRechargeTimeout: onRechargeTimeout,
                    onPostPayExtend: onPostPayExtend
                }
            };

            this.logger = options.logger;
            this.onTimeoutHandle = null; //main timer
            this.onExtendHandle = null; //extend postpay timer
            this.syncHandle = null; //sync timer
            this.serverNow = options.serverNow; //chatChannelClient.serverNow.bind(chatChannelClient)

            this.durationBeforeLastStop = 0;
            this.startTime = 0;
        }

        _createClass(ChatDurationManagerV2, [{
            key: 'startWithAvailabileTime',
            value: function startWithAvailabileTime(availableTime) {
                var startTime = this.serverNow();
                var rechargeTime = startTime + availableTime * 1000;

                this.start(startTime, rechargeTime);
            }
        }, {
            key: 'start',
            value: function start(startTime, rechargeTime) {
                //1) clear current timers
                this.clear();

                //2.a) save locally when event should happen
                this.rechargeTime = rechargeTime;

                //2.b) save startTime
                this.startTime = startTime;

                //2.c) clear stopTime
                this.stopTime = null;

                //3) calc warnTimeoutDelay
                var availableTime = rechargeTime - this.serverNow();
                var warnTimeout = this.options.rechargeWarningTimeout * 1000;
                var warnTimeoutDelay = availableTime - warnTimeout;

                if (warnTimeoutDelay > 0) {
                    //4.a) schedule timer for rechage warning if available time is greater than warning delay
                    this.scheduleRechargeWarning(rechargeTime, warnTimeoutDelay);
                } else {
                    //4.b) schedule timer for recharge dialog show if available time is less than warning delay
                    this.scheduleRecharge(rechargeTime);
                }

                //5) schedule timer for extend postpay chat
                this.schedulePostPayExtend(rechargeTime);

                //6) schedule timer for sync interval
                this.scheduleSyncHandle();
            }
        }, {
            key: 'stop',
            value: function stop() {
                if (this.stopTime) {
                    this.logger.log('Warning! ChatDurationManagerV2 was already paused.');
                    return;
                }

                this.stopTime = this.serverNow();
                var duration = this.stopTime - this.startTime;
                this.durationBeforeLastStop = this.durationBeforeLastStop + duration;
                //console.warn(`stop ${new Date().toISOString()} - ${this.serverNow()} - ${this.durationBeforeLastStop}`)
                this.clear();
            }
        }, {
            key: 'duration',
            value: function duration() {
                if (this.startTime === 0) {
                    return 0;
                }

                if (this.stopTime) {
                    return this.durationBeforeLastStop / 1000;
                }

                var now = this.serverNow();
                var duration = this.durationBeforeLastStop + now - this.startTime;

                return duration / 1000;
            }
        }, {
            key: 'scheduleSyncHandle',
            value: function scheduleSyncHandle() {
                var _this = this;

                var syncInterval = this.options.syncInterval * 1000;

                var syncDelta = this.options.syncDelta;
                var lastTime = this.serverNow();

                var syncTimers = function syncTimers() {
                    var diff = Math.abs(_this.serverNow() - lastTime);
                    var intervalWithDelta = syncInterval + syncDelta;

                    if (diff > intervalWithDelta) {
                        _this.logger.log('There was some timer delay... Doing timers resync. ScheduleSyncHandle.diff=' + diff + ', intervalWithDelta=' + intervalWithDelta);
                        //if timers out of sync restart timers with stored values
                        _this.start(_this.startTime, _this.rechargeTime);
                    } else {
                        //console.log(`OK - ${diff}`);
                    }
                    lastTime = _this.serverNow();
                };

                //Execute sync validation every syncInterval ms
                this.syncHandle = setInterval(function () {
                    return syncTimers();
                }, syncInterval);
            }
        }, {
            key: 'scheduleRechargeTimeout',
            value: function scheduleRechargeTimeout(rechargeTime) {
                var rechargeTimeoutCallback = this.options.timerCallbacks.onRechargeTimeout;

                if (!rechargeTimeoutCallback) {
                    this.logger.log('onRechargeTimeout callback is empty, can\'t schedule recharge timer.');
                    return;
                }

                var rechargeTimeout = this.options.rechargeTimeout * 1000;
                var rechargeTimeoutDelay = rechargeTime - this.serverNow() + rechargeTimeout;

                if (rechargeTimeoutDelay < 0) {
                    rechargeTimeoutDelay = 0;
                }

                this.onTimeoutHandle = setTimeout(function () {
                    return rechargeTimeoutCallback();
                }, rechargeTimeoutDelay);
            }
        }, {
            key: 'scheduleRecharge',
            value: function scheduleRecharge(rechargeTime) {
                var _this2 = this;

                var rechargeCallback = this.options.timerCallbacks.onRecharge;

                if (!rechargeCallback) {
                    this.logger.log('onRecharge callback is empty, can\'t schedule recharge timer.');
                    return;
                }

                var rechanrgeDelay = rechargeTime - this.serverNow();

                if (rechanrgeDelay < 0) {
                    rechanrgeDelay = 0;
                }

                var rechargeHandler = function rechargeHandler() {
                    rechargeCallback();
                    _this2.scheduleRechargeTimeout(rechargeTime);
                };

                //console.warn(`start ${new Date().toISOString()} - ${this.serverNow()} - ${rechanrgeDelay}`)

                this.onTimeoutHandle = setTimeout(function () {
                    return rechargeHandler();
                }, rechanrgeDelay);
            }
        }, {
            key: 'scheduleRechargeWarning',
            value: function scheduleRechargeWarning(rechargeTime, warnTimeoutDelay) {
                var _this3 = this;

                var onRechargeWarningCallback = this.options.timerCallbacks.onRechargeWarning;

                if (!onRechargeWarningCallback) {
                    this.logger.log('onRechargeWarning callback is empty, can\'t schedule warning timer.');
                    return;
                }

                var rechargeWarnHandler = function rechargeWarnHandler() {
                    onRechargeWarningCallback();
                    _this3.scheduleRecharge(rechargeTime);
                };

                this.onTimeoutHandle = setTimeout(function () {
                    return rechargeWarnHandler();
                }, warnTimeoutDelay);
            }
        }, {
            key: 'schedulePostPayExtend',
            value: function schedulePostPayExtend(rechargeTime) {
                var onPostPayExtendCallback = this.options.timerCallbacks.onPostPayExtend;

                if (!onPostPayExtendCallback) {
                    return;
                }

                var postPayExtendTimeout = this.options.postPayExtendTimeout * 1000;
                var availableTime = rechargeTime - this.serverNow();
                var extendDelay = availableTime - postPayExtendTimeout;

                if (extendDelay < 0) {
                    extendDelay = 0;
                }

                this.onExtendHandle = setTimeout(function () {
                    return onPostPayExtendCallback();
                }, extendDelay);
            }
        }, {
            key: 'clear',
            value: function clear() {
                if (this.onTimeoutHandle) {
                    clearTimeout(this.onTimeoutHandle);
                }

                if (this.onExtendHandle) {
                    clearTimeout(this.onExtendHandle);
                }

                if (this.syncHandle) {
                    clearTimeout(this.syncHandle);
                }
            }
        }, {
            key: 'durationFormatted',
            value: function durationFormatted() {
                var seconds = Math.round(this.duration());
                var minutes = Math.floor(seconds / 60);
                var hours = Math.floor(minutes / 60);

                seconds %= 60;
                minutes %= 60;
                hours %= 24;

                if (hours === 0) {
                    return this.padLeft(minutes) + ':' + this.padLeft(seconds);
                }

                return this.padLeft(hours) + ':' + this.padLeft(minutes) + ':' + this.padLeft(seconds);
            }
        }, {
            key: 'padLeft',
            value: function padLeft(time) {
                return time < 10 ? '0' + time : time;
            }
        }]);

        return ChatDurationManagerV2;
    }();

    exports.default = ChatDurationManagerV2;
});