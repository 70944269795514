define('ingenio-web/mixins/validatable', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        //used to distinguish components that are extended with this mixing from the rest
        validatable: true,
        //represents user-friendly name of the component to be validated
        userFriendlyName: '',
        //marks component as required
        isRequired: false,

        validationState: {
            valid: true,
            message: ''
        },

        setup: function () {
            //assumes this mixin is used from inside a component
            if (!(this instanceof Ember.Component)) {
                throw new Error('"Validatable" mixin is supposed to be used with components only.');
            }
        }.on('init'),

        //whenever component have validate method we call it by default when component renders, components that define didInsertElement
        //will have to take care of this themselves, this is just a convinience method, components doesn't have to define 'validate()'
        didInsertElement: function didInsertElement() {
            if (typeof this.validate === 'function') {
                this.validate();
            }
        },
        setInvalid: function setInvalid(msg) {
            this.set('validationState', {
                valid: false,
                message: msg
            });
        },
        setValid: function setValid() {
            this.set('validationState', {
                valid: true,
                message: ''
            });
        },


        validationObserver: function () {
            if (!this.get('validationState').valid) {
                this.$().addClass('invalid has-error');
                this.sendAction('onInvalid', this, this.get('validationState').message);
            } else {
                this.$().removeClass('invalid has-error');
                this.sendAction('onValid', this, '');
            }

            this.sendAction('onValidationStateChange', this.get('validationState'));
        }.observes('validationState.valid')
    });
});