define('ingenio-web/routes/application', ['exports', 'ingenio-web/utils/url', 'ingenio-web/utils/header-types', 'ingenio-web/utils/cookies', 'ingenio-web/utils/footer-types', '@sentry/browser', 'ingenio-web/utils/logger'], function (exports, _url, _headerTypes, _cookies, _footerTypes, _browser, _logger) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var MAX_AUTH_REDIRECTS = 2;

    function shouldRedirectToLoginPage() {
        var previousRedirectsCount = _cookies.default.getAuthSessionRedirectCount();

        if (previousRedirectsCount >= MAX_AUTH_REDIRECTS) {
            _cookies.default.clearAuthSessionRedirectCount();
            return false;
        }

        _cookies.default.updateAuthSessionRedirectCount();
        return true;
    }

    function _redirectToLoginPage() {
        //Also remove the tid from the return url
        var returnUrl = window.location.href;
        var loginUrl = Ember.ENV.urls.loginPage;
        var tidQueryParam = _url.default.findQueryParamFromUrl(returnUrl, 'tid');

        if (tidQueryParam) {
            //If the current url has tid, pass the tid to the login page to be processed in login/reg flow and remove it
            //from the returnurl(current url)
            returnUrl = _url.default.removeQueryParamFromUrl(returnUrl, 'tid');
            returnUrl = _url.default.appendQueryParamToUrl(returnUrl, {
                key: 'pmode',
                value: '1'
            });
            loginUrl = _url.default.appendQueryParamToUrl(loginUrl, tidQueryParam); //this becomes http://keen.com/login.aspx?tid=xxx

            if (window.location.href.indexOf('redeem-offer') > -1 || window.location.href.indexOf('add-money') > -1) {
                //If the current url is /app/#/redeem-offer?tid=blah, then when we redirect to login/registration page pass
                //the "nomobile=1" since we dont need the mobile page of login/reg in this flow
                loginUrl += '&nomobile=1&mode=ForceLogin';

                //add-money is very old page which we previously used with particular verions of offers mail. 
                //we no longer used this offers so add-money page was deleted
                //to be consistent with old links we added redirect to add-funds (new version with recent UI update) in case of request with tid param
                returnUrl = returnUrl.replace('add-money', 'redeem-offer');
            }

            loginUrl += '&returnUrl=' + encodeURIComponent(returnUrl); //append return url to login page
        } else {
            loginUrl += '?returnUrl=' + encodeURIComponent(returnUrl);
        }

        // fixes an issue where in some instances user experiences an infinite redirect between aspx and ember
        // (e.g.: issue with generating SessionID cookie but aspx things user is authenticated)
        if (shouldRedirectToLoginPage()) {
            window.location.replace(loginUrl);
        } else {
            window.location.replace(Ember.ENV.urls.errorPage);
        }
    }

    function currentUrlIsAddMoneyWithTid() {
        var currentUrl = window.location.href;
        var tidQueryParam = _url.default.findQueryParamFromUrl(currentUrl, 'tid');

        return !!((currentUrl.indexOf('add-money') > -1 || currentUrl.indexOf('redeem-offer') > -1) && tidQueryParam);
    }

    //Object to represent data prepared by the application which is available for all the routes
    var AppModel = Ember.Object.extend({
        headerTemplate: null,
        sessionId: null,
        isInPartnerMode: false,
        partnerSetting: null,
        headerContent: '', //Property to hold custom header content used by custom header templates. This is set during partner flow
        offerRibbon: {
            text: null,
            isVisible: false //top-most ribbon
        },
        userData: null,
        user: function () {
            if (!this.userData) {
                if (Ember.InitialRouteSettings.skipAuth) {
                    return { userId: 0 };
                }
                _redirectToLoginPage();
                return null;
            }
            return this.userData;
        }.property(),
        vendorTokens: {
            optimizelyProjectId: Ember.ENV.optimizelyProjectId,
            gtmContainerId: Ember.ENV.gtmContainerId,
            gaTagId: Ember.ENV.gaTagId,
            googleAdsTagId: Ember.ENV.googleAdsTagId
        }
    });

    Ember.RSVP.on('error', function (error) {
        (0, _logger.LogToSplunk)('EmberError_RSVP_OnError: ' + error, 'Error');

        if (Ember.InitialRouteSettings.skipAuth) {
            return;
        }

        if (error && error.status && error.status === 401 ||
        //ember adapters throw Unauthorized exceptions in a bit different format
        error && error.errors && error.errors.length > 0 && error.errors[0].status === '401') {
            console.log('401, redirecting to login page');
            _redirectToLoginPage();
        }
    });

    Ember.onerror = function (error) {
        var errorMsg;

        if (!error.stack) {
            errorMsg = 'No stack for that error: ';
            errorMsg += typeof error !== 'string' ? JSON.stringify(error) : error;
        } else {
            errorMsg = error.stack;
        }

        (0, _logger.LogToSplunk)('EmberError_OnError: ' + errorMsg, 'Error');

        console.error(errorMsg);
    };

    exports.default = Ember.Route.extend({
        appModel: null,
        defaultHeaderTemplateName: _headerTypes.default.MINIMAL,
        defaultFooterTemplateName: _footerTypes.default.MINIMAL,

        mixpanelClient: Ember.inject.service('mixpanel-client'),
        experimentation: Ember.inject.service(),

        init: function init() {
            Ember.InitialRouteSettings = {
                skipAuth: false
            };

            this.appModel = AppModel.create({
                headerTemplate: null,
                footerTemplate: null,
                sessionId: _cookies.default.getSessionId()
            });

            Ember.ENV.getApiRoot = function () {
                return Ember.ENV.apiRoot;
            };

            Ember.ENV.getLogApiRoot = function () {
                return Ember.ENV.logApiRoot;
            };
        },
        beforeModel: function beforeModel(transition) {
            var self = this;
            var apiUrl = Ember.ENV.getApiRoot();

            var partnerSettingsPromise = null;
            var initializeExperimentationPromise = this.get('experimentation').syncStore();

            //currently we support tid only if user already has a sessionid.
            if (this.appModel.get('sessionId')) {
                //If in partner mode, fetch the partner setting
                if (transition.queryParams.pmode && transition.queryParams.pmode === '1') {
                    this.get('appModel').set('isInPartnerMode', true);

                    partnerSettingsPromise = this.store.findRecord('session', this.appModel.get('sessionId')).then(function (session) {
                        self.set('appModel.partnerSetting', session.get('partnerSetting'));
                        Ember.RSVP.Promise.resolve();
                    });
                } else if (transition.queryParams.tid) {
                    this.get('appModel').set('isInPartnerMode', true);

                    //1) associate the tid with the current session 2) transition to the same route with pmode=1 instead
                    //of preserving tid in the url?
                    partnerSettingsPromise = this.store.findRecord('session', this.appModel.get('sessionId')).then(function (session) {
                        session.set('partnerSetting.tid', transition.queryParams.tid);

                        session.save().then(function (savedSession) {
                            self.set('appModel.partnerSetting', savedSession.get('partnerSetting'));
                            Ember.RSVP.Promise.resolve();
                        });
                    });
                }
            }

            if (Ember.InitialRouteSettings.skipAuth && !_cookies.default.getSessionId()) {
                Ember.Application = {
                    get user() {
                        return { userId: 0 };
                    }
                };

                self.addSiftScript();

                return Ember.RSVP.all([partnerSettingsPromise, initializeExperimentationPromise]);
            }

            //Redirect to login page if the user is not authenticated (or) in the case of add-money url
            if (Ember.ENV.shouldAuthenticate && (!this.appModel.get('sessionId') || currentUrlIsAddMoneyWithTid())) {
                _redirectToLoginPage();
                if (this.reject) {
                    this.reject();
                }
            }

            //todo restuta: move to cookies util
            var sessionId = _cookies.default.getSessionId();

            Ember.$.ajaxSetup({
                headers: {
                    'X-EpcApi-ID': sessionId
                }
            });

            var getUserPromise = Ember.$.getJSON(apiUrl + '/users/current').then(function (result) {
                var user = result.user;

                self.addSiftScript(user.id);

                //todo restuta: why do we fetch a user here and not in the model?
                //todo restuta: why do we use Ember.Application? Global objects are great way to get into trouble.
                // Use of AppModel is better approach if we need to share a user model
                Ember.Application.user = user;
                self.get('appModel').set('userData', user);
                var sentryConfig = Ember.ENV['@sentry/ember'];

                if (sentryConfig.enabled) {
                    // Set user information in Sentry's scope
                    _browser.configureScope(function (scope) {
                        scope.setUser({
                            id: user.id,
                            username: user.username
                        });
                    });
                }

                Ember.RSVP.Promise.resolve(user);
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log('Error getting current user!', error);

                // Handle case for local development environment.
                if (error.status === 401 && ['localdev', 'localdev2', 'localdev_pc'].includes(Ember.ENV.name)) {
                    //Propagate this error (It will be captured by error action and a new 'login' will be attempted).
                    throw error;
                }

                _redirectToLoginPage();
            });

            var promises = [getUserPromise, initializeExperimentationPromise];

            if (partnerSettingsPromise) {
                promises.push(partnerSettingsPromise);
            }

            return Ember.RSVP.all(promises);
        },
        addSiftScript: function addSiftScript(userId) {
            var dashboardToolMode = _cookies.default.getDashboardMode();
            var isCloverFlow = _cookies.default.isCloverFlow();
            var shouldSkipScript = dashboardToolMode === '1' || isCloverFlow;

            if (shouldSkipScript) {
                return;
            }

            var _user_id = userId;
            var _session_id = _cookies.default.getSift();

            var _sift = window._sift = window._sift || [];

            _sift.push(['_setAccount', Ember.ENV.siftKey]);
            _sift.push(['_setUserId', _user_id]);
            _sift.push(['_setSessionId', _session_id]);
            _sift.push(['_trackPageview']);

            var e = document.createElement('script');

            e.src = 'https://cdn.sift.com/s.js';
            document.body.appendChild(e);
        },
        model: function model() {
            return this.get('appModel');
        },


        actions: {
            error: function error(_error) {
                console.error('Application Route error', _error);

                (0, _logger.LogToSplunk)('EmberError_Route_Error: ' + _error, 'Error');

                var propagateErrorFurther = true;

                if (!_error.status && _error.statusText === 'error') {
                    var message = 'Looks like something went wrong, it\'s possible that our API is currently down. Please try again in a few seconds.';

                    _error.customMessage = message;
                    this.toast.error(message);
                }

                if (_error.status === 401) {
                    //todo restuta: to review
                    if (['localdev', 'localdev2', 'localdev_pc'].includes(Ember.ENV.name)) {
                        //in case of local testing we need to do login call
                        //because auth cookies can't be shared between 'Ember Server' (:4200) and 'Platform.Web'(:361)
                        console.log('Attempting login for local environment.');
                        Ember.$.ajax({
                            type: 'POST',
                            url: Ember.ENV.getApiRoot() + '/login',
                            data: JSON.stringify({
                                userNameEmail: Ember.ENV.userName,
                                password: Ember.ENV.userPassword
                            }),
                            contentType: 'application/json'
                        }).then(function (data, textStatus, request) {
                            var sessionId = request.getResponseHeader('X-EpcApi-ID');

                            document.cookie = 'SessionId=' + escape(sessionId);
                            // reload page so the new SessionId cookie is used for auth.
                            window.location.reload();
                        }).catch(function (err) {
                            // Handle case when local development login attempt fails. (Since SessionId will be absent.)
                            console.log('Error getting current user!', err);
                            // eslint-disable-next-line no-alert
                            alert('Could not load current user! Please check your login credentials for the environment ' + Ember.ENV.name + '!');
                            _redirectToLoginPage();
                        });
                        return false;
                    }
                    console.info('401, redirecting to login page');
                    _redirectToLoginPage();
                }

                return propagateErrorFurther;
            },


            //this action is sent automatically by each route during it's activation
            //this way we make sure we set appropriate header per route, see Index Router as an example
            //of how to set custom header
            setHeaderType: function setHeaderType(templateName) {
                if (templateName === 'collapsible-custom-header' || templateName === 'custom-header') {
                    //In case of custom header for partner flow, set the header content from the partner setting

                    if (this.get('appModel.partnerSetting.callFlowSetting.header.htmlContent')) {
                        this.get('appModel').set('headerContent', this.get('appModel.partnerSetting.callFlowSetting.header.htmlContent'));
                    } else if (this.get('appModel.partnerSetting.listingDisplaySetting.header.htmlContent')) {
                        this.get('appModel').set('headerContent', this.get('appModel.partnerSetting.listingDisplaySetting.header.htmlContent'));
                    }
                }

                var headerTemplatePath = this._getHeaderTemplatePath(templateName || this.get('defaultHeaderTemplateName'));

                this.get('appModel').set('headerTemplate', headerTemplatePath);
            },


            //this action is sent automatically by each route during it's activation
            //this way we make sure we set appropriate footer per route, see Index Router as an example
            //of how to set custom footer
            setFooterType: function setFooterType(templateName) {
                if (templateName === 'collapsible-custom-footer' || templateName === 'custom-footer') {
                    //In case of custom footer for partner flow, set the footer content from the partner setting
                    this.get('appModel').set('footerContent', this.get('appModel.partnerSetting.callFlowSetting.footer.htmlContent'));
                }

                var footerTemplatePath = this._getFooterTemplatePath(templateName || this.get('defaultFooterTemplateName'));

                this.get('appModel').set('footerTemplate', footerTemplatePath);
            },
            showOfferRibbon: function showOfferRibbon(offer) {
                // check if offer is applicable
                // it is not in case of AFQ widget
                var cookie = this.get('cookie');
                var cookieName = 'is-afq-widget';
                var isAfqWidget = cookie.getCookie(cookieName) || 'false';

                var ribbonText = offer.text;

                if (isAfqWidget.toLowerCase() === 'true') {
                    ribbonText = 'Ask a Psychic a Question for Free!';
                }

                if (ribbonText) {
                    var offerRibbon = {
                        text: ribbonText,
                        isAfqWidget: isAfqWidget.toLowerCase() === 'true',
                        isVisible: true
                    };

                    this.set('appModel.offerRibbon', offerRibbon);
                }
            },


            //not intended to be used directly
            hideOfferRibbon: function hideOfferRibbon() {
                this.set('appModel.offerRibbon', { isVisible: false });
            },
            redirectToLoginPage: function redirectToLoginPage() {
                _redirectToLoginPage();
            }
        },

        //todo restuta: revisit this, maybe we should use new render component by name HBS helper {{component <name>}}
        _getHeaderTemplatePath: function _getHeaderTemplatePath(templateName) {
            return 'partials/headers/' + templateName;
        },
        _getFooterTemplatePath: function _getFooterTemplatePath(templateName) {
            return 'partials/footers/' + templateName;
        }
    });
});