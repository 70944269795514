define('ingenio-web/models/member-picture', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _fragment, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        primaryVersion: (0, _attributes.fragment)('memberPictureInfo'),
        originalVersion: (0, _attributes.fragment)('memberPictureInfo'),
        thumbnailVersion: (0, _attributes.fragment)('memberPictureInfo'),
        size75Version: (0, _attributes.fragment)('memberPictureInfo'),
        isAssociatedWithService: (0, _attr.default)('boolean')
    });
});