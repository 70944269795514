define('ingenio-web/components/avscvn-validation-modal-content', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var cvvCodeSize = this.get('creditCard.cvvCodeSize');
            var mask = '9'.repeat(cvvCodeSize);

            this.$('.js_security-code').inputmask({ mask: mask });
        },


        countriesList: function () {
            return this.get('countries');
        }.property(),

        statesList: function () {
            var countryCode = this.get('creditCard.address.countryCode') || 'US';
            var country = this.get('countries').findBy('code', countryCode);

            if (country) {
                return country.states;
            }

            return [];
        }.property('creditCard.address.countryCode'),

        canContinue: function () {
            return this.get('creditCard.isValid') && this.get('creditCard.isCardValid');
        }.property('creditCard.isValid', 'creditCard.isCardValid'),

        hasClientSideValidationErrors: function () {
            return this.get('creditCard.validationState.valid') === false;
        }.property('creditCard.validationState.valid'),

        actions: {
            nameOnAccountTouched: function nameOnAccountTouched() {
                this.get('creditCard').addTouched('nameOnAccount');
            },

            cvvCodeTouched: function cvvCodeTouched() {
                this.get('creditCard').addTouched('cvvCode');
            },

            addressLine1Touched: function addressLine1Touched() {
                this.get('creditCard').addTouched('addressLine1');
            },

            addressLine2Touched: function addressLine2Touched() {
                this.get('creditCard').addTouched('addressLine2');
            },

            cityTouched: function cityTouched() {
                this.get('creditCard').addTouched('city');
            },

            postalCodeTouched: function postalCodeTouched() {
                this.get('creditCard').addTouched('postalCode');
            },

            cancel: function cancel() {
                this.sendAction('cancel', this.get('creditCard'));
            },

            continue: function _continue() {
                if (this.get('canContinue')) {
                    this.sendAction('continue', this.get('creditCard'));
                }
            }
        }
    });
});