define('ingenio-web/components/toggle-switch-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'label',
        classNames: ['btn btn-default'],
        attributeBindings: ['checked:checked'],
        classNameBindings: ['checked:active'],
        itemId: null,
        itemLabel: null,
        click: function click() {
            var itemId = this.get('itemId');

            this.set('selectedItemId', itemId);
            this.sendAction('itemSelected', itemId);
        },
        checked: function () {
            return this.get('itemId') === this.get('selectedItemId');
        }.property('selectedItemId')
    });
});