define('ingenio-web/mixins/auto-reload-helper', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var REPEAT_USER_MIN_DEPOSIT_AMT = Ember.ENV.repeatUserMinimumDepositAmount;

    exports.default = Ember.Mixin.create({
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        getMinutes: function getMinutes(amount, minuteRate) {
            return Math.floor(amount / minuteRate);
        },
        getNewUserPriceByBoxOrder: function getNewUserPriceByBoxOrder(boxOrder, minuteRate) {
            if (minuteRate > 10) {
                switch (boxOrder) {
                    case 1:
                        return Math.ceil(minuteRate * 2);
                    case 2:
                        return Math.ceil(minuteRate * 5);
                    case 3:
                        return Math.ceil(minuteRate * 10);
                    default:
                        return REPEAT_USER_MIN_DEPOSIT_AMT;
                }
            } else {
                return REPEAT_USER_MIN_DEPOSIT_AMT + (boxOrder - 1) * 10;
            }
        },
        getPriceByBoxOrder: function getPriceByBoxOrder(boxOrder, minuteRate) {
            if (this.userCreatedAccountInLast28Days()) {
                if (boxOrder > 0) {
                    return this.getNewUserPriceByBoxOrder(boxOrder, minuteRate);
                }

                return 0;
            }

            return Math.ceil(minuteRate * boxOrder * 10);
        },
        getPriceByBoxOrderAutoReload: function getPriceByBoxOrderAutoReload(boxOrder, minuteRate) {
            if (minuteRate <= 10) {
                switch (boxOrder) {
                    case 1:
                        return 20 .toFixed(2);
                    case 2:
                        return 40 .toFixed(2);
                    case 3:
                        return 60 .toFixed(2);
                }
            }

            if (minuteRate <= 20) {
                switch (boxOrder) {
                    case 1:
                        return 40 .toFixed(2);
                    case 2:
                        return 80 .toFixed(2);
                    case 3:
                        return 100 .toFixed(2);
                }
            }

            if (minuteRate <= 30) {
                switch (boxOrder) {
                    case 1:
                        return 60 .toFixed(2);
                    case 2:
                        return 120 .toFixed(2);
                    case 3:
                        return 180 .toFixed(2);
                }
            }

            if (minuteRate <= 50) {
                switch (boxOrder) {
                    case 1:
                        return 100 .toFixed(2);
                    case 2:
                        return 200 .toFixed(2);
                    case 3:
                        return 400 .toFixed(2);
                }
            }
        },
        userCreatedAccountInLast28Days: function userCreatedAccountInLast28Days() {
            var registeredAt = (0, _moment.default)(Ember.Application.user.registeredAt);

            return (0, _moment.default)().diff(registeredAt, 'days') <= 28;
        }
    });
});