define('ingenio-web/metrics-adapters/fb-pixel', ['exports', 'ember-metrics/utils/can-use-dom', 'ingenio-web/utils/object-transforms', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'fb-pixel';
    },
    init: function init() {
      var config = this.get('config');
      var betterImpressionId = config.betterImpressionId;


      if (_canUseDom.default) {
        /* eslint-disable */
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return;n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = '2.0';
          n.queue = [];t = b.createElement(e);t.async = !0;
          t.src = v;s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        /* eslint-enable */

        window.fbq('init', betterImpressionId);
      }
    },


    //This method should be used to fire event across all the registered FB pixel (using fbq.init)
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!_canUseDom.default) {
        return;
      }

      var compactedOptions = compact(options);
      var event = compactedOptions.event;


      if (!event) {
        return;
      }
      delete compactedOptions.event;

      if (window.fbq) {
        window.fbq('track', event, compactedOptions);
      }
    },


    /*
      This method should be used to fire event on a specific pixel id
      We don't have direct access to this method through the Metrics service since it exposes only 4 methods.
      (Ref: https://github.com/MelSumner/ember-metrics/blob/master/addon/services/metrics.js)
      We will need to call this method through the `invoke()` method of the metrics service
    */
    trackSingle: function trackSingle() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!_canUseDom.default) {
        return;
      }

      var compactedOptions = compact(options);

      var event = compactedOptions.event,
          partnerId = compactedOptions.partnerId;

      if (!event || !partnerId) {
        return;
      }
      delete compactedOptions.event;
      delete compactedOptions.partnerId;

      var config = this.get('config');
      var pixelId = void 0;
      var betterImpressionId = config.betterImpressionId;
      //TODO: We need to avoid the hardcoded check here as part of the adapter to fetch the pixelid.
      //Instead we can pass the pixelId to this method so that we will leave it to the client who is calling this method to fetch the pixelid

      switch (partnerId) {
        case 'bi':
          pixelId = betterImpressionId;
          break;
        default:
          return;
      }

      if (window.fbq) {
        window.fbq('trackSingle', pixelId, event, compactedOptions);
      }
    },
    willDestroy: function willDestroy() {
      if (!_canUseDom.default) {
        return;
      }

      removeFromDOM('script[src*="fbevents.js"]');

      delete window.fbq;
      delete window._fbq;
    }
  });
});