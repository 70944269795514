define('ingenio-web/adapters/application', ['exports', 'ember-data', 'ember-inflector', 'ingenio-web/utils/cookies'], function (exports, _emberData, _emberInflector, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _emberData.default.RESTAdapter.extend({
    host: Ember.ENV.getApiRoot(),

    headers: function () {
      //todo restuta: move to cookies util
      var sessionId = _cookies.default.getSessionId();

      return {
        'X-EpcApi-ID': sessionId, //'ecf3e22f-9afd-e411-940f-00155d0a1f61'
        'X-Domain-ID': Ember.ENV.domainId,
        'X-Uid': window.ENV.keenUid,
        'X-DY-ID': _cookies.default.getDynamicYieldID(),
        'X-DY-Session': _cookies.default.getDynamicYieldSession()
      };
    }.property().volatile(),
    /* volatile  ^^^ means: Call on a computed property to set it into non-cached mode. When in this mode the computed property will
      not automatically cache the return value. */

    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && payload.errors) {
        return payload.errors;
      } else if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object') {
        if (payload.Message) {
          return [{
            status: String(status),
            detail: String(payload.Message)
          }];
        } else if (payload.message) {
          return [{
            status: String(status),
            detail: String(payload.message)
          }];
        }

        return payload;
      }

      return [{
        status: String(status),
        title: 'The backend responded with an error',
        detail: String(payload)
      }];
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401) {
        var error = this.normalizeErrorResponse(status, headers, payload);

        /*  ncaughman - Adding To Propegate Http Status In Error
              (This can be removed once ember-data is upgraded ... newer versions implement DS.UnauthorizedError) */
        var adapterError = new _emberData.default.AdapterError([{
          title: 'Not Authorized (401)',
          status: '401',
          detail: error
        }]);

        // Include XHR Status Code In Adapter Error
        adapterError.status = status;

        return adapterError;
      }

      if (status === 422) {
        var _error = this.normalizeErrorResponse(status, headers, payload);

        var adapterError = new _emberData.default.AdapterError([{
          title: 'Unprocessable Entity',
          status: status,
          detail: _error.length > 0 ? _error[0].detail : undefined
        }]);

        // Include XHR Status Code In Adapter Error
        adapterError.status = status;

        return adapterError;
      }

      var response = this._super.apply(this, arguments);

      if (response && response.code) {
        response.set('code', status);
      }

      return response;
    },

    //making all urls to be "dasherized" style by default, so "domainInfo" becomes "domain-info"
    pathForType: function pathForType(type) {
      //following default adapter behaviour to pluralize type
      var pluralizedType = (0, _emberInflector.pluralize)(type);

      return Ember.String.dasherize(pluralizedType);
    },

    userId: function () {
      return _cookies.default.getUserId();
    }.property().volatile()
  });
});