define('ingenio-web/constants/events', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        //CALL/CHAT Checkout flow related events
        CONFIRMATION_CALL_BUTTON_CLICKED: 'Confirmation Step - "Call Now" Button Clicked',
        CONFIRMATION_SAVE_PHONE_NUMBERS_BUTTON_CLICKED: 'Confirmation Step - "Save Numbers" Button Clicked',
        CONFIRMATION_CHAT_BUTTON_CLICKED: 'Confirmation Step - Chat Now Button Clicked'
    };
});