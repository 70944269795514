define("ingenio-web/constants/domain", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        KEEN: 1,
        PSYCHIC_CENTER: 6
    };
});