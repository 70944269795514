define('ingenio-web/components/intl-tel-input', ['exports', 'ember-intl-tel-input/components/intl-tel-input'], function (exports, _intlTelInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _intlTelInput.default;
    }
  });
});