define('ingenio-web/components/header/full-header/component', ['exports', 'moment', 'ingenio-web/utils/logger'], function (exports, _moment, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(), // self-contained xhr
		mixpanelClient: Ember.inject.service('mixpanel-client'),

		notifications: {
			hasUnacknowledged: false,

			items: {
				mail: 0,
				ping: 0,
				offers: 0,
				feedback: 0,
				freeMinutes: 0,
				arrangedConversations: 0
			},

			unacknowledged: {
				mail: false,
				ping: false,
				offers: false,
				feedback: false,
				freeMinutes: false,
				arrangedConversations: false
			}
		},

		notificationMenuPreviouslyExpanded: false,

		searchBoxOpen: false,
		changeSearchIcon: false,
		showHeaderSearchContent: false,
		userName: 'Anonymous Stranger',

		// TODO: remove after we fully enable alert
		alertMeEnabled: false,

		isLoggedIn: Ember.computed('Ember.Application.user', 'Ember.Application.user.id', function () {
			return Ember.Application.user && Ember.Application.user.id && Ember.Application.user.id > 0;
		}),

		isAdvisor: Ember.computed('Ember.Application.user', function () {
			return Ember.Application.user.isAdvisor;
		}),

		unreadMail: Ember.computed('notifications.items.mail', function () {
			return this.getNotificationItemCount('mail');
		}),

		unusedFreeMinutes: Ember.computed('notifications.items.freeMinutes', function () {
			return this.getNotificationItemCount('freeMinutes');
		}),

		unredeemedOffers: Ember.computed('notifications.items.offers', function () {
			return this.getNotificationItemCount('offers');
		}),

		arrangedConversations: Ember.computed('notifications.items.arrangedConversations', function () {
			return this.getNotificationItemCount('arrangedConversations');
		}),

		unreadPings: Ember.computed('notifications.items.ping', function () {
			return this.getNotificationItemCount('ping');
		}),

		outstandingFeedback: Ember.computed('notifications.items.feedback', function () {
			return this.getNotificationItemCount('feedback');
		}),

		notificationsIconCssClass: Ember.computed('notifications.hasUnacknowledged', function () {
			var targetClass = '';

			if (this.get('notifications.hasUnacknowledged')) {
				targetClass = 'notification-dot';
			}

			return targetClass;
		}),

		unreadMailCssClass: Ember.computed('unreadMail', 'notifications.unacknowledged.mail', function () {
			return this.getNotificationMenuItemCssClass(this.get('unreadMail'), this.get('notifications.unacknowledged.mail'));
		}),

		unreadPingCssClass: Ember.computed('unreadPings', 'notifications.unacknowledged.ping', function () {
			return this.getNotificationMenuItemCssClass(this.get('unreadPings'), this.get('notifications.unacknowledged.ping'));
		}),

		freeMinutesCssClass: Ember.computed('unusedFreeMinutes', 'notifications.unacknowledged.freeMinutes', function () {
			return this.getNotificationMenuItemCssClass(this.get('unusedFreeMinutes'), this.get('notifications.unacknowledged.freeMinutes'));
		}),

		unredeemedOffersCssClass: Ember.computed('unredeemedOffers', 'notifications.unacknowledged.offers', function () {
			return this.getNotificationMenuItemCssClass(this.get('unredeemedOffers'), this.get('notifications.unacknowledged.offers'));
		}),

		outstandingFeedbackCssClass: Ember.computed('outstandingFeedback', 'notifications.unacknowledged.feedback', function () {
			return this.getNotificationMenuItemCssClass(this.get('outstandingFeedback'), this.get('notifications.unacknowledged.feedback'));
		}),

		arrangedConversationsCssClass: Ember.computed('arrangedConversations', 'notifications.unacknowledged.arrangedConversations', function () {
			return this.getNotificationMenuItemCssClass(this.get('arrangedConversations'), this.get('notifications.unacknowledged.arrangedConversations'));
		}),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.errors = [];

			this.set('userName', Ember.Application.user.username);
			this.getAlertMeFeatureFlag().then(function (value) {
				return _this.set('alertMeEnabled', value);
			}).catch(function (error) {
				var logger = new _logger.default(_this.get('store'), {
					source: 'alert-me-feature-flag',
					consumerId: Ember.Application.user.id
				});

				logger.error('Problem when fetching alert me feature flag (userId=' + Ember.Application.user.id + ').', { originalError: error });
			});
		},
		didInsertElement: function didInsertElement() {
			var _this2 = this;

			this._super.apply(this, arguments);
			this.updateNotificationData();

			this.$('#notifications-menu-container').mouseenter(function () {
				return _this2.onNotificationMenuExpanded('desktop');
			}).mouseleave(function () {
				return _this2.onNotificationMenuCollapsed();
			});

			$('#advisors-menu-content > a').on('click', function (ele) {
				return _this2.trackCategoryLinks('Main', ele);
			}); // Main category desktop
			$('#categories-container > a').on('click', function (ele) {
				return _this2.trackCategoryLinks('Main', ele);
			}); // Main category Mobile
		},
		headerSearchIconClicked: function headerSearchIconClicked(inputId) {
			var _this3 = this;

			if (this.get('searchBoxOpen')) {
				this.doSearch(inputId);
			} else {
				//note: we need to add slider functionality here, same as on non-ember pages
				this.set('showHeaderSearchContent', true);
				Ember.run.later(this, function () {
					_this3.set('changeSearchIcon', true);
					Ember.$('.js_header-input-search').focus();
				}, 300);
				this.set('searchBoxOpen', true);
			}
		},
		doSearch: function doSearch(inputId) {
			var searchTerm = Ember.$(inputId).val();
			var st = Ember.$.trim(searchTerm);

			if (st === '') {
				st = '*';
			}
			window.location.href = '/search?st=' + st;
		},


		helpPageUrl: function () {
			return Ember.ENV.urls.zendeskDomainNameUs;
		}.property(),

		actions: {
			signIn: function signIn() {
				window.location.href = '/Registration/LoginStarter.aspx?Mode=StartWithLogin&returnurl=/myaccountredirect';
			},
			register: function register() {
				window.location.href = '/Registration/LoginStarter.aspx?Mode=StartWithReg&returnurl=/myaccountredirect';
			},
			hamburgerMenuToggle: function hamburgerMenuToggle() {
				// Ember.$ is equivalent to this.$ (this in this scope is not the component itself, but the element which called the action).
				// https://stackoverflow.com/questions/47368614/ember-js-this-not-working-on-component

				//check if notification menu is already opened and close it.
				var notificationDropdown = Ember.$('#notifications-mobile-menu-content');
				if (!notificationDropdown.is(':hidden')) {
					notificationDropdown.slideUp(0);

					var notificationIcon = Ember.$("#mobile-notifications-menu-container div");
					notificationIcon.removeClass().addClass(notificationIcon.attr('alt-css')).find('svg').removeClass('hidden');
				}

				//toggle hamburger icon to an X
				Ember.$("#hamburger-container").find('.hamburger').toggleClass('is-active');

				//now, open/close main menu
				Ember.$("#main-mobile-menu-content").slideToggle(400, function () {
					//collapse all inside items
					if ($(this).is(':hidden')) {
						$('.collapse').collapse("hide");
					}
				});
			},
			notificationsMenuToggle: function notificationsMenuToggle() {
				// Ember.$ is equivalent to this.$ (this in this scope is not the component itself, but the element which called the action).
				// https://stackoverflow.com/questions/47368614/ember-js-this-not-working-on-component

				//check if main menu is already opened and close it.
				var hamburgerDropdown = Ember.$('#main-mobile-menu-content');
				if (!hamburgerDropdown.is(':hidden')) {
					hamburgerDropdown.slideUp(0);
					Ember.$('#hamburger-container .hamburger').removeClass('is-active');
				}

				//toggle existing icon with an X
				var notificationsMobileMenuContent = Ember.$('#notifications-mobile-menu-content');
				var elem = Ember.$('#mobile-notifications-menu-container').find('div');

				if (notificationsMobileMenuContent.is(':hidden')) {
					this.onNotificationMenuExpanded('mobile-web');
					elem.attr('alt-css', elem.attr('class')).removeClass().addClass("close-notifications-icon-container").find('svg').addClass('hidden');
				} else {
					elem.removeClass().addClass(elem.attr('alt-css')).find('svg').removeClass('hidden');
					this.onNotificationMenuCollapsed();
				}

				//now, open/close notifications menu
				notificationsMobileMenuContent.slideToggle(400);
			},
			headerSearchIconDesktopClicked: function headerSearchIconDesktopClicked() {
				this.headerSearchIconClicked("#header-input-search-d");
			},
			headerSearchIconMobileClicked: function headerSearchIconMobileClicked() {
				this.headerSearchIconClicked("#header-input-search-m");
			},
			closeSearchPopover: function closeSearchPopover() {
				var _this4 = this;

				this.set('showHeaderSearchContent', false);
				Ember.run.later(this, function () {
					_this4.set('changeSearchIcon', false);
				}, 300);
				Ember.$('.js_header-input-search').val('');
				this.set('searchBoxOpen', false);
			}
		},

		getNotificationMenuItemCssClass: function getNotificationMenuItemCssClass(itemCount, hasUnacknowledged) {
			var targetClass = '';

			if (itemCount > 0 || itemCount === '99+') {
				targetClass += ' show-badge';
			}

			if (hasUnacknowledged) {
				targetClass += ' active';
			}

			return targetClass;
		},
		getNotificationItemCount: function getNotificationItemCount(item) {
			var notificationCount = this.get('notifications.items.' + item) || -1;
			return notificationCount > 99 ? '99+' : notificationCount;
		},
		updateLastExpandedDate: function updateLastExpandedDate() {
			var _this5 = this;

			// for performance reasons, we'll avoid re-calling this if the user has already expanded their nav menu previously.
			if (this.get('notificationMenuPreviouslyExpanded')) {
				return;
			}

			this.getNotifications().then(function (notifications) {
				// although the backend accepts the last expanded date for the PUT operation, we are always
				// setting the date on the db to the server's local time (pacific standard). in order to avoid issues
				// with the comparison operations & client timezones, we'll explicitly pass the PDT timezone here.
				notifications.set('menuLastExpandedDate', (0, _moment.default)().utc().toISOString());
				notifications.save();

				// we will sleep for 1 second before marking the menu as expanded to prevent accidental hovers
				// clearing the notification icon.
				_this5.set('updateLastExpandedDateTimeout', setTimeout(function () {
					_this5.set('updateLastExpandedDateTimeout', null);
					_this5.set('notificationMenuPreviouslyExpanded', true);
				}, 500));
			});
		},
		getNotifications: function getNotifications() {
			return this.get('store').findRecord('userNotification', Ember.Application.user.id);
		},
		getAlertMeFeatureFlag: function getAlertMeFeatureFlag() {
			var ALERT_ME_FEATURE_FLAG = 'Features.AlertMe.Enabled';

			return this.get('store').queryRecord('platformAttribute', { names: ALERT_ME_FEATURE_FLAG }).then(function (attribute) {
				var attributeValue = attribute.get('value').toLowerCase();
				return attributeValue === 'true';
			});
		},
		updateNotificationData: function updateNotificationData() {
			var _this6 = this;

			// for performance reasons, we'll avoid re-pulling notification data after the first expansion.
			if (this.get('notificationMenuPreviouslyExpanded')) {
				this.set('notifications.hasUnacknowledged', false);

				Object.keys(this.get('notifications.items')).forEach(function (item) {
					return _this6.set('notifications.unacknowledged.' + item, false);
				});

				return;
			}

			this.getNotifications().then(function (notifications) {
				// map of all data store property names > local names.
				var map = {
					mail: {
						count: 'unreadMail',
						unacknowledged: 'hasUnacknowledgedMail'
					},

					ping: {
						count: 'unreadPings',
						unacknowledged: 'hasUnacknowledgedPings'
					},

					offers: {
						count: 'unredeemedOffers',
						unacknowledged: 'hasUnacknowledgedUnredeemedOffers'
					},

					feedback: {
						count: 'outstandingFeedback',
						unacknowledged: 'hasUnacknowledgedOutstandingFeedback'
					},

					freeMinutes: {
						count: 'unusedFreeMinutes',
						unacknowledged: 'hasUnacknowledgedUnusedFreeMinutes'
					},

					arrangedConversations: {
						count: 'arrangedConversations',
						unacknowledged: 'hasUnacknowledgedArrangedConversations'
					}
				};

				Object.keys(_this6.get('notifications.items')).forEach(function (item) {
					_this6.set('notifications.items.' + item, notifications.get(map[item].count));
					_this6.set('notifications.unacknowledged.' + item, notifications.get(map[item].unacknowledged));
				});

				_this6.set('notifications.hasUnacknowledged', notifications.get('hasUnacknowledgedNotifications'));
			});
		},
		onNotificationMenuExpanded: function onNotificationMenuExpanded() {
			this.updateLastExpandedDate();
		},
		onNotificationMenuCollapsed: function onNotificationMenuCollapsed() {
			var graceTimeout = this.get('updateLastExpandedDateTimeout');

			if (!graceTimeout) {
				this.updateNotificationData();
				return;
			}

			clearTimeout(graceTimeout);
		},
		trackCategoryLinks: function trackCategoryLinks(category, element) {
			this.get('mixpanelClient').trackNewEvent(category + ' Category Clicked', { eventData: { category: element.target.innerText.trim() } });
		}
	});
});