define('ingenio-web/best-match/index/controller', ['exports', 'moment', 'ingenio-web/utils/cookies'], function (exports, _moment, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend({
    queryParams: ['q', 'listingstid', 'hlt'],
    questionSwitcher: Ember.inject.service('question-switcher'),
    q: Ember.computed.alias('questionSwitcher.q'),
    listingstid: null,
    hlt: null,
    repeatUserType: 0,
    givenAnswers: {},
    loading: false,

    mixpanelClient: Ember.inject.service('mixpanel-client'),
    eventEmitter: Ember.inject.service('event-emitter'),

    pagesCount: function () {
      var repeatUserType = this.get('repeatUserType');

      if (repeatUserType === '1') {
        return 4;
      }

      if (repeatUserType === '2') {
        return 3;
      }

      return 5;
    }.property('repeatUserType'),

    currentQuestionNo: function () {
      var questionIndex = this.get('q');

      return questionIndex + 1;
    }.property('q'),

    showQuestions: function () {
      var questionIndex = this.get('q');
      var pagesCount = this.get('pagesCount');

      return questionIndex >= 0 && questionIndex <= pagesCount;
    }.property('q'),

    answers: function () {
      var currentQuestion = this.get('currentQuestion');

      return currentQuestion.answers;
    }.property('currentQuestion'),

    getUserInformationFromFinalAnswers: function getUserInformationFromFinalAnswers(finalAnswers) {
      var firstNameQuestionId = 4;
      var birthDateQuestionId = 5;

      var firstName = finalAnswers.filter(function (answer) {
        return answer.questionId.toString() === firstNameQuestionId.toString();
      })[0];

      var birthDate = finalAnswers.filter(function (answer) {
        return answer.questionId.toString() === birthDateQuestionId.toString();
      })[0];

      return {
        firstName: firstName ? firstName.answer : null,
        birthDate: birthDate ? birthDate.answer : null
      };
    },
    updateSessionUserInformation: function updateSessionUserInformation(firstName, birthDate) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var userId = Ember.Application.user ? Ember.Application.user.id : 0;

        if (typeof userId === 'undefined' || userId < 1) {
          return resolve(null);
        }

        var updated = false;
        var userInfo = { userId: userId };
        if (firstName && firstName.length > 3) {
          userInfo.firstName = firstName;
          updated = true;
        }

        var formattedBirthDate = (0, _moment.default)(birthDate, 'MM/DD/YYYY');
        if (formattedBirthDate.isValid()) {
          userInfo.birthDate = formattedBirthDate.toDate();
          updated = true;
        }

        if (updated) {
          Ember.$.ajax(Ember.ENV.getApiRoot() + '/users/' + Ember.Application.user.id + '/bestmatch', {
            method: "PUT",
            contentType: "application/json",
            data: JSON.stringify(userInfo)
          }).then(function (result) {
            resolve(result);
          }, reject);
        } else {
          resolve(false);
        }
      });
    },
    removeQuestionFromBestMatchAnswers: function removeQuestionFromBestMatchAnswers(questionId, finalAnswers) {
      var question = finalAnswers.filter(function (p) {
        return p.questionId.toString() === questionId.toString();
      })[0];

      if (question) {
        var questionToRemove = finalAnswers.indexOf(question);

        finalAnswers.splice(questionToRemove, 1);
      }
    },
    saveBestMatchAnswersInCloverFlow: function saveBestMatchAnswersInCloverFlow(finalAnswers) {
      var _this = this;

      this.removeQuestionFromBestMatchAnswers(0, finalAnswers);

      var bestMatchAnswer = this.store.createRecord('bestMatchAnswer');

      bestMatchAnswer.answers = finalAnswers;
      bestMatchAnswer.uid = _cookies.default.getGroupCookieUnescaped('KeenUid', 'Uid');

      bestMatchAnswer.save().then(function (result) {
        var token = result.get('token');

        _this.transitionToRoute('best-match.clover.results', {
          queryParams: {
            id: token,
            listingstid: _this.get('listingstid'),
            hlt: _this.get('hlt')
          }
        });
      });
    },


    actions: {
      answerQuestions: function answerQuestions(answers) {
        var _this2 = this;

        var questionIndex = this.get('q');
        var givenAnswers = this.get('givenAnswers');

        answers.forEach(function (a) {
          givenAnswers[a.questionId] = a.answer;
        });

        this.get('questionSwitcher').goForward();

        var pagesCount = this.get('pagesCount');

        if (questionIndex >= pagesCount - 1) {
          var prop;

          var _ret = function () {
            var finalAnswers = [];

            /*jshint loopfunc: true */
            for (prop in givenAnswers) {
              if (givenAnswers.hasOwnProperty(prop)) {
                var answer = givenAnswers[prop];

                if (Ember.isArray(answer)) {
                  answer.forEach(function (a) {
                    //eslint-disable-line no-loop-func
                    finalAnswers.push({
                      questionId: prop,
                      answer: a
                    });
                  });
                } else {
                  finalAnswers.push({
                    questionId: prop,
                    answer: answer
                  });
                }
              }
            }

            // Update first name and date of birth for current users.
            var userInfo = _this2.getUserInformationFromFinalAnswers(finalAnswers);

            /*Segmenting Save Logic For Clover To Separate Function (To Keep Logic Cleanly Separated)*/
            if (_cookies.default.isCloverFlow()) {
              _this2.updateSessionUserInformation(userInfo.firstName, userInfo.birthDate).then(function () {
                _this2.saveBestMatchAnswersInCloverFlow(finalAnswers);
              });
              return {
                v: void 0
              };
            }

            var bestMatchAnswer = _this2.store.createRecord('bestMatchAnswer', {
              answers: finalAnswers,
              uid: window.ENV.keenUid
            });

            _this2.set('loading', true);

            Ember.RSVP.hash({
              sessionInfo: _this2.updateSessionUserInformation(userInfo.firstName, userInfo.birthDate),
              answers: bestMatchAnswer.save(),
              delay: new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.run.later(function () {
                  return resolve();
                }, 3000);
              })
            }).then(function (payload) {
              var token = payload.answers.get('token');

              _cookies.default.createCookie('keen-best-match', token);

              var queryParams = {
                id: token,
                listingstid: _this2.get('listingstid'),
                hlt: _this2.get('hlt')
              };

              var minPrice = _this2.get('minPrice');
              var maxPrice = _this2.get('maxPrice');

              if (minPrice) {
                queryParams.minprice = minPrice;
              }

              if (maxPrice) {
                queryParams.maxprice = maxPrice;
              }

              _this2.transitionToRoute('best-match.results', { queryParams: queryParams });
            });
          }();

          if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
        }
      }
    }
  });
});