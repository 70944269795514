define('ingenio-web/components/chat/voice-to-text', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        recognition: null,
        recognitionState: 'not_recognising',
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.$('.mic-denied').popover({
                content: function content() {
                    return _this.$('.js_mic-denied-alert').html();
                },
                placement: 'left',
                trigger: 'hover',
                html: true
            });

            if (!('webkitSpeechRecognition' in window)) {
                this.set('recognitionState', 'incorrect_browser');

                //can't reproduce absence of VoiceToTextNoSupport event in Firefox
                //so trigger it a bit latter after 1 sec
                Ember.run.later(this, function () {
                    return _this.get('mixpanelClient').trackNewEvent('Chat VoiceToText Not Supported');
                }, 1000);

                var incorrectBrowserAlertShown = this.get('cookie').getCookie('keen-speech-to-text-browser-show');

                if (!incorrectBrowserAlertShown) {
                    $('.incorrect-browser-alert').removeClass('hidden').show();
                    this.get('cookie').setCookie('keen-speech-to-text-browser-show', true, { path: '/' });
                }

                return;
            }
        },


        onListeningChange: function () {
            var self = this;
            Ember.run.next(this, function () {
                self.attrs.onListening.update(self.get('recognitionState') === 'recognising');
            });
        }.observes('recognitionState'),

        onTypingChange: function () {
            var typing = this.get('typing');
            var recognitionState = this.get('recognitionState');

            if (typing && recognitionState === 'recognising') {
                this.stopRecordingAndSetNewRecognitionState('not_recognising', 'onTypingChange');
            }
        }.observes('typing', 'recognitionState'),

        onSendDisabledChange: function () {
            var sendDisabled = this.get('sendDisabled');
            var recognitionState = this.get('recognitionState');

            if (sendDisabled && recognitionState === 'recognising') {
                this.stopRecordingAndSetNewRecognitionState('not_recognising', 'onSendDisabledChange');
            }
        }.observes('sendDisabled'),

        canBeCleared: function () {
            var text = this.get('text');
            var recognitionState = this.get('recognitionState');

            return recognitionState === 'recognising' && text && text.length > 0;
        }.property('recognitionState', 'text'),

        charsNumber: function () {
            var text = this.get('text');

            return text ? text.length : 0;
        }.property('text'),

        actions: {
            sendMessage: function sendMessage() {
                var recognitionState = this.get('recognitionState');

                if (recognitionState === 'recognising') {
                    this.stopRecordingAndSetNewRecognitionState('not_recognising', 'onSendMessage');
                }
                this.get('messageSent')(this.get('text'));
            },
            clearText: function clearText() {
                this.set('text', '');
            },
            recordStart: function recordStart() {
                var _this2 = this;

                if (this.get('recognitionState') === 'incorrect_browser') {
                    $('.incorrect-browser-alert').show();
                    return;
                }

                var recognition = this.get('recognition');

                if (this.get('recognitionState') === 'recognising') {
                    this.stopRecordingAndSetNewRecognitionState('not_recognising', 'click');
                    return;
                }

                /* jshint ignore:start */
                recognition = new webkitSpeechRecognition(); //eslint-disable-line
                /* jshint ignore:end */

                recognition.continuous = false;
                recognition.interimResults = false;
                recognition.lang = 'en-US';

                recognition.onerror = function (event) {
                    console.log(event);
                    if (event.error === 'no-speech') {
                        _this2.stopRecordingAndSetNewRecognitionState('not_recognising', 'no-speech');
                    } else if (event.error === 'audio-capture') {
                        _this2.set('recognitionState', 'no_microphone');
                    } else if (event.error === 'not-allowed') {
                        _this2.set('recognitionState', 'denied');
                        _this2.get('mixpanelClient').trackNewEvent('Chat VoiceToText Permission Not Granted');
                    } else {
                        _this2.stopRecordingAndSetNewRecognitionState('not_recognising', 'unknown_error');
                    }
                };

                var scrollTextArea = function scrollTextArea() {
                    var textArea = $('.message');

                    if (textArea) {
                        textArea.scrollTop(textArea[0].scrollHeight);
                    }
                };

                recognition.onresult = function (event) {
                    for (var i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            var text = _this2.get('text');

                            if (!text) {
                                text = '';
                            }

                            if (text.length > 300) {
                                return;
                            }

                            //to send notificaiton we need be consistent with key-press-notifier._keyIsChar
                            _this2.get('keyPress')({ which: 7 });

                            _this2.set('text', text + ' ' + event.results[i][0].transcript);

                            Ember.run.scheduleOnce('afterRender', _this2, scrollTextArea);
                        }
                    }
                };

                recognition.onend = function () {
                    if (_this2.get('recognitionState') === 'recognising') {
                        _this2.startRecording(recognition, 'onend', false);
                    }
                };

                this.startRecording(recognition, 'click ', this.get('recognitionState') !== 'denied');
                this.set('recognitionState', 'recognising');
                this.set('recognition', recognition);
            }
        },
        startRecording: function startRecording(recognition, source, needTracking) {
            if (recognition) {
                recognition.start();
                if (needTracking) {
                    this.get('mixpanelClient').trackNewEvent('Chat Voice Recording Started', { eventData: { source: source } });
                }
            }
        },
        stopRecordingAndSetNewRecognitionState: function stopRecordingAndSetNewRecognitionState(newState, source) {
            var recognition = this.get('recognition');

            if (recognition) {
                if (newState) {
                    this.set('recognitionState', newState);
                }
                recognition.stop();
                this.get('mixpanelClient').trackNewEvent('Chat Voice Recording Stopped', { eventData: { source: source } });
            }
        }
    });
});