define('ingenio-web/models/price-bucket', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        boxOrder: (0, _attr.default)('number'),
        totalPrice: (0, _attr.default)('number'),
        minuteQuantity: (0, _attr.default)('number'),
        default: (0, _attr.default)('boolean'),
        selected: (0, _attr.default)('boolean')
    });
});