define('ingenio-web/chat-setup/details/route', ['exports', 'ingenio-web/mixins/checkout-flow/details/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _route.default.extend({
        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);

            var checkoutFlowModel = this.checkoutFlowModel();

            if (transition.queryParams.postpayerror) {
                checkoutFlowModel.set('postpayerror', true);
            } else if (transition.queryParams.autoreloaderror) {
                checkoutFlowModel.set('autoreloaderror', true);
            } else if (!transition.queryParams.r && checkoutFlowModel.get('user.balance.chatMinutes') > 0 && checkoutFlowModel.get('conversationPaymentMethodSelected')) {
                this.replaceWith(this.getParentRouteName() + '.confirmation', {
                    queryParams: Object.assign({ r: true }, transition.queryParams)
                });
            }
        },
        setupController: function setupController(controller, model) {
            var checkoutFlowModel = this.checkoutFlowModel();

            if (checkoutFlowModel.get('postpayerror') && model.get('isUserEligibleForConversationPostPay')) {
                model.set('postpayerror', true);
                model.set('isUserEligibleForConversationPostPay', false);

                this.flashMessages.danger('Oops! We\'re sorry. You\'re not qualified for Seamless Pay. Your card has not been charged.', {
                    sticky: true,
                    showProgress: true,
                    extendedTimeout: 500,
                    destroyOnClick: false
                });
            } else if (checkoutFlowModel.get('autoreloaderror') && model.get('useAutoReload')) {
                model.set('autoreloaderror', true);
                model.set('isUserEligibleForConversationPostPay', false);
                model.set('useAutoReload', false);

                this.flashMessages.danger('Oops! We\'re sorry. You\'re not qualified for Auto Reload Chat. Your card has not been charged.', {
                    sticky: true,
                    showProgress: true,
                    extendedTimeout: 500,
                    destroyOnClick: false
                });
            }
            this._super.apply(this, arguments);
        },


        actions: {
            didTransition: function didTransition() {
                this.get('mixpanelClient').trackNewEvent('Chat Setup - Details Viewed');
            }
        }
    });
});