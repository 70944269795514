define('ingenio-web/adapters/advisor-availability-for-customer', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot) {
            var userId = Ember.Application.user.id;
            var advisorId = snapshot.adapterOptions['advisorId'];
            var apiBaseUrl = Ember.ENV.getApiRoot() + '/users/' + advisorId + '/availabilities';

            return apiBaseUrl + '?consumerId=' + userId;
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            var p = {
                advisorAvailabilityForCustomer: Object.assign(payload.advisorAvailability, {
                    id: Ember.Application.user.id
                })
            };

            return this._super(status, headers, p, requestData);
        }
    });
});