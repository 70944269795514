define('ingenio-web/routes/monitor', ['exports', 'ingenio-web/utils/monitor-client', 'ingenio-web/utils/task-bar-notification', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/utils/pubnub-client', 'ingenio-web/utils/logger', 'ingenio-web/utils/ably-monitor-client'], function (exports, _monitorClient, _taskBarNotification, _headerTypes, _footerTypes, _pubnubClient, _logger, _ablyMonitorClient) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service("mixpanel-client"),

        activate: function activate() {
            var _this = this;

            this._super.apply(this, arguments);
            $(document).attr('title', Ember.ENV.domainName + ' - Call and Chat Monitor');
            this.get("mixpanelClient").trackNewEvent("Chat Monitor Launched", {
                eventData: {
                    advisor_id: Ember.Application.user.id, source: 'web'
                }
            });
            // To register trackLinks, wait for the link to be available in the DOM
            setTimeout(function () {
                _this.get('mixpanelClient').trackLinksNew('#troubleshoot-chat-sound-link', 'Chat Troubleshooting Link Clicked', { source: 'Monitor' });
            }, 1000);
        },
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.EMPTY;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.EMPTY;
        },
        model: function model() {
            var pubnubMonitorChannel = this.store.createRecord('pubnubMonitorChannel');
            var advisorId = Ember.Application.user.id;
            var advisorCallAvailability = this.store.findRecord('advisorCallAvailability', advisorId);

            var advisorAvailability = this.store.findRecord('advisorAvailability', advisorId);

            var promises = {
                pubnubMonitorChannelPromise: pubnubMonitorChannel.save({
                    adapterOptions: { channelType: 'MonitorV2' }
                }),
                advisorCallAvailabilityPromise: advisorCallAvailability,
                advisorAvailabilityPromise: advisorAvailability
            };

            return Ember.RSVP.hash(promises).then(function (result) {
                return {
                    pubnubMonitorChannel: result.pubnubMonitorChannelPromise,
                    advisorCallAvailability: result.advisorCallAvailabilityPromise,
                    advisorAvailability: result.advisorAvailabilityPromise
                };
            });
        },
        setupController: function setupController(controller, model) {
            var _this2 = this;

            var pubnubMonitorChannel = model.pubnubMonitorChannel;
            var advisorCallAvailability = model.advisorCallAvailability;

            var advisorId = Ember.Application.user.id;

            var loggerOptions = {
                advisorId: advisorId,
                source: 'Monitor'
            };
            var logger = new _logger.default(this.store, loggerOptions);

            var advisorAvailability = model.advisorAvailability;

            this._super(controller, model);
            //Todo: GP-1590-Removed Ably connection
            // try {
            //     const ablyMonitorClient = new AblyMonitorClient(advisorId);

            //     ablyMonitorClient.onStatusReceived = status => {
            //         console.log(`Ably channel (${advisorId}) status changed: ${JSON.stringify(status)}`);
            //     };

            //     ablyMonitorClient.onMessageReceived = message => {
            //         const latency = message.data.ReceivedTimestamp - message.data.ServerTimestamp;

            //         logger.log('AblyMessage: command = InitiateChat, '
            //             + `messageId = ${message.data.MessageId}, details = ${message.data.Body}, latency = ${latency}.`);
            //     };

            //     ablyMonitorClient.subscribe();
            // } catch (error) {
            //     logger.error(`Ably connection error ${error}`);
            // }

            var monitorChannel = pubnubMonitorChannel.get('channel');
            var monitorPubnubOptions = {
                pubKey: '',
                subKey: monitorChannel.get('subscribeKey'),
                authKey: monitorChannel.get('authKey'),
                uuid: _pubnubClient.default.guid() + '-' + advisorId,
                channelName: monitorChannel.get('channelName'),
                heartbeatSeconds: 180,
                subscribeState: {
                    clientType: 'advisorMonitor',
                    username: advisorId
                },
                restore: { value: true }
            };

            var taskBarNotification = new _taskBarNotification.default({
                advisorId: advisorId,
                title: Ember.ENV.domainName + ' Notification',
                icon: Ember.ENV.domainId === 1 ? 'https://si.keen.com/D1_new-keen-logo_V1.png' : 'https://si.keen.com/D1_pc-logo_V2.png'
            }, logger);
            var store = this.store;

            var onCallStatusUpdateReceived = function onCallStatusUpdateReceived(msg) {
                store.findRecord('consumer', msg.ConsumerId).then(function (consumer) {
                    var message = msg.ConsumerName + ' is attempting to CALL you';

                    msg.showNotification = function () {
                        var onClick = function onClick() {
                            window.focus();
                        };

                        var closeNotification = taskBarNotification.showNotification(message, onClick);

                        msg.closeNotification = closeNotification;
                    };

                    controller.send('onCallStatusUpdateReceived', msg, consumer);
                });

                controller.set('previousAnswers', null); //clear previous answers details

                _this2.getBestMatchAnswer(msg.ConsumerId, store, controller);
            };

            var monitorOptions = {
                advisorId: advisorId,
                callbacks: {
                    onConnect: function onConnect() {
                        return controller.send('onConnect');
                    },
                    onDisconnect: function onDisconnect() {
                        return controller.send('onDisconnect', false);
                    },

                    onCallConnect: function onCallConnect() {
                        return controller.send('onConnectCallMonitor');
                    },
                    onCallDisconnect: function onCallDisconnect() {
                        return controller.send('onDisconnectCallMonitor');
                    },
                    onCallStatusUpdateReceived: onCallStatusUpdateReceived,
                    onLockStatusUpdateReceived: function onLockStatusUpdateReceived(msg) {
                        return controller.send('onLockStatusUpdateReceived', msg);
                    },

                    onChatMissedUpdateSuccess: function onChatMissedUpdateSuccess() {
                        return controller.send('onChatMissedUpdateSuccess');
                    },
                    onChatMissedUpdateFailed: function onChatMissedUpdateFailed() {
                        return controller.send('onChatMissedUpdateFailed');
                    },
                    onChatRequestReceived: function onChatRequestReceived(request) {
                        var id = request.consumerId;

                        store.findRecord('consumer', id).then(function (consumer) {
                            var consumeName = consumer.get('name');
                            var message = consumeName + ' is attempting to CHAT with you';

                            request.showNotification = function () {
                                var onClick = function onClick() {
                                    window.focus();
                                };
                                var closeNotification = taskBarNotification.showNotification(message, onClick);

                                request.closeNotification = closeNotification;
                            };
                            controller.send('onChatRequestReceived', request, consumer);
                        });

                        controller.set('previousAnswers', null); //clear previous answers details

                        _this2.getBestMatchAnswer(request.consumerId, store, controller);
                    },
                    onChatEnded: function onChatEnded() {
                        return controller.send('onChatEnded');
                    },
                    onChatAccepted: function onChatAccepted() {
                        return controller.send('onChatAccepted');
                    },
                    onChatDeclined: function onChatDeclined() {
                        return controller.send('onChatDeclined');
                    },
                    onDisconnectLogOff: function onDisconnectLogOff() {
                        return controller.send('onDisconnect', true);
                    },
                    clearReservedMessage: function clearReservedMessage() {
                        return controller.send('clearReservedMessage');
                    }
                }
            };

            var monitorClient = new _monitorClient.default(monitorOptions, monitorPubnubOptions, this.store, logger, this);

            controller.set('logData.advisorId', advisorId);

            var chatEnabled = true;

            controller.send('setup', monitorClient, chatEnabled);
            controller.send('setupCallAvailability', advisorCallAvailability);
            controller.send('setupAvailability', advisorAvailability);

            monitorClient.init();
        },
        getBestMatchAnswer: function getBestMatchAnswer(consumerId, store, controller) {
            $.ajax({
                url: Ember.ENV.getApiRoot() + '/v2/best-match/recent-response?userId=' + consumerId,
                //url: `${Ember.ENV.getApiRoot()}/v2/best-match/responses?token=5c51a8d0-401d-4258-8ae4-9261940f5873`,
                type: 'GET'
            }).then(function (response) {
                controller.set('previousAnswers', response.data);
            }).catch(function (e) {
                return console.error(e);
            });
        }
    });
});