define('ingenio-web/utils/pubnub-client-v3', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var PubnubClient = function () {
        function PubnubClient(options, pubnubClientInstance) {
            var _this = this;

            _classCallCheck(this, PubnubClient);

            var defaultHeartbeatSeconds = 120;

            this.PUBNUB = pubnubClientInstance || PUBNUB; //property injection (for mocks in unit tests) otherwise use global PUBNUB object

            if (!options) {
                throw new Error('options must be provided, required ones are pubKey or subKey, authKey, uuid, userName and channelName');
            }

            this.sentMessages = [];
            this.receivedMessages = [];
            this.options = {
                pubKey: options.pubKey || '',
                subKey: options.subKey,
                authKey: options.authKey,
                uuid: options.uuid,
                userName: options.userName,
                userId: options.userId || 0,
                channelName: options.channelName,
                heartbeatSeconds: options.heartbeatSeconds || defaultHeartbeatSeconds,
                subscribeState: options.subscribeState || null,
                callbacks: options.callbacks || {
                    subscribe: {
                        success: null,
                        failure: null
                    },
                    disconnect: null,
                    resubscribe: {
                        success: null
                    },
                    unsubscribe: {
                        success: null
                    },
                    messageSent: {
                        success: null,
                        failure: null
                    },
                    messageReceived: null,
                    timeout: null,
                    multiOccupancy: null //more than one user on channel
                }
            };

            this.pubnub = this.PUBNUB.init({
                publish_key: this.options.pubKey, //eslint-disable-line camelcase
                subscribe_key: this.options.subKey, //eslint-disable-line camelcase
                auth_key: this.options.authKey, //eslint-disable-line camelcase
                uuid: this.options.uuid,
                ssl: true
            });

            this.pubnub.time(function (serverTime) {
                _this.difference = _this.PUBNUB.now() - serverTime / 10000;
            });
        }

        _createClass(PubnubClient, [{
            key: 'serverNow',
            value: function serverNow() {
                return Math.round(this.PUBNUB.now() - this.difference);
            }
        }, {
            key: 'sendAnalytics',
            value: function sendAnalytics(sessionId) {
                var _this2 = this;

                try {
                    if (this.analyticsSent) {
                        return;
                    }
                    var mapMessage = function mapMessage(m) {
                        return {
                            fromUserId: m.FromUserId,
                            messageId: m.MessageId,
                            timetoken: m.ReceivedTimestamp || m.SentTimestamp
                        };
                    };
                    var receivedMessages = this.receivedMessages.filter(function (m) {
                        return m.Type === 'chat';
                    }).map(mapMessage);
                    var sentMessages = this.sentMessages.filter(function (m) {
                        return m.Type === 'chat';
                    }).map(mapMessage);

                    Ember.$.ajax(Ember.ENV.getApiRoot() + '/chat/analytics', {
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({
                            sessionId: sessionId,
                            receivedMessages: receivedMessages,
                            sentMessages: sentMessages
                        })
                    }).then(function () {
                        _this2.receivedMessages = [];
                        _this2.sentMessages = [];
                        _this2.analyticsSent = true;
                    }).catch(function (e) {
                        return console.log(e);
                    });
                } catch (error) {
                    console.log('Error in sendAnalytics: ' + error.message);
                }
            }
        }, {
            key: 'subscribe',
            value: function subscribe() {
                var self = this;
                var subscribeSettings = {
                    channel: self.options.channelName,
                    heartbeat: self.options.heartbeatSeconds,
                    state: self.options.subscribeState,
                    message: function message(msg) {
                        if (msg.From !== self.options.userName && self.options.callbacks.messageReceived) {
                            if (self.receivedMessages) {
                                msg.ReceivedTimestamp = self.serverNow(); //save timetoken from pubnub
                                self.receivedMessages.push(msg);
                            }
                            Ember.run(function () {
                                return self.options.callbacks.messageReceived(msg);
                            });
                        }
                    },
                    connect: function connect() {
                        if (self.options.callbacks.subscribe.success) {
                            Ember.run(function () {
                                return self.options.callbacks.subscribe.success();
                            });
                        }
                    },
                    disconnect: function disconnect() {
                        if (self.options.callbacks.disconnect) {
                            Ember.run(function () {
                                return self.options.callbacks.disconnect();
                            });
                        }
                    },
                    reconnect: function reconnect() {
                        if (self.options.callbacks.resubscribe && self.options.callbacks.resubscribe.success) {
                            Ember.run(function () {
                                return self.options.callbacks.resubscribe.success();
                            });
                        }
                    },
                    error: function error(errorMessage) {
                        if (self.options.callbacks.subscribe.failure) {
                            Ember.run(function () {
                                return self.options.callbacks.subscribe.failure(errorMessage);
                            });
                        }
                    }
                };

                //If the other use goes offline, a timeout event is triggered.
                if (self.options.callbacks.timeout || self.options.callbacks.multiOccupancy) {
                    subscribeSettings.presence = function (message) {
                        if (self.options.callbacks.multiOccupancy && (message.action === 'join' || message.action === 'state-change')) {
                            Ember.run(function () {
                                return self.options.callbacks.multiOccupancy(message);
                            });
                        }
                        if (self.options.callbacks.timeout && (message.action === 'timeout' || message.action === 'leave')) {
                            Ember.run(function () {
                                return self.options.callbacks.timeout(message);
                            });
                        }
                    };
                }

                if (self.options.restore) {
                    subscribeSettings.restore = self.options.restore.value;
                }

                self.pubnub.subscribe(subscribeSettings);
            }
        }, {
            key: 'hereNow',
            value: function hereNow(callBack) {
                this.pubnub.here_now({
                    channel: this.options.channelName,
                    callback: function callback(m) {
                        callBack(m);
                    }
                });
            }
        }, {
            key: 'unsubscribe',
            value: function unsubscribe(withoutCallback) {
                var self = this;

                self.pubnub.unsubscribe({
                    channel: self.options.channelName
                }); //TODO: how to check for errors here?

                if (withoutCallback) {
                    return;
                }

                if (self.options.callbacks.unsubscribe && self.options.callbacks.unsubscribe.success) {
                    Ember.run(function () {
                        self.options.callbacks.unsubscribe.success();
                    });
                }
            }
        }, {
            key: '_publish',
            value: function _publish(recipient, message, type) {
                var self = this;
                var msg = {
                    Type: type || 'chat',
                    To: recipient,
                    From: self.options.userName,
                    FromUserId: self.options.userId,
                    Body: message,
                    Timestamp: new Date().getTime(),
                    MessageId: PubnubClient.guid()
                };

                msg.SentTimestamp = self.serverNow(); //Save time from pubnub

                self.pubnub.publish({
                    channel: self.options.channelName,
                    message: msg,
                    callback: function callback(response) {

                        if (response && response[1] === 'Sent' && self.sentMessages) {
                            self.sentMessages.push(msg);
                        }

                        if (self.options.callbacks.messageSent && self.options.callbacks.messageSent.success) {
                            Ember.run(function () {
                                return self.options.callbacks.messageSent.success();
                            });
                        }
                    },
                    error: function error() {
                        if (self.options.callbacks.messageSent && self.options.callbacks.messageSent.failure) {
                            Ember.run(function () {
                                return self.options.callbacks.messageSent.failure();
                            });
                        }
                    }
                });
            }
        }, {
            key: 'sendChatMessage',
            value: function sendChatMessage(recipient, message) {
                this._publish(recipient, message, 'chat');
            }
        }, {
            key: 'sendPresencePingMessage',
            value: function sendPresencePingMessage(recipient, message) {
                this._publish(recipient, message, 'presencePing');
            }
        }, {
            key: 'sendStateMessage',
            value: function sendStateMessage(recipient, state) {
                this._publish(recipient, state, 'state');
            }
        }, {
            key: 'sendSystemMessage',
            value: function sendSystemMessage(recipient, message) {
                this._publish(recipient, message, 'system');
            }
        }, {
            key: 'setNewAuthKey',
            value: function setNewAuthKey(authKey) {
                this.pubnub.auth(authKey);
                this.options.authKey = authKey;
            }
        }], [{
            key: 'guid',
            value: function guid() {
                return PUBNUB.uuid();
            }
        }]);

        return PubnubClient;
    }();

    exports.default = PubnubClient;
});