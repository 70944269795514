define("ingenio-web/routes/listing/route-base", ["exports", "ingenio-web/routes/route-base"], function (exports, _routeBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeBase.default.extend({
        bindPopovers: function bindPopovers() {
            $(function () {
                $("#popover-communication-styles .editable-list-content").on("show.bs.popover", function () {
                    $('#popover-tools .editable-list-content').popover('hide');
                    $("#popover-skills .editable-list-content").popover("hide");
                    $("#popover-language .editable-list-content").popover("hide");
                    $("#popover-specialities .editable-list-content").popover("hide");
                });

                $('#popover-specialities .editable-list-content').on('show.bs.popover', function () {
                    $('#popover-communication-styles .editable-list-content').popover('hide');
                    $('#popover-skills .editable-list-content').popover('hide');
                    $('#popover-tools .editable-list-content').popover('hide');
                    $('#popover-language .editable-list-content').popover('hide');
                });

                $('#popover-skills .editable-list-content').on('show.bs.popover', function () {
                    $('#popover-tools .editable-list-content').popover('hide');
                    $('#popover-communication-styles .editable-list-content').popover('hide');
                    $('#popover-specialities .editable-list-content').popover('hide');
                    $('#popover-language .editable-list-content').popover('hide');
                });

                $('#popover-tools .editable-list-content').on('show.bs.popover', function () {
                    $('#popover-communication-styles .editable-list-content').popover('hide');
                    $('#popover-specialities .editable-list-content').popover('hide');
                    $('#popover-skills .editable-list-content').popover('hide');
                    $('#popover-language .editable-list-content').popover('hide');
                });

                $('#popover-language .editable-list-content').on('show.bs.popover', function () {
                    $('#popover-communication-styles .editable-list-content').popover('hide');
                    $('#popover-skills .editable-list-content').popover('hide');
                    $('#popover-tools .editable-list-content').popover('hide');
                    $('#popover-specialities .editable-list-content').popover('hide');
                });
            });
        }
    });
});