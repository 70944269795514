define('ingenio-web/models/technical-feedback-reason', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        reason: (0, _attr.default)('string'),
        reasonType: (0, _attr.default)('number')
    });
});