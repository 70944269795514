define('ingenio-web/models/customers/filtration-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    availableStartLetters: [{ id: '%', name: 'All' }],
    customerLists: [],
    customerList: '0',
    startLetter: '%',
    customerName: '',

    init: function init() {
      for (var i = 65; i < 91; i++) {
        this.availableStartLetters.push({ id: String.fromCharCode(i), name: String.fromCharCode(i) });
      }
    }
  });
});