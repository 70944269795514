define('ingenio-web/models/listing-quality-score', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        title: (0, _attr.default)('string'),
        categoryId: (0, _attr.default)('number'),
        categoryName: (0, _attr.default)('string'),
        positionInCurrentCategory: (0, _attr.default)('number', { defaultValue: 0 }),
        topCategoryId: (0, _attr.default)('number'),
        topCategoryName: (0, _attr.default)('string'),
        positionInTopCategory: (0, _attr.default)('number', { defaultValue: 0 }),
        qualityScores: (0, _attr.default)(),
        listingQualityMetrics: (0, _attr.default)()
    });
});