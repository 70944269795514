define('ingenio-web/services/question-switcher', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        q: 0,
        lastQuestion: 5,
        eventEmitter: Ember.inject.service('event-emitter'),

        emitSubStepChangeToClover: function emitSubStepChangeToClover(nextStep) {
            if (_cookies.default.isCloverFlow()) {
                var callId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'CallId');
                var agentId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'AgentId');
                var operatorId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'OperatorId');

                this.get('eventEmitter').emit('BestMatchStepChanged', {
                    callId: callId,
                    agentId: agentId,
                    operatorId: operatorId,
                    nextStep: nextStep
                });
            }
        },
        goForward: function goForward() {
            var q = this.get('q');
            var lastQuestion = this.get('lastQuestion');

            if (q < lastQuestion) {
                this.set('q', q + 1);
                this.emitSubStepChangeToClover(this.get('q'));
            }
        },
        goBack: function goBack() {
            var q = this.get('q');

            if (q > 0) {
                this.set('q', q - 1);
                this.emitSubStepChangeToClover(this.get('q'));
            }
        }
    });
});