define('ingenio-web/models/grid/relay-connection-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    pageSizes: [10, 25, 50, 100],
    pageSize: 10,
    totalRecords: 0,
    currentPage: 1,
    after: null,
    first: 10,
    before: null,
    connection: null,

    reset: function reset() {
      var changed = void 0;

      if (this.get('currentPage') !== 1) {
        this.set('currentPage', 1);
        changed = true;
      }

      if (this.get('after') !== null) {
        this.set('after', null);
        changed = true;
      }

      if (this.get('first') !== this.get('pageSize')) {
        this.set('first', this.get('pageSize'));
        changed = true;
      }

      if (this.get('before') !== null) {
        this.set('before', null);
        changed = true;
      }

      if (this.get('last') !== null) {
        this.set('last', null);
        changed = true;
      }

      return changed;
    },


    fromRecordNumber: function () {
      var result = (this.get('currentPage') - 1) * this.get('pageSize') + 1;
      if (result > this.get('totalRecords')) result = 1;
      return result;
    }.property('pageSize', 'currentPage', 'totalRecords'),

    toRecordNumber: function () {
      return this.get('currentPage') * this.get('pageSize');
    }.property('pageSize', 'currentPage', 'totalRecords'),

    pageSizeChanged: Ember.observer('pageSize', function () {
      this.reset();
    }),

    actualPageSize: function () {
      return this.get('actualToRecordNumber') - this.get('fromRecordNumber') + 1;
    }.property('fromRecordNumber', 'toRecordNumber', 'totalRecords'),

    actualFromRecordNumber: function () {
      var result = (this.get('currentPage') - 1) * this.get('pageSize') + 1;
      if (result > this.get('totalRecords')) result = 1;
      if (this.get('totalRecords') === 0) result = 0;
      return result;
    }.property('pageSize', 'currentPage', 'totalRecords'),

    actualToRecordNumber: function () {
      var result = this.get('currentPage') * this.get('pageSize');
      if (result > this.get('totalRecords')) result = this.get('totalRecords');
      return result;
    }.property('pageSize', 'currentPage', 'totalRecords'),

    totalPages: function () {
      var result = Math.ceil(this.get('totalRecords') / this.get('pageSize'));
      if (result === 0) result = 1;
      return result;
    }.property('pageSize', 'totalRecords'),

    canGoPreviousPage: function () {
      return this.get('currentPage') > 1;
    }.property('pageSize', 'currentPage'),

    canGoNextPage: function () {
      return this.get('currentPage') < this.get('totalPages');
    }.property('pageSize', 'currentPage', 'totalPages'),

    disableGoPreviousPage: function () {
      return !this.get('canGoPreviousPage');
    }.property('canGoPreviousPage'),

    disableGoNextPage: function () {
      return !this.get('canGoNextPage');
    }.property('canGoNextPage'),

    previousPage: function previousPage() {
      var connection = this.get('connection');
      if (this.get('canGoPreviousPage')) {

        this.set('currentPage', this.get('currentPage') - 1);

        this.set('after', null);
        this.set('first', null);
        this.set('before', connection.edges[0].cursor);
        this.set('last', this.get('pageSize'));

        return true;
      }
      return false;
    },
    nextPage: function nextPage() {
      var connection = this.get('connection');
      if (this.get('canGoNextPage')) {
        this.set('after', connection.edges[connection.edges.length - 1].cursor);
        this.set('first', this.get('pageSize'));
        this.set('before', null);
        this.set('last', null);

        this.set('currentPage', this.get('currentPage') + 1);

        return true;
      }
      return false;
    },
    setConnection: function setConnection(connection) {
      this.set('connection', connection);
      this.set('totalRecords', connection.totalCount);
    }
  });
});