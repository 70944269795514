define('ingenio-web/models/address', ['exports', 'ingenio-web/models/model-base', 'ember-data/attr'], function (exports, _modelBase, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        addressLine1: (0, _attr.default)('string', { defaultValue: '' }),
        addressLine2: (0, _attr.default)('string', { defaultValue: '' }),
        city: (0, _attr.default)('string', { defaultValue: '' }),
        stateCode: (0, _attr.default)('string', { defaultValue: 'CA' }),
        countryCode: (0, _attr.default)('string', { defaultValue: 'US' }),
        postalCode: (0, _attr.default)('string', { defaultValue: '' })
    });
});