define('ingenio-web/models/listing-bid-result', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        timePeriod: (0, _attr.default)('string'),
        bidEligibilityErrors: (0, _attr.default)(),
        bidId: (0, _attr.default)('number'),
        listingTitle: (0, _attr.default)('string'),
        listingCategory: (0, _attr.default)('string'),
        currentPositionInCategory: (0, _attr.default)('number'),
        totalClicks: (0, _attr.default)('number'),
        totalChargedClicks: (0, _attr.default)('number'),
        totalQualifiedClicks: (0, _attr.default)('number'),
        bidAmount: (0, _attr.default)('string'),
        revenueFromFlAcquiredCustomers: (0, _attr.default)('string'),
        revenueFromFlClicks: (0, _attr.default)('string'),
        totalClickCharge: (0, _attr.default)('string'),
        averageClickCharge: (0, _attr.default)('string')
    });
});