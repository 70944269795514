define('ingenio-web/mixins/invisible-input-validation-support', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        isRequired: false,
        minLength: 10,
        maxLength: function () {
            return this.get('maxlength') || 50;
        }.property('maxlength'),

        validationEnabled: true,

        _onInput: function () {
            this.validate();
        }.on('input'),

        _onFocusIn: function () {
            this.validate();
        }.on('focusIn'),

        validate: function validate() {
            if (!this.get('validationEnabled')) {
                return;
            }

            var onValidate = this.get('onValidate');

            if (onValidate) {
                var valid = onValidate(this.get('value'));

                if (valid) {
                    this.setValid();
                } else {
                    this.setInvalid('');
                }

                return;
            }

            var lengthOfInput = this.get('value') ? this.get('value').length : 0;

            var lengthIsWithingMaxAndMin = lengthOfInput >= this.get('minLength') && lengthOfInput <= this.get('maxLength');
            var somethingWasEntered = lengthOfInput > 0;
            var componentIsRequired = this.get('isRequired');

            if (!lengthIsWithingMaxAndMin && (somethingWasEntered || componentIsRequired)) {
                this.setInvalid('Should be at least ' + this.get('minLength') + ' characters long');
            } else {
                this.setValid();
            }
        }
    });
});