define('ingenio-web/mail/details/route', ['exports', 'ingenio-web/utils/footer-types', 'ingenio-web/utils/header-types'], function (exports, _footerTypes, _headerTypes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.FULL;
        },

        model: function model(params) {
            this.set('recipientId', params.recipientId);
            return this.store.findRecord('mailDetail', params.mail_id, {
                adapterOptions: { recipientId: params.recipientId }
            });
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            controller.set('recipientId', this.get('recipientId'));
        },


        actions: {
            error: function error(_error) {
                console.log('Error fetching mail details', _error);
                this.toast.error('An error occured!');
            }
        }
    });
});