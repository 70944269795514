define('ingenio-web/components/free-minutes-offer-banner/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        offerText: Ember.computed('freeMinutes', function () {
            var freeMinutes = this.get('freeMinutes');
            var minutes = freeMinutes === 1 ? 'Minute' : 'Minutes';

            return 'Free ' + freeMinutes + ' ' + minutes;
        })
    });
});