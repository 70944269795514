define('ingenio-web/components/header/collapsible-best-match-minimal-header/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isLoggedIn: Ember.computed('Ember.Application.user', 'Ember.Application.user.id', function () {
            return Ember.Application.user && Ember.Application.user.id && Ember.Application.user.id > 0;
        }),

        isAdvisor: Ember.computed('Ember.Application.user', function () {
            return Ember.Application.user.isAdvisor;
        })
    });
});