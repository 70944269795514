define('ingenio-web/default-payment-method/route', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types'], function (exports, _headerTypes, _footerTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		defineHeaderType: function defineHeaderType() {
			return _headerTypes.default.FULL;
		},
		defineFooterType: function defineFooterType() {
			return _footerTypes.default.FULL;
		},

		model: function model(params, transition) {
			var _this = this;

			var returnUrl = transition.queryParams.returnUrl;
			return Ember.RSVP.hash({
				returnUrl: returnUrl,
				paymentMethods: this.store.findAll('paymentMethod')
			}).then(function (payload) {
				return _this.transformPromiseHashIntoViewModel(payload);
			});
		},
		transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
			var defaultPaymentMethodId = void 0;

			// use the default payment method, by default, if found.
			// handle a weird edge case where the user does not have any primary
			// payment methods and choose the first object by default.
			if (!Ember.isEmpty(payload.paymentMethods)) {
				var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
					return method.get('isPrimary');
				}) || payload.paymentMethods.get('firstObject');

				defaultPaymentMethodId = defaultPaymentMethod.get('id');
			} else {
				this.transitionTo('add-funds.payment-details');
			}

			return {
				paymentMethods: payload.paymentMethods,
				selectedPaymentMethodId: defaultPaymentMethodId,
				returnUrl: payload.returnUrl
			};
		}
	});
});