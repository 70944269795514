define("ingenio-web/serializers/advisor-offer-participation", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = DS.RESTSerializer.extend({
        //Delete JSON root element
        serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
            Ember.merge(hash, this.serialize(record, options));
        }
    });
});