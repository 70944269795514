define('ingenio-web/add-funds/details/controller', ['exports', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/checkout-flow/confirmation/controller'], function (exports, _errorHandler, _transitionHelpers, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_errorHandler.default, _transitionHelpers.default, {
    log_source: 'add-funds-flow-details',

    enablePrimaryButton: Ember.computed('isBusy', 'isAddFundsAmountValid', 'model.isAddFundsRequired', 'model.user.funds.additionalAmount', function () {
      if (!this.get('isAddFundsAmountValid') || this.get('isBusy')) {
        return false;
      }

      return this.get('model.user.funds.additionalAmount');
    }),

    initialize: function initialize() {
      this._super.apply(this, arguments);
      if (!this.get('model.user.funds.additionalAmount')) {
        var minimumDepositAmount = 0;
        this.set('model.user.funds.additionalAmount', null);
        this.set('model.user.additionalAmount', minimumDepositAmount);
      }
    },


    actions: {
      onFundsAmountChanged: function onFundsAmountChanged(amount) {
        this.set('model.user.funds.additionalAmount', amount);
        this.set('model.user.additionalAmount', amount);
      },
      toggleShowChangePaymentMethodPopUp: function toggleShowChangePaymentMethodPopUp() {
        this.toggleShowChangePaymentMethodPopUpCommon();
      },
      onClickChangePaymentMethod: function onClickChangePaymentMethod() {
        this.toggleShowChangePaymentMethodPopUpCommon();

        this.transitionToPaymentDetails();
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();

        var user = Ember.Application.user;

        this.set('isBusy', true);
        if (user.isAvsReqd) {
          var selectedCreditCard = this.get('model.paymentMethodSelected');

          //shoud we consider case when user enter cvcode and reopen ConfirmDepositDialog???

          var recheckAVSOnCancel = this.get('avsModel.recheckAVS') && this.get('avsModel.recheckAVS') == true;
          if (recheckAVSOnCancel || !this.get('avsModel.isValid') && selectedCreditCard.get('isCreditCard')) {
            this.showConfirmDepositDialog(user.isAvsReqd);

            //reset recheck state.
            var avsModel = this.get('avsModel');
            if (avsModel) {
              avsModel.set('recheckAVS', false);
            }
            return;
          }
        }

        this.addFundsToAccount().then(function () {
          _this.set('isBusy', false);

          _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.success');
        }).catch(function (error) {
          _this.set('isBusy', false);
          if (error.code == "CvnFailed" || error.code == "BothCvnAvsFailed") {
            user.isCvnReqd = true;
            _this.set('model.paymentMethodSelected.cvvCode', null);
          }
          if (error.responseJSON && error.responseJSON.displayChangePaymentMethodPopUp) {
            _this.toggleShowChangePaymentMethodPopUpCommon();
          } else {
            _this.handleError(error);
          }

          _this.set('primaryButtonBusy', false);
          _this.get('logger').error('Problem in adding funds. userId=' + user.id, {
            originalError: error
          });
        });
      }
    },

    toggleShowChangePaymentMethodPopUpCommon: function toggleShowChangePaymentMethodPopUpCommon() {
      this.toggleProperty('showChangePaymentMethodPopUp');
    }
  });
});