define('ingenio-web/models/credit-card-new', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    cvvCode: (0, _attr.default)('string'),
    last4Digits: (0, _attr.default)('string'),
    nameOnAccount: (0, _attr.default)('string'),
    accountNumber: (0, _attr.default)('string'),
    creditCardType: (0, _attr.default)('string'),

    // TODO: The API expects (and also returns) the expiration date for a CC in the
    // format of "MMYY". The actual Ember component handles this in terms of month and
    // year as separate data points as integers and the component outputs the expected
    // format. This could be simplified with an API change.
    expiration: (0, _attr.default)('string'),

    token: (0, _attr.default)('string'),
    checkoutComToken: (0, _attr.default)(), // of type object
    fullNumber: (0, _attr.default)('string'),
    address: (0, _attr.default)('address'),

    isPrimary: (0, _attr.default)('boolean', {
      defaultValue: true
    }),

    isAvsRequired: (0, _attr.default)('boolean', {
      defaultValue: false
    }),

    isCvnRequired: (0, _attr.default)('boolean', {
      defaultValue: true
    }),

    deviceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),

    checkoutComDeviceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),

    eventSource: (0, _attr.default)('string', {
      defaultValue: 'WebSite'
    })
  });
});