define("ingenio-web/components/arranged-conversations/advisor/appointment-card/component", ["exports", "ingenio-web/utils/html-utils", "ingenio-web/constants/mail-mode-type"], function (exports, _htmlUtils, _mailModeType) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var OfferType = Object.freeze({
    None: 0,
    DiscountedServiceRate: 1,
    RegularOffer: 2
  });

  exports.default = Ember.Component.extend({
    offerType: null,
    offerText: null,
    avatarUrl: null,
    hidePrice: false,
    advisorName: null,
    roundedRating: null,
    averageRating: null,
    pricePerMinute: null,
    numberOfReviews: null,
    _showCancelConfirmationDialog: false,
    answers: [],

    mixpanelClient: Ember.inject.service("mixpanel-client"),

    showCancelConfirmationDialog: Ember.computed.equal("_showCancelConfirmationDialog", true),

    showAcceptAppointmentDialog: Ember.computed.equal("_showAcceptAppointmentDialog", true),

    discountedServiceRateOffer: Ember.computed("offerType", function () {
      return this.get("offerType") === OfferType.DiscountedServiceRate;
    }),

    pricePerMinuteDisplayText: Ember.computed("pricePerMinute", function () {
      var pricePerMinuteFixed = (this.get("pricePerMinute") * 1).toFixed(2);
      return "$" + pricePerMinuteFixed;
    }),

    offerDisplayText: Ember.computed("offerType", "offerText", "discountedServiceRateOffer", function () {
      var offerType = this.get("offerType");

      switch (offerType) {
        case 1:
          return this.get("offerText");

        case 2:
          return !this.get("discountedServiceRateOffer") ? this.get("offerText") : "";

        default:
          return "";
      }
    }),

    strikePriceText: Ember.computed("offerType", function () {
      return this.get("offerType") === OfferType.RegularOffer;
    }),

    isICAppointment: Ember.computed("model.apptTypeID", function () {
      return this.get("model.apptTypeID") === 3;
    }),

    cssTypeIndicator: Ember.computed("model.type", "isICAppointment", function () {
      if (this.get("isICAppointment")) return "video";

      var ctype = this.get("model.type");
      if (ctype == "chat") return "chat";
      return "phone";
    }),

    pastEarnings: Ember.computed("model.user", function () {
      var user = this.get("model.user");
      if (user && user.pastEarnings && user.pastEarnings.value) {
        return user.pastEarnings.value;
      }
      return "-";
    }),

    startDateWeekText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("dddd");
    }),

    startDateMonthText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("MMMM Do");
    }),

    startTimeText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("h:mma");
    }),

    endTimeText: Ember.computed("model.scheduledEnd", function () {
      var scheduledEnd = moment(this.get("model.scheduledEnd"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledEnd.format("h:mma");
    }),

    statusText: Ember.computed("model.status", function () {
      return this.get("model.status").toString().replace(/_/g, " ").toLowerCase().capitalize();
    }),

    showAccept: Ember.computed("model.status", function () {
      return this.get("model.status").toString().toLowerCase() == "pending";
    }),

    showDecline: Ember.computed("model.status", "isICAppointment", function () {
      var isICAppointment = this.get("isICAppointment");
      var status = this.get("model.status").toString().toLowerCase();

      if (isICAppointment) {
        return status == "pending";
      }

      return status == "pending";
    }),

    showViewCustomerNote: Ember.computed("model.note", function () {
      var note = this.get("model.note");
      return note && note.id;
    }),

    showCancel: Ember.computed("model.status", "isICAppointment", function () {
      var isICAppointment = this.get("isICAppointment");
      var status = this.get("model.status").toString().toLowerCase();

      if (isICAppointment) {
        return status === "accepted";
      }

      return status == "accepted";
    }),

    nicknameText: Ember.computed("model.user", function () {
      var nickname = this.get("model.user.nickname");
      return nickname == null ? "-" : nickname;
    }),

    nextJSRoot: Ember.ENV.urls.nextJSRoot,

    displayJoinSessionButton: Ember.computed("model.status", "isICAppointment", "model.videoSessionId", function () {
      var status = this.get("model.status");
      var videoSessionId = this.get("model.videoSessionId") || 0;
      var isICAppointment = this.get("isICAppointment"); // Display only for IC Appointments
      var isVideoSessionCreated = videoSessionId > 0;

      return isICAppointment && isVideoSessionCreated && ["ACCEPTED", "INITIATED", "ACTIVE"].includes(status);
    }),

    actions: {
      accept: function accept() {
        this.sendAction("acceptAppointment", this.get("model"));
      },

      decline: function decline() {
        this.sendAction("declineAppointment", this.get("model"));
      },
      cancelItem: function cancelItem() {
        this.sendAction("cancelAppointment", this.get("model"));
      },
      joinVideoCall: function joinVideoCall() {
        var videoSessionId = this.get("model.videoSessionId");
        var url = Ember.ENV.urls.nextJSRoot + "/video/sessions/advisor?sessionid=" + videoSessionId;
        var mixpanel = this.get('mixpanelClient');
        if (mixpanel) {
          mixpanel.trackNewEvent('Join Video Call Clicked', {
            eventData: {
              user_type: 'advisor', sessionId: videoSessionId
            }
          });
        }
        window.open(url, "_self");
      },
      viewForm: function viewForm() {
        var _this = this;

        var surveyResponseId = this.get("model.surveyResponseId");
        var cachedAnswer = this.get("answers").find(function (a) {
          return a.id === surveyResponseId;
        });

        if (!cachedAnswer) {
          Ember.$.getJSON(Ember.ENV.getApiRoot() + ("/surveys/responses/" + surveyResponseId)).then(function (response) {
            var answer = {
              id: surveyResponseId,
              answers: response.data.map(function (q) {
                return {
                  question: q.question,
                  answer: q.answer
                };
              })
            };
            _this.set("answers", [].concat(_toConsumableArray(_this.get("answers").filter(function (a) {
              return a.id !== surveyResponseId;
            })), [answer]));
            _this.set("answer", answer);
          });
        } else {
          this.set("answer", cachedAnswer);
        }
      },
      closeForm: function closeForm() {
        this.set("answer", null);
      },
      sendPing: function sendPing() {
        var customerId = this.get("model.user.id");
        if (window.location.hostname === "localhost") {
          window.location.href = "/#/direct-messages/recipient/" + customerId;
        } else {
          window.location.href = "/app/#/direct-messages/recipient/" + customerId;
        }
      },
      sendEmail: function sendEmail() {
        window.location = "/Mail/Compose.aspx?" + _htmlUtils.default.encode({
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get("model.user.userName"),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      viewNote: function viewNote() {
        var noteId = this.get("model.note.id");
        var userId = Ember.Application.user.id;
        window.location = "/Mail/Read.aspx?" + _htmlUtils.default.encode({
          mailId: noteId,
          rcptId: userId,
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: _mailModeType.default.Inbox,
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      }
    }
  });
});