define('ingenio-web/services/user-events', ['exports', 'ingenio-web/helpers/platform-domain'], function (exports, _platformDomain) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        metrics: Ember.inject.service(),

        paymentMethodAdded: function paymentMethodAdded(method) {
            this.get('metrics').trackEvent('snapchat-pixel', {
                'event': 'ADD_BILLING',
                'description': method
            });

            if (this.currentUserRegistrationTidIsBiFbTid() && !Ember.Application.user.hasAddedFundsInPast) {
                this.get('metrics').invoke('trackSingle', 'fb-pixel', {
                    'partnerId': 'bi',
                    'event': 'AddPaymentInfo',
                    'partner_name': 'Keen'
                });
            }

            if ((0, _platformDomain.platformIsKeen)()) {
                // Web Tracking of TikTok event
                var userId = Ember.Application.user.id;
                var unixTimestamp = Math.floor(Date.now() / 1000);
                var eventId = userId + '_' + unixTimestamp;
                if (window.ttq) {
                    window.ttq.track('AddPaymentInfo', {}, { event_id: eventId });
                }
            }
        },


        currentUserRegistrationTidIsBiFbTid: function currentUserRegistrationTidIsBiFbTid() {
            var registrationTid = Ember.Application.user.registrationTid;
            var biFbTids = Ember.ENV.biFbTids;

            return biFbTids && registrationTid !== "" && registrationTid != null && biFbTids.includes(registrationTid);
        },

        fundsAdded: function fundsAdded(event) {
            // TODO: add Events tracking for External Partners, if needed
        },
        firstScheduledSession: function firstScheduledSession(event) {
            console.log("user-events firstScheduledSession");

            if (fbq) {
                fbq('track', event, { userId: Ember.Application.user.id });
            }

            if (gtag) {
                console.log("user-events gtag");

                gtag('event', event, {
                    'user_id': Ember.Application.user.id
                });

                gtag('event', 'conversion', { 'send_to': 'AW-968402472/XRqECN-Q9asZEKjM4s0D',
                    'user_id': Ember.Application.user.id,
                    'event_label': event
                });
            }
        }
    });
});