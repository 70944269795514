define('ingenio-web/components/number-text-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        keyDown: function keyDown(event) {
            // Allow: backspace, delete, tab, escape, and enter
            if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 27 || event.keyCode === 13
            // Allow: Ctrl+A
            || event.keyCode === 65 && event.ctrlKey === true
            // Allow: home, end, left, right
            || event.keyCode >= 35 && event.keyCode <= 39) {
                return; // let it happen, don't do anything
            } else if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                // Ensure that it is a number and stop the keypress
                event.preventDefault();
            }
        }
    });
});