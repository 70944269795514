define('ingenio-web/components/transaction-details/bonus-details/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        subjectLink: function () {
            var mailId = this.get('model.transactionDetail.bonusDetails.mailId');
            var advisorId = this.get('model.transactionDetail.advisorId');
            var isAdvisor = this.get('model.isAdvisor');
            var mode = isAdvisor ? 3 : 4;

            return '/Mail/Read.aspx?mailId=' + mailId + '&rcptId=' + advisorId + '&Subroutine=Mail&SubroutineParams=mode%3D' + mode;
        }.property('model.transactionDetail.bonusDetails.mailId', 'model.transactionDetail.advisorId'),

        amountLink: function () {
            var mailId = this.get('model.transactionDetail.bonusDetails.mailId');
            var advisorId = this.get('model.transactionDetail.advisorId');

            return '/account/keenmaildetail.asp?mid=' + mailId + '&recid=' + advisorId;
        }.property('model.transactionDetail.bonusDetails.mailId', 'model.transactionDetail.advisorId'),

        bonusLink: function () {
            var listingId = this.get('model.transactionDetail.listingId');
            var transactionId = this.get('model.transactionDetail.id');

            return '/myaccount/tipinfo.aspx?Subroutine=tip&SubroutineParams=sid%3D' + listingId + '%26cid%3D' + transactionId + '&ReturnURL=&CancelURL=';
        }.property('model.transactionDetail.listingId', 'model.transactionDetail.id'),

        bonusAmount: function () {
            return this.get('model.transactionDetail.bonusDetails.amount').toFixed(2);
        }.property('model.transactionDetail.bonusDetails.amount')
    });
});