define('ingenio-web/components/technical-feedback', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        sessionType: 'chat',
        isOpen: true,
        status: 'waitForFeedback',
        waitForFeedback: Ember.computed.equal('status', 'waitForFeedback'),
        thumbsDown: Ember.computed.equal('status', 'thumbsDown'),
        feedbackSent: Ember.computed.equal('status', 'feedbackSent'),

        initControl: function () {
            this.send('scroll');
        }.on('init'),

        sendDisabled: function () {
            var comment = this.get('comment');

            if (!comment || comment.length === 0) {
                return true;
            }
            return false;
        }.property('comment'),

        actions: {
            close: function close() {
                this.set('isOpen', false);

                if (!this.get('isAdvisor')) {
                    window.location = '/myaccount';
                }
            },
            thumbsUp: function thumbsUp() {
                var feedback = {
                    rating: 5, // thumbs up
                    sessionId: this.get('sessionId'),
                    sessionType: this.get('sessionType') === 'chat' ? 4 : 0, // 0-TwoPartyCall
                    fromAdvisor: this.get('isAdvisor')
                };

                this.send('saveFeedback', feedback);
            },
            thumbsDown: function thumbsDown() {
                this.set('status', 'thumbsDown');

                var that = this;
                var interval = window.setInterval(function () {
                    var textarea = $(that.element).find('.textarea-container').find("textarea");
                    if (textarea) {
                        textarea.attr("autocomplete", "nope");
                        window.clearInterval(interval);
                    }
                }, 100);

                this.send('scroll');
            },
            scroll: function scroll() {
                Ember.run.scheduleOnce('afterRender', this, function () {
                    var position = $('.technical-feedback-container').offset().top;

                    window.scrollTo(0, position);
                });
            },
            enterReason: function enterReason() {
                var technicalIssues = [];
                var comment = this.get('comment');

                var feedback = {
                    rating: 1, // thumbs down
                    sessionId: this.get('sessionId'),
                    sessionType: this.get('sessionType') === 'chat' ? 4 : 0, // 0-TwoPartyCall
                    fromAdvisor: this.get('isAdvisor'),
                    reasonIds: technicalIssues,
                    comment: comment
                };

                this.send('saveFeedback', feedback);
            },
            hide: function hide() {
                var _this = this;

                Ember.run.later(this, function () {
                    return _this.send('close', false);
                }, 1.5 * 1000);
            },
            saveFeedback: function saveFeedback(feedback) {
                var that = this;
                that.get('store').createRecord('technicalFeedback', feedback).save().then(function () {
                    that.set('status', 'feedbackSent');
                    that.send('hide');
                }).catch(function (e) {
                    console.error(e);
                    that.set('status', 'error');
                });
            }
        }
    });
});