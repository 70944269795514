define('ingenio-web/models/credit-card', ['exports', 'ember-data/attr', 'ingenio-web/models/model-base', 'moment', 'ingenio-web/utils/card-validator-util'], function (exports, _attr, _modelBase, _moment, _cardValidatorUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        creditCardType: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        accountNumber: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        last4Digits: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        expiration: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        isCardValid: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        isPrimary: (0, _attr.default)('boolean', {
            defaultValue: true
        }),
        token: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        checkoutComToken: (0, _attr.default)(), // of type object
        fullNumber: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        nameOnAccount: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        cvvCode: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        deviceId: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        checkoutComDeviceId: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        eventSource: (0, _attr.default)('string', {
            defaultValue: 'WebSite'
        }),
        expirationMonth: (0, _attr.default)('number', {
            defaultValue: function defaultValue() {
                var month = new Date().getMonth() + 1;

                if (month < 13) {
                    return month;
                }

                return 1;
            }
        }),
        expirationYear: (0, _attr.default)('number', {
            defaultValue: function defaultValue() {
                var year = new Date().getFullYear();
                var month = new Date().getMonth() + 2;

                if (month < 13) {
                    return year;
                }

                return year + 1;
            }
        }),
        address: (0, _attr.default)('address'),

        isAvsRequired: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        isCvnRequired: (0, _attr.default)('boolean', {
            defaultValue: true
        }),

        // because of issue: https://github.com/emberjs/ember.js/issues/11828
        // return null instead of false
        isReadonly: function () {
            var token = this.get('token') || '';

            if (token.length > 0) {
                return true;
            }
            return null;
        }.property('token'),

        cvvCodeSize: function () {
            var ccType = (this.get('creditCardType') || '').toLowerCase();

            return ccType === 'amex' ? 4 : 3;
        }.property('cvvCode'),

        generalInfo: function () {
            var result = '';

            if (!this.hasError('accountNumber') && !this.hasError('creditCardType') && this.get('creditCardType') !== '') {
                result = this.get('creditCardType') + ' ' + this.get('accountNumber');
            }

            return result;
        }.property('accountNumber', 'creditCardType', 'validationErrors.length'),

        validateCreditCard: function () {
            this.removeErrors('accountNumber');

            var needToValidate = !this.get('isReadonly');

            if (needToValidate) {
                // check required
                var accountNumber = this.get('accountNumber') || '';

                if (accountNumber.length === 0) {
                    this.addError('accountNumber', 'Credit Card number is required');
                    return;
                }

                // validate CC type
                var cardType = this.get('creditCardType') || '';

                if (cardType.length === 0 && accountNumber.length > 0) {
                    this.addError('accountNumber', 'Credit Card number is not valid');
                    return;
                }

                // validate CC number by type
                if (!(0, _cardValidatorUtil.validateByType)(accountNumber)) {
                    this.addError('accountNumber', 'Credit Card number is not valid');
                    return;
                }

                // validate Luhn algorithm
                if (!(0, _cardValidatorUtil.validateByLuhmAlg)(accountNumber)) {
                    this.addError('accountNumber', 'Credit Card number is not valid');
                    return;
                }
            }
        }.observes('accountNumber', 'creditCardType'),

        validateExpirationDate: function () {
            this.removeErrors('expirationDate');

            if (!this.get('expirationMonth') || !this.get('expirationYear')) {
                return;
            }

            var expirationDate = new Date(this.get('expirationYear'), this.get('expirationMonth') - 1, 1, 0, 0, 0, 0);

            expirationDate = (0, _moment.default)(expirationDate).add(1, 'M').subtract(1, 'd');

            if (expirationDate < new Date()) {
                // expiration date is prior of current date
                this.addError('expirationDate', 'Expiration date cannot be in the past.');
            }
        }.observes('expirationYear', 'expirationMonth'),

        validateNameOnAccount: function () {
            this.removeErrors('nameOnAccount');

            var nameOnAccount = this.get('nameOnAccount') || '';

            if (Ember.isEmpty(nameOnAccount.trim())) {
                this.addError('nameOnAccount', 'Name on account is required.');
            }

            //allow only whitespace, comma, back tick ,tilde, period, dash, apostrophe and alphabets
            var regex = new RegExp('^[a-zA-Z ,`~.\'-]+$');

            if (!regex.test(nameOnAccount)) {
                this.addError('nameOnAccount', 'Please enter a valid name.');
            }
        }.observes('nameOnAccount'),

        validateAddress: function () {
            this.removeErrors('addressLine1');
            this.removeErrors('city');

            // don't validate address if it is not required
            if (!this.get('isAvsRequired')) {
                return;
            }

            // address line 1 - required
            var addressLine1 = this.get('address.addressLine1') || '';

            if (addressLine1.length === 0) {
                // expiration date is prior of current date
                this.addError('addressLine1', 'Address Line1 is required.');
            }

            // city - required
            var city = this.get('address.city') || '';

            if (city.length === 0) {
                // expiration date is prior of current date
                this.addError('city', 'City is required.');
            }
        }.observes('address.addressLine1', 'address.city'),

        validatePostalCode: function () {
            this.removeErrors('postalCode');
            var postalCode = this.get('address.postalCode') || '';

            if (postalCode.length === 0) {
                this.addError('postalCode', 'Postal/Zip code is required.');
            }
        }.observes('address.postalCode'),

        validateAddressState: function () {
            this.removeErrors('stateCode');

            // don't validate address if it is not required
            if (!this.get('isAvsRequired')) {
                return;
            }

            // state code - required for those countries: 'AU', 'CA', 'IN', 'IE', 'IT', 'JP', 'MX', 'TW', 'GB', 'US'
            var stateCode = this.get('address.stateCode') || '';
            var countryCode = this.get('address.countryCode') || '';

            if (stateCode.length === 0 && ['AU', 'CA', 'IN', 'IE', 'IT', 'JP', 'MX', 'TW', 'GB', 'US'].includes(countryCode)) {
                // expiration date is prior of current date
                this.addError('stateCode', 'The state is required');
            }
        }.observes('address.stateCode'),

        // global validation method will be executed on init
        validate: function validate() {
            this.validateCreditCard();
            this.validateExpirationDate();
            this.validateNameOnAccount();
            this.validateAddress();
            this.validatePostalCode();
        },
        assignExpiration: function assignExpiration() {
            if (!this.get('expirationMonth') || !this.get('expirationYear')) {
                return;
            }

            var expirationDate = new Date(this.get('expirationYear'), this.get('expirationMonth') - 1, 1, 0, 0, 0, 0);

            this.set('expiration', (0, _moment.default)(expirationDate).format('MMYY'));
        },
        resetToken: function resetToken() {
            var accountNumber = this.get('savedCCNumber');

            if (accountNumber) {
                // rollback CC token
                this.set('accountNumber', accountNumber);
                this.set('token', '');
            }
        },


        afterLoad: function () {
            var month = 1;
            var year = new Date().getFullYear();

            if (this.get('expiration').length === 4) {
                month = parseInt(this.get('expiration').substring(0, 2));
                year = 2000 + parseInt(this.get('expiration').substring(2, 4));
            }

            this.set('expirationMonth', month);
            this.set('expirationYear', year);

            if (this.get('chargeAccountType') === 1) {
                this.set('accountNumber', 'PayPal: ' + this.get('name'));
            } else if (this.get('creditCardType').toLowerCase() === 'amex') {
                this.set('accountNumber', 'XXXX-XXXXXX-X' + this.get('last4Digits'));
            } else {
                this.set('accountNumber', 'XXXX-XXXX-XXXX-' + this.get('last4Digits'));
            }

            this.validate();
        }.on('didLoad')
    });
});