define('ingenio-web/mixins/keyboard-events', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var helpers = {
        includesCtrlKey: function includesCtrlKey(event) {
            return (event.ctrlKey || event.metaKey) && [65, 67, 86].includes(event.keyCode);
        },
        includesSpecialKey: function includesSpecialKey(event) {
            return !event.ctrlKey && !event.metaKey && (event.keyCode > 32 && event.keyCode < 47 || [8, 9, 13, 27].includes(event.keyCode));
        },
        includesSymbolKey: function includesSymbolKey(event) {
            return !event.ctrlKey && !event.metaKey && [188, 189, 222].includes(event.keyCode);
        },
        includesDecimalKey: function includesDecimalKey(event) {
            return [110, 190].includes(event.keyCode);
        },
        includesSpaceKey: function includesSpaceKey(event) {
            return event.keyCode === 32;
        },
        includesAlphaKey: function includesAlphaKey(event) {
            return !event.ctrlKey && !event.metaKey && event.keyCode > 64 && event.keyCode < 91;
        },
        includesNumericKey: function includesNumericKey(event) {
            return event.keyCode > 47 && event.keyCode < 58 || event.keyCode > 95 && event.keyCode < 106;
        }
    };

    exports.default = Ember.Mixin.create({
        keyType: {
            ctrl: 'ctrl',
            alpha: 'alpha',
            space: 'space',
            symbol: 'symbol',
            decimal: 'decimal',
            special: 'special',
            numeric: 'numeric'
        },

        allowKeys: function allowKeys(event, keyTypes) {
            if (!Array.isArray(keyTypes)) {
                return;
            }

            if (keyTypes.includes(this.keyType.ctrl) && helpers.includesCtrlKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.alpha) && helpers.includesAlphaKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.space) && helpers.includesSpaceKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.symbol) && helpers.includesSymbolKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.decimal) && helpers.includesDecimalKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.special) && helpers.includesSpecialKey(event)) {
                return;
            }

            if (keyTypes.includes(this.keyType.numeric) && helpers.includesNumericKey(event)) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();
        }
    });
});