define("ingenio-web/constants/mail-mode-type", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    /// <summary>
    /// Compose page
    /// </summary>
    Compose: 0,
    /// <summary>
    /// Payment Mail Buttons page - create buttons
    /// </summary>
    PaymentButtonsCreate: 1,
    /// <summary>
    /// Payment Mail Buttons page - edit buttons
    /// </summary>
    PaymentButtonsEdit: 2,
    /// <summary>
    /// Inbox page
    /// </summary>
    Inbox: 3,
    /// <summary>
    /// Sent Mail page
    /// </summary>
    Sent: 4,

    /// <summary>
    /// call flow mail page
    /// </summary>
    CallFlow: 5,

    /// <summary>
    /// mail page from expanded listing page link
    /// </summary>
    ExpandedListing: 6,

    /// <summary>
    /// mail page from "ask a question" link located on homepage, etc.
    /// </summary>
    AskAQuestion: 7
  };
});