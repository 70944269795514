define('ingenio-web/adapters/bid', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot, requestType) {
            var bidId = id; //Ember.get(record, 'id');
            var listingId = snapshot.record ? snapshot.record.get('listingId') : snapshot.get('listingId'); // Ember.get(record, 'listingId');
            var baseUrl = Ember.ENV.getApiRoot() + '/listings/' + listingId + '/bids';

            switch (requestType) {
                default:
                    return baseUrl;

                case 'updateRecord':
                case 'deleteRecord':
                    return baseUrl + '/' + bidId;

                case 'validateRecord':
                    return baseUrl + '/validate';
            }
        },


        /**
         * Find Record: Supports querying the latest bid for a particular listing.
         * TODO: Check if there is a better approach to this that aligns with REST & Ember standards.
         *
         * @param {Object} store = Ember Store
         * @param {Object} type - Model Type
         * @param {Number} id - The bid id to query - this parameter is currently ignored for findRecord().
         * @param {Object} snapshot - Snapshot
         * @return {Promise} bid
         */
        findRecord: function findRecord(store, type, id, snapshot) {
            var listingId = Ember.get(snapshot.adapterOptions, 'listingId');
            var latestBidUrl = Ember.ENV.getApiRoot() + '/listings/' + listingId + '/bids/latest?bidType=BidPerClick&bidStatus=Active';

            if (!listingId || isNaN(listingId)) {
                console.error('Bid: A listing id is required when querying for the latest bid.');
                return null;
            }

            return this.ajax(latestBidUrl, 'GET');
        },

        validateRecord: function validateRecord(bid) {
            var url = this.buildURL('bid', null, bid, 'validateRecord');
            var data = {
                bid: bid.toJSON()
            };

            return this.ajax(url, 'POST', { data: data });
        }
    });
});