define('ingenio-web/adapters/chat-transcript', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(type, id, snapshot) {
            var userId = Ember.Application.user.id;
            var apiBaseUrl = Ember.ENV.getApiRoot() + '/users/' + userId + '/chat/transcripts';

            if (id) {
                apiBaseUrl += '/' + id;
            }

            var includeSystemMessages = false;

            if (snapshot) {
                includeSystemMessages = snapshot.adapterOptions.includeSystemMessages || null;
            }

            return includeSystemMessages ? apiBaseUrl + '?includeSystemMessages=' + includeSystemMessages : apiBaseUrl;
        }
    });
});