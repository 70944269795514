define('ingenio-web/controllers/chat/advisor-session', ['exports', 'ingenio-web/controllers/chat/session-base', 'ingenio-web/objects/chat/end-reasons'], function (exports, _sessionBase, _endReasons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _sessionBase.default.extend({
        note: null,
        showConsumerDetails: false,
        networkUp: true,

        assignToListInProgress: false,
        hideAssignToList: false,
        currentConsumerList: null,
        visible: true,

        setup: function () {
            var _this = this;

            this._super();

            this.set('notification', this._createNotification({
                text: '',
                closeAfter: null
            }));

            this.set('afterSessionEndCallback', function () {
                _this.send('saveNotes');
                _this.set('showConsumerDetails', false);
            });
        }.observes('model').on('init'),

        saveNotesDisabled: function () {
            var note = this.get('note.note');

            return !note || note.length === 0;
        }.property('note.note'),

        onNetworkConnectionStatusChange: Ember.observer('networkUp', function () {
            var _this2 = this;

            var notification = null;

            if (!this.get('networkUp')) {
                var text = 'Your network is currently disconnected. Make sure your device has an active internet connection.';

                notification = { text: text, closeAfter: null };
            } else {
                var _text = 'Your internet connection was restored.';

                notification = { text: _text, closeAfter: 5 };

                this.get('messages').filter(function (item) {
                    return item.get('sendContext.deliveryError');
                }).forEach(function (message) {
                    _this2.get('chatChannelClient').sendChatMessage(null, message.get('sendContext'));
                });
            }
            Ember.run.debounce(function () {
                //dont notify network issues if the session has already ended
                if (_this2.get('status') === 'ended') {
                    return;
                }
                _this2.set('notification', _this2._createNotification(notification));
            }, 2000);
        }),

        actions: {
            showConnectingNotification: function showConnectingNotification() {
                this.set('notification', this._createNotification({
                    text: 'Setting Up. Connecting to Customer - this will only take a few moments...',
                    closeAfter: null
                }));
            },
            toggleShowConsumerDetails: function toggleShowConsumerDetails() {
                this.toggleProperty('showConsumerDetails');
            },
            onTextChange: function onTextChange(newText) {
                this.set('lastEnteredText', newText);
            },
            onSystemMessageReceivedV2: function onSystemMessageReceivedV2(message) {
                console.log(JSON.stringify(message));
                if (message.Type === 'system' || message.Type === 'state') {
                    return false;
                }

                var eventData = message.Data;

                if (!eventData) {
                    this.get('logger').log('onSystemMessageReceivedV2 empty data.');
                    return false;
                }

                var messageId = message.MessageId;
                var eventType = eventData.eventType;

                if (messageId) {
                    var latency = message.ReceivedTimestamp - message.ServerTimestamp;

                    this.get('logger').log('ChatAnalysis - Command: ' + eventType + ', MessageId: ' + messageId + '. Latency: ' + latency + '.');
                }

                var handledByBaseController = this._super(message);

                if (!handledByBaseController) {
                    switch (eventType) {
                        case 'ChatExtendedEvent':
                            {
                                //We need to store spent on chat time
                                this.get('chatDurationManagerV2').stop();

                                var availableTime = Number(eventData.availableTime);
                                var startTime = eventData.startTime;
                                var endTime = eventData.endTime;

                                this.unpauseSession(availableTime, startTime, endTime);
                                break;
                            }
                        case 'ChatResumedEvent':
                            {
                                //if this is usual upause message but not (Unpause|nn|PostPay)
                                //it means user failed to extend session and added money manually so we need
                                //set isPostPay to false and go by prepay flow
                                var isPostPay = eventData.postpay;

                                this.set('isPostPay', isPostPay);

                                //AutoReload
                                var isAutoReload = eventData.autoreload;

                                this.set('isAutoReload', isAutoReload);

                                var _availableTime = Number(eventData.availableTime);

                                var _startTime = eventData.startTime;
                                var _endTime = eventData.endTime;
                                this.unpauseSession(_availableTime, _startTime, _endTime);
                                break;
                            }
                        default:
                            {
                                this.get('logger').log('Unexpected message received ' + JSON.stringify(message));
                                return false;
                            }
                    }
                }
                return false; //to avoid 'Nothing handled the action' error
            },
            onSessionStarted: function onSessionStarted() {
                var _this3 = this;

                this.get('logger').log('onSessionStarted');
                //user can start chatting now.

                this.set('showChatFooter', false);
                $(function () {
                    return $('.footer').hide();
                });

                this.set('status', 'ready');
                this.set('notification', this._createNotification({
                    text: 'Session Started. Your session has started',
                    closeAfter: 3
                }));

                var availableTime = this.get('model.availableTimeSeconds');

                this.get('keyPressNotifier').startTimeoutCountdown();
                this.get('chatDurationManagerV2').startWithAvailabileTime(availableTime);
                this.get('logger').log('started chatDurationManager with ' + availableTime + ' seconds');

                this.sendSystemMessage('Session Started');

                var session = this.get('model');

                session.set('status', 'AdvisorJoined');
                session.save().then(function () {
                    return _this3.logger.log('Advisor Joined');
                });
            },
            warnForRecharge: function warnForRecharge() {
                this.get('logger').log('show warning');
                this.get('logger').log('current status is :' + this.get('status'));

                this.sendSystemMessage('1 Minute Remaining');

                //TODO: change notification message to correct one
                this.set('notification', this._createNotification({
                    text: '1 minute remaining. Customer will soon be asked to add more time.',
                    closeAfter: 5
                }));
            },
            sessionTimeoutWarning: function sessionTimeoutWarning() {
                var isPostPay = this.get('isPostPay');
                var isAutoReload = this.get('isAutoReload');

                if (!isPostPay && !isAutoReload) {
                    this.send('warnForRecharge');
                }
            },
            demandForRecharge: function demandForRecharge() {
                var _this4 = this;

                this.get('logger').log('show recharge screen');
                this.get('logger').log('current status is :' + this.get('status'));

                this.sendSystemMessage('Session Paused to Add Funds');
                this.get('chatDurationManagerV2').stop();

                var session = this.model;

                session.set('status', 'Paused');
                session.save().then(function () {
                    _this4.set('status', 'rechargePaused');

                    _this4.get('keyPressNotifier').stopTimeoutCountdown();
                }).catch(function (e) {
                    _this4.get('logger').log('error has occurred during pausing session for recharge' + e);
                    _this4.endSession(_endReasons.default.unknown);
                });
            },
            onRechargeTimeout: function onRechargeTimeout() {
                this.get('logger').log('recharge Timeout');
                this.endSession(_endReasons.default.advisorEndedRechargeTimeout);
            },
            saveNotes: function saveNotes() {
                var _this5 = this;

                var note = this.get('note');

                note.save().then(function () {
                    _this5.set('showSaveNoteSuccess', true);
                    Ember.run.later(_this5, function () {
                        return _this5.set('showSaveNoteSuccess', false);
                    }, 5000);
                }).catch(function () {
                    _this5.set('showSaveNoteError', true);
                    Ember.run.later(_this5, function () {
                        return _this5.set('showSaveNoteError', false);
                    }, 5000);
                });
            },


            //Below action is for testing only - it'll go away.
            otherPartyMessageSent: function otherPartyMessageSent(text) {
                this.addMessage({ text: text }, false);
            }
        },

        unpauseSession: function unpauseSession(remainingDurationSeconds, startTime, endTime) {
            this.get('logger').log('unpauseSession(' + remainingDurationSeconds + ', ' + startTime + ', ' + endTime + ')');

            this.sendSystemMessage('Session Resumed');

            if (remainingDurationSeconds > 0) {
                this.get('logger').log('can chat for seconds: ' + remainingDurationSeconds);

                if (!this.get('isPostPay') && !this.get('isAutoReload')) {
                    this.set('notification', this._createNotification({
                        text: 'Chat Session Resumed. You can chat for ' + Math.round(remainingDurationSeconds / 60) + ' minutes',
                        closeAfter: 8,
                        hideCloseButton: true
                    }));
                }
                var chatDurationManagerV2 = this.get('chatDurationManagerV2');

                chatDurationManagerV2.start(startTime, endTime);
                this.get('keyPressNotifier').startTimeoutCountdown();

                this.set('status', 'ready');
            } else {
                //TODO: Should we show an error instead?
                this.get('logger').log('unpause session returned 0 seconds');
                this.endSession(_endReasons.default.advisorRechargeError);
            }
        }
    });
});