define('ingenio-web/models/bid', ['exports', 'ember-data/model', 'ember-data/attr', 'moment'], function (exports, _model, _attr, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        listingId: (0, _attr.default)('number', {
            defaultValue: null
        }),
        contextId: (0, _attr.default)('number', {
            defaultValue: null
        }),
        bidType: (0, _attr.default)('string'),
        bidStatus: (0, _attr.default)('string'),
        userStatus: (0, _attr.default)('string'),
        rechargeType: (0, _attr.default)('string'),
        bidAmount: (0, _attr.default)('number', {
            defaultValue: null
        }),
        budgetAmount: (0, _attr.default)('number', {
            defaultValue: null
        }),
        createdDt: (0, _attr.default)('date', {
            defaultValue: (0, _moment.default)()
        }),
        lastUpdatedDt: (0, _attr.default)('date', {
            defaultValue: (0, _moment.default)()
        }),

        validate: function validate() {
            var adapter = this.store.adapterFor(this.constructor.modelName);

            return adapter.validateRecord(this);
        }
    });
});