define('ingenio-web/controllers/call/status', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['callid', 'listingid'],
        callStatusPollFrequency: 1000,

        callError: false,
        additionalCheck: 0,

        displayConnectingContent: false,
        displayRecordingListingMessage: false,
        displayMessageContentContainer: false,
        displayMessageContent1: false,
        displayMessageContent2: false,
        displayMessageContent3: false,
        displayMessageContent4: false,
        displayMessageContent5: false,
        displayMessageContent6: false,
        displayMessageContent7: false,
        displayButtonsAndLinks: false,
        displayLeaveFeedbackButton: false,
        displayCallbackButton: false,
        displayInfoLinks: false,

        displayQuestion: Ember.computed.or('displayMessageContent1', 'displayMessageContent2', 'displayMessageContent3', 'displayMessageContent4'),

        preferredCurrency: Ember.computed('Ember.Application.user.preferredCurrency', function () {
            return Ember.Application.user.preferredCurrency;
        }),

        formattedPhoneNumber: function () {
            var phone = this.get('model.phone');

            if (phone) {
                if (this.get('model.phone.isUSNumber')) {
                    var result = this.get('model.phone.number');

                    if (result.length === 11) {
                        result = '(' + result.substring(1, 4) + ') ' + result.substring(4, 7) + '-' + result.substring(7);
                    }
                    return result;
                }

                return this.get('model.phone.number');
            }
            return '';
        }.property('model.phone.number'),

        fetchCallStatusAndMessage: function fetchCallStatusAndMessage() {
            var self = this;

            Ember.$.getJSON(Ember.ENV.getApiRoot() + '/call/' + self.get('callid')).then(function (response) {
                var data = response.callStatus;

                self.updateCallStatus(data, self);

                if (data) {
                    if (!data.isCallDone) {
                        Ember.run.later(self, self.fetchCallStatusAndMessage, self.get('callStatusPollFrequency'));
                    } else if (self.get('additionalCheck') < 5) {
                        // check call status 5 times even after call is done to get final status
                        self.set('additionalCheck', self.get('additionalCheck') + 1);
                        Ember.run.later(self, self.fetchCallStatusAndMessage, self.get('callStatusPollFrequency'));
                    }
                }
            });
        },

        updateCallStatus: function updateCallStatus(data) {
            var self = this;

            if (data) {
                self.get('model').setProperties(data);

                self.resetFlags();

                if (data.resultType === 1) {
                    self.set('callError', true);
                }

                if (data.messageType === 5) {
                    // Hack to satisfy inconsistent design
                    self.set('callError', false);
                }

                if (data.isDisplayConnectingImage) {
                    self.set('displayConnectingContent', true);
                }

                if (data.isDisplayLeaveFeedbackButton && data.leaveFeedbackUrl.length > 0) {
                    self.set('displayLeaveFeedbackButton', true);
                }

                if (data.isDisplayArrangeCallButton) {
                    self.set('displayCallbackButton', true);
                }

                if (data.isCallDone) {
                    self.set('displayInfoLinks', true);
                }

                if (data.isDisplayLeaveFeedbackButton || data.isDisplayArrangeCallButton) {
                    self.set('displayButtonsAndLinks', true);
                }

                self.setMessageDisplayFlags(data);
            }
        },

        resetFlags: function resetFlags() {
            this.setProperties({
                displayErrorHeadline: false,
                displaySuccessHeadline: false,
                displayConnectingContent: false,
                displayRecordingListingMessage: false,

                displayMessageContentContainer: false,

                displayMessageContent1: false,
                displayMessageContent2: false,
                displayMessageContent3: false,
                displayMessageContent4: false,
                displayMessageContent5: false,
                displayMessageContent6: false,
                displayMessageContent7: false,
                displayButtonsAndLinks: false,

                displayLeaveFeedbackButton: false,
                displayCallbackButton: false,
                displayInfoLinks: false
            });
        },

        setInitialMessageFromBestMatchCookies: function setInitialMessageFromBestMatchCookies() {
            var answersJsonFromCookies = _cookies.default.getCookie('best-match-v2-answers');

            if (!answersJsonFromCookies) {
                this.set('popularQuestion', null);
                return;
            }

            _cookies.default.eraseCookie('best-match-v2-answers');
            var answers = JSON.parse(answersJsonFromCookies);
            var popularQuestion = (answers.find(function (a) {
                return a.label === 'PopularQuestions' && a.popularQuestion;
            }) || '').option;

            if (popularQuestion && popularQuestion.length > 0) {
                this.set('popularQuestion', popularQuestion);
            }
        },


        setMessageDisplayFlags: function setMessageDisplayFlags(data) {
            //TODO restuta=>alexey: modify SS to return array of strings representing message purpose based on HTML semantics
            /*
               e.g. 1 and 2 can be called "pre call instructions"
                   3 can be called "wait while connecting", etc
                   also it worth considering moving callStatus mapping on the client, so we can awoid those names alltogether
            */
            switch (data.messageType) {
                case 0:
                    //recorded listing
                    this.set('displayRecordingListingMessage', data.message && data.message.length > 0);
                    break;
                case 1:
                    this.set('displayMessageContent1', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 2:
                    this.set('displayMessageContent2', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 3:
                    this.set('displayMessageContent3', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 4:
                    this.set('displayMessageContent4', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 5:
                    this.set('displayMessageContent5', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 6:
                    this.set('displayMessageContent6', true);
                    this.set('displayMessageContentContainer', true);
                    break;
                case 7:
                    this.set('displayMessageContent7', true);
                    this.set('displayMessageContentContainer', true);
                    break;
            }
        },

        actions: {
            leaveFeedback: function leaveFeedback() {
                window.location.href = this.get('model.leaveFeedbackUrl');
            }
        }
    });
});