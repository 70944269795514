define('ingenio-web/components/select-dropdown-group', ['exports', 'ember-select/components/select-dropdown-group'], function (exports, _selectDropdownGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _selectDropdownGroup.default;
    }
  });
});