define('ingenio-web/models/chat-advisor', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr'], function (exports, _fragment, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        userId: (0, _attr.default)('number', {
            defaultValue: 0
        }),
        advisorName: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        profilePictureUrl: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        averageRating: (0, _attr.default)('number', {
            defaultValue: 0.0
        }),
        starRating: (0, _attr.default)('number', {
            defaultValue: 0.0
        }),
        ratingsCount: (0, _attr.default)('number', {
            defaultValue: 0
        }),
        lifetimeTransactionCount: (0, _attr.default)('number', {
            defaultValue: 0
        })
    });
});