define('ingenio-web/adapters/paypal-token', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'paypal-express-checkout/token';
        }
    });
});