define('ingenio-web/router', ['exports', 'ingenio-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType
    });

    Router.map(function () {
        this.route('error');

        this.route('support', {}, function () {
            this.route('satisfaction-guarantee', {}, function () {
                this.route('details');
            }); // satisfaction-guarantee

            this.route('close-account');
            this.route('deactivate-account');
        }); // support

        this.route('listings', {
            resetNamespace: true,
            path: '/listings'
        }, function () {
            this.route('new');
            this.route('idv');
        });

        this.route('video', function () {
            this.route('session-ended');

            this.route('videoDetails', {
                path: '/details/:session_id'
            });
        });

        this.route('mail', function () {
            this.route('details', {
                path: '/details/:mail_id'
            });
        });

        //Call related routes here
        this.route('call', function () {
            this.route('callVerification', {
                path: 'verification'
            });

            this.route('status', {
                queryParams: ['callid', 'listingid']
            });

            this.route('callDetails', {
                path: '/details/:call_id'
            });
        });

        //Listing page related routes
        this.route('listing/edit', {
            path: '/listings/:listing_id/edit'
        });
        this.route('listing/drafts', {
            resetNamespace: true,
            path: '/listings/drafts'
        });
        this.route('listing/draft', {
            resetNamespace: true,
            path: '/listings/drafts/:listing_draft_id'
        });

        //Chat related routes here
        this.route('chat', function () {
            this.route('initiate', {
                queryParams: ['listingid']
            });

            this.route('consumerSession', {
                path: '/sessions/:session_id/consumer'
            });

            this.route('advisorSession', {
                path: '/sessions/:session_id/advisor'
            });

            this.route('voiceToTextTest', {
                path: '/voice-to-text-test'
            });

            this.route('session', {
                path: '/sessions/:session_id'
            });

            this.route('chatDetails', {
                path: '/details/:session_id'
            });
        });

        this.route('call-setup', function () {
            this.route('details');
            this.route('payment-details');
            this.route('confirmation');
        });

        this.route('chat-setup', function () {
            this.route('details');
            this.route('payment-details');
            this.route('confirmation');
        });

        this.route('add-funds', function () {
            this.route('details');
            this.route('payment-details');
            this.route('success');
        });

        this.route('redeem-offer', function () {
            this.route('details');
            this.route('payment-details');
            this.route('success');
            this.route('no-offer');
        });

        this.route('best-match', function () {
            this.route('results');
            this.route('clover', function () {
                this.route('search', {
                    path: '/best-match/clover/search/:keyword'
                });

                this.route('results');
            });
        });

        this.route('add-money');

        this.route('not-authorized');
        this.route('no-customer-data-yet');

        this.route('offer/RedeemSuccess', {
            path: '/offer/redeem-success/:offer_id'
        });

        this.route('offer/NotQualified', {
            path: '/offer/not-qualified/:offer_id'
        });

        this.route('monitor', {
            path: '/monitor'
        });

        this.route('smsNotifications', {
            path: '/sms-notifications'
        });

        //wildcard router to catch all missing URLs and redirect to 404, should be defined last
        this.route('not-found', {
            path: '/*path'
        });

        this.route('dev');

        this.route('featured-listings', function () {
            this.route('listing-quality-score');
            this.route('results', function () {
                this.route('summary');
                this.route('listings');
                this.route('quality-score');
                this.route('bids');
                this.route('clicks');
                this.route('transactions');
                this.route('customers');
            });
            this.route('bid', function () {
                this.route('select-listing');
                this.route('set-bid');
                this.route('confirm-bid');
                this.route('confirmation');
            });
        });
        this.route('myaccount', function () {
            this.route('listings');
            this.route('tax-documents');
            this.route('customers');
            this.route('customer-details', {
                path: 'customers/:id'
            });
            this.route('feedback');
        });
        this.route('direct-messages', function () {
            this.route('inbox', {
                path: '/'
            });
            this.route('conversation', {
                path: '/recipient/:user_id'
            });
        });

        this.route('arranged-conversations', {
            path: '/arranged-conversations/appointments/:appointment_id'
        });
        this.route('arranged-conversations');

        this.route('arrange-conversation', function () {
            this.route('details');
            this.route('payment-details');
            this.route('add-funds');
            this.route('create');
            this.route('confirmation');
            this.route('sms');
            this.route('expiration-extension');
        });

        this.route('appointment', function () {
            this.route('payment-details');
            this.route('phone-number');
            this.route('confirmation');
            this.route('success');
            this.route('error');
        });

        this.route('birth-chart', function () {
            this.route('payment-details');
            this.route('confirmation');
        });

        this.route('tell-a-friend', function () {
            this.route('referral');
            this.route('confirmation');
        });

        this.route('default-payment-method');
    });

    exports.default = Router;
});