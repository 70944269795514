define('ingenio-web/best-match/clover/results/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['id', 'listingstid', 'hlt', 'c'],
        id: null,
        listingstid: null,
        hlt: null,
        c: null,
        showMoreListingsButton: Ember.computed.lt('c', 10),

        actions: {
            loadMoreListings: function loadMoreListings() {
                this.set('c', 10);
                this.get('loadMoreListings')(10);
            }
        }
    });
});