define('ingenio-web/lib/cookie', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    setCookie: function setCookie(key, value, options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          Ember.$.cookie(key, value, options);
          Ember.run(null, resolve);
        } catch (e) {
          Ember.run(null, reject, e);
        }
      });
    },

    getCookie: function getCookie(key) {
      return Ember.$.cookie(key);
    },

    removeCookie: function removeCookie(key, options) {
      return Ember.$.removeCookie(key, options);
    }
  });
});