define('ingenio-web/components/arranged-conversations/customer/waitlist-item-card-take-chat-now/component', ['exports', 'ingenio-web/components/arranged-conversations/customer/waitlist-item-card/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var secondsLeft = this.getSecondsLeft();

      if (secondsLeft > 0) {
        var timer = setInterval(function () {
          return _this.setTimeLeft();
        }, 1000);

        this.set('timer', timer);
        var timeout = setTimeout(function () {
          _this.sendAction('refresh'); //after timeout servicing item should go away
          clearInterval(timer);
        }, secondsLeft * 1000);

        this.set('timeout', timeout);

        this.setTimeLeft();
      }
    },
    willDestroyElement: function willDestroyElement() {
      var timer = this.get('timer');

      if (timer) {
        clearInterval(timer);
      }

      var timeout = this.get('timeout');

      if (timeout) {
        clearInterval(timeout);
      }
      this._super.apply(this, arguments);
    },
    pad: function pad(num) {
      return ('0' + num).slice(-2);
    },
    hhmmss: function hhmmss(secs) {
      var minutes = Math.floor(secs / 60);

      secs = secs % 60;
      minutes = minutes % 60;
      return this.pad(minutes) + ':' + this.pad(secs);
    },
    setTimeLeft: function setTimeLeft() {
      var secondsLeft = this.getSecondsLeft();

      if (secondsLeft > 0) {
        this.set('timeLeft', '' + this.hhmmss(secondsLeft));
      } else {
        this.set('timeLeft', null);
      }
    },
    getSecondsLeft: function getSecondsLeft() {
      var servicingSince = this.get('model.servicingSince');

      if (!servicingSince) {
        return 0;
      }

      var diffInSec = moment().diff(servicingSince, 'seconds');

      if (diffInSec >= 0 && diffInSec < 120) {
        return 120 - diffInSec;
      }

      return 0;
    },


    isServicing: Ember.computed('model.status', function () {
      var status = this.get('model.status').toString().toLowerCase();

      return true || status === 'servicing';
    }),

    startChatUrl: Ember.computed('model.id', function () {
      var arId = this.get('model.id');

      return '/webar/' + arId;
    }),

    actions: {
      takeChatNow: function takeChatNow() {
        var _this2 = this;

        setTimeout(function () {
          document.location.href = _this2.get('startChatUrl');
        }, 400);
      }
    }
  });
});