define('ingenio-web/chat-setup/availability-error/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
        flashMessages: Ember.inject.service(),

        checkoutFlowModel: function checkoutFlowModel() {
            return this.modelFor(this.getParentRouteName());
        },
        model: function model() {
            var model = this._super.apply(this, arguments);

            var checkoutFlowModel = this.checkoutFlowModel();
            var advisorId = checkoutFlowModel.listing.get('advisorId');

            return this.store.queryRecord('directMessagesEligibility', { recipientId: advisorId }).then(function (eligibility) {
                checkoutFlowModel.set('pingEligible', eligibility.get('name') === 'Eligible');
                return model;
            });
        }
    });
});