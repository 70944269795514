define('ingenio-web/mixins/checkout-flow/route-base', ['exports', 'ingenio-web/models/checkout-flow'], function (exports, _checkoutFlow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    experimentation: Ember.inject.service('experimentation'),

    arrangeId: null,
    arrangeChatLogging: Ember.inject.service('arrange-chat-logging'),

    model: function model(params, transition) {
      var _this = this;

      var user = Ember.Application.user;
      var listingId = transition.queryParams.listingid;

      var promises = {
        listing: this.store.findRecord('listing', listingId),
        countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
        balance: this.store.findRecord('balance', user.id, {
          adapterOptions: {
            listingId: listingId
          }
        }),
        userOffer: this.store.queryRecord('specialOffer', {
          listingId: listingId
        }).then(function (payload) {
          return payload;
        }).catch(function (adapterError) {
          var error = adapterError.errors[0];

          if (error.status === 404) {
            console.warn(error.title);
          } else {
            throw adapterError;
          }
        }),
        paymentMethods: this.store.findAll('paymentMethod'),
        phoneNumbers: this.store.findAll('userPhone'),
        priceBuckets: this.store.query('priceBucket', { listingId: listingId }),
        experiments: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/experiments'),
        buckets: Ember.$.post(Ember.ENV.getApiRoot() + '/experiments/buckets', {
          identifier: 'NEW_USER_PRICE_BUCKET_TEST',
          domainId: Ember.ENV.domainId,
          tempUid: window.ENV.keenUid
        }),
        orderPrepare: this.store.createRecord('orderPrepare').save({ adapterOptions: { listingId: listingId } })
      };
      if (transition.queryParams.arrangeid) {
        var arrangeId = transition.queryParams.arrangeid;
        arrangeId = arrangeId.replace(/[^\d]/g, '');

        this.set('arrangeId', arrangeId);
        promises.arrangedChat = this.store.findRecord('arrangedChat', arrangeId);
      }

      return Ember.RSVP.hash(promises).then(function (payload) {
        return _this.transformPromiseHashIntoViewModel(payload);
      }).catch(function (error) {
        if (_this.get('arrangeId')) {
          _this.get('arrangeChatLogging').trackEvent('ChatSessionCreatePreConditionFailure', _this.get('arrangeId'), {
            code: 'GENERAL_FAILURE',
            error: error.message
          });
        }
      });
    },
    getRepeatUserMinimumDepositAmount: function getRepeatUserMinimumDepositAmount() {
      var mergedExperimentProps = this.get('experimentation.mergedProperties');

      if (mergedExperimentProps.minimumDepositAmount > 0) {
        return mergedExperimentProps.minimumDepositAmount;
      }
      return Ember.ENV.repeatUserMinimumDepositAmount;
    },
    transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload) {
      var fundsModel = this.store.createRecord('funds');

      var callMinutes = payload.balance.get('callMinutes');
      var chatMinutes = payload.balance.get('chatMinutes');
      var ignoreOfferForCheckoutFlow = payload.userOffer ? payload.userOffer.get('ignoreOfferForCheckoutFlow') : false;
      var discountRewardType = 6;
      var isPromoOffer = payload.userOffer ? payload.userOffer.get('rewardType') === discountRewardType : false;

      // we don't need to show offer if user have enough money for 1 min chat/call 
      // unless its a promotion (advisor sale) offer
      if (!isPromoOffer && (chatMinutes > 0 || callMinutes > 0 || ignoreOfferForCheckoutFlow)) {
        payload.userOffer = null;
      }

      // use the default payment method, by default, if found.
      // handle a weird edge case where the user does not have any primary
      // payment methods and choose the first object by default.
      if (!Ember.isEmpty(payload.paymentMethods)) {
        var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
          return method.get('isPrimary');
        }) || payload.paymentMethods.get('firstObject');

        fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
      }

      // TODO: currently because of the offer engine, the user must deposit the qualifier amount
      // even if they have a sufficient account balance. after the engine is fixed, the logic here
      // should be updated to avoid forcing the user to deposit additional funds when they have enough
      // in their account funds.

      // when setting up the funds model ... populate the offer price if in an offer flow.
      if (payload.userOffer && payload.userOffer.get('qualifierAmount')) {
        fundsModel.set('offerId', payload.userOffer.get('id'));
        fundsModel.set('offerAmount', parseFloat(payload.userOffer.get('qualifierAmount')).toFixed(2));
      }

      var hasAddedFundsInPast = Ember.Application.user.hasAddedFundsInPast;
      var userOffer = payload.userOffer;
      var balance = payload.balance.get('monetary');

      var quickStart = false;
      var arrangedChat = null;
      if (payload.arrangedChat) {
        arrangedChat = payload.arrangedChat;
        quickStart = true;
        this.get('arrangeChatLogging').trackEvent('ChatConfirmation', this.get('arrangeId'));
      }

      return _checkoutFlow.default.create({
        domain: {
          id: Ember.ENV.domainId,
          name: Ember.ENV.domainName
        },
        listing: payload.listing,
        quickStart: quickStart,
        arrangedChat: arrangedChat,
        user: {
          funds: fundsModel,
          balance: payload.balance,
          offer: payload.userOffer,
          paymentMethods: payload.paymentMethods,
          phoneNumbers: payload.phoneNumbers,
          priceBuckets: payload.priceBuckets
        },
        countries: payload.countries,

        showStepsType: this.getStepsType(hasAddedFundsInPast, payload.paymentMethods),
        showDepositeType: this.getDepositeType(hasAddedFundsInPast, userOffer, balance),

        showOfferRemovalMessage: false, //Flag to display the message upon user offer removal
        repeatUserMinimumDepositAmount: this.getRepeatUserMinimumDepositAmount(),
        orderPrepare: payload.orderPrepare
      });
    },
    getDepositeType: function getDepositeType(hasAddedFundsInPast, userOffer, balance) {
      if (hasAddedFundsInPast) {
        if (userOffer && userOffer.get('qualifierAmount')) {
          return 'addMoney';
        }
        return 'addMinutes';
      } else {
        if (!userOffer && !balance) {
          return 'addMinutes';
        }
        return 'addMoney';
      }
    },
    getStepsType: function getStepsType(hasAddedFundsInPast, paymentMethods) {
      if (!paymentMethods) {
        return 'show3Steps';
      }

      var today = moment();
      var validCard = paymentMethods.find(function (card) {
        var expirationDate = moment(card.get('expiration'), 'MMYY');
        return expirationDate.isValid() && today < expirationDate.add(1, 'months');
      });
      if (hasAddedFundsInPast && validCard) {
        return 'hide';
      }
      return 'show3Steps';
    },


    actions: {
      onUserOfferDeleted: function onUserOfferDeleted() {
        var model = this.modelFor(this.routeName);
        model.set('showOfferRemovalMessage', true);
      }
    }
  });
});