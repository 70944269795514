define('ingenio-web/models/arrange-conversation-flow', ['exports', 'ingenio-web/models/checkout-flow'], function (exports, _checkoutFlow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _checkoutFlow.default.extend({
        flowType: 'arrange-conversation',

        notifications: { success: [], errors: [] },

        smsPageDisplayed: false,
        userInAddFundsFlow: false,
        returnRoute: null,
        userOperation: null,
        conversationType: 'CALL',
        advisorConversationRequest: null,
        waitlistInfo: null,
        minimumBalanceMinutesForConversation: 3,

        isChatConversation: Ember.computed('conversationType', 'advisorConversationRequest', function () {
            if (this.get('advisorConversationRequest')) {
                return this.get('advisorConversationRequest.forConversationType') === 'CHAT';
            }
            return this.get('conversationType') === 'CHAT';
        }),

        isCallConversation: Ember.computed('conversationType', 'advisorConversationRequest', function () {
            if (this.get('advisorConversationRequest')) {
                return !this.get('advisorConversationRequest.forConversationType') === 'CHAT';
            }
            return !this.get('conversationType') === 'CHAT';
        }),

        minimumBalanceRequiredForListing: Ember.computed('totalFeesDue', 'listing.minuteRateAsNumber', 'minimumBalanceMinutesForConversation', function () {
            var totalFeesDue = this.get('totalFeesDue');
            var pricePerMinute = this.get('minuteRateWithDiscount');
            var minimumBalanceMinutes = this.get('minimumBalanceMinutesForConversation');

            return pricePerMinute * minimumBalanceMinutes + totalFeesDue;
        }),

        isAddFundsRequired: Ember.computed('minimumBalanceRequiredForListing', 'user.balance.monetaryAsNumber', 'totalMinutesWithCurrentOffer', 'minimumBalanceMinutesForConversation', function () {
            // check if user has promotional minutes and if they are sufficient to start a call per domain.
            if (this.get('totalMinutesWithCurrentOffer') >= this.get('minimumBalanceMinutesForConversation')) {
                return false;
            }

            return this.get('user.balance.monetaryAsNumber') < this.get('minimumBalanceRequiredForListing');
        }),

        minimumDepositAmount: Ember.computed('totalFeesDue', 'user.balance.sellerMinutes', 'user.balance.monetaryAsNumber', 'minimumBalanceRequiredForListing', 'listing.minuteRate', 'isAddFundsRequired', 'totalMinutesWithCurrentOffer', 'minimumBalanceMinutesForConversation', function () {
            if (!this.get('isAddFundsRequired')) {
                return 0;
            }

            var sellerMinutes = this.get('user.balance.sellerMinutes');
            var pricePerMinute = this.get('minuteRateWithDiscount');
            var totalMinutesWithCurrentOffer = this.get('totalMinutesWithCurrentOffer');
            var minimumBalanceMinutesForConversation = this.get('minimumBalanceMinutesForConversation');

            var userBalance = this.get('user.balance.monetaryAsNumber');

            var userBalanceRemaining = userBalance;
            if (totalMinutesWithCurrentOffer > 0) {
                userBalanceRemaining = userBalance - (totalMinutesWithCurrentOffer * pricePerMinute + this.get('totalFeesDue'));
            }

            var addedFundsBefore = this.get('user.hasAddedFundsInPast');
            var repeatUserMinimumDepositAmount = this.get('repeatUserMinimumDepositAmount');
            var absoluteMinimumDeposit = addedFundsBefore ? repeatUserMinimumDepositAmount : Ember.ENV.minimumDepositAmount;

            // if user has at least 1 minute of talk time the fees due are already covered.
            // we included the original totalFeesDue above still as its needed to determine the remainder.
            var totalFeesDue = this.get('totalFeesDue');
            if (totalMinutesWithCurrentOffer > 0) {
                totalFeesDue = 0;
            }

            // if a user has any seller minutes, we need to determine amount to deposit separately.
            if (sellerMinutes > 0 && totalMinutesWithCurrentOffer === 0) {
                // if a user has 3 seller minutes and insufficient funds, we should only charge for the minimum to 
                // cover the session fee. we should revisit code when we migrate to Next.js => very messy code.
                if (sellerMinutes + totalMinutesWithCurrentOffer >= minimumBalanceMinutesForConversation) {
                    return Number(Math.max(totalFeesDue, absoluteMinimumDeposit).toFixed(2));
                }

                var _minutesRemaining = minimumBalanceMinutesForConversation - sellerMinutes;
                var _expectedAmountDue = _minutesRemaining * pricePerMinute + totalFeesDue - userBalanceRemaining;
                return Number(Math.max(_expectedAmountDue, absoluteMinimumDeposit).toFixed(2));
            }

            var minutesRemaining = minimumBalanceMinutesForConversation - totalMinutesWithCurrentOffer;
            var expectedAmountDue = minutesRemaining * pricePerMinute + totalFeesDue - userBalanceRemaining;
            return Number(Math.max(expectedAmountDue, absoluteMinimumDeposit).toFixed(2));
        }),

        isAddPaymentMethodRequired: Ember.computed('hasExistingPaymentMethods', function () {
            var paymentMethod = this.get('paymentMethodSelected');

            if (!paymentMethod) {
                return true;
            }

            if (paymentMethod.get('chargeAccountType') === 1) {
                return false;
            }

            var today = moment();
            var expirationDate = moment(paymentMethod.get('expiration'), 'MMYY');

            return !(expirationDate.isValid() && today < expirationDate.add(1, 'months'));
        }),

        totalMinutesWithCurrentOffer: Ember.computed('listing.minuteRateAsNumber', 'user.balance.monetaryAsNumber', 'user.balance.sellerMinutes', 'user.offer.rewardMinutes', 'user.offer.rewardMoney', 'user.offer.qualifierAmount', 'totalFeesDue', function () {
            var totalFeesDue = this.get('totalFeesDue');
            var isChatConversation = this.get('isChatConversation');
            var callMinutes = Number(this.get('user.balance.callMinutes') || 0);
            var chatMinutes = Number(this.get('user.balance.chatMinutes') || 0);
            var userBalance = Number(this.get('user.balance.monetaryAsNumber') || 0);

            var minutes = isChatConversation ? chatMinutes : callMinutes;
            if (minutes === 0) {
                var offerMinutes = Number(this.get('user.offer.rewardMinutes') || 0);
                minutes += offerMinutes;
            }

            if (userBalance >= totalFeesDue) {
                var sellerMinutes = Number(this.get('user.balance.sellerMinutes') || 0);
                minutes += sellerMinutes;
            }

            return minutes;
        }),

        isSmsOptional: Ember.computed('isChatConversation', function () {
            return !this.get('isChatConversation');
        }),

        smsOptionalMessage: Ember.computed('isChatConversation', function () {
            if (!this.get('isChatConversation')) {
                return 'Without enabled SMS you will not get notified for the arranged conversation';
            }
            return 'Without enabled SMS you will not get notified for the arranged conversation';
        }),

        isSmsPageRequired: Ember.computed('user.notifications.viaSms.enabled', 'smsPageDisplayed', 'isSmsOptional', function () {
            if (this.get('smsPageDisplayed') && this.get('isSmsOptional')) {
                return false;
            }

            return !this.get('user.notifications.viaSms.enabled');
        }),

        canArrangeConversation: Ember.computed('isAddPaymentMethodRequired', 'isAddFundsRequired', 'isSmsPageRequired', function () {
            return !this.get('isAddPaymentMethodRequired') && !this.get('isAddFundsRequired') && this.get('hasExistingPhoneNumber') && !this.get('isSmsPageRequired');
        }),

        totalFeesDue: Ember.computed('orderPrepare.fees.charges', function () {
            return this.get('orderPrepare.fees.charges').reduce(function (aggregator, item) {
                return aggregator + item.amount;
            }, 0);
        }),

        feesNames: Ember.computed('orderPrepare.fees.charges', function () {
            return this.get('orderPrepare.fees.charges').reduce(function (aggregator, item) {
                if (aggregator.length > 0) {
                    return aggregator + ', ' + item.name;
                }
                return item.name;
            }, "").replace(/,(?=[^,]+$)/, ' and');
        }),

        minuteRateWithDiscount: Ember.computed('listing.minuteRateAsNumber', 'user.offer.rewardType', function () {
            var rewardType = Number(this.get('user.offer.rewardType') || 0);
            var serviceRate = this.get('listing.minuteRateAsNumber');
            var discountRewardType = 6;
            if (rewardType === discountRewardType) {
                var discountedMinuteRate = Math.round(serviceRate * 100) / 100;
                var rewardAmount = this.get('user.offer.rewardAmount');
                discountedMinuteRate = serviceRate * 100 * (100 - rewardAmount) / 100;
                discountedMinuteRate = Math.ceil(discountedMinuteRate) / 100;
                return discountedMinuteRate;
            }
            return serviceRate;
        })
    });
});