define('ingenio-web/components/privacy/do-not-sell-my-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ipAddresses: Ember.A(),
        advertisingIds: Ember.A(),
        formSubmittedSuccessfully: false,
        domainName: Ember.ENV.domainName,
        domainId: Ember.ENV.domainId,
        privacyEmail: Ember.ENV.domainName === 'Keen' ? 'privacy@keen.com' : 'privacy@psychiccenter.com',

        init: function init() {
            this._super.apply(this, arguments);

            this.set('ipAddresses', Ember.A());
            this.set('advertisingIds', Ember.A());

            // Initially show atleast one ipAddress and advertisingId field.
            this.get('ipAddresses').pushObject(''); // adds an empty ipAddress field
            this.get('advertisingIds').pushObject(''); //adds an empty advertising id field.
        },
        resetForm: function resetForm() {
            //reset number of ipAddress and advertisingId fields to one each.
            this.setProperties({
                ipAddresses: [''],
                advertisingIds: ['']
            });
            //reset all the form field values.
            document.getElementById('dnsmyinfo-form').reset();
        },


        actions: {
            addIpAddress: function addIpAddress() {
                this.get('ipAddresses').pushObject('');
            },
            addAdvertisingId: function addAdvertisingId() {
                this.get('advertisingIds').pushObject('');
            },
            submit: function submit() {
                var _this = this;

                var serializedData = Ember.$('#dnsmyinfo-form').serialize();
                var url = Ember.ENV.getApiRoot() + '/privacy/donotsellmyinfo';

                Ember.$.post(url, serializedData).done(function () {
                    _this.resetForm();
                    _this.set('formSubmittedSuccessfully', true);
                }).fail(function (reason) {
                    _this.get('loggerService').error('Error submitting DoNotSellMyInfo form', {}, reason);
                    _this.toast.error('Oops! There is a problem submitting your request. Please try again later.');
                });
            }
        }
    });
});