define('ingenio-web/mixins/transaction-details/transaction-details-consumer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        poll: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('listing', this.get('model.listing'));
            this.getAvailability();
            this.checkLoyaltySettings();
            this.startPolling();
        },
        didRecieveAttributes: function didRecieveAttributes() {
            this._super.apply(this, arguments);
            // Component data had changed at this point. So, clear previous poll and start afresh.
            this.stopPolling();
            this.startPolling();
        },
        startPolling: function startPolling() {
            this.get('poll').addPoll({
                interval: 30 * 1000, // 30 seconds
                callback: Ember.run.bind(this, this.getAvailability),
                label: 'advisor-availability'
            });
        },
        stopPolling: function stopPolling() {
            this.get('poll').stopPollByLabel('advisor-availability');
        },
        willDestroyElement: function willDestroyElement() {
            this.stopPolling();
            this._super.apply(this, arguments);
        },
        getAvailability: function getAvailability() {
            var _this = this;

            var listingId = this.get('model.listing.id');

            this.get('store').queryRecord('listing-v2', { id: listingId, includeDeletedListing: true, includeArrangeChatStatus: true }).then(function (listing) {
                _this.set('listing', listing);
            });
        },
        checkLoyaltySettings: function checkLoyaltySettings() {
            var _this2 = this;

            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/settings/loyalty').then(function (response) {
                var isKeen = Ember.ENV.domainId === 1;
                if (response.enabled && isKeen) {
                    _this2.set('showRewards', true);
                    _this2.getRewardPoints();
                } else {
                    _this2.set('showRewards', false);
                }
            }).fail(function () {
                _this2.set('showRewards', false);
            });
        },
        getRewardPoints: function getRewardPoints() {
            var _this3 = this;

            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            Ember.$.getJSON(apiUrl + '/loyalty/users/' + userInfo.id).then(function (response) {
                _this3.set('rewardInfo', response);

                var currentTierName = response.tiers.current.name.toLowerCase();
                switch (currentTierName) {
                    default:
                        _this3.set('rewardTierName', 'discover');
                        break;
                    case 'awaken':
                        _this3.set('rewardTierName', 'awaken');
                        break;
                    case 'clarity':
                        _this3.set('rewardTierName', 'clarity');
                        break;
                    case 'transcend':
                        _this3.set('rewardTierName', 'transcend');
                        _this3.set('showTranscend', true);
                        break;
                }
            }).fail(function () {
                _this3.set('showRewards', false);
            });
        },


        userImage: function () {
            var picture = this.get('model.listing.picture.primaryVersion.url') || 'https://si.keen.com/D1_default-advisor-img_V1.png';

            return picture;
        }.property('model.listing.picture.primaryVersion.url'),

        advisorAvailabilityText: function () {
            var availability = this.get('listing.availability');
            var chatAvailability = this.get('listing.chatAvailability');
            var text = '';

            switch (availability) {
                case 2: //busySendMail
                case 3: //busyAppt
                case 4:
                    //busyArrangeCall
                    text = 'Busy';
                    break;
                case 5: //awayArrangeCall
                case 6: //awaySendMail
                case 7:
                    //awayAppt
                    text = 'Away';
                    break;
            }

            if (chatAvailability === 2) {
                text = '';
            }

            return text;
        }.property('listing.availability', 'listing.chatAvailability')
    });
});