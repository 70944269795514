define('ingenio-web/components/chat/end-chat-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            toggleEndChatModal: function toggleEndChatModal() {
                this.get('onToggleEndChatModal')();
            },

            endSessionSelf: function endSessionSelf() {
                this.get('onEndSession')();
            }
        }
    });
});