define('ingenio-web/components/listings/category-listings/component', ['exports', 'ingenio-web/utils/cookies'], function (exports, _cookies) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        cloverListingId: null,
        showReserveConfirmation: false,

        // mixpanel tracking props
        trackingScreen: null,
        trackingSource: null,

        // webtrack props
        callWebTrack: null,
        callAdpWebTrack: null,
        chatWebTrack: null,
        chatAdpWebTrack: null,

        clickId: null,
        eventEmitter: Ember.inject.service('event-emitter'),
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        impressionTrackingForListings: null,

        didRender: function didRender() {
            this._super.apply(this, arguments);
            if (!_cookies.default.isCloverFlow()) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                    if (this.get('impressionTrackingForListings')) {
                        this.get('impressionTrackingForListings')();
                    }
                });
            }
        },


        actions: {
            cloverCancelReserveClick: function cloverCancelReserveClick() {
                this.set('cloverListingId', null);
                this.set('showReserveConfirmation', false);
            },
            cloverReserveConfirmClick: function cloverReserveConfirmClick() {
                var listingId = this.get('cloverListingId');

                if (listingId > 0) {
                    if (_cookies.default.isCloverFlow()) {
                        var callId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'CallId');
                        var agentId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'AgentId');
                        var operatorId = _cookies.default.getGroupCookieUnescaped('CLOVER', 'OperatorId');

                        this.get('eventEmitter').emit('ListingPicked', {
                            callId: callId,
                            agentId: agentId,
                            operatorId: operatorId,
                            listingId: listingId
                        }).then(function () {
                            alert('Advisor was successfully reserved'); //eslint-disable-line no-alert
                        }).catch(function (XMLHttpRequest) {
                            alert('error');
                            var errorJson = void 0;
                            if (XMLHttpRequest.responseText && XMLHttpRequest.responseText.length > 0) {
                                errorJson = JSON.stringify(JSON.parse(XMLHttpRequest.responseText), null, 4);
                            } else {
                                errorJson = 'Unknown error while reserving advisor';
                            }
                            $('#pickAdvisorErrorBody').text(errorJson);
                            $('#pickAdvisorErrorDialog').modal('show');
                        });
                    } else {
                        console.error('CLOVER: A flow cookie was not detected for the current session.');
                    }
                } else {
                    console.error('CLOVER: A listing ID was not provided to complete this reservation.');
                }
            },
            cloverReserveButtonClick: function cloverReserveButtonClick(listingId) {
                this.set('cloverListingId', listingId);
                this.set('showReserveConfirmation', true);
            },
            listingDetailsClick: function listingDetailsClick(tid, detailsUrl) {
                // do not open ADP if in CLOVER flow.
                if (this.get('isClover')) {
                    return;
                }

                var url = detailsUrl;

                if (tid) {
                    url += '&tid=' + tid;
                }

                if (this.trackingScreen) {
                    url += '&screen=' + this.trackingScreen;
                }

                if (this.trackingSource) {
                    url += '&source=' + this.trackingSource;
                }

                if (this.callAdpWebTrack > 0) {
                    url += '&callwebtrack=' + this.callAdpWebTrack;
                }

                if (this.chatAdpWebTrack > 0) {
                    url += '&chatwebtrack=' + this.chatAdpWebTrack;
                }

                if (this.clickId) {
                    url += '&clickid=' + this.clickId;
                }

                var anchorTarget = this.get('isClover') ? '_blank' : '_parent';

                window.open(url, anchorTarget);
            }
        }
    });
});