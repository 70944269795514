define('ingenio-web/models/call', ['exports', 'ember-data/attr', 'ember-data/model', 'ingenio-web/models/call-result-codes'], function (exports, _attr, _model, _callResultCodes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        serviceId: (0, _attr.default)('number'),
        webTrackId: (0, _attr.default)('number'),
        phoneNumber: (0, _attr.default)('string'),
        phoneNumberType: (0, _attr.default)('string'),
        phoneCountryCode: (0, _attr.default)('string'),
        isPostPay: (0, _attr.default)('boolean'),
        clickId: (0, _attr.default)('string'),
        message: (0, _attr.default)('string'),
        resultCode: (0, _attr.default)('number'),

        resultCodeAsString: Ember.computed('resultCode', {
            get: function get() {
                var _this = this;

                return Object.keys(_callResultCodes.default).find(function (key) {
                    return _callResultCodes.default[key] === parseInt(_this.get('resultCode'));
                });
            }
        })
    });
});