define('ingenio-web/call-setup/confirmation/route', ['exports', 'ingenio-web/mixins/checkout-flow/confirmation/route', 'ingenio-web/mixins/checkout-flow/conversation-payment-method-route-check'], function (exports, _route, _conversationPaymentMethodRouteCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend(_conversationPaymentMethodRouteCheck.default, {
    actions: {
      didTransition: function didTransition() {
        this.get('mixpanelClient').trackNewEvent('Call Setup - Confirmation Viewed');
      }
    }
  });
});