define('ingenio-web/best-match/clover/results/route', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/routes/public-route'], function (exports, _headerTypes, _footerTypes, _publicRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _publicRoute.default.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.EMPTY;
        },
        defineFooterType: function defineFooterType() {
            return _footerTypes.default.EMPTY;
        },

        model: function model(params, transition) {
            var id = transition.queryParams.id;
            var listingsTid = transition.queryParams.listingstid;
            var hlt = transition.queryParams.hlt;

            var c = transition.queryParams.c;

            if (!c) {
                c = 4;
            }

            this.set('id', id);
            this.set('listingsTid', listingsTid);
            this.set('hlt', hlt);
            return this.loadListings(id, c, listingsTid, hlt);
        },
        loadListings: function loadListings(id, size, tid, hlt, minPrice, maxPrice) {
            var _this = this;

            //eslint-disable-line
            this.clearRefresh();
            var maxPriceVal = maxPrice;
            var controller = this.controller;

            //Set Arbitrarily Large Max Price When $10.00+ Is Chosen.
            if (parseFloat(maxPrice) === parseFloat(10.00)) {
                maxPriceVal = 999.99;
            }

            var url = Ember.ENV.getApiRoot() + '/listings?' + ('type=best-match&token=' + id + '&index=0&size=' + size + '&') + ('listingsTid=' + tid + '&HLT=' + hlt + '&minPrice=' + minPrice + '&maxPrice=' + maxPriceVal);

            return $.ajax({
                url: url,
                type: 'GET'
            }).then(function (response) {
                var index = 1;

                response.listings.forEach(function (l) {
                    l.index = index++;
                });
                return response.listings;
            }).always(function () {
                if (controller) {
                    controller.set('loading', false);
                }

                var refreshId = setTimeout(function () {
                    _this.loadListings(id, size, tid, hlt, minPrice, maxPrice).then(function (listings) {
                        if (controller) {
                            controller.set('listings', listings);
                        }
                    });
                }, 15 * 1000);

                _this.set('refreshId', refreshId);
            });
        },
        setupController: function setupController(controller, model) {
            var _this2 = this;

            controller.set('listings', model);
            controller.set('loadMoreListings', function (c) {
                //controller.set('loading', true);
                _this2.loadListings(_this2.get('id'), c, _this2.get('listingsTid'), _this2.get('hlt')).then(function (listings) {
                    controller.set('listings', listings);
                });
            });
        },
        clearRefresh: function clearRefresh() {
            var refreshId = this.get('refreshId');

            if (refreshId) {
                clearTimeout(refreshId);
                this.set('refreshId', null);
            }
        },

        actions: {
            willTransition: function willTransition() {
                this.clearRefresh();
            },
            loading: function loading() {
                //overriding default loading event to prevent minimal header from showing up. Setup a custom loader here if needed.
            },
            priceFilterChanged: function priceFilterChanged(minPrice, maxPrice) {
                var _this3 = this;

                var id = this.get('id');
                var listingsTid = this.get('listingsTid');
                var hlt = this.get('hlt');

                this.loadListings(id, 10, listingsTid, hlt, minPrice, maxPrice).then(function (listings) {
                    _this3.controller.set('listings', listings);
                });
            }
        }
    });
});