define('ingenio-web/mixins/validatable-model', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        validationState: {
            valid: true,
            touched: false,
            message: ''
        },

        setup: function () {
            //assumes this mixin is used from inside a component
            if (!(this instanceof Ember.Component)) {
                throw new Error('"Validatable-model" mixin is supposed to be used with components only.');
            }

            if (!this.get('model')) {
                throw new Error('"Validatable-model" mixin requires model to be provided.');
            }

            if (!this.get('field')) {
                throw new Error('"Validatable-model" mixin requires field to be provided.');
            }
        }.on('init'),

        didInsertElement: function didInsertElement() {
            this.validate();
        },


        markTouched: function markTouched() {
            var field = this.get('field');
            this.get('model').addTouched(field);
        },

        validate: function validate() {
            if (typeof this.get('model.validate') === 'function') {
                this.get('model').validate();
            }
        },

        validationObserver: function () {
            var field = this.get('field');
            var isTouched = this.get('model').isTouched(field);

            if (this.get('model').hasError(field)) {
                var message = this.get('model').errorMessage(field);

                this.set('validationState', {
                    valid: false,
                    touched: isTouched,
                    message: message
                });

                this.$().addClass('invalid');
                if (isTouched) {
                    this.$().addClass('field-error');
                }
            } else {
                this.set('validationState', {
                    valid: true,
                    touched: isTouched,
                    message: ''
                });

                this.$().removeClass('invalid');
                this.$().removeClass('field-error');
            }
        }.observes('model.validationErrors.length', 'model.touched.length'),

        showError: function () {
            var showHint = !!this.get('showHint') || this.get('validationState.touched');
            if (showHint) {
                return !this.get('validationState.valid');
            }
            return false;
        }.property('validationState', 'showHint'),

        actions: {
            onInput: function onInput() {
                this.validate();
            },
            onFocusOut: function onFocusOut() {
                this.markTouched();
            },
            onFocusIn: function onFocusIn() {
                this.validate();
            }
        }
    });
});