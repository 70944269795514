define("ingenio-web/arrange-conversation/payment-details/controller", ["exports", "ingenio-web/mixins/scroll-helpers", "ingenio-web/mixins/checkout-flow/payment-details/controller"], function (exports, _scrollHelpers, _controller) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, {
    queryParams: ["listingid", "pmode", "webtrack", 'clickid', "arrange", "r", "ctype"],

    arrangeConversation: Ember.inject.controller(),
    steps: Ember.computed.alias("arrangeConversation.steps"),

    getNextRouteName: function getNextRouteName() {
      if (this.get("model.userInAddFundsFlow")) {
        return this.getParentRouteName() + ".add-funds";
      } else if (this.get("model.isAddFundsRequired")) {
        return this.getParentRouteName() + ".add-funds";
      } else if (this.get("model.isSmsPageRequired")) {
        return this.getParentRouteName() + ".sms";
      } else if (this.get("model.canArrangeConversation")) {
        return this.getParentRouteName() + ".create";
      }
    }
  });
});