define('ingenio-web/components/featured-listings/bid/bid-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        minimumBidAmount: 0.20,
        maximumBidAmount: 200.00,
        maximumBudgetAmount: 9999999.00,
        minimumBudgetAmount: Ember.computed('bidAmount', function () {
            // The minimum budget allowed is x times the bid amount. The value is hard-coded here, but should align with attribute:
            // (Features.LeadGenerationPrograms.BidPrograms.Click.SpendLimits.SystemSettings.MinBidsSufficientForUserStopBidding).
            return this.get('bidAmount') * 3;
        }),

        bidAmountControlId: 'bidAmount',
        budgetAmountControlId: 'budgetAmount',
        bidExpirationTypeControlId: 'bidExpirationType',

        bidExpirationType: Ember.computed('bidAmount', 'budgetAmount', 'expirationType', {
            get: function get() {
                var expirationType = this.get('expirationType');

                if (expirationType) {
                    return expirationType;
                }

                return this.get('budgetAmount') ? 'budget' : 'no-expiration';
            },
            set: function set(key, value) {
                if (value === 'no-expiration' && this.get('budgetAmount') > 0.00) {
                    this.set('budgetAmount', null);

                    this.removeValidationError('budgetAmount');
                }

                this.set('expirationType', value);

                return value;
            }
        }),

        hasValidationErrors: function () {
            var bidAmount = this.get('bidAmount');
            var validationErrors = this.get('validationErrors');
            var validationControls = JSON.parse(JSON.stringify(validationErrors));

            var errorCount = Object.keys(validationControls).map(function (property) {
                return validationControls[property] !== null ? 1 : 0;
            }).reduce(function (sum, hasError) {
                return sum + hasError;
            });

            return errorCount > 0 || !bidAmount;
        }.property('bidAmount', 'validationErrors.bidAmount', 'validationErrors.budgetAmount', 'validationErrors.bidExpirationType'),

        init: function init() {
            this._super.apply(this, arguments);

            this.set('validationErrors', Ember.Object.create({
                bidAmount: null,
                budgetAmount: null,
                bidExpirationType: null
            }));

            // Trigger validation on component load for existing bids
            if (this.get('bidId')) {
                this.send('bidAmountChanged');
            }
        },


        actions: {
            previousStep: function previousStep() {
                this.sendAction('previousStep');
            },
            cancelBid: function cancelBid() {
                this.sendAction('cancelBid');
            },
            nextStep: function nextStep() {
                this.sendAction('nextStep');
            },
            bidAmountKeyUp: function bidAmountKeyUp() {
                var _this = this;

                Ember.run.cancel(this.get('bidAmountValidationTimer'));

                this.set('bidAmountValidationTimer', Ember.run.later(function () {
                    _this.validateBidAmount();
                }, 1000));
            },
            bidAmountChanged: function bidAmountChanged() {
                Ember.run.cancel(this.get('bidAmountValidationTimer'));

                this.set('bidAmount', this.formatAmount(this.get('bidAmount')));

                this.validateBidAmount();

                // When the bid amount is changed, re-evaluate the budget amount to ensure the minimum budget is still met.
                this.send('budgetAmountChanged');
            },
            budgetAmountKeyUp: function budgetAmountKeyUp() {
                var _this2 = this;

                var budgetAmount = this.formatAmount(this.get('budgetAmount'));

                this.set('expirationType', 'budget');

                if (!budgetAmount) {
                    return;
                }

                Ember.run.cancel(this.get('budgetAmountValidationTimer'));

                this.set('budgetAmountValidationTimer', Ember.run.later(function () {
                    _this2.validateBudgetAmount();
                }, 1000));
            },
            budgetAmountChanged: function budgetAmountChanged() {
                Ember.run.cancel(this.get('budgetAmountValidationTimer'));

                this.set('budgetAmount', this.formatAmount(this.get('budgetAmount')));

                if (this.get('bidExpirationType') !== 'budget') {
                    return;
                }

                this.validateBudgetAmount();
            },
            bidExpirationTypeChanged: function bidExpirationTypeChanged() {
                var budgetAmount = this.get('budgetAmount');
                var bidExpirationType = this.get('bidExpirationType');

                var budgetAmountControlId = this.get('budgetAmountControlId');
                var bidExpirationTypeControlId = this.get('bidExpirationTypeControlId');

                if (bidExpirationType === 'budget' && !budgetAmount) {
                    this.addValidationError(bidExpirationTypeControlId, 'Budget amount is required.');
                } else {
                    this.removeValidationError(budgetAmountControlId);
                    this.removeValidationError(bidExpirationTypeControlId);

                    this.send('budgetAmountChanged');
                }
            }
        },

        validateBidAmount: function validateBidAmount() {
            var bidAmountControlId = this.get('bidAmountControlId');

            var bidAmount = this.formatAmount(this.get('bidAmount'));
            var minimumBidAmount = this.formatAmount(this.get('minimumBidAmount'));
            var maximumBidAmount = this.formatAmount(this.get('maximumBidAmount'));

            if (!bidAmount) {
                this.addValidationError(bidAmountControlId, 'We\'re sorry, but you need to enter a valid amount.');
            } else if (parseFloat(bidAmount) < parseFloat(minimumBidAmount)) {
                this.addValidationError(bidAmountControlId, 'We\'re sorry, the minimum bid amount is $' + minimumBidAmount + ' per click.');
            } else if (parseFloat(bidAmount) > parseFloat(maximumBidAmount)) {
                this.addValidationError(bidAmountControlId, 'We\'re sorry, the maximum bid allowed is $' + maximumBidAmount + ' per click.');
            } else {
                this.removeValidationError(bidAmountControlId);
            }
        },
        validateBudgetAmount: function validateBudgetAmount() {
            var budgetAmountControlId = this.get('budgetAmountControlId');
            var bidExpirationTypeControlId = this.get('bidExpirationTypeControlId');

            var budgetAmount = this.formatAmount(this.get('budgetAmount'));
            var minimumBudgetAmount = this.formatAmount(this.get('minimumBudgetAmount'));
            var maximumBudgetAmount = this.formatAmount(this.get('maximumBudgetAmount'));

            if (!budgetAmount) {
                this.addValidationError(budgetAmountControlId, 'We\'re sorry, but you need to enter a valid budget amount.');
            } else if (parseFloat(budgetAmount) < parseFloat(minimumBudgetAmount)) {
                this.addValidationError(budgetAmountControlId, 'Please enter a budget amount that\'s at least 3 times greater than your Maximum Bid.');
            } else if (parseFloat(budgetAmount) > parseFloat(maximumBudgetAmount)) {
                this.addValidationError(budgetAmountControlId, 'We\'re sorry, the maximum budget amount allowed is $' + maximumBudgetAmount + '.');
            } else {
                this.removeValidationError(budgetAmountControlId);
                this.removeValidationError(bidExpirationTypeControlId);
            }
        },
        formatAmount: function formatAmount(amount) {
            if (!amount || amount < 0.00) {
                return null;
            }

            return parseFloat(amount).toFixed(2);
        },
        getValidationError: function getValidationError(control) {
            var validationErrors = this.get('validationErrors');

            if (!validationErrors.hasOwnProperty(control)) {
                console.error('Validation is not mapped for control "' + control + '".');
                return;
            }

            return validationErrors.get(control);
        },
        addValidationError: function addValidationError(control, message) {
            var existingError = this.getValidationError(control);

            if (existingError) {
                this.removeValidationError(control);
            }

            this.set('validationErrors.' + control, message);
        },
        removeValidationError: function removeValidationError(control) {
            var existingError = this.getValidationError(control);

            if (!existingError) {
                return;
            }

            this.set('validationErrors.' + control, null);
        }
    });
});