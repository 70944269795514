define("ingenio-web/constants/activity-sub-type", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    Unknown: 0,
    /// <summary>
    /// Live Call made
    /// </summary>
    CallLiveMade: 1,
    /// <summary>
    /// Call made from callback
    /// </summary>
    CallBackMade: 2,
    /// <summary>
    /// Missed a call that was initiated as a buyer
    /// </summary>
    CallMissedMade: 3,
    /// <summary>
    /// Live Call made via featured listings
    /// </summary>
    CallLiveFeaturedListingMade: 4,
    /// <summary>
    /// Recorded call made
    /// </summary>
    CallRecordedMade: 5,
    /// <summary>
    /// Call back attempt
    /// </summary>
    CallBackAttempt: 6,
    /// <summary>
    /// Charge on payment method (Credit Card, Check, etc.)
    /// </summary>
    Charge: 7,
    /// <summary>
    /// Mail sent sold
    /// </summary>
    MailSentSold: 8,
    /// <summary>
    /// Payout received
    /// </summary>
    PayoutReceived: 9,
    /// <summary>
    /// Refund received
    /// </summary>
    Refund: 10,
    /// <summary>
    /// Earnings from expiry of assets
    /// </summary>
    ExpiredAssetEarnings: 11,
    /// <summary>
    /// General transactions
    /// </summary>
    GeneralTransaction: 12,
    /// <summary>
    /// Mail sent
    /// </summary>
    MailSent: 13,
    /// <summary>
    /// Mail received
    /// </summary>
    MailReceived: 14,
    /// <summary>
    /// Mail received sold
    /// </summary>
    MailReceivedSold: 15,
    /// <summary>
    /// Mail received bought
    /// </summary>
    MailReceivedBought: 16,
    /// <summary>
    /// Mail invitation sent with free minute offer
    /// </summary>
    MailInvitationSent: 17,
    /// <summary>
    /// Mail invitation received with free minute offer
    /// </summary>
    MailInvitationReceived: 18,
    /// <summary>
    /// Time asset transaction
    /// </summary>
    TimeAssetTransaction: 19,
    /// <summary>
    /// Mail tip sent
    /// </summary>
    MailTipSent: 20,
    /// <summary>
    /// Mail tip received
    /// </summary>
    MailTipReceived: 21,
    /// <summary>
    /// Call Attempted from Buyer to Seller. Seller Problem.
    /// </summary>
    CallMissedSellerProblem: 22,
    /// <summary>
    /// Seller Activity, applies for Pay to View, Payment Request
    /// </summary>
    MailFeaturedListingSentSold: 23,
    /// <summary>
    /// Buyer Activity, applies for Pay to View, Payment Request
    /// </summary>
    MailFeaturedListingReceivedBought: 24,
    /// <summary>
    /// Buyer Activity, applies for Send Payment
    /// </summary>
    MailFeaturedListingSentBought: 25,
    /// <summary>
    /// Seller Activity, applies for Send Payment
    /// </summary>
    MailFeaturedListingReceivedSold: 26,
    /// <summary>
    /// Buyer Activity, applies for Send Tip
    /// </summary>
    MailTipFeaturedListingSentBought: 27,
    /// <summary>
    /// Seller Activity, applies for Send Tip
    /// </summary>
    MailTipFeaturedListingReceivedSold: 28,
    /// <summary>
    /// Recorded Call made via featured listings
    /// </summary>
    CallRecordedFeaturedListingMade: 29,
    /// <summary>
    /// Call lead charge activity
    /// </summary>
    CallLeadCharge: 30,
    /// <summary>
    /// Click lead charge activity
    /// </summary>
    ClickLeadCharge: 31,
    /// <summary>
    /// Call made from callback via featured listings
    /// </summary>
    CallBackFeaturedListingMade: 33,
    /// <summary>
    /// Live call received
    /// </summary>
    CallLiveReceived: 101,
    /// <summary>
    /// Call received from callback
    /// </summary>
    CallBackReceived: 102,
    /// <summary>
    /// Missed a call that was initiated by another user
    /// </summary>
    CallMissedReceived: 103,
    /// <summary>
    /// Live Call received via featured listings
    /// </summary>
    CallLiveFeaturedListingReceived: 104,
    /// <summary>
    /// Recorded call received
    /// </summary>
    CallRecordedReceived: 105,
    /// <summary>
    /// Call received from callback via featured listings
    /// </summary>
    CallBackFeaturedListingReceived: 106,
    /// <summary>
    /// Call missed by seeker as part of appointment
    /// </summary>
    CallSeekerMissedAppointment: 107,
    /// <summary>
    /// Mail sent bought
    /// </summary>
    MailSentBought: 108,
    /// <summary>
    /// Call Attempted from Buyer to Seller. Buyer Problem.
    /// </summary>
    CallMissedBuyerProblem: 109,
    /// <summary>
    /// Recorded Call received via featured listings
    /// </summary>
    CallRecordedFeaturedListingReceived: 110,
    /// <summary>
    /// User details changed
    /// </summary>
    UserDetailsChanged: 150,
    /// <summary>
    /// Service details changed
    /// </summary>
    ServiceDetailsChanged: 151,
    /// <summary>
    /// Customer List details changed
    /// </summary>
    CustomerListDetailsChanged: 152,
    /// <summary>
    /// Customer List of customer changed
    /// </summary>
    CustomerListChanged: 153,
    /// <summary>
    /// Customer List deleted
    /// </summary>
    CustomerListDeleted: 154,
    /// <summary>
    /// Feedback rating for call
    /// </summary>
    CallFeedbackRating: 155,
    /// <summary>
    /// Feedback rating for mail
    /// </summary>
    MailFeedbackRating: 156,
    /// <summary>
    /// Domain details changed
    /// </summary>
    DomainDetailsChanged: 157,
    /// <summary>
    /// Context details changed
    /// </summary>
    ContextDetailsChanged: 158,
    /// <summary>
    /// Feedback deleted
    /// </summary>
    FeedbackDeleted: 159,
    /// <summary>
    /// Chat Received
    /// </summary>
    ChatReceived: 160,
    /// <summary>
    /// Feedback rating for chat
    /// </summary>
    ChatFeedbackRating: 161,
    /// <summary>
    /// Chat Declined
    /// </summary>
    ChatDeclined: 162,
    /// <summary>
    /// Chat received via featured listings
    /// </summary>
    ChatFeaturedListingReceived: 163,
    /// <summary>
    /// Chat made via featured listings
    /// </summary>
    ChatFeaturedListingMade: 164,
    /// <summary>
    /// Chat made
    /// </summary>
    ChatMade: 165,
    /// <summary>
    /// Chat failed
    /// </summary>
    ChatFailed: 166
  };
});