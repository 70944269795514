define('ingenio-web/mixins/transition-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParamKeyValuePairs: Ember.computed('queryParams', 'queryParams.@each', function () {
            return Ember.getProperties(this, this.get('queryParams'));
        }),

        transitionToWithCurrentParams: function transitionToWithCurrentParams(name) {
            this.transitionTo(name, {
                queryParams: this.get('queryParams')
            });
        },
        transitionToRouteWithCurrentParams: function transitionToRouteWithCurrentParams(name, params) {
            if (!params) params = {};
            this.transitionToRoute(name, {
                queryParams: Object.assign({}, this.get('queryParamKeyValuePairs'), params)
            });
        },
        transitionToUrlWithCurrentParams: function transitionToUrlWithCurrentParams(url, params) {
            if (!params) params = {};
            this.reloadWithQueryStringVars(url, Object.assign({}, this.get('queryParamKeyValuePairs'), params));
        },
        reloadWithQueryStringVars: function reloadWithQueryStringVars(url, queryStringVars) {
            var existingQueryVars = location.search ? location.search.substring(1).split("&") : [],
                newQueryVars = {},
                newUrl = url + "?";
            if (existingQueryVars.length > 0) {
                for (var i = 0; i < existingQueryVars.length; i++) {
                    var pair = existingQueryVars[i].split("=");
                    newQueryVars[pair[0]] = pair[1];
                }
            }
            if (queryStringVars) {
                for (var queryStringVar in queryStringVars) {
                    var val = queryStringVars[queryStringVar];
                    if (val) {
                        newQueryVars[queryStringVar] = queryStringVars[queryStringVar];
                    }
                }
            }
            if (newQueryVars) {
                for (var newQueryVar in newQueryVars) {
                    newUrl += newQueryVar + "=" + newQueryVars[newQueryVar] + "&";
                }
                newUrl = newUrl.substring(0, newUrl.length - 1);
                window.location.href = newUrl;
            } else {
                window.location.href = location.href;
            }
        }
    });
});