define('ingenio-web/controllers/direct-messages/conversation', ['exports', 'ingenio-web/objects/chat/message', 'ingenio-web/utils/date-utils'], function (exports, _message, _dateUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        messages: Ember.computed.union('model.conversation', 'newMessages'),
        newMessages: Ember.A(),
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        primaryPhone: function () {
            var userInfo = Ember.Application.user;
            var userPhones = this.get('model.userPhones');

            var primaryPhone = userPhones.findBy('isPrimary', true);

            if (!primaryPhone) {
                primaryPhone = this.store.createRecord('userPhone');
                primaryPhone.set('userId', userInfo.id);
            }
            return primaryPhone;
        }.property('model.userPhones'),

        listingUrl: function () {
            return this.get('model.listingDetails.detailsUrl');
        }.property('model.listingDetails'),

        customerDetailsUrl: function () {
            var custId = this.get('model.recipient.id');

            return '/app/#/myaccount/customers/' + custId;
        }.property('model.recipient'),

        selectedUserPhoneId: function () {
            var primaryPhone = this.get('primaryPhone');
            var selectedUserPhoneId = primaryPhone.get('id');

            return selectedUserPhoneId;
        }.property('primaryPhone'),

        smsEnabled: function () {
            var userPhones = this.get('model.userPhones');
            var smsEnabled = userPhones.any(function (p) {
                return p.get('isSmsNotificationEnabled') === true;
            });

            return smsEnabled;
        }.property('model.userPhones'),

        enteredTextObsever: function () {
            var enteredText = this.get('enteredText');
            var limitExceeded = enteredText && enteredText.length > 300;

            this.set('showMessageToLongError', limitExceeded);
        }.observes('enteredText'),

        setup: function () {
            this.set('newMessages', Ember.A());
            this.hideAllErrors();
        }.observes('model').on('init'),

        hideAllErrors: function hideAllErrors() {
            this.set('showMessageToLongError', false);
            this.set('showRecipientNonActiveMemberError', false);
            this.set('showMessageLimitError', false);
            this.set('showEligibilityError', false);
            this.set('showGenericError', false);
        },


        actions: {
            hideError: function hideError(errorType) {
                this.set(errorType, false);
            },
            hideMessage: function hideMessage(messageId) {
                var _this = this;

                var messages = this.get('messages');

                var message = messages.find(function (m) {
                    return m.id === messageId;
                });
                var index = messages.indexOf(message);

                var removeMessage = function removeMessage(m) {
                    _this.get('model.conversation').removeObject(m);
                    _this.get('newMessages').removeObject(m);
                };

                removeMessage(message);

                this.store.findRecord('directMessage', message.id, {
                    backgroundReload: false
                }).then(function (m) {
                    m.destroyRecord();
                });

                messages = this.get('messages');

                var sameDayMessages = messages.find(function (m) {
                    return (0, _dateUtils.sameDay)(m.date, message.date) && !m.dateMessage;
                });

                if (!sameDayMessages) {
                    var dateMessage = messages.objectAt(index - 1);

                    removeMessage(dateMessage);
                }
            },
            closeEnableSmsNotifications: function closeEnableSmsNotifications() {
                $('#smsNotificationDialog').modal('hide');
                this.set('smsEnabled', true);
                var enableSmsCallback = this.get('enableSmsCallback');

                if (enableSmsCallback) {
                    enableSmsCallback();
                    this.set('enableSmsCallback', null);
                }
            },
            closeAddPhoneDialog: function closeAddPhoneDialog() {
                $('#addPhoneNumberDialog').modal('hide');
            },
            closeSmsNotificationDialog: function closeSmsNotificationDialog() {
                $('#smsNotificationDialog').modal('hide');
            },
            messageSent: function messageSent(text) {
                var _this2 = this;

                this.hideAllErrors();
                if (!text) {
                    return;
                }

                if (text.length > 300) {
                    this.set('showMessageToLongError', true);
                    return new Error('ToLongError');
                }

                var sendMessagePromise = new Promise(function (resolve, reject) {

                    var sendMessage = function sendMessage() {
                        var message = _this2.store.createRecord('directMessage');

                        message.set('message', text);
                        message.set('fromUserId', _this2.model.user.id);
                        message.set('toUserId', _this2.model.recipient.get('id'));

                        message.save().then(function (m) {
                            var newMessage = _message.default.create({
                                id: m.get('id'),
                                text: text,
                                date: new Date(),
                                isMine: true
                            });

                            //if it is new message today we need add dateMessage
                            var messagesCount = _this2.get('messages.length');

                            if (messagesCount === 0 || messagesCount > 0 && !(0, _dateUtils.sameDay)(newMessage.date, _this2.get('messages.lastObject.date'))) {

                                var dateMessage = _message.default.create({
                                    dateMessage: true,
                                    date: newMessage.date
                                });

                                _this2.get('newMessages').pushObject(dateMessage);
                            }
                            _this2.get('newMessages').pushObject(newMessage);
                            resolve();
                        }).catch(function (error) {
                            var errorReason = null;

                            if (Ember.isArray(error.errors)) {
                                errorReason = error.errors.get('firstObject.detail');
                            }

                            if (errorReason === 'MessageCountLimitReached') {
                                _this2.set('showMessageLimitError', true);
                            } else if (errorReason === 'RecipientNonActiveMember') {
                                _this2.set('showRecipientNonActiveMemberError', true);
                            } else if (errorReason === 'InvalidRelationship') {
                                _this2.set('showEligibilityError', true);
                            } else {
                                _this2.set('showGenericError', true);
                            }

                            reject(errorReason);
                        });
                    };

                    if (_this2.get('model.userPhones.length') === 0) {
                        $('#addPhoneNumberDialog').modal('show');
                        return;
                    }

                    if (!_this2.get('smsEnabled')) {
                        $('#smsNotificationDialog').modal('show');
                        _this2.set('enableSmsCallback', sendMessage);
                        return;
                    }

                    sendMessage();
                });

                return sendMessagePromise;
            }
        }
    });
});