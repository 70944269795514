define('ingenio-web/adapters/sg-transaction', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            var userId = Ember.Application.user.id;

            return 'users/' + userId + '/transactions/satisfaction-guarantee';
        }

        // Use buildURL for local development with mock api
        // buildURL: function() {
        //     const userId = Ember.Application.user.id;

        //     return 'http://localhost:3008' + '/users/' + userId + '/transactions/satisfaction-guarantee';
        // }
    });
});