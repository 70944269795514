define('ingenio-web/components/arranged-conversations/customer/waitlist-item-card/component', ['exports', 'ingenio-web/utils/html-utils'], function (exports, _htmlUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    mixpanelClient: Ember.inject.service("mixpanel-client"),

    _showCancelConfirmationDialog: false,
    showCancelConfirmationDialog: Ember.computed.equal("_showCancelConfirmationDialog", true),

    cssTypeIndicator: Ember.computed("model.forConversationType", function () {
      var ctype = this.get("model.forConversationType").toString().toLowerCase();
      if (ctype == "chat") return "chat";
      return "phone";
    }),

    isCall: Ember.computed("model.forConversationType", function () {
      var ctype = this.get("model.forConversationType").toString().toLowerCase();
      return ctype == "call";
    }),

    waitTimeText: Ember.computed("model.requestedFor", function () {
      var availability = this.get("model.requestedFor.availability.callAvailability");
      var selectedStatus = availability.filter(function (item) {
        return item.isSelected;
      })[0];

      if (selectedStatus.type == "OFFLINE") {
        return "When advisor returns**";
      } else {
        var extraChar = "";
        var hoursDiff = moment.duration(new moment().diff(new moment(selectedStatus.selectedAsOf))).hours();
        if (hoursDiff >= 4) {
          extraChar = "*";
        }
        return "Approx. wait*" + extraChar;
      }
    }),

    advisorIsOfflineForConversationType: Ember.computed("model.forConversationType", "model.requestedFor.availability.callAvailability", "model.requestedFor.availability.chatAvailability", function () {
      var type = this.get("model.forConversationType");
      var callAvailabilities = this.get("model.requestedFor.availability.callAvailability") || [];
      var chatAvailability = this.get("model.requestedFor.availability.chatAvailability");
      console.log('Chat Availability: ', chatAvailability, 'Call Availability: ', callAvailabilities);

      if (type === 'CHAT') {
        if (chatAvailability === "UNAVAILABLE") {
          return true;
        }
      } else {
        var isOfflineForCall = callAvailabilities.findBy('type', 'OFFLINE').isSelected || false;
        if (isOfflineForCall) {
          return true;
        }
      }
      return false;
    }),

    isChatConversation: Ember.computed.equal('model.forConversationType', 'CHAT'),

    userIsFirstInLine: Ember.computed("model.queuePosition", function () {
      return this.get('model.queuePosition') === 1;
    }),

    waitTimeTextMultiline: Ember.computed("model.requestedFor", function () {
      var availability = this.get("model.requestedFor.availability.callAvailability");
      var selectedStatus = availability.filter(function (item) {
        return item.isSelected;
      })[0];

      if (selectedStatus.type == "OFFLINE") {
        return true;
      }

      return false;
    }),

    profilePictureUrl: Ember.computed("model.onListing.picture.default.uri", function () {
      var pictureUrl = this.get("model.onListing.picture.default.uri");
      if (pictureUrl == null) {
        return "https://si.keen.com/D1_default-advisor-img_V1.png";
      }

      return pictureUrl;
    }),

    categoryNameUrlPart: Ember.computed("model.onListing.category.name", function () {
      var value = this.get("model.onListing.category.name");
      if (!value) {
        value = "";
      }
      return value.replace(/[\W_]+/g, "-").toLowerCase();
    }),

    advisorNameUrlPart: Ember.computed("model.requestedFor.userName", function () {
      var value = this.get("model.requestedFor.userName");
      if (!value) {
        value = "";
      }
      return value.replace(/[\W_]+/g, "-").toLowerCase();
    }),

    expiresAtText: Ember.computed("model.expiresAt", function () {
      var diff = new moment(this.get("model.expiresAt")).diff(new moment());
      var expiresAtDays = 0;
      var expiresAtHours = 0;
      var expiresAtMinutes = 1;
      var expiresAtText = "";

      if (diff > 0) {
        expiresAtDays = moment.duration(new moment(this.get("model.expiresAt")).diff(new moment())).days();
        expiresAtHours = moment.duration(new moment(this.get("model.expiresAt")).diff(new moment())).hours();
        expiresAtMinutes = moment.duration(new moment(this.get("model.expiresAt")).diff(new moment())).minutes();
      } else {
        return "Now";
      }

      function pluralize(text, value, ending) {
        if (value == 1) {
          text += value.toString() + ending;
        } else if (value > 1) {
          text += value.toString() + ending + "s";
        }

        return text + " ";
      }

      expiresAtText = pluralize(expiresAtText, expiresAtDays, "d");
      expiresAtText = pluralize(expiresAtText, expiresAtHours, "hr");
      expiresAtText = pluralize(expiresAtText, expiresAtMinutes, "min");

      expiresAtText = expiresAtText.trim();
      if (expiresAtText == "") {
        return "Now";
      }

      return expiresAtText;
    }),

    actions: {
      confirmCancelItem: function confirmCancelItem() {
        this.set("_showCancelConfirmationDialog", true);
        this.sendAction("stopUpdates");
      },
      closeDialog: function closeDialog() {
        this.set("_showCancelConfirmationDialog", false);
        this.sendAction("startUpdates");
      },
      cancelItem: function cancelItem() {
        var that = this;
        var id = this.get("model.id");
        Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql', {
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            operationName: null,
            query: "mutation ($callbackId:ID!){ cancelAdvisorConversationRequest(id: $callbackId)}",
            variables: {
              callbackId: id
            }
          })
        }).then(function (result) {
          that.set("_showCancelConfirmationDialog", false);
          that.sendAction("startUpdates");
          that.sendAction("refresh");
        });
      },
      refreshList: function refreshList() {
        this.sendAction("refresh");
      },
      updateCallbackTime: function updateCallbackTime() {
        var listingId = this.get('model.onListing.id');
        var route = 'arrange-conversation.expiration-extension';

        this.get('router').transitionTo(route, {
          queryParams: { listingid: listingId }
        });
      },
      sendPing: function sendPing() {
        var advisorId = this.get('model.requestedFor.id');
        if (window.location.hostname === 'localhost') {
          window.location.href = '/app/#/direct-messages/recipient/' + advisorId;
        } else {
          window.location.href = '/app/#/direct-messages/recipient/' + advisorId;
        }
      },
      sendEmail: function sendEmail() {
        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.requestedFor.userName'),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      }
    }
  });
});