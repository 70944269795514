define('ingenio-web/models/chat-session-extension', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        sessionId: (0, _attr.default)('number'),
        amount: (0, _attr.default)('number'),
        minutes: (0, _attr.default)('number'),
        extensionType: (0, _attr.default)('string'),
        availableTimeSeconds: (0, _attr.default)('number'),
        remainingDuration: fragment('remainingDuration'),
        eventSource: (0, _attr.default)('string')
    });
});