define('ingenio-web/components/privacy-policy-link/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        link: Ember.computed('type', function () {
            var isKeen = Ember.ENV.domainId === 1;

            return isKeen ? 'https://help.keen.com/hc/en-us/articles/1500000300002-Ingenio-Privacy-Policy' : '';
        })
    });
});