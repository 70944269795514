define('ingenio-web/components/deactivate-account-customer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var domainName = 'Keen';
    var keenPrivacyPolicyLink = 'https://help.keen.com/hc/en-us/articles/1500000300002-Ingenio-Privacy-Policy';
    var keenSGLink = 'https://help.keen.com/hc/en-us/articles/1500000300362-Keen-Satisfaction-Guarantee';
    var keenPersonalInfoLink = '/MyAccount/UpdatePersonalInfoStarter.aspx';
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var user = Ember.Application.user;
            this.set('user', user);
            this.get('store').findRecord('balance', user.id, {
                adapterOptions: {}
            }).then(function (balance) {
                return _this.set("balance", Number(balance.get('monetary')).toFixed(2));
            });
        },


        user: null,
        balance: null,
        selectedReason: null,
        displayCloseAccountConfirmationModal: false,
        displayCloseAccountSuccessModal: false,
        closeAccountStatus: null,
        reasons: [{ id: 1, text: domainName + ' is too expensive for me.', help: "Our highly skilled advisors have rates that support almost any budget, with some starting as low as $1.99/min. The lower price options coupled with the ability to purchase as little as $10 helps to ensure you can get the advice you need when you need it, even if just for a short chat." }, {
            id: 2, text: "I don't want to be charged anymore.", help: domainName + ' is not a subscription service and does not charge any membership fees so there is no need for you to deactivate your account. If you do not wish to be charged, you simply do not need to use your account as ' + domainName + ' is a pay as you go service.'
        }, {
            id: 3, text: "I am receiving too many emails.", help: 'If you are receiving too many emails from ' + domainName + ' and wish to unsubscribe from certain or all emails, simply visit the <a href=\'' + keenPersonalInfoLink + '\' target=\'_blank\'>Personal Info</a> page and update your notification settings.'
        }, {
            id: 4, text: "I am concerned about my privacy.", help: 'Please note that your privacy is protected on ' + domainName + '. Visit <a href=\'' + keenPrivacyPolicyLink + '\' target=\'_blank\'>Ingenio\'s Private Policy</a> page for more information.'
        }, {
            id: 5, text: 'I had a negative experience on ' + domainName + '.', help: 'We\'re sorry that you had an unpleasant experience. Please note that you may be eligible for receive a Satisfaction Guarantee for this reading. Simply visit the <a href=\'' + keenSGLink + '\' target=\'_blank\'>Satisfaction Guarantee page</a> for more information.'
        }, {
            id: 6, text: 'I just want to take a break from ' + domainName + '.', help: 'Please note that ' + domainName + ' is not a subscription service and does not charge any membership fees so there is no need for you to deactivate your account. If you do not wish to be charged, you simply do not need to use your account as ' + domainName + ' is a pay as you go service.'
        }, {
            id: 7, text: 'I will be getting my psychic readings elsewhere.', help: ''
        }],

        logoutUser: function logoutUser() {
            var url = '/login/logout';
            Ember.$.post(url, {}).done(function (data) {
                console.log('Logged out successfully!');
            }).fail(function (reason) {
                console.log('Error logging out user: ', reason);
                //navigate the user to the home page.
                window.location.href = '/';
            });
        },


        actions: {
            reasonChanged: function reasonChanged(reason) {
                this.set('selectedReason', reason);
            },
            confirmCloseAccount: function confirmCloseAccount() {
                this.set('displayCloseAccountConfirmationModal', true);
            },
            cancel: function cancel() {
                this.set('displayCloseAccountConfirmationModal', false);
            },
            closeAccount: function closeAccount() {
                var _this2 = this;

                var userId = this.get('user.id');
                var C_RequestedByCustomer = 6;
                var url = Ember.ENV.getApiRoot() + '/users/' + userId + '/account-closure';
                var data = {
                    userId: userId,
                    userAccountClosureType: C_RequestedByCustomer,
                    comments: this.get("selectedReason.text")
                };
                Ember.$.post(url, data).done(function (data) {
                    if (data.accountClosed) {
                        _this2.set('closeAccountStatus', 'success');
                        _this2.logoutUser();
                    } else {
                        // Account close failure.
                        console.log('Error closing account!');
                        _this2.set('closeAccountStatus', 'failure');
                        _this2.get('loggerService').error('Error closing account', {}, reason);
                    }
                }).fail(function (reason) {
                    console.log('Error closing account: ', reason);
                    _this2.set('closeAccountStatus', 'failure');
                    _this2.get('loggerService').error('Error closing account', {}, reason);
                });
            }
        }
    });
});