define('ingenio-web/initializers/logger', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'logger',
        initialize: function initialize(application) {
            // Inject the logger service to all components, controllers and routes as 'loggerService'.
            // Note: The name of the injected property should be ideally 'logger' instead of 'loggerService'.
            // Since this property is injected app-wide and we are still using the logger util in many parts of the application, we don't want the name to cause any issues due to name conflict. Hence 'loggerService' is used.
            application.inject('controller', 'loggerService', 'service:logger');
            application.inject('component', 'loggerService', 'service:logger');
            application.inject('route', 'loggerService', 'service:logger');
        }
    };
});