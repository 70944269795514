define('ingenio-web/adapters/credit-card-token', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL() {
            return Ember.ENV.urls.ccap + 'TokenManager.aspx';
        },
        queryRecord: function queryRecord(store, type, query) {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                $.ajax({
                    url: _this.buildURL(),
                    jsonp: 'callbacksuccess',
                    dataType: 'jsonp',
                    data: {
                        accountnumber: query.filter.accountNumber,
                        creditcardtype: query.filter.creditCardType.toUpperCase()
                    },
                    success: function success(payload) {
                        var token = payload.TokenId;
                        var apiError = _this.getErrorMessageIfTokenInvalid(token);

                        if (apiError) {
                            reject([{
                                "errors": {
                                    "detail": apiError
                                }
                            }]);
                        }

                        resolve({
                            creditCardToken: {
                                id: 1, // dummy id to satisfy ember data
                                tokenId: token,
                                cardType: payload.CredCardType,
                                cardLastFourDigits: payload.LastFour
                            }
                        });
                    },
                    error: function error(jqXHR) {
                        return reject(jqXHR);
                    }
                });
            });
        },
        getErrorMessageIfTokenInvalid: function getErrorMessageIfTokenInvalid(token) {
            switch (token) {
                case '':
                    return 'Invalid token from web service.';

                case '-1':
                    return 'The credit card number is invalid.';

                case '-2':
                    return 'A system error occurred when creating the credit card token.';

                default:
                    return null;
            }
        }
    });
});