define('ingenio-web/components/chat/engagement-text', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var keenSentences = ['Take a moment to collect your thoughts and think of questions you want to ask your Advisor.', 'Ask direct, clear questions for a better reading.', 'The future has many possibilities. Your Advisor can help you explore them all.'];

    var pcSentences = ['TIP: Write down questions for your psychic in advance.', 'TIP: Sharing your first name and birthday helps your psychic connect to your energy', 'TIP: Remember, the future is always in your hands.', 'TIP: Make sure to "favorite" your go-to psychics.', 'TIP: Take a deep breath and connect to your inner truth.', 'TIP: Bring positivity into the chat as it will effect the energy of the reading.', 'TIP: Meditating before or after readings can help you absorb your psychic\'s advice', 'TIP: Ask open-ended questions so your psychic can paint a full picture.', 'TIP: Reflect on your psychic\'s guidance by reading your chat transcripts.', 'TIP: Visit our Psychic Wiki to learn more about all things psychics.', 'TIP: Learn more psychic terms by visiting our Psychic Glossary.', 'TIP: Ask your psychic about your spirit guides.', 'TIP: Ask your psychic about anything, such as love, career, and more.', 'TIP: Try different reading styles, such as tarot, mediumship, or astrology.', 'TIP: Get readings regularly to deepen your relationship with your psychic.', 'TIP: Maintain your inner balance with spiritual guidance from a psychic.', 'TIP: In times of uncertainty, psychics can help bring reassurance.'];

    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            if (Ember.ENV.domainName === 'Keen') {
                this.sentences = keenSentences;
                this.displayTipTitle = true;
            } else {
                this.sentences = pcSentences;
            }
        },


        sentences: null,
        displayContent: '',
        isFirstTip: true,
        isLastTip: false,
        tipSequence: 0,
        showPreviousTip: null,
        showNextTip: null,
        timer: null,
        displayTipTitle: false,

        updateTip: function updateTip(self, startSequence) {
            var tipSequence = startSequence != undefined ? startSequence : self.tipSequence;
            var sentences = self.get('sentences');
            var totalTips = sentences.length;

            if (tipSequence == totalTips) {
                return;
            } else {
                tipSequence = tipSequence + 1;
                self.set('tipSequence', tipSequence);
            }

            var text = sentences[tipSequence - 1];
            var html = '';
            if (Ember.ENV.domainName === 'Keen') {
                html = text;
            } else {
                html = text.replace('TIP:', '<span class="bold">Tip:</span>').replace('REM:', '<span class="bold">Remember:</span>').replace('DYK:', '<span class="bold">Did You Know:</span>').replace('NOTE:', '<span class="bold">Note:</span>');
            }

            self.set('isFirstTip', tipSequence == 1);
            self.set('isLastTip', tipSequence == totalTips);

            self.set('displayContent', '<div class="fadeInRight animated tip-wrapper"><div class="tip-content">' + html + '</div></div>');
            self.set('displayTipTitle', this.displayTipTitle);
            self.set('totalTipCount', totalTips);
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            self.updateTip(this, 0); //Setting first instantly
            var timer = setInterval(function () {
                self.updateTip(self);
            }, 5000); // rotate text every 5 seconds
            self.set('timer', timer);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            if (this.get('timer')) {
                clearInterval(this.get('timer'));
            }
        },


        actions: {
            showPreviousTip: function showPreviousTip() {
                clearInterval(this.get('timer'));
                var tipSequence = this.tipSequence;
                tipSequence = tipSequence - 2; // +1 will be done in the updateTip method, so will be showing tipSequence - 1 
                this.updateTip(this, tipSequence);
                var self = this;
                var timer = setInterval(function () {
                    self.updateTip(self);
                }, 5000);
                this.set('timer', timer);
            },
            showNextTip: function showNextTip() {
                clearInterval(this.get('timer'));
                this.updateTip(this); // +1 will be done in the updateTip method
                var self = this;
                var timer = setInterval(function () {
                    self.updateTip(self);
                }, 5000);
                this.set('timer', timer);
            }
        }
    });
});