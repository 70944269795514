define('ingenio-web/services/logger', ['exports', '@sentry/browser', 'ingenio-web/utils/logger'], function (exports, _browser, _logger) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.logEntries = []; //Adapted from the current logger util class. Collects a list of messages for debugging. Used to generate aggregated log like 'chatMonitorLog'
        },


        store: Ember.inject.service(),

        /**
         * Captures and sends the message to the backend and/or sentry based on the configuration.
         * @param {object} captureInfo - Information to capture.
         * @param {string} captureInfo.message - A meaningful description/title of what happened.
         * @param {string} captureInfo.level - The 'level' of the message. Can be 'fatal, error, warning, info or debug' based on severity.
         * @param {object} captureInfo.errorObject - An exception-like object.
         * @param {object} captureInfo.data - Extra information to log. Will be sent as extras for Sentry and to define properties of the logEntry object.
         * @param {object} captureInfo.tags - A set of key:value strings that will be passed as tags data to sentry for this message entry.
         * @param {boolean} [captureInfo.sentry=true] - If false, will not send a message to Sentry.
         * @param {boolean} [captureInfo.createLogEntry=true] - If true, will create a 'logEntry' record in ember store using the properties defined in 'data'.
         * @returns {void} void
         */
        capture: function capture(_ref) {
            var message = _ref.message,
                _ref$level = _ref.level,
                level = _ref$level === undefined ? 'info' : _ref$level,
                _ref$errorObject = _ref.errorObject,
                errorObject = _ref$errorObject === undefined ? null : _ref$errorObject,
                _ref$data = _ref.data,
                data = _ref$data === undefined ? {} : _ref$data,
                _ref$tagsData = _ref.tagsData,
                tagsData = _ref$tagsData === undefined ? {} : _ref$tagsData,
                _ref$sentry = _ref.sentry,
                sentry = _ref$sentry === undefined ? true : _ref$sentry,
                _ref$createLogEntry = _ref.createLogEntry,
                createLogEntry = _ref$createLogEntry === undefined ? false : _ref$createLogEntry;

            var sentryConfig = Ember.ENV['@sentry/ember'];

            // Log to sentry only if sentry logging is enabled for the environment.
            if (sentryConfig.enabled && sentry) {

                try {
                    _browser.withScope(function (scope) {
                        // Set extra information from data
                        Object.keys(data).forEach(function (key) {
                            scope.setExtra(key, data[key]);
                        });

                        // Set tags data if any
                        Object.keys(tagsData).forEach(function (key) {
                            scope.setTag(key, tagsData[key]);
                        });
                        scope.setTag('manual_capture', true); // extra tag to identify that this message is captured manually (and NOT automatically by Sentry's script).

                        if (level === 'error' && errorObject instanceof Error) {
                            _browser.captureException(errorObject);
                        } else {
                            _browser.captureMessage(message, level);
                        }
                    });
                } catch (error) {
                    console.error('An error occurred while logging event to sentry!', error);
                }
            }

            var isoDateString = new Date().toISOString();
            var logEntryMessage = isoDateString + ' - ' + message;

            (0, _logger.LogToSplunk)('logger.capture: ' + message, level, data, this.get('store'));

            //capture this message
            this.logEntries.push(logEntryMessage);
        },


        /**
         * Creates a logEntry based on the given message (and sends a message to Sentry when sentry is set to true)
         * @param {string} message - A meaningful description/title of what happened.
         * @param {object} data - Extra information to log. Will be sent as extras for Sentry and to define properties of the logEntry object.
         * @param {boolean} [sentry=false] - If true, will send a message to Sentry with type 'info'.
         * @returns {void} void
         */
        log: function log(message) {
            var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var sentry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            return this.capture({
                message: message,
                data: data,
                sentry: sentry,
                createLogEntry: this.createLogEntry
            });
        },


        /**
         *
         * @param {string} message - A meaningful description/title of what happened.
         * @param {object} data - Extra information to log. Will be sent as extras for Sentry and to define properties of the logEntry object.
         * @param {object} errorObject -  An exception-like object.
         * @returns {void} void
         */
        error: function error(message) {
            var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var errorObject = arguments[2];

            (0, _logger.LogToSplunk)('EmberError_logger.error: ' + message, 'Error', data);

            return this.capture({
                level: 'error',
                message: message,
                data: data,
                errorObject: errorObject
            });
        },
        hasLogEntries: function hasLogEntries() {
            return this.logEntries.length > 0;
        },
        getAndClearLogEntries: function getAndClearLogEntries() {
            var logEntries = this.logEntries;

            this.logEntries = [];
            return logEntries;
        }
    });
});