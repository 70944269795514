define('ingenio-web/services/checkout-risk', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var checkoutPublicKey = Ember.ENV.checkoutPublicKey;
      var risk = window.Risk.init(checkoutPublicKey);

      risk.publishRiskData().then(function (deviceSessionId) {
        console.log('Device Session ID:', deviceSessionId); // Log the resolved value
        window.Risk.deviceSessionId = deviceSessionId || 'Empty deviceSessionId';
      }).catch(function (error) {
        console.error('Error in publishRiskData:', error); // Log any errors
      });
    },
    getDeviceId: function getDeviceId() {
      return window.Risk.deviceSessionId;
    },


    // a change in this date will trigger re-initialization of frames in the observed component.
    framesReinitializedAt: null,

    reinitializeFrames: function reinitializeFrames() {
      this.set('framesReinitializedAt', Date.now().toString());
    }
  });
});