define('ingenio-web/components/checkout-flow/order-summary/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['checkout-order-summary-return-container'],

        routing: Ember.inject.service('-routing'),

        amountToChargeRounded: Ember.computed('amountToCharge', function () {
            var amountToCharge = this.get('amountToCharge');

            if (!amountToCharge || isNaN(amountToCharge)) {
                return '0.00';
            }

            return Number(amountToCharge).toFixed(2);
        }),

        balanceAmountRounded: Ember.computed('balanceAmount', function () {
            if (!this.get('balanceAmount')) {
                return '0.00';
            }

            return this.get('balanceAmount').toFixed(2);
        }),

        timeLabel: Ember.computed('routing.currentRouteName', function () {
            var currentRouteName = this.get('routing.currentRouteName');

            return currentRouteName.startsWith('chat') ? 'Chat Time' : 'Talk Time';
        }),

        offerQualifierAmountRounded: Ember.computed('offerQualifierAmount', function () {
            return this.get('offerQualifierAmount') ? Number(this.getOfferDisplayAmount()).toFixed(2) : '0.00';
        }),

        totalAmountDue: Ember.computed('offerQualifierAmountRounded', 'amountToChargeRounded', 'fees', function () {
            var offerQualifierAmount = this.get('offerQualifierAmountRounded');
            var chargeAmount = this.get('amountToChargeRounded');

            var totalAmount = 0.00;

            if (offerQualifierAmount) {
                totalAmount += Number(offerQualifierAmount);
            }

            if (chargeAmount) {
                totalAmount += Number(chargeAmount);
            }
            // const fees = this.get('fees.fees.charges').reduce((total, fee)=>total+fee.amount, 0);
            // totalAmount += fees;
            return totalAmount.toFixed(2);
        }),

        showSelectedConversationPaymentMethod: Ember.computed('finalStep', 'payAfterConversationSelected', 'quickStartFlow', function () {
            var finalStep = this.get('finalStep');
            var quickStartFlow = this.get('quickStartFlow');
            var payAfterConversationSelected = this.get('payAfterConversationSelected');

            if (quickStartFlow) {
                return false;
            }

            if (payAfterConversationSelected) {
                return true;
            }

            return finalStep;
        }),

        showChangeLinkExtended: Ember.computed('showChangeLink', 'isUserEligibleForConversationPostPay', 'conversationPaymentMethodSelected', function () {
            var showChangeLink = this.get('showChangeLink');
            var isUserEligibleForConversationPostPay = this.get('isUserEligibleForConversationPostPay');
            var conversationPaymentMethodSelected = this.get('conversationPaymentMethodSelected');

            if (!showChangeLink) {
                return false;
            }

            return !isUserEligibleForConversationPostPay || !conversationPaymentMethodSelected;
        }),

        hideControlForMobile: Ember.computed('isUserEligibleForConversationPostPay', 'conversationPaymentMethodSelected', 'finalStep', function () {
            var isUserEligibleForConversationPostPay = this.get('isUserEligibleForConversationPostPay');
            var conversationPaymentMethodSelected = this.get('conversationPaymentMethodSelected');
            var finalStep = this.get('finalStep');

            return isUserEligibleForConversationPostPay && !conversationPaymentMethodSelected && !finalStep;
        }),

        preferredCurrency: Ember.computed('Ember.Application.user.preferredCurrency', function () {
            return Ember.Application.user.preferredCurrency;
        }),

        //TODO : temporary fix for international currency test
        //Should be removed once international currency is fully supported. 
        getOfferDisplayAmount: function getOfferDisplayAmount() {
            var currency = this.get('preferredCurrency');
            var offerQualifierAmount = this.get('offerQualifierAmount');
            var offerDesc = this.get('offerDescription');
            if (currency != "$" && offerDesc) {
                var offerDisplayAmount = offerDesc.split(currency).pop();
                if (!isNaN(offerDisplayAmount)) {
                    return offerDisplayAmount;
                }
            }
            return offerQualifierAmount;
        },

        displayAdditionalFees: Ember.computed('conversationPaymentMethodSelected', 'hideTalkTimeAndToBeCharged', 'payAfterConversationSelected', function () {
            return this.get('conversationPaymentMethodSelected') || !this.get('hideTalkTimeAndToBeCharged') && !this.get('payAfterConversationSelected');
        })
    });
});