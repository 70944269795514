define('ingenio-web/controllers/myaccount/feedback', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notifications: Ember.inject.service('toast'),
        busy: Ember.inject.service('busy'),
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        showRating: function () {
            var listing = this.get('selectedListing');
            if (listing) {
                return listing.get('starRating') > 0;
            }
            return false;
        }.property('model.filters.selectedListingId'),

        selectedListing: function () {
            var listingId = this.get('model.filters.selectedListingId');
            return this.get('model.filters.listings').find(function (item) {
                return item.id === listingId;
            });
        }.property('model.filters.selectedListingId'),

        actions: {
            gridGoPreviousPage: function gridGoPreviousPage() {},
            gridGoNextPage: function gridGoNextPage() {}
        }
    });
});