define('ingenio-web/components/picture-upload/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _Ember$Component$exte;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend((_Ember$Component$exte = {
        store: Ember.inject.service(),
        classNames: ['picture-upload-container'],

        defaultImageUrl: './images/profile-placeholder.svg',
        file: null,
        cropper: null,
        croppedData: null,
        rendering: false,
        allowDelete: true,
        loadingImage: false,
        pictureList: null,
        selectedPictureId: 0,

        picture: null
    }, _defineProperty(_Ember$Component$exte, 'pictureList', null), _defineProperty(_Ember$Component$exte, 'pictureObserver', Ember.observer('picture', function () {
        // this fixes an issue where a user selects an picture, closes the modal,
        // and then reattempts to upload the same picture.
        if (!this.get('picture')) {
            this.$('.file-picture').val('');
        }
    })), _defineProperty(_Ember$Component$exte, 'errorObserver', Ember.observer('errors.[]', function () {
        if (this.get('errors.length') > 0) {
            this.get('pictureModal').open();
        }
    })), _defineProperty(_Ember$Component$exte, 'pictureListWithValidAspectRatios', Ember.computed('pictureList.@each', function () {
        if (Ember.isEmpty(this.get('pictureList'))) {
            return null;
        }

        // currently, some advisors have pictures that reside from before the new cropper
        // tool was introduced which enforces a specific aspect ratio. to ensure only 
        // pictures conforming to the expected ratio are chosen, we will filter off any
        // which do not conform and indicate to the user they were excluded from the select list.
        return this.get('pictureList').filter(function (picture) {
            var calculatedWidth = picture.get('primaryVersion.width') * Ember.ENV.listings.edit.photo.aspectRatio;

            return picture.get('primaryVersion.height') === calculatedWidth;
        });
    })), _defineProperty(_Ember$Component$exte, 'countOfPicturesWithInvalidAspectRatio', Ember.computed('pictureList.@each', 'pictureListWithValidAspectRatios.@each', function () {
        return this.get('pictureList.length') - this.get('pictureListWithValidAspectRatios.length');
    })), _defineProperty(_Ember$Component$exte, 'isPictureSelectedForUpload', Ember.computed('picture', function () {
        return this.get('picture') != null;
    })), _defineProperty(_Ember$Component$exte, 'hasError', Ember.computed('errors.[]', function () {
        return this.get('errors.length') > 0;
    })), _defineProperty(_Ember$Component$exte, 'shouldShowCropTool', Ember.computed('hasError', 'isPictureSelectedForUpload', function () {
        return this.get('isPictureSelectedForUpload') && !this.get('hasError');
    })), _defineProperty(_Ember$Component$exte, 'imageUrl', Ember.computed('imageSource', 'defaultImageUrl', function () {
        return this.get('imageSource.length') > 0 ? this.get('imageSource') : this.get('defaultImageUrl');
    })), _defineProperty(_Ember$Component$exte, 'imageSelected', Ember.computed('imageUrl', function () {
        return !Ember.isEqual(this.get('imageUrl'), this.get('defaultImageUrl'));
    })), _defineProperty(_Ember$Component$exte, 'deleteButtonVisible', Ember.computed('allowDelete', 'imageSelected', function () {
        return this.get('imageSelected') && this.get('allowDelete');
    })), _defineProperty(_Ember$Component$exte, 'showPictureList', Ember.computed('pictureList', function () {
        return !Ember.isEmpty(this.get('pictureList'));
    })), _defineProperty(_Ember$Component$exte, 'didInsertElement', function didInsertElement() {
        this._super.apply(this, arguments);

        this.bindPictureModalEvents();
    }), _defineProperty(_Ember$Component$exte, 'bindPictureModalEvents', function bindPictureModalEvents() {
        var _this = this;

        var element = this.$('.picture-upload-modal');

        this.set('pictureModal', {
            open: function open(callback) {
                element.modal('show');
                element.on('shown.bs.modal', callback);
            },

            close: function close(callback) {
                element.modal('hide');
                element.on('hidden.bs.modal', callback);
            }
        });

        element.on('hidden.bs.modal', function () {
            return _this.set('picture', null);
        });
    }), _defineProperty(_Ember$Component$exte, 'createFormDataForNewPicture', function createFormDataForNewPicture() {
        var picture = this.get('picture');
        var croppedData = this.get('croppedData');
        var formData = new FormData();

        formData.append('picture', picture, picture.name);
        // Currently we can't do filters on the server so we send image as is.
        if (croppedData) {
            formData.append('x', croppedData.x);
            formData.append('y', croppedData.y);
            formData.append('width', croppedData.width);
            formData.append('height', croppedData.height);
            formData.append('rotate', 0);
            formData.append('scaleX', croppedData.scaleX);
            formData.append('scaleY', croppedData.scaleY);
        }

        return formData;
    }), _defineProperty(_Ember$Component$exte, 'actions', {
        onDeleteButtonClicked: function onDeleteButtonClicked() {
            this.attrs.onPictureDeleted();
        },
        chooseButtonClicked: function chooseButtonClicked() {
            var _this2 = this;

            this.get('errors').clear();

            // fetch existing pictures during the modal open event.
            this.get('store').findAll('picture', { reload: true }).then(function (pictures) {
                _this2.set('pictureList', pictures);
            });

            this.get('pictureModal').open();
        },
        pictureChanged: function pictureChanged(event) {
            this.get('errors').clear();
            this.set('picture', event.target.files[0]);
        },
        doneButtonClicked: function doneButtonClicked() {
            var _this3 = this;

            if (this.get('isPictureSelectedForUpload')) {
                //Save the picture by raising event 
                this.attrs.onImageChosen(this.createFormDataForNewPicture());
                this.set('selectedPictureId', 0);
            } else if (this.get('selectedPictureId')) {
                if (this.attrs.onPictureSelected) {
                    var picture = this.get('pictureList').find(function (pic) {
                        return pic.id === _this3.get('selectedPictureId');
                    });
                    if (picture) {
                        this.attrs.onPictureSelected(picture);
                    }
                }
            }
            window.dialogOpened = false;
            this.get('pictureModal').close();
        },
        onPictureSelected: function onPictureSelected(pictureId) {
            this.set('selectedPictureId', pictureId);
        },
        onPictureCropped: function onPictureCropped(croppedData) {
            this.set('croppedData', croppedData);
        }
    }), _Ember$Component$exte));
});