define("ingenio-web/components/arranged-conversations/customer/appointment-card/component", ["exports", "ingenio-web/utils/html-utils", "ingenio-web/constants/mail-mode-type", "ingenio-web/mixins/transition-helpers"], function (exports, _htmlUtils, _mailModeType, _transitionHelpers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_transitionHelpers.default, {
    mixpanelClient: Ember.inject.service("mixpanel-client"),

    _showCancelConfirmationDialog: false,
    showCancelConfirmationDialog: Ember.computed.equal("_showCancelConfirmationDialog", true),

    cssTypeIndicator: Ember.computed("model.type", "model.apptTypeID", function () {
      var apptTypeID = this.get("model.apptTypeID");
      var isICAppointment = apptTypeID === 3;
      if (isICAppointment) return "video";
      var ctype = this.get("model.type");
      if (ctype == "chat") return "chat";
      return "phone";
    }),

    startDateWeekText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("dddd");
    }),

    startDateMonthText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("MMMM Do");
    }),

    startTimeText: Ember.computed("model.scheduledStart", function () {
      var scheduledStart = moment(this.get("model.scheduledStart"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledStart.format("h:mma");
    }),

    endTimeText: Ember.computed("model.scheduledEnd", function () {
      var scheduledEnd = moment(this.get("model.scheduledEnd"), "YYYY-MM-DDTHH:mm:ss");
      return scheduledEnd.format("h:mma");
    }),

    statusText: Ember.computed("model.status", function () {
      return this.get("model.status").toString().replace(/_/g, " ").toLowerCase().capitalize();
    }),

    profilePictureUrl: Ember.computed("model.listing.picture.default.uri", function () {
      var pictureUrl = this.get("model.listing.picture.default.uri");
      if (pictureUrl == null) {
        return "https://si.keen.com/D1_default-advisor-img_V1.png";
      }

      return pictureUrl;
    }),

    categoryNameUrlPart: Ember.computed("model.listing.category.name", function () {
      var value = this.get("model.listing.category.name");
      if (!value) {
        value = "";
      }
      return value.replace(/[\W_]+/g, "-").toLowerCase();
    }),

    advisorNameUrlPart: Ember.computed("model.listing.advisor.userName", function () {
      var value = this.get("model.listing.advisor.userName");
      if (!value) {
        value = "";
      }
      return value.replace(/[\W_]+/g, "-").toLowerCase();
    }),

    showViewCustomerNote: Ember.computed("model.note", function () {
      var note = this.get("model.note");
      return note && note.id;
    }),

    nextJSRoot: Ember.ENV.urls.nextJSRoot,

    displayJoinSessionButton: Ember.computed("model.status", "model.apptTypeID", "model.videoSessionId", function () {
      var status = this.get("model.status");
      var apptTypeID = this.get("model.apptTypeID");
      var videoSessionId = this.get("model.videoSessionId") || 0;
      var isICAppointment = apptTypeID === 3; // Display only for IC Appointments
      var isVideoSessionCreated = videoSessionId > 0;

      return isICAppointment && isVideoSessionCreated && ["ACCEPTED", "INITIATED", "ACTIVE"].includes(status);
    }),

    actions: {
      rescheduleAppointment: function rescheduleAppointment() {
        var listingId = this.get("model.listing.id");
        var webtrack = 4; //TODO: clarify value to use
        var apptId = this.get("model.id");
        var url = Ember.ENV.urls.nextJSRoot + "/appointment/time-selection?listingid=" + listingId + "&webtrack=" + webtrack + "&apptid=" + apptId;
        this.reloadWithQueryStringVars(url);
      },
      joinVideoCall: function joinVideoCall() {
        var videoSessionId = this.get("model.videoSessionId");
        var url = Ember.ENV.urls.nextJSRoot + "/video/sessions/consumer?sessionid=" + videoSessionId;
        var mixpanel = this.get('mixpanelClient');
        if (mixpanel) {
          mixpanel.trackNewEvent('Join Video Call Clicked', {
            eventData: {
              user_type: 'consumer', sessionId: videoSessionId
            }
          });
        }
        window.open(url, "_self");
      },
      cancelAppointment: function cancelAppointment() {
        var apptId = this.get("model.id");
        var url = Ember.ENV.urls.nextJSRoot + "/appointment/cancel?apptid=" + apptId;
        this.reloadWithQueryStringVars(url);
      },
      confirmCancelItem: function confirmCancelItem() {
        this.set("_showCancelConfirmationDialog", true);
        this.sendAction("stopUpdates");
      },
      closeDialog: function closeDialog() {
        this.set("_showCancelConfirmationDialog", false);
        this.sendAction("startUpdates");
      },
      cancelItem: function cancelItem() {
        var _this = this;

        var that = this;
        var id = this.get("model.id");
        var note = $(".customer-cancel-appointment").val();
        if (note == "") {
          note = null;
        }
        Ember.$.ajax(Ember.ENV.getApiRoot() + "/appointments/" + id + "/cancel", {
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify({ note: note })
        }).always(function () {
          that.set("_showCancelConfirmationDialog", false);
          $(".customer-cancel-appointment").val("");
          that.sendAction("startUpdates");
          that.sendAction("refresh");
        }).fail(function (response) {
          var jsonError = response.responseJSON;
          _this.flashMessages.danger(jsonError.Message, {
            showProgress: true,
            timeout: 5000
          });
        });
      },
      createVideoSession: function createVideoSession() {
        var _this2 = this;

        var that = this;
        var apptId = this.get("model.id");

        var userId = Ember.Application.user.id;

        Ember.$.ajax(Ember.ENV.getApiRoot() + "/users/" + userId + "/video/sessions?apptId=" + apptId, {
          method: "POST",
          contentType: "application/json",
          data: JSON.stringify({})
        }).then(function (data) {
          $(that.element).find(".create-session-button").hide();
          window.location.reload();
        }).fail(function (response) {
          var jsonError = response.responseJSON;
          _this2.flashMessages.danger("Could not create session for appointment", {
            showProgress: true,
            timeout: 5000
          });
        });
      },
      sendPing: function sendPing() {
        var customerId = this.get("model.listing.advisor.id");
        if (window.location.hostname === "localhost") {
          window.location.href = "/#/direct-messages/recipient/" + customerId;
        } else {
          window.location.href = "/app/#/direct-messages/recipient/" + customerId;
        }
      },
      sendEmail: function sendEmail() {
        window.location = "/Mail/Compose.aspx?" + _htmlUtils.default.encode({
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get("model.listing.advisor.userName"),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      viewNote: function viewNote() {
        var noteId = this.get("model.note.id");
        var userId = this.get("model.listing.advisor.id");
        window.location = "/Mail/Read.aspx?" + _htmlUtils.default.encode({
          mailId: noteId,
          rcptId: userId,
          Subroutine: "Mail",
          SubroutineParams: _htmlUtils.default.encode({
            mode: _mailModeType.default.Sent,
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      viewFormDetails: function viewFormDetails() {
        alert("To be implemented!");
      }
    }
  });
});