define('ingenio-web/routes/myaccount/customers', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/utils/footer-types', 'ingenio-web/mixins/reset-scroll-on-transition', 'ingenio-web/models/grid/pagination-info', 'ingenio-web/models/customers/filtration-params'], function (exports, _headerTypes, _footerTypes, _resetScrollOnTransition, _paginationInfo, _filtrationParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {

    defineHeaderType: function defineHeaderType() {
      return _headerTypes.default.FULL;
    },

    defineFooterType: function defineFooterType() {
      return _footerTypes.default.EMPTY;
    },

    model: function model() {
      var filters = _filtrationParams.default.create();
      var advisorId = Ember.Application.user.id;

      return Ember.RSVP.hash({
        customerLists: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists')
      }).then(function (result) {
        filters.set("customerLists", result.customerLists.customerLists);
        return {
          selectedCustomers: [],
          pagination: _paginationInfo.default.create(),
          filters: filters
        };
      });
    },

    actions: {
      didTransition: function didTransition() {
        Ember.run.next(this, 'initTooltip');
      }
    },

    initTooltip: function initTooltip() {
      Ember.$('[data-toggle="tooltip"]').tooltip();
    }
  });
});