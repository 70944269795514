define('ingenio-web/serializers/mail-detail', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload) {
            var newPayload = { mailDetail: payload };

            return this._super(store, primaryModelClass, newPayload);
        },
        normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload) {
            var newPayload = { mailDetail: payload };

            return this._super(store, primaryModelClass, newPayload);
        }
    });
});