define('ingenio-web/components/featured-listings/listing-quality-score', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        position: function () {
            var str = '--';

            if (this.get('listing.positionInCurrentCategory') > 0) {
                str = '#' + this.get('listing.positionInCurrentCategory');
            }
            return str;
        }.property('listing.positionInCurrentCategory'),

        topCatPosition: function () {
            var str = '--';

            if (this.get('listing.positionInTopCategory') > 0) {
                str = '#' + this.get('listing.positionInTopCategory');
            }
            return str;
        }.property('listing.positionInTopCategory'),
        filteredlistingQualityMetrics: function () {
            var qualityMetrics = this.get('listing.listingQualityMetrics').filter(function (item) {
                return item.componentName == 'Retention Rate';
            });
            return qualityMetrics;
        }.property('listing.listingQualityMetrics')
    });
});