define('ingenio-web/arrange-conversation/details/route', ['exports', 'ingenio-web/utils/footer-types', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _footerTypes, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    flashMessages: Ember.inject.service(),

    defineFooterType: function defineFooterType() {
      if (Ember.ENV.domainId === 6) {
        return _footerTypes.default.MINIMAL;
      }
      return _footerTypes.default.FULL;
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var model = this.modelFor(this.getParentRouteName());

      // just in case if user has arranged call - direct user to the confirmation page
      if (model.get("advisorConversationRequest")) {
        this.replaceWith(this.getParentRouteName() + ".confirmation", {
          queryParams: transition.queryParams
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      model.set("userOperation", "Arrange Conversation");

      if (model.get("showOfferRemovalMessage")) {
        this.flashMessages.danger("Oops! We're sorry. You're not qualified for this offer. Your card has not been charged.", {
          sticky: true,
          showProgress: true,
          extendedTimeout: 500,
          destroyOnClick: false
        });
        model.set("showOfferRemovalMessage", false);
      }

      model.get("notifications.success").forEach(function (item) {
        _this.flashMessages.success(item, {
          showProgress: true,
          timeout: 5000
        });
      });
      model.get("notifications.success").clear();

      model.get("notifications.errors").forEach(function (item) {
        _this.flashMessages.danger(item, {
          showProgress: true,
          timeout: 5000
        });
      });
      model.get("notifications.errors").clear();
    }
  });
});