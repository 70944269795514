define('ingenio-web/components/chat/chat-notify', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notification: null,

        show: false,
        timer: null,

        notificationObserver: function () {
            var _this = this;

            Ember.run.cancel(this.get('timer'));

            var notification = this.get('notification');

            if (!notification.get('text')) {
                this.set('show', false);
                return;
            }

            this.set('show', true);
            var closeAfterSeconds = notification.get('closeAfter');

            if (closeAfterSeconds) {
                var timer = Ember.run.later(this, function () {
                    _this.set('show', false);
                    notification.set('text', null);
                }, closeAfterSeconds * 1000);

                this.set('timer', timer);
            }
        }.observes('notification').on('init'),

        hideNotification: function hideNotification() {
            this.set('show', false);
        },


        actions: {
            close: function close() {
                this.hideNotification();
                if (this.get('notification.closeAction')) {
                    this.get('notification.closeAction')();
                }
            },

            cancel: function cancel() {
                this.get('notification.cancelAction')();
                this.hideNotification();
            },

            submitButton: function submitButton() {
                this.hideNotification();
                this.get('notification.submitAction')();
            }
        }
    });
});