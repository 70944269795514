define('ingenio-web/services/previous-session-transcript-loader', ['exports', 'ingenio-web/objects/chat/message'], function (exports, _message) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        convertTranscriptToMessages: function convertTranscriptToMessages(transcriptResponse, advisorId, consumerId, isAdvisor, advisorAvatarUrl) {
            if (!transcriptResponse || !transcriptResponse.get('messages') || transcriptResponse.get('messages').length === 0) {
                return {
                    transcript: null,
                    optedOutTranscripts: transcriptResponse.get('optedOutTranscripts')
                };
            }

            var conversationDate = new Date(transcriptResponse.get('messages')[0].timestamp);
            var today = new Date();
            var participantId = isAdvisor ? advisorId : consumerId;

            return {
                transcript: transcriptResponse.get('messages').map(function (m) {
                    var messageIsMine = participantId.toString() === m.fromId.toString() && m.from !== 'keen';
                    var avatarUrl = '';

                    if (messageIsMine && participantId === advisorId) {
                        avatarUrl = advisorAvatarUrl;
                    } else if (!messageIsMine && participantId !== advisorId) {
                        avatarUrl = advisorAvatarUrl;
                    }

                    return _message.default.create({
                        //no avatar for consumers
                        avatarUrl: avatarUrl,
                        system: m.from === 'keen',
                        text: m.message,
                        sentAt: m.timestamp,
                        isMine: messageIsMine,
                        outputTimeFormat: conversationDate.getFullYear() !== today.getFullYear() ? 'MMM Do YYYY, h:mm A' : 'MMM Do, h:mm A'
                    });
                }),
                optedOutTranscripts: transcriptResponse.get('optedOutTranscripts')
            };
        },
        getPreviousSessionTranscript: function getPreviousSessionTranscript(advisorId, consumerId, isAdvisor, advisorAvatarUrl, store) {
            var _this = this;

            var query = isAdvisor ? {
                consumerId: consumerId,
                isAdvisor: true,
                mostRecentOne: true,
                pageNumber: -1,
                pageSize: 100
            } : {
                advisorId: advisorId,
                mostRecentOne: true,
                pageNumber: -1,
                pageSize: 100
            };

            return store.queryRecord('chatTranscript', query).then(function (transcript) {
                var chatMessages = _this.convertTranscriptToMessages(transcript, advisorId, consumerId, isAdvisor, advisorAvatarUrl);

                return chatMessages;
            });
        },
        getTranscript: function getTranscript(sessionId, advisorId, consumerId, isAdvisor, advisorAvatarUrl, store) {
            var _this2 = this;

            //eslint-disable-line max-params
            return store.findRecord('chat-transcript', sessionId, {
                adapterOptions: { includeSystemMessages: 'true' }
            }).then(function (transcript) {
                var chatMessages = _this2.convertTranscriptToMessages(transcript, advisorId, consumerId, isAdvisor, advisorAvatarUrl);

                return chatMessages;
            });
        }
    });
});