define('ingenio-web/call-setup/details/route', ['exports', 'ingenio-web/mixins/checkout-flow/details/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _route.default.extend({
        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);

            var checkoutFlowModel = this.checkoutFlowModel();

            if (!transition.queryParams.r && checkoutFlowModel.get('user.balance.callMinutes') >= Ember.ENV.minimumBalanceMinutesForConversation && checkoutFlowModel.get('conversationPaymentMethodSelected')) {
                this.replaceWith(this.getParentRouteName() + '.confirmation', {
                    queryParams: Object.assign({ r: true }, transition.queryParams)
                });
            }
        },


        actions: {
            didTransition: function didTransition() {
                this.get('mixpanelClient').trackNewEvent('Call Setup - Details Viewed');
            }
        }
    });
});