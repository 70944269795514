define('ingenio-web/models/payment-method', ['exports', 'ember-data/attr', 'ingenio-web/models/model-base'], function (exports, _attr, _modelBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        isPrimary: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        chargeAccountType: (0, _attr.default)('number', {
            defaultValue: '0'
        }),
        creditCardType: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        expiration: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        last4Digits: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        name: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        email: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        isInEdit: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        mode: (0, _attr.default)('string', {
            defaultValue: 'edit'
        }),
        maskedCardNumber: (0, _attr.default)('string'),
        paypalExpressCheckoutToken: (0, _attr.default)('string', {
            defaultValue: ''
        }),
        entity: (0, _attr.default)(),

        deviceId: (0, _attr.default)('string', {
            defaultValue: ''
        }),

        eventSource: (0, _attr.default)('string', {
            defaultValue: 'WebSite'
        }),

        generalInfo: function () {
            if (this.get('chargeAccountType') === 1) {
                return this.get('accountNumber');
            }

            return this.get('creditCardType') + '-' + this.get('last4Digits') + ' exp: ' + this.get('expiration');
        }.property('last4Digits', 'creditCardType', 'validationErrors.length'),

        isExpired: function () {
            var expiration = this.get('expiration');
            if (!expiration) {
                return false;
            }

            var today = moment();

            var expirationDate = moment(expiration, 'MMYY');

            return !(expirationDate.isValid() && today < expirationDate.add(1, 'months'));
        }.property('expiration'),

        shortInfo: function () {
            if (this.get('chargeAccountType') === 1) {
                return this.get('accountNumber');
            }

            var cardType = this.get('creditCardType').toLowerCase();

            switch (cardType) {
                case 'visa':
                    cardType = 'VISA';
                    break;
                case 'mastercard':
                    cardType = 'MC';
                    break;
                case 'amex':
                    cardType = 'AMEX';
                    break;
                case 'discover':
                    cardType = 'DS';
                    break;
            }

            return cardType + '-' + this.get('last4Digits');
        }.property('last4Digits', 'creditCardType'),

        cssClass: function () {
            if (this.get('chargeAccountType') === 0) {
                return 'epc-' + this.get('creditCardType').toLowerCase();
            } else if (this.get('chargeAccountType') === 1) {
                return 'epc-paypal';
            } else if (this.get('chargeAccountType') === 2) {
                return 'epc-credit-card';
            } else if (this.get('chargeAccountType') === 3) {
                return 'epc-paypal';
            }

            return null;
        }.property('chargeAccountType'),

        isCreditCard: function () {
            return this.get('chargeAccountType') === 0;
        }.property('chargeAccountType'),

        isPayPal: function () {
            return this.get('chargeAccountType') === 1;
        }.property('chargeAccountType'),

        isNewCreditCard: function () {
            return this.get('chargeAccountType') === 2;
        }.property('chargeAccountType'),

        isNewPayPal: function () {
            return this.get('chargeAccountType') === 3;
        }.property('chargeAccountType'),

        accountNumber: function () {
            if (this.get('chargeAccountType') === 1) {
                return this.get('email');
            } else if (this.get('creditCardType').toLowerCase() === 'amex') {
                return 'XXXX-XXXXXX-X' + this.get('last4Digits');
            }

            return 'XXXX-XXXX-XXXX-' + this.get('last4Digits');
        }.property('chargeAccountType', 'last4Digits')
    });
});