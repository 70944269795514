define("ingenio-web/birth-chart/step-helpers", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        queryParams: ["pmode", "survrespid", "steptype", "source", "reportid"],

        steps: Ember.computed('birthChart.steps', function () {

            var steps = this.get('birthChart.steps');

            return steps;
        })
    });
});