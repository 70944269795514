define('ingenio-web/components/mail-details/component', ['exports', 'ingenio-web/utils/html-utils'], function (exports, _htmlUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.getListingModel();
            this.mailRestored = false;
        },


        store: Ember.inject.service(),
        toast: Ember.inject.service(),

        currentUserId: Ember.Application.user.id,

        model: null, //passed in

        recipientId: null, //passed in

        listingModel: null, // fetched on init based on the mail listingId

        mailDetails: Ember.computed.reads('model.maildetails.transactionDetails'),

        listing: Ember.computed.reads('model.maildetails.listingDetails'),

        advisorEarnings: Ember.computed.reads('model.maildetails.advisorEarnings'),

        feedback: Ember.computed('model.maildetails.feedbackDetails', function () {
            var feedbackDetails = this.get('model.maildetails.feedbackDetails');
            var listingId = this.get('listing.serviceId');
            var mailId = this.get('model.id');

            if (!feedbackDetails) {
                return { transactionDetail: { transactionIsNotRateableAndNoRatingExists: true } };
            }

            return {
                isMail: true,
                transactionDetail: {
                    listingId: listingId,
                    id: mailId,
                    feedbackRating: feedbackDetails.rating,
                    feedbackComment: feedbackDetails.description,
                    advisorStrengths: feedbackDetails.advisorStrengths,

                    // This property is for Chat only, always true for Mail
                    feedbackIsQualifiedForDisplay: true,
                    transactionIsRateable: feedbackDetails.transactionIsRateable,

                    // TODO: avoid code duplication by re-use of "transaction-detail" model
                    feedbackExists: Ember.computed("feedbackComment", function () {
                        return this.feedbackComment && this.getfeedbackComment.length > 0;
                    }),

                    feedbackCommentExistAndQualifiedForDisplay: Ember.computed("feedbackComment", "feedbackIsQualifiedForDisplay", function () {
                        return this.feedbackComment && this.feedbackComment.length > 0 && this.feedbackIsQualifiedForDisplay;
                    }),

                    feedbackRatingExistsButNotQualifiedForDisplay: Ember.computed("feedbackRating", "feedbackIsQualifiedForDisplay", function () {
                        var feedbackRatingExists = this.feedbackRating > 0;
                        return feedbackRatingExists && !this.feedbackIsQualifiedForDisplay;
                    }),

                    feedbackRatingExistsAndQualifiedForDisplay: Ember.computed("feedbackRating", "feedbackIsQualifiedForDisplay", function () {
                        var feedbackRatingExists = this.feedbackRating && this.feedbackRating > 0;
                        return feedbackRatingExists && this.feedbackIsQualifiedForDisplay;
                    }),

                    transactionIsNotRateableAndNoRatingExists: Ember.computed('transactionIsRateable', 'feedbackRating', function () {
                        return !this.transactionIsRateable && this.feedbackRating <= 0;
                    }),

                    advisorStrengthsExists: Ember.computed("advisorStrengths", function () {
                        return this.advisorStrengths && this.advisorStrengths.length > 0;
                    })
                }
            };
        }),

        mailType: Ember.computed('mailDetails.mailType', function () {
            var type = this.get('mailDetails.mailType');
            var name = '';
            switch (type) {
                case 13:
                    name = 'Payment Request';
                    break;
                case 14:
                    name = 'Pay-To-View';
                    break;
                case 24:
                    name = 'Send Payment';
                    break;
                case 25:
                    name = 'Bonus';
                    break;

                default:
                    name = 'Invalid Email Type';
                    break;
            }
            return name;
        }),

        advisorAvailabilityText: function () {
            var availability = this.get('listingModel.availability');
            var chatAvailability = this.get('listingModel.chatAvailability');
            var text = '';

            switch (availability) {
                case 2: //busySendMail
                case 3: //busyAppt
                case 4:
                    //busyArrangeCall
                    text = 'Busy';
                    break;
                case 5: //awayArrangeCall
                case 6: //awaySendMail
                case 7:
                    //awayAppt
                    text = 'Away';
                    break;
            }

            if (chatAvailability === 2) {
                text = '';
            }

            return text;
        }.property('listingModel.availability', 'listingModel.chatAvailability'),

        currentUserIsRecipient: function () {
            return this.get('currentUserId').toString() === this.get('recipientId').toString();
        }.property('currentUserId', 'recipientId'),

        getListingModel: function getListingModel() {
            var _this = this;

            var listingId = this.get('listing.serviceId');

            this.get('store').queryRecord('listing-v2', { id: listingId, includeDeletedListing: true, includeArrangeChatStatus: true }).then(function (listing) {
                _this.set('listingModel', listing);
            });
        },


        isKeen: function () {
            return Ember.ENV.domainId === 1;
        }.property(),

        actions: {
            restoreDeletedMail: function restoreDeletedMail() {
                var _this2 = this;

                var mailId = this.get('mailDetails.mailId');
                var recipientId = this.get('recipientId');
                var url = Ember.ENV.getApiRoot() + '/mail/' + mailId + '/restore?recipientId=' + recipientId;

                Ember.$.post(url, {}).done(function () {
                    _this2.set('mailRestored', true);
                    _this2.get('toast').success('Mail restored succesfully!');
                    window.location.reload(); //reload is required for mail restore to work properly.
                }).fail(function (reason) {
                    _this2.get('toast').error('Error restoring mail');
                    _this2.get('loggerService').error('Error restoring mail', {}, reason);
                });
            },
            sendMail: function sendMail() {
                window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
                    Subroutine: 'Mail',
                    SubroutineParams: _htmlUtils.default.encode({
                        mode: '0',
                        to: this.get('mailDetails.memberName'),
                        ReturnURL: window.location,
                        CancelURL: window.location
                    })
                });
            },
            sendOffer: function sendOffer() {
                window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
                    Subroutine: 'Mail',
                    SubroutineParams: _htmlUtils.default.encode({
                        mode: '0',
                        to: this.get('mailDetails.memberName'),
                        type: '19',
                        ReturnURL: window.location,
                        CancelURL: window.location
                    })
                });
            },
            sendPing: function sendPing() {
                var consumerId = this.get('mailDetails.memberId');
                window.location.href = '/app/#/direct-messages/recipient/' + consumerId;
            }
        }
    });
});