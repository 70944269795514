define('ingenio-web/components/arrange-conversation/bottom-info-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['bottom-info-container']

    });
});