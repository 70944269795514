define('ingenio-web/components/arrange-conversation/waitlist-info-panel/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['waitlist-info-container'],
    advisorChatAvailability: null,
    advisorCallAvailability: null,
    conversationType: null,

    advisorIsOfflineForConversationType: Ember.computed('advisorChatAvailability', 'advisorCallAvailability', 'conversationType', function () {
      var type = this.get('conversationType');
      var advisorChatAvailability = this.get("advisorChatAvailability");
      var advisorCallAvailability = this.get("advisorCallAvailability");
      console.log('Conversation Type', type, 'Call Availability:', advisorCallAvailability, 'Chat Availability:', advisorChatAvailability);
      if (type === 'CHAT') {
        if (advisorChatAvailability === 'UNAVAILABLE') {
          return true;
        }
      } else {
        if (advisorCallAvailability && advisorCallAvailability.type === 'OFFLINE' && advisorCallAvailability.isSelected) {
          return true;
        };
        if (advisorCallAvailability && advisorCallAvailability.type === 'ARRANGE_CALLS' && advisorCallAvailability.isSelected) {
          return false;
        };
      }
      return false;
    }),

    asterisk: Ember.computed('advisorCallAvailability', function () {
      var availability = this.get("advisorCallAvailability");

      if (availability && availability.type === "ARRANGE_CALLS") {
        var sinceTime = (0, _moment.default)(availability.selectedAsOf);
        var now = (0, _moment.default)(new Date());
        var duration = _moment.default.duration(now.diff(sinceTime));
        if (duration.asMinutes() > 60 * 4) {
          return "**";
        }
      }

      return "*";
    })
  });
});