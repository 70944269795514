define('ingenio-web/call-setup/route', ['exports', 'ingenio-web/mixins/checkout-flow/route-base'], function (exports, _routeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _routeBase.default.extend({
    model: function model(params, transition) {
      var _this = this;

      return this._super(params, transition).then(function (model) {
        if (model.showDepositeType === 'addMoney') {
          return model;
        }

        var user = Ember.Application.user;

        return Ember.RSVP.hash({
          callPostPayEligibility: _this.store.findRecord('callPostPayEligibility', user.id)
        }).then(function (m) {
          var isUserEligibleForCconversationPostPay = m.callPostPayEligibility.get('isUserEligibleForCallPostPay');

          model.set('isUserEligibleForConversationPostPay', isUserEligibleForCconversationPostPay);

          return model;
        });
      });
    }
  });
});