define('ingenio-web/components/myaccount/number-of-stars', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var stars = this.get('stars');
            var readonly = this.get('readonly');

            $(this.element).rating({
                showClear: false,
                showCaption: false,
                size: 'xss',
                readonly: readonly
            });

            $(this.element).rating('update', stars);
        }
    });
});