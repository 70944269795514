define('ingenio-web/routes/offer/not-qualified', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            return Ember.RSVP.hash({
                offerId: params.offer_id,
                offerTinyDescription: Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/offers/' + params.offer_id).then(function (response) {
                    return response.Offer.Descriptions.TinyDescription;
                })
            });
        }
    });
});