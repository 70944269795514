define('ingenio-web/transforms/server-date', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.DateTransform.extend({
        deserialize: function deserialize(serialized) {
            if (serialized) {
                if (serialized.slice(-1) !== 'Z') {
                    serialized += 'Z';
                }

                var date = new Date(serialized);
                return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            }

            return null;
        }
    });
});