define('ingenio-web/utils/task-bar-notification', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var TaskBarNotification = function () {
        function TaskBarNotification(options, logger) {
            _classCallCheck(this, TaskBarNotification);

            this.notificationSupported = 'Notification' in window;
            this.logger = logger;
            this.logData = { advisorId: options.advisorId, source: 'Monitor' };

            this.options = {
                title: options.title || 'Keen Notification',
                icon: options.icon || 'https://si.keen.com/D1_new-keen-logo_V1.png',
                closeAfter: options.closeAfter || 2 * 60 * 1000
            };
        }

        _createClass(TaskBarNotification, [{
            key: 'log',
            value: function log(eventName) {
                this.logger.log(eventName, this.logData);
            }
        }, {
            key: 'logError',
            value: function logError(errorName, error) {
                this.logger.error(errorName, this.logData, error);
            }
        }, {
            key: 'showNotification',
            value: function showNotification(text, onClick) {
                var _this = this;

                if (!this.notificationSupported) {
                    var browserInfo = '';

                    browserInfo += 'Browser CodeName: ' + navigator.appCodeName;
                    browserInfo += '; Browser Name: ' + navigator.appName;
                    browserInfo += '; Browser Version: ' + navigator.appVersion;
                    browserInfo += '; Cookies Enabled: ' + navigator.cookieEnabled;
                    browserInfo += '; Browser Language: ' + navigator.language;
                    browserInfo += '; Browser Online: ' + navigator.onLine;
                    browserInfo += '; Platform: ' + navigator.platform;
                    browserInfo += '; User-agent header: ' + navigator.userAgent;

                    this.log('Notification is not supported in browser: ' + browserInfo);
                    return null;
                }
                if (Notification.permission === 'granted') {
                    return this._showNottification(text, onClick);
                } else if (Notification.permission !== 'denied') {

                    Notification.requestPermission(function (permission) {
                        if (permission === 'granted') {
                            _this.log('Notification was granted');
                            return _this._showNottification(text);
                        }
                        _this.log('Notification was not granted');
                    });
                } else {
                    this.log('Notifications are denied');
                }
            }
        }, {
            key: '_showNottification',
            value: function _showNottification(text, onClick) {
                try {
                    //we  added try catch here bacuse of following error related to mobile browsers
                    //Failed to construct 'Notification': Illegal constructor. Use ServiceWorkerRegistration.showNotification() instead.


                    var options = {
                        body: text,
                        icon: this.options.icon
                    };

                    var n = new Notification(this.options.title, options);
                    this.log('Notifications shown');

                    var timeoutHandler = setTimeout(function () {
                        return n.close();
                    }, this.options.closeAfter);
                    var closeNotification = function closeNotification() {
                        if (onClick) {
                            try {
                                onClick();
                            } catch (error) {
                                console.error('exception dring click on notification');
                            }
                        }

                        clearTimeout(timeoutHandler);
                        n.close();
                    };

                    n.addEventListener('click', closeNotification);

                    return closeNotification;
                } catch (e) {
                    this.logger.log('Error during sending notification. Message: ' + (e.message || '') + ' Stack: ' + (e.stack || ''));
                    return function () {};
                }
            }
        }]);

        return TaskBarNotification;
    }();

    exports.default = TaskBarNotification;
});