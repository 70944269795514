define('ingenio-web/utils/customer-utils', ['exports', 'ingenio-web/utils/html-utils', 'ingenio-web/constants/mail-mode-type'], function (exports, _htmlUtils, _mailModeType) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // TODO: add similar to below collecion to map from activityTypeId to activityType
    var formattedActivityDescriptions = {
        'CHATRECEIVED': 'Chat Rec\'d',
        'CHATRECEIVEDFROMFL': 'FL Chat Rec\'d',
        'CHATDECLINED': 'Chat Declined',
        'CHATFAILED': 'Chat Failed',
        'CHATMISSED': 'Chat Missed',
        'CALLBACKATTEMPT': 'Tried Callback',
        'CALLRECORDEDRECEIVED': 'Record Call',
        'CALLRECORDEDFEATUREDLISTINGRECEIVED': 'FL Record Call',
        'CALLLIVEFROMCALLBACKRECEIVED': 'Call Rec\'d',
        'CALLLIVERECEIVED': 'Call Rec\'d',
        'CALLLIVEFEATUREDLISTINGRECEIVED': 'FL Call Rec\'d',
        'CALLLIVEFLFROMCALLBACKRECEIVED': 'FL Call Rec\'d',
        'CALLMISSEDMADE': 'Call Missed',
        'CALLMISSEDRECEIVED': 'Call Missed',
        'CALLMISSEDBUYERPROBLEM': 'Customer couldn\'t connect',
        'MAILRECEIVEDBOUGHT': 'Pymt Rec\'d',
        'MAILRECEIVEDFEATUREDLISTINGBOUGHT': 'FL Pymt Rec\'d',
        'MAILRECEIVED': 'Mail Rec\'d',
        'MAILRECEIVEDSOLD': 'Mail Rec\'d',
        'MAILRECEIVEDFEATUREDLISTINGSOLD': 'FL Mail Rec\'d',
        'MAILSENT': 'Mail Sent',
        'MAILSENTBOUGHT': 'Mail Sold',
        'MAILSENTSOLD': 'Mail Sold',
        'MAILSENTFEATUREDLISTINGBOUGHT': 'FL Mail Sold',
        'MAILSENTFEATUREDLISTINGSOLD': 'FL Mail Sold',
        'MAILTIPRECEIVED': 'Tip Rec\'d',
        'MAILTIPRECEIVEDFEATUREDLISTINGSOLD': 'FL Tip Rec\'d',
        'MAILTIPSENT': 'Tip Sent',
        'MAILTIPSENTFEATUREDLISTINGBOUGHT': 'FL Tip Sent',
        'MAILINVITATIONSENT': 'Sent Offer'
    };

    var activityTypeNames = {
        3: 'CALLMISSEDMADE',
        6: 'CALLBACKATTEMPT',
        8: 'MAILSENTSOLD',
        13: 'MAILSENT',
        14: 'MAILRECEIVED',
        15: 'MAILRECEIVEDSOLD',
        16: 'MAILRECEIVEDBOUGHT',
        17: 'MAILINVITATIONSENT',
        20: 'MAILTIPSENT',
        21: 'MAILTIPRECEIVED',
        23: 'MAILSENTFEATUREDLISTINGSOLD',
        24: 'MAILRECEIVEDFEATUREDLISTINGBOUGHT',
        25: 'MAILSENTFEATUREDLISTINGBOUGHT',
        26: 'MAILRECEIVEDFEATUREDLISTINGSOLD',
        27: 'MAILTIPSENTFEATUREDLISTINGBOUGHT',
        28: 'MAILTIPRECEIVEDFEATUREDLISTINGSOLD',
        101: 'CALLLIVERECEIVED',
        102: 'CALLLIVEFROMCALLBACKRECEIVED',
        103: 'CALLMISSEDRECEIVED',
        104: 'CALLLIVEFEATUREDLISTINGRECEIVED',
        105: 'CALLRECORDEDRECEIVED',
        106: 'CALLLIVEFLFROMCALLBACKRECEIVED',
        108: 'MAILSENTBOUGHT',
        109: 'CALLMISSEDBUYERPROBLEM',
        110: 'CALLRECORDEDFEATUREDLISTINGRECEIVED',
        160: 'CHATRECEIVED',
        162: 'CHATDECLINED',
        163: 'CHATRECEIVEDFROMFL',
        166: 'CHATFAILED',
        167: 'CHATMISSED'
    };

    exports.default = {
        getActivityUrl: function getActivityUrl(customerId, activity) {
            if (!activity) return null;

            var activityType = activity.type;
            var activityId = activity.activityId;
            var mailId = activity.mailId;
            var transId = activity.masterTransactionId;
            var isDeleted = false;
            if (activity.sessionExists !== undefined) {
                isDeleted = !activity.sessionExists;
            }

            switch (activityType) {
                case "CHATRECEIVED":
                case "CHATRECEIVEDFROMFL":
                    return '/app/#/chat/details/' + activityId;
                case "CALLLIVERECEIVED":
                case "CALLLIVEFEATUREDLISTINGRECEIVED":
                case "CALLRECORDEDRECEIVED":
                case "CALLRECORDEDFEATUREDLISTINGRECEIVED":
                case "CALLLIVEFROMCALLBACKRECEIVED":
                case "CALLLIVEFLFROMCALLBACKRECEIVED":
                    return '/app/#/call/details/' + activityId;
                case "MAILSENT":
                case "MAILTIPSENT":
                case "MAILTIPSENTFEATUREDLISTINGBOUGHT":
                    return isDeleted ? '' : '/Mail/Read.aspx?' + _htmlUtils.default.encode({
                        mailId: mailId,
                        rcptId: customerId,
                        Subroutine: 'Mail',
                        SubroutineParams: _htmlUtils.default.encode({
                            mode: _mailModeType.default.Sent,
                            ReturnURL: window.location,
                            CancelURL: window.location
                        })
                    });
                case "MAILRECEIVED":
                case "MAILRECEIVEDBOUGHT":
                case "MAILRECEIVEDFEATUREDLISTINGBOUGHT":
                    // use current user id as a recepient
                    return isDeleted ? '' : '/Mail/Read.aspx?' + _htmlUtils.default.encode({
                        mailId: mailId,
                        rcptId: Ember.Application.user.id,
                        Subroutine: 'Mail',
                        SubroutineParams: _htmlUtils.default.encode({
                            mode: _mailModeType.default.Inbox,
                            ReturnURL: window.location,
                            CancelURL: window.location
                        })
                    });
                case "MAILSENTSOLD":
                case "MAILRECEIVEDSOLD":
                case "MAILSENTFEATUREDLISTINGSOLD":
                case "MAILRECEIVEDFEATUREDLISTINGSOLD":
                    return '/MyAccount/Transactions/Details.aspx?' + _htmlUtils.default.encode({
                        Subroutine: 'Overview',
                        SubroutineParams: _htmlUtils.default.encode({
                            transId: transId,
                            clientTransCode: 'KMS',
                            previous: 'Flows.MyCustomersSubroutine',
                            detailBreadcrumbType: 'activities',
                            ReturnURL: window.location,
                            CancelURL: window.location
                        })
                    });

                case "MAILTIPRECEIVED":
                case "MAILTIPRECEIVEDFEATUREDLISTINGSOLD":
                    // if we have transaction Id show the Transaction Page else the Mail Page
                    if (transId && transId > 0) {
                        return '/MyAccount/Transactions/Details.aspx?' + _htmlUtils.default.encode({
                            Subroutine: 'Overview',
                            SubroutineParams: _htmlUtils.default.encode({
                                transId: transId,
                                clientTransCode: 'KMS',
                                previous: 'Flows.MyCustomersSubroutine',
                                detailBreadcrumbType: 'activities',
                                ReturnURL: window.location,
                                CancelURL: window.location
                            })
                        });
                    }
                    return isDeleted ? '' : '/Mail/Read.aspx?' + _htmlUtils.default.encode({
                        mailId: mailId,
                        rcptId: Ember.Application.user.id,
                        Subroutine: 'Mail',
                        SubroutineParams: _htmlUtils.default.encode({
                            mode: _mailModeType.default.Inbox,
                            ReturnURL: window.location,
                            CancelURL: window.location
                        })
                    });

                default:
                    return '';
            }
        },
        getLastActivityUrl: function getLastActivityUrl(customer) {
            if (!customer || !customer.contacts || !customer.contacts.last || !customer.contacts.last.type) {
                return '';
            }

            return this.getActivityUrl(customer.id, customer.contacts.last);
        },
        getFormattedText: function getFormattedText(customer) {
            return formattedActivityDescriptions[customer.contacts.last.type] || 'N/A';
        },
        getFormattedText2: function getFormattedText2(type, description) {
            return formattedActivityDescriptions[type] || 'N/A';
        },
        getCustomerDetailsUrl: function getCustomerDetailsUrl(customer) {
            return '/app/#/myaccount/customers/' + customer.id;
        },
        getActivityTypeNameById: function getActivityTypeNameById(activityId) {
            return activityTypeNames[activityId];
        }
    };
});