define('ingenio-web/components/summary-box-buttons/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			onPrimaryButtonClick: function onPrimaryButtonClick() {
				this.sendAction('onPrimaryButtonClick');
			},

			onCancelButtonClick: function onCancelButtonClick() {
				this.sendAction('onCancelButtonClick');
			}
		}
	});
});