define("ingenio-web/arrange-conversation/index/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    arrangeConversationFlowModel: Ember.computed(function () {
      return this.modelFor(this.getParentRouteName());
    }),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (this.get("arrangeConversationFlowModel.advisorConversationRequest")) {
        this.replaceWith(this.getParentRouteName() + ".confirmation", {
          queryParams: transition.queryParams
        });
      } else {
        this.replaceWith(this.getParentRouteName() + ".details", {
          queryParams: transition.queryParams
        });
      }
    }
  });
});