define('ingenio-web/routes/offer/redeem-success', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            return Ember.RSVP.hash({
                returnUrl: Ember.ENV.urls.myAccountPage,
                offerTinyDescription: Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/offers/' + params.offer_id).then(function (response) {
                    return response.Offer.Descriptions.TinyDescription;
                }),
                balance: Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/balance').then(function (response) {
                    return response.monetary;
                })
            });
        }
    });
});