define('ingenio-web/controllers/sms-notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['update', 'returnUrl', 'type', 'backButtonText'],
        update: null,
        returnUrl: null,
        type: 'transactional',
        backButtonText: null
    });
});