define('ingenio-web/arranged-conversations/controller', ['exports', 'ingenio-web/mixins/error-handler', 'mobile-detect'], function (exports, _errorHandler, _mobileDetect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_errorHandler.default, {
        queryParams: ['appointmentid', 'tab', 'canceled'],
        appointmentid: null,
        callMonitorWindow: null,
        canceled: null,
        mixpanelClient: Ember.inject.service('mixpanel-client'),

        showAcceptAppointmentDialog: false,
        showDeclineAppointmentDialog: false,
        showCancelAppointmentDialog: false,

        flashMessages: Ember.inject.service(),
        toast: Ember.inject.service(),

        isAdvisor: Ember.computed('Ember.Application.user.isAdvisor', function () {
            return Ember.Application.user.isAdvisor;
        }),

        isMobile: Ember.computed(function () {
            var md = new _mobileDetect.default(window.navigator.userAgent);
            var isPhone = md.phone() !== null;
            var isTablet = md.tablet() !== null;
            console.log('Mobile Detection - Phone:', md.phone(), ' - Tablet: ', md.tablet(), 'isPhone:', isPhone, 'isTablet:', isTablet);

            return isPhone && !isTablet;
        }),
        isKeen: Ember.computed(function () {
            return Ember.ENV.domainId === 1;
        }),

        advisorsActive: Ember.computed('tab', function () {
            if (this.get('tab')) {
                return this.get('tab').toString().toLowerCase() == 'my-advisors';
            }
            return false;
        }),

        customersActive: Ember.computed('advisorsActive', function () {
            return !this.get('advisorsActive');
        }),

        headerItemType: Ember.computed('model.headerItemType', function () {
            return this.get('model.headerItemType');
        }),

        headerItemTypeIndicator: Ember.computed('model.headerItemTypeIndicator', function () {
            return this.get('model.headerItemTypeIndicator');
        }),

        setup: function () {
            var _this = this;

            setTimeout(function () {
                if (_this.get('canceled') === 'true') {
                    _this.get('toast').success('Appointment was cancelled successfully.');
                    _this.set('canceled', null);
                }
            }, 2000);
        }.on('init'),

        handleErrorResponse: function handleErrorResponse(response) {
            var jsonError = response.responseJSON;
            var errorMessage = jsonError && jsonError.Message ? jsonError.Message : 'Something went wrong! Please try again later!';

            this.flashMessages.danger(errorMessage, {
                showProgress: false,
                timeout: 5000
            });
        },


        arrangeConversationsHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360053005414-Arranged-Conversations';
            }
            return '/help/arrangeconversations';
        }.property(),

        makingAppointmentsHelpUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360053783793-Appointments';
            }
            return '/help/makingappointments';
        }.property(),

        actionCallStatus: Ember.computed('model.actionCall', function () {
            var actionCall = this.get('model.actionCall');

            if (actionCall && actionCall.call) {
                switch (actionCall.call.statusCode) {
                    case 'INITIATE_FAILED':
                    case 'UNKNOWN':
                        return 'ERROR';

                    case 'INITIATED':
                        {
                            switch (actionCall.call.resultCode) {
                                case 'BOTH_ANSWER':
                                case 'CALLING_BUYER':
                                case 'BUYER_RING_NO_ANSWER':
                                case 'BUYER_BUSY':
                                case 'BUYER_SIT_TONE':
                                case 'BUYER_DEAD_AIR':
                                case 'BUYER_CALL_INIT_FAILED':
                                case 'BUYER_NO_VALID_RESPONSE':
                                case 'BUYER_CALL_IN_ERROR':
                                case 'BUYER_HANGUP_AFTER_ACCEPT':
                                case 'BUYER_SUPERVISION_ERROR':
                                case 'BUYER_REJECT_CALL':
                                case 'BUYER_NO_VALID_RESPONSE_TIMEOUT':
                                    return 'CONNECTING';

                                default:
                                    {
                                        if (actionCall.call.source == 'CALL_ME_BACK_WEB') {
                                            return 'CALLINGYOU';
                                        }

                                        return 'CONNECTING';
                                    }
                            }
                        }

                    case 'SUBMITTED':
                        return 'CONNECTING';

                    case 'CONNECTED':
                        return 'ONCALL';

                    case 'TERMINATED':
                    case 'PROCESSED':
                        switch (actionCall.call.resultCode) {
                            case 'BOTH_ANSWER':
                                return 'CALLENDED';

                            case 'BUYER_RING_NO_ANSWER':
                            case 'BUYER_BUSY':
                            case 'BUYER_SIT_TONE':
                            case 'BUYER_DEAD_AIR':
                            case 'BUYER_CALL_INIT_FAILED':
                            case 'BUYER_NO_VALID_RESPONSE':
                            case 'BUYER_CALL_IN_ERROR':
                            case 'BUYER_HANGUP_AFTER_ACCEPT':
                            case 'BUYER_SUPERVISION_ERROR':
                            case 'BUYER_REJECT_CALL':
                            case 'BUYER_NO_VALID_RESPONSE_TIMEOUT':
                                return 'UNAVAILABLE';

                            case 'SELLER_RING_NO_ANSWER':
                                return 'YOUDIDNTANSWER';

                            case 'SELLER_BUSY':
                                return 'PHONEBUSY';

                            case 'SELLER_SIT_TONE':
                            case 'SELLER_DEAD_AIR':
                            case 'SELLER_REJECT_CALL':
                                return 'CALLNOTACCEPTED';

                            case 'SELLER_CALL_INIT_FAILED':
                            case 'SELLER_NO_VALID_RESPONSE':
                            case 'SELLER_CALL_IN_ERROR':
                            case 'SELLER_SUPERVISION_ERROR':
                                return 'ERRORCALLINGYOU';
                        }

                    default:
                        return '';
                }
            }
            return 'TAKEACTION';
        }),

        actionCallStatusCss: Ember.computed('actionCallStatus', function () {
            var actionCallStatus = this.get('actionCallStatus');

            if (actionCallStatus) {
                switch (actionCallStatus) {
                    case 'TAKEACTION':
                        return '';

                    default:
                        return 'connecting';
                }
            }

            return 'connecting';
        }),

        actionCallStatusText: Ember.computed('model', 'actionCallStatus', function () {
            var actionCall = this.get('model.actionCall');
            var actionCallStatus = this.get('actionCallStatus');

            if (actionCall && !!actionCallStatus) {
                switch (actionCallStatus) {
                    case 'ERROR':
                        return 'Error';

                    case 'CONNECTING':
                        return 'Connecting ';

                    case 'CALLINGYOU':
                        return 'Calling You';

                    case 'ONCALL':
                        return 'On Call With ';

                    case 'CALLENDED':
                        return 'Call Ended';

                    case 'YOUDIDNTANSWER':
                        return 'Missed Call';

                    case 'PHONEBUSY':
                        return 'Your Line Is Busy';

                    case 'CALLNOTACCEPTED':
                        return 'Call Wasn`t Accepted';

                    case 'ERRORCALLINGYOU':
                        return 'Error Calling You';

                    case 'UNAVAILABLE':
                        return 'Unavailable';

                    case 'TAKEACTION':
                        return ' is waiting for you';

                    default:
                        return '';
                }
            }
            return '';
        }),

        actionCallStatusTextUsername: Ember.computed('model', 'actionCallStatus', function () {
            var actionCall = this.get('model.actionCall');
            var actionCallStatus = this.get('actionCallStatus');

            if (actionCall && actionCall.requestedBy) {
                if (actionCallStatus) {
                    switch (actionCallStatus) {
                        case 'CONNECTING':
                        case 'ONCALL':
                        case 'TAKEACTION':
                            return actionCall.requestedBy.userName;

                        default:
                            return '';
                    }
                }
                return actionCall.requestedBy.userName;
            }

            return '';
        }),

        advisorsCallsArranged: Ember.computed('model.advisor', function () {
            var advisor = this.get('model.advisor');

            if (advisor && advisor.conversateWaitList && advisor.conversateWaitList.requests && advisor.conversateWaitList.requests.edges) {
                return advisor.conversateWaitList.requests.edges.length > 0;
            }
            return false;
        }),

        advisorsAppointmentsRequested: Ember.computed('model.advisor', function () {
            var advisor = this.get('model.advisor');

            if (advisor && advisor.listingAppointments) {
                return advisor.listingAppointments.length > 0;
            }
            return false;
        }),

        customerCallsArranged: Ember.computed('model.customer', function () {
            var customer = this.get('model.customer');

            if (customer && customer.advisorConversationRequests && customer.advisorConversationRequests.edges) {
                return customer.advisorConversationRequests.edges.length > 0;
            }

            return false;
        }),

        customerAppointmentsRequested: Ember.computed('model.customer', function () {
            var customer = this.get('model.customer');

            if (customer && customer.requestedListingAppointments) {
                return customer.requestedListingAppointments.length > 0;
            }

            return false;
        }),

        actions: {
            openMonitor: function openMonitor(chatOnline) {
                if (!this.callMonitorWindow || this.callMonitorWindow.closed) {
                    var url = '/app/#/monitor/';

                    if (window.location.hostname === 'localhost') {
                        url = '/#/monitor/';
                    }

                    this.callMonitorWindow = window.open('' + url, 'callChatMonitor', 'width=490,height=650,location=0,menubar=0,resizable=0,scrollbars=0,toolbar=0,status=0');
                }

                this.callMonitorWindow.focus();
            },
            onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
                this.set('phonesInMultiEditMode', true);
                this.send('startEditPhones');
                this.send('stopUpdates');
            },
            onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
                this.send('cancelEditPhones');
                this.set('phonesInMultiEditMode', false);
                this.send('startUpdates');
            },
            onPhoneNumberChange: function onPhoneNumberChange(phones) {
                this.set('phones', phones);
            },
            confirmAcceptAppointment: function confirmAcceptAppointment(appointment) {
                this.set('pendingAppointment', appointment);
                this.set('showAcceptAppointmentDialog', true);
            },
            confirmDeclineAppointment: function confirmDeclineAppointment(appointment) {
                this.set('pendingAppointment', appointment);
                this.set('showDeclineAppointmentDialog', true);
            },
            confirmCancelAppointment: function confirmCancelAppointment(appointment) {
                this.set('pendingAppointment', appointment);
                this.set('showCancelAppointmentDialog', true);
            },
            acceptAppointment: function acceptAppointment(appointment) {
                var _this2 = this;

                var note = $('#advisor-accept-appointment-note').val();

                if (note == '') {
                    note = null;
                }

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/v2/appointments/' + appointment.id + '/accept', {
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({ note: note })
                }).always(function () {
                    _this2.set('showAcceptAppointmentDialog', false);
                    $('#advisor-accept-appointment-note').val('');
                    _this2.send('refresh');
                }).fail(function (response) {
                    return _this2.handleErrorResponse(response);
                });
            },
            declineAppointment: function declineAppointment(appointment) {
                var _this3 = this;

                var note = $('#advisor-decline-appointment-note').val();

                if (note == '') {
                    note = null;
                }

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/v2/appointments/' + appointment.id + '/decline', {
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({ note: note })
                }).always(function () {
                    _this3.set('showDeclineAppointmentDialog', false);
                    $('#advisor-decline-appointment-note').val('');
                    _this3.send('refresh');
                }).fail(function (response) {
                    return _this3.handleErrorResponse(response);
                });
            },
            cancelAppointment: function cancelAppointment(appointment) {
                var _this4 = this;

                var note = $('#advisor-cancel-appointment-note').val();

                if (note == '') {
                    note = null;
                }

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/v2/appointments/' + appointment.id + '/cancel', {
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({ cancelReason: note })
                }).always(function () {
                    _this4.set('showCancelAppointmentDialog', false);
                    $('#advisor-cancel-appointment-note').val('');
                    _this4.send('refresh');
                }).fail(function (response) {
                    return _this4.handleErrorResponse(response);
                });
            },
            closeDialog: function closeDialog() {
                this.set('showAcceptAppointmentDialog', false);
                this.set('showDeclineAppointmentDialog', false);
                this.set('showCancelAppointmentDialog', false);
            },
            saveNumbers: function saveNumbers() {
                var _this5 = this;

                var promiseHashes = [];

                this.get('phones').forEach(function (phone) {
                    var phoneNumberWithoutCountryCode = phone.number.number.replace('+' + phone.number.countryCode, '');

                    var dsPhone = _this5.get('model.userPhones').find(function (dsPhone) {
                        return dsPhone.get('id') === phone.number.id;
                    });

                    if (Ember.isEmpty(phoneNumberWithoutCountryCode) && !Ember.isEmpty(dsPhone)) {
                        promiseHashes.push(dsPhone.destroyRecord());
                        return;
                    }

                    if (!Ember.isEmpty(phoneNumberWithoutCountryCode) && Ember.isEmpty(dsPhone)) {
                        dsPhone = _this5.store.createRecord('userPhone');
                    }

                    if (!Ember.isEmpty(dsPhone)) {

                        var phoneChanged = dsPhone.get('phoneChanged') || !(dsPhone.get('number') === phoneNumberWithoutCountryCode && dsPhone.get('countryCode') === phone.number.countryCode);

                        if (!dsPhone.get('oldNumber')) {
                            dsPhone.setProperties({
                                oldNumber: dsPhone.get('id') ? dsPhone.get('number') : ''
                            });
                        }

                        if (!dsPhone.get('oldCountryCode')) {
                            dsPhone.setProperties({
                                oldCountryCode: dsPhone.get('id') ? dsPhone.get('countryCode') : ''
                            });
                        }

                        dsPhone.setProperties({
                            phoneType: phone.type.id,
                            isPrimary: phone.number.isPrimary,
                            isUSNumber: phone.number.isUSNumber,
                            countryCode: phone.number.countryCode,
                            number: phoneNumberWithoutCountryCode,
                            phoneChanged: phoneChanged
                        });

                        promiseHashes.push(dsPhone.save({
                            adapterOptions: {
                                filters: {
                                    ntvCheckEnabled: true
                                }
                            }
                        }));
                    }
                });

                Ember.RSVP.hash(promiseHashes).then(function () {
                    _this5.store.findAll('userPhone').then(function (userPhones) {
                        _this5.set('phonesInMultiEditMode', false);
                        _this5.send('startUpdates');
                    });

                    _this5.get('model.userPhones').forEach(function (p) {
                        return p.setProperties({ phoneChanged: false, oldNumber: null, oldCountryCode: null });
                    });
                }).catch(function (response) {
                    return _this5.handleError(response);
                });
            },
            exploreAdvisors: function exploreAdvisors() {
                window.location = window.location.protocol + '//' + window.location.hostname + '/how-to-find-the-best-psychic-for-you';
            },
            waitlistTitleClick: function waitlistTitleClick() {
                $('.js_collapse-container').on('hidden.bs.collapse', '.js_waitlist-content', function (e) {
                    $(e.target).parent().find('.js_item-card-title').addClass('separator-line');
                });

                $('.js_collapse-container').on('show.bs.collapse', '.js_waitlist-content', function (e) {
                    $(e.target).parent().find('.js_item-card-title').removeClass('separator-line');
                });
            },
            appointmentsTitleClick: function appointmentsTitleClick() {
                $('.js_collapse-container').on('hidden.bs.collapse', '.js_appointments-content', function (e) {
                    $(e.target).parent().find('.js_item-card-title').addClass('last');
                });

                $('.js_collapse-container').on('show.bs.collapse', '.js_appointments-content', function (e) {
                    $(e.target).parent().find('.js_item-card-title').removeClass('last');
                });
            },
            startEditPhones: function startEditPhones() {
                var oldPrimaryPhone = this.get('model.userPhones').find(function (dsPhone) {
                    return dsPhone.get('isPrimary');
                });

                if (oldPrimaryPhone) {
                    this.set('oldPrimaryPhoneId', oldPrimaryPhone.get('id'));
                }
            },
            cancelEditPhones: function cancelEditPhones() {
                var phones = this.get('model.userPhones');

                var oldPrimaryPhoneId = this.get('oldPrimaryPhoneId');

                phones.forEach(function (phone) {
                    if (phone.get('phoneChanged')) {
                        phone.setProperties({
                            countryCode: phone.get('oldCountryCode'),
                            number: phone.get('oldNumber')
                        });
                    }
                    if (oldPrimaryPhoneId) {
                        phone.setProperties({
                            isPrimary: phone.get('id') === oldPrimaryPhoneId
                        });
                    }
                });
            }
        }
    });
});