define('ingenio-web/utils/url', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    function generateQueryStringFromKeyValues(queryParams) {
        //console.log(queryParams);
        var queryString = '';

        queryParams.forEach(function (queryParam) {
            queryString += '&' + queryParam.key + '=' + queryParam.value;
        });
        return queryString.substring(1); //remove the prefixing & as part of the string
    }

    /**
     * Fallback function for browsers like IE that don't support the URL() constructor
     * @param {string} href - The href to parse
     * @returns {object} infoObject - Object with the url information
    */
    function getURLInfo(href) {
        //Regex credit: https://stackoverflow.com/users/542156/rems
        var urlInfoRegex = new RegExp(['^(https?:)//', // protocol
        '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
        '(/{0,1}[^?#]*)', // pathname
        '(\\?[^#]*|)', // search
        '(#.*|)$' // hash
        ].join(''));
        var match = href.match(urlInfoRegex);

        return match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        };
    }

    exports.default = {
        getURLInfo: getURLInfo,
        getQueryParamsFromUrl: function getQueryParamsFromUrl(url) {

            var hashUrl = url;
            var hashIndex = url.lastIndexOf('#');

            if (hashIndex !== -1) {
                // hash value present in url
                hashUrl = url.slice(hashIndex + 1);
            }

            var queryString = hashUrl.split('?')[1];

            if (queryString) {
                var queryStringList = queryString.split('&');
                var queryParams = [];

                queryStringList.forEach(function (queryPram) {
                    var keyValue = queryPram.split('=');

                    queryParams.push({
                        key: keyValue[0],
                        value: keyValue[1]
                    });
                });
                return queryParams;
            }

            return [];
        },

        findQueryParamFromUrl: function findQueryParamFromUrl(url, queryParamName) {
            var queryParams = this.getQueryParamsFromUrl(url);

            if (queryParams && queryParams.length > 0) {
                //return queryParams.find( item => item.key === queryParamName);
                for (var i = 0; i < queryParams.length; i++) {
                    if (queryParams[i].key === queryParamName) {
                        return queryParams[i];
                    }
                }
            }
            return null;
        },

        removeQueryParamFromUrl: function removeQueryParamFromUrl(url, queryParamName) {
            var baseUrl = '';

            if (url.startsWith('http')) {
                var ourl = typeof URL === 'function' ? new URL(url) : getURLInfo(url);

                baseUrl = ourl.protocol + '//' + ourl.host + ourl.pathname;
            } else {
                baseUrl = url.split('?')[0];
            }

            var queryParams = this.getQueryParamsFromUrl(url);
            var matchIndex = -1;

            for (var i = 0; i < queryParams.length; i++) {
                if (queryParams[i].key === queryParamName) {
                    matchIndex = i;
                }
            }

            if (matchIndex !== -1) {
                queryParams.splice(matchIndex, 1); //remove from the list
                if (queryParams && queryParams.length > 0) {
                    return baseUrl + '?' + generateQueryStringFromKeyValues(queryParams);
                }

                return '' + baseUrl;
            }

            return url;
        },

        appendQueryParamToUrl: function appendQueryParamToUrl(url, queryParam) {
            var baseUrl = '';

            if (url.startsWith('http')) {
                var ourl = typeof URL === 'function' ? new URL(url) : getURLInfo(url);

                baseUrl = ourl.protocol + '//' + ourl.host + ourl.pathname;
            } else {
                baseUrl = url.split('?')[0];
            }

            var queryParams = this.getQueryParamsFromUrl(url);

            if (queryParam) {
                queryParams.push(queryParam); //append the new queryparam
            } else {
                return url;
            }

            return baseUrl + '?' + generateQueryStringFromKeyValues(queryParams);
        }
    };
});