define('ingenio-web/components/featured-listings/results/navigation-tabs', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        activeTabName: null,
        didInsertElement: function didInsertElement() {
            var self = this;

            this._super.apply(this, arguments);
            this.$('#' + self.get('activeTabName')).addClass('active');

            this.$('ul li').on('click', function (e) {
                var tabElement = $(e.target);

                $('ul li').removeClass('active');
                if (e.target.tagName.toLowerCase() === 'a') {
                    tabElement = $(e.target).parent();
                }
                tabElement.addClass('active');
            });

            this.$('.navigation-tabs').scrollingTabs({
                enableSwiping: true
            });
        }
    });
});