define('ingenio-web/models/chat-consumer', ['exports', 'ember-data-model-fragments/fragment', 'ember-data/attr'], function (exports, _fragment, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        name: (0, _attr.default)('string', { defaultValue: '' }),
        consumerFirstName: (0, _attr.default)('string', { defaultValue: '' }),
        nickname: (0, _attr.default)('string', { defaultValue: '' }),
        userId: (0, _attr.default)('number', { defaultValue: 0 }),
        memberSince: (0, _attr.default)('string', { defaultValue: '' }),
        customerSince: (0, _attr.default)('string', { defaultValue: '' }), //date since user is a customer of the advisor.
        lastContactDate: (0, _attr.default)('string', { defaultValue: '' }), //date when consumer last contacted advisor.
        totalEarningsForAdvisor: (0, _attr.default)('string', { defaultValue: '' }),
        avgFeedbackToAdvisor: (0, _attr.default)('number', { defaultValue: 0 }),
        totalFeedbacksToAdvisorCount: (0, _attr.default)('number', { defaultValue: 0 }),
        note: (0, _attr.default)('string', { defaultValue: '' }),
        consumerDetailsPageUrl: (0, _attr.default)('string', { defaultValue: '' }),
        hasDoneChatRechargeInThePast: (0, _attr.default)('boolean', { defaultValue: false })
    });
});