define('ingenio-web/mixins/checkout-flow/chat-availability-helper', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        checkChatAvailability: function checkChatAvailability(advisorId) {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, _) {
                _this.store.findRecord('advisor-availability-for-customer', Math.random(), { //we use random there to workaround caching issue
                    adapterOptions: {
                        advisorId: advisorId
                    },
                    reload: true
                }).then(function (availability) {
                    var chatAvailability = availability.get('advisorChatAvailability.availability');

                    if (chatAvailability === 'Available') {
                        resolve({ available: true, chatAvailability: chatAvailability });
                    } else {
                        resolve({ available: false, chatAvailability: chatAvailability });
                    }
                });
            });
        }
    });
});