define('ingenio-web/adapters/user-phone', ['exports', 'ember-data', 'ingenio-web/adapters/application'], function (exports, _emberData, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        // this fixes a JSON parse error due to a response type mismatch when deleting
        // a user's phone number. this can be removed after the phone api is refactored
        // to return the correct empty json response.
        ajaxOptions: function ajaxOptions(url, type, options) {
            var hash = this._super.apply(this, arguments);

            if (type === 'DELETE') {
                hash.dataType = 'text';
            }

            return hash;
        },
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            var filters = query;

            if (!filters && snapshot && snapshot.filters) {
                filters = snapshot.filters;
            }

            if (!filters && snapshot && snapshot.adapterOptions) {
                filters = snapshot.adapterOptions.filters;
            }

            return this.getApiUrlWithFilters(filters || [], id);
        },


        // this helper is also utilized in special-offer... consider if it is needed long-term
        // and move into a helper method if so.
        getApiUrlWithFilters: function getApiUrlWithFilters(filters, id) {
            var apiUrl = Ember.ENV.getApiRoot() + '/users/' + Ember.Application.user.id + '/phones';

            if (id) {
                apiUrl += '/' + id;
            }

            if (!filters) {
                return apiUrl;
            }

            var keys = Object.keys(filters);
            var queryParams = keys.map(function (key) {
                return key + '=' + encodeURIComponent(filters[key]);
            }).join('&');

            return apiUrl + '?' + queryParams;
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            if (payload && payload.errors && Object.keys(payload.errors).length) {
                // TODO: this structure may not fully support field-level validation.
                // verify and refactor as needed to align with ingenio api standards.
                var errorsCollection = Object.keys(payload.errors).map(function (key) {
                    return {
                        status: 400,
                        source: {
                            pointer: key
                        },
                        detail: payload.errors[key][0]
                    };
                });

                return new _emberData.default.InvalidError(errorsCollection);
            }

            return this._super.apply(this, arguments);
        }
    });
});