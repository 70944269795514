define('ingenio-web/components/chat/recharge-pause-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            end: function end() {
                this.get('onEnd')();
            }
        }
    });
});