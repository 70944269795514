define('ingenio-web/adapters/experiment-bucket', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'experiments/buckets';
        },

        createRecord: function createRecord(_, type, snapshot) {
            var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

            // the request payload and the response payload differ for this model type.
            var _snapshot$record = snapshot.record,
                userId = _snapshot$record.userId,
                tempUid = _snapshot$record.tempUid,
                domainId = _snapshot$record.domainId,
                activeBuckets = _snapshot$record.activeBuckets;

            var data = { userId: userId, tempUid: tempUid, domainId: domainId, activeBuckets: activeBuckets };

            return this.ajax(url, 'POST', { data: data }).then(function (response) {
                return {
                    experimentBucket: {
                        id: 1,
                        activeBuckets: response.activeBuckets,
                        inactiveBuckets: response.inactiveBuckets,
                        experimentOffer: response.experimentOffer,
                        mergedProperties: response.mergedProperties,
                        mergedSettings: response.mergedSettings
                    }
                };
            });
        }
    });
});