define('ingenio-web/arrange-conversation/payment-details/route', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/route', 'ingenio-web/utils/footer-types'], function (exports, _route, _footerTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    defineFooterType: function defineFooterType() {
      if (Ember.ENV.domainId === 6) {
        return _footerTypes.default.MINIMAL;
      }
      return _footerTypes.default.FULL;
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var model = this.modelFor(this.getParentRouteName());

      // just in case confirm that user got to the page from the flow
      if (!model.get("userOperation")) {
        if (!model.get("advisorConversationRequest")) {
          this.replaceWith(this.getParentRouteName() + ".details", {
            queryParams: transition.queryParams
          });
        } else {
          this.replaceWith(this.getParentRouteName() + ".confirmation", {
            queryParams: transition.queryParams
          });
        }
      }
    },
    skipDetailsRedirect: function skipDetailsRedirect() {
      return true;
    }
  });
});