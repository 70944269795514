define('ingenio-web/models/user-phone', ['exports', 'lodash', 'ember-data/attr', 'ingenio-web/models/model-base'], function (exports, _lodash, _attr, _modelBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        USPhoneRegex: /^[0-9]{10}$/i,
        FormattedUSPhoneRegex: /^(\([0-9]{3}\)|[0-9]{3}-) [0-9]{3}-[0-9]{4}$/i,
        InternationalPhoneRegex: /^[0-9]{6,}$/i,
        InternationalPhoneCountryRegex: /^[0-9]{1,}$/i,

        userId: (0, _attr.default)('number'),
        phoneType: (0, _attr.default)('number', { defaultValue: 2 }), // 2 - mobile
        isPrimary: (0, _attr.default)('boolean', { defaultValue: true }),
        isNumberValid: (0, _attr.default)('boolean', { defaultValue: true }),
        numberInvalidReason: (0, _attr.default)(''),
        isUSNumber: (0, _attr.default)('boolean', { defaultValue: true }),

        number: (0, _attr.default)('string', { defaultValue: '' }),
        extension: (0, _attr.default)('string', { defaultValue: '' }),
        countryCode: (0, _attr.default)('string', { defaultValue: '1' }),

        serverPhone: (0, _attr.default)('string', { defaultValue: '' }),
        serverPhoneNumber: (0, _attr.default)('string', { defaultValue: '' }),
        serverCountryCode: (0, _attr.default)('string', { defaultValue: '' }),

        phoneTypes: [{ id: 0, text: 'work' }, { id: 1, text: 'home' }, { id: 2, text: 'mobile' }],

        isSmsNotificationEnabled: (0, _attr.default)('boolean', { defaultValue: false }),
        isPromotionalSmsEnabled: (0, _attr.default)('boolean', { defaultValue: false }),

        didLoad: function didLoad() {
            if (this.get('isNumberValid')) {
                this.assignServerPhone();
            }
        },

        didUpdate: function didUpdate() {
            if (this.get('isNumberValid')) {
                this.assignServerPhone();
            }
        },

        didCreate: function didCreate() {
            if (this.get('isNumberValid')) {
                this.assignServerPhone();
            }
        },

        formattedNumber: function () {
            var phone = this.get('number').replace(/[^0-9]/g, '').trim();
            var countryCode = this.get('countryCode');
            var isUSNumber = this.get('isUSNumber');

            var result = phone;

            if (isUSNumber) {
                if (result.length === 10) {
                    result = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
                }
            } else {
                result = '+' + countryCode + ' ' + phone;
            }

            return result;
        }.property('isUSNumber', 'type', 'number', 'countryCode'),

        phoneTypeStr: function () {
            var phoneType = this.get('phoneType');
            var mtype = _lodash.default.find(this.phoneTypes, { id: phoneType });

            if (mtype) {
                return mtype.text;
            }
            return '';
        }.property('phoneType'),

        handleChangeLocality: function () {
            // assign 1 to US country code
            if (this.get('isUSNumber')) {
                this.set('countryCode', '1');
            }
        }.observes('isUSNumber'),

        validateNumber: function () {
            this.removeErrors('number');

            var number = (this.get('number') || '').replace('_', '').trim();

            // required field
            if (number === '') {
                this.addError('number', 'Please provide phone number');
                return;
            }

            // number format
            if (this.get('isUSNumber')) {
                if (!this.USPhoneRegex.test(number) && !this.FormattedUSPhoneRegex.test(number)) {
                    this.addError('number', 'Incorrect format of user phone');
                }
            } else if (!this.InternationalPhoneRegex.test(number) && !this.FormattedUSPhoneRegex.test(number)) {
                this.addError('number', 'Incorrect format of user phone');
            }
        }.observes('number', 'isUSNumber'),

        validateCountryCode: function () {
            this.removeErrors('countryCode');

            var countryCode = (this.get('countryCode') || '').replace('_', '').trim();

            // required field
            if (countryCode === '') {
                this.addError('countryCode', 'Please provide country code');
                return;
            }

            if (this.get('isUSNumber')) {
                if (countryCode !== '1') {
                    this.addError('countryCode', 'Incorrect format of country code for US phone');
                }
            } else if (!this.InternationalPhoneCountryRegex.test(countryCode)) {
                this.addError('countryCode', 'Incorrect format of country code');
            }
        }.observes('countryCode', 'isUSNumber'),

        // global validation method will be executed on init
        validate: function () {
            this.validateNumber();
            this.validateCountryCode();
        }.on('init'),

        assignServerPhone: function assignServerPhone() {
            this.set('serverPhone', this.get('formattedNumber'));
            this.set('serverPhoneNumber', this.get('number'));
            this.set('serverCountryCode', this.get('countryCode'));
        }
    });
});