define('ingenio-web/routes/chat/consumer-session', ['exports', 'ingenio-web/utils/pubnub-client', 'ingenio-web/utils/key-press-notifier', 'ingenio-web/utils/chat-duration-manager-v2', 'ingenio-web/utils/logger', 'ingenio-web/utils/header-types', 'ingenio-web/objects/chat/end-reasons', 'ingenio-web/mixins/visibility-handler', 'ingenio-web/helpers/fbm-user'], function (exports, _pubnubClient, _keyPressNotifier, _chatDurationManagerV, _logger, _headerTypes, _endReasons, _visibilityHandler, _fbmUser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_visibilityHandler.default, {
        logger: null,
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.EMPTY;
        },
        previousSessionTranscriptLoader: Ember.inject.service('previous-session-transcript-loader'),

        model: function model(params) {
            return this.store.findRecord('chatSession', params.session_id);
        },


        //for non-primary model data
        nonPrimaryModelData: {
            timeLimit: {},
            listingCategoryName: {},
            listingCategoryId: {},
            listingMinuteRate: {},
            priceBuckets: [],
            orderCredits: []
        },

        makeLogoClickable: function makeLogoClickable() {
            this.controller.set('logoLinkActive', true);
        },


        actions: {
            didTransition: function didTransition() {
                var _this = this;

                var sessionId = this.controller.get('sessionId');
                var userId = Ember.Application.user.id;
                var apiRoot = Ember.ENV.getApiRoot();
                var url = apiRoot + '/users/' + userId + '/chat/sessions/' + sessionId;
                var sessionEnded = this.controller.get('sessionEnded');

                window.addEventListener('unload', function () {
                    if (!navigator.sendBeacon) {
                        return;
                    }
                    //we are in the middle of ending chat session which probably will be canceled
                    //becaseu started in same time page was closing
                    var ending = _this.controller.get('ending');

                    if (!_this.get('sendBeaconStarted') && (!sessionEnded || ending)) {
                        _this.set('sendBeaconStarted', true);
                        navigator.sendBeacon(url);
                    }
                }, true);

                return true; // Bubble the didTransition event
            },


            willTransition: function willTransition(transition) {
                var chatting = this.controller.get('chatting');

                if (chatting) {
                    if (!confirm( //eslint-disable-line no-alert
                    'Navigating away will end your session. Do you want to continue?')) {
                        transition.abort();
                    } else {
                        this.controller.send('endSessionOnBackButtonClick');
                        alert('Session ended'); //eslint-disable-line no-alert
                    }
                }
            }
        },

        afterModel: function afterModel(model) {
            var _this2 = this;

            var consumerId = Number(model.get('chatConsumer.userId'));
            var userId = Number(Ember.Application.user.id);
            var sessionId = Number(model.get('id'));

            if (consumerId !== userId) {
                throw new Error('incorrect request');
            }
            (0, _fbmUser.setFbmUserVar)();

            var loggerOptions = {
                consumerId: consumerId,
                sessionId: sessionId,
                source: 'ConsumerChat'
            };
            var logger = new _logger.default(this.store, loggerOptions);

            this.set('logger', logger);

            //model is chat-session

            if (!model.get('uuid')) {
                model.set('uuid', _pubnubClient.default.guid());
            }

            this.get('logger').log('In consumer-session afterModel. chat channel name: ' + model.get('chatChannel.channelName') + ' uuid: ' + model.get('uuid'));

            var apiUrl = Ember.ENV.getApiRoot();

            var promises = {
                listing: this.store.findRecord('listing-v2', model.get('listingId')),
                priceBuckets: this.store.query('priceBucket', { listingId: model.get('listingId') }),
                experiments: Ember.$.getJSON(apiUrl + '/experiments'),
                buckets: Ember.$.post(apiUrl + '/experiments/buckets', {
                    identifier: 'NEW_USER_PRICE_BUCKET_TEST',
                    domainId: Ember.ENV.domainId,
                    tempUid: window.ENV.keenUid
                }),
                userOffer: this.store.queryRecord('specialOffer', { listingId: model.get('listingId') }),
                orderCredits: Ember.ENV.domainId === 1 && model.get('status') === 'Ended' ? Ember.$.getJSON(apiUrl + '/orders/chat/' + sessionId + '/credit').then(function (result) {
                    return result.data && result.data.credits;
                }) : null
            };

            return Ember.RSVP.hash(promises).then(function (payload) {
                var listingData = payload.listing;

                var timeLimit = _this2.store.createRecord('timeLimit');

                var serviceRate = listingData.get('serviceRateAmount');

                var discountedMinuteRate = Math.round(serviceRate * 100) / 100;

                var discountRewardType = 6;
                var userOffer = payload.userOffer;
                if (userOffer && userOffer.get('rewardType') === discountRewardType) {
                    var rewardAmount = userOffer.get('rewardAmount');

                    discountedMinuteRate = serviceRate * 100 * (100 - rewardAmount) / 100;
                    discountedMinuteRate = Math.ceil(discountedMinuteRate) / 100;
                }

                timeLimit.set('minuteRate', discountedMinuteRate);

                timeLimit.set('usePrimaryAccount', true);
                _this2.nonPrimaryModelData.timeLimit = timeLimit;

                _this2.nonPrimaryModelData.listingCategoryName = listingData.get('categoryName');
                _this2.nonPrimaryModelData.listingCategoryId = listingData.get('categoryId');
                _this2.nonPrimaryModelData.listingMinuteRate = listingData.get('serviceRateAmountRounded');

                timeLimit.set('hasAddedFundsInPast', Ember.Application.user.hasAddedFundsInPast);

                _this2.nonPrimaryModelData.orderCredits = payload.orderCredits || [];
                _this2.nonPrimaryModelData.priceBuckets = payload.priceBuckets;
                _this2.nonPrimaryModelData.rewards = null;
            });
        },
        setupController: function setupController(controller, model) {
            this.get('logger').log('In consumer-session setupController.');
            controller.set('logoLinkActive', false);
            controller.set('status', 'connecting');
            controller.set('logger', this.get('logger'));
            controller.set('sessionId', Number(model.get('id')));
            controller.set('webtrack', model.get('webtrack'));
            controller.set('model', model);
            controller.set('clickId', model.get('clickId'));
            controller.set('timeLimit', this.nonPrimaryModelData.timeLimit);
            controller.set('listingCategoryName', this.nonPrimaryModelData.listingCategoryName);
            controller.set('listingCategoryId', this.nonPrimaryModelData.listingCategoryId);
            controller.set('listingMinuteRate', this.nonPrimaryModelData.listingMinuteRate);
            controller.set('priceBuckets', this.nonPrimaryModelData.priceBuckets);
            controller.set('orderCredits', this.nonPrimaryModelData.orderCredits);

            controller.set('rewards', this.nonPrimaryModelData.rewards);

            if (!model.get('isValidToStartChat')) {
                this.get('logger').log('Error: Invalid session status or available time. status:' + model.get('status') + ' availableTimeSeconds:' + model.get('availableTimeSeconds'));
                controller.endSession(_endReasons.default.consumerBackButtonClicked);
                return;
            }

            controller.send('showConnectingNotification');

            //setup timers.
            var rechargeTimeLimit = 60;

            controller.set('rechargeTimeLimit', rechargeTimeLimit);

            var isPostPay = model.get('isPostPay');

            controller.set('isPostPay', isPostPay);

            var autoReload = model.get('autoReload');

            controller.set('autoReload', autoReload);

            var timeoutPostWarningThreshold = 180;

            controller.set('idleWarnDuration', timeoutPostWarningThreshold);

            var keyPressNotifier = new _keyPressNotifier.default({
                timeoutPostWarningThreshold: timeoutPostWarningThreshold,
                timeoutWarningThreshold: 3 * 60,
                callbacks: {
                    timeoutImminent: function timeoutImminent(timeoutAfter) {
                        return Ember.run(function () {
                            return controller.send('onSessionIdleTimeoutImminent', timeoutAfter);
                        });
                    },
                    timeout: function timeout() {
                        return Ember.run(function () {
                            return controller.send('onSessionIdleTimeout');
                        });
                    },
                    sendChatState: function sendChatState(state) {
                        return controller.send('sendKeyPressMessage', state);
                    }
                }
            });

            controller.set('keyPressNotifier', keyPressNotifier);

            var cookie = this.get('cookie');
            var isAfqWidget = cookie.getCookie('is-afq-widget') || 'false';
            var chatQuestion = cookie.getCookie('chat-question-text-from-chat-banner');

            var afqWidgetInfo = {
                isEnabled: isAfqWidget.toLowerCase() === 'true',
                question: chatQuestion
            };

            controller.set('afqWidgetInfo', afqWidgetInfo);

            //TODO: Evaluate if below piece of code should go in afterModel hook.
            this.subscribeToChannels(model, controller);

            //this.getPreviousSessionTranscript(model, controller);
            var advisorId = model.get('chatAdvisor.userId');
            var consumerId = model.get('chatConsumer.userId');
            var advisorAvatarUrl = model.get('chatAdvisor.profilePictureUrl');

            this.get('previousSessionTranscriptLoader').getPreviousSessionTranscript(advisorId, consumerId, false, advisorAvatarUrl, this.store).then(function (chatMessages) {
                controller.set('previousChatMessagesWithSameAdvisor', chatMessages.transcript);
                controller.set('hideChatTranscripts', chatMessages.optedOutTranscripts);
            });
        },
        createChatDurationManagerV2: function createChatDurationManagerV2(controller, serverNow, logger) {
            var chatDurationManagerV2 = new _chatDurationManagerV.default({
                rechargeWarningTimeout: 60,
                rechargeTimeout: 60,
                postPayExtendTimeout: 20,

                syncInterval: 5,
                syncDelta: 200,
                serverNow: serverNow,
                logger: logger,

                timerCallbacks: {
                    onRechargeWarning: function onRechargeWarning() {
                        controller.send('sessionTimeoutWarning');
                    },
                    onRecharge: function onRecharge() {
                        controller.send('demandForRecharge');
                    },
                    onRechargeTimeout: function onRechargeTimeout() {
                        controller.send('onRechargeTimeout');
                    },
                    onPostPayExtend: function onPostPayExtend() {
                        controller.send('extendSessionIfPostPayEnabled');
                    }
                }
            });

            var elapsedDurationTimeoutV2 = setInterval(function () {
                var durationV2 = chatDurationManagerV2.durationFormatted();

                Ember.run(function () {
                    return controller.set('elapsedDurationV2', '' + durationV2);
                });
            }, 1000);

            controller.set('chatDurationManagerV2', chatDurationManagerV2);
            controller.set('elapsedDurationTimeoutV2', elapsedDurationTimeoutV2);
        },
        subscribeToChannels: function subscribeToChannels(session, controller) {
            var _this3 = this;

            var chatUserName = session.get('chatConsumer.name') || session.get('chatConsumer.userId');
            //setup system channel
            var systemChannel = session.get('systemChannel');
            var systemChannelCallbacks = {
                setStateFailure: function setStateFailure(message) {
                    return _this3.get('logger').log('Setting Status failed. ' + message);
                },
                subscribe: {
                    success: function success() {
                        return _this3.get('logger').log('systemChannel subscribe success.');
                    },
                    failure: this.onSystemChannelSubscribeFailure.bind(this)
                },
                unsubscribe: {
                    success: function success() {
                        return _this3.get('logger').log('systemChannel unsubscribe success.');
                    }
                },
                messageReceived: function messageReceived(m) {
                    return controller.send('onSystemMessageReceivedV2', m.message);
                }
            };
            var sessionUuid = _pubnubClient.default.guid();
            var systemChannelOptions = {
                channelName: systemChannel.get('channelName'),
                subKey: systemChannel.get('subscribeKey'),
                pubKey: systemChannel.get('publishKey'),
                authKey: systemChannel.get('authKey'),
                uuid: sessionUuid,
                userName: chatUserName,
                userId: session.get('chatConsumer.userId'),
                callbacks: systemChannelCallbacks,
                heartbeatSeconds: 60,
                logger: this.get('logger')
            };
            var systemChannelClient = new _pubnubClient.default(systemChannelOptions);

            controller.set('systemChannelClient', systemChannelClient);
            systemChannelClient.subscribe();

            var logger = this.get('logger');

            var redirect_time = controller.get('model').redirect_time;
            var api_load_time = 0;

            if (redirect_time) {
                api_load_time = Date.now() - redirect_time;
            }

            //setup chat channel
            var chatChannel = session.get('chatChannel');
            var chatChannelCallbacks = {
                setStateFailure: function setStateFailure(message) {
                    return _this3.get('logger').log('Setting Status failed. ' + message);
                },
                subscribe: {
                    success: function success() {
                        _this3.set('needPresencePing', true);
                        _this3.startPresencePing(1);
                        //We are noticing a delay in state-change presence event received by advisor. For some problematic sessions, it's ~10s.
                        //So we are also sending a "ping" message through chat-channel, with the hope that this message has lower latency than presence message.
                        //We'll verify through logs if our theory is correct.

                        //latter we could move onBothPartiesReady to messageReceived to make start of session free from some issues
                        //like when we show customer is ready for chat but advisor is not received presence event
                        // and can't see ready for chat window
                        if (redirect_time) {
                            logger.log('Consumer-Session-Load-Time:Full=' + (Date.now() - redirect_time) + ';Api=' + api_load_time);
                        }

                        controller.send('onBothPartiesReady');
                        //once customer connected we need to clean up channel subscription made during chat init phase
                        if (session.initClieanUp) {
                            session.initClieanUp();
                        }
                    },
                    failure: this.onChatChannelSubscribeFailure.bind(this)
                },

                unsubscribe: {
                    success: function success() {
                        return _this3.get('logger').log('chatChannel unsubscribe success.');
                    }
                },

                timeout: function timeout(presenceMessage) {
                    var clientType = '';

                    if (presenceMessage.data && presenceMessage.data.clientType) {
                        clientType = presenceMessage.data.clientType;
                    } else if (presenceMessage.state && presenceMessage.state.clientType) {
                        clientType = presenceMessage.state.clientType;
                    }

                    if (presenceMessage.action === 'leave' && clientType === 'advisorSession') {
                        controller.send('endSessionOtherPartyEnded');
                    }
                },

                multiOccupancy: function multiOccupancy(presenceMessage) {
                    var clientType = '';

                    if (presenceMessage.data && presenceMessage.data.clientType) {
                        clientType = presenceMessage.data.clientType;
                    } else if (presenceMessage.state && presenceMessage.state.clientType) {
                        clientType = presenceMessage.state.clientType;
                    }

                    var presenceLog = 'presence join message with action: ' + presenceMessage.action + ' and uuid: ' + presenceMessage.uuid + ' and occupancy: ' + presenceMessage.occupancy;

                    if (clientType) {
                        presenceLog += ' and data.clientType: ' + clientType;
                    }

                    _this3.get('logger').log(presenceLog);
                },

                messageReceived: function messageReceived(m) {
                    var message = m.message;

                    if (message.Type === 'presencePing' && message.Body === 'received') {
                        _this3.stopPresencePing();
                        //controller.send('onBothPartiesReady');
                    } else {
                        controller.send('onChatMessageReceived', m);
                    }
                },
                messageDelivered: function messageDelivered(timeToken, type, value) {
                    controller.send('onChatMessageDelivered', timeToken, type, value);
                },
                networkUp: function networkUp() {
                    controller.set('networkUp', true);
                },

                networkDown: function networkDown() {
                    controller.set('networkUp', false);
                }
            };

            var chatChannelOptions = {
                channelName: chatChannel.get('channelName'),
                subKey: chatChannel.get('subscribeKey'),
                pubKey: chatChannel.get('publishKey'),
                authKey: chatChannel.get('authKey'),
                uuid: sessionUuid,
                userName: chatUserName,
                userId: session.get('chatConsumer.userId'),
                callbacks: chatChannelCallbacks,
                heartbeatSeconds: 60,
                restore: { value: true },
                subscribeState: {
                    clientType: 'consumerSession',
                    username: chatUserName
                },
                logger: this.get('logger')
            };
            var chatChannelClient = new _pubnubClient.default(chatChannelOptions);

            var serverNow = chatChannelClient.serverNow.bind(chatChannelClient);

            this.createChatDurationManagerV2(controller, serverNow, this.get('logger'));

            controller.set('chatChannelClient', chatChannelClient);
            this.set('chatChannelClient', chatChannelClient);

            chatChannelClient.subscribe();

            window.addEventListener('beforeunload', function (event) {
                if (_this3.getStatus() === 'Ended') {
                    return null;
                }

                // Cancel the event as stated by the standard.
                event.preventDefault();

                // Chrome requires returnValue to be set.
                event.returnValue = '';
            });

            window.addEventListener('unload', function (event) {
                _this3.endSession(event.type);
            });

            // NOTE: pagehide event is also being used because beforeunload
            // event is not supported on iOS safari and possibly other mobile browsers.
            window.addEventListener('pagehide', function (event) {
                _this3.endSession(event.type);
            });
        },
        startPresencePing: function startPresencePing(n) {
            var _this4 = this;

            if (n <= 0) {
                return;
            }

            if (this.get('needPresencePing')) {
                var chatChannelClient = this.get('chatChannelClient');

                chatChannelClient.sendPresencePingMessage(null, 'ping');

                var presencePingTimeout = setTimeout(function () {
                    return _this4.startPresencePing(n - 1);
                }, 1000);

                this.set('presencePingTimeout', presencePingTimeout);
            }
        },
        stopPresencePing: function stopPresencePing() {
            this.set('needPresencePing', false);
            var presencePingTimeout = this.get('presencePingTimeout');

            if (presencePingTimeout) {
                clearTimeout(presencePingTimeout);
            }
        },
        onSystemChannelSubscribeFailure: function onSystemChannelSubscribeFailure(error) {
            this.get('logger').log('system channel subscribe failure. Pubnub error : ' + JSON.stringify(error));
            this.controller.send('endSessionOnError');
        },
        onChatChannelSubscribeFailure: function onChatChannelSubscribeFailure(error) {
            this.get('logger').log('chat channel subscribe failure. Pubnub error : ' + JSON.stringify(error));
            this.controller.send('endSessionOnError');
        },
        endSession: function endSession(eventType) {
            this.get('logger').log('Ending session based on ConsumerSession event :' + eventType);

            //This is async and so there is no guarantee it'll complete before window closes.
            this.controller.endSession(_endReasons.default.consumerDisconnect);
        },
        getStatus: function getStatus() {
            return this.controller.get('model').get('status');
        }
    });
});