define('ingenio-web/utils/file-download', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        downloadFile: function downloadFile(url) {
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();

                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.withCredentials = true;

                xhr.onload = function () {
                    var httpStatusCode = this.status;
                    var fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

                    if (httpStatusCode === 200) {
                        var fileName = '';
                        var disposition = xhr.getResponseHeader('Content-Disposition');

                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            var matches = fileNameRegex.exec(disposition);

                            if (matches !== null && matches[1]) {
                                fileName = matches[1].replace(/['"]/g, '');
                            }
                        }

                        var contentType = xhr.getResponseHeader('Content-Type');

                        var blob = void 0;

                        if (window.navigator.msSaveBlob) {
                            // IE/Microsoft Edge browser detected.
                            // Use the Blob constructor since File() is not available in some builds of Edge and IE
                            blob = new Blob([this.response], {
                                type: contentType
                            });
                        } else {
                            // Use the File constructor.
                            blob = new File([this.response], fileName, {
                                type: contentType
                            });
                        }

                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
                            // These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, fileName);
                        } else {
                            var URL = window.URL || window.webkitURL;
                            var downloadUrl = URL.createObjectURL(blob);

                            if (fileName) {
                                // use HTML5 a[download] attribute to specify filename
                                var a = document.createElement('a');

                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location = downloadUrl;
                                } else {
                                    a.href = downloadUrl;
                                    a.download = fileName;
                                    document.body.appendChild(a);
                                    a.click();
                                }
                            } else {
                                window.location = downloadUrl;
                            }

                            setTimeout(function () {
                                URL.revokeObjectURL(downloadUrl);
                            }, 100); // cleanup

                            resolve(url);
                        }
                    } else {
                        reject({
                            httpStatusCode: httpStatusCode
                        });
                    }
                };

                return xhr.send();
            });
        }
    };
});