define('ingenio-web/components/arrange-conversation/talk-time-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        additionalFundsAmountRounded: Ember.computed('additionalFundsAmount', function () {
            var additionalFundsAmount = this.get('additionalFundsAmount');

            if (!additionalFundsAmount || isNaN(additionalFundsAmount)) {
                return '0.00';
            }

            return Number(additionalFundsAmount).toFixed(2);
        }),

        talkTimeMinutes: Ember.computed('listingPricePerMinute', 'additionalFundsAmountRounded', 'totalMinutesWithCurrentOffer', function () {
            var listingPricePerMinute = Number(this.get('listingPricePerMinute') || 0);
            var additionalFundsAmount = Number(this.get('additionalFundsAmountRounded') || 0);
            var totalMinutesWithCurrentOffer = Number(this.get('totalMinutesWithCurrentOffer') || 0);
            var mins = totalMinutesWithCurrentOffer + additionalFundsAmount / listingPricePerMinute;

            return Math.floor(Math.round(mins * 10000) / 10000);
        })
    });
});