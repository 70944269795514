define('ingenio-web/routes/featured-listings/bid', ['exports', 'ingenio-web/utils/header-types', 'ingenio-web/models/featured-listings-bid'], function (exports, _headerTypes, _featuredListingsBid) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        defineHeaderType: function defineHeaderType() {
            return _headerTypes.default.FULL;
        },

        model: function model() {
            return _featuredListingsBid.default.create();
        },


        actions: {
            willTransition: function willTransition() {
                this.set('transitionInProgress', true);

                return true;
            },
            didTransition: function didTransition() {
                this.set('transitionInProgress', false);

                return true;
            },
            setCurrentStep: function setCurrentStep(routeName) {
                var model = this.modelFor('featured-listings.bid');

                model.get('steps').setCurrentStep(routeName);
            },
            previousStep: function previousStep() {
                var transitionInProgress = this.get('transitionInProgress');
                var previousStep = this.modelFor('featured-listings.bid').get('steps.previousStep');

                if (!transitionInProgress && previousStep) {
                    this.transitionTo(previousStep.get('route'));
                }
            },
            nextStep: function nextStep() {
                var transitionInProgress = this.get('transitionInProgress');
                var nextStep = this.modelFor('featured-listings.bid').get('steps.nextStep');

                if (!transitionInProgress && nextStep) {
                    this.transitionTo(nextStep.get('route'));
                }
            }
        }
    });
});