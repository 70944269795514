define('ingenio-web/utils/logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  //TODO: Adjust the logger to 1) remove dependency of ember store to be passed when ever its instantiated, 2) support for passing error object so that we can append the error stack

  function mapMessageType(messageType) {
    switch (messageType ? messageType.toLowerCase() : messageType) {
      case 'info':
        return 'Information';
      case 'error':
        return 'Error';
      default:
        break;
    }
  }

  function getMixpanelInfoProperties() {
    try {
      return mixpanel._.info.properties();
    } catch (error) {
      var errorDescription = 'ERROR: ' + error;

      return {
        $browser: errorDescription,
        $browser_version: errorDescription,
        $os: errorDescription
      };
    }
  }

  function LogToSplunk(originalMessage, messageType) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      userId: Ember.Application.user ? Ember.Application.user.id : 0,
      escalationType: 'NonCritical',
      source: 'Web'
    };
    var store = arguments[3];

    try {
      var errorShouldBeignored = false;
      var sentryConfig = Ember.ENV['@sentry/ember'];

      sentryConfig.ignoreErrors.forEach(function (regex) {
        if ((typeof regex === 'undefined' ? 'undefined' : _typeof(regex)) !== 'object' && originalMessage.match(new RegExp(regex))) {
          console.error('ignored: ' + originalMessage);
          errorShouldBeignored = true;
          return;
        }
      });

      if (errorShouldBeignored) {
        return;
      }

      var mixpanelInfoProperties = getMixpanelInfoProperties();

      var logApiV2 = {
        Message: originalMessage,
        'Platform Component': 'ClientSide',
        'Escalation Type': data.escalationType || (messageType == 'Error' ? 'Critical' : 'NonCritical'),
        'Client Time': moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        Type: mapMessageType(messageType),
        'User ID': data.userId || (data.consumerId ? data.consumerId : data.advisorId),
        Source: data.source, //Web|Android-App|IOS-App
        'User Type': data.consumerId ? 'Consumer' : data.advisorId ? 'Advisor' : 'Unknown',
        SessionId: data.sessionId,
        callId: data.callId,
        'Full URL': window.location.href.replace(window.location.search, ''),
        'Query String': window.location.search,
        'User Agent': navigator.userAgent,
        Browser: mixpanelInfoProperties.$browser,
        'Browser Version': mixpanelInfoProperties.$browser_version,
        'Operating System': mixpanelInfoProperties.$os
        //'Cookie Collection': document.cookie.replace(new RegExp('SessionId=([^;]+)', 'g'), 'SessionId=xxx')
      };

      if (mixpanelInfoProperties.$device) {
        logApiV2.Device = mixpanelInfoProperties.$device;
      }

      var url = Ember.ENV.getLogApiRoot() + '/v2/logs';

      $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ event: logApiV2 }),
        error: function error(reason) {
          console.error(reason);
        }
      });
    } catch (error) {
      try {
        if (store) {
          var logEntry = store.createRecord('logEntry');
          var message = 'Error durring loggign: ' + error;

          logEntry.set('message', message);
          logEntry.set('type', "Error");
          logEntry.set('consumerId', data.consumerId);
          logEntry.set('advisorId', data.advisorId);

          logEntry.save();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  exports.LogToSplunk = LogToSplunk;

  var Logger = function () {
    function Logger(store, options) {
      _classCallCheck(this, Logger);

      this.debugLog = [];
      this.store = store;
      this.createLogEntry = true; //temporarily disable all log entry creation.
      if (options) {
        this.source = options.source;
        this.consumerId = options.consumerId;
        this.advisorId = options.advisorId;
        this.sessionId = options.sessionId;
        this.callId = options.callId;
      }
    }

    _createClass(Logger, [{
      key: 'hasRecords',
      value: function hasRecords() {
        return this.debugLog.length > 0;
      }
    }, {
      key: 'getLogAndClear',
      value: function getLogAndClear() {
        var debugLog = this.debugLog;

        this.debugLog = [];
        return debugLog;
      }
    }, {
      key: 'log',
      value: function log(originalMessage) {
        return this._logMessage(originalMessage, 'Info');
      }
    }, {
      key: 'error',
      value: function error(originalMessage, options) {
        var detailedErrorMessage = '';

        if (options && options.originalError) {
          detailedErrorMessage += ' \\n ' + this._getDetailedErrorString(options.originalError);
        }
        return this._logMessage(originalMessage + detailedErrorMessage, 'Error');
      }
    }, {
      key: 'setSessionId',
      value: function setSessionId(sessionId) {
        this.sessionId = sessionId;
      }
    }, {
      key: 'setCallId',
      value: function setCallId(callId) {
        this.callId = callId;
      }
    }, {
      key: '_logMessage',
      value: function _logMessage(originalMessage, messageType) {
        var message = new Date().toISOString() + ' - ' + originalMessage;

        LogToSplunk(originalMessage, messageType, this, this.store);

        this.debugLog.push(message);

        console.log(message); //eslint-disable-line no-console
      }
    }, {
      key: '_prepareLogEntry',
      value: function _prepareLogEntry(logEntry, message, messageType) {
        logEntry.set('message', message);
        logEntry.set('type', messageType);

        if (this.source) {
          logEntry.set('source', this.source);
        }
        if (this.consumerId) {
          logEntry.set('consumerId', this.consumerId);
        }
        if (this.advisorId) {
          logEntry.set('advisorId', this.advisorId);
        }
        if (this.sessionId) {
          logEntry.set('sessionId', this.sessionId);
        }
        if (this.callId) {
          logEntry.set('callId', this.callId);
        }
      }
    }, {
      key: '_getDetailedErrorString',
      value: function _getDetailedErrorString(originalError) {
        /*
        The reason that we are not just using json.stringify() is due to the reason the originalError comes in various format some in json format some in string.
        Also while passing the prop names to the json.stringify(), if any of the prop is of type object it doesnt go to next level of props. 
        Hence we are adding the prop names under the "errors" collection.
         */
        return JSON.stringify(originalError, originalError.errors ? Object.keys(originalError).push(Object.keys(originalError.errors)) : Object.keys(originalError));
      }
    }]);

    return Logger;
  }();

  exports.default = Logger;
});