define('ingenio-web/models/advisor-chat-availability', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        availability: (0, _attr.default)('string'),
        availabilityUpdateSource: (0, _attr.default)('string')
    });
});