define("ingenio-web/appointment/error/controller", ["exports", "ingenio-web/arrange-conversation/controller", "ingenio-web/mixins/scroll-helpers", "ingenio-web/appointment/step-helpers"], function (exports, _controller, _scrollHelpers, _stepHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
        queryParams: ['code'],
        errorMessage: '',
        errorDescription: '',
        displayReturnToMyKeenButton: false,
        tryAgain: false,
        tryAgainUrl: Ember.computed('listingid', 'webtrack', function () {
            return Ember.ENV.urls.nextJSRoot + "/appointment/time-selection?listingid=" + this.get('listingid') + "&webtrack=" + this.get('webtrack');
        }),

        init: function init() {
            this._super.apply(this, arguments);
        },


        errorObserver: Ember.observer('code', function () {
            var code = this.get('code');
            var genericErrorMessage = 'Sorry! Something Went Wrong.';
            var timeConflictMessage = 'Sorry! That Appointment Time Is Unavailable.';
            var message = '';
            var description = '';
            var returnToMyKeen = false;
            var tryAgain = false;
            var redirect = false;

            switch (code) {
                case 'NotAcceptingAppointments':
                    message = 'Advisor is not accepting Appointments';
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'ProviderIsOnConsumersBlockedList':
                    // redirect
                    message = genericErrorMessage;
                    returnToMyKeen = false;
                    tryAgain = false;
                    redirect = true;
                    break;
                case 'ConsumerIsOnProvidersBlockedList':
                    // redirect
                    message = genericErrorMessage;
                    returnToMyKeen = false;
                    tryAgain = false;
                    redirect = true;
                    break;
                case 'ProviderHasNoPhoneNumber':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'ProviderPhoneNumberIsBlocked':
                    // no redirect
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    redirect = false;
                    break;
                case 'ConsumerPhoneNumberIsBlocked':
                    // no redirect
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    redirect = false;
                    break;
                case 'ReScheduleExistingAppointmentFailed':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'AppointmentStartAlreadyPassed':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'AppointmentStartEarlierThanThreshold':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'AppointmentStartLaterThanThreshold':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'ProviderHasAcceptedAnotherAppointmentForThisTime':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'ConsumerHasAcceptedAnotherAppointmentForThisTime':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'AppointmentTimeIsInvalid':
                    message = timeConflictMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'FreeAppointmentNotAvailable':
                    message = genericErrorMessage;
                    returnToMyKeen = false;
                    tryAgain = true;
                    break;
                case 'ConsumerHasNoPhoneNumber':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'AccessDenied':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'ServiceIsInvalid':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'UsersDomainsAreIncompatible':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'ConsumerAccountIsInactive':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'ProviderAccountIsInactive':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'UnknownError':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
                case 'CannotScheduleAppointmentToOneself':
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;

                default:
                    message = genericErrorMessage;
                    returnToMyKeen = true;
                    tryAgain = false;
                    break;
            }

            this.set('errorMessage', message);
            this.set('errorDescription', description);
            this.set('displayReturnToMyKeenButton', returnToMyKeen);
            this.set('tryAgain', tryAgain);

            // handle case redirect 
            if (redirect) {
                var ec = '';
                if (code === 'ConsumerIsOnProvidersBlockedList') {
                    ec = 'blocked-customer';
                }
                if (code === 'ProviderIsOnConsumersBlockedList') {
                    ec = 'blocked-advisor';
                }

                var url = "/app/error?error_code=" + ec;
                window.location.href = url;
            }
            return;
        })
    });
});