define('ingenio-web/arrange-conversation/sms/controller', ['exports', 'ingenio-web/arrange-conversation/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    queryParams: ["listingid", "pmode", "webtrack", 'clickid', "arrange", "r", "ctype"],

    arrangeConversation: Ember.inject.controller(),
    steps: Ember.computed.alias("arrangeConversation.steps"),

    update: 1,
    returnUrl: null,

    optionalTitle: function () {
      if (this.get('model.isChatConversation')) return "Enable SMS to get notified for the arranged chat.";
      return "Enable SMS to get notified for the arranged call.";
    }.property('model.isChatConversation'),

    actions: {
      onBack: function onBack() {
        var _this = this;

        this.clearErrors();

        this.set("isBusy", true);

        var smsNotificationEnabled = this.get('smsNotificationEnabled');
        if (smsNotificationEnabled) {
          this.set('model.user.notifications.viaSms.enabled', smsNotificationEnabled);
        }

        if (this.get("model.isSmsPageRequired")) {
          var route = this.getParentRouteName() + ".details";
          this.transitionToRouteWithCurrentParams(route);
          this.set("isBusy", false);
        } else {
          if (this.get("model.isAddPaymentMethodRequired")) {
            this.set("isBusy", false);
            // user need to add payment method
            this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".payment-details");
          } else if (this.get("model.isAddFundsRequired")) {
            this.set("isBusy", false);
            this.transitionToRouteWithCurrentParams(this.getParentRouteName() + ".add-funds");
          } else if (this.get("model.canArrangeConversation")) {
            this.arrangeConversationRequest().then(function (arrangedConversation) {
              _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + ".confirmation");
            }).catch(function (error) {
              var route = _this.getParentRouteName() + ".details";
              _this.transitionToRouteWithCurrentParams(route);
            }).finally(function () {
              _this.set("isBusy", false);
            });
          } else {
            this.set("isBusy", false);
            var _route = this.getParentRouteName() + ".details";
            this.transitionToRouteWithCurrentParams(_route);
          }
        }
      }
    },
    onBack: Ember.computed(function () {
      var me = this;
      return function () {
        me.actions.onBack.call(me);
      };
    })
  });
});