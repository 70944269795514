define('ingenio-web/components/payment-method/list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hideApplePay: false, //passed in (used to hide apple pay payment method)
    classNames: ['payment-method-list-container'],
    applePay: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.initializeDefaultPaymentMethod();
    },


    filteredPaymentMethods: Ember.computed('attrs.paymentMethods.@each', 'hideApplePay', function () {
      var paymentMethodsWithoutApplePay = this.get('attrs.paymentMethods').filter(function (method) {
        return method.get('chargeAccountType') !== 3;
      });
      return paymentMethodsWithoutApplePay;
    }),

    maximumPaymentMethodsReached: Ember.computed('attrs.paymentMethods.@each', function () {
      return Number(this.get('attrs.paymentMethods.length')) >= Number(Ember.ENV.maximumNumberOfPaymentMethods);
    }),

    maximumPayPalAccountsReached: Ember.computed('attrs.paymentMethods.@each', function () {
      var paypalAccounts = this.get('attrs.paymentMethods').filter(function (method) {
        return method.get('chargeAccountType') === 1;
      });

      return Number(paypalAccounts.get('length')) >= Number(Ember.ENV.maximumNumberOfPayPalAccounts);
    }),

    actions: {
      onPaymentMethodChosen: function onPaymentMethodChosen(paymentMethodId) {
        this.attrs.onPaymentMethodChosen(paymentMethodId, false);
      },
      onSetAsDefault: function onSetAsDefault(value) {
        // this.set('setSelectedPaymentAsDefault', value);
        // if (this.attrs.onPaymentMethodChosen) {
        //   this.attrs.onPaymentMethodChosen(
        //     this.get('selectedPaymentId'),
        //     this.get('setSelectedPaymentAsDefault')
        //   );
        // }
        // console.log('list : onSetAsDefault' + value);
      }
    },

    initializeDefaultPaymentMethod: function initializeDefaultPaymentMethod() {
      // let primaryPayment = this.paymentMethods.find(payment =>
      //   payment.get('isPrimary')
      // );
      // if (primaryPayment) {
      //   this.send('onPaymentMethodChosen', primaryPayment.get('id'));
      // }
      //TODO: Muthu - check if there is a scenario where the default payment is not set when there are more than 1 payment methods on file
    }
  });
});