define('ingenio-web/components/featured-listings/results/listings-export', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var exportMapper = Ember.Object.extend({
        columnsMap: [],

        mapColumn: function mapColumn(jsonPropety, title) {
            this.get('columnsMap').push(Ember.Object.create({
                jsonProperty: jsonPropety,
                title: title
            }));

            return this;
        },
        getColumns: function getColumns() {
            return this.get('columnsMap');
        }
    });

    exports.default = Ember.Component.extend({
        bidResults: null,
        exportMap: exportMapper.create(),

        bidResultsMapped: Ember.computed('bidResults', 'exportMap.columnsMap.@each', function () {
            var mappedData = [];
            var bidResults = this.get('bidResults');
            var columnMap = this.get('exportMap').getColumns();

            // Push report header titles for each mapped column.
            mappedData.push(columnMap.map(function (column) {
                return column.title;
            }));

            // Push results into string arrays based on map configuration.
            bidResults.forEach(function (result) {
                mappedData.push(columnMap.map(function (column) {
                    var jsonProperty = column.get('jsonProperty');

                    return result.get(jsonProperty) || '---';
                }));
            });

            return mappedData;
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.get('exportMap').mapColumn('listingCategory', 'Listing Category').mapColumn('currentPositionInCategory', 'Current Position').mapColumn('bidAmount', 'Current Bid').mapColumn('revenueFromFlClicks', 'Earnings From Clicks').mapColumn('totalClicks', 'Total Clicks').mapColumn('totalChargedClicks', 'Charged Clicks').mapColumn('averageClickCharge', 'Avg. Click Charge').mapColumn('totalClickCharge', 'FL Charge Amount');
        },


        actions: {
            export: function _export() {
                var bidResultsMapped = this.get('bidResultsMapped');

                this.get('excel').export(bidResultsMapped, { sheetName: 'BidResults', fileName: 'bidResults.xlsx' });
            }
        }
    });
});