define('ingenio-web/models/featured-listings-bid', ['exports', 'ingenio-web/utils/featured-listings-bid-steps'], function (exports, _featuredListingsBidSteps) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        bid: null,
        steps: _featuredListingsBidSteps.default.create(),
        listing: {
            id: null,
            title: null,
            category: null
        },
        expirationType: 'no-expiration',
        cancelBid: false,

        resetModel: function resetModel() {
            this.set('bid', null);
            this.set('steps', _featuredListingsBidSteps.default.create());
            this.set('listing.id', null);
            this.set('listing.title', null);
            this.set('listing.category', null);
            this.set('cancelBid', false);
        }
    });
});