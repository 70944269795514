define('ingenio-web/call-setup/payment-details/controller', ['exports', 'ingenio-web/mixins/checkout-flow/payment-details/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    callSetup: Ember.inject.controller(),
    steps: Ember.computed.alias('callSetup.steps')
  });
});