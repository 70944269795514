define('ingenio-web/appointment/confirmation/controller', ['exports', 'ingenio-web/arrange-conversation/controller', 'ingenio-web/mixins/scroll-helpers', 'ingenio-web/appointment/step-helpers'], function (exports, _controller, _scrollHelpers, _stepHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
    appointment: Ember.inject.controller(),
    secondaryButtonBusy: false,
    showLeaveWaitlistConfirmationDialog: false,
    agreeToTerms: false,
    displayAgreeToTermsCheckbox: true,

    enablePrimaryButton: Ember.computed('model.accountBalanceWithExtraFundsMeetsMinimum', 'primaryButtonBusy', 'phonesValid', 'model.user.funds.paymentMethodId', 'model.user.paymentMethods', function () {
      var paymentMethodId = this.get('model.user.funds.paymentMethodId');
      var paymentMethod = this.get('model.user.paymentMethods').findBy('id', paymentMethodId);

      if (!paymentMethodId || !paymentMethod || paymentMethod.get('isExpired')) {
        return false;
      }

      if (this.get('primaryButtonBusy') || !this.get('phonesValid')) {
        return false;
      }

      return true;
    }),

    disableRequestOrRescheduleButton: Ember.computed('enablePrimaryButton', 'agreeToTerms', 'primaryButtonBusy', 'displayAgreeToTermsCheckbox', function () {
      if (this.get('primaryButtonBusy')) {
        return true;
      }
      if (this.get('displayAgreeToTermsCheckbox') && this.get('agreeToTerms') === false) {
        return true;
      }
      this.get('enablePrimaryButton') ? false : true;
    }),

    hideSteps: Ember.computed.equal('steptype', '1'),

    durationFormatted: Ember.computed('model.appointmentType.sessionPrice.chargeDurationInMinutes', function () {
      var totalMins = Number(this.get('model.appointmentType.sessionPrice.chargeDurationInMinutes')) || 0;

      var minutesToHour = function minutesToHour(totalMinutes) {
        if (!totalMinutes) return 0 + ' min';

        var hours = Math.floor(totalMinutes / 60) || 0;
        var minutes = totalMinutes % 60 || 0;

        var h = hours > 0 ? hours.toFixed() + ' hr' : '';
        var m = minutes > 0 ? minutes.toFixed() + ' min' : '';

        return (h + ' ' + m).trim();
      };

      return minutesToHour(totalMins);
    }),

    isPP: Ember.computed('model.listing.categoryId', function () {
      var listingCategoryId = this.get('model.listing.categoryId');
      return listingCategoryId === 17101;
    }),

    actions: {
      changeTime: function changeTime() {
        var url = Ember.ENV.urls.nextJSRoot + '/appointment/time-selection';
        this.transitionToUrlWithCurrentParams(url);
      },
      changeSurveyForm: function changeSurveyForm() {
        var url = Ember.ENV.urls.nextJSRoot + '/appointment/survey';
        this.transitionToUrlWithCurrentParams(url);
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        this.clearErrors();

        var url = Ember.ENV.getApiRoot() + '/appointments';
        var apptId = this.get('apptid');

        this.set('primaryButtonBusy', true);

        var data = {
          listingId: this.get('listingid'),
          consumerId: Ember.Application.user.id,
          appointmentStartTime: moment(new Date(this.get('time'))).format('YYYY-MM-DDTHH:mm:ss'),
          appointmentTypeId: this.get('apttypeid'),
          appointmentDuration: this.get('aptduration'),
          surveyResponseId: this.get('survrespid'),
          useSessionBasedPricing: !this.get('aptduration')
        };

        if (apptId) {
          data.isRescheduleFlow = true;
          data.rescheduledAppointmentId = apptId;
        }

        if (data.surveyResponseId > 0) {
          //track only for first session-based pricing
          this.get('userEvents').firstScheduledSession('First Scheduled Session');

          this.get('mixpanelClient').trackNewEvent('First Scheduled Session', {
            eventData: {
              listing_id: data.listingId,
              appointment_type_id: data.appointmentTypeId,
              is_session_Pricing: data.useSessionBasedPricing
            }
          });
        }

        Ember.$.post(url, data).done(function () {
          _this.set('primaryButtonBusy', false);
          _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.success');
        }).fail(function (error) {
          var params = { code: error.responseJSON.code };
          _this.set('primaryButtonBusy', false);
          _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.error', params);
        });
      },
      onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
        // this.set('phonesInMultiEditMode', true);
        // this.startEditPhones();
        // return;
        this.set('phonesInMultiEditMode', false);
        this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.phone-number');

        this.scrollToElementOnMobileViewWithDelay('.confirmation-container');
      },
      onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
        this.cancelEditPhones();
        this.set('phonesInMultiEditMode', false);
      },
      onPhoneNumberChange: function onPhoneNumberChange(phones) {
        this.set('phones', phones);
      },
      onPhoneNumbersValidityChange: function onPhoneNumbersValidityChange(validationState) {
        this.set('phonesValid', validationState.valid);
      },
      changePaymentMethod: function changePaymentMethod() {
        this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.payment-details');

        this.scrollToElementOnMobileViewWithDelay('.payment-details-container');
      },
      onChangeTextAlertsClick: function onChangeTextAlertsClick() {
        window.location.href = '/MyAccount/UpdatePersonalInfo.aspx?expand=email-notify';
      }
    }
  });
});