define('ingenio-web/components/notes-history-control', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        updatedNoteText: '',
        preservedText: '',
        isEditMode: false,
        actionLocation: '',
        maxCommentLength: 150,
        customerNotes: [],

        initControl: function () {
            this.set('preservedText', this.get('value') || '');
            this.set('isEditMode', false);
            this.set('showFullSearchContainer', false);
            this.actions.refreshNotes(this.get('customerId'), '', this);
        }.on('init'),

        controlStatusCss: function () {
            if (this.get('isEditMode')) {
                return 'edit';
            }
            return 'valid';
        }.property('isEditMode'),

        disableSave: function () {
            if (this.get('isEditMode') || this.get('isAddNoteMode')) {
                return false;
            }
            return true;
        }.property('isEditMode', 'isAddNoteMode'),

        handleUpdatedTextChange: function () {
            var activeNoteEntry = null;
            var updatedValue = '';

            if (this.get('isAddNoteMode')) {
                activeNoteEntry = $(this.element).find('.js_new-note-container');
                updatedValue = this.get('newNoteText');
            }

            if (this.get('isEditMode')) {
                activeNoteEntry = $(this.element).find('.js_note-container.inEdit');
                updatedValue = this.get('updatedNoteText');
            }

            if (!!!activeNoteEntry) {
                return;
            }

            var maxCommentLength = this.get('maxCommentLength');
            if (updatedValue.length >= maxCommentLength) {
                activeNoteEntry.find('.js_error-message').removeClass('hidden');
            } else {
                activeNoteEntry.find('.js_error-message').addClass('hidden');
            }
            this.set('disableSave', !updatedValue || updatedValue.length == 0 || updatedValue.length > maxCommentLength);
        }.observes('updatedNoteText', 'newNoteText', 'isEditMode', 'isAddNoteMode'),

        subscribeForKeyEvents: function subscribeForKeyEvents(textArea, scope, noteId) {
            var that = scope;
            $(textArea).unbind('keydown');
            $(textArea).keydown(function (event) {
                if (event.keyCode == 13 && !(event.shiftKey || event.altKey)) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            });

            $(textArea).unbind('keyup');
            $(textArea).keyup(function (event) {

                function getCaret(el) {
                    if (el.selectionStart) {
                        return el.selectionStart;
                    } else if (document.selection) {
                        el.focus();
                        var r = document.selection.createRange();
                        if (r == null) {
                            return 0;
                        }
                        var re = el.createTextRange(),
                            rc = re.duplicate();
                        re.moveToBookmark(r.getBookmark());
                        rc.setEndPoint('EndToStart', re);
                        return rc.text.length;
                    }
                    return 0;
                }

                if (event.keyCode == 13) {
                    event.stopPropagation();
                    if (event.shiftKey || event.altKey) {
                        var content = this.value;
                        if (event.altKey) {
                            var caret = getCaret(this);
                            this.value = content.substring(0, caret) + '\n' + content.substring(caret, content.length);
                            this.selectionStart = content.substring(0, caret).length + 1;
                        }
                    } else {
                        event.preventDefault();
                        that.actions.save.call(that, [noteId]);
                    }
                }
            });
        },

        handleAddNoteMode: function () {
            var newNoteInput = $(this.element).find('.js_new-note-container').find('textarea');

            if (this.get('isAddNoteMode')) {
                setTimeout(function () {
                    newNoteInput.focus();
                }, 200);
            } else {
                newNoteInput.val('');
            }
        }.observes('isAddNoteMode'),

        handleEditNoteMode: function () {
            var editNoteInput = $(this.element).find('.js_note-container.inEdit').find('textarea');
            if (this.get('isEditMode')) {
                setTimeout(function () {
                    editNoteInput.focus();
                }, 200);
            }
        }.observes('isEditMode'),

        actions: {
            addNote: function addNote() {
                if (this.get('isEditMode')) {
                    return;
                }

                this.set('today', (0, _moment.default)().format('L'));
                this.set('newNoteText', '');
                this.set('isAddNoteMode', true);

                var textarea = $(this.element).find('.js_new-note-container').find('textarea');
                textarea.attr('autocomplete', 'nope');

                this.subscribeForKeyEvents($(this.element).find('.js_new-note-container').find('textarea'), this);
            },
            edit: function edit(noteId) {
                if (this.get('isEditMode') || this.get('isAddNoteMode')) {
                    return;
                }

                var element = $(document.getElementById('note-' + noteId));
                var textElement = $(element).find('.js_view-note');
                var updatedValue = textElement.text().trim();

                element.addClass('inEdit');
                element.find('.js_view-note').addClass('hidden');
                element.find('.js_edit-note').removeClass('hidden');
                this.set('isEditMode', true);

                //disable add note button
                $(document.getElementById('addNoteButton')).addClass('disabled');

                var firstElement = element[0];
                var offset = firstElement.offsetTop - firstElement.parentNode.offsetTop;
                firstElement.parentNode.scrollTop = offset;

                var textarea = element.find('textarea');
                textarea.attr('autocomplete', 'nope');

                this.subscribeForKeyEvents(textarea, this, noteId);

                this.set('updatedNoteText', updatedValue);
                this.set('preservedText', this.get('value'));
                this.set('disableSave', false);
            },
            confirmDelete: function confirmDelete(noteId) {
                this.set('showDeleteConfirmationDialog', true);
                this.set('noteInConfirmation', noteId);
            },
            cancelDelete: function cancelDelete() {
                this.set('showDeleteConfirmationDialog', false);
                this.set('noteInConfirmation', '');
            },
            delete: function _delete() {
                var noteId = this.get('noteInConfirmation');
                if (!noteId) {
                    return;
                }

                var element = $(document.getElementById('note-' + noteId));
                element.removeClass('inEdit');
                element.find('.js_view-note').removeClass('hidden');
                element.find('.js_edit-note').addClass('hidden');
                this.set('isEditMode', false);

                //enable add note button
                $(document.getElementById('addNoteButton')).removeClass('disabled');

                this.set('showDeleteConfirmationDialog', false);

                this.actions.deleteNotes([noteId], this);
                this.actions.refreshNotes(this.get('customerId'), this.get('searchText'), this);
            },
            searchIconClick: function searchIconClick() {
                var isSearch = this.get('showFullSearchContainer');
                if (isSearch) {
                    this.set('isEditMode', false);
                    var searchText = $(this.element).find('#noteTextSearch').val();
                    this.set('searchText', searchText);
                    this.actions.searchNotes(this.get('customerId'), searchText, this);
                } else {
                    this.set('showFullSearchContainer', true);
                }
            },
            closeSearch: function closeSearch() {
                $(this.element).find('#noteTextSearch').val('');
                this.send('searchIconClick');
                this.set('showFullSearchContainer', false);
            },
            save: function save(noteId) {
                if (this.get('disableSave')) {
                    return;
                }

                if (this.get('isAddNoteMode')) {
                    var newNoteText = this.get('newNoteText');
                    this.set('isAddNoteMode', false);
                    this.actions.saveNote(newNoteText, [], this);
                    this.set('newNoteText', '', []);

                    this.set('searchText', '');
                    $(this.element).find('#noteTextSearch').val('');
                    this.actions.refreshNotes(this.get('customerId'), this.get('searchText'), this);
                } else {
                    var element = $(document.getElementById('note-' + noteId));
                    var updatedValue = this.get('updatedNoteText');

                    var notes = this.get('customerNotes');
                    var notesToUpdate = [];
                    for (var i = 0; i < notes.length; i++) {
                        var note = notes[i];

                        if (note.noteId == noteId) {
                            notesToUpdate.push({
                                noteId: note.noteId,
                                note: updatedValue
                            });

                            break;
                        }
                    }
                    this.set('isEditMode', false);
                    element.removeClass('inEdit');
                    element.find('.js_view-note').removeClass('hidden');
                    element.find('.js_edit-note').addClass('hidden');

                    //enable add note button
                    $(document.getElementById('addNoteButton')).removeClass('disabled');

                    this.actions.saveNote('', notesToUpdate, this);
                }
                this.actions.refreshNotes(this.get('customerId'), this.get('searchText'), this);
            },
            cancelEdit: function cancelEdit(noteId) {
                if (!!noteId) {
                    var element = $(document.getElementById('note-' + noteId));
                    element.removeClass('inEdit');
                    element.find('.js_view-note').removeClass('hidden');
                    element.find('.js_edit-note').addClass('hidden');
                    this.set('isEditMode', false);

                    //enable add note button
                    $(document.getElementById('addNoteButton')).removeClass('disabled');
                } else {
                    this.set('isAddNoteMode', false);
                }
                this.actions.refreshNotes(this.get('customerId'), this.get('searchText'), this);
            },
            saveNote: function saveNote(newNoteText, updatedNotes, scope) {
                var advisorId = Ember.Application.user.id;

                var notesToProcess = [];
                for (var i = 0; i < updatedNotes.length; i++) {
                    notesToProcess.push(Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-notes/' + updatedNotes[i].noteId, {
                        method: 'PUT',
                        contentType: 'application/json',
                        data: JSON.stringify({
                            text: updatedNotes[i].note
                        })
                    }));
                }

                if (!!newNoteText) {
                    notesToProcess.push(Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-notes', {
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({
                            customerId: scope.get('customerId'),
                            text: newNoteText
                        })
                    }));
                }

                return Ember.RSVP.hash(notesToProcess).then(function (result) {
                    scope.actions.refreshNotes(scope.get('customerId'), scope.get('searchText'), scope);
                }, function (xhr) {
                    //TODO: Handle error.
                });
            },
            deleteNotes: function deleteNotes(noteIds, scope) {
                var notesToDelete = [];
                var notes = scope.get('customerNotes');
                var advisorId = Ember.Application.user.id;

                for (var i = 0; i < notes.length; i++) {
                    var note = notes[i];
                    if (noteIds.indexOf(note.noteId) > -1) {
                        notesToDelete.push(Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-notes/' + note.noteId, {
                            method: 'DELETE',
                            contentType: 'application/json'
                        }));
                    }
                }

                return Ember.RSVP.hash(notesToDelete).then(function (result) {
                    scope.actions.refreshNotes(scope.get('customerId'), scope.get('searchText'), scope);
                }, function (xhr) {
                    //TODO: Handle error.
                });
            },
            refreshNotes: function refreshNotes(customerId, searchText, scope) {
                var advisorId = Ember.Application.user.id;

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-notes?customerId=' + customerId + '&searchText=' + (searchText || ''), {
                    method: 'GET',
                    contentType: 'application/json'
                }).then(function (result) {
                    scope.set('customerNotes', result.customerNotes);
                });
            },
            searchNotes: function searchNotes(customerId, searchText, scope) {
                var advisorId = Ember.Application.user.id;

                Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-notes?customerId=' + customerId + '&searchText=' + (searchText || ''), {
                    method: 'GET',
                    contentType: 'application/json'
                }).then(function (result) {
                    var htmlEscape = function htmlEscape(s) {
                        return s ? s.replace(/&/g, '&amp;').replace(/</g, '&lt;') : s;
                    };
                    var replacementRegex = searchText ? new RegExp(htmlEscape(searchText).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i') : null;

                    var mark = function mark(text) {
                        text = htmlEscape(text);
                        return replacementRegex && text ? text.replace(replacementRegex, '<mark>$&</mark>') : text;
                    };

                    result.customerNotes = result.customerNotes.map(function (note) {
                        note.text = mark(note.text);
                        return note;
                    });

                    scope.set('customerNotes', result.customerNotes);
                });
            }
        }
    });
});