define('ingenio-web/models/user', ['exports', 'ingenio-web/models/model-base', 'ember-data/attr'], function (exports, _modelBase, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _modelBase.default.extend({
        USPhoneRegex: /^[0-9]{10}$/i,
        FormatedUSPhoneRegex: /^(\([0-9]{3}\)|[0-9]{3}-) [0-9]{3}-[0-9]{4}$/i,
        InternationalPhoneRegex: /^[0-9]{6,}$/i,
        InternationalPhoneCountryRegex: /^[0-9]{1,}$/i,

        phoneTypes: ['home', 'mobile', 'work'],
        name: (0, _attr.default)('string', { defaultValue: '' }),
        birthDate: (0, _attr.default)('date'),
        email: (0, _attr.default)('string', { defaultValue: '' }),
        firstName: (0, _attr.default)('string', { defaultValue: '' }),
        lastName: (0, _attr.default)('string', { defaultValue: '' }),
        username: (0, _attr.default)('string', { defaultValue: '' }),
        uid: (0, _attr.default)('string', { defaultValue: '' }),

        image: (0, _attr.default)('string', { defaultValue: '' }),
        isCvnReqd: (0, _attr.default)('boolean', { defaultValue: false }),
        sendKeenEmail: (0, _attr.default)('boolean', { defaultValue: false }),
        sendPartnerEmail: (0, _attr.default)('boolean', { defaultValue: false }),

        timezone: (0, _attr.default)('number'),
        preferredCurrency: (0, _attr.default)('string', { defaultValue: '$' }),

        primaryPhone: (0, _attr.default)('string', { defaultValue: 'home' }),
        isUSPhone: (0, _attr.default)('boolean', { defaultValue: true }),

        home: (0, _attr.default)('string', { defaultValue: '' }),
        homeCountryCode: (0, _attr.default)('string', { defaultValue: '' }),

        mobile: (0, _attr.default)('string', { defaultValue: '' }),
        mobileCountryCode: (0, _attr.default)('string', { defaultValue: '' }),

        work: (0, _attr.default)('string', { defaultValue: '' }),
        workCountryCode: (0, _attr.default)('string', { defaultValue: '' }),
        workExt: (0, _attr.default)('string', { defaultValue: '' }),
        hasAddedFundsInPast: (0, _attr.default)('boolean', { defaultValue: false }),
        primaryListingId: (0, _attr.default)('number', { defaultValue: 0 }),

        didLoad: function didLoad() {
            // identify is phone US or Other
            this.set('isUSPhone', !this.get('isInternationalPhone'));

            var phoneNumber;
            var countryCode;

            // remove country code from phone numbers
            if (this.get('homeCountryCode') !== '') {
                phoneNumber = this.get('home');
                countryCode = this.get('homeCountryCode');
                if (phoneNumber.length > 0 && phoneNumber.startsWith(countryCode)) {
                    phoneNumber = phoneNumber.substring(countryCode.length);
                    this.set('home', phoneNumber);
                }
            }

            if (this.get('mobileCountryCode') !== '') {
                phoneNumber = this.get('mobile');
                countryCode = this.get('mobileCountryCode');
                if (phoneNumber.length > 0 && phoneNumber.startsWith(countryCode)) {
                    phoneNumber = phoneNumber.substring(countryCode.length);
                    this.set('mobile', phoneNumber);
                }
            }

            if (this.get('workCountryCode') !== '') {
                phoneNumber = this.get('work');
                countryCode = this.get('workCountryCode');
                if (phoneNumber.length > 0 && phoneNumber.startsWith(countryCode)) {
                    phoneNumber = phoneNumber.substring(countryCode.length);
                    this.set('work', phoneNumber);
                }
            }
        },

        isInternationalPhone: function () {
            var homeCountry = this.get('homeCountryCode') || '1';
            var mobileCountry = this.get('homeCountryCode') || '1';
            var workCountry = this.get('homeCountryCode') || '1';

            return homeCountry !== '1' || mobileCountry !== '1' || workCountry !== '1';
        }.property('homeCountryCode', 'mobileCountryCode', 'workCountryCode'),

        primaryPhoneNumber: function () {
            if (!this.get('isValid')) {
                return '';
            }

            var phoneType = this.get('primaryPhone').toLowerCase();
            var phoneNumber = this.get(phoneType);
            var countryCode = this.get(phoneType + 'CountryCode');

            return this.formatPhoneNumber(phoneNumber, countryCode, phoneType);
        }.property('primaryPhone', 'isUSPhone', 'home', 'mobile', 'work', 'homeCountryCode', 'mobileCountryCode', 'workCountryCode', 'isValid'),

        validatePhoneNumberFormat: function validatePhoneNumberFormat(originalPhone, originalCountryCode, isUSPhone) {
            var phone = originalPhone.replace('_', '');
            var countryCode = originalCountryCode.replace('_', '');

            if (phone === '') {
                return '';
            }

            var result = '';

            if (isUSPhone) {
                if (!this.USPhoneRegex.test(phone) && !this.FormatedUSPhoneRegex.test(phone)) {
                    result = 'Incorrect format of user phone';
                }
            } else {
                if (!this.InternationalPhoneRegex.test(phone) && !this.FormatedUSPhoneRegex.test(phone)) {
                    result = 'Incorrect format of user phone';
                }
                if (!this.InternationalPhoneCountryRegex.test(countryCode)) {
                    if (result !== '') {
                        result = result + ' and incorrect format of phone country code';
                    } else {
                        result = 'Incorrect format of phone country code';
                    }
                }
            }
            return result;
        },

        formatPhoneNumber: function formatPhoneNumber(originalPhone, countryCode, phoneType) {
            var isUSNumber = this.get('isUSPhone');

            var phone = originalPhone.replace(/[^0-9]/g, '').trim();

            var formattedPhone = phone;

            if (isUSNumber) {
                if (formattedPhone.length === 10) {
                    formattedPhone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
                }
            } else {
                formattedPhone = '+' + countryCode + ' ' + phone;
            }

            if (phoneType) {
                formattedPhone = phoneType.substring(0, 1).toUpperCase() + phoneType.substring(1) + '  ' + formattedPhone;
            }

            return formattedPhone;
        },

        validatePhones: function () {
            var self = this;

            self.removeErrors('primaryPhone');
            self.removeErrors('home');
            self.removeErrors('mobile');
            self.removeErrors('work');

            // validate primary phone number
            var phoneType = self.get('primaryPhone') || '';

            if (phoneType === '') {
                this.addError('primaryPhone', 'Please select type of primary phone');
            }

            phoneType = phoneType.toLowerCase();

            var phoneNumber = self.get(phoneType) || '';

            if (phoneNumber === '') {
                self.addError(phoneType, 'Primary phone number is required');
            }

            var isUSNumber = self.get('isUSPhone');

            // iterate over phones and validate
            self.get('phoneTypes').forEach(function (ptype) {
                var number = self.get(ptype) || '';

                // for non-empty us phones assign country code to 1
                var countryCode = self.get(ptype + 'CountryCode');

                if (isUSNumber && number !== '' && countryCode !== '1') {
                    self.set(ptype + 'CountryCode', '1');
                }

                // validate phone and country formats
                var error = self.validatePhoneNumberFormat(number, countryCode, isUSNumber);

                if (error !== '') {
                    self.addError(ptype, error);
                }
            });
        }.observes('primaryPhone', 'isUSPhone', 'home', 'mobile', 'work'),

        validatePhoneCountries: function () {
            var self = this;

            var phoneNumberIsUS = self.get('isUSPhone');

            if (!phoneNumberIsUS) {
                self.removeErrors('home');
                self.removeErrors('mobile');
                self.removeErrors('work');

                // iterate over phones and validate
                self.get('phoneTypes').forEach(function (ptype) {
                    var number = self.get(ptype) || '';

                    // validate phone and country formats
                    var countryCode = self.get(ptype + 'CountryCode');
                    var error = self.validatePhoneNumberFormat(number, countryCode, phoneNumberIsUS);

                    if (error !== '') {
                        self.addError(ptype, error);
                    }
                });
            }
        }.observes('homeCountryCode', 'mobileCountryCode', 'workCountryCode'),

        // global validation method will be executed on init
        validate: function () {
            this.validatePhones();
            this.validatePhoneCountries();
        }.on('init')
    });
});