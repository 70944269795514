define("ingenio-web/appointment/payment-details/controller", ["exports", "ingenio-web/mixins/scroll-helpers", "ingenio-web/appointment/step-helpers", "ingenio-web/mixins/checkout-flow/payment-details/controller"], function (exports, _scrollHelpers, _stepHelpers, _controller) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
    appointment: Ember.inject.controller(),
    hideSteps: Ember.computed.equal('steptype', '1'),

    getNextRouteName: function getNextRouteName() {
      var step = ".confirmation";

      if (this.get('model.user.phoneNumbers').filter(function (phone) {
        return phone.get('isPrimary') && phone.get('isSmsNotificationEnabled');
      }).length === 0) {
        step = ".phone-number";
      }
      return this.getParentRouteName() + step;
    }
  });
});