define('ingenio-web/arrange-conversation/route', ['exports', 'ingenio-web/models/arrange-conversation-flow'], function (exports, _arrangeConversationFlow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        experimentation: Ember.inject.service('experimentation'),

        model: function model(params, transition) {
            var _this = this;

            var user = Ember.Application.user;
            var listingId = transition.queryParams.listingid;

            var convType = 'CALL';

            if (transition.queryParams.ctype && transition.queryParams.ctype.toUpperCase() === 'CHAT') {
                convType = 'CHAT';
            }

            return Ember.RSVP.hash({
                waitListInformation: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql', {
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        operationName: 'WaitListInformation',
                        query: 'query WaitListInformation($listingToWaitFor:ID!){listing(id:$listingToWaitFor){advisor{id userName availability{callAvailability{type acceptingAppointments selectedAsOf isSelected}chatAvailability}conversateWaitList{awaiting estimatedWaitTime}}}currentUser{profile{notifications{viaSms{enabled}}}advisorConversationRequests{edgesLength edges{node{id queuePosition requestedAt expiresAt requestedBy{id userName}requestedFor{id userName}forConversationType estimatedWaitTime onListing{id title}status}}}}}',
                        variables: { listingToWaitFor: listingId }
                    })
                }),
                listing: this.store.findRecord('listing', listingId),
                countries: Ember.$.getJSON(Ember.ENV.getApiRoot() + '/countries'),
                balance: this.store.findRecord('balance', user.id, {
                    adapterOptions: {
                        listingId: listingId
                    },
                    reload: true
                }),
                userOffer: this.store.queryRecord('specialOffer', {
                    listingId: listingId
                }),
                paymentMethods: this.store.findAll('paymentMethod'),
                phoneNumbers: this.store.findAll('userPhone', { reload: true }),
                orderPrepare: this.store.createRecord('orderPrepare').save({ adapterOptions: { listingId: listingId, isArrange: true } })
            }).then(function (payload) {
                return _this.transformPromiseHashIntoViewModel(payload, convType);
            });
        },


        actions: {
            onUserOfferDeleted: function onUserOfferDeleted() {
                var model = this.modelFor(this.routeName);

                model.set('showOfferRemovalMessage', true);
            }
        },

        getRepeatUserMinimumDepositAmount: function getRepeatUserMinimumDepositAmount() {
            var mergedExperimentProps = this.get('experimentation.mergedProperties');

            if (mergedExperimentProps.minimumDepositAmount > 0) {
                return mergedExperimentProps.minimumDepositAmount;
            }
            return Ember.ENV.repeatUserMinimumDepositAmount;
        },
        transformPromiseHashIntoViewModel: function transformPromiseHashIntoViewModel(payload, convType) {
            var fundsModel = this.store.createRecord('funds');

            // use the default payment method, by default, if found.
            // handle a weird edge case where the user does not have any primary
            // payment methods and choose the first object by default.
            if (!Ember.isEmpty(payload.paymentMethods)) {
                var defaultPaymentMethod = payload.paymentMethods.find(function (method) {
                    return method.get('isPrimary');
                }) || payload.paymentMethods.get('firstObject');

                fundsModel.set('paymentMethodId', defaultPaymentMethod.get('id'));
            }

            // when setting up the funds model ... populate the offer price if in an offer flow.
            if (payload.userOffer && payload.userOffer.get('qualifierAmount')) {
                fundsModel.set('offerId', payload.userOffer.get('id'));
                fundsModel.set('offerAmount', parseFloat(payload.userOffer.get('qualifierAmount')).toFixed(2));
            }

            // check if user has arranged call
            var advisorId = payload.listing.get('advisorId');

            var advisorConversationRequest = null;

            if (payload.waitListInformation.data.currentUser.advisorConversationRequests) {
                var advisorConversationRequests = payload.waitListInformation.data.currentUser.advisorConversationRequests.edges.map(function (item) {
                    return item.node;
                });

                advisorConversationRequest = advisorConversationRequests.find(function (item) {
                    return item && item.requestedFor.id == advisorId;
                });
                if (advisorConversationRequest) {
                    // assign flag indicating that the arrange call is created in prev. session
                    advisorConversationRequest.existing = true;
                    if (!advisorConversationRequest.queuePosition || advisorConversationRequest.queuePosition == 0) {
                        advisorConversationRequest.queuePosition = 1;
                    }
                }
            }

            // get information about advisor to be able to arrange call
            var advisorCallAvailability = payload.waitListInformation.data.listing.advisor.availability.callAvailability.find(function (state) {
                return state.isSelected;
            });

            var waitlistInfo = payload.waitListInformation.data.listing.advisor.conversateWaitList;

            if (!waitlistInfo.awaiting) {
                waitlistInfo.awaiting = 1;
            } else {
                waitlistInfo.awaiting += 1;
            }
            waitlistInfo.advisorCallAvailability = advisorCallAvailability;
            waitlistInfo.advisorChatAvailability = payload.waitListInformation.data.listing.advisor.availability.chatAvailability;

            return _arrangeConversationFlow.default.create({
                conversationType: convType,
                notifications: { success: [], errors: [] },
                domain: {
                    id: Ember.ENV.domainId,
                    name: Ember.ENV.domainName
                },
                listing: payload.listing,
                advisorConversationRequest: advisorConversationRequest,
                waitlistInfo: waitlistInfo,
                user: {
                    notifications: payload.waitListInformation.data.currentUser.profile.notifications,
                    hasAddedFundsInPast: Ember.Application.user.hasAddedFundsInPast,
                    funds: fundsModel,
                    balance: payload.balance,
                    offer: payload.userOffer,
                    paymentMethods: payload.paymentMethods,
                    phoneNumbers: payload.phoneNumbers
                },
                countries: payload.countries,

                showStepsType: 'hide',
                showDepositeType: 'addMoney',
                showOfferRemovalMessage: false, //Flag to display the message upon user offer removal
                repeatUserMinimumDepositAmount: this.getRepeatUserMinimumDepositAmount(),
                orderPrepare: payload.orderPrepare
            });
        },
        getDepositeType: function getDepositeType(hasAddedFundsInPast, userOffer, balance) {
            return 'addMoney';
        }
    });
});