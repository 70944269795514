define('ingenio-web/adapters/call', ['exports', 'ingenio-web/models/call-result-codes', 'ingenio-web/adapters/application'], function (exports, _callResultCodes, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'call';
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            // determine if the call succeeded or resulted in an error.
            if (payload.resultCode !== _callResultCodes.default.success) {
                return new DS.InvalidError([payload]);
            }

            payload['call'] = payload;

            return this._super.apply(this, arguments);
        }
    });
});