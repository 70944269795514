define('ingenio-web/components/sms-notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    insertPhoneError: false,
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    isKeen: function () {
      return Ember.ENV.domainId === 1;
    }.property(),

    selectedPhoneNumber: function () {
      var serverPhoneNumber = this.get('selectedPhone.serverPhone');

      if (serverPhoneNumber && serverPhoneNumber.length > 8) {
        return serverPhoneNumber;
      }

      return '';
    }.property('selectedPhone.serverPhone'),

    backButtonCaption: function () {
      var backButtonText = this.get('backButtonText');

      if (backButtonText) {
        return backButtonText;
      }
      return 'Back';
    }.property('backButtonText'),

    showSmsAlertEnabledPage: function () {
      var smsNotificationEnabled = this.get('smsNotificationEnabled');
      var update = this.get('update');

      return smsNotificationEnabled && update !== '1';
    }.property('smsNotificationEnabled', 'update'),

    selectedPhoneObserves: function () {
      var selectedUserPhoneId = this.get('selectedUserPhoneId');

      if (!selectedUserPhoneId) {
        this.set('selectedPhone', this.get('primaryPhone'));
      } else {
        var userPhones = this.get('userPhones');
        var selectedPhone = userPhones.findBy('id', selectedUserPhoneId.toString());

        this.set('selectedPhone', selectedPhone);
      }
    }.observes('selectedUserPhoneId'),

    isDisplayContinue: function () {
      return this.get('isContinue') && (!this.get('selectedPhone.isUSNumber') || this.get('insertPhoneError'));
    }.property('insertPhoneError', 'isSmsOptional', 'selectedPhone.isUSNumber'),

    saveDisabled: function () {
      var selectedPhone = this.get('selectedPhone');
      var isUSNumber = this.get('selectedPhone.isUSNumber');

      return selectedPhone.get('inProcess') || !selectedPhone.get('isValid') || !isUSNumber;
    }.property('selectedPhone.number', 'selectedPhone.code', 'selectedPhone.isUSNumber', 'selectedPhone.extension', 'selectedPhone.inProcess'),

    actions: {
      enableSmsNotification: function enableSmsNotification() {
        var _this = this;

        var selectedPhone = this.get('selectedPhone');

        if (!selectedPhone.get('isValid')) {
          return;
        }

        this.set('insertPhoneError', false);
        selectedPhone.set('inProcess', true);
        selectedPhone.set('isSmsNotificationEnabled', true);
        selectedPhone.save().then(function () {
          _this.set('smsNotificationEnabled', true);

          _this.set('update', 0);
        }).catch(function (xhr) {
          _this.set('insertPhoneError', true);
          selectedPhone.set('inProcess', false);
          selectedPhone.handleError(xhr);
        });
      },
      enablePromotionalSms: function enablePromotionalSms() {
        var _this2 = this;

        var selectedPhone = this.get('selectedPhone');

        if (!selectedPhone.get('isValid')) {
          return;
        }

        this.set('insertPhoneError', false);
        selectedPhone.set('inProcess', true);
        selectedPhone.set('isPromotionalSmsEnabled', true);
        selectedPhone.save().then(function () {
          _this2.set('smsNotificationEnabled', true);

          _this2.set('update', 0);
        }).catch(function (xhr) {
          _this2.set('insertPhoneError', true);
          selectedPhone.set('inProcess', false);
          selectedPhone.handleError(xhr);
        });
      },
      back: function back() {
        var onBack = this.get('onBack');

        this.set('primaryButtonBusy', true);
        if (onBack) {
          onBack();
          this.set('primaryButtonBusy', false);
        } else {
          if (this.get('returnUrl')) {
            window.location.href = this.get('returnUrl');
          } else {
            window.location.href = '/MyAccount/UpdatePersonalInfo.aspx';
          }
        }
      }
    }
  });
});