define('ingenio-web/utils/popup-blocker-detector', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var PopupBlockerDetector = function () {
        function PopupBlockerDetector() {
            _classCallCheck(this, PopupBlockerDetector);
        }

        _createClass(PopupBlockerDetector, [{
            key: 'getBrowser',
            value: function getBrowser() {
                var isIE = navigator.userAgent.toLowerCase().indexOf('msie') !== -1;
                var isFF = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
                var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') !== -1;
                var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') !== -1;

                if (isIE) {
                    return 'ie';
                } else if (isFF) {
                    return 'ff';
                } else if (isChrome) {
                    return 'chrome';
                } else if (isSafari) {
                    return 'safari';
                }

                return '';
            }
        }, {
            key: 'check',
            value: function check(callback) {
                var browser = this.getBrowser();

                var isChrome = browser === 'chrome';
                var isSafari = browser === 'safari';

                var popupBlockerWin = window.open('about:blank', 'popupBlockerTestWin', 'width=50,height=50,top=0,left=0,location=0,menubar=0,resizable=0,scrollbars=0,toolbar=0,status=0');
                var blockerDetected;

                if (isChrome) {
                    setTimeout(function () {
                        if (!popupBlockerWin || popupBlockerWin && popupBlockerWin.innerHeight === 0) {
                            blockerDetected = true;
                        } else {
                            popupBlockerWin.close();
                            blockerDetected = false;
                        }
                        if (callback) {
                            return callback({
                                blockerDetected: blockerDetected,
                                browser: browser
                            });
                        }
                    }, 1000);
                } else if (isSafari) {
                    if (typeof popupBlockerWin === 'undefined') {
                        blockerDetected = true;
                    } else {
                        popupBlockerWin.close();
                        blockerDetected = false;
                    }
                    if (callback) {
                        return callback({
                            blockerDetected: blockerDetected,
                            browser: browser
                        });
                    }
                } else {
                    if (popupBlockerWin === null) {
                        blockerDetected = true;
                    } else if (popupBlockerWin) {
                        popupBlockerWin.close();
                        blockerDetected = false;
                    }
                    if (callback) {
                        return callback({
                            blockerDetected: blockerDetected,
                            browser: browser
                        });
                    }
                }
            }
        }]);

        return PopupBlockerDetector;
    }();

    exports.default = PopupBlockerDetector;
});