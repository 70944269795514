define('ingenio-web/models/prequalified-offer', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        userId: (0, _attr.default)('number'),
        offerId: (0, _attr.default)('number'),
        expirationDate: (0, _attr.default)('date'),
        tinyDescription: (0, _attr.default)('string'),
        terms: (0, _attr.default)('string'),
        rewardAmount: (0, _attr.default)('number', { defaultValue: 0 })
    });
});