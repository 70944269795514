define('ingenio-web/components/chat/advisor-info-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            closeDialog: function closeDialog() {
                this.get('onClose')();
            }
        }
    });
});