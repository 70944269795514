define('ingenio-web/controllers/myaccount/customers', ['exports', 'ingenio-web/models/customers/create-new-list-criteria', 'ingenio-web/utils/html-utils', 'ingenio-web/utils/cookies'], function (exports, _createNewListCriteria, _htmlUtils, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SPROUTING_CUSTOMERS_SMART_LIST = 'SproutingCustomersList';
  var SPROUTING_CUSTOMERS_SMART_LIST_TRY_IT = 'SproutingCustomersListTryIt';

  exports.default = Ember.Controller.extend({
    queryParams: ['customerType'],
    customerType: null,
    customerTypeId: 0,
    notifications: Ember.inject.service('toast'),
    busy: Ember.inject.service('busy'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    currentUserId: Ember.computed('Ember.Application.user.id', function () {
      return Ember.Application.user ? Ember.Application.user.id : null;
    }),

    customersGrid: null,

    _showDeleteConfirmationDialog: false,
    showDeleteConfirmationDialog: Ember.computed.equal('_showDeleteConfirmationDialog', true),

    _showCreateNewListDialog: false,
    showCreateNewListDialog: Ember.computed.equal('_showCreateNewListDialog', true),

    FilterType: { One: 1, All: 2 },

    smartListBannerHiddenCss: '',

    isCustomersBlocked: function isCustomersBlocked(filterType) {
      var blockedList = this.get('model.filters.customerLists').find(function (list) {
        return list.type === "Block";
      });
      var selectedCustomers = this.get('model.selectedCustomers');

      switch (filterType) {
        case this.FilterType.One:
          var blockedCustomer = selectedCustomers.find(function (customer) {
            return customer.list.id == blockedList.id;
          });
          return blockedCustomer != null;
        case this.FilterType.All:
          return selectedCustomers.every(function (customer) {
            return customer.list.id == blockedList.id;
          });
        default:
          throw new Error('"' + filterType + '" is unsupported filter type');
      }
    },

    assignToListItems: function () {
      var selectedCustomers = this.get('model.selectedCustomers');
      var currentListId = this.get('model.filters.customerList');
      var customerLists = this.get('model.filters.customerLists');
      var result = [];
      customerLists.forEach(function (customerList) {
        if (currentListId != customerList.id && !selectedCustomers.every(function (customer) {
          return customer.list.id == customerList.id;
        }) && customerList.type != "Block") {
          result.push(customerList);
        };
      });

      return result;
    }.property('model.selectedCustomers.length'),

    isOneOfSelectedCustomersBlocked: function () {
      return this.isCustomersBlocked(this.FilterType.One);
    }.property('model.selectedCustomers.length'),

    customerTypeChanged: Ember.observer('model.filters.customerLists', 'customerType', function () {
      var newCustomersList = this.get('model.filters.customerLists') || [];
      var newCustomer = newCustomersList.find(function (list) {
        return list.type.toLowerCase() === "newcustomers";
      });
      if (this.get('customerType') === 'new' && newCustomer) {
        var customerId = newCustomer.id;
        this.set('customerTypeId', customerId);
        this.set('model.filters.customerList', customerId);
      }
    }),

    disableSendEmail: function () {
      // disable send email for blocked customers
      if (this.get('isOneOfSelectedCustomersBlocked')) {
        return true;
      }

      return !this.get('model.selectedCustomers') || this.get('model.selectedCustomers').length === 0;
    }.property('model.selectedCustomers.length'),

    disableSendOffer: function () {
      // disable send offer for blocked customers
      if (this.get('isOneOfSelectedCustomersBlocked')) {
        return true;
      }

      return !this.get('model.selectedCustomers') || this.get('model.selectedCustomers').length === 0;
    }.property('model.selectedCustomers.length'),

    disableSendPing: function () {
      // disable send ping for blocked customers
      if (this.get('isOneOfSelectedCustomersBlocked')) {
        return true;
      }

      return !this.get('model.selectedCustomers') || this.get('model.selectedCustomers').length !== 1;
    }.property('model.selectedCustomers.length'),

    disableAssignToList: function () {
      return !this.get('model.selectedCustomers') || this.get('model.selectedCustomers').length === 0;
    }.property('model.selectedCustomers.length'),

    isKeen: function () {
      return !(Ember.ENV.domainId === 6);
    }.property('Ember.ENV.domainId'),

    switchListToView: function () {
      if (this.get('model.pagination.currentPage') !== 1) {
        this.set('model.pagination.currentPage', 1);
      } else {
        this.refreshGrid();
      }
    }.observes('model.filters.customerList', 'model.filters.startLetter'),

    refreshGrid: function () {
      if (this.get('customersGrid')) {
        this.get('customersGrid').refreshGrid();
      }
    }.observes('model.pagination.currentPage', 'model.pagination.pageSize'),

    assignToList: function assignToList(list, customerIds) {
      var advisorId = Ember.Application.user.id;

      return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists/' + list.id, {
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          customerIds: customerIds
        })
      });
    },
    errorMessage: function errorMessage(xhr) {
      if (!xhr || !xhr.responseJSON || !xhr.responseJSON.errors) return '';
      return xhr.responseJSON.errors.reduce(function (agregator, item) {
        if (agregator === '') return item.message;else return agregator + ', ' + item.message;
      }, '');
    },


    myCustomersHelpUrl: function () {
      if (Ember.ENV.domainId === 1) {
        return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360049122774-My-Customers-Lists';
      }
      return '/help/mycustomers';
    }.property(),

    isDisplaySmartListBanner: function () {
      var smartListCookie = SPROUTING_CUSTOMERS_SMART_LIST + this.get('currentUserId').toString();
      var smartListTryItCookie = SPROUTING_CUSTOMERS_SMART_LIST_TRY_IT + this.get('currentUserId').toString();

      return this.get('isKeen') && _cookies.default.getCookie(smartListCookie) !== 'show=false' && _cookies.default.getCookie(smartListTryItCookie) !== 'show=false';
    }.property('currentUserId'),

    actions: {
      gridGoPreviousPage: function gridGoPreviousPage() {},
      gridGoNextPage: function gridGoNextPage() {},
      searchByName: function searchByName() {
        this.refreshGrid();
        return false;
      },
      sendEmail: function sendEmail() {
        if (this.get('disableSendEmail')) return;

        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.selectedCustomers').map(function (c) {
              return c.userName;
            }).join(', '),
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      sendOffer: function sendOffer() {
        if (this.get('disableSendOffer')) return;

        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            mode: "0",
            to: this.get('model.selectedCustomers').map(function (c) {
              return c.userName;
            }).join(', '),
            type: "19",
            ReturnURL: window.location,
            CancelURL: window.location
          })
        });
      },
      sendPing: function sendPing() {
        if (this.get('disableSendPing')) return;

        var customer = this.get('model.selectedCustomers')[0];
        window.location.href = '/app/#/direct-messages/recipient/' + customer.id;
      },
      assignToList: function assignToList(list) {
        var me = this;
        var notifications = me.get('notifications');
        me.get('busy').show();

        return this.assignToList(list, this.get('model.selectedCustomers').map(function (c) {
          return c.id;
        })).then(function (response) {
          notifications.info('Success', 'Assign To List', {
            positionClass: 'toast-top-right'
          });
          me.refreshGrid();
        }, function (xhr) {
          me.get('busy').hide();

          var message = me.errorMessage(xhr);
          if (message === '') message = 'Error';else message = 'Error: ' + message;
          notifications.error(message, 'Assign To List', {
            positionClass: 'toast-top-right'
          });
        });
      },
      confirmCreateNewList: function confirmCreateNewList() {
        if (this.get('disableAssignToList')) return;

        this.set('model.newListCriteria', _createNewListCriteria.default.create());

        this.set('_showCreateNewListDialog', true);
      },
      createNewList: function createNewList(criteria) {
        var _this = this;

        if (this.get('disableAssignToList')) return;

        var me = this;

        var advisorId = Ember.Application.user.id;
        criteria.clearErrors();

        return Ember.$.ajax(Ember.ENV.getApiRoot() + '/advisors/' + advisorId + '/customer-lists', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            name: criteria.get('listName')
          })
        }).then(function (response) {
          var list = response.customerList;

          me.get('model.filters.customerLists').addObject(list);

          return me.assignToList(list, _this.get('model.selectedCustomers').map(function (c) {
            return c.id;
          })).then(function (response) {
            _this.set('_showCreateNewListDialog', false);

            me.refreshGrid();

            var notifications = me.get('notifications');
            notifications.info('Success', 'Create New List', {
              positionClass: 'toast-top-right'
            });
          }, criteria.handleError.bind(criteria));
        }, criteria.handleError.bind(criteria));
      },
      cancelCreateNewList: function cancelCreateNewList() {
        this.set('_showCreateNewListDialog', false);
      },
      contactTypeClicked: function contactTypeClicked() {},
      customerClicked: function customerClicked() {},
      listNameTouched: function listNameTouched() {
        if (this.get('model.newListCriteria')) {
          this.get('model.newListCriteria').addTouched('listName');
        }
      },
      closeSmartListBanner: function closeSmartListBanner() {
        var cookie = SPROUTING_CUSTOMERS_SMART_LIST + this.get('currentUserId').toString();

        _cookies.default.createCookie(cookie, 'show=false', 1);
        this.set('smartListBannerHiddenCss', 'hidden');
      },
      smartListTryNowClick: function smartListTryNowClick() {
        var cookie = SPROUTING_CUSTOMERS_SMART_LIST_TRY_IT + this.get('currentUserId').toString();

        _cookies.default.createCookie(cookie, 'show=false', 1);
        this.set('smartListBannerHiddenCss', 'hidden');

        window.location = '/Mail/Compose.aspx?' + _htmlUtils.default.encode({
          Subroutine: 'Mail',
          SubroutineParams: _htmlUtils.default.encode({
            smlt: "1",
            ReturnURL: '',
            CancelURL: ''
          })
        });
      }
    }
  });
});