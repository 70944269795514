define('ingenio-web/serializers/call', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        primaryKey: 'callId',

        serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
            Ember.merge(hash, this.serialize(record, options));
        }
    });
});