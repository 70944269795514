define('ingenio-web/components/chat/initiate-chat-timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        timerSeconds: null,
        timerFinish: null,
        timer: null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.timerFinish = new Date().getTime() + this.timerSeconds * 1000;
            var timer = setInterval(function () {
                return _this.startTimer();
            }, 50);

            this.set('timer', timer);
            setTimeout(function () {
                clearInterval(timer);
            }, this.timerSeconds * 1000);
        },
        willDestroyElement: function willDestroyElement() {
            var timer = this.get('timer');

            if (timer) {
                clearInterval(timer);
            }
            this._super.apply(this, arguments);
        },


        timerPercentGreater50: function () {
            return this.get('timerPercent') > 50;
        }.property('timerPercent'),

        timerPercentGreater99: function () {
            return this.get('timerPercent') > 99;
        }.property('timerPercent'),

        drawTimer: function drawTimer(percent, time) {
            var deg = 360 / 100 * percent;

            $('.pie').css({
                '-moz-transform': 'rotate(' + deg + 'deg)',
                '-o-transform': 'rotate(' + deg + 'deg)',
                '-ms-transform': 'rotate(' + deg + 'deg)',
                '-webkit-transform': 'rotate(' + deg + 'deg)',
                transform: 'rotate(' + deg + 'deg)'
            });

            $('.pie-head-container').css({
                '-moz-transform': 'rotate(' + deg + 'deg)',
                '-o-transform': 'rotate(' + deg + 'deg)',
                '-ms-transform': 'rotate(' + deg + 'deg)',
                '-webkit-transform': 'rotate(' + deg + 'deg)',
                transform: 'rotate(' + deg + 'deg)'
            });

            var date = new Date(time * 1000);
            var mins = ('0' + date.getUTCMinutes()).slice(-2);
            var secs = ('0' + date.getUTCSeconds()).slice(-2);

            this.set('timeRemaining', mins + ':' + secs);
        },
        startTimer: function startTimer() {
            var seconds = (this.timerFinish - new Date().getTime()) / 1000;

            if (seconds <= 0) {
                this.drawTimer(100, 0);
                var timer = this.get('timer');

                if (timer) {
                    clearInterval(timer);
                }
            } else {
                var percent = 100 - seconds / this.timerSeconds * 100;

                this.drawTimer(percent, seconds + 1);
                this.set('timerPercent', percent);
            }
        }
    });
});