define('ingenio-web/models/advisor-listing', ['exports', 'ingenio-web/models/model-base', 'ember-data/attr'], function (exports, _modelBase, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function keyValuePair(obj) {
        return Object.keys(obj).map(function (key) {
            return encodeURIComponent(key).replace('%20', '+') + '=' + encodeURIComponent(obj[key]).replace('%20', '+');
        }).join('&');
    }

    function statusValues(listing) {

        var subroutineParams = {
            sid: listing.get('serviceId'),
            srvtp: 0,
            status: listing.get('statusCode'),
            category: listing.get('categoryId'),
            domainId: 1
        };

        var parameters = {
            Subroutine: 'listaccept',
            SubroutineParams: keyValuePair(subroutineParams),
            ReturnURL: '/#/myaccount/listings',
            CancelURL: ''
        };

        switch (listing.get('statusCode')) {
            case 1:
                return {
                    title: 'Accepted',
                    css: 'fa fa-check-square-o status-accepted',
                    accepted: true
                };
            case 2:
                subroutineParams.status = 2;
                return {
                    title: 'Listing rejected. Needs editing.',
                    css: 'fa fa-exclamation-triangle status-rejected',
                    url: '/listing/listingacceptancestatus.asp?' + keyValuePair(parameters),
                    isRejected: true
                };
            case 3:
                subroutineParams.status = 6;
                return {
                    title: 'Listing review is pending.',
                    css: 'fa fa-clock-o status-pending',
                    accepted: true,
                    url: '/listing/listingacceptancestatus.asp?' + keyValuePair(parameters)
                };
            case 4:
                return {
                    title: 'Pending Visible',
                    css: 'fa fa-clock-o status-pending'
                };
            case 5:
                return {
                    title: 'Accepted Edited',
                    css: 'fa fa-check-square-o status-accepted',
                    accepted: true
                };
            case 6:
                return {
                    title: 'Listing rejected. Waiting for listing review.',
                    css: 'fa fa-exclamation-triangle status-rejected',
                    url: '/listing/listingacceptancestatus.asp?' + keyValuePair(parameters),
                    isRejected: true
                };
            case 7:
                subroutineParams.status = 6;
                return {
                    title: 'Listing review is pending.',
                    css: 'fa fa-clock-o status-pending',
                    url: '/listing/listingacceptancestatus.asp?' + keyValuePair(parameters)
                };
        }
    }

    exports.default = _modelBase.default.extend({
        serviceId: (0, _attr.default)('number'),
        isApproved: (0, _attr.default)('boolean'),
        averageRating: (0, _attr.default)('number'),
        bidAmount: (0, _attr.default)(),
        bidPosition: (0, _attr.default)('number'),

        bidPositionFormatted: Ember.computed(function () {
            return this.get('bidPosition') > 0 && this.get('accepted') ? '#' + this.get('bidPosition') : '';
        }),

        url: (0, _attr.default)('string'),

        categoryId: (0, _attr.default)('number'),
        categoryName: (0, _attr.default)('string'),

        hasBid: Ember.computed(function () {
            return this.get('bidAmount.quantity') > 0;
        }),

        statusCode: (0, _attr.default)('number'),
        statusText: Ember.computed(function () {
            return statusValues(this).title;
        }),
        statusIconClass: Ember.computed(function () {
            return statusValues(this).css;
        }),
        statusUrl: Ember.computed(function () {
            return statusValues(this).url;
        }),
        statusRejected: Ember.computed(function () {
            return statusValues(this).isRejected;
        }),

        title: (0, _attr.default)('string'),

        rate: (0, _attr.default)(),

        ratingPoints: (0, _attr.default)('number'),
        starRating: (0, _attr.default)('number'),
        ratingsCount: (0, _attr.default)('number'),

        lifeTimeCallCount: (0, _attr.default)('number'),
        lifeTimeChatCount: (0, _attr.default)('number'),
        lifeTimeTransactionCount: (0, _attr.default)('number'),

        rejectionReason: (0, _attr.default)('string'),
        rejectionComment: (0, _attr.default)('string'),

        accepted: Ember.computed(function () {
            return statusValues(this).accepted;
        })
    });
});