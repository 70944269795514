define('ingenio-web/components/featured-listings/listing-bid-errors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = [{
        status: 'ListingNotApproved',
        message: 'The ability to feature this listing is not available because the listing is not yet "Accepted". You will be notified if your listing "Needs Editing". If your listing "Needs Editing", you will need to resubmit the listing for review after you have made your edits.' // eslint-disable-line
    }, {
        status: 'ListingNoAvatar',
        message: 'Only listings with photos can be featured. Add a photo to your listing.'
    }, {
        status: 'ListingAvatarNotApproved',
        message: 'Only listings with approved photos can be featured.'
    }, {
        status: 'ListingMinimumStarRatingNotMet',
        message: 'Listings with Ratings Stars less than 3.5 out of 5 are not eligible to be featured. Please feature another one of your listings or wait until your Ratings Stars are equal to or greater than 3.5.' // eslint-disable-line
    }, {
        status: 'ListingMinimumCumulativePointsNotMet',
        message: 'You cannot feature a listing with a rating of -1 or less.'
    }, {
        status: 'ListingMinimumNumberOfRatingsNotMet',
        message: 'There was an unexpected error placing this bid. Please try again.'
    }, {
        status: 'ListingOptedOutOfDirectory',
        message: 'There was an unexpected error placing this bid. Please try again.'
    }, {
        status: 'ListingOptedOutOfFeaturedListings',
        message: 'There was an unexpected error placing this bid. Please try again.'
    }, {
        status: 'ListingSuspendedFromFeaturedListings',
        message: 'Your listing has been suspended. Please review the email that we sent on how to improve your listing. Once you have enhanced your listing, you can contact Customer Support (anchor link to CS form) to reinstate your listing.' // eslint-disable-line
    }, {
        status: 'ListingIsDeleted',
        message: 'There was an unexpected error placing this bid. Please try again.'
    }, {
        status: 'RecordedListingMinimumDurationNotMet',
        message: 'You cannot feature a listing that has a recording less than 1 minute in length. Please record a valid message for this listing before placing a featured listing bid.' // eslint-disable-line
    }, {
        status: 'MultiPartyListingNotSupported',
        message: 'Only listings in directories can be featured (anchor link to edit listing page). Edit your listing to be included in at least one directory.' // eslint-disable-line
    }, {
        status: 'AdvisorSuspendedFromFeaturedListings',
        message: 'There was an unexpected error placing this bid. Please try again.'
    }];
});