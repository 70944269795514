define('ingenio-web/helpers/number-suffix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberSuffix = numberSuffix;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function numberSuffix(_ref, namedArgs) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    if (!value) return '-';

    var j = value % 10,
        k = value % 100;

    var suffix = "";
    if (j == 1 && k != 11) {
      suffix = "st";
    } else if (j == 2 && k != 12) {
      suffix = "nd";
    } else if (j == 3 && k != 13) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    if (namedArgs && namedArgs.highlightValue) {
      return '<span class="bold">' + value + suffix + '</span>';
    }
    return '' + value + suffix;
  }

  exports.default = Ember.Helper.helper(numberSuffix);
});