define('ingenio-web/helpers/platform-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.platformName = platformName;
    exports.isIOS = isIOS;
    function platformName() {
        return Ember.ENV.domainName;
    }

    /**
     * Returns true if the platform is iOS
     * @returns {boolean} true or false.
     */
    function isIOS() {
        return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
        // iPad on iOS 13 detection
        || navigator.userAgent.includes('Mac') && 'ontouchend' in document;
    }

    exports.default = Ember.Helper.helper(platformName);
});