define('ingenio-web/models/platform-attribute', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        name: (0, _attr.default)('string'),
        type: (0, _attr.default)('string'),
        value: (0, _attr.default)('string')
    });
});