define('ingenio-web/models/picture', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        primaryVersion: (0, _attr.default)(),
        originalVersion: (0, _attr.default)(),
        thumbnailVersion: (0, _attr.default)(),
        size75Version: (0, _attr.default)(),
        size500Version: (0, _attr.default)(),
        isAssociatedWithService: (0, _attr.default)('boolean')
    });
});