define('ingenio-web/models/call-status', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        callStatus: (0, _attr.default)('string'),
        headline: (0, _attr.default)('string'),
        messageType: (0, _attr.default)('number'),
        message: (0, _attr.default)('string'),
        resultType: (0, _attr.default)('number', { defaultValue: 0 }),
        isDisplayConnectingImage: (0, _attr.default)('boolean', { defaultValue: false }),
        isDisplayLeaveFeedbackButton: (0, _attr.default)('boolean', { defaultValue: false }),
        leaveFeedbackUrl: (0, _attr.default)('string'),
        isDisplayArrangeCallButton: (0, _attr.default)('boolean', { defaultValue: false }),
        isCallDone: (0, _attr.default)('boolean', { defaultValue: false })
    });
});