define('ingenio-web/mixins/checkout-flow/details/route', ['exports', 'ingenio-web/mixins/reset-scroll-on-transition'], function (exports, _resetScrollOnTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    flashMessages: Ember.inject.service(),
    mixpanelClient: Ember.inject.service('mixpanel-client'),

    checkoutFlowModel: function checkoutFlowModel() {
      return this.modelFor(this.getParentRouteName());
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (!model.get('user.selectedBox')) {
        model.set('user.selectedBox', model.get('isAddFundsRequired') ? 1 : 0);
      }

      if (model.get('showOfferRemovalMessage')) {
        this.flashMessages.danger("Oops! We're sorry. You're not qualified for this offer. Your card has not been charged.", {
          sticky: true,
          showProgress: true,
          extendedTimeout: 500,
          destroyOnClick: false
        });
        model.set('showOfferRemovalMessage', false);
      }
    }
  });
});