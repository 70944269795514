define('ingenio-web/components/chat/chat-transcript-messages', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.later(function () {
                _this.get('onShowUp')();
            }, 500);
        },


        customerNameFirstLetter: function () {
            return this.get('consumerName').charAt(0).toUpperCase();
        }.property('consumerName'),

        showCustomerAvatarAsText: function () {
            var consumerName = this.get('consumerName');

            if (consumerName && consumerName.length > 0) {
                return true;
            }
            return false;
        }.property('consumerName'),

        actions: {
            openUserInfoModal: function openUserInfoModal() {
                this.get('onAvatarClick')();
            }
        }
    });
});