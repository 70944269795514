define('ingenio-web/appointment/phone-number/controller', ['exports', 'ingenio-web/mixins/scroll-helpers', 'ingenio-web/appointment/step-helpers', 'ingenio-web/arrange-conversation/controller', 'ingenio-web/utils/logger'], function (exports, _scrollHelpers, _stepHelpers, _controller, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend(_scrollHelpers.default, _stepHelpers.default, {
    appointment: Ember.inject.controller(),
    hideSteps: Ember.computed.equal('steptype', '1'),

    logger: Ember.computed('', function () {
      var loggerOptions = {
        consumerId: Ember.Application.user.id,
        source: 'arrange-conversation-phone-number'
      };
      return new _logger.default(this.store, loggerOptions);
    }),

    primaryPhone: Ember.computed('model.user.phoneNumbers', 'phoneVersion', function () {
      var phoneNumbers = this.get('model.user.phoneNumbers');
      if (!phoneNumbers) {
        return null;
      }

      var primaryPhone = phoneNumbers.find(function (phone) {
        return phone.get('isPrimary');
      });

      if (phoneNumbers.get('length') === 1) {
        phoneNumbers.objectAt(0).set('isPrimary', true);
        return phoneNumbers.objectAt(0);
      }

      return primaryPhone;
    }),

    primaryEmail: Ember.computed('model.user', function () {
      var currentUser = Ember.Application.user;
      return currentUser.email;
    }),

    showSmsConfirmation: Ember.computed('', function () {
      return true;
    }),

    enablePrimaryButton: Ember.computed('primaryButtonBusy', 'phonesValid', 'model.agreeToSms', function () {
      if (this.get('primaryButtonBusy') || !this.get('phonesValid')) {
        return false;
      }

      return this.get('model.agreeToSms');
    }),

    actions: {
      onEditPhoneNumbersClick: function onEditPhoneNumbersClick() {
        this.set('phonesInMultiEditMode', true);
        this.startEditPhones();
      },
      onEditPhoneNumbersCancel: function onEditPhoneNumbersCancel() {
        this.cancelEditPhones();
        this.set('phonesInMultiEditMode', false);
      },
      onPhoneNumberChange: function onPhoneNumberChange(phones) {
        this.set('phones', phones);
      },
      onPhoneNumbersValidityChange: function onPhoneNumbersValidityChange(validationState) {
        this.set('phonesValid', validationState.valid);
      },
      onPrimaryButtonClick: function onPrimaryButtonClick() {
        var _this = this;

        var nextStep = function nextStep() {
          _this.transitionToRouteWithCurrentParams(_this.getParentRouteName() + '.confirmation');
        };

        var enableSmsIfNotEnabled = function enableSmsIfNotEnabled(primaryPhone) {
          if (!primaryPhone.get('isSmsNotificationEnabled')) {
            _this.set('primaryButtonBusy', true);
            primaryPhone.set('isSmsNotificationEnabled', true);
            //enable sms
            primaryPhone.save().then(function () {
              _this.set('primaryButtonBusy', false);
              var v = (_this.get('phoneVersion') || 0) + 1;
              _this.set('phoneVersion', v);
              nextStep();
            }).catch(function (xhr) {
              _this.set('insertPhoneError', true);
              _this.set('primaryButtonBusy', true);
              primaryPhone.handleError(xhr);
            });
          } else {
            nextStep();
          }
        };

        var noPhone = !this.get('primaryPhone');

        if (this.get('phonesInMultiEditMode') || noPhone) {
          // save phone numbers
          this.set('model.returnRoute', this.getParentRouteName() + '.phone-number');
          this.set('model.userInAddFundsFlow', false);

          this.set('primaryButtonBusy', true);
          this.savePhoneNumbers().then(function (phones) {
            _this.set('model.user.phoneNumbers', phones);
            _this.set('phonesInMultiEditMode', false);
            _this.set('phoneVersion', (_this.get('phoneVersion') || 0) + 1);
            _this.set('model.agreeToSms', false);
            enableSmsIfNotEnabled(_this.get('primaryPhone'));
          }).catch(function (error) {
            _this.handleError(error);
            _this.get('logger').error('Problem in adding/updating phone number. userId=' + Ember.Application.user.id, {
              originalError: error
            });
          }).finally(function () {
            _this.set('primaryButtonBusy', false);
          });
        } else {
          enableSmsIfNotEnabled(this.get('primaryPhone'));
        }
      }
    }
  });
});