define("ingenio-web/birth-chart/confirmation/route", ["exports", "ingenio-web/utils/footer-types", "ingenio-web/mixins/reset-scroll-on-transition"], function (exports, _footerTypes, _resetScrollOnTransition) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollOnTransition.default, {
    flashMessages: Ember.inject.service(),

    defineFooterType: function defineFooterType() {
      return _footerTypes.default.MINIMAL;
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      model.set("userOperation", "Birth Chart");
      controller.set('primaryButtonBusy', false);
    }
  });
});