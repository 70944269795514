define('ingenio-web/tell-a-friend/referral/route', ['exports', 'ingenio-web/utils/header-types'], function (exports, _headerTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    defineHeaderType: function defineHeaderType() {
      return _headerTypes.default.FULL;
    },

    model: function model() {
      return this.store.queryRecord('tafReferralStat', {}).then(function (referralStats) {
        return {
          referralDetail: {
            emails: "",
            referrerName: ""
          },
          referralStats: referralStats
        };
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      controller.initialize();
    }
  });
});