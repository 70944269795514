define('ingenio-web/models/historical-bid-per-position', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        timeRanges: (0, _attr.default)(),
        positionRanges: (0, _attr.default)(),
        bidData: (0, _attr.default)()
    });
});