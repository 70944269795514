define('ingenio-web/components/chat/add-time-modal', ['exports', 'ingenio-web/objects/conversation-payment-methods'], function (exports, _conversationPaymentMethods) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            if (!this.get('isPostPayEligible')) {
                this.set('timeLimit.increaseType', '$custom');
            }
        },


        mixpanelClient: Ember.inject.service('mixpanel-client'),
        experimentation: Ember.inject.service('experimentation'),
        payAfterConversationSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.PayAfterConversation),
        payBeforeConversationSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.PayBeforeConversation),
        autoReloadChatSelected: Ember.computed.equal('conversationPaymentMethod', _conversationPaymentMethods.default.AutoReload),

        minimumAmountForMidChatRecharge: Ember.computed('autoReloadChatSelected', 'timeLimit.minuteRate', function () {
            var minuteRate = this.get('timeLimit.minuteRate');
            var repeatUserMinimumDepositAmount = this.getRepeatUserMinimumDepositAmount();
            var minimumBalanceMinutesForConversation = Ember.ENV.minimumBalanceMinutesForConversation;

            if (this.get('autoReloadChatSelected')) {
                return Math.max(Number(2 * minuteRate), repeatUserMinimumDepositAmount);
            }

            return Math.max(Number(minuteRate * minimumBalanceMinutesForConversation), repeatUserMinimumDepositAmount);
        }),
        isValid: true,

        getRepeatUserMinimumDepositAmount: function getRepeatUserMinimumDepositAmount() {
            var mergedExperimentProps = this.get('experimentation.mergedProperties');

            if (mergedExperimentProps.minimumDepositAmount > 0) {
                return mergedExperimentProps.minimumDepositAmount;
            }
            return Ember.ENV.repeatUserMinimumDepositAmount;
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.send('prePaySelected'), this.set('selectedBox', 1);
            var firstPriceBuket = this.get('priceBuckets').filter(function (priceBucket) {
                return priceBucket.get('boxOrder') !== 0;
            }).get('firstObject');

            if (firstPriceBuket) {
                firstPriceBuket.set('selected', true);
            }
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.set('addInProgress', false);
        },


        onGeneralError: Ember.observer('timeLimit.generalErrorsState.invalid', function () {
            // Enable continue to chat button when there is an error
            if (this.get('timeLimit.generalErrorsState.invalid')) {
                this.set('addInProgress', false);
            }
        }),

        addInProgress: false,

        disableAdd: function () {
            if (!this.get('isValid')) {
                return true;
            }

            return this.get('timeLimit.inProcess') || !this.get('timeLimit.isValid') || this.get('isPostPayEligible') && !this.get('conversationPaymentMethod');
        }.property('timeLimit.inProcess', 'timeLimit.isValid', 'isPostPayEligible', 'conversationPaymentMethod', 'isValid'),

        platformNameText: function () {
            return Ember.ENV.domainName;
        }.property(),

        actions: {
            onValidationStateChange: function onValidationStateChange(state) {
                this.set('isValid', state.valid);
                this.set('addInProgress', false);
            },
            onConversationPaymentMethodChanged: function onConversationPaymentMethodChanged(conversationPaymentMethod) {
                if (conversationPaymentMethod === _conversationPaymentMethods.default.PayAfterConversation && this.get('conversationPaymentMethod') !== _conversationPaymentMethods.default.PayAfterConversation) {
                    this.send('postPaySelected');
                } else if (conversationPaymentMethod === _conversationPaymentMethods.default.PayBeforeConversation && this.get('conversationPaymentMethod') !== _conversationPaymentMethods.default.PayBeforeConversation) {
                    this.send('prePaySelected');
                } else if (conversationPaymentMethod === _conversationPaymentMethods.default.AutoReload && this.get('conversationPaymentMethod') !== _conversationPaymentMethods.default.AutoReload) {
                    this.send('autoReloadSelected');
                }
                this.set('addInProgress', false);
            },
            onIncreaseTypeChanged: function onIncreaseTypeChanged(increaseType) {
                if (this.get('timeLimit.increaseType') !== increaseType) {
                    this.set('timeLimit.increaseType', increaseType);
                    this.set('timeLimit.buyMinutes', null);
                }
            },
            onAutoReloadIncreaseTypeChanged: function onAutoReloadIncreaseTypeChanged(increaseType, amount) {
                if (this.get('timeLimit.increaseType') !== increaseType) {
                    this.set('timeLimit.increaseType', '$custom');
                    this.set('timeLimit.customAmount', amount);
                }
            },
            onBuyMinutesChanged: function onBuyMinutesChanged(buyMinutes) {
                this.get('timeLimit').addTouched('buyMinutes');
                this.set('timeLimit.increaseType', 'custom');
                this.set('timeLimit.buyMinutes', buyMinutes);
            },
            onAmountChanged: function onAmountChanged(amount) {
                if (!amount) {
                    return;
                }

                this.get('timeLimit').addTouched('customAmount');
                this.set('timeLimit.increaseType', '$custom');
                this.set('timeLimit.customAmount', amount);
            },


            add: function add() {
                if (this.get('conversationPaymentMethod') === _conversationPaymentMethods.default.PayAfterConversation) {
                    this.sendAction('extendPostPaySession', 'add-time-dialog');
                } else if (this.get('conversationPaymentMethod') === _conversationPaymentMethods.default.AutoReload) {
                    var amount = this.get('timeLimit.customAmount');
                    this.sendAction('extendAutoReloadSession', 'add-time-dialog', amount);
                } else if (this.get('timeLimit.isValid')) {
                    this.get('timeLimit').clearGenericErrors(); //clear any existing server-side errors.
                    this.sendAction('addMoney');
                }

                // Disable the 'continue to chat' button to prevent user from clicking the button multiple times
                this.set('addInProgress', true);
            },

            postPaySelected: function postPaySelected() {
                if (this.get('conversationPaymentMethod') === _conversationPaymentMethods.default.PayAfterConversation) {
                    this.set('conversationPaymentMethod', null);
                    this.set('timeLimit.increaseType', '10');
                } else {
                    this.set('conversationPaymentMethod', _conversationPaymentMethods.default.PayAfterConversation);
                    this.set('timeLimit.increaseType', _conversationPaymentMethods.default.PayAfterConversation);
                    this.set('isValid', true);
                }
            },
            prePaySelected: function prePaySelected() {
                this.set('timeLimit.increaseType', this.get('isPostPayEligible') ? '10' : '$custom');
                if (this.get('conversationPaymentMethod') === _conversationPaymentMethods.default.PayBeforeConversation) {
                    this.set('conversationPaymentMethod', null);
                } else {
                    this.set('conversationPaymentMethod', _conversationPaymentMethods.default.PayBeforeConversation);
                }
            },
            autoReloadSelected: function autoReloadSelected() {
                this.set('timeLimit.increaseType', '10');
                if (this.get('conversationPaymentMethod') === _conversationPaymentMethods.default.AutoReload) {
                    this.set('conversationPaymentMethod', null);
                } else {
                    this.set('conversationPaymentMethod', _conversationPaymentMethods.default.AutoReload);
                }
            },


            end: function end() {
                this.sendAction('endChat');
            }
        }
    });
});