define('ingenio-web/components/star-rating/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        numberOfReviewsTruncatedText: Ember.computed('numberOfReviews', function () {
            var numberOfReviews = this.get('numberOfReviews');

            if (!numberOfReviews) {
                return null;
            }

            if (numberOfReviews > 9999) {
                return Math.floor(numberOfReviews / 1000) + 'K';
            }

            return numberOfReviews.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }),

        roundedRatingFixed: Ember.computed('roundedRating', 'averageRating', function () {
            return this.get('averageRating') ? this.get('averageRating').toFixed(1) : '0.0';
        }),

        onRatingChanges: function () {
            var roundedRating = this.get('roundedRating');
            this.$(this.element).find('.js_input-rating-stars').rating('update', roundedRating);
        }.observes('roundedRating'),

        didInsertElement: function didInsertElement() {
            var roundedRating = this.get('roundedRating');

            this.$(this.element).find('.js_input-rating-stars').rating({
                showClear: false,
                showCaption: false,
                size: 'xs'
            });

            this.$(this.element).find('.js_input-rating-stars').rating('update', roundedRating);
        }
    });
});