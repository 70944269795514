define('ingenio-web/services/event-emitter', ['exports', 'ingenio-web/utils/browser'], function (exports, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        //Emits evnent with the given name and it's data. Both are required.
        emit: function emit(name, data) {
            if (!name) {
                throw new Error('Event has to have a name.');
            }

            if (!data) {
                throw new Error('Event has to have a "data" property filled in with it\'s data');
            }

            data.browser = _browser.browserName;

            var url = Ember.ENV.cloverApiRoot + '/events';

            return Ember.$.ajax({
                url: url,
                method: 'POST',
                data: JSON.stringify({
                    name: name,
                    data: data
                }),
                contentType: 'application/json; charset=UTF-8'
            });
        }
    });
});