define('ingenio-web/components/myaccount/contact-summary-free', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isKeen: function () {
      return !(Ember.ENV.domainId === 6);
    }.property('Ember.ENV.domainId')
  });
});