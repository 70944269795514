define('ingenio-web/adapters/funds', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() {
            return 'users/' + Ember.Application.user.id + '/balance';
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {
            if (status === 422) {
                var error = this.normalizeErrorResponse(status, headers, payload);

                var adapterError = new DS.AdapterError([{
                    title: 'Unprocessable Entity (422)',
                    status: status,
                    detail: error
                }]);

                adapterError.status = status;
                adapterError.responseJSON = payload;
                return Object.assign(adapterError, payload);
            }

            var response = this._super.apply(this, arguments);

            if (response && response.code) {
                response.set('code', status);
            }

            return response;
        }
    });
});