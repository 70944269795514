define('ingenio-web/call/call-verification/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var primaryPhone = null;

            return this.store.findAll('userPhone').then(function (userPhones) {
                primaryPhone = userPhones.findBy('isPrimary', true);
                return primaryPhone;
            });
        }
    });
});