define('ingenio-web/initializers/cookie-initializer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'cookie-initializer',
        after: ['cookie'],

        initialize: function initialize(app) {
            app.inject('route:chat.initiate', 'cookie', 'cookie:main');
            app.inject('route:chat.consumerSession', 'cookie', 'cookie:main');
            app.inject('route:chat.confirmation', 'cookie', 'cookie:main');
            app.inject('route:application', 'cookie', 'cookie:main');
            app.inject('component:chat.sendTextArea', 'cookie', 'cookie:main');
            app.inject('component:chat.voiceToText', 'cookie', 'cookie:main');
            app.inject('component:chat.chatEnded', 'cookie', 'cookie:main');
            app.inject('controller:chat.consumerSession', 'cookie', 'cookie:main');
            app.inject('controller:chat.confirmation', 'cookie', 'cookie:main');
            app.inject('controller:monitor', 'cookie', 'cookie:main');
        }
    };
});