define('ingenio-web/controllers/support/satisfaction-guarantee/details', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        selectedTransaction: null,
        selectedTransactionId: null,
        requestCreditDisabled: true,
        store: Ember.inject.service(),
        toast: Ember.inject.service('toast'),
        creditRequestAttempted: false,
        creditRequestInProgress: false,
        loyaltyFetching: false,
        creditRedeemed: false,
        creditRedeemFailed: false,
        showCreditReasonDialog: false,
        maxRefundApplied: false,
        domainName: Ember.ENV.domainName, //=> 'Keen | PsychicCenter

        noEligibleTransactions: Ember.computed('model.[]', function () {
            return !(this.get('model.length') > 0);
        }),

        advisorsUrl: Ember.computed('domainName', function () {
            //use a different url for PC and Keen
            return this.get('domainName') === 'Keen' ? '/psychic-readings/195' : '/all-psychics/17004';
        }),

        satisfactionGuaranteeHelpLinkUrl: function () {
            if (Ember.ENV.domainId === 1) {
                return Ember.ENV.urls.zendeskDomainNameUs + '/articles/360053007874-Satisfaction-Guarantee';
            }
            return '/help/satisfactionguarantee';
        }.property(),

        newUserBalance: 0,
        getBalance: function getBalance() {
            var self = this;
            var apiUrl = Ember.ENV.getApiRoot();
            var userInfo = Ember.Application.user;

            Ember.$.getJSON(apiUrl + '/users/' + userInfo.id + '/balance').then(function (response) {
                var newUserBalance = Number(response.monetary).toFixed(2);

                self.set('newUserBalance', newUserBalance);
            }).catch(function (error) {
                console.error(error);
            });
        },


        actions: {
            transactionSelected: function transactionSelected(transaction) {
                this.set('selectedTransaction', transaction);
                this.set('selectedTransactionId', transaction.id);
                //enable the request credit button
                this.set('requestCreditDisabled', false);
            },
            requestCredit: function requestCredit() {
                var _this = this;

                this.set('creditRequestInProgress', true);
                var selectedTransaction = this.get('selectedTransaction');
                var apiUrl = Ember.ENV.getApiRoot();
                var userId = Ember.Application.user.id;
                var loyaltyRequestUrl = apiUrl + ('/loyalty/users/' + userId);
                this.set('loyaltyFetching', true);
                this.set('loyaltyFetchSucess', false);

                Ember.$.getJSON(loyaltyRequestUrl).then(function (response) {
                    var tierId = Ember.get(response, 'tiers.current.id');
                    _this.set('isTranscendTier', tierId === "zrl_diamond");
                    _this.set('loyaltyFetchSucess', true);
                    _this.set('loyaltyFetching', false);
                }).catch(function () {
                    console.error('Error requesting user tier');
                    _this.set('loyaltyFetching', false);
                });

                var payload = {
                    amount: selectedTransaction.get('amount'),
                    transactionReferenceId: selectedTransaction.get('transactionReferenceId'),
                    majorType: selectedTransaction.get('majorType')
                };
                var sgRequestUrl = apiUrl + ('/users/' + userId + '/transactions/satisfaction-guarantee');
                // const sgRequestUrl = 'http://localhost:3008' + `/users/${userId}/transactions/satisfaction-guarantee`;

                Ember.$.post(sgRequestUrl, payload).then(function (response) {
                    // hide sg eligiblity listing content
                    _this.set('creditRequestAttempted', true);
                    var sgResult = response.sgResult;

                    if (sgResult.isSGProcessed) {
                        // show credit redeemed success screen
                        _this.set('creditRedeemed', true);
                        _this.set('amountCredited', sgResult.refundAmount);
                        _this.set('maxRefundApplied', sgResult.maxRefundApplied);
                        _this.set('transactionAmount', sgResult.transactionAmount);
                        // this.set('promoFundsUsedAmount', sgResult.promoFundsUsedAmount);
                    } else {
                        // show credit redeem failure screen
                        _this.set('creditRedeemFailed', true);
                        var rejectedReason = sgResult.rejectionReason;

                        _this.set('rejectedReason', rejectedReason);
                    }
                    _this.getBalance();
                }).catch(function (error) {
                    console.error('Error requesting credit: ', error);
                    _this.get('toast').error('Please try again after some time.', 'Error requesting credit!', {
                        positionClass: 'toast-top-right'
                    });
                }).finally(function () {
                    _this.set('creditRequestInProgress', false);
                });
            },
            showCustomerFeedbackDialog: function showCustomerFeedbackDialog(transaction) {
                var listingTitle = transaction.get('listingTitle') || '';
                var description = transaction.get('customerFeedback.description') || '';

                this.setProperties({
                    feedbackDialogTitle: listingTitle,
                    feedbackDialogDescription: description,
                    showCustomerFeedbackDialog: true
                });
            },
            hideCustomerFeedbackDialog: function hideCustomerFeedbackDialog() {
                this.setProperties({
                    feedbackDialogTitle: '',
                    feedbackDialogDescription: '',
                    showCustomerFeedbackDialog: false
                });
            },
            showCreditReasonDialog: function showCreditReasonDialog() {
                this.set('showCreditReasonDialog', true);
            },
            hideCreditReasonDialog: function hideCreditReasonDialog() {
                this.set('showCreditReasonDialog', false);
            }
        } // end actions
    });
});