define('ingenio-web/components/chat/add-time-minutes-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tenMinutesPrice: function () {
            return '$' + this.get('timeLimit.tenMinutesPrice').toFixed(2);
        }.property('timeLimit.tenMinutesPrice'),

        twentyMinutesPrice: function () {
            return '$' + this.get('timeLimit.twentyMinutesPrice').toFixed(2);
        }.property('timeLimit.twentyMinutesPrice'),

        buyMinutesPrice: function () {
            var price = this.get('timeLimit.buyMinutesPrice');

            if (!price || price === 0) {
                return '';
            }

            return '- $' + price.toFixed(2);
        }.property('timeLimit.buyMinutesPrice'),

        buyMinutesHandler: function () {
            this.set('timeLimit.increaseType', 'custom');
        }.observes('timeLimit.buyMinutes'),

        actions: {
            buyMinutesTouched: function buyMinutesTouched() {
                this.get('timeLimit').addTouched('buyMinutes');
            },

            customIncreaseTypeSelected: function customIncreaseTypeSelected() {
                this.set('timeLimit.increaseType', 'custom');
            }
        }
    });
});