define('ingenio-web/components/multi-step-indicator/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        activeStep: Ember.computed('steps.@each', function () {
            return this.get('steps').find(function (step) {
                return step.active;
            });
        }),

        activeStepNumber: Ember.computed('steps.activeStep', function () {
            return this.get('steps').indexOf(this.get('activeStep')) + 1;
        }),

        stepsWithShadingData: Ember.computed('steps.@each', 'activeStep', function () {
            var _this = this;

            var steps = this.get('steps').slice();

            if (Ember.isEmpty(steps)) {
                return [];
            }

            steps.forEach(function (step, index) {
                return Ember.set(steps.objectAt(index), 'filled', index <= _this.get('steps').indexOf(_this.get('activeStep')));
            });

            return steps;
        })
    });
});