define('ingenio-web/objects/chat/message', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Message = Ember.Object.extend({
        avatarUrl: '',
        text: '',
        sentAt: '',
        isMine: false,
        outputTimeFormat: 'h:mm A',
        outputTimeFormatWithSeconds: 'h:mm:ss A',
        sendContext: {}
    });

    exports.default = Message;


    var DeliverableMessage = Ember.Object.extend({
        isMine: true,
        text: '',
        sent: false,
        deliveryError: false,
        timetoken: null,
        received: false,
        read: false
    });

    exports.DeliverableMessage = DeliverableMessage;
});