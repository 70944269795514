define('ingenio-web/components/ing-currency-input/component', ['exports', 'ingenio-web/mixins/keyboard-events'], function (exports, _keyboardEvents) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var formatCurrency = function formatCurrency(value) {
        var decimalNumber = value.includes('.');

        if (decimalNumber) {
            var decimalParts = value.split('.');

            if (decimalParts.length === 2 && parseFloat(decimalParts[1]) === 0) {
                return decimalParts[0];
            }

            return parseFloat(value).toFixed(2);
        }

        return value;
    };

    exports.default = Ember.Component.extend(_keyboardEvents.default, {
        tagName: 'input',
        attributeBindings: ['name', 'type', 'maxlength', 'inputmode', 'pattern', 'value', 'autocomplete', 'disabled'],

        keyboard: {
            backspace: 8,
            delete: 46
        },

        maxLengthReached: Ember.computed('value', 'maxlength', function () {
            var value = this.get('value');
            var maxlength = this.get('maxlength');

            if (!value || !maxlength) {
                return false;
            }

            return value.length > Number(maxlength);
        }),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var initialValue = this.$().val();
            var formattedValue = formatCurrency(initialValue);

            this.$().val(formattedValue);
        },
        focusOut: function focusOut(event) {
            event.target.value = formatCurrency(event.target.value);
        },
        keyDown: function keyDown(event) {
            if (this.get('maxLengthReached')) {
                return;
            }

            var backspaceOrDeleteKeyPressed = [this.get('keyboard.backspace'), this.get('keyboard.delete')].includes(event.keyCode);

            // fall back to allowKeys mixin if the key pressed is not a number or a delete key.
            if (!isFinite(event.key) && !backspaceOrDeleteKeyPressed) {
                return this.allowKeys(event, [this.keyType.ctrl, this.keyType.numeric, this.keyType.special, this.keyType.decimal]);
            }
        }
    });
});