define('ingenio-web/models/listings-search', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        advisorId: (0, _attr.default)('number'),
        name: (0, _attr.default)('string'),

        categoryId: (0, _attr.default)('number'),
        categoryName: (0, _attr.default)('string'),
        featuredCategoryId: (0, _attr.default)('number'),

        listingTitle: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        speakerName: (0, _attr.default)('string'),
        extension: (0, _attr.default)('string'),
        salespitch: (0, _attr.default)('string'),
        detailsUrl: (0, _attr.default)('string'),

        serviceRate: (0, _attr.default)('string'),
        serviceRateAmount: (0, _attr.default)('number'),
        starRating: (0, _attr.default)('number'),
        averageRating: (0, _attr.default)('number'),
        ratingCount: (0, _attr.default)('number', {
            defaultValue: 0
        }),
        points: (0, _attr.default)('number'),
        lifetimeTransactionCount: (0, _attr.default)('number'),

        picture: (0, _attributes.fragment)('memberPicture'),
        pictureURL: (0, _attr.default)('string'),

        memberSince: (0, _attr.default)('date'),
        lastContacted: (0, _attr.default)('date'),

        offerPrice: (0, _attributes.fragment)('offerPrice'),

        availability: (0, _attr.default)('number'),
        chatAvailability: (0, _attr.default)('number'),

        isChatEnabled: (0, _attr.default)('boolean', {
            defaultValue: false
        }),
        isFavorite: (0, _attr.default)('boolean', {
            defaultValue: false
        })
    });
});