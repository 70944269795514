define('ingenio-web/adapters/video', ['exports', 'ingenio-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      return Ember.ENV.getApiRoot() + '/v2/listing-assets';
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      return this._super(status, headers, { video: payload.data }, requestData);
    }
  });
});