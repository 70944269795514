define('ingenio-web/tell-a-friend/referral/controller', ['exports', 'ingenio-web/utils/email-validator-util'], function (exports, _emailValidatorUtil) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Controller.extend({
		mixpanelClient: Ember.inject.service('mixpanel-client'),

		isFormValid: false,

		errors: {},
		isValid: true,
		isDisabled: false,
		isReferralSubmited: false,
		emailsSucessfull: [],
		emailsErrored: [],

		initialize: function initialize() {
			this._super.apply(this, arguments);

			this.set("isDisabled", false);
		},
		makeFormInvalid: function makeFormInvalid(fieldName, errorMessage) {
			this.set("errors." + fieldName, errorMessage);

			this.set("isValid", false);

			this.set("isDisabled", false);
		},
		makeFormValid: function makeFormValid() {
			this.set("errors", {});

			this.set("isValid", true);

			this.set("isDisabled", true);
		},
		splitEmails: function splitEmails(emails) {
			var separator = ",";
			var pattern = separator + "\s*$";
			var lastSeparatorReqexp = new RegExp(pattern, "g");

			var emailsUpdated = emails.replace(lastSeparatorReqexp, "");

			return emailsUpdated.split(separator).map(function (s) {
				return s.trim();
			});
		},
		distinct: function distinct(emails) {
			// A value in the Set may only occur once; it is unique in the Set's collection
			var emailsSet = new Set(emails);

			return [].concat(_toConsumableArray(emailsSet));
		},
		validateEmails: function validateEmails() {
			var emails = this.get("model.referralDetail.emails");

			var fieldName = "emails";
			var errorMessage = "";

			if (emails.length == 0) {
				errorMessage = "You must enter at least one email address. Please re-enter.";

				this.makeFormInvalid(fieldName, errorMessage);

				return;
			}

			var emailsArray = this.splitEmails(emails);
			var maxEmailsCount = 5;
			if (emailsArray.length > maxEmailsCount) {
				errorMessage = "You can only send up to " + maxEmailsCount + " emails.";
			}

			var currentUserEmail = Ember.Application.user.email;

			emailsArray.forEach(function (element) {
				if (!_emailValidatorUtil.default.isValidEmail(element)) {
					errorMessage = "Your email address does not appear to be valid. Please re-enter.";
				} else if (element === currentUserEmail) {
					errorMessage = "Please do not enter your own email address. The system will not allow you to refer yourself.";
				}
			});

			if (errorMessage !== "") {
				this.makeFormInvalid(fieldName, errorMessage);
			}
		},
		validateName: function validateName() {
			var name = this.get("model.referralDetail.referrerName");

			if (name.length == 0) {
				this.makeFormInvalid("referrerName", "You did not enter your Name. Please re-enter.");
			}
		},
		validateForm: function validateForm() {
			this.validateEmails();

			this.validateName();
		},
		showFormError: function showFormError() {
			this.set("errors.generalError", "Sorry, there was an error sending emails.");

			this.set("isDisabled", false);
		},
		submitForm: function submitForm() {
			var _this = this;

			var userId = Ember.Application.user.id;
			var url = Ember.ENV.getApiRoot() + '/users/' + userId + '/referrals';

			var emails = this.get("model.referralDetail.emails");
			var emailsArray = this.distinct(this.splitEmails(emails));

			var referrerName = this.get("model.referralDetail.referrerName");

			var referralData = JSON.stringify({
				emails: emailsArray,
				referrerName: referrerName
			});

			Ember.$.ajax(url, {
				method: 'POST',
				contentType: 'application/json',
				data: referralData
			}).done(function (data) {
				var emails = _this.get("model.referralDetail.emails");
				var emailsArray = _this.distinct(_this.splitEmails(emails));

				if (data && data.emails.length > 0) {
					_this.set('emailsSucessfull', data.emails);
					_this.set('emailsErrored', data.erroredEmails);
					_this.set('isReferralSubmited', true);
				} else if (data && data.erroredEmails.length === emailsArray.length) {
					var errEmails = data.erroredEmails.toString();
					var errorMessage = 'We cannot process this referral request for ' + errEmails + '. Please try again with a different email. ';
					_this.makeFormInvalid("emails", errorMessage);
				} else {
					var _errorMessage = 'We cannot process this referral request. Please try again with a different email.';
					_this.makeFormInvalid("emails", _errorMessage);
				}
			}).fail(function () {
				_this.showFormError();
			});
		},


		actions: {
			onReferNow: function onReferNow() {
				this.makeFormValid();

				this.validateForm();

				if (this.get("isValid")) {
					this.submitForm();
				}
			},
			helpPopup: function helpPopup() {
				window.open('/help/popup/tellafriend', 'tafHelp', 'width=600,height=600,top=20,left=200,scrollbars');
			},
			onTellMoreFriends: function onTellMoreFriends() {
				window.location.reload();
				// this.set('isReferralSubmited', false);
				// this.set('emailsSucessfull', []);
				// this.set('emailsErrored', []);
			}
		}
	});
});